import { Removal, DTO } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box } from "grommet";
import { useAppDispatch } from "../../../app/store";
import { SlimHeading } from "../../common";

interface RemovalSummaryProps {
	removal: DTO<Removal>;
	displayContext?: "orders" | "donation";
}

export const RemovalSummary: React.FC<RemovalSummaryProps> = (props) => {
	const dispatch = useAppDispatch();

	function handleNavigate(): void {
		dispatch(push(`/admin/dashboard/removals/${props.removal.id}`));
	}

	return (
		<Box
			border
			hoverIndicator
			margin="small"
			onClick={handleNavigate}
		>
			<Box direction="row" gap="small">
				<Box margin="small" gap="small">
					<SlimHeading level="4">
						Junk Removal Service
					</SlimHeading>
				</Box>
			</Box>
		</Box>
	);
};