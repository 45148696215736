import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PartnerService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCustomer, selectIsAdmin, setRequiresLogin } from "../../../app/store/application";
import { fetchStore } from "../../../app/store/partners";
import { Loader, Unauthorized } from "../../common";

export const AdminGuard: React.FC = (props) => {
	const isAdmin = useAppSelector(selectIsAdmin);

	if(!isAdmin) {
		return (
			<Unauthorized />
		);
	}

	return (
		<>
			{props.children}
		</>
	);
};

export const StoreAuthGuard: React.FC = (props) => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [loading, setLoading] = useState(true);

	function authorizeStoreUser(storeId: string): void {
		PartnerService.authorizeStoreUser(storeId)
			.then(res => {
				setIsAuthorized(res);
				return dispatch(fetchStore(storeId)).unwrap();
			})
			.catch(err => {
				console.error("Failed to authorize store user", err);
				setIsAuthorized(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		if(!params.storeId) {
			dispatch(push("/"));
			return;
		}

		authorizeStoreUser(params.storeId);
	}, [params.storeId]);

	if(!loading && !isAuthorized) {
		return (
			<Unauthorized />
		);
	}

	return (
		<Loader visible={loading && !isAuthorized}>
			{props.children}
		</Loader>
	);
};

export const LoginGuard: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const customer = useAppSelector(selectCustomer);

	useEffect(() => {
		if(!customer) {
			dispatch(setRequiresLogin(true));
		}
	}, [customer]);

	if(!customer) {
		return <></>;
	}

	return (
		<>
			{props.children}
		</>
	);
};

