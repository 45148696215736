import { DTO, ProductMedia, Media, Product, ServerResponse, UnionProduct } from "../../types";
import { v4 as uuid } from "uuid";
import { RequestService } from "./request";
import { CustomerProduct, DTOWithoutBase, ListingChannel, ListingChannelSource, PaginatedServerResponse, ProductCondition, ProductIntent, PurchaseDelivery, QueryProductsRequest, RelationExpand, Store, StoreProduct } from "@rego-app/common";

export const ProductService = {
	async getProduct(productId: string, expand?: RelationExpand<Product>): Promise<DTO<Product>> {
		const result = await RequestService
			.get<ServerResponse<DTO<Product>>>(`/api/v1/product/${productId}`, {
				params: {
					expand: expand ? expand.join(",") : undefined
				}
			});
		return result.data.result;
	},

	async getUnionProduct(productId: string, expand?: RelationExpand<UnionProduct>): Promise<DTO<UnionProduct>> {
		const result = await RequestService
			.get<ServerResponse<DTO<UnionProduct>>>(`/api/v1/product/${productId}`, {
				params: {
					expand: expand ? expand.join(",") : undefined
				}
			});
		return result.data.result;
	},

	async getProductsFromSameSeller(productId: string): Promise<DTO<Product>[]> {
		const result = await RequestService
			.post<PaginatedServerResponse<DTO<Product>[]>>(`/api/v1/product/${productId}/same-seller`, {
				page: 1,
				limit: 20
			});
		return result.data.result;
	},

	async listProducts(filters: Partial<Product>, expand?: RelationExpand<Product>): Promise<DTO<Product>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Product>[]>>(`/api/v1/product`, {
				params: {
					...filters,
					expand: expand ? expand.join(",") : undefined
				}
			});
		return result.data.result;
	},

	async listProductsUnion(filters: Partial<Product>, expand?: RelationExpand<UnionProduct>): Promise<DTO<UnionProduct>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<UnionProduct>[]>>(`/api/v1/product`, {
				params: {
					...filters,
					expand: expand ? expand.join(",") : undefined
				}
			});
		return result.data.result;
	},

	async listUrgentProducts(): Promise<DTO<CustomerProduct>[]> {
		const result = await RequestService.get<ServerResponse<DTO<CustomerProduct>[]>>(`/api/v1/product/urgent`);
		return result.data.result;
	},

	async queryProducts(request: QueryProductsRequest): Promise<PaginatedServerResponse<DTO<Product>[]>> {
		const result = await RequestService
			.post<PaginatedServerResponse<DTO<Product>[]>>(`/api/v1/product/query`, request);
		return result.data;
	},

	async updateProduct<T extends DTO<Product>>(productId: string, data: Partial<T>): Promise<T> {
		const result = await RequestService
			.patch<ServerResponse<T>>(`/api/v1/product/${productId}`, data);
		return result.data.result;
	},

	async listProductMedia(productId: string): Promise<Media[]> {
		return [];
	},

	async getMediaContent(productId: string, mediaId: string): Promise<string> {
		const result = await RequestService
			.get<ServerResponse<string>>(`/api/v1/product/${productId}/media/${mediaId}/content`);
		return result.data.result;
	},

	async getMediaURL(productId: string, mediaId: string): Promise<string> {
		const result = await RequestService
			.get<ServerResponse<string>>(`/api/v1/product/${productId}/media/${mediaId}/view`);
		return result.data.result;
	},

	async putProductMedia(productId: string, media: DTO<ProductMedia>): Promise<ProductMedia> {
		const result = await RequestService
			.put<ServerResponse<ProductMedia>>(`/api/v1/product/${productId}/media/`, {
				...media,
				id: undefined
			});
		return result.data.result;
	},

	async updateProductMedia(productId: string, mediaId: string, updates: Partial<ProductMedia>): Promise<DTO<ProductMedia>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<ProductMedia>>>(`/api/v1/product/${productId}/media/${mediaId}`, {
				...updates
			});
		return result.data.result;
	},

	async updateProductMediaPrimary(productId: string, mediaId: string): Promise<DTO<ProductMedia>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<ProductMedia>>>(`/api/v1/product/${productId}/media/${mediaId}/primary`);
		return result.data.result;
	},

	async deleteProductMedia(productId: string, mediaId: string): Promise<ProductMedia> {
		const result = await RequestService
			.delete<ServerResponse<ProductMedia>>(`/api/v1/product/${productId}/media/${mediaId}`);
		return result.data.result;
	},

	async getProductsToReview(): Promise<DTO<UnionProduct>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<UnionProduct>[]>>("/api/v1/product/queue", {
				params: {
					reviewed: false,
					intent: ProductIntent.SELL,
					expand: ["customer", "order"].join(",")
				}
			});
		return result.data.result;
	},

	async approveProduct(productId: string): Promise<Product> {
		const result = await RequestService
			.post<ServerResponse<Product>>(`/api/v1/product/${productId}/approve`);
		return result.data.result;
	},

	async rejectProduct(productId: string, reason: string): Promise<Product> {
		const result = await RequestService
			.post<ServerResponse<Product>>(`/api/v1/product/${productId}/reject`, {
				reason
			});
		return result.data.result;
	},

	async expireProduct(productId: string): Promise<Product> {
		const result = await RequestService
			.post<ServerResponse<Product>>(`/api/v1/product/${productId}/expire`, {

			});
		return result.data.result;
	},

	async getRegoListing(productId: string): Promise<DTO<ListingChannel>> {
		const result = await RequestService
			.get<ServerResponse<DTO<ListingChannel>>>(`/api/v1/product/${productId}/channel/${ListingChannelSource.REGO}`);
		return result.data.result;
	},

	async getProductDeliveries(productId: string): Promise<DTO<PurchaseDelivery>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<PurchaseDelivery>[]>>(`/api/v1/product/${productId}/delivery`);
		return result.data.result;
	},
};

export function buildCustomerProduct(data: Partial<DTOWithoutBase<CustomerProduct>>): DTO<CustomerProduct> {
	return {
		id: uuid(),
		brand: data.brand ?? null,
		brand_input: data.brand_input ?? "",
		condition: data.condition ?? ProductCondition.UNKNOWN,
		description: data.description ?? "",
		dimensions: data.dimensions ?? {
			height: 0,
			width: 0,
			depth: 0,
			unit: "inches"
		},
		goal: data.goal ?? ProductIntent.SELL,
		item: data.item ?? null,
		item_input: data.item_input ?? "",
		keywords: [],
		materials: [],
		media: data.media ?? [],
		price: data.price ?? 0,
		price_compare: data.price_compare ?? 0,
		title: data.title ?? "",
		weight: data.weight ?? {
			value: 0,
			unit: "pounds"
		}
	} as unknown as DTO<CustomerProduct>;
}

export function buildStoreProduct(data: Partial<DTOWithoutBase<StoreProduct>>): DTO<StoreProduct> {
	return {
		id: uuid(),
		brand: data.brand ?? null,
		brand_input: data.brand_input ?? "",
		condition: data.condition ?? ProductCondition.UNKNOWN,
		description: data.description ?? "",
		dimensions: data.dimensions ?? {
			height: 0,
			width: 0,
			depth: 0,
			unit: "inches"
		},
		item_input: data.item_input ?? "",
		keywords: [],
		materials: [],
		media: data.media ?? [],
		price: data.price ?? 0,
		price_compare: data.price_compare ?? 0,
		title: data.title ?? "",
		weight: data.weight ?? {
			value: 0,
			unit: "pounds"
		}
	} as unknown as DTO<StoreProduct>;
}