import { ActionItem, ActionType, DeliveryActionItem, DeliveryWindow, ProductIntent, ServiceActionItem } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Card, CardBody, Grid, Heading, Image, Text } from "grommet";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { listCustomerActionItems, selectCustomerActionItems, useAppDispatch, useAppSelector } from "../../../app/store";
import { parseDateFromUTC } from "../../../helpers";
import { useTimezone, useWindowDimensions } from "../../common";
import { AcceptDeliveryButton, ServiceCard } from "../components";
import relax from "../../../img/relax.gif";

export const DashboardActions: React.FC = (props) => {
	const dimensions = useWindowDimensions();
	const [columns, setColumns] = useState(3);

	const actions = useAppSelector(selectCustomerActionItems);

	useEffect(() => {
		if(dimensions.width > 1200) {
			setColumns(3);
			return;
		}

		if(dimensions.width > 900) {
			setColumns(2);
			return;
		}

		setColumns(1);
	}, [dimensions.width]);

	return (
		<Box margin="medium" gap="small">
			<Box align="center">
				<Heading level="2" color="brand">
					Your Action Items
				</Heading>
			</Box>
			{actions.length === 0
				? (
					<Box align="center" justify="center" gap="small">
						<Text weight="bold">
							Take a break ... your job is done for now
						</Text>
						<Image
							a11yTitle="Relax"
							alt="Relax"
							style={{ maxWidth: "125px" }}
							src={relax}
						/>
					</Box>
				)
				: (
					<Grid columns={{ count: columns, size: "auto" }}>
						{actions.map(action => (
							<ActionItemCard
								key={JSON.stringify(action)}
								action={action}
							/>
						))}
					</Grid>
				)}
		</Box>
	);
};

interface ActionItemCardProps {
	action: ActionItem;
}

const ApproveDeliveryCard: React.FC<ActionItemCardProps> = (props) => {
	const timezone = useTimezone();
	const dispatch = useAppDispatch();

	function handleConfirmationUpdated(): void {
		dispatch(listCustomerActionItems()).unwrap()
			.catch(err => {
				console.error("Failed to fetch action items", err);
			});
	}

	function printWindowRange(window: DeliveryWindow): JSX.Element {
		return (
			<Text weight="bold">
				{parseDateFromUTC(window.date, timezone)} from {moment().set("hours", window.from).format("hh A")} to {moment().set("hours", window.to).format("hh A")}
			</Text>
		);
	}

	if(isDeliveryActionItem(props.action)) {
		const delivery = props.action.delivery;
		return (
			<Box fill="vertical">
				<Card margin="small" flex>
					<CardBody margin="medium">
						<Text>
							Congratulations on your furniture sale! Your furniture is scheduled to get picked-up by the delivery team at {printWindowRange(delivery.window)}. Please confirm below if this time works for you. If you need a different time, please call or chat with us.
						</Text>
						<Box flex justify="end">
							<Box fill="horizontal">
								<AcceptDeliveryButton
									delivery={delivery}
									onConfirmationUpdated={handleConfirmationUpdated}
								/>
							</Box>
						</Box>
					</CardBody>
				</Card>
			</Box>
		);
	}

	throw new Error("IMPLEMENT!");
};

const ScheduleDeliveryCard: React.FC<ActionItemCardProps> = (props) => {
	const timezone = useTimezone();
	const dispatch = useAppDispatch();

	if(isServiceActionItem(props.action)) {
		const intent = props.action.intent;
		const service = props.action.service;

		if(intent === ProductIntent.DONATE) {
			return (
				<ServiceCard
					intent={ProductIntent.DONATE}
					service={service}
					serviceLabel="donation"
					onClickOpen={() => {
						dispatch(push(`/dashboard/donations`));
					}}
					contentCompleted={`Your donation was completed on ${parseDateFromUTC(service.completed_at, timezone)}. Your donation receipt will be uploaded here when it is received.`}
					contentInProgress={"Your donation is in progress."}
					contentReadyToSchedule={"Your donation is ready to be scheduled. Click below to pick a time for your item(s) to be picked up"}
					contentScheduled={"Your donation is scheduled. If you need to make any changes give us a call. Please note, if your pickup is cancelled within 24 hours of the scheduled date a cancellation fee of 50% will be applied"}
				/>
			);
		}
		return (
			<ServiceCard
				intent={ProductIntent.JUNK}
				service={service}
				serviceLabel="donation"
				onClickOpen={() => {
					dispatch(push(`/dashboard/donations`));
				}}
				contentCompleted={`Your junk removal was completed on ${parseDateFromUTC(service.completed_at, timezone)}.`}
				contentInProgress={"Your junk removal is in progress."}
				contentReadyToSchedule={"Your junk removal is ready to be scheduled. Click below to pick a time for your item(s) to be picked up"}
				contentScheduled={"Your junk removal is scheduled. If you need to make any changes give us a call. Please note, if your pickup is cancelled within 24 hours of the scheduled date a cancellation fee of 50% will be applied"}
			/>
		);
	}

	throw new Error("IMPLEMENT!");
};

function isServiceActionItem(action: ActionItem): action is ServiceActionItem {
	return !!(action as ServiceActionItem).service;
}

function isDeliveryActionItem(action: ActionItem): action is DeliveryActionItem {
	return !!(action as DeliveryActionItem).delivery;
}

const ActionItemCard: React.FC<{ action: ActionItem; }> = (props) => {
	switch(props.action.type) {
		case ActionType.APPROVE_DELIVERY: {
			return (
				<ApproveDeliveryCard action={props.action} />
			);
		}
		case ActionType.SCHEDULE_DELIVERY: {
			return (
				<ScheduleDeliveryCard action={props.action} />
			);
		}
	}
};