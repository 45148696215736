import { Estimate, Service, Timezone } from "../../../types";
import { Box, Button, Calendar, CheckBox, Form, FormField, Grid, Spinner, Text, ThemeContext } from "grommet";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { OrderService } from "../../../app/services/order";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectOrder } from "../../../app/store/order";
import { Loader, PickupWindow, pickupWindows, PickupWindowType, SlimHeading, useTimezone } from "../../common";
import { DayOfWeek, Delivery, DeliveryWindow, DTO, Order, Payment, PaymentMethod, StoreHours } from "@rego-app/common";
import { CircleInformation } from "grommet-icons";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Tooltip } from "@mui/material";
import { push } from "connected-react-router";
import { createPaymentIntent, fetchClientSecret } from "../../../app/store/checkout";
import { dayOfWeekToNumber, formatCurrency, numberToDayOfWeek } from "../../../helpers";
import { ExpandMore } from "@mui/icons-material";
import { CheckoutSelectPaymentMethod, formatCardDescription } from "../../shop";
import { DeliveryService } from "../../../app/services/delivery";
import { fetchDonation } from "../../../app/store/admin";

interface EstimateOptionProps {
	selected: boolean | null;
	label: JSX.Element;
	information: string;
	service: DTO<Service>;
	requiresHelper: boolean;
	selectedDate?: Date;
	selectedWindow?: typeof pickupWindows[number];
	wasSelected(estimate?: DTO<Estimate>): void;
}

interface EstimateOptionState {
	isLoading: boolean;
	estimate: DTO<Estimate> | null;
}

export const EstimateOption: React.FC<EstimateOptionProps> = (props) => {

	const snack = useSnackbar();
	const [state, setState] = useState<EstimateOptionState>({
		isLoading: false,
		estimate: null
	});

	function loadEstimate() {
		const { selectedDate, selectedWindow, service, requiresHelper } = props;

		console.debug(props);
		if(!selectedDate || !selectedWindow) {
			return;
		}

		setState({
			...state,
			isLoading: true
		});

		const window: DeliveryWindow = {
			date: selectedDate,
			from: selectedWindow.from,
			to: selectedWindow.to,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code
		};

		OrderService.createServiceEstimate(service.order.id, service.id, service.vehicle, window, requiresHelper ?? false)
			.then(res => {
				console.debug("GOT RESULT", res);
				setState((state) => {
					return {
						...state,
						estimate: res
					};
				});
			})
			.catch(err => {
				console.error("Failed to load estimate", err);
				snack.enqueueSnackbar("We had some trouble getting your price quote", {
					variant: "error"
				});
			})
			.finally(() => {
				setState((state) => {
					return {
						...state,
						isLoading: false
					};
				});
			});
	}

	useEffect(() => {
		console.debug(props.selectedDate);
		console.debug(props.selectedWindow);
		loadEstimate();
	}, [props.selectedDate, props.selectedWindow]);

	return (
		<Box
			hoverIndicator
			onClick={() => {
				if(state.estimate) {
					props.wasSelected(state.estimate);
				}
			}}
			border={{ side: "all", size: "small", color: props.selected ? "brand" : "black" }}
			align="center"
			pad="medium">
			<Box align="end">
				<Tooltip
					leaveDelay={3000}
					leaveTouchDelay={3000}
					title={props.information}
				>
					<CircleInformation />
				</Tooltip>
			</Box>
			<Box margin="small">
				<SlimHeading level={"3"}>
					{state.isLoading
						? (<Spinner />)
						: (state.estimate?.total_amount)
							? `$${state.estimate?.total_amount}`
							: "-"
					}
				</SlimHeading>
			</Box>
			<Box align="center" justify="center">
				{props.label}
			</Box>
		</Box>
	);
};

interface ScheduleControllerState {
	isLoading: boolean;
	isSubmitting: boolean;
	isLoadingPayment: boolean;
	isLoadingEstimate: boolean;
	isSelectingDate: boolean;
	isSelectingPayment: boolean;
	isUpdatingDelivery: boolean;
	showConfirmation: boolean;
	selectedDate: string;
	selectedWindow: typeof pickupWindows[number] | null;
	requiresHelper: boolean;
	service: DTO<Service> | null;
	payment: DTO<Payment> | null;
	estimate: DTO<Estimate> | null;
	delivery: DTO<Delivery> | null;
	paymentMethod: DTO<PaymentMethod> | null;
}

export const ScheduleController: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const timezone = useTimezone();
	const order = useAppSelector(selectOrder);
	const snack = useSnackbar();
	const [state, setState] = useState<ScheduleControllerState>({
		isLoading: true,
		isSubmitting: false,
		isSelectingDate: true,
		isSelectingPayment: false,
		isUpdatingDelivery: false,
		isLoadingPayment: false,
		isLoadingEstimate: false,
		selectedDate: "",
		selectedWindow: null,
		service: null,
		payment: null,
		estimate: null,
		delivery: null,
		requiresHelper: false,
		paymentMethod: null,
		showConfirmation: false
	});

	useEffect(() => {
		if(state.service) {
			const estimates = [...(state.service.estimates ?? [])].sort((a, b) => {
				return moment(b.created_at).unix() - moment(a.created_at).unix();
			});
			updateState({ estimate: estimates[0], payment: (state.service.payments ?? [])[0] ?? state.payment });
		}
	}, [state.service]);

	useEffect(() => {
		if(state.payment?.payment_method) {
			updateState({ paymentMethod: state.payment.payment_method });
		}
	}, [state.payment]);

	useEffect(() => {
		if(state.service?.scheduled) {
			dispatch(push("/dashboard"));
		}
	}, [state.service]);

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		if(query.get("step") === "PAYMENT") {
			updateState({
				isSelectingDate: false,
				isSelectingPayment: true
			});
		}
	}, []);

	function updateState(updates: Partial<ScheduleControllerState>): void {
		setState((state) => {
			return {
				...state,
				...updates
			};
		});
	}

	function captureServiceIdFromQuery(): string {
		const serviceId = new URLSearchParams(location.search).get("serviceId");

		if(!serviceId) {
			throw new Error("Could not find serviceId in query");
		}

		return serviceId;
	}

	useEffect(() => {
		loadService();
	}, []);

	async function loadService(): Promise<void> {
		try {
			const service = await OrderService.getService(captureServiceIdFromQuery());
			updateState({ isLoading: false, service });
		}
		catch(e) {
			console.debug("Failed to load service", e);
			updateState({ isLoading: false });

			snack.enqueueSnackbar("Something went wrong loading your order details", {
				variant: "error"
			});
		}
	}

	const calculateDateBounds = useCallback((): [Date, Date] => {
		return [
			moment().add(1, "day").toDate(),
			moment.min(
				order?.cutoff_date ? moment(order?.cutoff_date) : moment().add(1, "month"),
				moment().add(1, "month")
			).toDate()
		];
	}, [order?.cutoff_date]);

	const excludedDates = useMemo((): Date[] => {
		const excluded: Date[] = [];
		if(!state.service) {
			return excluded;
		}

		if(!state.service.store) {
			//FIXME: need this to be more dynamic (exclude sundays when no store provided (junk))
			const cutoff = moment().add(1, "year");
			let cursor = moment();

			while(cursor.unix() < cutoff.unix()) {
				if(cursor.weekday() === 0) {
					excluded.push(moment(cursor).toDate());
				}

				cursor.add(1, "day");
			}
			return excluded;
		}

		if(!state.service.store.hours) {
			return excluded;
		}

		const hours = state.service.store.hours;
		const closed = (Object.keys(hours.daily) as (keyof typeof hours["daily"])[])
			.filter(day => !hours.daily[day])
			.map(day => dayOfWeekToNumber(day));

		const cutoff = moment().add(1, "year");
		let cursor = moment();

		while(cursor.unix() < cutoff.unix()) {
			if(closed.includes(cursor.weekday())) {
				excluded.push(moment(cursor).toDate());
			}

			if(hours.exceptions.find(e => e.date === cursor.format("YYYY-MM-DD"))) {
				excluded.push(moment(cursor).toDate());
			}

			cursor.add(1, "day");
		}

		const storeHoursExceptions = state.service.store.hours.exceptions ?? [];
		for(const exception of storeHoursExceptions) {
			if(!exception.open) {
				excluded.push(new Date(exception.date));
			}
		}

		return excluded;
	}, [state.service]);

	const windows = useMemo((): PickupWindowType[] => {
		if(!state.service) {
			return pickupWindows;
		}

		if(state.service.store) {
			return pickupWindows;
		}

		return [
			{
				from: 8,
				to: 12,
				label: "08:00 AM - 12:00 PM"
			},
			{
				from: 12,
				to: 16,
				label: "12:00 PM - 04:00 PM"
			},
			{
				from: 16,
				to: 20,
				label: "04:00 PM - 08:00 PM"
			}
		];

	}, [state.service]);

	function onWindowSelected(window: string): void {
		updateState({ selectedWindow: windows.find(w => w.label === window) });
	}

	function onDateSelected(selectedDate: string) {
		updateState({ selectedDate: selectedDate });
	}

	function onSaveDate(): void {
		updateState({ isSelectingDate: false, isSelectingPayment: true });
	}

	function onPaymentMethodSelected(method: DTO<PaymentMethod>): void {
		updateState({ paymentMethod: method, isSelectingPayment: false });
	}

	function getMinimumPickupTime(dayOfWeek: DayOfWeek): string {
		if(!state.service) {
			return "23:59";
		}

		if(state.service.store) {
			const hours = state.service.store.hours;
			const value = hours.daily[dayOfWeek];
			if(value) {
				return value.from;
			}
		}

		return "08:00";
	}

	//provide 1 hour of buffer
	function getMaximumPickupTime(dayOfWeek: DayOfWeek): string {
		if(!state.service) {
			return "00:00";
		}

		if(state.service.store) {
			const hours = state.service.store.hours;
			const value = hours.daily[dayOfWeek];
			if(value) {
				const hours = value.to.split(":")[0];
				const minutes = value.to.split(":")[1];

				return `${Number(hours) - 1}:${minutes}`;
			}
		}

		return "20:00";
	}

	function handleCreatePayment(order: DTO<Order>, service: DTO<Service>, estimate: DTO<Estimate>, paymentMethod: DTO<PaymentMethod>): Promise<DTO<Payment>> {
		updateState({ isLoadingPayment: true });
		return OrderService.createServicePaymentIntent(
			order.id,
			service.id,
			estimate.id,
			paymentMethod
		).then(payment => {
			updateState({ isLoadingPayment: false, payment });
			return payment;
		})
			.catch(err => {
				console.error("Failed to create payment", err);
				snack.enqueueSnackbar("We ran into an issue confirming your payment", { variant: "error" });
				throw err;
			})
			.finally(() => {
				updateState({ isLoadingPayment: false });
			});
	}

	function handleScheduleDelivery(service: DTO<Service>, window: DeliveryWindow): Promise<DTO<Delivery>> {
		updateState({ isUpdatingDelivery: true });
		return OrderService.scheduleServiceDelivery(service.id, window)
			.then(delivery => {
				updateState({ isUpdatingDelivery: false, delivery });
				return delivery;
			})
			.catch(err => {
				console.error("Failed to update delivery", err);
				snack.enqueueSnackbar("We ran into an issue scheduling your pickup time", { variant: "error" });
				throw err;
			})
			.finally(() => {
				updateState({ isUpdatingDelivery: false });
			});
	}

	async function wrapPaymentAndDeliveryUpdates(order: DTO<Order>, service: DTO<Service>, estimate: DTO<Estimate>, paymentMethod: DTO<PaymentMethod>, window: DeliveryWindow): Promise<void> {
		if(!state.payment) {
			await handleCreatePayment(order, service, estimate, paymentMethod)
				.then(() => dispatch(fetchDonation(service.id)))
				.catch(err => {
					throw err;
				});
		}

		await handleScheduleDelivery(service, window);
	}

	function handleSubmit(): void {
		if(!state.service || !state.estimate || !state.paymentMethod || !state.selectedDate || !state.selectedWindow) {
			return;
		}

		updateState({ isSubmitting: true });
		const deliveryWindow: DeliveryWindow = {
			from: state.selectedWindow.from,
			to: state.selectedWindow.to,
			date: new Date(state.selectedDate),
			timezone: timezone
		};

		wrapPaymentAndDeliveryUpdates(state.service.order, state.service, state.estimate, state.paymentMethod, deliveryWindow)
			.then(() => {
				updateState({ showConfirmation: true });
				snack.enqueueSnackbar("Your pickup has been successfully scheduled", { variant: "success" });
				dispatch(push("/dashboard"));
			})
			.catch(err => {
				console.error("Failed to schedule pickup", err);
				snack.enqueueSnackbar("We ran into an error scheduling your pickup", { variant: "error" });
			})
			.finally(() => {
				updateState({ isSubmitting: false });
			});
	}

	return (
		<Box margin="medium">
			<Box direction="column" gap="small">
				<Box gap="small" margin="small">
					{state.estimate && (
						<Box align="center" gap="small">
							<SlimHeading level="3">
								Pickup Quote
							</SlimHeading>
							<Box align="center" justify="center">
								<Text weight="bold">
									{formatCurrency(state.estimate.total_amount)}
								</Text>
							</Box>
							<Text>
								This is your total cost for the pickup of your item(s)
							</Text>
						</Box>
					)}
				</Box>
				<Box>
					<Accordion
						expanded={state.isSelectingDate}
					>
						<AccordionSummary
							onClick={() => {
								setState({
									...state,
									isSelectingDate: !state.isSelectingDate
								});
							}}
							expandIcon={<ExpandMore />}
						>
							{state.selectedDate
								? (
									<Box direction="row" justify="between" flex>
										<Box>
											<SlimHeading level="3">Pickup Date</SlimHeading>
										</Box>
										<Box align="center" justify="center" margin={{ right: "small" }}>
											<Text>{moment.tz(state.selectedDate, timezone).format("MM/DD/YYYY")}</Text>
										</Box>
									</Box>
								)
								: (
									<SlimHeading level="3">Pickup Date</SlimHeading>
								)
							}
						</AccordionSummary>
						<AccordionDetails>
							<Box margin="medium" direction="row-responsive" justify="center" gap="medium">
								<ThemeContext.Extend
									value={{
										global: {
											focus: {
												border: {
													color: "transparent",
												},
											},
										},
									}}>
									<Calendar
										firstDayOfWeek={0}
										style={{ outline: "none !important", boxShadow: "none !important", width: "auto" }}
										margin="small"
										date={state.selectedDate ?? undefined}
										showAdjacentDays="trim"
										bounds={calculateDateBounds().map(d => d.toISOString())}
										disabled={excludedDates.map(d => d.toISOString())}
										onSelect={(date) => {
											onDateSelected((Array.isArray(date) ? date[0] : date));
										}}
									/>
								</ThemeContext.Extend>
								<Box fill="vertical" gap="medium">
									<FormField
										name="window"
										label="Select your desired pickup window"
										info={!state.selectedDate ? "Please select a date first" : undefined}
									>
										<PickupWindow
											name="window"
											windows={windows}
											onChange={(event) => {
												onWindowSelected(event.target.value);
											}}
											disabled={!state.selectedDate}
											minimumStartTime={state.service && state.selectedDate ? getMinimumPickupTime(numberToDayOfWeek(moment(state.selectedDate).weekday())) : undefined}
											maximumEndTime={state.service && state.selectedDate ? getMaximumPickupTime(numberToDayOfWeek(moment(state.selectedDate).weekday())) : undefined}
										/>
									</FormField>
								</Box>
							</Box>
							<Box flex justify="end" align="end">
								<Button
									primary
									disabled={!state.selectedDate || !state.selectedWindow}
									onClick={onSaveDate}
									label="Save Date"
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={state.isSelectingPayment}
					>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							onClick={() => {
								setState({
									...state,
									isSelectingPayment: !state.isSelectingPayment
								});
							}}
						>
							{state.paymentMethod
								? (
									<Box direction="row" justify="between" flex>
										<Box>
											<SlimHeading level="3">Payment Method</SlimHeading>
										</Box>
										<Box align="center" justify="center" margin={{ right: "small" }}>
											<Text>{formatCardDescription(state.paymentMethod)}</Text>
										</Box>
									</Box>
								)
								: (
									<SlimHeading level="3">Payment Method</SlimHeading>
								)
							}
						</AccordionSummary>
						<AccordionDetails>
							<CheckoutSelectPaymentMethod
								onSuperNext={() => { }}
								paymentMethod={state.paymentMethod ?? undefined}
								handleSavePaymentMethod={onPaymentMethodSelected}
								returnUrl={`${window.location.protocol}//${window.location.host}/schedule?serviceId=${state.service?.id}&step=PAYMENT`}
							/>
						</AccordionDetails>
					</Accordion>
				</Box>
				<Box flex justify="end" align="end" margin={{ top: "large" }}>
					<Button
						primary
						label="Schedule Pickup"
						onClick={handleSubmit}
						disabled={!state.selectedDate || !state.selectedWindow || !state.paymentMethod || state.isSubmitting}
						icon={state.isSubmitting ? <Spinner /> : undefined}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export const ScheduleHome: React.FC = (props) => {
	return (
		<ScheduleController />
	);
};