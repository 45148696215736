import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Box, Button, Form, Text, Spinner } from "grommet";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/store";
import { selectPayment } from "../../../app/store/checkout";
import { Loader, SlimHeading } from "../../common";
import { useConfig } from "../../common/hooks/useConfig";

export const toCurrency = function (number: number) {
	return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
};

interface CheckoutFormProps {
	paymentId: string;
	clientSecret: string;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = (props) => {
	const config = useConfig();
	const stripe = useStripe();
	const payment = useAppSelector(selectPayment);
	const elements = useElements();
	const [message, setMessage] = useState<string>("");
	const [errorMessage, setErrorMessage] = useState("");
	const [isReady, setIsReady] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false);

	useEffect(() => {
		if(!stripe) {
			return;
		}

		if(!props.clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(props.clientSecret).then((res) => {
			console.debug(res);
			const { paymentIntent } = res;
			switch(paymentIntent?.status) {
				case "canceled":
					setIsCompleted(true);
					setMessage("Your payment request was cancelled.");
					break;
				case "succeeded":
				case "requires_capture":
					setMessage("Payment succeeded!");
					setIsCompleted(true);
					break;
				case "processing":
					setMessage("Your payment is processing.");
					break;
				case "requires_payment_method":
					//When no payment received && failed payment
					setMessage("");
					setIsCompleted(false);
					break;
				case "requires_action":
				case "requires_confirmation":
					setMessage("We need some more information.");
					setIsCompleted(false);
					break;
				default:
					setMessage("Something went wrong.");
					break;
			}
		}).finally(() => {
			setIsReady(true);
		});
	}, [stripe]);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if(!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.protocol}//${window.location.host}/checkout/confirmation?paymentId=${props.paymentId}`,
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if(error.type === "card_error" || error.type === "validation_error") {
			setErrorMessage(error.message ?? "");
		} else {
			setErrorMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	return (
		<Loader visible={!isReady || !elements || !stripe} >
			<Box margin="large" align="center" justify="center" gap="small">
				<Box align="center">
					<SlimHeading level="3">
						{toCurrency(Number(payment?.amount))}
					</SlimHeading>
				</Box>
				<Form>
					<Box gap="small">
						<PaymentElement />
						<Box margin="small">
							<Button
								style={{ borderRadius: undefined }}
								primary
								type="submit"
								onClick={handleSubmit}
								icon={isLoading ? <Spinner /> : undefined}
								label={isLoading ? "" : "Pay Now"}
								disabled={isLoading || !stripe || !elements || isCompleted}
							/>
						</Box>
						<Box>
							<SlimHeading level="5">
								Don't worry! Your card won't be charged until your service is completed. Depending on your bank, you may see a temporary authorization for the full amount. If you cancel less than 24 hours before your scheduled pickup time, a cancellation fee of 50% will be applied.
							</SlimHeading>
						</Box>
						<Box>
							{message && <div id="payment-message">{message}</div>}
							{errorMessage
								? (
									<Text id="payment-message" color="status-error">
										{errorMessage}
									</Text>
								)
								: (
									<Text id="payment-message">
										{message}
									</Text>
								)
							}
						</Box>
					</Box>
				</Form>
			</Box>
		</Loader>
	);
};