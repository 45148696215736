import { DTO, Payment } from "@rego-app/common";
import { Box, FormField, CheckBox, Button, Form, Layer, TextInput, Spinner } from "grommet";
import { FormNext, User } from "grommet-icons";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { PaymentService } from "../../../app/services";
import { useAppDispatch } from "../../../app/store";
import { fetchPayment } from "../../../app/store/admin";
import { getStandardFormValidations, getNumberFormValidations, formatCurrency, isDonation } from "../../../helpers";
import { CopyToClipboardField, FieldWithActionButton, LocalDateField } from "../../common";
import { navigateToCustomer, navigateToDonation, navigateToOrder, navigateToPurchase, navigateToRemoval } from "../routes";

interface PaymentDetailsProps {
	payment: DTO<Payment>;
	onPaymentCapture(): void;
}

export const PaymentDetails: React.FC<PaymentDetailsProps> = (props) => {
	return (
		<Box>
			<CopyToClipboardField
				name="id"
				label="Payment Id"
				value={props.payment.id}
			/>
			<CopyToClipboardField
				name="number"
				label="Payment Number"
				value={String(props.payment.number ?? "")}
			/>
			<FormField
				name="authorized"
				label="Authorized"
				contentProps={{ border: undefined }}
			>
				<CheckBox
					disabled
					readOnly
					checked={props.payment.authorized}
				/>
			</FormField>
			{props.payment.authorized && (
				<Fragment>
					<LocalDateField
						name="authorized_at"
						label="Authorized At"
						value={props.payment.authorized_at}
					/>
					<FormField
						name="amount_authorized"
						label="Amount Authorized"
					>
						<TextInput
							disabled
							name="amount_authorized"
							value={formatCurrency(props.payment.amount_authorized)}
						/>
					</FormField>
				</Fragment>
			)}
			<FormField
				name="captured"
				label="Captured"
				contentProps={{ border: undefined }}
			>
				<CheckBox
					disabled
					readOnly
					checked={props.payment.captured}
				/>
			</FormField>
			{!props.payment.captured && (
				<Box margin="xsmall" align="start">
					<Button
						primary
						label="Capture"
						onClick={props.onPaymentCapture}
					/>
				</Box>
			)}
			{props.payment.captured && (
				<Fragment>
					<LocalDateField
						name="captured_at"
						label="Captured At"
						value={props.payment.captured_at}
					/>
					<FormField
						name="amount_captured"
						label="Amount Captured"
					>
						<TextInput
							disabled
							name="amount_captured"
							value={formatCurrency(props.payment.amount_captured)}
						/>
					</FormField>
				</Fragment>
			)}
		</Box>
	);
};


interface PaymentLinksProps {
	payment: DTO<Payment>;
}

export const PaymentLinks: React.FC<PaymentLinksProps> = (props) => {
	const dispatch = useAppDispatch();

	return (
		<Box>
			{props.payment.customer && (
				<FieldWithActionButton
					name="customer"
					label="Customer"
					value={`${props.payment.customer.first_name} ${props.payment.customer.last_name}`}
					icon={<User />}
					onClick={() => {
						navigateToCustomer(props.payment.customer.id, dispatch);
					}}
				/>
			)}
			{props.payment.order && (
				<FieldWithActionButton
					name="order"
					label="Order"
					value={props.payment.order.id}
					icon={<FormNext />}
					onClick={() => {
						props.payment.order && navigateToOrder(props.payment.order.id, dispatch);
					}}
				/>
			)}
			{props.payment.service && (
				<FieldWithActionButton
					name={"service"}
					label={isDonation(props.payment.service) ? "Donation" : "Removal"}
					value={props.payment.service.id}
					icon={<FormNext />}
					onClick={() => {
						props.payment.service && isDonation(props.payment.service)
							? navigateToDonation(props.payment.service.id, dispatch)
							: navigateToRemoval(props.payment.service.id, dispatch);
					}}
				/>
			)}
			{props.payment.purchase && (
				<FieldWithActionButton
					name="purchase"
					label="Purchase"
					value={props.payment.purchase.id}
					icon={<FormNext />}
					onClick={() => {
						props.payment.purchase && navigateToPurchase(props.payment.purchase.id, dispatch);
					}}
				/>
			)}
		</Box>
	);
};

interface CapturePaymentModalProps {
	payment: DTO<Payment>;
	onClose(): void;
}

export const CapturePaymentModal: React.FC<CapturePaymentModalProps> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const [state, setState] = useState({
		...props.payment,
		isSubmitting: false,
		amount_captured: 0
	});

	useEffect(() => {
		setState({ ...state, ...props.payment });
	}, [props.payment]);

	function handleSubmit(): void {
		if(!state.amount_captured) {
			return;
		}

		setState({ ...state, isSubmitting: true });
		PaymentService.capturePayment(props.payment.id, Number(state.amount_captured))
			.then(() => dispatch(fetchPayment({ paymentId: props.payment.id })).unwrap())
			.then(() => props.onClose())
			.catch(err => {
				console.error("Failed to capture payment", err);
				snack.enqueueSnackbar("Failed to capture payment", { variant: "error" });
			})
			.finally(() => {
				setState({ ...state, isSubmitting: false });
			});
	}

	return (
		<Layer
			onEsc={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box margin="medium">
				<Form
					value={state}
					onChange={(changes) => setState({ ...state, ...changes })}
					onSubmit={handleSubmit}
					validate="submit"
				>
					<FormField
						label="Amount Authorized"
						name="amount_authorized"
						disabled
					/>
					{props.payment.authorized && (
						<LocalDateField
							name="authorized_at"
							label="Authorized At"
							value={props.payment.authorized_at}
						/>
					)}
					<FormField
						label="Amount to Capture"
						name="amount_captured"
						info="cannot be more than amount authorized"
						validate={[
							...getStandardFormValidations(),
							...getNumberFormValidations(),
							(value: unknown) => {
								if(isNaN(Number(state.amount_captured))) {
									return {
										status: "error",
										message: "Please enter a valid number"
									};
								}

								if(Number(state.amount_captured) < 0) {
									return {
										status: "error",
										message: "Amount captured cannot be less than or equal to zero"
									};
								}

								if(Number(state.amount_captured) > Number(state.amount_authorized)) {
									return {
										status: "error",
										message: "Amount captured cannot be greater than amount authorized"
									};
								}
							}
						]}
					>
					</FormField>
					<Box flex direction="row" justify="between">
						<Button
							label="Cancel"
							onClick={props.onClose}
						/>
						<Button
							primary
							type="submit"
							label="Capture"
							disabled={state.isSubmitting}
							icon={state.isSubmitting ? <Spinner /> : undefined}
						/>
					</Box>
				</Form>
			</Box>
		</Layer>
	);
};
