import { LinearProgress } from "@mui/material";
import { Customer, Delivery, DeliveryAddress, DeliveryType, DeliveryWindow, DTO, Product, Purchase, Timezone } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, Calendar, CheckBox, ColumnConfig, DataTable, Form, FormField, Grid, Select, Spinner, Text, ThemeContext } from "grommet";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductService } from "../../../app/services";
import { DeliveryService } from "../../../app/services/delivery";
import { PurchaseService } from "../../../app/services/purchase";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { addSelectedProductForDelivery, fetchPurchase, listPurchases, removeSelectedProductForDelivery, selectActiveDeliveryProducts, selectCompletedPurchases } from "../../../app/store/admin";
import { getOrFetchVehicles, selectVehicles } from "../../../app/store/reference";
import { formatCurrency, getStandardFormValidations, parseTimestampFromUTC } from "../../../helpers";
import { UnionProduct } from "../../../types";
import { ElevatedCard, Loader, MapComponent, MapMarker, PickupWindow, SlimHeading, useScreenSize } from "../../common";
import { ElevatedCardHeader } from "../../partner/components";
import { purchasePickupWindows } from "../../shop";
import { AddressSummary, ProductSummary, PurchaseDeliveries, PurchaseDetails, PurchaseProducts } from "../components";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code;

const recentPurchaseColumns: ColumnConfig<DTO<Purchase>>[] = [
	{
		property: "number",
		header: "Purchase Number",
		primary: true,
		sortable: true,
		render: (purchase) => purchase.number
	},
	{
		property: "paid_at",
		header: "Created At",
		primary: false,
		sortable: true,
		render: (purchase) => parseTimestampFromUTC(purchase.paid_at, timezone)
	},
	{
		property: "amount",
		header: "Purchase Total",
		primary: false,
		sortable: true,
		render: (purchase) => formatCurrency(purchase.payment?.amount ?? 0)
	},
	{
		property: "market.code",
		header: "Market",
		primary: false,
		sortable: true,
		render: (purchase) => purchase.market.code
	},
	{
		property: "customer.last_name",
		header: "Customer Last Name",
		primary: false,
		render: (purchase) => purchase.customer?.last_name ?? ""
	},
	{
		property: "customer.first_name",
		header: "Customer First Name",
		primary: false,
		render: (purchase) => purchase.customer?.first_name ?? ""
	},
	{
		property: "status",
		header: "Status",
		primary: false,
		render: (purchase) => {
			if((purchase.deliveries ?? []).every(d => d.completed)) {
				return "Completed";
			}

			return "In Progress";
		}
	}
];


interface PurchaseScreenState {
	isLoading: boolean;
	loadedPurchases: boolean;
	isModalVisible: boolean;
	showPurchase: boolean;
	activePurchase?: DTO<Purchase>;
}

export const AdminPurchasesPage: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [ state, setState ] = useState<PurchaseScreenState>({
		isLoading: false,
		isModalVisible: false,
		showPurchase: false,
		loadedPurchases: false
	});

	const purchases = useAppSelector(selectCompletedPurchases);

	function handleNavigate(purchase: DTO<Purchase>): void {
		dispatch(push(`/admin/dashboard/purchases/${purchase.id}`));
	}

	useEffect(() => {
		if(!state.isLoading) {
			setState({ ...state, isLoading: true });
		}

		dispatch(listPurchases({ filters: {}, expand: [ "customer", "payment", "market", "deliveries" ] })).unwrap()
			.catch(err => {
				console.error(`Failed to fetch recent purchases`, err);
				snack.enqueueSnackbar("Failed to load recent orders", {
					variant: "error"
				});
			})
			.finally(() => {
				setState(state => {
					return { ...state, loadedPurchases: true };
				});
			});
	}, []);

	useEffect(() => {
		if(state.loadedPurchases) {
			setState({
				...state,
				isLoading: false
			});
		}
	}, [ state.loadedPurchases ]);

	return (
		<Box margin="small" gap="large">
			<Box gap="small">
				<Box>
					<SlimHeading level="3">Recently Purchased</SlimHeading>
					<Box>
						{state.isLoading && (
							<LinearProgress />
						)}
						<DataTable
							columns={recentPurchaseColumns}
							data={purchases}
							step={10}
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

interface PurchaseDetailsState {
	isLoadingPurchase: boolean;
	customer: DTO<Customer> | null;
	purchase: DTO<Purchase> | null;
}

export const PurchaseDetailsPage: React.FC = (props) => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [ state, setState ] = useState<PurchaseDetailsState>({
		isLoadingPurchase: false,
		customer: null,
		purchase: null
	});
	const size = useScreenSize();

	useEffect(() => {
		if(!params.purchaseId) {
			dispatch(push("/admin/dashboard/purchases"));
			return;
		}

		handleFetchPurchase(params.purchaseId);
	}, []);

	useEffect(() => {
		if(state.purchase?.customer) {
			const customer = state.purchase.customer;
			setState((state) => {
				return {
					...state,
					customer
				};
			});
		}
	}, [ state.purchase ]);

	function wrapStateUpdate(changes: Partial<PurchaseDetailsState>) {
		setState(state => {
			return {
				...state,
				...changes
			};
		});
	}

	function handleFetchPurchase(purchaseId: string): void {
		wrapStateUpdate({ isLoadingPurchase: true });

		dispatch(fetchPurchase({ purchaseId, expand: [ "payment", "deliveries", "market", "customer", "intents" ] })).unwrap()
			.then(purchase => {
				console.debug("Loaded purchase", purchase);
				wrapStateUpdate({ purchase });
			})
			.catch(err => {
				console.error("Failed to fetch purchase", err);
				snack.enqueueSnackbar("Failed to get purchase", {
					variant: "error"
				});
			})
			.finally(() => {
				wrapStateUpdate({ isLoadingPurchase: false });
			});
	}

	async function handleUpdatePurchaseAddress(changes: Partial<DeliveryAddress>): Promise<void> {
		if(!state.purchase) {
			return;
		}

		const updated = await PurchaseService.updatePurchase(state.purchase.id, {
			address: changes as DeliveryAddress
		}).catch(err => {
			console.error("Failed to update purchase", err);
			snack.enqueueSnackbar("Failed to update purchase address", {
				variant: "error"
			});
			return null;
		});

		if(updated) {
			setState({
				...state,
				purchase: updated
			});
		}
	}

	return (
		<Loader visible={state.isLoadingPurchase}>
			{state.purchase && (
				<Box margin="small" gap="small">
					<Grid columns={{ count: size === "small" ? 1 : 2, size: "flex" }} gap="small">
						<ElevatedCard
							header={
								<ElevatedCardHeader title="Purchase Details" />
							}
						>
							<PurchaseDetails purchase={state.purchase} customer={state.customer} />
						</ElevatedCard>
						<ElevatedCard
							header={
								<ElevatedCardHeader title="Buyer Address" />
							}
						>
							<AddressSummary
								address={(state.purchase.address ?? {}) as DeliveryAddress}
								forceMinimumMapHeight={200}
								onUpdateOrderAddress={handleUpdatePurchaseAddress}
							/>
						</ElevatedCard>
						<ElevatedCard
							header={
								<ElevatedCardHeader title="Purchased Products" />
							}
						>
							<PurchaseProducts intents={state.purchase.intents} />
						</ElevatedCard>
						<ElevatedCard
							header={
								<ElevatedCardHeader
									title="Purchase Deliveries"
									action={
										<Button
											primary
											label="Create Delivery"
											onClick={() => {
												if(!state.purchase) {
													return;
												}

												dispatch(push(`/admin/dashboard/purchases/${state.purchase.id}/delivery`));
											}}
										/>
									}
								/>
							}
						>
							<PurchaseDeliveries
								purchase={state.purchase}
							/>
						</ElevatedCard>
					</Grid>
				</Box>
			)}
		</Loader >
	);
};


export const CreatePurchaseDeliveryPage: React.FC = (props) => {
	const snack = useSnackbar();
	const params = useParams();
	const dispatch = useAppDispatch();
	const vehicles = useAppSelector(selectVehicles);
	const [ wasLoaded, setWasLoaded ] = useState(false);
	const [ wasPurchaseLoaded, setWasPurchaseLoaded ] = useState(false);
	const [ wasDeliveryLoaded, setWasDeliveryLoaded ] = useState(false);

	const [ purchase, setPurchase ] = useState<DTO<Purchase> | null>(null);
	const [ deliveries, setDeliveries ] = useState<DTO<Delivery>[]>([]);

	const selectedProducts = useAppSelector(selectActiveDeliveryProducts);

	const markers = useMemo((): MapMarker[] => {
		if(!purchase) {
			return [];
		}

		const productMarkers = selectedProducts.map(product => {
			if(product.order?.address) {
				const address = product.order.address;
				return {
					latitude: Number(address.latitude),
					longitude: Number(address.longitude)
				};
			}

			return null;
		}).filter(v => !!v) as MapMarker[];

		return [
			...productMarkers,
			{
				latitude: Number(purchase.address?.latitude),
				longitude: Number(purchase.address?.longitude),
				label: "Buyer"
			}
		];
	}, [ selectedProducts, purchase ]);

	function handleFetchPurchase(purchaseId: string): void {
		dispatch(fetchPurchase({ purchaseId, expand: [ "payment", "deliveries", "market", "customer", "intents" ] })).unwrap()
			.then(purchase => {
				console.debug("Loaded purchase", purchase);
				setPurchase(purchase);
			})
			.catch(err => {
				console.error("Failed to fetch purchase", err);
				snack.enqueueSnackbar("Failed to get purchase", {
					variant: "error"
				});
			})
			.finally(() => {
				setWasPurchaseLoaded(true);
			});
	}

	function handleFetchDeliveries(): void {
		if(!purchase) {
			return;
		}

		Promise.all((purchase.deliveries.map(delivery => {
			return DeliveryService.getDelivery(delivery.id, [ "products" ]);
		})))
			.then(res => {
				setDeliveries(res);
			})
			.catch(err => {
				console.error("Failed to load deliveries");
				snack.enqueueSnackbar("Error loading deliveries");
			})
			.finally(() => {
				setWasDeliveryLoaded(true);
			});
	}

	useEffect(() => {
		if(!params.purchaseId) {
			dispatch(push("/admin/dashboard/purchases"));
			return;
		}

		handleFetchPurchase(params.purchaseId);
	}, []);

	useEffect(() => {
		if(purchase) {
			handleFetchDeliveries();
		}
	}, [ purchase ]);

	useEffect(() => {
		dispatch(getOrFetchVehicles()).unwrap()
			.catch(err => {
				console.error("Failed to load vehicles", err);
				snack.enqueueSnackbar("Error loading vehicle data", {
					variant: "error"
				});
			});
	}, []);

	interface FormState {
		vehicle: string;
		window: string;
		isLoading: boolean;
		requiresHelper: boolean;
		selectedDate: Date | null;
	}

	const [ formState, setFormState ] = useState<FormState>({
		vehicle: "",
		window: "",
		isLoading: false,
		selectedDate: null,
		requiresHelper: false
	});

	useEffect(() => {
		if(purchase?.delivery_window) {
			const window = purchase.delivery_window;
			setFormState({
				...formState,
				selectedDate: window.date ? moment.tz(window.date, window.timezone).toDate() : null,
				window: purchasePickupWindows.find(w => w.from === window.from && w.to === window.to)?.label ?? ""
			});
		}
	}, [ purchase ]);

	function handleSubmit(): void {
		if(!purchase) {
			return;
		}

		const { window, selectedDate, requiresHelper, vehicle } = formState;
		if(!window || !selectedDate || !vehicle) {
			return;
		}

		const matchedWindow = purchasePickupWindows.find(w => w.label === window);
		if(!matchedWindow) {
			return;
		}

		const matchedVehicle = vehicles.find(v => v.name === vehicle);
		if(!matchedVehicle) {
			return;
		}

		if(!selectedProducts.length) {
			snack.enqueueSnackbar("At least one product needs to be selected", { variant: "warning" });
			return;
		}

		setFormState({
			...formState,
			isLoading: true
		});


		const formattedWindow: DeliveryWindow = {
			date: selectedDate,
			from: matchedWindow.from,
			to: matchedWindow.to,
			timezone
		};

		PurchaseService.createPurchaseDelivery(purchase.id, formattedWindow, selectedProducts, requiresHelper, matchedVehicle)
			.then(delivery => {
				dispatch(push(`/admin/dashboard/purchases/${purchase.id}`));
			})
			.catch(err => {
				console.error(`failed to create delivery`, err);
				snack.enqueueSnackbar("Error creating delivery", { variant: "error" });
			})
			.finally(() => {
				setFormState({
					...formState,
					isLoading: false
				});
			});
	}

	function selectProduct(product: DTO<UnionProduct>): void {
		dispatch(addSelectedProductForDelivery(product));
	}

	function removeProduct(product: DTO<UnionProduct>): void {
		dispatch(removeSelectedProductForDelivery(product));
	}

	useEffect(() => {
		if(wasPurchaseLoaded && wasDeliveryLoaded && !wasLoaded) {
			setWasLoaded(true);
		}
	}, [ wasPurchaseLoaded, wasDeliveryLoaded, wasLoaded ]);

	return (
		<Loader visible={!wasLoaded}>
			<Box>
				<Box flex style={{ minHeight: "400px" }}>
					<MapComponent
						allowFreeZoom
						markers={markers}
						ignoreMaxHeight
						ignoreMargin
						requestCurrentLocation={false}
					/>
				</Box>
				<Grid columns={{ count: 2, size: "auto" }} margin="small" gap="medium">
					<Box>
						<PurchaseProducts
							intents={purchase?.intents ?? []}
							useDefaultOnClick={true}
							renderProducts={(intents) => (
								<Box>
									{intents.map((intent) => {
										const product = intent.product as unknown as DTO<UnionProduct>;
										return (
											<ProductSummary product={intent.product as unknown as DTO<UnionProduct>} permitAI={false}>
												<Box align="end" justify="center" flex="grow">
													<Box margin="small">
														<CheckBox
															label={<Text weight="bold">Select?</Text>}
															disabled={(!!product.service || !!product.delivery || intent.delivery_type === DeliveryType.PICKUP)}
															onClick={(event) => {
																event.stopPropagation();
															}}
															onChange={(event) => {
																return (event.target.checked)
																	? selectProduct(product)
																	: removeProduct(product);
															}}
														/>
													</Box>
												</Box>
											</ProductSummary>
										);
									})}
								</Box>
							)}
						/>
					</Box>
					<Box>
						<Form
							validate="submit"
							onSubmit={handleSubmit}
							value={formState}
							onChange={(updates) => {
								setFormState({
									...formState,
									...updates
								});
							}}
						>
							<Box gap="medium">
								<Box>
									<FormField
										label="Vehicle"
										name="vehicle"
										validate={[
											...getStandardFormValidations()
										]}
									>
										<Select
											name="vehicle"
											options={vehicles.map(v => v.name)}
										/>
									</FormField>
									<FormField
										label="Requires Helper?"
										name="requiresHelper"
										contentProps={{ border: undefined }}
									>
										<CheckBox
											name="requiresHelper"
										/>
									</FormField>
									<ThemeContext.Extend
										value={{
											global: {
												focus: {
													border: {
														color: "transparent",
													},
												},
											},
										}}>
										<Calendar
											style={{ outline: "none !important", boxShadow: "none !important", width: "auto" }}
											margin="small"
											date={formState.selectedDate?.toISOString() ?? undefined}
											showAdjacentDays="trim"
											onSelect={(date) => {
												setFormState({
													...formState,
													selectedDate: new Date(Array.isArray(date) ? date[ 0 ] : date)
												});

											}}
										/>
									</ThemeContext.Extend>
									<FormField
										label="Delivery Window"
									>
										<PickupWindow
											name="window"
											value={formState.window ?? ""}
											windows={purchasePickupWindows}
											onChange={(event) => {
												setFormState(state => {
													return {
														...state,
														window: event.target.value
													};
												});
											}}
										/>
									</FormField>
								</Box>
								<Box align="end" margin="small">
									<Button
										primary
										type="submit"
										label="Create Delivery"
										disabled={formState.isLoading}
										icon={formState.isLoading ? <Spinner /> : undefined}
									/>
								</Box>
							</Box>

						</Form>
					</Box>
				</Grid>
			</Box>
		</Loader>
	);
};