import { Route, Routes } from "react-router-dom";
import { ErrorPage } from "../../common";
import { Checkout } from "../pages";
import { TestPayment } from "../pages/TestPayment";
import { CheckoutConfirmation } from "../pages/CheckoutConfirmation";
import { StripeProvider } from "../provider";

export const CheckoutRouter: React.FC = (props) => {
	return (
		<StripeProvider>
			<Routes>
				<Route path="/" element={<Checkout />} />
				<Route path="/test" element={<TestPayment />} />
				<Route path="/confirmation" element={<CheckoutConfirmation />} />
				<Route element={
					<ErrorPage errorCode={404} />
				} />
			</Routes>
		</StripeProvider>
	);
};