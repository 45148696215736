import { FrontendConfig } from "@rego-app/common";
import { ServerResponse } from "../../types";
import { RequestService } from "./request";

export const ConfigService = {
	async getConfig(): Promise<FrontendConfig> {
		const url = (process.env.NODE_ENV === "development")
			? "/api/v1/config"
			: "/config";

		console.debug(`Fetching config from url [${url}]`);
		const result = await RequestService
			.get<ServerResponse<FrontendConfig>>(url);
		return result.data.result;
	}
};