import { Box, Button, Card, CardBody, CardHeader, Grid } from "grommet";
import { ImageCarousel, Loader, SlimHeading } from "../../common";
import { listCustomerOrders, selectCompletedCustomerOrders, selectOrderedCustomerOrders, useAppDispatch, useAppSelector } from "../../../app/store";
import { DTO, Order, OrderStatus } from "@rego-app/common";
import { selectCustomer } from "../../../app/store/application";
import { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { OrderDetails, ServiceCardContainer } from "../components";

interface OrderCardProps {
	order: DTO<Order>;
}

export const OrderCard: React.FC<OrderCardProps> = (props) => {
	const { address } = props.order;
	const [showOrderDetails, setShowOrderDetails] = useState(false);

	return (
		<Fragment>
			{showOrderDetails && (
				<OrderDetails
					order={props.order}
					onClose={() => {
						setShowOrderDetails(false);
					}}
				/>
			)}
			<Card
				height="medium"
				margin="small"
				hoverIndicator={true}
				onClick={() => {
					setShowOrderDetails(true);
				}}
			>
				<CardHeader flex>
					<ImageCarousel
						width={"100%"}
						height={"100%"}
						product={props.order.products}
					/>
				</CardHeader>
				<CardBody>
					<Box margin="medium" align="center">
						<SlimHeading textAlign="center" level="5">
							{address.address_line_one}
						</SlimHeading>
						{!!address.address_line_two && (
							<SlimHeading textAlign="center" level="5">
								{address.address_line_two}
							</SlimHeading>
						)}
						<SlimHeading textAlign="center" level="5">
							{address.city}, {address.state} {address.zip}
						</SlimHeading>
					</Box>
					<Box align="center" justify="end" margin="small" flex="grow">
						<Button
							primary
							label="View Details"
						/>
					</Box>
				</CardBody>
			</Card>
		</Fragment>
	);
};

export const DashboardHome: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const customer = useAppSelector(selectCustomer);
	const orders = useAppSelector(selectOrderedCustomerOrders);
	const completedOrders = useAppSelector(selectCompletedCustomerOrders);

	const [isLoaded, setIsLoaded] = useState(false);
	const [isViewingAll, setIsViewingAll] = useState(false);
	const [filteredOrders, setFilteredOrders] = useState<DTO<Order>[]>(orders);

	useEffect(() => {
		dispatch(listCustomerOrders()).unwrap()
			.catch(err => {
				console.error("Failed to load customer products", err);
				snack.enqueueSnackbar("We ran into an issue loading your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}, []);

	useEffect(() => {
		setFilteredOrders(isViewingAll
			? [...orders.filter(o => o.status !== OrderStatus.CANCELLED).concat(completedOrders)]
			: [...orders.filter(o => o.status !== OrderStatus.CANCELLED)]
		);
	}, [orders, isViewingAll]);

	function toggleViewAll(): void {
		setIsViewingAll(!isViewingAll);
	}

	return (
		<Loader visible={!isLoaded}>
			<ServiceCardContainer
				title={`${customer?.first_name}'s Dashboard`}
				noItemsMessage="nothing in progress right now"
				largeScreenPageSize={9}
				mediumScreenPageSize={6}
				smallScreenPageSize={4}
				isViewingAll={isViewingAll}
				toggleViewAll={toggleViewAll}
				items={filteredOrders}
				renderItems={(items) => (
					<Grid columns="medium">
						{items.map(order => (
							<OrderCard key={order.id} order={order as unknown as Order} />
						))}
					</Grid>
				)}
			/>
		</Loader>
	);
};