import { Text, TextProps } from "grommet";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

interface CountdownProps extends TextProps {
	to: Date;
	onExpiredCallback(): void;
	listeners?: Array<{ secondsRemaining: number, callback: (timeRemaining: number) => void; }>;
}

export const Countdown: React.FC<CountdownProps> = (props) => {
	const [remaining, setRemaining] = useState<number | null>(null);

	useEffect(() => {
		const timer = setInterval(() => {
			const remaining = moment(props.to).diff(moment(), "seconds");
			if(remaining <= 0) {
				props.onExpiredCallback();
			}

			if(props.listeners) {
				props.listeners.forEach(listener => {
					if(remaining === listener.secondsRemaining) {
						listener.callback(remaining);
					}
				});
			}

			setRemaining(remaining);
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [props.to]);

	const format = useMemo(() => {
		if(!remaining) {
			return "";
		}

		if(remaining <= 0) {
			return "expired";
		}

		const minutes = Math.floor(remaining / 60);
		const seconds = remaining % 60;

		return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
	}, [remaining]);

	return (
		<Text {...props}>
			{format}
		</Text>
	);
};