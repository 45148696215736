import { CustomerProduct, Delivery, DeliveryConfirmation, DeliveryWindow, DTO, Product, ProductIntent, PurchaseDelivery } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, Card, CardBody, CardHeader, Text, Layer, Anchor, Spinner, CardFooter } from "grommet";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import config from "../../../app/config";
import { ProductService } from "../../../app/services";
import { DeliveryService } from "../../../app/services/delivery";
import { listCustomerActionItems, useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCustomer } from "../../../app/store/application";
import { parseDateFromUTC } from "../../../helpers";
import { ImageCarousel, Link, SlimHeading, useAlert, useScreenSize, useTimezone } from "../../common";
import { ProductCard } from "../../shop";

export const ListingDetails: React.FC<{ product: DTO<Product>; onClose: () => void; }> = (props) => {
	const alerts = useAlert();

	function handleNeedToMakeChanges(): void {
		alerts.displayConfirmAlert(
			"Need to make changes?",
			<SlimHeading level="5">Give us a call at <Anchor label={config.contact.phone} href={`tel:${config.contact.phone}`} /> to make any changes.</SlimHeading>
		);
	}

	return (
		<Layer
			onEsc={props.onClose}
			onClickOutside={props.onClose}
			style={{ maxWidth: "350px" }}
		>
			<ProductCard
				product={props.product}
			>
				<Box direction="row" justify="between" align="center">
					<Button
						primary
						label="Make Changes?"
						onClick={handleNeedToMakeChanges}
					/>
					<Button
						color="status-warning"
						label="Close"
						onClick={props.onClose}
					/>
				</Box>
			</ProductCard>
		</Layer>
	);
};

export const CallUsButton: React.FC = (props) => {
	return (
		<Box align="center" justify="center" flex>
			<Button
				primary
				label="Call Us"
				href={`tel:${config.contact.phone}`}
			/>
		</Box>
	);
};

export const AcceptDeliveryButton: React.FC<{ delivery: DTO<Delivery>, onConfirmationUpdated: (confirmation: DTO<DeliveryConfirmation>) => void; }> = (props) => {
	const snack = useSnackbar();
	const [isAccepting, setIsAccepting] = useState(false);
	const customer = useAppSelector(selectCustomer);

	function acceptDelivery(): void {
		setIsAccepting(true);
		DeliveryService.confirmCustomerDelivery(props.delivery.id, customer?.id ?? "")
			.then(res => {
				props.onConfirmationUpdated(res);
			})
			.catch(err => {
				console.error(`failed to accept delivery`, err);
				snack.enqueueSnackbar("We ran into an issue confirming your delivery time", { variant: "error" });
			})
			.finally(() => {
				setIsAccepting(false);
			});
	}

	return (
		<Box align="center" justify="between" direction="row" flex>
			<Button
				label="Call Us"
				href={`tel:${config.contact.phone}`}
			/>
			<Button
				primary
				label="Confirm"
				disabled={isAccepting}
				icon={isAccepting ? <Spinner /> : undefined}
				onClick={acceptDelivery}
			/>
		</Box>
	);
};

interface ListingContentProps {
	title: JSX.Element;
	action: JSX.Element;
}

export const ListingContent: React.FC<ListingContentProps> = (props) => {
	return (
		<Box gap="medium" flex>
			{props.title}
			{props.action}
		</Box>
	);
};

interface ListingContentControllerProps {
	delivery?: DTO<Delivery> | null,
	confirmation?: DTO<DeliveryConfirmation> | null,
	product: DTO<CustomerProduct>;
	onConfirmationUpdated(confirmation: DTO<DeliveryConfirmation>): void;
}

export const ListingContentController: React.FC<ListingContentControllerProps> = (props) => {

	const timezone = useTimezone();
	const dispatch = useAppDispatch();
	const [product, setProduct] = useState(props.product);

	useEffect(() => {
		setProduct(props.product);
	}, [props.product]);

	function printWindowRange(window: DeliveryWindow): JSX.Element {
		return (
			<Text weight="bold">
				{parseDateFromUTC(window.date, timezone)} from {moment().set("hours", window.from).format("hh A")} to {moment().set("hours", window.to).format("hh A")}
			</Text>
		);
	}

	const [title, action] = useMemo((): JSX.Element[] => {
		if(product.disposition === ProductIntent.SELL) {
			if(props.delivery && props.confirmation) {
				if(!props.confirmation.confirmed) {
					return [(
						<Text>
							Congratulations on your furniture sale! Your furniture is scheduled to get picked-up by the delivery team at {printWindowRange(props.delivery.window)}. Please confirm below if this time works for you. If you need a different time, please call or chat with us.
						</Text>
					), (
						<AcceptDeliveryButton
							delivery={props.delivery}
							onConfirmationUpdated={props.onConfirmationUpdated}
						/>
					)];
				}

				if(!props.delivery.accepted) {
					return [(
						<Text>
							You have confirmed a pickup date of {printWindowRange(props.delivery.window)}. We're just making sure this time works for all parties. We'll let you know once anything changes and your pickup date is confirmed.
						</Text>
					), (
						<></>
					)];
				}

				if(!props.delivery.completed) {
					return [(
						<Text>
							Your pickup is scheduled for {printWindowRange(props.delivery.window)}. If this time no longer works for you, please get in touch with us.
						</Text>
					), (
						<CallUsButton />
					)];
				}

				return [(
					<Text>
						Your pickup was completed on <Text weight="bold">{parseDateFromUTC(props.delivery.completed_at, timezone)}.</Text> Have more furniture you want to sell?
					</Text>
				), (
					<Box align="center" justify="center" direction="row">
						<Button
							primary
							onClick={() => {
								dispatch(push("/order"));
							}}
							label="List Your Furniture"
						/>
					</Box>
				)];
			}

			return [(
				<Text>
					Congrats! Your item was purchased. We're still working with the buyer to arrange a delivery time. We'll let you know once anything changes, so keep an eye out for our email and text notifications.
				</Text>
			), (
				<></>
			)];
		}

		if(!product.reviewed) {
			return [(
				<Text>
					Your item is still being reviewed by our team. If approved, your item will be published for sale. Look out for our email and text notifications - we'll let you know when anything changes.
				</Text>
			), (
				<></>
			)];
		}

		return [(
			<Text>
				Your item is currently listed for sale. We'll let you know once we find a buyer and are ready to schedule your pickup.
			</Text>
		), (
			<Box>
				{product.remove_scheduled && (
					<Text>
						If we are unable to find a buyer by <Text weight="bold">{parseDateFromUTC(product.remove_scheduled_for, timezone)}</Text> we will work to find a charitable organization who will accept your item. <Link to="/faq">FAQs</Link>
					</Text>
				)}
			</Box>
		)];
	}, [props.delivery, props.confirmation, product]);

	return (
		<ListingContent
			title={title}
			action={action}
		/>
	);
};

interface ListingCardProps {
	product: DTO<CustomerProduct>;
	onClickOpen(donation: DTO<CustomerProduct>): void;
}

export const ListingCard: React.FC<ListingCardProps> = (props) => {
	const size = useScreenSize();
	const dispatch = useAppDispatch();

	function handleViewMore(): void {
		return props.onClickOpen(props.product);
	}

	const customer = useAppSelector(selectCustomer);
	const [deliveries, setDeliveries] = useState<DTO<PurchaseDelivery>[]>([]);
	const [confirmation, setConfirmation] = useState<DTO<DeliveryConfirmation> | null>(null);

	const productDescription = useMemo(() => {
		return props.product.item?.name ?? props.product.item_input;
	}, [props.product]);

	function fetchProductDeliveries(): void {
		ProductService.getProductDeliveries(props.product.id)
			.then(delivery => {
				setDeliveries(delivery);
			})
			.catch(err => {
				console.error(`failed to load product deliveries`, err);
			});
	}

	function fetchDeliveryConfirmation(): void {
		if(!deliveries || deliveries.length === 0 || !customer) {
			return;
		}

		DeliveryService.getDeliveryConfirmation(
			deliveries[0].id,
			customer.id
		)
			.then(confirmation => {
				setConfirmation(confirmation);
			})
			.catch(err => {
				console.error(`failed to load delivery confirmation`, err);
			});
	}

	function fetchActionItems(): void {
		dispatch(listCustomerActionItems()).catch(err => {
			console.error("Failed to fetch customer action items", err);
		});
	}

	useEffect(() => {
		if(props.product.disposition === ProductIntent.SELL) {
			fetchProductDeliveries();
		}
	}, [props.product]);

	useEffect(() => {
		if(deliveries) {
			fetchDeliveryConfirmation();
		}
	}, [deliveries, props.product]);

	function handleConfirmationUpdated(confirmation: DTO<DeliveryConfirmation>): void {
		setConfirmation({ ...confirmation });
		fetchProductDeliveries();
		fetchActionItems();
	}

	return (
		<Box fill="vertical">
			<Card margin="small" style={{ height: size === "small" ? "550px" : "600px" }}>
				<CardHeader flex style={{ maxHeight: "200px" }}>
					<ImageCarousel
						width={"100%"}
						height={"100%"}
						product={props.product}
					/>
				</CardHeader>
				<CardBody margin="small" gap="small">
					<Box margin="small" gap="small">
						<span>
							<Text weight="bold">
								{productDescription}&nbsp;
							</Text>
							<Text>
								in&nbsp;
							</Text>
							<Text weight="bold">
								{props.product.condition}&nbsp;
							</Text>
							<Text>
								condition
							</Text>
						</span>
					</Box>
					<Box flex margin="small">
						<ListingContentController
							delivery={deliveries?.[0] ?? undefined}
							product={props.product}
							confirmation={confirmation}
							onConfirmationUpdated={handleConfirmationUpdated}
						/>
					</Box>
				</CardBody>
				<CardFooter background="brand">
					<Box margin="medium" align="center">
						<Anchor
							color="white"
							label="View Listing"
							onClick={() => {
								handleViewMore();
							}}
						/>
					</Box>
				</CardFooter>
			</Card>
		</Box>
	);
};