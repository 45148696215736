import { ResponsiveContext } from "grommet";
import { useContext } from "react";
import { ScreenSize } from "../../../types";

export * from "./useCoco";
export * from "./useGoogleTagManager";
export * from "./useTensorflow";
export * from "./useWebcam";
export * from "./useWindowDimensions";

export function useScreenSize(): ScreenSize {
	return useContext(ResponsiveContext) as ScreenSize;
}