import { push } from "connected-react-router";
import { Box, Button } from "grommet";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { logout, selectIsLoggedIn } from "../../../app/store/application";
import { Loader, SlimHeading } from "../../common";

export const Logout: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const isLoggedIn = useAppSelector(selectIsLoggedIn);

	useEffect(() => {
		if(!isLoggedIn) {
			dispatch(push("/"));
			return;
		}

		dispatch(logout());
	}, [isLoggedIn]);

	return (
		<Loader visible={isLoggedIn}>
			<Box margin="large" align="center" gap="medium">
				<Box align="center">
					<SlimHeading level="3">You have been successfully logged out</SlimHeading>
				</Box>
				<Box>
					<Button
						label="Return Home"
						onClick={() => {
							dispatch(push("/"));
						}}
					/>
				</Box>
			</Box>
		</Loader>
	);
};