import type { AccessTokenResponse, Customer, DTO, ServerResponse, User } from "../../types";
import { RequestService } from "./request";
import { UserService } from "./user";
import { UtilService } from "./util";

const USER_ID = "user_id" as const;
const USERNAME = "username" as const;
const REFRESH_TOKEN = "refreshToken" as const;
const ACCESS_TOKEN = "accessToken" as const;
const ACCESS_TOKEN_RESPONSE = "accessTokenResponse" as const;

export const AuthService = {
	async login(username: string, password: string): Promise<DTO<User> | false> {
		try {
			const result = await RequestService.post<ServerResponse<AccessTokenResponse>>("/auth/login", {
				user_id: username,
				secret: password
			});

			this.setAccessTokenResponse(result.data.result);
			const user = await UserService.getSelf();

			this.setUserId(user.id);
			this.setUsername(username);
			return user;
		}
		catch(e) {
			return false;
		}
	},

	async register(customerData: DTO<Customer>, password: string): Promise<DTO<Customer>> {
		try {
			const result = await RequestService.post<ServerResponse<DTO<Customer>>>("/auth/register", {
				...customerData,
				password
			});

			return result.data.result;
		}
		catch(e) {
			throw e;
		}
	},

	async getAnonymousAccessToken(params: URLSearchParams): Promise<DTO<User>> {
		try {
			const result = await RequestService.get<ServerResponse<AccessTokenResponse>>(`/auth/onboarding`, {
				params: UtilService.buildAttribution(params)
			});

			this.setAccessTokenResponse(result.data.result);
			this.setAccessToken(result.data.result.access_token);
			this.setRefreshToken(result.data.result.refresh_token);

			const user = await UserService.getSelf();
			this.setUserId(user.id);
			return user;
		}
		catch(e) {
			throw e;
		}
	},

	async sendLoginCode(username: string): Promise<boolean> {
		const result = await RequestService.put<ServerResponse<boolean>>("/auth/login/code", {
			username
		});
		return result.data.result ?? false;
	},

	async loginWithCode(username: string, code: string): Promise<DTO<User> | false> {
		try {
			const result = await RequestService.post<ServerResponse<AccessTokenResponse>>("/auth/login/code", {
				username,
				code
			});

			this.setAccessTokenResponse(result.data.result);

			const user = await UserService.getSelf();

			this.setUsername(username);
			this.setUserId(user.id);
			return user;
		}
		catch(e) {
			return false;
		}
	},

	async logout(): Promise<void> {
		localStorage.removeItem(ACCESS_TOKEN_RESPONSE);
		localStorage.removeItem(ACCESS_TOKEN);
		localStorage.removeItem(REFRESH_TOKEN);
	},

	setAccessTokenResponse(response: AccessTokenResponse): void {
		localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response));
		this.setAccessToken(response.access_token);
		this.setRefreshToken(response.refresh_token);
	},

	getAccessTokenResponse(): AccessTokenResponse | null {
		try {
			return JSON.parse(localStorage.getItem(ACCESS_TOKEN_RESPONSE) ?? "") ?? null;
		}
		catch(e) {
			return null;
		}
	},

	setUsername(username: string): void {
		localStorage.setItem(USERNAME, username);
	},

	getUsername(): string | null {
		return localStorage.getItem(USERNAME);
	},

	setUserId(userId: string): void {
		localStorage.setItem(USER_ID, userId);
	},

	getUserId(): string | null {
		return localStorage.getItem(USER_ID);
	},

	setAccessToken(token: string): void {
		localStorage.setItem(ACCESS_TOKEN, token);
	},

	getAccessToken(): string | null {
		return localStorage.getItem(ACCESS_TOKEN);
	},

	setRefreshToken(token: string): void {
		localStorage.setItem(REFRESH_TOKEN, token);
	},

	getRefreshToken(): string | null {
		return localStorage.getItem(REFRESH_TOKEN);
	}
};