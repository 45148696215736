import React, { Fragment, useEffect, useMemo } from "react";
import { Anchor, Box, Button, FormField, Image, Menu, ResponsiveContext, Select, Text } from "grommet";
import { Login } from "grommet-icons";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCustomer, selectDemoMode, setRequiresLogin } from "../../../app/store/application";
import { Avatar } from "../../auth";
import { Menu as MenuIcon } from "grommet-icons";
import { push } from "redux-first-history";
import regoLogo from "../../../img/logo.png";
import { selectUserStores } from "../../../app/store/partners";
import { navigateToStoreDashboard } from "../../partner/helpers";
import { SlimHeading } from "../components";
import { useStoreProvider } from "../../partner";
import { useLocation } from "react-router-dom";
import { useWindowDimensions } from "../hooks";
import "./temp.css";

const HEADER_PAGES = [
	{
		label: "Shop",
		path: "/shop"
	},
	{
		label: "Sell",
		path: "/order"
	},
	{
		label: "Donate",
		path: "/order"
	},
	{
		label: "Dashboard",
		path: "/dashboard"
	}
];

export const StoreUserHeader: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const userStores = useAppSelector(selectUserStores);
	const { selectedStore, setSelectedStore } = useStoreProvider();
	const location = useLocation();

	useEffect(() => {
		setSelectedStore({ ...userStores[0] });
	}, [userStores]);

	function handleSelectStore(name: string): void {
		const found = userStores.find(s => s.name === name);
		if(found) {
			setSelectedStore({ ...found });
			dispatch(push(`/partner/store/${found.id}/dashboard`));
		}
	}

	const shouldHide = useMemo(() => {
		return location.pathname.includes("admin") || location.pathname.includes("shop");
	}, [location.pathname]);

	return (
		<Fragment>
			{(userStores.length > 0 && !shouldHide) && (
				<Box background="accent-1" pad="small" direction="row-responsive" flex gap="medium">
					{userStores.length === 1
						? (
							<Box direction="row" flex align="center" margin="small">
								<SlimHeading level="4">
									{userStores[0].name}
								</SlimHeading>
							</Box>
						)
						: (
							<Box direction="row" margin="small" flex>
								<FormField
									width={size === "small" ? "100%" : "large"}
									margin="none"
								>
									<Select
										className="full-width-button"
										style={{ width: "100%" }}
										options={userStores.map(store => store.name)}
										value={selectedStore?.name ?? undefined}
										onChange={(event) => handleSelectStore(event.target.value)}
									/>
								</FormField>
							</Box>
						)}
					<Box margin="small" justify="center">
						<Button
							color="white"
							label="View Store Dashboard"
							onClick={() => {
								selectedStore && navigateToStoreDashboard(selectedStore.id, dispatch);
							}}
						/>
					</Box>
				</Box>
			)}
		</Fragment>
	);
};

export const Header: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const customer = useAppSelector(selectCustomer);
	const isDemoMode = useAppSelector(selectDemoMode);

	return (
		<Box
			id="header"
			tag="header"
		>
			<Box
				direction="row"
				align="center"
				justify="between"
				background="white"
				pad={{ left: "medium", right: "small", vertical: "small" }}
				elevation="medium"
				style={{ zIndex: 1 }}
				{...props}
			>
				<Button
					plain
					label={(
						<Image
							width="200px"
							src={regoLogo}
							onClick={() => {
								dispatch(push("/"));
							}}
						/>
					)}
				/>
				<Box direction="row" gap="small" align="center">
					<ResponsiveContext.Consumer>
						{size =>
							size === 'small' ? (
								<Box justify="end">
									<Menu
										a11yTitle="Navigation Menu"
										dropProps={{ align: { top: 'bottom', right: 'right' } }}
										icon={<MenuIcon color="brand" />}
										items={HEADER_PAGES.map(page => {
											return {
												label: (
													<Box pad="small">
														<Anchor
															label={page.label}
															onClick={() => {
																dispatch(push(page.path));
															}}
														/>
													</Box>
												)
											};
										})}
									/>
								</Box>
							) : (
								<Box justify="end" direction="row" gap="medium">
									{
										HEADER_PAGES.map(page => {
											return (
												<Anchor
													key={page.label}
													label={page.label}
													onClick={(() => {
														dispatch(push(page.path));
													})}
												/>
											);
										})
									}
								</Box>
							)
						}
					</ResponsiveContext.Consumer>
					{customer
						? <Avatar />
						: <Button
							plain
							reverse
							icon={<Login />} disabled={isDemoMode} onClick={() => {
								dispatch(setRequiresLogin(true));
							}}
							label={
								<Text weight="bold">Login</Text>
							}
						/>
					}
				</Box>
			</Box>
			<StoreUserHeader />
		</Box>
	);
};