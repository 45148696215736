import { push } from "connected-react-router";
import { Anchor, Box, Grid, Sidebar } from "grommet";
import { Cart, Deliver, Document, Gift, Group, Lounge, Money, Trash } from "grommet-icons";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../app/store";
import { SidebarButton } from "../../common";
import { DashboardGroup } from "../../dashboard";

interface SidebarButtonData {
	path: string;
	label: string;
	icon: JSX.Element;
}

const buttons: SidebarButtonData[] = [
	{
		path: "/admin/dashboard/customers",
		label: "Customers",
		icon: <Group />
	},
	{
		path: "/admin/dashboard/deliveries",
		label: "Deliveries",
		icon: <Deliver />
	},
	{
		path: "/admin/dashboard/donations",
		label: "Donations",
		icon: <Gift />
	},
	{
		path: "/admin/dashboard/listings",
		label: "Listings",
		icon: <Money />
	},
	{
		path: "/admin/dashboard/orders",
		label: "Orders",
		icon: <Document />
	},
	{
		path: "/admin/dashboard/payments",
		label: "Payments",
		icon: <Money />
	},
	{
		path: "/admin/dashboard/products",
		label: "Products",
		icon: <Lounge />
	},
	{
		path: "/admin/dashboard/purchases",
		label: "Purchases",
		icon: <Cart />
	},
	{
		path: "/admin/dashboard/removals",
		label: "Removals",
		icon: <Trash />
	}
];

export const AdminSidebar: React.FC = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();

	return (
		<Sidebar pad="none" id="sidebar" background="brand">
			<Box id="sidebar-content" pad="small">
				{buttons.map(data => (
					<SidebarButton
						key={data.path}
						icon={data.icon}
						label={data.label}
						selected={data.path === location.pathname}
						onSelected={() => {
							dispatch(push(data.path));
						}}
					/>
				))}
			</Box>
			<Box flex="grow">

			</Box>
		</Sidebar >
	);
};

export const AdminDashboard: React.FC = (props) => {
	return (
		<Box fill="vertical">
			<Grid columns={["small", "flex"]} fill="vertical">
				<AdminSidebar />
				<Outlet />
			</Grid>
		</Box >
	);
};

export const AdminHome: React.FC = (props) => {
	return (
		<Box>
			<DashboardGroup title="Admin Queue">
				<Box margin="medium">
					<Anchor>
						Nothing here ... sweet
					</Anchor>
				</Box>
			</DashboardGroup>
		</Box>
	);
};