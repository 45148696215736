import { Box, BoxProps } from "grommet";

export const Container: React.FC<BoxProps> = (props) => {
	return (
		<Box 
			gap="medium"
			margin={{
				top: "small",
				bottom: "small",
				left: "large",
				right: "large"
			}}
			pad={{
				top: "small",
				bottom: "small",
				left: "large",
				right: "large"
			}}
			{...props}
		>
			{props.children}
		</Box>
	);
};