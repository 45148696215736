import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, Layer } from "grommet";
import { DTO, ProductIntent, Removal, Service } from "@rego-app/common";
import { ServiceCard, ServiceCardContainer } from "../components";
import { useSnackbar } from "notistack";
import { Loader, SlimHeading, useTimezone } from "../../common";
import { listCustomerRemovals, selectActiveCustomerRemovals, selectCustomerRemovals, useAppDispatch, useAppSelector } from "../../../app/store";
import { FormClose } from "grommet-icons";
import { ProductSummary } from "../../admin/components";
import { parseDateFromUTC } from "../../../helpers";

export const RemovalDetails: React.FC<{ removal: DTO<Removal>; onClose: () => void; }> = (props) => {
	return (
		<Layer
			onEsc={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box>
				<Box margin="small">
					<Box align="end" justify="center">
						<Button
							icon={<FormClose size="medium" />}
							hoverIndicator
							onClick={props.onClose}
						/>
					</Box>
					<Box align="center">
						<SlimHeading level="3">Item(s) for Removal</SlimHeading>
						{(props.removal.products ?? []).map(product => (
							<ProductSummary
								permitAI={false}
								key={product.id}
								product={product}
								hideChipContent
							/>
						))}
					</Box>
				</Box>
			</Box>
		</Layer>
	);
};

export const DashboardRemovals: React.FC = (props) => {
	const snack = useSnackbar();
	const timezone = useTimezone();
	const dispatch = useAppDispatch();

	const removals = useAppSelector(selectCustomerRemovals);
	const activeRemovals = useAppSelector(selectActiveCustomerRemovals);

	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ isViewingAll, setIsViewingAll ] = useState(false);
	const [ displayedRemovals, setDisplayedRemovals ] = useState<DTO<Removal>[]>([]);
	const [ selectedRemoval, setSelectedRemoval ] = useState<DTO<Removal> | null>(null);
	const [ isDetailsModalOpen, setDetailsModalOpen ] = useState(false);

	function handleViewRemoval(removal: DTO<Removal>): void {
		setSelectedRemoval(removal);
		setDetailsModalOpen(true);
	}

	function toggleViewAll(): void {
		setIsViewingAll(!isViewingAll);
	}

	useEffect(() => {
		dispatch(listCustomerRemovals({})).unwrap()
			.catch(err => {
				console.error("Failed to load customer removals", err);
				snack.enqueueSnackbar("We ran into an issue loading your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}, []);

	useEffect(() => {
		setDisplayedRemovals(
			(isViewingAll)
				? removals
				: activeRemovals
		);
	}, [ removals, isViewingAll ]);

	const renderItems = useCallback((items: DTO<Removal>[]) => (
		<Grid columns="medium">
			{items.map(removal => (
				<ServiceCard
					intent={ProductIntent.JUNK}
					service={removal as unknown as DTO<Service>}
					serviceLabel="donation"
					onClickOpen={handleViewRemoval}
					contentCompleted={`Your junk removal was completed on ${parseDateFromUTC(removal.completed_at, timezone)}.`}
					contentInProgress={"Your junk removal is in progress."}
					contentReadyToSchedule={"Your junk removal is ready to be scheduled. Click below to pick a time for your item(s) to be picked up"}
					contentScheduled={"Your junk removal is scheduled. If you need to make any changes give us a call. Please note, if your pickup is cancelled within 24 hours of the scheduled date a cancellation fee of 50% will be applied"}
				/>
			))}
		</Grid>
	), [ isViewingAll, displayedRemovals ]);

	return (
		<Loader visible={!isLoaded}>
			{isDetailsModalOpen && selectedRemoval && (
				<RemovalDetails
					removal={selectedRemoval}
					onClose={() => {
						setDetailsModalOpen(false);
					}}
				/>
			)}
			<ServiceCardContainer
				title="Your Removals"
				noItemsMessage="nothing in progress right now"
				largeScreenPageSize={9}
				mediumScreenPageSize={6}
				smallScreenPageSize={4}
				isViewingAll={isViewingAll}
				toggleViewAll={toggleViewAll}
				items={displayedRemovals}
				renderItems={renderItems}
			/>
		</Loader>
	);
};