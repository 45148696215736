import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import config from "../../../app/config";

export const useGoogleTagManager = () => {
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		const tagManagerArgs = {
			gtmId: config.google.tagManager.GTM
		};

		TagManager.initialize(tagManagerArgs);
		setIsReady(true);
	}, []);

	return {
		isReady
	};
};