import { CustomerProduct, Product, ProductIntent, ProductMedia, ProductMediaContext } from "@rego-app/common";
import { Box, Button, Card, CardBody, CardFooter, CardHeader, CheckBox, Form, FormField, Grid, Image, Layer, Select, Spinner, Text, TextArea } from "grommet";
import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSteps } from "react-step-builder";
import { fileToMedia, mediaToFile } from "../../../helpers";
import { DTO } from "../../../types";
import { FormProgressButtons } from "./FormProgressButtons";
import { ImageCarousel, SlimHeading } from "../../common/components";
import { DropzoneRootProps, useDropzone } from "react-dropzone";
import placeholder from "../../../img/placeholder.jpg";
import { ProductService } from "../../../app/services";
import { Camera, FormClose } from "grommet-icons";
import { useWindowDimensions } from "../../common";
import { useAppSelector } from "../../../app/store";
import { selectForcedIntent, selectOrderIntent } from "../../../app/store/order";

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '10px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out'
};

const focusedStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

interface ProductImageDropZoneProps {
	onFilesAccepted(files: File[]): Promise<void>;
}

export const ProductImageDropZone: React.FC<ProductImageDropZoneProps> = (props) => {
	const [isUploading, setIsUploading] = useState(false);

	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
		open
	} = useDropzone({ accept: ["image/png", "image/jpg", "image/jpeg", "video/mp4", "video/mpeg", "video/mpg"] });

	const style = useMemo((): DropzoneRootProps => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isFocused,
		isDragAccept,
		isDragReject
	]);

	useEffect(() => {
		if(acceptedFiles.length > 0) {
			setIsUploading(true);
			props.onFilesAccepted(acceptedFiles)
				.catch(err => {
					console.error("Failed during upload", err);
				})
				.finally(() => {
					setIsUploading(false);
					acceptedFiles.splice(0, acceptedFiles.length);
				});
		}
	}, [acceptedFiles]);

	return (
		<div className="container">
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} />
				<p>Drag 'n' drop some files here, or click to select files</p>
			</div>
			{/* <Grid columns={{ count: 2, size: "auto" }}>
				<Button
					primary
					icon={isUploading ? <Spinner /> : <FormAdd />}
					disabled={isUploading}
					label="Upload Front"
					onClick={open}
				/>
				<Button
					primary
					icon={isUploading ? <Spinner /> : <FormAdd />}
					disabled={isUploading}
					label="Upload Back"
					onClick={open}
				/>
				<Button
					primary
					icon={isUploading ? <Spinner /> : <FormAdd />}
					disabled={isUploading}
					label="Upload Left"
					onClick={open}
				/>
				<Button
					primary
					icon={isUploading ? <Spinner /> : <FormAdd />}
					disabled={isUploading}
					label="Upload Right"
					onClick={open}
				/>
				<Button
					primary
					icon={isUploading ? <Spinner /> : <FormAdd />}
					disabled={isUploading}
					label="Upload Tag"
					onClick={open}
				/>
				<Button
					primary
					icon={isUploading ? <Spinner /> : <FormAdd />}
					disabled={isUploading}
					label="Upload Damages"
					onClick={open}
				/>
			</Grid> */}
			<Box align="center" margin="small">

			</Box>
		</div>
	);
};

interface MediaDropzoneProps {
	label: string;
	context?: ProductMediaContext;
	product: Partial<DTO<Product>>;
	isValidating: boolean;
	allowSkipUpload?: true;
	onSkipUpload(context: ProductMediaContext, skip: boolean): void;
	onFilesAccepted(files: File[], context: ProductMediaContext): Promise<void>;
	handleDeleteMedia?(media: DTO<ProductMedia>): Promise<boolean>;
}

export const MediaDropzone: React.FC<MediaDropzoneProps> = (props) => {
	const [isUploading, setIsUploading] = useState(false);
	const [isInvalid, setIsInvalid] = useState(false);
	const [cannotAccess, setCannotAccess] = useState(false);

	const [initialIndex, setInitialIndex] = useState(0);


	useEffect(() => {
		if(!(props.product.media ?? []).find(m => m.context === props.context) && props.isValidating && !cannotAccess) {
			setIsInvalid(true);
		}
		else {
			setIsInvalid(false);
		}
	}, [props.product, props.isValidating, cannotAccess]);

	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
		open
	} = useDropzone({ accept: ["image/png", "image/jpg", "image/jpeg", "video/mp4", "video/mpeg", "video/mpg"] });

	const style = useMemo((): DropzoneRootProps => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {}),
		borderColor: (isInvalid) ? "red" : baseStyle.borderColor
	}), [
		isFocused,
		isDragAccept,
		isDragReject,
		isInvalid
	]);

	async function handleDeleteMedia(media: DTO<ProductMedia>): Promise<boolean> {
		if(props.handleDeleteMedia) {
			return props.handleDeleteMedia(media).finally(() => {
				setInitialIndex(0);
			});
		}

		return false;
	}

	useEffect(() => {
		if(acceptedFiles.length > 0) {
			setIsUploading(true);
			props.onFilesAccepted(acceptedFiles, props.context ?? ProductMediaContext.OTHER)
				.catch(err => {
					console.error("Failed during upload", err);
				})
				.finally(() => {
					setIsUploading(false);
					acceptedFiles.splice(0, acceptedFiles.length);

					if(props.product.media?.length) {
						setInitialIndex(props.product.media?.length - 1);
					}
				});
		}
	}, [acceptedFiles]);

	const hasMedia = useMemo(() => {
		if(!props.context) {
			return (props.product.media ?? []).length > 0;
		}

		return (props.product.media ?? []).some(m => m.context === props.context);
	}, [props.product]);

	return (
		<Card height="medium">
			{(hasMedia) && (
				<CardHeader flex>
					<ImageCarousel
						width="100%"
						height="100%"
						product={props.product as DTO<Product>}
						filterMedia={props.context ? (media) => media.filter(m => m.context === props.context) : undefined}
						initial={initialIndex}
						handleDeleteMedia={handleDeleteMedia}
					/>
				</CardHeader>
			)}
			<CardBody>
				<Box margin={hasMedia ? "small" : "medium"} flex gap="xsmall">
					{!cannotAccess && (
						<Box {...getRootProps()} onClick={open} style={{ ...style } as React.CSSProperties}>
							<input {...getInputProps()} />
							<Grid columns={["1/3", "2/3"]} fill="vertical">
								<Box align="center" justify="center" flex>
									{isUploading
										? (
											<Spinner
												size="medium"
											/>
										)
										: (
											<Camera
												size="large"
											/>
										)
									}
								</Box>
								<Box align="center" justify="center">
									<Text style={{ "overflow": "hidden" }}>
										Click here to upload {props.label.toLowerCase()}
									</Text>
								</Box>
							</Grid>
						</Box>
					)}
					<Box>
						{(props.context && props.context !== ProductMediaContext.OTHER && props.allowSkipUpload) && (
							<FormField
								pad={false}
								margin={{ vertical: undefined, horizontal: undefined }}
								contentProps={{ border: undefined, pad: "small" }}
								onChange={event => {
									props.onSkipUpload(props.context ?? ProductMediaContext.OTHER, event.target.checked);
									setCannotAccess(event.target.checked);
								}}
							>
								<CheckBox

									label={<Text weight="bold">I don't have access to this</Text>}
									checked={cannotAccess}
								/>
							</FormField>
						)}
						{cannotAccess && (
							<Text weight="lighter" margin="small">
								For example, if this is a heavy item and it's unsafe to move around to get the right photo
							</Text>
						)}
					</Box>
				</Box>
			</CardBody>
			<CardFooter background="brand" fill="horizontal">
				<Box margin="small" align="center" justify="center" flex>
					<Text weight="bold" textAlign="center">
						{props.label}
					</Text>
				</Box>
			</CardFooter>
		</Card>
	);
};

interface MediaDropzoneContainerProps {
	product: DTO<Product>;
	isValidating: boolean;
	onSkipUpload(context: ProductMediaContext, skip: boolean): void;
	onFilesAccepted(files: File[], context: ProductMediaContext): Promise<void>;
}

export const MediaDropzoneContainer: React.FC<MediaDropzoneContainerProps> = (props) => {
	const dimensions = useWindowDimensions();
	const [columns, setColumns] = useState(2);
	const [hasTag, setHasTag] = useState(props.product.media.some(m => m.context === ProductMediaContext.TAG));
	const [hasDefect, setHasDefect] = useState(props.product.media.some(m => m.context === ProductMediaContext.DEFECT));

	useEffect(() => {
		if(dimensions.width >= 1200) {
			setColumns(3);
			return;
		}

		if(dimensions.width >= 650) {
			setColumns(2);
			return;
		}

		setColumns(1);
		return;
	}, [dimensions.width]);

	return (
		<Box gap="medium">
			<Box>
				<FormField
					contentProps={{ border: undefined }}
				>
					<CheckBox
						label={<Text weight="bold">Do you have access to the tag (or label)?</Text>}
						checked={hasTag}
						onChange={(event) => {
							setHasTag(event.target.checked);
						}}
					/>
				</FormField>
				<FormField
					contentProps={{ border: undefined }}
				>
					<CheckBox
						label={<Text weight="bold">Are there any damages (stains, rips, etc.)?</Text>}
						checked={hasDefect}
						onChange={(event) => {
							setHasDefect(event.target.checked);
						}}
					/>
				</FormField>
			</Box>
			<Grid columns={{ count: columns, size: "auto" }} gap="medium" align="around">
				<MediaDropzone
					label="Front"
					context={ProductMediaContext.FRONT}
					product={props.product}
					isValidating={props.isValidating}
					onSkipUpload={props.onSkipUpload}
					onFilesAccepted={props.onFilesAccepted}
				/>
				<MediaDropzone
					label="Back"
					context={ProductMediaContext.BACK}
					product={props.product}
					isValidating={props.isValidating}
					onSkipUpload={props.onSkipUpload}
					onFilesAccepted={props.onFilesAccepted}
				/>
				<MediaDropzone
					label="Left Side"
					context={ProductMediaContext.LEFT}
					product={props.product}
					isValidating={props.isValidating}
					onSkipUpload={props.onSkipUpload}
					onFilesAccepted={props.onFilesAccepted}
				/>
				<MediaDropzone
					label="Right Side"
					context={ProductMediaContext.RIGHT}
					product={props.product}
					isValidating={props.isValidating}
					onSkipUpload={props.onSkipUpload}
					onFilesAccepted={props.onFilesAccepted}
				/>
				{hasTag && (
					<MediaDropzone
						label="Tag"
						context={ProductMediaContext.TAG}
						product={props.product}
						isValidating={false}
						onSkipUpload={props.onSkipUpload}
						onFilesAccepted={props.onFilesAccepted}
					/>
				)}
				{hasDefect && (
					<MediaDropzone
						label="Damages"
						context={ProductMediaContext.DEFECT}
						product={props.product}
						isValidating={props.isValidating}
						onSkipUpload={props.onSkipUpload}
						onFilesAccepted={props.onFilesAccepted}
					/>
				)}
			</Grid>
		</Box>
	);
};

interface ProductUploadFormProps {
	product: DTO<CustomerProduct>;
	isSaving: boolean;
	onMediaCreated(media: DTO<ProductMedia>[]): Promise<void>;
	onMediaUpdated(media: DTO<ProductMedia>): Promise<void>;
	onMediaDeleted(media: DTO<ProductMedia>): Promise<void>;
}

interface ProductMediaContainerProps {
	product: DTO<CustomerProduct>;
	media: DTO<ProductMedia>[];
	isValidating: boolean;
	onMediaUpdated(media: DTO<ProductMedia>): Promise<void>;
	onMediaDeleted(media: DTO<ProductMedia>): Promise<void>;
}

export const ProductMediaContainer: React.FC<ProductMediaContainerProps> = (props) => {
	const dimensions = useWindowDimensions();
	const [columns, setColumns] = useState(1);

	useEffect(() => {
		if(dimensions.width >= 1200) {
			setColumns(3);
			return;
		}

		if(dimensions.width >= 650) {
			setColumns(2);
			return;
		}

		setColumns(1);
		return;
	}, [dimensions.width]);

	return (
		<Grid columns={{ count: columns, size: "auto" }} gap="medium">
			{props.media.map(media => (
				<ProductMediaCard
					key={media.id ?? media.content_hash}
					media={media}
					product={props.product}
					onMediaDeleted={props.onMediaDeleted}
					onMediaUpdated={props.onMediaUpdated}
					isInvalid={props.isValidating && (!media.context || media.context === ProductMediaContext.OTHER)}
				/>
			))}
		</Grid>
	);
};

interface ProductMediaDescriptionModalProps {
	onClose(): void;
	description: string;
	onSaveAndClose(description: string): Promise<void>;
}

export const ProductMediaDescriptionModal: React.FC<ProductMediaDescriptionModalProps> = (props) => {
	const [isSaving, setIsSaving] = useState(false);
	const [description, setDescription] = useState(props.description ?? "");

	useEffect(() => {
		setDescription(props.description);
	}, [props.description]);

	function handleSaveDescription(): void {
		setIsSaving(true);
		props.onSaveAndClose(description)
			.finally(() => {
				setIsSaving(false);
				props.onClose();
			});
	}

	return (
		<Layer
			onEsc={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box margin="medium" gap="medium">
				<Box>
					<FormField
						label="Image Notes"
						name="description"
						value={description}
						onChange={(event) => {
							setDescription(event.target.value);
						}}
					>
						<TextArea
							name="description"
							cols={25}
							rows={3}
							value={description}
						/>
					</FormField>
				</Box>
				<Box direction="row" justify="between">
					<Button
						label="Cancel"
						onClick={props.onClose}
						color="status-error"
					/>
					<Button
						label="Save"
						primary
						disabled={isSaving}
						icon={isSaving ? <Spinner /> : undefined}
						onClick={handleSaveDescription}
					/>
				</Box>
			</Box>
		</Layer>
	);
};

interface ProductMediaCardProps {
	product: DTO<Product>;
	media: DTO<ProductMedia>;
	isInvalid: boolean;
	onMediaDeleted(media: DTO<ProductMedia>): Promise<void>;
	onMediaUpdated(media: DTO<ProductMedia>): Promise<void>;
}

export const ProductMediaCard: React.FC<ProductMediaCardProps> = (props) => {
	const [src, setSrc] = useState("");
	const [isDeleting, setIsDeleting] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [isUpdatingDescription, setIsUpdatingDescription] = useState(false);
	const [context, setContext] = useState<FriendlyContext | null>(props.media.context ? contextToFriendly(props.media.context) : null);

	type FriendlyContext =
		| "Front"
		| "Back"
		| "Left Side"
		| "Right Side"
		| "Tag (or label)"
		| "Damages"
		| "Other";

	const contextMapping: Record<FriendlyContext, ProductMediaContext> = {
		Front: ProductMediaContext.FRONT,
		Back: ProductMediaContext.BACK,
		"Left Side": ProductMediaContext.LEFT,
		"Right Side": ProductMediaContext.RIGHT,
		"Tag (or label)": ProductMediaContext.TAG,
		"Damages": ProductMediaContext.DEFECT,
		"Other": ProductMediaContext.OTHER
	} as Record<FriendlyContext, ProductMediaContext>;

	function friendlyToContext(friendly: FriendlyContext): ProductMediaContext {
		return contextMapping[friendly];
	}

	function contextToFriendly(context: ProductMediaContext): FriendlyContext {
		switch(context) {
			case ProductMediaContext.FRONT: {
				return "Front";
			}
			case ProductMediaContext.BACK: {
				return "Back";
			}
			case ProductMediaContext.LEFT: {
				return "Left Side";
			}
			case ProductMediaContext.RIGHT: {
				return "Right Side";
			}
			case ProductMediaContext.DEFECT: {
				return "Damages";
			}
			case ProductMediaContext.TAG: {
				return "Tag (or label)";
			}
			case ProductMediaContext.OTHER: {
				return "Other";
			}
		}
	}

	useEffect(() => {
		if(context && friendlyToContext(context) !== props.media.context) {
			setIsUpdating(true);
			props.onMediaUpdated({
				...props.media,
				context: friendlyToContext(context)
			})
				.finally(() => {
					setIsUpdating(false);
				});
		}
	}, [context]);

	useEffect(() => {
		if(!props.media.content) {
			//Need to get content from API
			ProductService.getMediaURL(
				props.product.id,
				props.media.id
			)
				.then(content => {
					setSrc(content);
				})
				.catch(err => {
					console.error("Failed to load product media content", props.media, err);
					setSrc(placeholder);
				});
		}
		else {
			const file = mediaToFile(props.media);
			setSrc(URL.createObjectURL(file));
		}
	}, [props.media]);

	function deleteMedia(): void {
		setIsDeleting(true);
		props.onMediaDeleted(props.media)
			.catch(err => {
				console.error("Failed to delete media", err);
			})
			.finally(() => {
				setIsDeleting(false);
			});
	}

	function handleSaveDescription(description: string): Promise<void> {
		setIsUpdating(true);
		return props.onMediaUpdated({
			...props.media,
			description: description
		})
			.catch(err => {
				console.error("Failed to update media description", err);
			})
			.finally(() => {
				setIsUpdating(false);
			});
	}

	return (
		<Card>
			{isUpdatingDescription && (
				<ProductMediaDescriptionModal
					description={props.media.description}
					onClose={() => {
						setIsUpdatingDescription(false);
					}}
					onSaveAndClose={handleSaveDescription}
				/>
			)}
			<CardHeader flex style={{ minHeight: "200px" }}>
				<Box align="center" justify="center" fill>
					{(!src)
						? (
							<Spinner
								size="medium"
							/>
						)
						: (
							<Image
								fit="cover"
								height="100%"
								width="100%"
								src={src}
							/>
						)}
				</Box>
			</CardHeader>
			<Box gap="small" margin="small">
				<Box align="center" justify="center">
					<FormField
						label="Image Type"
						name={props.media.content_hash + "-type"}
						error={props.isInvalid ? "This field is required" : undefined}
					>
						<Select
							name={props.media.content_hash + "-type"}
							icon={isUpdating ? <Spinner /> : undefined}
							disabled={isUpdating}
							value={context ?? ""}
							options={Object.keys(contextMapping).filter(o => o !== "Other")}
							onChange={(event) => {
								setContext(event.target.value);
							}}

						/>
					</FormField>
				</Box>
				<Box direction="row" gap="small" justify="between">
					<Box>
						<Button
							icon={isDeleting ? <Spinner /> : <FormClose />}
							disabled={isDeleting}
							label="Delete"
							primary
							color="status-error"
							onClick={deleteMedia}
						/>
					</Box>
					<Box>
						<Button
							label="Add Notes"
							primary
							onClick={() => {
								setIsUpdatingDescription(true);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Card>
	);
};

interface SimpleImageUploadProps {
	product: DTO<Product>;
	isValidating: boolean;
	onSkipUpload(context: ProductMediaContext, skip: boolean): void;
	onFilesAccepted(files: File[], context: ProductMediaContext): Promise<void>;
}

export const SimpleImageUpload: React.FC<SimpleImageUploadProps> = (props) => {
	return (
		<Box align="center" flex>
			<Box gap="small" style={{ maxWidth: "600px" }}>
				<Box align="center">
					<SlimHeading level="3">
						Image Upload
					</SlimHeading>
				</Box>
				<SlimHeading level="4">
					Please upload at least one image of your item
				</SlimHeading>
				<MediaDropzone
					label="Item Image"
					product={props.product}
					isValidating={props.isValidating}
					onSkipUpload={props.onSkipUpload}
					onFilesAccepted={props.onFilesAccepted}
				/>
			</Box>
		</Box>
	);
};

type UploadSkipState = Record<ProductMediaContext, boolean>;

export const ProductUploadForm: React.FC<ProductUploadFormProps> = (props) => {
	const snack = useSnackbar();
	const { next } = useSteps();
	const [isLoading, setIsLoading] = useState(false);
	const [isValidating, setIsValidating] = useState(false);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const intent = useAppSelector(selectOrderIntent);
	const forcedIntent = useAppSelector(selectForcedIntent);

	const [uploadSkipState, setUploadSkipState] = useState<UploadSkipState>({
		[ProductMediaContext.FRONT]: false,
		[ProductMediaContext.BACK]: false,
		[ProductMediaContext.LEFT]: false,
		[ProductMediaContext.RIGHT]: false,
		[ProductMediaContext.TAG]: false,
		[ProductMediaContext.DEFECT]: false,
		[ProductMediaContext.OTHER]: false
	});

	const isSalesFlow = useMemo(() => {
		return (forcedIntent ?? props.product.intent) !== ProductIntent.JUNK;
	}, [forcedIntent, props.product, props.product.intent]);

	function addMedia(input: DTO<ProductMedia>[]): Promise<void> {
		return props.onMediaCreated(input);
	}

	function onSkipUpload(context: ProductMediaContext, skip: boolean): void {
		setUploadSkipState({
			...uploadSkipState,
			[context]: skip
		});
	}

	function handleFileUpload(files: File[], context: ProductMediaContext): Promise<void> {
		setIsLoading(true);
		return Promise.all(
			files.map(file => {
				return fileToMedia<DTO<ProductMedia>>(file, context);
			})
		).then(media => {
			return addMedia(media);
		})
			.then((res) => {
				console.debug("Upload complete!", res);
			})
			.catch(err => {
				console.error("Failed to upload file", err);
				snack.enqueueSnackbar("Failed to upload file", {
					variant: "error"
				});
			}).finally(() => {
				setIsLoading(false);
			});
	}

	return (
		<Form
			onSubmit={() => {
				setIsValidating(true);
				const errors: string[] = [];
				const media = props.product?.media ?? [];

				if(isSalesFlow) {
					if(!media.find(m => m.context === ProductMediaContext.FRONT) && !uploadSkipState[ProductMediaContext.FRONT]) {
						errors.push("Please upload at least one image of the front of your item");
					}

					if(!media.find(m => m.context === ProductMediaContext.BACK) && !uploadSkipState[ProductMediaContext.BACK]) {
						errors.push("Please upload at least one image of the back of your item");
					}

					if(!media.find(m => m.context === ProductMediaContext.LEFT) && !uploadSkipState[ProductMediaContext.LEFT]) {
						errors.push("Please upload at least one image of the left side of your item");
					}

					if(!media.find(m => m.context === ProductMediaContext.RIGHT) && !uploadSkipState[ProductMediaContext.RIGHT]) {
						errors.push("Please upload at least one image of the right side of your item");
					}

					if(media.length <= 0) {
						errors.push("Please upload at least one image of your item");
					}
				}
				else {
					if(media.length <= 0) {
						errors.push("Please upload at least one image of your item");
					}
				}

				setValidationErrors(errors);
				if(errors.length > 0) {
					console.debug("GOT FORM VALIDATION ERRORS", errors);
					return;
				}

				next();
			}}
			validate="submit"
		>
			<Box gap="medium">
				{isSalesFlow && (
					<Fragment>
						<Box gap="small">
							<Text weight="bold">
								Please upload images of your item. We require at least one image for each required item below (you can upload more than one).
							</Text>
							<Grid columns={{ count: 2, size: "auto" }}>
								<Box>
									<Text weight="bold">Required:</Text>
									<ul>
										<li>Front</li>
										<li>Back</li>
										<li>Left Side</li>
										<li>Right Side</li>
									</ul>
								</Box>
								<Box>
									<Text weight="bold">Optional:</Text>
									<ul>
										<li>Tag (or label)</li>
										<li>Damages* <Text weight="bold">(required if present)</Text></li>
									</ul>
								</Box>
							</Grid>
							<Box>
								<small>
									* if your item has any defects or damages (stains, rips, etc.) please include an image of each
								</small>
							</Box>
						</Box>
						<MediaDropzoneContainer
							product={props.product}
							isValidating={isValidating}
							onSkipUpload={onSkipUpload}
							onFilesAccepted={handleFileUpload}
						/>
					</Fragment>
				)}
				{!isSalesFlow && (
					<SimpleImageUpload
						product={props.product}
						isValidating={isValidating}
						onSkipUpload={onSkipUpload}
						onFilesAccepted={handleFileUpload}
					/>
				)}
				{validationErrors.length > 0 && (
					<Box gap="small">
						<Text>
							Please correct the below errors:
						</Text>
						<Box>
							<ul>
								{validationErrors.map(error => (
									<li key={error}>
										<Text color="status-error" key={error}>
											{error}
										</Text>
									</li>
								))}
							</ul>
						</Box>
					</Box>
				)}
				<FormProgressButtons isLoading={props.isSaving || isLoading} />
			</Box>
		</Form>
	);
};