import { DTO, Order, Product } from "../../types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { OrderScreen, ProductUploadType } from "../../types";
import { ProductIntent } from "../../types";
import { buildCustomerProduct, CustomerService, OrderService, PartnerService, ProductService } from "../services";
import { Customer, CustomerProduct, DeliveryAddress, InboundReferral, OrderStatus, Partner, Referral, ReferralDirection, Store } from "@rego-app/common";
import { v4 as uuid } from "uuid";
import { listCustomerOrders, selectCustomerOrders } from "./dashboard";
import { isBuildingPartner, isStorePartner } from "../../helpers";

export interface OrderState {
	order: DTO<Order> | null;
	activeProduct: DTO<CustomerProduct> | null;
	referral: DTO<Referral> | null;
	activeStore: DTO<Store> | null;
	activePartner: DTO<Partner> | null;
	initialIntent: ProductIntent | null;
	forcedIntent: ProductIntent | null;
	isOrderPending: boolean;
	pendingOrderProducts: DTO<CustomerProduct>[];
	pendingOrderAddress: DeliveryAddress | null;
	orderGoal: ProductIntent | null;
	orderIntent: ProductIntent | null;
	screen: OrderScreen;
	uploadType: ProductUploadType;
	promptedForCutoffDate: boolean;
	cutoffDate: string | null;
}

const initialState: OrderState = {
	order: null,
	activeProduct: null,
	referral: null,
	activePartner: null,
	activeStore: null,
	isOrderPending: false,
	orderGoal: null,
	orderIntent: null,
	forcedIntent: null,
	initialIntent: null,
	pendingOrderAddress: null,
	pendingOrderProducts: [],
	screen: OrderScreen.PRODUCT,
	uploadType: ProductUploadType.AUTOMATIC,
	promptedForCutoffDate: false,
	cutoffDate: null
};

export const flushDeferredOrderItems = createAsyncThunk<DTO<Order>, void, { state: RootState; }>(
	"order/flushDeferredOrderItems",
	async (_, thunk) => {
		if(!thunk.getState().application.customer) {
			throw new Error("cannot flush deferred order items as no customer is found in state");
		}

		const orderResult = await thunk.dispatch(createOrDeferCustomerOrder(thunk.getState().order.order ?? {})).unwrap();
		console.debug("Created order", orderResult);

		if(thunk.getState().order.pendingOrderProducts) {
			for(const product of thunk.getState().order.pendingOrderProducts) {
				const result = await thunk.dispatch(upsertDeferredProduct(product)).unwrap();
				console.debug("Created product", result);
			}
		}

		thunk.dispatch(setOrderPending(false));
		return await thunk.dispatch(refreshOrder()).unwrap();
	}
);

export const refreshOrder = createAsyncThunk<DTO<Order>, void, { state: RootState; }>(
	"order/refreshOrder",
	async (_, thunk) => {
		const order = thunk.getState().order.order;
		if(!order) {
			throw new Error("Order not found in state");
		}

		const updatedOrder = await OrderService.getOrder(order.id, ["products", "move_details"]);
		thunk.dispatch(setOrder({
			...updatedOrder
		}));

		if(updatedOrder.cutoff_date) {
			//FIXME: should this be typed as a string?
			thunk.dispatch(setCutoffDate(updatedOrder.cutoff_date as unknown as string));
		}

		return updatedOrder;
	}
);

export const createOrDeferCustomerOrder = createAsyncThunk<DTO<Order>, Partial<DTO<Order>>, { state: RootState; }>(
	"order/createOrDeferCustomerOrder",
	async (data, thunk) => {
		const customer = thunk.getState().application.customer;
		if(!customer) {
			const order = {} as DTO<Order>;
			thunk.dispatch(setOrderPending(true));
			thunk.dispatch(setOrder(order));
			return order;
		}

		const existingOrder = thunk.getState().order.order;
		console.debug("Found existing order", existingOrder);

		if(existingOrder?.status === OrderStatus.CREATED) {
			console.debug("Found existing order in state", existingOrder);
			return existingOrder;
		}

		const referral = thunk.getState().order.referral;
		console.debug("Got referral from state", referral);

		const order = await OrderService.createOrder({
			...data,
			customer: customer as Customer,
			referral: (thunk.getState().order.referral ?? undefined) as InboundReferral
		});

		thunk.dispatch(setOrder(order));
		return order;
	}
);

const upsertDeferredProduct = createAsyncThunk<DTO<CustomerProduct>, DTO<CustomerProduct>, { state: RootState; }>(
	"order/upsertDeferredProduct",
	async (data, thunk) => {
		const pendingProducts = thunk.getState().order.pendingOrderProducts ?? [];
		const existing = pendingProducts.find(p => p.id === data.id);
		if(existing) {
			console.debug("Found existing product ... updating pending state", data);

			const product = {
				...existing,
				...data
			};

			thunk.dispatch(setPendingOrderProducts([
				...pendingProducts.filter(p => p.id !== data.id),
				product
			]));

			return product;
		}

		console.debug("Creating new deferred product", data);
		const product: DTO<CustomerProduct> = {
			...buildCustomerProduct(data),
			...data,
			media: data.media ?? [],
			id: uuid()
		};

		thunk.dispatch(setPendingOrderProducts([
			...pendingProducts,
			product
		]));

		return product;
	}
);

const upsertProduct = createAsyncThunk<DTO<CustomerProduct>, DTO<CustomerProduct>, { state: RootState; }>(
	"order/upsertProduct",
	async (data, thunk) => {
		const order = (thunk.getState().order.isOrderPending)
			? await thunk.dispatch(flushDeferredOrderItems()).unwrap()
			: thunk.getState().order.order;

		if(!order) {
			throw new Error("Could not find order in state");
		}

		const customer = thunk.getState().application.customer;
		if(!customer) {
			throw new Error("Could not find customer in state");
		}

		const product = (data.created_at)
			? await ProductService.updateProduct(
				data.id,
				{
					...data,
					media: []
				}
			)
			: await CustomerService.createCustomerProduct(
				customer.id,
				{ ...buildCustomerProduct(data), ...data }
			);

		console.debug(`Got product after upsert`, product);
		await OrderService.addProductToOrder(
			order.id,
			product.id
		);

		if(Array.isArray(data.media)) {
			await Promise.all(data.media.map(media => {
				if(!media.created_at) {
					return ProductService.putProductMedia(
						product.id,
						media
					);
				}
				else {
					return ProductService.updateProductMedia(
						product.id,
						media.id,
						{ ...media }
					);
				}
			}));

			//check for deleted
			const existing = order.products?.filter(product => product.id === data.id).flatMap(product => product.media) ?? [];
			for(const m of existing) {
				if(!data.media.find(compareTo => compareTo.id === m.id)) {
					await ProductService.deleteProductMedia(
						product.id,
						m.id
					);
				}
			}
		}

		const refreshedOrder = await thunk.dispatch(refreshOrder()).unwrap();
		const refreshedProduct = refreshedOrder.products.find(p => p.id === product.id);
		if(!refreshedProduct) {
			console.error(`Product [${product.id}] not found in refreshed order`, refreshedOrder);
			throw new Error(`Could not find product [${product.id}] in order [${refreshedOrder.id}]`);
		}

		return refreshedProduct;
	}
);

export const upsertOrDeferOrderProduct = createAsyncThunk<DTO<CustomerProduct>, DTO<CustomerProduct>, { state: RootState; }>(
	"order/upsertOrDeferOrderProduct",
	async (data, thunk) => {
		console.debug("IN UPSERT ROUTER", thunk.getState(), data);
		return (!thunk.getState().application.customer)
			? await thunk.dispatch(upsertDeferredProduct(data)).unwrap()
			: await thunk.dispatch(upsertProduct(data)).unwrap();
	}
);

const removeDeferredProduct = createAsyncThunk<DTO<CustomerProduct> | null, DTO<CustomerProduct>, { state: RootState; }>(
	"order/removeDeferredProduct",
	async (data, thunk) => {
		const pendingProducts = thunk.getState().order.pendingOrderProducts ?? [];
		const existingIndex = pendingProducts.findIndex(p => p.id === data.id);

		if(existingIndex !== -1) {
			const removed = pendingProducts.splice(existingIndex, 1);
			return removed[0] ?? null;
		}

		console.debug("Could not find existing deferred product", data);
		return null;
	}
);

const removeProduct = createAsyncThunk<DTO<CustomerProduct> | null, DTO<CustomerProduct>, { state: RootState; }>(
	"order/removeProduct",
	async (data, thunk) => {
		const order = thunk.getState().order.order;
		if(!order) {
			throw new Error("Could not find order in state");
		}

		//TODO: Should this also delete??
		const product = await OrderService.removeProductFromOrder(
			order.id,
			data.id,
			false
		);

		await thunk.dispatch(refreshOrder()).unwrap();
		return product;
	}
);

export const removeOrderProduct = createAsyncThunk<DTO<CustomerProduct> | null, DTO<CustomerProduct>, { state: RootState; }>(
	"order/removeOrderProduct",
	async (data, thunk) => {
		return (!thunk.getState().application.customer)
			? thunk.dispatch(removeDeferredProduct(data)).unwrap()
			: thunk.dispatch(removeProduct(data)).unwrap();
	}
);

export const updateOrderAddress = createAsyncThunk<DTO<Order>, { orderId: string, address: DeliveryAddress; }, { state: RootState; }>(
	"order/updateOrderAddress",
	async (data, thunk) => {
		await OrderService.updateOrder(data.orderId, {
			address: data.address
		});

		return await thunk.dispatch(refreshOrder()).unwrap();
	}
);

export const updateOrder = createAsyncThunk<DTO<Order>, { orderId: string, data: Partial<DTO<Order>>; }, { state: RootState; }>(
	"order/updateOrder",
	async (data, thunk) => {
		await OrderService.updateOrder(data.orderId, {
			...data.data
		});

		return await thunk.dispatch(refreshOrder()).unwrap();
	}
);

export const submitOrder = createAsyncThunk<DTO<Order>, string, { state: RootState; }>(
	"order/submitOrder",
	async (orderId, thunk) => {
		await OrderService.submitOrder(orderId);
		return await thunk.dispatch(refreshOrder()).unwrap();
	}
);

export const cancelOrder = createAsyncThunk<DTO<Order>, string, { state: RootState; }>(
	"order/cancelOrder",
	async (orderId, thunk) => {
		const order = await OrderService.cancelOrder(orderId);
		await thunk.dispatch(listCustomerOrders()).unwrap();
		return order;
	}
);

export const createInboundReferral = createAsyncThunk<DTO<InboundReferral>, { partnerId: string, params: URLSearchParams; }, { state: RootState; }>(
	"order/createReferral",
	async (data, thunk) => {
		const referral = await PartnerService.createReferral(data.partnerId, data.params);
		const partner = await PartnerService.getPartner(data.partnerId, ["children", "organization"]);
		referral.partner = partner as unknown as Partner;
		return referral;
	}
);

export const orderSlice = createSlice({
	name: "order",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setOrder(state, action: PayloadAction<DTO<Order>>): void {
			state.order = action.payload;
		},
		setActiveProduct(state, action: PayloadAction<DTO<CustomerProduct> | null>): void {
			state.activeProduct = action.payload;
		},
		setOrderPending(state, action: PayloadAction<boolean>): void {
			state.isOrderPending = action.payload;
		},
		setOrderIntent(state, action: PayloadAction<ProductIntent>): void {
			state.orderGoal = action.payload;
			state.orderIntent = action.payload === ProductIntent.JUNK
				? ProductIntent.JUNK
				: ProductIntent.SELL;
		},
		setOrderGoal(state, action: PayloadAction<ProductIntent>): void {
			state.orderGoal = action.payload;
		},
		setPendingOrderAddress(state, action: PayloadAction<DeliveryAddress>): void {
			state.pendingOrderAddress = action.payload;
		},
		setPendingOrderProducts(state, action: PayloadAction<DTO<CustomerProduct>[]>): void {
			state.pendingOrderProducts = action.payload;
		},
		updateProductUploadType(state, action: PayloadAction<ProductUploadType>): void {
			state.uploadType = action.payload;
		},
		resetOrderState(state, action: PayloadAction): void {
			state.order = null;
			state.pendingOrderAddress = null;
			state.isOrderPending = false;
			state.pendingOrderProducts = [];
		},
		setForcedIntent(state, action: PayloadAction<ProductIntent>): void {
			state.forcedIntent = action.payload;
		},
		setInitialIntent(state, action: PayloadAction<ProductIntent>): void {
			state.initialIntent = action.payload;
		},
		setPromptedForCutoffDate(state, action: PayloadAction<boolean>): void {
			state.promptedForCutoffDate = action.payload;
		},
		setCutoffDate(state, action: PayloadAction<string | null>): void {
			state.cutoffDate = action.payload;
		},
		setInboundReferral(state, action: PayloadAction<DTO<InboundReferral>>): void {
			state.referral = action.payload;
			if(action.payload.partner && isStorePartner(action.payload.partner)) {
				state.activeStore = action.payload.partner;
				state.activePartner = action.payload.partner;
				state.forcedIntent = action.payload.partner.service;
			}

			if(action.payload.partner && isBuildingPartner(action.payload.partner)) {
				state.activePartner = action.payload.partner;
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(createOrDeferCustomerOrder.fulfilled, (state, action: PayloadAction<DTO<Order>>) => {
			state.order = action.payload;
		});
		builder.addCase(submitOrder.fulfilled, (state, action: PayloadAction<DTO<Order>>) => {
			state.order = action.payload;
		});
		builder.addCase(updateOrderAddress.fulfilled, (state, action: PayloadAction<DTO<Order>>) => {
			state.order = action.payload;
		});
		builder.addCase(createInboundReferral.fulfilled, (state, action) => {
			state.referral = action.payload;
			state.activePartner = action.payload.partner;
			state.activeStore = isStorePartner(action.payload.partner) ? action.payload.partner : null;

			if(state.activeStore) {
				state.forcedIntent = state.activeStore.service;
			}
		});
	}
});

export const {
	setOrder,
	setOrderPending,
	setOrderIntent,
	setPendingOrderAddress,
	setPendingOrderProducts,
	setForcedIntent,
	setInitialIntent,
	setActiveProduct,
	resetOrderState,
	setCutoffDate,
	setPromptedForCutoffDate,
	setInboundReferral
} = orderSlice.actions;

export const selectOrder = (state: RootState): DTO<Order> | null => state.order.order;
export const selectActiveProduct = (state: RootState): DTO<CustomerProduct> | null => state.order.activeProduct;
export const selectReferral = (state: RootState): DTO<Referral> | null => state.order.referral;
export const selectInitialIntent = (state: RootState): ProductIntent | null => state.order.initialIntent;
export const selectForcedIntent = (state: RootState): ProductIntent | null => state.order.forcedIntent;
export const selectActivePartner = (state: RootState): DTO<Partner> | null => state.order.activePartner;
export const selectActiveStore = (state: RootState): DTO<Store> | null => state.order.activeStore;

export const selectIsOrderPending = (state: RootState): boolean => state.order.isOrderPending;
export const selectPendingOrderProducts = (state: RootState): DTO<Product>[] => state.order.pendingOrderProducts;
export const selectPendingOrderAddress = (state: RootState): DeliveryAddress | null => state.order.pendingOrderAddress;
export const selectOrderIntent = (state: RootState): ProductIntent | null => state.order.orderIntent;
export const selectOrderGoal = (state: RootState): ProductIntent | null => state.order.orderGoal;
export const selectScreen = (state: RootState): OrderScreen => state.order.screen;
export const selectUploadType = (state: RootState): ProductUploadType => state.order.uploadType;

export const selectOrderProducts = (state: RootState): DTO<Product>[] => {
	if(selectIsOrderPending(state)) {
		return selectPendingOrderProducts(state);
	}

	return (selectOrder(state)?.products ?? []) as unknown as DTO<Product>[];
};

export const selectOrderInProgress = (state: RootState): DTO<Order> | null => {
	return selectCustomerOrders(state).find(order => order.status === OrderStatus.CREATED) ?? null;
};

export const selectCutoffDate = (state: RootState): string | null => state.order.cutoffDate;
export const selectPromptedForCutoffDate = (state: RootState): boolean => state.order.promptedForCutoffDate;


export default orderSlice.reducer;