import { Heading, HeadingExtendedProps } from "grommet";
import { Box, LinearProgress } from "@material-ui/core";

export const SlimHeading: React.FC<HeadingExtendedProps> = (props) => {
	return (
		<Heading {...props} margin="none">
			{props.children}
		</Heading>
	);
};

interface StepProgressProps {
	progress: number;
}

export const StepProgress: React.FC<StepProgressProps> = (props) => {
	return (
		<Box sx={{ width: '100%' }}>
			<LinearProgress variant="determinate" value={Math.max(10, props.progress)} />
		</Box>
	);
};