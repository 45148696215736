import { DTO, Purchase, RelationExpand } from "@rego-app/common";
import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCustomer } from "../../../app/store/application";
import { selectPayment } from "../../../app/store/checkout";
import { fetchActivePurchase, fetchPurchase, selectIsPurchaseInProgress, selectPurchase, setIsPurchaseInProgress } from "../../../app/store/purchase";
import { Loader } from "../../common";
import { CheckoutPage } from "../pages";

export const CheckoutController: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const payment = useAppSelector(selectPayment);
	const customer = useAppSelector(selectCustomer);
	const purchase = useAppSelector(selectPurchase);
	const [initialized, setInitialized] = useState(false);
	const isPurchaseInProgress = useAppSelector(selectIsPurchaseInProgress);

	function handleFetchPurchase(purchaseId: string): Promise<DTO<Purchase>> {
		return dispatch(fetchPurchase({ purchaseId, expand: ["intents", "payment", "payment.payment_method"] as RelationExpand<Purchase> })).unwrap();
	}

	function initialize(): void {
		console.debug(`in checkout controller initialize ... got purchase`, purchase);
		if(purchase) {
			if(!isPurchaseInProgress) {
				if(payment) {
					dispatch(push(`/shop/checkout?purchaseId=${purchase.id}&step=REVIEW`));
					return;
				}

				dispatch(push("/shop/cart"));
				return;
			}

			if(purchase.expired) {
				dispatch(setIsPurchaseInProgress(false));
				dispatch(push("/shop/cart"));
				return;
			}

			handleFetchPurchase(purchase.id).then(() => {
				dispatch(setIsPurchaseInProgress(true));
			}).catch(err => {
				console.error(`failed to load purchase`, err);
			}).finally(() => {
				setInitialized(true);
			});
			return;
		}

		const purchaseId = new URLSearchParams(window.location.search).get(
			"purchaseId"
		);

		if(purchaseId) {
			handleFetchPurchase(purchaseId).then(() => {
				dispatch(setIsPurchaseInProgress(true));
			}).catch(err => {
				console.error(`failed to load purchase`, err);
			}).finally(() => {
				setInitialized(true);
			});

			return;
		}

		dispatch(fetchActivePurchase()).unwrap().then(res => {
			if(res) {
				console.debug("Got active purchase");
				dispatch(setIsPurchaseInProgress(true));
				dispatch(push(`/shop/checkout?purchaseId=${res.id}`));
			}
			else {
				//no active purchase found
				dispatch(setIsPurchaseInProgress(false));
				dispatch(push("/shop/cart"));
			}
		}).catch(err => {
			console.error("Failed to fetch active purchase", err);
			dispatch(setIsPurchaseInProgress(false));
			dispatch(push("/shop/cart"));
		}).finally(() => {
			setInitialized(true);
		});
	}

	useEffect(() => {
		initialize();
	}, []);

	return (
		<Loader visible={!initialized}>
			{(customer && purchase) && (
				<CheckoutPage
					customer={customer}
				/>
			)}
		</Loader>
	);
};