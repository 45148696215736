import { LinearProgress } from "@mui/material";
import { DeliveryAddress, Donation, DTO, Estimate, Order, OrderMilestone, OrderNote, OrderStatus, Product, ProductIntent, Store, StoreContact, StoreHours, Timezone, Vehicle } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, CheckBox, ColumnConfig, DataTable, Form, FormField, Grid, Select, Spinner, Text, TextArea, TextInput } from "grommet";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchInboundReferral, fetchOrder, listOrders, listStores, recordOpenedOrder, selectInboundReferral, selectOrderHistory, selectOrders, selectStores } from "../../../app/store/admin";
import { getOrFetchVehicles, selectVehicles } from "../../../app/store/reference";
import { getStandardFormValidations, isStorePartner, parseDateFromUTC, parseTimestampFromUTC } from "../../../helpers";
import { Loader, MapComponent, MapMarker, Modal, pickupWindows, SlimHeading, useScreenSize, useTimezone } from "../../common";
import { AddressSummary, OrderDetails, OrderRemovals, OrderProducts, ProductSummary, StoreHoursList, OrderDonations, orderExpand } from "../components";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code;

const recentOrderColumns: ColumnConfig<DTO<Order>>[] = [
	{
		property: "number",
		header: "Order Number",
		primary: true,
		sortable: true,
		render: (order) => order.number
	},
	{
		property: "ordered_at",
		header: "Submitted At",
		primary: false,
		sortable: true,
		render: (order) => parseTimestampFromUTC(order.ordered_at, timezone)
	},
	{
		property: "cutoff_date",
		header: "Cutoff Date",
		primary: false,
		sortable: true,
		render: (order) => parseDateFromUTC(order.cutoff_date, timezone)
	},
	{
		property: "market_code",
		header: "Market",
		primary: false,
		sortable: true,
		render: (order) => order.market?.code ?? ""
	},
	{
		property: "last_name",
		header: "Customer Last Name",
		primary: false,
		render: (order) => order.customer?.last_name ?? ""
	},
	{
		property: "first_name",
		header: "Customer First Name",
		primary: false,
		render: (order) => order.customer?.first_name ?? ""
	},
	{
		property: "status",
		header: "Status",
		primary: false,
		render: (order) => order?.status ?? ""
	},
	{
		property: "milestone",
		header: "Milestone",
		primary: false,
		render: (order) => order?.milestone ?? ""
	}
];


interface OrderScreenState {
	isLoading: boolean;
	loadedRecentOrders: boolean;
	loadedUrgentOrders: boolean;
	isLoadingUrgentOrders: boolean;
	loadedOrders: boolean;
	isModalVisible: boolean;
	showOrder: boolean;
	activeOrder?: DTO<Order>;
	recentOrders: DTO<Order>[];
}

export const AdminOrdersPage: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [ state, setState ] = useState<OrderScreenState>({
		isLoading: false,
		isLoadingUrgentOrders: false,
		isModalVisible: false,
		showOrder: false,
		loadedOrders: false,
		loadedRecentOrders: false,
		loadedUrgentOrders: false,
		recentOrders: []
	});

	const orders = useAppSelector(selectOrders);
	const recentOrders = useAppSelector(selectOrderHistory);
	const [ urgentOrders, setUrgentOrders ] = useState<DTO<Order>[]>([]);

	function handleNavigate(order: DTO<Order>): void {
		dispatch(recordOpenedOrder(order));
		dispatch(push(`/admin/dashboard/orders/${order.id}`));
	}

	useEffect(() => {
		if(state.loadedOrders && !state.loadedRecentOrders) {
			setState({ ...state, isLoading: true });
			Promise.all(recentOrders.map(record => {
				const existing = orders.find(o => o.id === record.id);
				if(existing) {
					return existing;
				}

				return OrderService.getOrder(record.id, [ "customer", "market" ]);
			}))
				.then(results => {
					setState(state => {
						return { ...state, recentOrders: [ ...results ] };
					});
				})
				.catch(err => {
					console.error(`Failed to fetch recent orders`, err);
					snack.enqueueSnackbar("Failed to load recent orders", {
						variant: "error"
					});
				})
				.finally(() => {
					setState(state => {
						return { ...state, loadedRecentOrders: true };
					});
				});
		}
	}, [ state.loadedOrders, state.loadedRecentOrders ]);

	useEffect(() => {
		if(!state.isLoading) {
			setState({ ...state, isLoading: true });
		}

		const filters = {
			order: {
				ordered_at: "DESC"
			},
			limit: 100,
			status: OrderStatus.ORDERED
		};

		dispatch(listOrders({ filters, expand: [ "customer", "market" ] })).unwrap()
			.catch(err => {
				console.error(`Failed to fetch recent orders`, err);
				snack.enqueueSnackbar("Failed to load recent orders", {
					variant: "error"
				});
			})
			.finally(() => {
				setState(state => {
					return { ...state, loadedOrders: true };
				});
			});
	}, []);

	useEffect(() => {
		setState({ ...state, isLoading: true, isLoadingUrgentOrders: true });

		OrderService.listUrgentOrders()
			.then(urgentOrders => {
				setUrgentOrders([ ...urgentOrders ].filter(order => !!order.cutoff_date));
			})
			.catch(err => {
				console.error(`Failed to fetch urgent orders`, err);
				snack.enqueueSnackbar("Failed to load urgent orders", {
					variant: "error"
				});
			})
			.finally(() => {
				setState(state => {
					return { ...state, loadedUrgentOrders: true, isLoadingUrgentOrders: false };
				});
			});
	}, []);

	useEffect(() => {
		if(state.loadedOrders && state.loadedRecentOrders && state.loadedUrgentOrders) {
			setState({
				...state,
				isLoading: false
			});
		}
	}, [ state.loadedOrders, state.loadedRecentOrders, state.loadedUrgentOrders ]);

	return (
		<Box margin="small" gap="large">
			<Box gap="small">
				<Box>
					<SlimHeading level="3">Recently Viewed</SlimHeading>
					<Box>
						{(state.isLoading || !state.loadedRecentOrders) && (
							<LinearProgress />
						)}
						<DataTable
							columns={recentOrderColumns}
							data={state.recentOrders}
							step={10}
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box>
			<Box gap="small">
				<Box>
					<SlimHeading level="3">Recently Ordered</SlimHeading>
					<Box>
						{(state.isLoading || !state.loadedOrders) && (
							<LinearProgress />
						)}
						<DataTable
							columns={recentOrderColumns}
							data={orders}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box>
			<Box gap="small">
				<Box>
					<SlimHeading level="3">Urgent Orders</SlimHeading>
					<Box>
						{(state.isLoadingUrgentOrders) && (
							<LinearProgress />
						)}
						<DataTable
							columns={recentOrderColumns}
							data={urgentOrders}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const Note: React.FC<{ note: DTO<OrderNote>; }> = (props) => {
	const timezone = useTimezone();

	return (
		<Box border style={{ minHeight: "70px", display: "inline-table" }} flex>
			<Box margin="small" flex>
				<Text>
					{props.note.value}
				</Text>
			</Box>
			<Box pad={{ left: "small" }}>
				<Text size="small">
					<Text weight="bold" size="small">
						{props.note.created_by.first_name} {props.note.created_by.last_name}
					</Text> at <Text weight="bold" size="small">{parseTimestampFromUTC(props.note.created_at, timezone)}</Text>
				</Text>
			</Box>
		</Box>
	);
};

interface OrderNotesModalProps {
	onClose(): void;
	onCreateNote(order: DTO<Order>, note: string): Promise<DTO<Order>>;
	order: DTO<Order>;
}

const OrderNotesModal: React.FC<OrderNotesModalProps> = (props) => {
	const snack = useSnackbar();
	const [ note, setNote ] = useState("");
	const [ order, setOrder ] = useState({ ...props.order });
	const [ isCreating, setIsCreating ] = useState(false);

	const hasNotes = useMemo(() => {
		return (order.notes ?? []).length > 0;
	}, [ order ]);

	function handleAddNote(): void {
		setIsCreating(true);
		props.onCreateNote(props.order, note)
			.then(order => {
				setOrder({ ...order });
			})
			.catch(err => {
				snack.enqueueSnackbar("Failed to create note", { variant: "error" });
				console.error(err);
			})
			.finally(() => {
				setIsCreating(false);
			});
	}

	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
			style={{ minWidth: "600px" }}
		>
			<Box gap="small" margin="medium">
				<Box style={{ minHeight: "400px" }}>
					{!hasNotes
						? (
							<Box align="center" justify="center">
								<Text>no notes yet</Text>
							</Box>
						)
						: (
							<Box gap="small" overflow={{ vertical: "scroll" }}>
								{order.notes.map(note => (
									<Note
										key={note.id}
										note={note}
									/>
								))}
							</Box>
						)}
				</Box>
				<Box style={{ minHeight: "200px" }} flex>
					<FormField
						label="New Note"
						name="notes"
						onChange={(event) => {
							setNote(event.target.value);
						}}
					>
						<TextArea
							name="notes"
							rows={4}
						/>
					</FormField>
					<Button
						label="Add Note"
						primary
						icon={isCreating ? <Spinner /> : undefined}
						disabled={!note || isCreating}
						onClick={handleAddNote}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

interface OrderDetailsState {
	isViewingNotes: boolean;
	isLoadingOrder: boolean;
	isUpdatingOrder: boolean;
	isCancellingOrder: boolean;
	isCompletingOrder: boolean;
	order: DTO<Order> | null;
}

export const OrderDetailsPage: React.FC = (props) => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [ state, setState ] = useState<OrderDetailsState>({
		isLoadingOrder: false,
		isViewingNotes: false,
		isUpdatingOrder: false,
		isCancellingOrder: false,
		isCompletingOrder: false,
		order: null
	});

	const size = useScreenSize();

	useEffect(() => {
		if(!params.orderId) {
			dispatch(push("/admin/dashboard/orders"));
			return;
		}

		handleFetchOrder(params.orderId);
	}, []);

	function wrapStateUpdate(changes: Partial<OrderDetailsState>) {
		setState(state => {
			return {
				...state,
				...changes
			};
		});
	}

	function handleViewNotes(): void {
		wrapStateUpdate({ isViewingNotes: true });
	}

	function handleCancelOrder(): void {
		const order = state.order;
		if(!order) {
			return;
		}

		wrapStateUpdate({ isCancellingOrder: true });

		OrderService.cancelOrder(order.id)
			.then(() => dispatch(fetchOrder({ orderId: order.id, expand: orderExpand })).unwrap())
			.then(order => {
				console.debug("Loaded order", order);
				wrapStateUpdate({ order: order });
			})
			.catch(err => {
				console.error(`failed to cancel order`, err);
				snack.enqueueSnackbar(err?.error?.message ?? "Failed to cancel order", { variant: "error" });
			})
			.finally(() => {
				wrapStateUpdate({ isCancellingOrder: false });
			});
	}

	function handleCompleteOrder(): void {
		const order = state.order;
		if(!order) {
			return;
		}

		wrapStateUpdate({ isCompletingOrder: true });

		OrderService.completeOrder(order.id)
			.then(() => dispatch(fetchOrder({ orderId: order.id, expand: orderExpand })).unwrap())
			.then(order => {
				console.debug("Loaded order", order);
				wrapStateUpdate({ order: order });
			})
			.catch(err => {
				console.error(`failed to complete order`, err);
				snack.enqueueSnackbar(err?.error?.message ?? "Failed to complete order", { variant: "error" });
			})
			.finally(() => {
				wrapStateUpdate({ isCompletingOrder: false });
			});
	}

	function handleFetchOrder(orderId: string): void {
		wrapStateUpdate({ isLoadingOrder: true });

		dispatch(fetchOrder({ orderId, expand: orderExpand })).unwrap()
			.then(order => {
				console.debug("Loaded order", order);
				wrapStateUpdate({ order: order });
			})
			.catch(err => {
				console.error("Failed to fetch order", err);
				snack.enqueueSnackbar("Failed to get order", {
					variant: "error"
				});
			})
			.finally(() => {
				wrapStateUpdate({ isLoadingOrder: false });
			});
	}

	function handleCreateNote(order: DTO<Order>, note: string): Promise<DTO<Order>> {
		return OrderService.createOrderNote(order.id, note)
			.then(() => dispatch(fetchOrder({ orderId: order.id, expand: orderExpand })).unwrap());
	}

	async function handleUpdateOrderAddress(changes: Partial<DeliveryAddress>): Promise<void> {
		if(!state.order) {
			return;
		}

		const updated = await OrderService.updateOrder(state.order.id, {
			address: changes as DeliveryAddress
		}).catch(err => {
			console.error("Failed to update order", err);
			snack.enqueueSnackbar("Failed to update order address", {
				variant: "error"
			});
			return null;
		});

		if(updated) {
			setState({
				...state,
				order: updated
			});
		}
	}

	function handleUpdateMilestone(milestone: OrderMilestone): void {
		const order = state.order;
		if(!order) {
			return;
		}

		wrapStateUpdate({ isUpdatingOrder: true });

		OrderService.updateOrderMilestone(order.id, milestone)
			.then(() => dispatch(fetchOrder({ orderId: order.id, expand: orderExpand })).unwrap())
			.then(order => {
				console.debug("Loaded order", order);
				wrapStateUpdate({ order: order });
			})
			.catch(err => {
				console.error(`failed to update order`, err);
				snack.enqueueSnackbar(err?.error?.message ?? "Failed to update order", { variant: "error" });
			})
			.finally(() => {
				wrapStateUpdate({ isUpdatingOrder: false });
			});
	}

	return (
		<Loader visible={state.isLoadingOrder}>
			{state.order && (
				<Box margin="small" gap="small">
					{state.isViewingNotes && (
						<OrderNotesModal
							order={state.order}
							onClose={() => {
								wrapStateUpdate({ isViewingNotes: false });
							}}
							onCreateNote={handleCreateNote}
						/>
					)}
					<Box margin="small" justify="start" align="center" gap="small" direction="row-responsive">
						<Button
							label="Start Donation"
							disabled={state.order?.status !== OrderStatus.ORDERED}
							onClick={() => {
								if(!state.order) {
									return;
								}

								dispatch(push(`/admin/dashboard/orders/${state.order.id}/donation`));
							}}
						/>
						<Button
							label="Start Removal"
							disabled={state.order?.status !== OrderStatus.ORDERED}
							onClick={() => {
								if(!state.order) {
									return;
								}

								dispatch(push(`/admin/dashboard/orders/${state.order.id}/removal`));
							}}
						/>
						<Button
							label="View Notes"
							onClick={handleViewNotes}
						/>
					</Box>
					<Grid columns={{ count: size === "small" ? 1 : 2, size: "flex" }} gap="small">
						<Box margin="small" gap="small">
							<SlimHeading level="3">Order Details</SlimHeading>
							<OrderDetails
								order={state.order}
								onCancelOrder={handleCancelOrder}
								onCompleteOrder={handleCompleteOrder}
								onUpdateMilestone={handleUpdateMilestone}
								isUpdatingOrder={state.isUpdatingOrder}
								isCancellingOrder={state.isCancellingOrder}
								isCompletingOrder={state.isCompletingOrder}
							/>
						</Box>
						<Box margin="small" gap="small">
							<SlimHeading level="3">Order Address</SlimHeading>
							<AddressSummary
								address={state.order.address}
								forceMinimumMapHeight={200}
								onUpdateOrderAddress={handleUpdateOrderAddress}
							/>
						</Box>
						<Box margin="small" gap="small">
							<SlimHeading level="3">Order Products</SlimHeading>
							<OrderProducts
								order={state.order}
								renderProducts={products => (
									<Box>
										{products.map(product => (
											<ProductSummary
												permitAI
												product={product}
												key={product.id}
												overrideOnClick={() => {
													dispatch(push(`/admin/dashboard/products/${product.id}`));
												}}
											/>
										))}
									</Box>
								)}
							/>
						</Box>
						<Box margin="small" gap="small">
							<SlimHeading level="3">Order Donations</SlimHeading>
							<OrderDonations order={state.order} />
						</Box>
						<Box margin="small" gap="small">
							<SlimHeading level="3">Order Removals</SlimHeading>
							<OrderRemovals order={state.order} />
						</Box>
						<Box margin="small" gap="small">
							<SlimHeading level="3">Order Purchases</SlimHeading>
							<Box align="center" justify="center">
								TBD
							</Box>
						</Box>
					</Grid>
				</Box>
			)}
		</Loader >
	);
};

interface CreateOrderDonationState {
	wasLoaded: boolean;
	wasOrderLoaded: boolean;
	wasStoresLoaded: boolean;
	wasReferralLoaded: boolean;
	isLoadingReferral: boolean;
	isLoadingOrder: boolean;
	isCreatingDonation: boolean;
	order: DTO<Order> | null;
	donation: DTO<Donation> | null;
	estimate: DTO<Estimate> | null;
	selectedStore: DTO<Store> | null;
	selectedVehicle: DTO<Vehicle> | null;
	selectedProducts: DTO<Product>[];
	selectedRequiresHelper: boolean;
	selectedPickupWindow?: typeof pickupWindows[ number ];
}

export const CreateOrderDonationScreen: React.FC = (props) => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const stores = useAppSelector(selectStores);
	const snack = useSnackbar();
	const vehicles = useAppSelector(selectVehicles);
	const [ state, setState ] = useState<CreateOrderDonationState>({
		isLoadingOrder: false,
		isLoadingReferral: false,
		isCreatingDonation: false,
		wasStoresLoaded: false,
		wasLoaded: false,
		wasOrderLoaded: false,
		wasReferralLoaded: false,
		order: null,
		donation: null,
		selectedStore: null,
		selectedVehicle: null,
		selectedProducts: [],
		selectedRequiresHelper: false,
		estimate: null
	});
	const inboundReferral = useAppSelector(selectInboundReferral);

	useEffect(() => {
		if(state.donation) {
			dispatch(push(`/admin/dashboard/donations/${state.donation.id}`));
		}
	}, [ state.donation ]);

	useEffect(() => {
		dispatch(getOrFetchVehicles())
			.catch(err => {
				console.error("Failed to load vehicles", err);
				snack.enqueueSnackbar("Error loading vehicle data", {
					variant: "error"
				});
			});
	}, []);

	useEffect(() => {
		if(!state.wasLoaded) {
			if(state.wasStoresLoaded && state.wasOrderLoaded && state.wasReferralLoaded) {
				setState({
					...state,
					wasLoaded: true
				});
			}
		}
	}, [ state.wasStoresLoaded, state.isLoadingOrder ]);

	function loadDonationStores(): void {
		dispatch(listStores({ service: ProductIntent.DONATE })).unwrap()
			.catch(err => {
				console.error("Failed to load stores", err);
				snack.enqueueSnackbar("Failed to load stores", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					wasStoresLoaded: true
				});
			});
	}

	useEffect(() => {
		loadDonationStores();
	}, []);

	useEffect(() => {
		if(!params.orderId) {
			dispatch(push("/admin/dashboard/orders"));
			return;
		}

		handleFetchOrder(params.orderId);
	}, []);

	useEffect(() => {
		if(inboundReferral?.partner && isStorePartner(inboundReferral.partner)) {
			wrapStateUpdate({ selectedStore: inboundReferral.partner });
		}
	}, [ inboundReferral ]);

	function wrapStateUpdate(changes: Partial<CreateOrderDonationState>) {
		setState(state => {
			return {
				...state,
				...changes
			};
		});
	}

	function handleFetchOrder(orderId: string): void {
		wrapStateUpdate({ isLoadingOrder: true });

		dispatch(fetchOrder({ orderId, expand: [] })).unwrap()
			.then(order => {
				console.debug("Loaded order", order);
				setState((current) => {
					return {
						...current,
						order
					};
				});

				return dispatch(fetchInboundReferral({ orderId, expand: [ "partner" ] })).unwrap();
			})
			.then(res => {
				setState((current) => {
					return {
						...current,
						wasReferralLoaded: true
					};
				});
			})
			.catch(err => {
				console.error("Failed to fetch order", err);
				snack.enqueueSnackbar("Failed to get order", {
					variant: "error"
				});
			})
			.finally(() => {
				wrapStateUpdate({ isLoadingOrder: false });
			});
	}

	function createDonation(): void {
		if(!state.order || !state.selectedStore || !state.selectedVehicle) {
			return;
		}

		setState({
			...state,
			isCreatingDonation: true
		});

		OrderService.createStoreDonation(
			state.order.id,
			state.selectedStore.id,
			{
				vehicle: state.selectedVehicle,
				requires_helper: state.selectedRequiresHelper,
				products: state.selectedProducts.map(p => p.id)
			}
		)
			.then(res => {
				setState({
					...state,
					donation: res
				});
			})
			.catch(err => {
				console.error("Failed to create donation", err);
				snack.enqueueSnackbar("Failed to create donation", err);
			})
			.finally(() => {
				setState({
					...state,
					isCreatingDonation: false
				});
			});
	}

	const markers = useMemo(() => {
		const markers: MapMarker[] = [];

		if(state.order) {
			markers.push({
				latitude: Number(state.order.address.latitude),
				longitude: Number(state.order.address.longitude),
				label: "A"
			});
		}

		if(state.selectedStore) {
			markers.push({
				latitude: Number(state.selectedStore.address.latitude),
				longitude: Number(state.selectedStore.address.longitude),
				label: "B"
			});
		}

		return markers;
	}, [ state.order, state.selectedStore ]);

	function addProductToDonation(product: DTO<Product>): void {
		setState({
			...state,
			selectedProducts: [
				...state.selectedProducts,
				product
			]
		});
	}

	function removeProductFromDonation(product: DTO<Product>): void {
		setState({
			...state,
			selectedProducts: [
				...state.selectedProducts
			].filter(p => p.id !== product.id)
		});
	}

	const storeContact = useMemo(() => {
		return state.selectedStore?.contacts?.[ 0 ] ?? {} as StoreContact;
	}, [ state.selectedStore ]);

	return (
		<Loader visible={state.isLoadingOrder}>
			<Box margin="medium">
				<Form
					validate="submit"
					onSubmit={() => {
						createDonation();
					}}
				>
					<Grid columns={{ count: 2, size: "flex" }} rows="auto" gap="medium" margin="small">
						<Box gap="small">
							<FormField
								name="store"
								label="Donation Partner / Store"
								validate={[
									...getStandardFormValidations(),
									(value: any) => {
										if(!stores.find(s => s.name === value)) {
											return {
												status: "error",
												message: "Select a store from the dropdown"
											};
										}
									}
								]}
							>
								<TextInput
									name="store"
									value={state?.selectedStore?.name}
									suggestions={stores.map(s => s.name)}
									onSuggestionSelect={(suggestion) => {
										setState({
											...state,
											selectedStore: stores.find(s => s.name === suggestion.suggestion) ?? null
										});
									}}
								/>
							</FormField>
							<Grid columns={{ count: "fit", size: "auto" }} gap="small">
								<StoreHoursList hours={state.selectedStore?.hours as StoreHours} />
								<Box gap="small">
									<Box gap="small">
										<SlimHeading level="4">Drop-Off Instructions</SlimHeading>
										<TextArea
											value={state.selectedStore?.delivery_instructions}
										/>
									</Box>
									<Box gap="small">
										<FormField
											label="Contact Person"
										>
											<TextInput
												value={[ storeContact.first_name, storeContact.last_name ].filter(v => !!v).join(" ")}
											/>
										</FormField>
										<FormField
											label="Contact Email"
											readOnly
										>
											<TextInput
												value={storeContact.email_address}
											/>
										</FormField>
										<FormField
											label="Contact Phone"
											readOnly
										>
											<TextInput
												value={storeContact.phone_number}
											/>
										</FormField>
									</Box>
								</Box>
							</Grid>
						</Box>
						<Box flex="grow">
							<Box align="center" justify="center" fill>
								<MapComponent
									markers={markers}
									requestCurrentLocation={true}
								/>
							</Box>
						</Box>
						<Box>
							<SlimHeading level="4">
								Order Products
							</SlimHeading>
							{state.order
								? (
									<OrderProducts
										order={state.order}
										renderProducts={(products) => (
											<Box>
												{products.map((product) => (
													<ProductSummary product={product} permitAI>
														<Box align="end" justify="center" flex="grow">
															<Box margin="small">
																<CheckBox
																	label={<Text weight="bold">Select?</Text>}
																	disabled={!!product.service}
																	onClick={(event) => {
																		event.stopPropagation();
																	}}
																	onChange={(event) => {
																		return (event.target.checked)
																			? addProductToDonation(product)
																			: removeProductFromDonation(product);
																	}}
																/>
															</Box>
														</Box>
													</ProductSummary>
												))}
											</Box>
										)}
									/>
								)
								: (
									<Box align="center" justify="center">
										<SlimHeading level="5">order not found</SlimHeading>
									</Box>
								)
							}
						</Box>
						<Box gap="small">
							<SlimHeading level="4">
								Donation / Delivery Options
							</SlimHeading>
							<Box>
								<FormField
									name="vehicle"
									label="Vehicle"
									onChange={(event) => {
										setState({
											...state,
											selectedVehicle: vehicles.find(v => v.name === event.target.value) ?? null
										});
									}}
									validate={[
										...getStandardFormValidations()
									]}
								>
									<Select
										name="vehicle"
										options={vehicles.map(v => v.name)}
									/>
								</FormField>
								<FormField
									name="requires_helper"
									label="Requires Helper?"
									onChange={(event) => {

									}}
									contentProps={{ border: undefined }}
								>
									<CheckBox
										name="requires_helper"
									/>
								</FormField>
								<FormField
									name="selected_product"
									contentProps={{ border: undefined }}
									validate={[
										(value: any) => {
											if(state.selectedProducts.length <= 0) {
												return {
													status: "error",
													message: "At least one product must be selected"
												};
											}
										}
									]}
								/>
							</Box>
						</Box>
					</Grid>
					<Box align="end">
						<Button
							label="Create Donation"
							disabled={state.isCreatingDonation || state.isLoadingOrder}
							icon={state.isCreatingDonation ? <Spinner /> : undefined}
							type="submit"
						/>
					</Box>
				</Form>
			</Box>
		</Loader>
	);
};