import React, { useState } from "react";
import { Contact, ContactType, Customer, DTO } from "../../../types";
import { Anchor, Box, Button, CheckBox, Form, FormField, Layer, Text, Spinner, TextInput } from "grommet";
import { Close } from "grommet-icons";
import { CustomerService, UtilService } from "../../../app/services";
import { SlimHeading } from "../../common";
import { UsernameField } from "./UsernameField";

interface CreateContactProps {
	onCreatedCallback(contact?: DTO<Contact>): void;
	customer: DTO<Customer>;
	type?: ContactType;
	forcePrimary?: boolean;
}

interface CreateContactState {
	username: string;
	usernameLoginType: ContactType;
	isLoading: boolean;
	wasContactCreated: boolean;
	wasVerificationCodeSent: boolean;
	verificationCodeSentAt: number | null;
	errorMessage: string;
	contact: DTO<Contact> | null;
	isPrimary: boolean;
	code: string;
}

export const CreateContactModal: React.FC<CreateContactProps> = (props) => {

	const [state, setState] = useState<CreateContactState>({
		username: "",
		usernameLoginType: props.type ?? ContactType.PHONE,
		isLoading: false,
		wasContactCreated: false,
		wasVerificationCodeSent: false,
		verificationCodeSentAt: null,
		errorMessage: "",
		contact: null,
		isPrimary: props.forcePrimary ?? false,
		code: ""
	});

	const toggleUsernameLoginType = (): void => {
		setState({
			...state,
			username: "",
			usernameLoginType: state.usernameLoginType === ContactType.EMAIL
				? ContactType.PHONE
				: ContactType.EMAIL
		});
	};

	const sendVerificationCode = async (): Promise<void> => {
		try {
			setState({
				...state,
				isLoading: true
			});

			//Check sent timestamp
			if(state.wasVerificationCodeSent) {
				//can't send another code until 60 seconds
				const LIMIT = 60 * 1000;
				if(Date.now() <= LIMIT + (state.verificationCodeSentAt ?? 0)) {
					setState({
						...state,
						errorMessage: "You can't request another login code yet"
					});
					return;
				}
			}

			if(!state.contact) {
				setState({
					...state,
					errorMessage: "We weren't able to send a verification code"
				});
				return;
			}

			if(!await CustomerService.sendVerificationCode(props.customer.id, state.contact.id)) {
				throw new Error("Failed to send verification code");
			};

			setState({
				...state,
				isLoading: false,
				wasVerificationCodeSent: true,
				verificationCodeSentAt: Date.now(),
				errorMessage: ""
			});
		}
		catch(e) {
			console.error("Failed to send verification code", e);
			setState({
				...state,
				isLoading: false,
				wasVerificationCodeSent: false,
				errorMessage: "Something wen't wrong, and we weren't able to send a verification code"
			});
		}
	};

	const verifyContact = async (): Promise<void> => {
		try {
			setState({
				...state,
				isLoading: true
			});

			if(!state.contact) {
				setState({
					...state,
					errorMessage: "We weren't able to verify that code"
				});
				return;
			}

			if(!await CustomerService.confirmVerificationCode(props.customer.id, state.contact.id, state.code)) {
				throw new Error("Failed to verify code");
			};

			setState({
				...state,
				isLoading: false
			});

			props.onCreatedCallback(state.contact);
		}
		catch(e) {
			console.error("Failed to confirm verification code", e);
			setState({
				...state,
				isLoading: false,
				wasVerificationCodeSent: false,
				verificationCodeSentAt: null,
				errorMessage: "We weren't able to verify that code"
			});
		}
	};

	const createContact = async (): Promise<void> => {
		try {
			setState({
				...state,
				isLoading: true
			});

			const contact = await CustomerService.createContact(props.customer.id, {
				value: (state.usernameLoginType === ContactType.PHONE)
					? UtilService.formatPhoneToNumber(state.username)
					: state.username,
				type: state.usernameLoginType,
				is_primary: state.isPrimary
			});

			const wasCodeSent = await CustomerService.sendVerificationCode(
				props.customer.id,
				contact.id
			).catch(err => {
				console.error("Failed to send verification code", err);
				return false;
			});

			setState({
				...state,
				isLoading: false,
				wasContactCreated: true,
				wasVerificationCodeSent: wasCodeSent,
				contact,
				errorMessage: ""
			});
		}
		catch(e) {
			console.error("Failed to send verification code", e);
			setState({
				...state,
				isLoading: false,
				wasContactCreated: false,
				wasVerificationCodeSent: false,
				errorMessage: "Something wen't wrong, and we weren't able to send a verification code"
			});
		}
	};

	return (
		<Layer
			onClickOutside={() => {
				props.onCreatedCallback();
			}}
		>
			<Box align="center" margin="medium" gap="small" style={{ minWidth: "300px" }}>
				<Box align="end" justify="center" fill="horizontal">
					<Button plain icon={(
						<Close
							onClick={() => props.onCreatedCallback()}
						/>
					)} />
				</Box>
				<Box margin="small" gap="small">
					<Box direction="column">
						<SlimHeading level="3">Add Contact Method</SlimHeading>
					</Box>
					<Box>
						<Form
							messages={{
								required: "This field is required"
							}}
							value={state}
							onChange={(changes) => {
								setState({
									...state,
									...changes,
									errorMessage: ""
								});
							}}
							onSubmit={({ value }) => {
								if(!state.wasContactCreated) {
									createContact();
									return;
								}

								verifyContact();
							}}
						>
							<Box gap="small" direction="column">
								<Box gap="none" direction="column">
									<UsernameField
										disabled={state.wasVerificationCodeSent}
										emailFieldLabel="Add new phone number?"
										phoneFieldLabel="Add new email?"
										type={state.usernameLoginType}
										username={state.username}
										toggleType={toggleUsernameLoginType}
										disableToggle={!!props.type}
									/>
									{!props.forcePrimary && (
										<FormField
											readOnly={state.wasVerificationCodeSent}
											border={false}
											htmlFor="isPrimary"
											name="isPrimary"
											contentProps={{
												border: false
											}}
										>
											<CheckBox
												name="isPrimary"
												id="isPrimary"
												label={<Text weight="bold">Make Primary?</Text>}
												toggle
											/>
										</FormField>
									)}
									{state.wasVerificationCodeSent && (
										<Box gap="small" direction="column">
											<FormField
												required
												name="code"
												htmlFor="code"
												label="Verification Code"
											>
												<TextInput autoComplete="one-time-code" id="code" type="text" name="code" pattern="[0-9]{6}" />
											</FormField>
											<Box direction="column" align="start">
												<Anchor onClick={sendVerificationCode}>Send another code?</Anchor>
											</Box>
										</Box>
									)}
								</Box>
								<FormField contentProps={{ border: false }} pad={false} margin="none" hidden error={state.errorMessage} />
								<Box direction="column" gap="small" justify="center" align="center">
									<Button
										icon={state.isLoading ? <Spinner /> : undefined}
										reverse
										type="submit"
										primary
										label={state.isLoading ? "Loading" : "Submit"}
									/>
								</Box>
							</Box>
						</Form>
					</Box>
				</Box>
			</Box>
		</Layer>
	);
};