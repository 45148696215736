import { Chip } from "@material-ui/core";
import { CustomerProduct, DeliveryAddress, Donation, DTO, Order, OrderMilestone, Product, ProductIntent, RelationExpand, Removal } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, FormField, Grid, Image, Layer, Select, Spinner, Text, TextInput } from "grommet";
import { useSnackbar } from "notistack";
import { createRef, Fragment, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { OrderService, ProductService } from "../../../app/services";
import { useAppDispatch } from "../../../app/store";
import { isCustomerProduct, isStoreProduct, parseDateFromUTC, parseTimestampFromUTC } from "../../../helpers";
import { CopyToClipboardField, FieldWithActionButton, ImageCarousel, Loader, LocalDateField, MapComponent, Pagination, SlimHeading, useTimezone } from "../../common";
import placeholder from "../../../img/placeholder.jpg";
import { FormCheckmark, FormClose, User } from "grommet-icons";
import { AddressForm } from "../../order";
import { DonationSummary } from "./donation";
import { RemovalSummary } from "./removals";
import { navigateToCustomer } from "../routes";
import { UnionProduct } from "../../../types";
import { LinearProgress } from "@mui/material";
import { Detection, useDetection } from "../../demo/components/ScanDemo";
import { Code } from "@mui/icons-material";

export const orderExpand: RelationExpand<Order> = [ "customer", "notes", "market" ];

export const OrderSummary: React.FC<{ order: DTO<Order>; }> = (props) => {
	const dispatch = useAppDispatch();

	function handleOrderNavigate(): void {
		dispatch(push(`/admin/dashboard/orders/${props.order.id}`));
	}

	return (
		<Box
			hoverIndicator
			border
			gap="small"
			margin="small"
			onClick={handleOrderNavigate}
		>
			<Box margin="small" gap="small">
				<Box direction="row" align="center" justify="end">
					<Chip label={props.order.status} />
				</Box>
				<Box gap="small" align="start">
					{props.order.created_at && (
						<LocalDateField
							label="Created At"
							value={props.order.created_at}
						/>
					)}
					{props.order.ordered_at && (
						<LocalDateField
							label="Ordered At"
							value={props.order.ordered_at}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};

interface OrderDetailsProps {
	order: DTO<Order>;
	onCancelOrder(): void;
	onCompleteOrder(): void;
	onUpdateMilestone(milestone: OrderMilestone): void;
	isUpdatingOrder: boolean;
	isCancellingOrder: boolean;
	isCompletingOrder: boolean;
}

export const OrderDetails: React.FC<OrderDetailsProps> = (props) => {
	const snack = useSnackbar();
	const timezone = useTimezone();
	const dispatch = useAppDispatch();

	return (
		<Box>
			<CopyToClipboardField
				name="id"
				label="Order Id"
				value={props.order.id}
			/>
			<CopyToClipboardField
				name="number"
				label="Order Number"
				value={String(props.order.number ?? "")}
			/>
			<FormField
				label="Order Status"
			>
				<TextInput
					readOnly
					disabled
					value={props.order.status}
				/>
			</FormField>
			<FormField
				name="milestone"
				label="Order Milestone"
			>
				<Select
					name="milestone"
					///@ts-ignore
					readOnly={[ "COMPLETED", "CANCELLED" ].includes(props.order.status)}
					///@ts-ignore
					disabled={[ "COMPLETED", "CANCELLED" ].includes(props.order.status)}
					value={props.order.milestone}
					onChange={(event) => {
						props.onUpdateMilestone(event.target.value);
					}}
					options={Object.values(OrderMilestone)}
					icon={props.isUpdatingOrder ? <Spinner /> : undefined}
				/>
			</FormField>
			{props.order.customer && (
				<FieldWithActionButton
					name="customer"
					label="Customer"
					icon={<User />}
					value={`${props.order.customer.first_name} ${props.order.customer.last_name}`}
					onClick={() => {
						navigateToCustomer(props.order.customer.id, dispatch);
					}}
				/>
			)}
			<FormField
				label="Created At"
			>
				<TextInput
					value={props.order.created_at ? parseTimestampFromUTC(props.order.created_at, timezone) : undefined}
				/>
			</FormField>
			<FormField
				label="Ordered At"
			>
				<TextInput
					value={props.order.ordered_at ? parseTimestampFromUTC(props.order.ordered_at, timezone) : undefined}
				/>
			</FormField>
			{!props.order.cancelled_at && (
				<FormField
					label="Completed At"
				>
					<Box flex direction="row">
						<TextInput
							plain
							readOnly
							style={{ height: "100%" }}
							value={props.order.completed_at ? parseTimestampFromUTC(props.order.completed_at, timezone) : undefined}
						/>
						{!props.order.completed_at && (
							<Button
								disabled={props.isCompletingOrder}
								hoverIndicator
								icon={props.isCompletingOrder ? <Spinner /> : <FormCheckmark color="green" />}
								onClick={props.onCompleteOrder}
							/>
						)}
					</Box>
				</FormField>
			)}
			{!props.order.completed_at && (
				<FormField
					label="Cancelled At"
				>
					<Box flex direction="row">
						<TextInput
							plain
							readOnly
							style={{ height: "100%" }}
							value={props.order.cancelled_at ? parseTimestampFromUTC(props.order.cancelled_at, timezone) : undefined}
						/>
						{!props.order.cancelled_at && (
							<Button
								disabled={props.isCancellingOrder}
								hoverIndicator
								icon={props.isCancellingOrder ? <Spinner /> : <FormClose color="red" />}
								onClick={props.onCancelOrder}
							/>
						)}
					</Box>
				</FormField>
			)}
			<FormField
				label="Cutoff / Move-out Date"
			>
				<TextInput
					readOnly
					value={props.order.cutoff_date ? parseDateFromUTC(props.order.cutoff_date, timezone) : undefined}
				/>
			</FormField>
		</Box>
	);
};

export const ProductDetailsModal: React.FC<{ product: DTO<Product>, onClose: () => void; }> = (props) => {
	return (
		<Layer
			onClickOutside={props.onClose}
			onEsc={props.onClose}
		>
			<Box margin="medium">
				<Box align="end" justify="center">
					<Button
						hoverIndicator
						onClick={props.onClose}
						icon={
							<FormClose size="medium" />
						}
					/>
				</Box>
				<Box>
					<FormField
						label="Item Type"
					>
						<TextInput
							readOnly
							value={props.product.item?.name ?? props.product.item_input ?? ""}
						/>
					</FormField>
					<FormField
						label="Item Condition"
					>
						<TextInput
							readOnly
							value={props.product.condition}
						/>
					</FormField>
					<ImageCarousel product={props.product} />
				</Box>
			</Box>
		</Layer>
	);
};

interface ProductSummaryProps {
	product: DTO<UnionProduct | CustomerProduct>;
	displayContext?: "orders" | "donation";
	hideChipContent?: boolean;
	overrideOnClick?: () => void;
	permitAI: boolean;
}

export const ProductSummary: React.FC<ProductSummaryProps> = (props) => {
	const { isReady, detect } = useDetection();
	const [ displayDetailsModal, setDisplayDetailsModal ] = useState(false);
	const [ thumbnail, setThumbnail ] = useState("");
	const [ isLoading, setIsLoading ] = useState(true);
	const [ product, setProduct ] = useState<DTO<UnionProduct>>(props.product as UnionProduct);

	function handleHideModal(): void {
		setDisplayDetailsModal(false);
	}

	useEffect(() => {
		if(!product) {
			return;
		}

		if(product.media.length) {
			ProductService.getMediaURL(props.product.id, props.product.media[ 0 ].id)
				.then(res => {
					//const media = { ...props.product.media[0] };
					//media.content = res;
					//const file = mediaToFile(media);
					//setThumbnail(URL.createObjectURL(file));
					setThumbnail(res);
				})
				.catch(err => {
					console.error("Failed to load product media", props.product, err);
					setThumbnail(placeholder);
				});
		}
		else {
			setThumbnail(placeholder);
		}
	}, [ product ]);

	useEffect(() => {
		if(props.product) {
			setIsLoading(true);
			ProductService.getUnionProduct(
				props.product.id,
				[ "service", "store", "order", "customer", "purchase", "purchases" ]
			).then(res => {
				setProduct({ ...res });
			}).catch(err => {
				console.error("Failed to load hydrated product", err);
			}).finally(() => {
				setIsLoading(false);
			});
		}
	}, [ props.product ]);

	const [ detections, setDetections ] = useState<Detection[]>([]);

	const elementRef = createRef<HTMLImageElement>();

	useEffect(() => {
		if(!props.permitAI) return;
		(async () => {
			console.log("IN EFFECT:");
			console.log("IS COCO READY: ", isReady);
			console.log("MEDIA: ", props.product.media);
			console.log("ELEMENT REF: ", elementRef.current);
			if(elementRef.current && isReady) {
				const media = props.product.media;
				for(const image of media) {
					const element = elementRef.current;

					const src = await ProductService.getMediaURL(props.product.id, image.id);
					const [ path, query ] = src.split("?");

					element.src = path + "?" + query + "&t=" + Date.now() + "&r=" + Math.random();
					console.log("SRC: ", element.src);

					await new Promise<void>((r, j) => {
						function loaded(event: any): any {
							console.log("GOT LOADED!", element, event);
							detect(element).then(detections => {
								console.log("Detections: ", detections);
								setDetections([ ...detections ]);
							}).catch(err => {
								console.error("Failed to detect", err);
							}).finally(() => {
								element.removeEventListener("load", loaded);
								r();
							});
						}

						element.addEventListener("load", loaded);
					});
				}
			}
		})();
	}, [ props.product.media, isReady, props.product.id, elementRef.current, props.permitAI ]);

	const bestDetection = useMemo(() => {
		if(detections.length) {
			const best = detections.reduce((prev, curr) => {
				if(curr.score > prev.score) {
					return curr;
				}

				return prev;
			}, detections[ 0 ]);

			return best;
		}

		return null;
	}, [ detections ]);

	return (
		<Box>
			{displayDetailsModal && (
				<ProductDetailsModal
					product={props.product}
					onClose={handleHideModal}
				/>
			)}
			<img
				alt=""
				id="image-preview"
				ref={elementRef}
				crossOrigin="anonymous"
				style={{ display: "none" }}
			/>
			<Box
				border
				hoverIndicator
				margin="small"
				onClick={(event) => {
					if(props.overrideOnClick) {
						props.overrideOnClick();
						return;
					}

					setDisplayDetailsModal(true);
				}}
			>
				{isLoading && (
					<LinearProgress />
				)}
				<Grid columns={[ "xsmall", "flex" ]}>
					<Box align="center" justify="center">
						<Box margin="small">
							{!thumbnail
								? (
									<Box>
										<Spinner size="medium" fill />
									</Box>
								)
								: (
									<Image
										height={75}
										width={75}
										src={thumbnail}
									/>
								)
							}
						</Box>
					</Box>
					<Box margin="small" gap="small" direction="row">
						<Box>
							<SlimHeading level="3">
								{product.item?.name ?? product.item_input}
							</SlimHeading>
							{!props.hideChipContent && (
								<Fragment>
									<Box align="start" direction="row" gap="small" margin="small">
										{isStoreProduct(product) && product.store && (
											<Chip style={{ maxWidth: "inherit" }} color="primary" label={<Text size="small" truncate>{product.store?.name}</Text>} />
										)}
										{isCustomerProduct(product) && (
											<Box gap="small" direction="row">
												<Chip color="primary" label={product.disposition ?? product.intent} />
												{(!product.service && product.intent !== ProductIntent.SELL) && (
													<Chip color="secondary" label={"Not Assigned"} />
												)}
												{product.disposition === ProductIntent.SELL && (
													<Chip color="primary" label={"Purchased"} />
												)}
											</Box>
										)}
										{(props.permitAI && !!bestDetection) && (
											<Chip
												color="default"
												icon={<Code />}
												label={bestDetection?.name}
											/>
										)}
										{(props.permitAI && !!bestDetection)
											? (bestDetection.score > .50)
												? <Chip
													color="default"
													label="Donate"
													icon={<Text>{Math.round(bestDetection.score * 100)}%</Text>}
												/>
												: <Chip color="default" label="Low Confidence" />
											: undefined
										}
									</Box>
								</Fragment>
							)}
						</Box>
						<Box flex="grow">
							{props.children}
						</Box>
					</Box>
				</Grid>
			</Box>
		</Box>
	);
};

interface AddressDetailsProps {
	address: DeliveryAddress;
	onClose(): void;
	onUpdateAddress(address: Partial<DeliveryAddress>): Promise<void>;
}

interface AddressDetailsState extends Partial<DTO<DeliveryAddress>> {
	wasChanged: boolean;
	isSaving: boolean;
}

export const AddressDetails: React.FC<AddressDetailsProps> = (props) => {
	const [ state, setState ] = useState<AddressDetailsState>({
		...props.address,
		wasChanged: false,
		isSaving: false
	});

	function handleAddressSelected(address: DeliveryAddress): void {
		setState({
			...state,
			...address,
			wasChanged: true
		});
	}

	function handleSaveAddress(address: DeliveryAddress): void {
		setState({
			...state,
			...address,
			isSaving: true
		});

		props.onUpdateAddress(state)
			.catch(err => {
				console.error("Failed to update address", err);
			})
			.finally(() => {
				setState({
					...state,
					isSaving: false
				});
			});
	}

	return (
		<Layer
			full
			onEsc={props.onClose}
			margin="medium"
		>
			<Box >
				<Box margin="small" align="end">
					<Button
						icon={<FormClose />}
						onClick={props.onClose}
						hoverIndicator
					/>
				</Box>
				<Box margin="medium" overflow={{ vertical: "scroll" }}>
					<AddressForm
						address={state}
						onAddressSelected={handleAddressSelected}
						onFormSubmit={handleSaveAddress}
					>
						<Box align="end" justify="center" margin="small">
							<Button
								label="Save Changes"
								disabled={!state.wasChanged || state.isSaving}
								type="submit"
							/>
						</Box>
					</AddressForm>
				</Box>
			</Box>
		</Layer>
	);
};

interface AddressSummaryProps {
	address: DeliveryAddress;
	onUpdateOrderAddress(address: DeliveryAddress): Promise<void>;
	forceMinimumMapHeight?: number;
}

export const AddressSummary: React.FC<AddressSummaryProps> = (props) => {
	const [ showAddressDetails, setShowAddressDetails ] = useState(false);

	function formatAddress(): string {
		return [
			props.address.address_line_one,
			props.address.address_line_two
		].filter(v => !!v).join(", ");
	}

	function formatCityStateZip(): string {
		const address = props.address;
		return `${address.city}, ${address.state} ${address.zip}`;
	}

	async function handleUpdateAddress(changes: Partial<DeliveryAddress>): Promise<void> {
		await props.onUpdateOrderAddress(changes as DeliveryAddress).catch(err => {
			console.error("Caught error updating address ... this should be caught upstream", err);
		});
		setShowAddressDetails(false);
	}

	return (
		<Box fill>
			{showAddressDetails && (
				<AddressDetails
					onClose={() => {
						setShowAddressDetails(false);
					}}
					address={props.address}
					onUpdateAddress={handleUpdateAddress}
				/>
			)}
			<Box gap="small" direction="row" justify="between">
				<Box gap="xsmall">
					<SlimHeading level="4">{formatAddress()}</SlimHeading>
					<SlimHeading level="4">{formatCityStateZip()}</SlimHeading>
				</Box>
				<Box align="end" justify="center">
					<Button
						label="Make Changes"
						onClick={() => {
							setShowAddressDetails(true);
						}}
					/>
				</Box>
			</Box>
			<Box align="center" justify="center" flex="grow" style={{ minHeight: props.forceMinimumMapHeight ?? undefined }}>
				<MapComponent
					allowFreeZoom
					markers={props.address?.latitude ? [ {
						latitude: Number(props.address.latitude),
						longitude: Number(props.address.longitude)
					} ] : []}
					requestCurrentLocation={false}
				/>
			</Box>
		</Box>
	);
};

interface OrderProductsProps {
	order: DTO<Order>;
	renderProducts?: (products: DTO<CustomerProduct>[]) => JSX.Element;
}

export const OrderProducts: React.FC<OrderProductsProps> = (props) => {
	const snack = useSnackbar();
	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ products, setProducts ] = useState<DTO<CustomerProduct>[]>([]);

	useEffect(() => {
		OrderService.listOrderProducts(props.order.id, {}, [ "service", "customer", "purchases" ])
			.then(products => {
				setProducts(products);
			})
			.catch(err => {
				console.error("Failed to load order products", err);
				snack.enqueueSnackbar("Failed to load order products", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}, []);

	const renderItems = useCallback((products: DTO<CustomerProduct>[]) => {
		if(props.renderProducts) {
			return props.renderProducts(products);
		}

		return (
			<Box>
				{products.map(product => (
					<ProductSummary permitAI product={product} key={product.id} />
				))}
			</Box>
		);
	}, [ products, props.renderProducts ]);

	return (
		<Loader visible={!isLoaded}>
			<Box gap="small" flex>
				<Pagination
					items={products}
					pageSize={5}
					renderItems={renderItems}
				/>
			</Box>
		</Loader>
	);
};

export const OrderDonations: React.FC<{ order: DTO<Order>; }> = (props) => {
	const snack = useSnackbar();
	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ donations, setDonations ] = useState<DTO<Donation>[]>([]);

	useEffect(() => {
		OrderService.listOrderDonations(props.order.id, {}, [ "products", "store", "vehicle" ])
			.then(products => {
				setDonations(products);
			})
			.catch(err => {
				console.error("Failed to load order donations", err);
				snack.enqueueSnackbar("Failed to load order donations", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}, []);

	return (
		<Loader visible={!isLoaded}>
			<Box gap="small" flex="grow">
				{donations.length
					? (
						<Pagination
							items={donations}
							pageSize={5}
							renderItems={(donations) => (
								<Box>
									{donations.map(donation => (
										<DonationSummary donation={donation as DTO<Donation>} key={donation.id} />
									))}
								</Box>
							)}
						/>
					)
					: (
						<Box align="center" justify="center" fill flex="grow">
							<SlimHeading level="5">No donations yet</SlimHeading>
						</Box>
					)
				}
			</Box>
		</Loader>
	);
};


export const OrderRemovals: React.FC<{ order: DTO<Order>; }> = (props) => {
	const snack = useSnackbar();
	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ removals, setRemovals ] = useState<DTO<Removal>[]>([]);

	useEffect(() => {
		OrderService.listOrderRemovals(props.order.id, {}, [ "products", "vehicle" ])
			.then(removals => {
				setRemovals(removals);
			})
			.catch(err => {
				console.error("Failed to load order removals", err);
				snack.enqueueSnackbar("Failed to load order removals", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}, []);

	return (
		<Loader visible={!isLoaded}>
			<Box gap="small" flex="grow">
				{removals.length
					? (
						<Pagination
							items={removals}
							pageSize={5}
							renderItems={(removals) => (
								<Box>
									{removals.map(removal => (
										<RemovalSummary removal={removal as DTO<Removal>} key={removal.id} />
									))}
								</Box>
							)}
						/>
					)
					: (
						<Box align="center" justify="center" fill flex="grow">
							<SlimHeading level="5">No removals yet</SlimHeading>
						</Box>
					)
				}
			</Box>
		</Loader>
	);
};