import { push } from "connected-react-router";
import { Box, FormField, Grid, TextInput } from "grommet";
import { useAppDispatch } from "../../../app/store";
import { CloseableModal, CustomerProduct, DTO, Order, Product, Service } from "../../../types";
import { ProductSummary } from "../../admin/components";
import { LocalDateField, Modal, Pagination, SlimHeading, useScreenSize } from "../../common";

interface OrderServiceCardProps {
	items: DTO<Service>[];
	label: string;
	action: () => void;
}

export const OrderServiceCard: React.FC<OrderServiceCardProps> = (props) => {
	return (
		<Box
			elevation="small"
			border="all"
			hoverIndicator
			round
			onClick={() => {
				props.action();
			}}
		>
			<Box margin="small" gap="medium">
				<Box align="start">
					<SlimHeading level="4">{props.label}</SlimHeading>
				</Box>
				<Box align="center">
					<SlimHeading level="3">{props.items.length}</SlimHeading>
					<SlimHeading level="4">Active</SlimHeading>
				</Box>
			</Box>
		</Box>
	);
};

interface OrderDetailsProps extends CloseableModal {
	order: DTO<Order>;
}

export const OrderDetails: React.FC<OrderDetailsProps> = (props) => {
	const dispatch = useAppDispatch();

	function formatAddress(): string {
		return [
			props.order.address.address_line_one
		].filter(v => !!v).join(", ");
	}

	function formatCityStateZip(): string {
		const address = props.order.address;
		return `${address.city}, ${address.state} ${address.zip}`;
	}

	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box gap="small">
				<Box direction="column" gap="small" overflow={{ vertical: "scroll" }}>
					<Box style={{ display: "inline-table" }}>
						<FormField
							label="Order Number"
						>
							<TextInput
								disabled
								value={props.order.number}
							/>
						</FormField>
						<LocalDateField
							label="Created At"
							value={props.order.created_at}
						/>
						<LocalDateField
							label="Submitted At"
							value={props.order.ordered_at}
						/>
						{props.order.cutoff_date && (
							<LocalDateField
								label="Cutoff Date"
								omitTimestamp
								value={props.order.cutoff_date}
							/>
						)}
						<FormField
							label="Order Address"
							contentProps={{ border: undefined }}
						>
							<Box margin="small">
								<SlimHeading level="5">{formatAddress()}</SlimHeading>
								{props.order.address.address_line_two && (
									<SlimHeading level="5">{props.order.address.address_line_two}</SlimHeading>
								)}
								<SlimHeading level="5">{formatCityStateZip()}</SlimHeading>
							</Box>
						</FormField>
					</Box>
					<Box flex gap="small">
						<SlimHeading level="3">Order Products</SlimHeading>
						<Box gap="small" flex style={{ minHeight: "fit-content" }} id="container-for-pagination">
							<Pagination
								items={props.order.products}
								pageSize={5}
								renderItems={(products: DTO<CustomerProduct>[]) => (
									<Box>
										{products.map(product => (
											<ProductSummary
												permitAI={false}
												product={product}
												key={product.id}
												hideChipContent
											/>
										))}
									</Box>
								)}
							/>
						</Box>
					</Box>
				</Box>
				<Grid columns={{ count: 2, size: "auto" }} gap="small">
					<OrderServiceCard
						label="Donations"
						items={(props.order.donations ?? []).filter(d => !d.completed && d.visible)}
						action={() => {
							dispatch(push("/dashboard/donations"));
						}}
					/>
					<OrderServiceCard
						label="Removals"
						items={(props.order.removals ?? []).filter(d => !d.completed && d.visible)}
						action={() => {
							dispatch(push("/dashboard/removals"));
						}}
					/>
				</Grid>
			</Box>
		</Modal>
	);
};