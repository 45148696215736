import { LinearProgress } from "@mui/material";
import { DTO, Item, Product, ProductIntent, Service, Store, Vehicle } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, CheckBox, ColumnConfig, DataTable, Form, FormField, Select, Spinner, Text, TextArea, TextInput } from "grommet";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Steps, useSteps } from "react-step-builder";
import { OrderService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchProductApprovalQueue, setActiveProduct, selectActiveProduct, selectProductApprovalQueue, updateProduct, rejectProduct, approveProduct, listStores, selectStores, expireProduct } from "../../../app/store/admin";
import { getOrFetchVehicles, selectVehicles } from "../../../app/store/reference";
import { getStandardFormValidations } from "../../../helpers";
import { UnionProduct } from "../../../types";
import { Loader, LocalDateField, Modal, SlimHeading } from "../../common";

const listingTableColumns: ColumnConfig<DTO<UnionProduct>>[] = [
	{
		property: "number",
		header: "Product Number",
		primary: true,
		render: (product) => product.number
	},
	{
		property: "order_number",
		header: "Order Number",
		primary: true,
		render: (product) => product.order?.number
	},
	{
		property: "type",
		header: "Product Type",
		sortable: true,
		render: (product) => product.item?.name ?? product?.item_input ?? ""
	},
	{
		property: "last_name",
		header: "Customer Last Name",
		primary: true,
		sortable: true,
		render: (product) => product?.customer?.last_name ?? ""
	},
	{
		property: "first_name",
		header: "Customer First Name",
		primary: false,
		render: (product) => product?.customer?.first_name ?? ""
	},
];

interface AdminListingState {
	isLoading: boolean,
	selectedProduct: DTO<Product> | null;
}

export const AdminListingsPage: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const products = useAppSelector(selectProductApprovalQueue);
	const [state, setState] = useState<AdminListingState>({
		isLoading: false,
		selectedProduct: null
	});

	function handleSelectProduct(product: DTO<UnionProduct>): void {
		dispatch(setActiveProduct(product));
		dispatch(push(`/admin/dashboard/products/${product.id}?launchApprovalFlow=true`));
	}

	function fetchProducts(): void {
		setState({
			...state,
			isLoading: true
		});
		dispatch(fetchProductApprovalQueue()).unwrap()
			.catch(err => {
				console.error("failed to load product approval queue", err);
				snack.enqueueSnackbar("Failed to load product approval queue", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isLoading: false
				});
			});
	}

	useEffect(() => {
		fetchProducts();
	}, []);

	return (
		<Box margin="medium" gap="small">
			<SlimHeading level="3">
				Approval Queue
			</SlimHeading>
			<Box>
				{state.isLoading && (
					<LinearProgress />
				)}
				<DataTable
					columns={listingTableColumns}
					data={products}
					step={10}
					onClickRow={(event) => {
						handleSelectProduct(event.datum);
					}}
				/>
			</Box>
		</Box>
	);
};


interface FormState {
	title: string;
	description: string;
	item: DTO<Item> | null;
	item_input: string;
	intent: ProductIntent | null;
	price: number | null;
	price_compare?: number | null;
}

interface RejectListingState {
	intent: ProductIntent | null;
	vehicle: DTO<Vehicle> | null;
	store: DTO<Store> | null;
	service: DTO<Service> | null;
	reason: string;
	isExpired: boolean;
}

interface RejectListingStep {
	state: RejectListingState;
	updateState(changes: Partial<RejectListingState>): void;
}

const RejectListingServiceStep: React.FC<RejectListingStep & { services: DTO<Service>[]; }> = (props) => {
	const [isSaving, setIsSaving] = useState(false);
	const snack = useSnackbar();
	const activeProduct = useAppSelector(selectActiveProduct);

	const existingServicesNotScheduled = useMemo(() => {
		return (props.services ?? []).filter(s => !s.scheduled).sort(sortServiceComparator);
	}, [props.services]);

	if(!activeProduct) {
		return <></>;
	}

	function sortServiceComparator(a: DTO<Service>, b: DTO<Service>): number {
		return moment(b.created_at).unix() - moment(a.created_at).unix();
	}

	function addProductToService(service: DTO<Service>): void {
		if(!activeProduct) {
			return;
		}

		setIsSaving(true);
		OrderService.addProductToService(service.id, activeProduct.id)
			.then(() => {
				props.updateState({
					service
				});
			})
			.catch(err => {
				console.error("Failed to add product to service", service, err);
				snack.enqueueSnackbar("Failed to add product to service", { variant: "error" });
			})
			.finally(() => {
				setIsSaving(false);
			});
	}

	if(props.state.intent === ProductIntent.DONATE) {
		return (
			<Box gap="small">
				<SimpleDonationForm
					state={props.state}
					updateState={props.updateState}
				/>
				{(existingServicesNotScheduled.length > 0 && !props.state.service) && (
					<Box gap="small">
						<SlimHeading level="4">
							We found an existing donation that has not been scheduled. Do you want to add this product to the most recent one?
						</SlimHeading>
						<Box align="start">
							<Button
								label="Add Product"
								primary
								disabled={isSaving}
								icon={isSaving ? <Spinner /> : undefined}
								onClick={() => {
									addProductToService(existingServicesNotScheduled[0]);
								}}
							/>
						</Box>
					</Box>
				)}
			</Box>
		);
	}

	if(props.state.intent === ProductIntent.JUNK) {
		return (
			<Box gap="small">
				<SimpleRemovalForm
					state={props.state}
					updateState={props.updateState}
				/>
				{(existingServicesNotScheduled.length > 0 && !props.state.service) && (
					<Box gap="small">
						<Text>
							We found an existing removal that has not been scheduled. Do you want to add this product to the most recent one?
						</Text>
						<Button
							label="Add Product"
							primary
							disabled={isSaving}
							icon={isSaving ? <Spinner /> : undefined}
							onClick={() => {
								addProductToService(existingServicesNotScheduled[0]);
							}}
						/>
					</Box>
				)}
			</Box>
		);
	}

	return <></>;
};

const RejectListingReasonStep: React.FC<RejectListingStep> = (props) => {
	return (
		<Box>
			<Form
				value={props.state}
				onChange={(changes) => {
					props.updateState(changes);
				}}
			>
				<FormField
					name="intent"
					label="New Product Intent"
					validate={[
						...getStandardFormValidations()
					]}
				>
					<Select
						name="intent"
						options={[ProductIntent.DONATE, ProductIntent.JUNK]}
					/>
				</FormField>
				<FormField
					info="select if you're rejecting due to the cutoff date"
					contentProps={{ border: undefined }}
				>
					<CheckBox
						label="Is this product expired?"
						checked={props.state.isExpired}
						onChange={(event) => {
							props.updateState({ isExpired: event.target.checked });
						}}
					/>
				</FormField>
				{!props.state.isExpired && (
					<FormField
						name="reason"
						label="Rejection Reason"
						help="This is displayed to the customer in an email"
					>
						<TextArea
							name="reason"
						/>
					</FormField>
				)}
			</Form>
		</Box>
	);
};

interface ListingDecisionControllerProps {
	onClose: () => void;
	isExpirationFlow: boolean;
}

export const ListingDecisionController: React.FC<ListingDecisionControllerProps> = (props) => {
	const snack = useSnackbar();
	const { next, hasNext, current } = useSteps();
	const dispatch = useAppDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [isInvalid, setInvalid] = useState(false);
	const [overrideSubmit, setOverrideSubmit] = useState(false);
	const [orderServices, setOrderServices] = useState<DTO<Service>[]>([]);
	const [firstLoadedServices, setFirstLoadedServices] = useState(false);
	const [isLoadingServices, setIsLoadingServices] = useState(false);
	const activeProduct = useAppSelector(selectActiveProduct);
	const [decision, setDecision] = useState<"Approve" | "Reject" | null>(props.isExpirationFlow ? "Reject" : null);

	const [rejectState, setRejectState] = useState<RejectListingState>({
		store: null,
		reason: "",
		vehicle: null,
		service: null,
		isExpired: props.isExpirationFlow ?? false,
		intent: activeProduct?.intent ?? null
	});

	useEffect(() => {
		if(activeProduct?.service) {
			setRejectState({
				...rejectState,
				intent: activeProduct.intent,
				service: activeProduct.service,
				reason: rejectState.reason || activeProduct.rejected_reason || ""
			});
		}
	}, [activeProduct]);

	useEffect(() => {
		function required(values: (keyof RejectListingState)[]): void {
			setInvalid(values.some(key => !rejectState[key]));
		}

		if(decision === "Approve") {
			required([]);
			return;
		}

		switch(current) {
			case 2: {
				if(rejectState.isExpired) {
					required(["intent"]);
					break;
				}

				required(["intent", "reason"]);
				break;
			}
			case 3: {
				required(
					(rejectState.intent === ProductIntent.DONATE)
						? ["store", "vehicle"]
						: ["vehicle"]
				);
				break;
			}
			default: {
				required([]);
			}
		}
	}, [rejectState, current]);

	function handleUpdateProductIntent(): void {
		if(!activeProduct) {
			return;
		}
		const { intent } = rejectState;
		setIsSaving(true);
		handleUpdateProduct(activeProduct, { intent: intent ?? undefined })
			.then(() => {
				next();
			})
			.catch(err => {
				console.error("Failed to update product intent");
				snack.enqueueSnackbar(err?.error?.message ?? "Failed to update product intent", { variant: "error" });
			})
			.finally(() => {
				setIsSaving(false);
			});
	}

	function handleUpdateDonationDetails(): void {
		if(!order || !activeProduct || !rejectState.store || !rejectState.vehicle) {
			return;
		}

		if(rejectState.service) {
			next();
			return;
		}

		const { store, vehicle } = rejectState;

		setIsSaving(true);
		OrderService.createStoreDonation(
			order.id,
			store.id,
			{ products: [activeProduct.id], vehicle, requires_helper: true }
		)
			.then((service) => {
				setRejectState((state) => {
					return {
						...state,
						service
					};
				});
				next();
			})
			.catch(err => {
				console.error("Failed to create service", err);
				snack.enqueueSnackbar(err?.error?.message ?? "Failed to create service", { variant: "error" });
			})
			.finally(() => {
				setIsSaving(false);
			});
	}

	function handleUpdateRemovalDetails(): void {
		if(!order || !activeProduct || !rejectState.vehicle) {
			return;
		}

		if(rejectState.service) {
			next();
			return;
		}

		const { vehicle } = rejectState;

		setIsSaving(true);
		OrderService.createOrderRemoval(
			order.id,
			vehicle,
			[activeProduct]
		)
			.then((service) => {
				setRejectState((state) => {
					return {
						...state,
						service
					};
				});
				next();
			})
			.catch(err => {
				console.error("Failed to create service", err);
				snack.enqueueSnackbar(err?.error?.message ?? "Failed to create service", { variant: "error" });
			})
			.finally(() => {
				setIsSaving(false);
			});
	}

	function handleOverrideSubmit(): void {
		if(decision !== "Reject") {
			setOverrideSubmit(false);
			return;
		}

		switch(current) {
			case 2: {
				handleUpdateProductIntent();
				break;
			}
			case 3: {
				if(rejectState.store && rejectState.intent === ProductIntent.DONATE) {
					handleUpdateDonationDetails();
					return;
				}

				if(rejectState.intent === ProductIntent.JUNK) {
					handleUpdateRemovalDetails();
					return;
				}
				break;
			}
			default: {
				break;
			}
		}
	}

	useEffect(() => {
		if(decision !== "Reject") {
			setOverrideSubmit(false);
			return;
		}

		switch(current) {
			case 2: {
				setOverrideSubmit(true);
				break;
			}
			case 3: {
				setOverrideSubmit(true);
				break;
			}
			default: {
				setOverrideSubmit(false);
			}
		}
	}, [rejectState, current]);

	const order = useMemo(() => {
		return activeProduct?.order;
	}, [activeProduct]);

	function handleApproveProduct(product: DTO<Product>): Promise<any> {
		setIsSaving(true);
		return dispatch(approveProduct({ productId: product.id }))
			.unwrap()
			.catch(err => {
				console.error("Failed to approve product", activeProduct, err);
				snack.enqueueSnackbar(
					err?.error?.message ?? "Failed to approve product",
					{ variant: "error" }
				);
			})
			.finally(() => {
				setIsSaving(false);
				props.onClose();
			});
	}

	async function handleRejectListing(product: DTO<Product>, reason: string): Promise<DTO<Product>> {
		setIsSaving(true);
		return await dispatch(rejectProduct({ productId: product.id, reason })).unwrap().catch(err => {
			//TODO: WHY CANT I GET THE ORIGINAL ERROR!!!
			console.error("Failed to reject product", activeProduct, err);
			snack.enqueueSnackbar(err?.error?.message ?? "Failed to reject product", { variant: "error" });
			throw err;
		}).finally(() => {
			setIsSaving(false);
			props.onClose();
		});
	}

	async function handleExpireListing(product: DTO<Product>): Promise<DTO<Product>> {
		setIsSaving(true);
		return await dispatch(expireProduct({ productId: product.id })).unwrap().catch(err => {
			//TODO: WHY CANT I GET THE ORIGINAL ERROR!!!
			console.error("Failed to expire product", activeProduct, err);
			snack.enqueueSnackbar(err?.error?.message ?? "Failed to expire product", { variant: "error" });
			throw err;
		}).finally(() => {
			setIsSaving(false);
			props.onClose();
		});
	}

	async function handleUpdateProduct(product: DTO<UnionProduct>, updates: Partial<DTO<UnionProduct>>): Promise<DTO<UnionProduct>> {
		setIsSaving(true);
		return await dispatch(updateProduct({ productId: product.id, updates })).unwrap().catch(err => {
			console.error("Failed to update product", activeProduct, err);
			snack.enqueueSnackbar(err?.error?.message ?? "Failed to update product", { variant: "error" });
			throw err;
		}).finally(() => {
			setIsSaving(false);
		});
	}

	function handleFetchExistingServices(intent: ProductIntent): void {
		if(!order || !rejectState.intent) {
			return;
		}

		setIsLoadingServices(true);
		((rejectState.intent === ProductIntent.DONATE)
			? OrderService.listOrderDonations(order.id, {}, ["delivery", "store", "estimates"])
			: OrderService.listOrderRemovals(order.id, {}, ["delivery", "estimates"])
		)
			.then((services) => {
				setOrderServices([...services]);
			})
			.catch(err => {
				console.error("Failed to load order services");
			})
			.finally(() => {
				setIsLoadingServices(false);
				setFirstLoadedServices(true);
			});
	}

	useEffect(() => {
		if(!order || !rejectState.intent) {
			return;
		}

		handleFetchExistingServices(rejectState.intent);
	}, [rejectState.intent, order]);

	useEffect(() => {
		if(rejectState.intent) {
			setRejectState({
				...rejectState,
				service: null
			});
		}
	}, [rejectState.intent]);

	useEffect(() => {
		if(rejectState.service) {
			const service = orderServices.find(s => s.id === rejectState?.service?.id) ?? rejectState.service;
			setRejectState({
				...rejectState,
				service,
				vehicle: service.vehicle ?? null,
				store: service.store ?? null,
			});
		}
	}, [rejectState.service]);

	const steps = useMemo(() => {
		if(decision === "Approve") {
			return [
				<Box>
					<Text weight="bold">
						This will alert the customer that their item has been approved for sale and will make the product available for purchase.
					</Text>
				</Box>
			];
		}

		if(decision === "Reject") {
			return [
				<RejectListingReasonStep
					state={rejectState}
					updateState={(changes) => {
						setRejectState({
							...rejectState,
							...changes
						});
					}}
				/>,
				<RejectListingServiceStep
					state={rejectState}
					services={orderServices}
					updateState={(changes) => {
						setRejectState({
							...rejectState,
							...changes
						});
					}}
				/>,
				<Box>
					<Text weight="bold">
						This will mark the product as rejected as create the service record. An email will not go out to the customer until the service is confirmed.
					</Text>
				</Box>
			];
		}

		return [

		];
	}, [decision, rejectState]);

	if(!activeProduct) {
		return (
			<Loader visible={true}>

			</Loader>
		);
	}

	return (
		<Box flex>
			<Box gap="small" flex>
				{React.createElement(Steps, {
					children: [
						<Box gap="medium">
							<Box>
								<Button
									primary={decision === "Approve"}
									label="Approve"
									onClick={() => {
										setDecision("Approve");
									}}
								/>
							</Box>
							<Box align="center" justify="center">
								<Text weight="bold">
									or
								</Text>
							</Box>
							<Box>
								<Button
									primary={decision === "Reject"}
									label="Reject"
									onClick={() => {
										setDecision("Reject");
									}}
								/>
							</Box>
						</Box>,
						...steps
					]
				})}
				<Box flex justify="end">
					<ListingDecisionButtonGroup
						isDisabled={!decision || isInvalid}
						isSubmitting={isSaving}
						onSubmit={() => {
							if(overrideSubmit) {
								handleOverrideSubmit();
								return;
							}

							if(hasNext) {
								next();
								return;
							}

							if(decision === "Approve") {
								handleApproveProduct(activeProduct);
								return;
							}

							if(decision === "Reject") {
								if(rejectState.isExpired) {
									handleExpireListing(activeProduct);
									return;
								}

								handleRejectListing(activeProduct, rejectState.reason);
							}
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

interface ListingDecisionButtonGroupProps {
	isDisabled: boolean;
	isSubmitting: boolean;
	onSubmit(): void;
}

const ListingDecisionButtonGroup: React.FC<ListingDecisionButtonGroupProps> = (props) => {
	const { hasPrev, hasNext, prev } = useSteps();
	return (
		<Box direction="row" justify="between">
			<Button
				disabled={!hasPrev}
				label="Previous"
				onClick={prev}
			/>
			<Button
				disabled={props.isDisabled || props.isSubmitting}
				icon={props.isSubmitting ? <Spinner /> : undefined}
				primary
				type="submit"
				onClick={props.onSubmit}
				label={hasNext ? "Next" : "Submit"}
			/>
		</Box>
	);
};

interface ListingDecisionModalProps {
	onClose(): void;
	isExpirationFlow: boolean;
}

export const ListingDecisionModal: React.FC<ListingDecisionModalProps> = (props) => {
	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
			style={{
				minWidth: "600px",
				minHeight: "400px"
			}}
		>
			<ListingDecisionController
				isExpirationFlow={props.isExpirationFlow}
				onClose={props.onClose}
			/>
		</Modal>
	);
};

export const ListingDecisionDetails: React.FC<{ product: DTO<Product>; }> = (props) => {

	const activeProduct = useMemo(() => {
		return props.product;
	}, [props.product]);

	return (
		<Box gap="small">
			<Box align="center">
				<Text weight="bold">
					Listing Decision
				</Text>
			</Box>
			<FormField
				label="Approved"
				contentProps={{ border: undefined }}
			>
				<CheckBox
					readOnly
					disabled
					checked={activeProduct.approved ?? false}
				/>
			</FormField>
			{activeProduct.approved_by && (
				<FormField
					label="Approved By"
				>
					<TextInput
						readOnly
						disabled
						value={`${activeProduct.approved_by?.first_name} ${activeProduct.approved_by?.last_name}`}
					/>
				</FormField>
			)}
			<FormField
				label="Rejected"
				contentProps={{ border: undefined }}
			>
				<CheckBox
					readOnly
					disabled
					checked={activeProduct.rejected ?? false}
				/>
			</FormField>
			{activeProduct.rejected_by && (
				<FormField
					label="Rejected By"
				>
					<TextInput
						readOnly
						disabled
						value={`${activeProduct.rejected_by?.first_name} ${activeProduct.rejected_by?.last_name}`}
					/>
				</FormField>
			)}
			{activeProduct.rejected_at && (
				<LocalDateField
					label="Rejected At"
					value={activeProduct.rejected_at}
				/>
			)}
			{activeProduct.rejected_reason && (
				<FormField
					label="Rejected Reason"
				>
					<TextInput
						readOnly
						disabled
						value={activeProduct.rejected_reason}
					/>
				</FormField>
			)}
		</Box>
	);
};

interface BaseFormProps {
	disabled: boolean;
	selectedVehicle?: DTO<Vehicle>;
	onVehicleSelect(vehicle: DTO<Vehicle>): void;
}

const BaseForm: React.FC<BaseFormProps> = (props) => {
	const dispatch = useAppDispatch();
	const vehicles = useAppSelector(selectVehicles);

	useEffect(() => {
		dispatch(getOrFetchVehicles());
	}, []);

	return (
		<Box flex>
			<Form
				style={{ height: "100%" }}
				validate="submit"
			>
				<Box gap="small" fill="vertical">
					<FormField
						name="vehicle"
						label="Vehicle"
						validate={[
							...getStandardFormValidations()
						]}
						onChange={(event) => {
							const found = vehicles.find(v => v.name === event.target.value);
							if(found) {
								props.onVehicleSelect({ ...found });
							}
						}}
					>
						<Select
							name="vehicle"
							///@ts-ignore
							readOnly={props.disabled}
							///@ts-ignore
							disabled={props.disabled}
							value={props.selectedVehicle?.name}
							options={vehicles.map(v => v.name)}
						/>
					</FormField>
					{props.children}
				</Box>
			</Form>
		</Box>
	);
};

interface SimpleDonationFormProps {
	state: RejectListingState;
	updateState(changes: Partial<RejectListingState>): void;
}

const SimpleDonationForm: React.FC<SimpleDonationFormProps> = (props) => {
	const dispatch = useAppDispatch();
	const stores = useAppSelector(selectStores);

	useEffect(() => {
		dispatch(listStores({ service: ProductIntent.DONATE }));
	}, []);

	return (
		<BaseForm
			disabled={!!props.state.service}
			onVehicleSelect={(vehicle) => {
				props.updateState({ vehicle });
			}}
			selectedVehicle={props.state.vehicle ?? undefined}
		>
			<FormField
				label="Store"
				name="store"
				validate={[
					...getStandardFormValidations()
				]}
				onChange={(event) => {
					const store = stores.find(v => v.name === event.target.value);
					if(store) {
						props.updateState({ store });
					}
				}}
			>
				<Select
					name="store"
					///@ts-ignore
					readOnly={!!props.state.service}
					///@ts-ignore
					disabled={!!props.state.service}
					options={stores.map(s => s.name)}
					value={props.state.store?.name ?? undefined}
				/>
			</FormField>
		</BaseForm>
	);
};

interface SimpleRemovalFormProps {
	state: RejectListingState;
	updateState(changes: Partial<RejectListingState>): void;
}

const SimpleRemovalForm: React.FC<SimpleRemovalFormProps> = (props) => {
	return (
		<BaseForm
			disabled={!!props.state.service}
			selectedVehicle={props.state.vehicle ?? undefined}
			onVehicleSelect={(vehicle) => {
				props.updateState({ vehicle });
			}}
		/>
	);
};