import type { Customer, DTO, ServerResponse, Store, User } from "../../types";
import { RequestService } from "./request";

export const UserService = {
	async getSelf(): Promise<DTO<User>> {
		const result = await RequestService
			.get<ServerResponse<DTO<User>>>("/api/v1/user/me");
		return result.data.result;
	},

	async getCustomerSelf(): Promise<DTO<Customer> | null> {
		const result = await RequestService
			.get<ServerResponse<DTO<Customer> | null>>("/api/v1/customer/me");
		return result.data.result;
	},

	async getUserStores(): Promise<DTO<Store>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Store>[]>>("/api/v1/user/me/store");
		return result.data.result;
	},
};