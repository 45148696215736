import { Anchor, Box, Footer as GrommetFooter, Image, Text } from "grommet";
import { FacebookOption, Instagram, Twitter } from "grommet-icons";
import { ButtonWhite, Link } from "../components";
import logo from "../../../img/logo.png";
import config from "../../../app/config";
import { useMemo } from "react";
import { useAppSelector } from "../../../app/store";
import { selectForceHideFooter } from "../../../app/store/application";

export const Footer: React.FC = () => {
	const isForcedHidden = useAppSelector(selectForceHideFooter);

	const shouldHide = useMemo(() => {
		return isForcedHidden;
	}, [isForcedHidden]);

	if(shouldHide) return (<></>);

	return (
		<Box id="footer" background="dark-1">
			<GrommetFooter align="start" justify="start" direction="row" background="dark-1" pad={{ horizontal: "large", top: "large", bottom: "small" }}>
				<Box gap="medium">
					<Text weight="bold" size="small">
						Legal
					</Text>
					<Box>
						<Link weight="normal" size="small" color="white" to="/terms">Terms</Link>
						<Link weight="normal" size="small" color="white" to="/privacy">Privacy</Link>
					</Box>
				</Box>
				<Box gap="medium">
					<Text weight="bold" size="small">
						Get Help
					</Text>
					<Box>
						<Link weight="normal" size="small" color="white" to="/faq">FAQs</Link>
					</Box>
				</Box>
			</GrommetFooter>
			<GrommetFooter direction="row" background="dark-1" pad={{ horizontal: "large", vertical: "small" }}>
				<Box align="center" justify="center" flex="grow">
					<Box pad="none" direction="row">
						<Anchor
							a11yTitle="Follow us on Instagram"
							href="https://www.instagram.com/regoapp.io"
							icon={<Instagram color="brand" size="medium" />}
							target="_blank"
							style={{ padding: "4" }}
						/>
						<Anchor
							a11yTitle="Check us out on Facebook"
							href="https://www.facebook.com/regoapp.io"
							icon={<FacebookOption color="brand" size="medium" />}
							target="_blank"
							style={{ padding: "4" }}
						/>
						<Anchor
							a11yTitle="Follow us on Twitter"
							href="https://twitter.com/"
							icon={<Twitter color="brand" size="medium" />}
							target="_blank"
							style={{ padding: "4" }}
						/>
					</Box>
				</Box>
			</GrommetFooter>
			<GrommetFooter direction="row" background="dark-1" align="center" justify="between" pad="large">
				<Box direction="row" align="center" gap="small">
					<Image src={logo} width={100} />
				</Box>
				<Box>
					<ButtonWhite secondary label="Call Us" href={`tel:${config.contact.phone}`} />
				</Box>
			</GrommetFooter>
		</Box>
	);
};