import { DTO, Product, Item, ProductCondition, Brand, ProductIntent, ProductDimensions, CustomerProduct } from "@rego-app/common";
import { Form, Box, FormField, Text, TextInput, Select, CheckBox, TextArea, Grid, Card, CardHeader, CardBody, Button } from "grommet";
import { Money } from "grommet-icons";
import { useSnackbar } from "notistack";
import { Fragment, useMemo } from "react";
import { useEffect, useState } from "react";
import { useSteps } from "react-step-builder";
import { ReferenceService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectForcedIntent, selectOrderIntent } from "../../../app/store/order";
import { fetchBrands, selectBrands } from "../../../app/store/reference";
import { getNumberFormValidations, getStandardFormValidations } from "../../../helpers";
import { SlimHeading } from "../../common";
import { useIntent } from "../hooks";
import { FormProgressButtons } from "./FormProgressButtons";

interface ProductDetailsFormProps {
	isSaving: boolean;
	product: Partial<DTO<CustomerProduct>>;
	existingProducts: DTO<CustomerProduct>[];
	onProductUpdated(product: Partial<DTO<CustomerProduct>>): Promise<void>;
}

interface ExtendedProductState {
	weight_input?: number;
	height_input?: number;
	width_input?: number;
	depth_input?: number;
}

interface ProductDetailsState extends Partial<DTO<CustomerProduct>>, ExtendedProductState {
	knowsDimensions: boolean;
	knowsBrandName: boolean;
}

export const ProductDetailsForm: React.FC<ProductDetailsFormProps> = (props) => {
	const { next } = useSteps();
	const intent = useIntent();
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const brands = useAppSelector(selectBrands);
	const [autofillItems, setAutofillItems] = useState<Item[]>([]);

	const [state, setState] = useState<ProductDetailsState>({
		...props.product,
		goal: intent.forcedIntent ?? intent.goal ?? undefined,
		intent: intent.forcedIntent ?? intent.goal ?? undefined,
		knowsDimensions: !!(props.product?.dimensions?.width) || false,
		knowsBrandName: !!(props.product?.brand || props.product?.brand_input) || false,
		weight_input: props.product.weight?.value ?? 0,
		depth_input: props.product.dimensions?.depth ?? 0,
		width_input: props.product.dimensions?.width ?? 0,
		height_input: props.product.dimensions?.height ?? 0
	});
	const [letRegoPrice, setLetRegoPrice] = useState(false);

	useEffect(() => {
		if(brands.length === 0) {
			dispatch(fetchBrands());
		}
	}, [brands]);

	useEffect(() => {
		setState({
			...state,
			...props.product
		});
	}, [props.product]);

	useEffect(() => {
		const dimensions = props.product.dimensions;
		if(dimensions?.width || dimensions?.height || dimensions?.width) {
			setState({
				...state,
				knowsDimensions: true,
				width_input: dimensions.width,
				height_input: dimensions.height,
				depth_input: dimensions.depth
			});
		}
	}, [props.product]);


	function updateFormState(changes: Partial<DTO<Product>>): void {
		const item = (autofillItems.length > 0 && changes.item_input)
			? autofillItems.find(i => i.name === changes.item_input) ?? null
			: changes.item ?? state.item ?? null;

		setState((state) => {
			const newState = {
				...state,
				...changes,
				item
			};
			return newState;
		});
	}

	function queryItems(query: string): void {
		ReferenceService.queryItems(query)
			.then(results => {
				setAutofillItems([...results]);
			})
			.catch(err => {
				console.error("Failed to query items", err);
			});
	}

	return (
		<Form
			value={state}
			validate="submit"
			onChange={updateFormState}
			onSubmit={() => {
				props.onProductUpdated(state)
					.then(() => {
						console.debug("Product update complete ... calling next");
						next();
					})
					.catch(err => {
						console.error("Failed to submit ... error during product update", err);
						snack.enqueueSnackbar("We ran into an issue saving your information", {
							variant: "error"
						});
					});
			}}
		>
			<Box gap="medium">
				<Box align="center">
					<SlimHeading level="3" textAlign="center">
						{(!state.item && !state.item_input)
							? "New Item Details"
							: "Update Item Details"}
					</SlimHeading>
				</Box>
				<Box gap="small">
					{!intent.isForced && (
						<FormField
							name="intent"
							label="What do you want to do with this item?"
						>
							<Select
								name="intent"
								options={[ProductIntent.SELL, ProductIntent.DONATE, ProductIntent.JUNK]}
							/>
						</FormField>
					)}
					<FormField
						label="What kind of item?"
						help="Start typing to see options"
						name="item_input"
						onChange={(event) => {
							queryItems(event.target.value);
						}}
						validate={[
							...getStandardFormValidations()
						]}
					>
						<TextInput
							name="item_input"
							suggestions={autofillItems.map(s => s.name)}
							onSuggestionSelect={(selected) => {
								updateFormState({
									item: autofillItems.find(i => i.name === selected.suggestion)
								});
							}}
						/>
					</FormField>
					{!state.item && (
						<FormField
							label="Estimated Weight"
							name="weight_input"
							info="pounds (lbs)"
							help="If you don't find your item in our list, please enter the estimated weight"
							onChange={(event) => {
								const value = event.target.value;
								if(isNaN(Number(value))) {
									return;
								}
								updateFormState({
									weight: {
										unit: "pounds",
										value: Number(value ?? 0)
									}
								});
							}}
							validate={[
								...getStandardFormValidations(),
								(value) => {
									if(isNaN(Number(value))) {
										return {
											message: "Please enter a valid number",
											status: "error"
										};
									}
								}
							]}
						>
							<TextInput
								name="weight_input"
								inputMode="numeric"
							/>
						</FormField>
					)}
					{(state.intent === ProductIntent.SELL || state.intent === ProductIntent.DONATE) && (
						<Fragment>
							<FormField
								label="Item Condition"
								name="condition"
								help="Choose the closest option to your items condition"
								validate={[
									...getStandardFormValidations()
								]}
							>
								<Select required name="condition" options={Object.values(ProductCondition).sort()} />
							</FormField>
							<FormField
								contentProps={{ border: undefined }}
								name="knowsBrandName"
							>
								<CheckBox
									label={<Text weight="bold">Do you know the brand?</Text>}
									name="knowsBrandName"
								/>
							</FormField>
							{state.knowsBrandName && (
								<FormField
									label="Brand Name"
									name="brand_input"
									help="If you don't see your option in our list you can enter your own"
									validate={[
										...getStandardFormValidations()
									]}
								>
									<TextInput
										name="brand_input"
										suggestions={brands.map(b => b.name)}
										onSuggestionSelect={(selected) => {
											updateFormState({
												brand: brands.find(i => i.name === selected.suggestion) as Brand | undefined
											});
										}}
									/>
								</FormField>
							)}
						</Fragment>
					)}
					{state.intent === ProductIntent.SELL && (
						<Box>
							<FormField
								name="description"
								label="Item Description"
								help="A short description shown to potential buyers"
								validate={[
									...getStandardFormValidations()
								]}
							>
								<TextArea
									name="description"
								/>
							</FormField>
							{!letRegoPrice && (
								<FormField
									name="price"
									label="Sale Price"
									disabled={letRegoPrice}
									validate={[
										...getStandardFormValidations(),
										...getNumberFormValidations()
									]}
								>
									<TextInput
										name="price"
										inputMode="numeric"
										value={state.price || undefined}
										disabled={letRegoPrice}
									/>
								</FormField>
							)}
							<FormField
								validate={undefined}
								contentProps={{ border: undefined }}
							>
								<CheckBox
									checked={letRegoPrice}
									label={<Text weight="bold">Or let us price for you</Text>}
									onChange={(event) => {
										setLetRegoPrice(event.target.checked);
									}}
								/>
							</FormField>
							<FormField
								name="knowsDimensions"
								contentProps={{ border: undefined }}
							>
								<CheckBox
									name="knowsDimensions"
									label={<Text weight="bold">Do you know the dimensions?</Text>}
								/>
							</FormField>
							{state.knowsDimensions && (
								<Grid columns={{ count: 3, size: "auto" }} gap="small">
									<FormField
										label="Height"
										name="height_input"
										info="inches"
										onChange={(event) => {
											const value = event.target.value;
											if(isNaN(Number(value))) {
												return;
											}
											updateFormState({
												dimensions: {
													...state.dimensions,
													height: Number(event.target.value ?? 0)
												} as ProductDimensions
											});
										}}
										validate={[
											...getStandardFormValidations(),
											(value) => {
												if(isNaN(Number(value))) {
													return {
														message: "Please enter a valid number",
														status: "error"
													};
												}
											}
										]}
									>
										<TextInput
											name="height_input"
											inputMode="numeric"
										/>
									</FormField>
									<FormField
										label="Width"
										name="width_input"
										info="inches"
										onChange={(event) => {
											const value = event.target.value;
											if(isNaN(Number(value))) {
												return;
											}
											updateFormState({
												dimensions: {
													...state.dimensions,
													width: Number(event.target.value ?? 0)
												} as ProductDimensions
											});
										}}
										validate={[
											...getStandardFormValidations(),
											(value) => {
												if(isNaN(Number(value))) {
													return {
														message: "Please enter a valid number",
														status: "error"
													};
												}
											}
										]}
									>
										<TextInput
											name="width_input"
											inputMode="numeric"
										/>
									</FormField>
									<FormField
										label="Depth"
										name="depth_input"
										info="inches"
										onChange={(event) => {
											const value = event.target.value;
											if(isNaN(Number(value))) {
												return;
											}
											updateFormState({
												dimensions: {
													...state.dimensions,
													depth: Number(event.target.value ?? 0)
												} as ProductDimensions
											});
										}}
										validate={[
											...getStandardFormValidations(),
											(value) => {
												if(isNaN(Number(value))) {
													return {
														message: "Please enter a valid number",
														status: "error"
													};
												}
											}
										]}
									>
										<TextInput
											name="depth_input"
											inputMode="numeric"
										/>
									</FormField>
								</Grid>
							)}
						</Box>
					)}
				</Box>
				<Box>
					<FormProgressButtons
						products={props.existingProducts}
						isLoading={props.isSaving}
					/>
				</Box>
			</Box>
		</Form>
	);
};