import { LinearProgress } from "@material-ui/core";
import { DTO, Payment, Timezone } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, ColumnConfig, DataTable, DataTableProps, Form, FormField, Grid, Layer, Spinner, TextInput } from "grommet";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PaymentService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchPayment, fetchPayments, selectActivePayment, selectPayments } from "../../../app/store/admin";
import { formatCurrency, getNumberFormValidations, getStandardFormValidations, parseTimestampFromUTC } from "../../../helpers";
import { SlimHeading, useWindowDimensions } from "../../common";
import { CapturePaymentModal, PaymentDetails, PaymentLinks } from "../components";



const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code;

const recentPaymentColumns: ColumnConfig<DTO<Payment>>[] = [
	{
		property: "number",
		header: "Payment Number",
		primary: true,
		sortable: true,
		render: (payment) => String(payment.number)
	},
	{
		property: "authorized_at",
		header: "Authorized At",
		primary: false,
		sortable: true,
		render: (payment) => parseTimestampFromUTC(payment.authorized_at, timezone)
	},
	{
		property: "captured_at",
		header: "Captured At",
		primary: false,
		sortable: true,
		render: (payment) => parseTimestampFromUTC(payment.captured_at, timezone)
	},
	{
		property: "amount_authorized",
		header: "Amount Authorized",
		primary: false,
		sortable: true,
		render: (payment) => formatCurrency(payment.amount_authorized || 0)
	},
	{
		property: "amount_captured",
		header: "Amount Captured",
		primary: false,
		sortable: true,
		render: (payment) => formatCurrency(payment.amount_captured || 0)
	}
];

interface AdminPaymentsPageState {
	isLoading: boolean;
	loadedPayments: boolean;
	isModalVisible: boolean;
	showPayment: boolean;
	activePayment?: DTO<Payment>;
}

export const AdminPaymentsPage: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [state, setState] = useState<AdminPaymentsPageState>({
		isLoading: false,
		showPayment: false,
		isModalVisible: false,
		loadedPayments: false
	});

	const payments = useAppSelector(selectPayments);

	function handleNavigate(payment: DTO<Payment>): void {
		dispatch(push(`/admin/dashboard/payments/${payment.id}`));
	}

	useEffect(() => {
		setState({ ...state, isLoading: true, loadedPayments: false });

		dispatch(fetchPayments({ filters: {} }))
			.catch(err => {
				console.error(`Failed to fetch recent payments`, err);
				snack.enqueueSnackbar("Failed to load recent payments", {
					variant: "error"
				});
			})
			.finally(() => {
				setState(state => {
					return { ...state, loadedPayments: true };
				});
			});
	}, []);

	useEffect(() => {
		if(state.loadedPayments) {
			setState({
				...state,
				isLoading: false
			});
		}
	}, [state.loadedPayments, state.isLoading]);

	function isPaymentExpiring(authorizedAt: Date | string | null): boolean {
		if(!authorizedAt) return false;
		const sixDaysAgo = moment().subtract(6, "days").startOf("day").unix();
		return moment(authorizedAt).unix() < sixDaysAgo;
	}

	const tableRowProps = useMemo(() => {
		return payments.filter(p => !p.captured && isPaymentExpiring(p.authorized_at)).reduce((p, c) => {
			if(!p) return { [String(c.number)]: { background: "status-warning" } };
			p[String(c.number)] = { background: "status-warning" };
			return p;
		}, {} as DataTableProps["rowProps"]);
	}, [payments]);

	return (
		<Box margin="small" gap="large">
			<Box gap="small">
				<Box>
					<SlimHeading level="3">Recent Payments</SlimHeading>
					<Box>
						{(state.isLoading || !state.loadedPayments) && (
							<LinearProgress />
						)}
						<DataTable
							pad="small"
							columns={recentPaymentColumns}
							data={payments}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
							rowProps={tableRowProps}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

interface PaymentDetailsState {
	isLoadingPayment: boolean;
	isCapturingPayment: boolean;
	isCapturingPaymentFlow: boolean;
}

export const AdminPaymentDetailsScreen: React.FC = (props) => {
	const params = useParams();
	const snack = useSnackbar();
	const dimensions = useWindowDimensions();
	const dispatch = useAppDispatch();

	const payment = useAppSelector(selectActivePayment);

	const [state, setState] = useState<PaymentDetailsState>({
		isLoadingPayment: false,
		isCapturingPayment: false,
		isCapturingPaymentFlow: false
	});

	const columns = useMemo(() => {
		switch(dimensions.size) {
			case "small": {
				return 1;
			}
			case "medium": {
				return 1;
			}
			case "large": {
				return 2;
			}
			case "xlarge": {
				return 2;
			}
		}
	}, [dimensions.size]);

	function renderCapturePaymentModal(): void {
		setState({
			...state,
			isCapturingPaymentFlow: true
		});
	}

	function handleFetchPayment(paymentId: string): void {
		setState({
			...state,
			isLoadingPayment: true
		});

		dispatch(fetchPayment({ paymentId })).unwrap()
			.catch(err => {
				console.error("Failed to load payment", err);
				snack.enqueueSnackbar("Failed to load payment", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isLoadingPayment: false
				});
			});
	}

	useEffect(() => {
		if(!params.paymentId) {
			dispatch(push("/admin/dashboard/payments"));
			return;
		}

		handleFetchPayment(params.paymentId);
	}, []);

	return (
		<Box margin="large" gap="small">
			{(payment && state.isCapturingPaymentFlow) && (
				<CapturePaymentModal
					payment={payment}
					onClose={() => {
						setState({
							...state,
							isCapturingPaymentFlow: false
						});
					}}
				/>
			)}
			<Grid columns={{ count: columns, size: "auto" }} gap="small">
				<Box flex>
					{payment
						? (
							<Box gap="small" flex>
								<SlimHeading level="3">
									Payment Details
								</SlimHeading>
								<PaymentDetails
									payment={payment}
									onPaymentCapture={renderCapturePaymentModal}
								/>
							</Box>
						)
						: (
							<Box align="center" justify="center" flex>
								<Spinner size="large" />
							</Box>
						)
					}
				</Box>
				<Box flex>
					{payment
						? (
							<Box gap="small" flex>
								<SlimHeading level="3">
									Payment Links
								</SlimHeading>
								<PaymentLinks
									payment={payment}
								/>
							</Box>
						)
						: (
							<Box align="center" justify="center" flex>
								<Spinner size="large" />
							</Box>
						)
					}
				</Box>
			</Grid>
		</Box>
	);
};