import React from 'react';

import { Box, Diagram, DiagramConnectionAnchor, DiagramConnectionType, Grid, Heading, ResponsiveContext, Stack } from 'grommet';
import { Gift, Lounge, Money, Trash } from 'grommet-icons';

const Animated = () => {
	const anchor: DiagramConnectionAnchor = "vertical";
	const type: DiagramConnectionType = "curved";
	const connections = [
		{ id: "sell", anchor, color: "brand", thickness: "small", type, toTarget: "2", fromTarget: "1", round: true },
		{ id: "donate", anchor, color: "brand", thickness: "small", type, toTarget: "3", fromTarget: "1", round: true },
		{ id: "junk", anchor, color: "brand", thickness: "small", type, toTarget: "4", fromTarget: "1", round: true },
	];

	function getIconSize(size: string): "large" | "xlarge" {
		switch(size) {
			case "xsmall":
			case "small": {
				return "large";
			}
			default: {
				return "xlarge";
			}
		}
	}

	return (
		<ResponsiveContext.Consumer>
			{size => (
				<Box align="center" fill>
					<Stack fill>
						<Box direction="column" align="center" gap="large">
							<Box align="center" id="1">
								<Lounge id="1" color="neutral-2" size={getIconSize(size)} />
								<Heading margin="none" textAlign="center" level="4">Sellers</Heading>
								<Heading margin="none" textAlign="center" level="4">Donors</Heading>
								<Heading margin="none" textAlign="center" level="4">Need furniture out'ers</Heading>
								<Box pad="small"></Box>
								<Heading margin="none" textAlign="center" level="3">Connect With</Heading>
								<Box pad="small"></Box>
							</Box>
							<Box pad="small"></Box>
							<Grid columns={{ count: 3, size: "auto" }}>
								<Box pad="medium" direction="column" align="center" id="2">
									<Money color="neutral-2" size={getIconSize(size)} />
									<Heading textAlign="center" level="4">Buyers</Heading>
								</Box>
								<Box pad="medium" direction="column" align="center" id="3">
									<Gift color="neutral-2" size={getIconSize(size)} />
									<Heading textAlign="center" level="4">Charitable Organizations</Heading>
								</Box>
								<Box pad="medium" direction="column" align="center" id="4">
									<Trash color="neutral-2" size={getIconSize(size)} />
									<Heading textAlign="center" level="4">Disposal Services</Heading>
								</Box>
							</Grid>
						</Box>
						<Diagram
							z="-100"
							animation={{ type: "draw", duration: 1500 }}
							connections={connections} />
					</Stack>
				</Box>
			)}
		</ResponsiveContext.Consumer>
	);
};

export { Animated as Diagram };