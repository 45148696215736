import { Donation, DonationMedia, DTO, InboundReferral, Order, ProductIntent, Store } from "@rego-app/common";
import { Anchor, Box, FormField, Image, Layer, Spinner, TextInput } from "grommet";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../app/store";
import { listStores } from "../../../app/store/admin";
import { Loader, SlimHeading } from "../../common";
import placeholder from "../../../img/placeholder.jpg";
import { Chip } from "@mui/material";
import { push } from "connected-react-router";
import { OrderService } from "../../../app/services";
import { isStorePartner } from "../../../helpers";

interface CreateDonationFormState {
	order?: DTO<Order>;
	stores: DTO<Store>[];
	selectedStore?: DTO<Store>;
	wasStoresLoaded: boolean;
	wasLoaded: boolean;
}

export const CreateDonationForm: React.FC<{ order?: DTO<Order>, referral?: DTO<InboundReferral>; }> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const [state, setState] = useState<CreateDonationFormState>({
		order: props.order,
		stores: [],
		wasLoaded: false,
		wasStoresLoaded: false
	});

	useEffect(() => {
		if(props.referral?.partner && isStorePartner(props.referral.partner)) {
			setState({
				...state,
				selectedStore: props.referral.partner
			});
		}
	}, [props.referral]);

	useEffect(() => {
		if(!state.wasLoaded) {
			if(state.wasStoresLoaded) {
				setState({
					...state,
					wasLoaded: true
				});
			}
		}
	}, [state.wasStoresLoaded]);

	function setSelectedStore(store?: DTO<Store>): void {
		setState({
			...state,
			selectedStore: store
		});
	}

	function loadDonationStores(): void {
		dispatch(listStores({ service: ProductIntent.DONATE })).unwrap()
			.then((stores) => {
				setState({
					...state,
					stores
				});
			})
			.catch(err => {
				console.error("Failed to load stores", err);
				snack.enqueueSnackbar("Failed to load stores", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					wasStoresLoaded: true
				});
			});
	}

	useEffect(() => {
		loadDonationStores();
	}, []);

	return (
		<Layer>
			<Loader visible={!state.wasLoaded}>
				<Box margin="small">
					<FormField
						label="Store"
					>
						<TextInput
							suggestions={state.stores.map(store => store.name)}
							onSuggestionSelect={(value) => {
								setSelectedStore(state.stores.find(s => s.name === value.suggestion));
							}}
						/>
					</FormField>
				</Box>
			</Loader>

		</Layer>
	);
};


interface DonationSummaryProps {
	donation: DTO<Donation>;
	displayContext?: "orders" | "donation";
}

export const DonationSummary: React.FC<DonationSummaryProps> = (props) => {
	const dispatch = useAppDispatch();

	const donationStatus = useMemo((): string => {
		const service = props.donation;

		if(service.completed) {
			return "Completed";
		}

		if(service.started) {
			return "In Progress";
		}

		if(service.scheduled) {
			return "Scheduled";
		}

		return "Ready to Schedule";
	}, [props.donation]);

	function handleNavigate(): void {
		dispatch(push(`/admin/dashboard/donations/${props.donation.id}`));
	}

	return (
		<Box
			border
			hoverIndicator
			margin="small"
			onClick={handleNavigate}
		>
			<Box direction="row" gap="small">
				<Box align="center" justify="center">
					<Box margin="small">
						<Image height={75} src={props.donation.store.logo_url ?? placeholder} />
					</Box>
				</Box>
				<Box margin="small" gap="small">
					<SlimHeading level="4">
						{props.donation.store.name}
					</SlimHeading>
					<Box align="end" justify="end" gap="small" direction="row">
						<Chip label={props.donation.visible ? "Visible" : "Not Visible"} />
						<Chip label={donationStatus} />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export const DonationMediaItem: React.FC<{ media: DTO<DonationMedia>, donation: DTO<Donation>; }> = (props) => {
	const [src, setSrc] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		OrderService.getMediaURL(props.donation.id, props.media.id)
			.then(res => {
				setSrc(res);
			})
			.catch(err => {
				console.error("Failed to load media url", err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [props.donation, props.media]);

	return (
		<Box fill="horizontal">
			{(isLoading || !src)
				? (
					<Spinner />
				)
				: (
					<Anchor
						href={src}
						target="_blank"
						label={props.media.name}
					/>
				)}
		</Box>
	);
};

export const DonationMediaContainer: React.FC<{ media: DTO<DonationMedia>[]; donation: DTO<Donation>; }> = (props) => {
	return (
		<Box gap="small">
			{props.media.map(media => (
				<DonationMediaItem
					media={media}
					donation={props.donation}
				/>
			))}
		</Box>
	);
};