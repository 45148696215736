import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import type { DTO } from "../../types";
import { Store, ProductQuery } from "@rego-app/common";
import { PartnerService } from "../services";

export interface PartnerState {
	activeStore: DTO<Store> | null;
	priceLow: number | null;
	priceHigh: number | null;
	filteredStores: string[];
	filteredCategories: string[];
}

const initialState: PartnerState = {
	activeStore: null,
	priceLow: null,
	priceHigh: null,
	filteredStores: [],
	filteredCategories: []
};

export const fetchStore = createAsyncThunk<DTO<Store>, string, { state: RootState; }>(
	"shop/fetchStore",
	async (storeId, thunk) => {
		return await PartnerService.getStore(storeId).catch(err => thunk.rejectWithValue(err));
	}
);


export const shopSlice = createSlice({
	name: "shop",
	initialState,
	reducers: {
		setPriceLowFilter(state: typeof initialState, action: PayloadAction<number | null>): void {
			state.priceLow = action.payload;
		},
		setPriceHighFilter(state: typeof initialState, action: PayloadAction<number | null>): void {
			state.priceHigh = action.payload;
		},
		setFilteredStores(state: typeof initialState, action: PayloadAction<string[]>): void {
			state.filteredStores = [...action.payload];
		},
		setFilteredCategories(state: typeof initialState, action: PayloadAction<string[]>): void {
			state.filteredCategories = [...action.payload];
		},
		setFilters(state: typeof initialState, action: PayloadAction<ProductQuery>): void {
			const { stores, categories, priceGreaterThan, priceLessThan } = action.payload;
			state.filteredStores = stores ?? [];
			state.filteredCategories = categories ?? [];
			state.priceHigh = priceLessThan ?? null;
			state.priceLow = priceGreaterThan ?? null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchStore.fulfilled, (state, action) => {
				state.activeStore = action.payload;
			});
	}
});

export const {
	setFilters,
	setPriceHighFilter,
	setPriceLowFilter,
	setFilteredStores,
	setFilteredCategories
} = shopSlice.actions;

export const selectPriceLowFilter = (state: RootState): number | null => state.shop.priceLow;
export const selectPriceHighFilter = (state: RootState): number | null => state.shop.priceHigh;
export const selectFilteredStores = (state: RootState): string[] => state.shop.filteredStores;
export const selectFilteredCategories = (state: RootState): string[] => state.shop.filteredCategories;

export const selectProductFilters = (state: RootState): ProductQuery => {
	return {
		stores: selectFilteredStores(state),
		categories: selectFilteredCategories(state),
		priceGreaterThan: selectPriceLowFilter(state) ?? undefined,
		priceLessThan: selectPriceHighFilter(state) ?? undefined
	};
};

export const selectActiveStore = (state: RootState): DTO<Store> | null => state.shop.activeStore;

export default shopSlice.reducer;