
class FeatureFlag {
	public name: string;

	constructor(
		name: string,
		private enabled: boolean
	) {
		this.name = name;
	}

	enable(): void {
		this.enabled = true;
	}

	disable(): void {
		this.enabled = false;
	}

	isEnabled(): boolean {
		return this.enabled;
	}
}

export const FLAGS = {
	DISABLE_PRODUCT_DIMENSIONS: new FeatureFlag(
		"DISABLE_PRODUCT_DIMENSIONS",
		true
	),
	ENABLE_AUTOMATIC_PRODUCT_SCAN: new FeatureFlag(
		"ENABLE_AUTOMATIC_PRODUCT_SCAN",
		false
	)
};