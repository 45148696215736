import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectIsLoggedIn } from "../../../app/store/application";
import { selectClientSecret, selectPayment } from "../../../app/store/checkout";
import { CheckoutForm } from "../components";
import { useStripeProvider } from "../provider";

export const Checkout: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const stripe = useStripeProvider();
	const payment = useAppSelector(selectPayment);
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const clientSecret = useAppSelector(selectClientSecret);

	const elementsOptions: StripeElementsOptions = {
		clientSecret
	};

	useEffect(() => {
		if(!isLoggedIn) {
			console.debug("User is not logged in");
			dispatch(push("/dashboard"));
		}

		if(!clientSecret) {
			console.debug("Could not find client secret in store ... pushing to dashboard");
			dispatch(push("/dashboard"));
			return;
		}

		if(!payment) {
			console.debug("Could not find payment in store ... pushing to dashboard");
			dispatch(push("/dashboard"));
			return;
		}
	}, []);

	return (
		<Elements stripe={stripe.stripePromise} options={elementsOptions}>
			<CheckoutForm
				paymentId={payment?.id ?? ""}
				clientSecret={clientSecret}
			/>
		</Elements>
	);
};