import { Attribution, DTOWithoutBase } from "@rego-app/common";

export const UtilService = {

	buildAttribution(params: URLSearchParams): Omit<DTOWithoutBase<Attribution>, "visitor" | "referrals"> {
		return {
			referer: document.referrer,
			utm_campaign: params.get("utm_campaign") ?? "",
			utm_content: params.get("utm_content") ?? "",
			utm_medium: params.get("utm_medium") ?? "",
			utm_source: params.get("utm_source") ?? "",
			utm_term: params.get("utm_term") ?? ""
		};
	},

	formatPhoneToNumber(input: string): string {
		input = input.replace(/\D/g, "");
		if(input.length <= 9 || input.length >= 12) {
			throw new Error(`Invalid phone [${input}]`);
		}

		if(input.length === 10 && input.charAt(0) === "1") {
			throw new Error(`Invalid phone [${input}]`);
		}

		if(input.length === 10) {
			return "1" + input;
		}

		return input;
	},

	formatPhoneToDisplay(input: string): string {
		input = input.replace(/\D/g, "");
		if(input.length <= 9 || input.length >= 12) {
			throw new Error(`Invalid phone [${input}]`);
		}

		const regex = /(\d{3})(\d{3})(\d{4})/;
		input = (input.length === 10)
			? input
			: input.slice(1);

		const matches = input.match(regex);
		if(!matches) {
			throw new Error(`Could not validate phone [${input}]`);
		}

		return `1 (${matches[1]}) ${matches[2]}-${matches[3]}`;
	}
};