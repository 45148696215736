import { useState, useEffect, useMemo } from "react";

type WindowSize =
	| "small"
	| "medium"
	| "large"
	| "xlarge";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;

	const size = ((): WindowSize => {
		if(width >= 1200) {
			return "xlarge";
		}

		if(width >= 900) {
			return "large";
		}

		if(width >= 600) {
			return "medium";
		}

		return "small";
	})();

	return {
		size,
		width,
		height
	};
}

export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

export default useWindowDimensions;

export function useResponsiveColumns(small: number, medium: number, large: number, xlarge: number) {
	const { size } = useWindowDimensions();
	const columns = useMemo(() => {
		switch(size) {
			case "small": {
				return small;
			}
			case "medium": {
				return medium;
			}
			case "large": {
				return large;
			}
			case "xlarge": {
				return xlarge;
			}
		}
	}, [size, small, medium, large, xlarge]);

	return columns;
}