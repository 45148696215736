import { DTO, Store } from "@rego-app/common";
import React, { createContext, useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/store";
import { fetchUserStores } from "../../../app/store/partners";

export const StoreContext = createContext<DTO<Store> | null>(null);

export const StoreProvider: React.FC = (props) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchUserStores()).unwrap()
			.catch(err => {
				console.error("Failed to load user stores");
			});
	}, []);

	return (
		<StoreContext.Provider value={null}>
			{props.children}
		</StoreContext.Provider>
	);
};

export function useStoreProvider() {
	const [selectedStore, setSelectedStore] = useState<DTO<Store> | null>(null);

	return {
		selectedStore,
		setSelectedStore
	};
}