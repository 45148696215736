import { Divider } from "@mui/material";
import { DeliveryType, DTO, Product } from "@rego-app/common";
import { push } from "connected-react-router";
import { Anchor, Box, Button, FormField, Grid, GridExtendedProps, Heading, Image, Select, Spinner, Text } from "grommet";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useMemo, useState } from "react";
import { ProductService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCustomer, setRequiresLogin, setRequiresRegistration } from "../../../app/store/application";
import { convertCartToPurchase, createPurchase, fetchCustomerCart, removeProductFromCart, selectCartProductQuantity, selectCartProducts, selectIsPurchaseInProgress, selectLocalCart, selectPurchase, selectShouldBeginCheckout, setIsPurchaseInProgress, setShouldBeginCheckout, syncLocalCartToServer, updateCartProductQuantity } from "../../../app/store/purchase";
import { formatCurrency, isErrorResponse } from "../../../helpers";
import { ElevatedCard, Loader, useWindowDimensions } from "../../common";
import { LoginRequired } from "../../order/components/LoginRequired";
import { CartContainer, ProductCard } from "../components";
import placeholder from "../../../img/placeholder.jpg";

export const CartPage: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const purchase = useAppSelector(selectPurchase);
	const customer = useAppSelector(selectCustomer);
	const localCart = useAppSelector(selectLocalCart);
	const shouldBeginCheckout = useAppSelector(selectShouldBeginCheckout);
	const isPurchaseInProgress = useAppSelector(selectIsPurchaseInProgress);
	const snack = useSnackbar();
	const [step, setStep] = useState(4);
	const dimensions = useWindowDimensions();
	const cart = useAppSelector(selectCartProducts);
	const cartProductQuantity = useAppSelector(selectCartProductQuantity);
	const [wasLoaded, setWasLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showLoginPrompt, setShowLoginPrompt] = useState(false);

	function handleNavigateToShop(): void {
		dispatch(push("/shop"));
	}

	useEffect(() => {
		if(dimensions.width >= 1200) {
			setStep(4);
			return;
		}

		if(dimensions.width >= 900) {
			setStep(3);
			return;
		}

		if(dimensions.width >= 600) {
			setStep(2);
			return;
		}

		setStep(1);
	}, [dimensions.width]);

	function handleLoginSelected(): void {
		setShowLoginPrompt(false);
		dispatch(setRequiresLogin(true));
	}

	function handleRegisterSelected(): void {
		dispatch(setRequiresRegistration(true));
	}

	function refreshCart(): void {
		dispatch(fetchCustomerCart()).unwrap()
			.catch(err => {

			})
			.finally(() => {
				setWasLoaded(true);
			});
	}

	useEffect(() => {
		refreshCart();
	}, []);

	function syncLocalCart(): Promise<unknown> {
		if(localCart.length >= 0) {
			return dispatch(syncLocalCartToServer()).unwrap();
		}

		return Promise.resolve();
	}

	useEffect(() => {
		syncLocalCart().catch(err => {
			console.error("Failed during local cart sync", err);
		});
	}, []);

	function handleCheckoutNow(): void {
		if(!customer) {
			setShowLoginPrompt(true);
			return;
		}

		setIsLoading(true);

		syncLocalCart().then(() => {
			const intents = cart.map(product => {
				const quantity = cartProductQuantity[product.id] ?? 1;
				return {
					id: product.id,
					quantity,
					delivery_type: DeliveryType.DELIVERY
				};
			});

			return dispatch(convertCartToPurchase(intents)).unwrap();
		}).catch(err => {
			if(isErrorResponse(err)) {
				if(Array.isArray(err.error.errors)) {
					const errors = err.error.errors;
					if(errors[0]?.product) {
						snack.enqueueSnackbar(`One or more products are no longer available for sale and have been removed from your cart`, {
							variant: "error"
						});

						return dispatch(fetchCustomerCart()).unwrap();
					}
				}
			}

			console.error("Failed to create purchase", err);
			snack.enqueueSnackbar("We ran into an issue creating your purchase", {
				variant: "error"
			});

			throw err;
		}).catch(err => {

		}).finally(() => {
			setIsLoading(false);
			setShouldBeginCheckout(false);
		});
	}

	useEffect(() => {
		if(isPurchaseInProgress && purchase) {
			dispatch(push("/shop/checkout?purchaseId=" + purchase.id));
		}

		if(shouldBeginCheckout) {
			dispatch(setShouldBeginCheckout(false));
			handleCheckoutNow();
		}
	}, [shouldBeginCheckout, isPurchaseInProgress, purchase]);

	return (
		<Loader visible={!wasLoaded}>
			<Box margin="medium" gap="medium" fill="vertical" id="cart-container" flex>
				{showLoginPrompt && (
					<LoginRequired
						selectedLogin={handleLoginSelected}
						selectedRegister={handleRegisterSelected}
					/>
				)}
				<CartContainer
					isCreatingPurchase={isLoading}
					onCreatePurchase={handleCheckoutNow}
				/>
			</Box>
		</Loader>
	);
};