import { DetectedObject, ObjectDetection } from "@tensorflow-models/coco-ssd";
import { Anchor, Box, Button, CheckBox, Form, FormField, Heading, Image, RadioButton, Select, Spinner, Stack, TextInput } from "grommet";
import { Radial } from "grommet-icons";
import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import Webcam from "react-webcam";
import { ReferenceService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchBrands, fetchItems, selectBrands, selectItems } from "../../../app/store/reference";
import { decodeDataUrl } from "../../../helpers";
import { Brand, DTO, Item, MediaContentType, MediaExtension, Product, ProductCondition, ProductMedia } from "../../../types";
import { Loader, Modal, SlimHeading, useCoco, useWebcam, useWindowDimensions } from "../../common";
import useDetectionLoop from "../hooks/useDetectionLoop";
import { ScanCanvas } from "./canvas";
import { ScanCaptureButton, ScanGuidelines } from "./layout";
import { ScanWebcam } from "./webcam";

function resolveBrands(brands: DTO<Brand>[], input: string): DTO<Brand>[] {
	const whitelist = ["Ikea", "West Elm", "Wayfair"];
	return brands.filter(b => whitelist.includes(b.name));
}

function resolveItem(items: DTO<Item>[], input: string): DTO<Item> | null {
	const lookup: Record<string, string> = {
		"couch": "Other Couch / Sofa",
		"chair": "Office Chair",
		"bench": "Coffee Table",
		//"dining table": "Dining / Kitchen Table",
		"dining table": "Coffee Table",
		"potted plant": "Flower Pot",
		"refrigerator": "Refrigerator",
		"oven": "Oven",
		"microwave": "Microwave",
		"tv": "TV",
		"vase": "Vase"
	};
	console.log(items.length);
	return items.find(i => i.name === lookup[input]) ?? null;
}

function resolveCondition(score: number): ProductCondition {
	if(score >= .90) {
		return ProductCondition.EXCELLENT;
	}

	return ProductCondition.FAIR;
}

interface ScanDemoProps {
	onSuccessfullCapture(
		item: DTO<Item>,
		item_input: string,
		condition: ProductCondition,
		media: ProductMedia,
		brand: DTO<Brand> | null
	): Promise<void>;
}

interface PreviewImageModalProps {
	src: string;
	onClose: () => void;
}

const PreviewImageModal: React.FC<PreviewImageModalProps> = (props) => {
	return (
		<Modal
			onClickClose={props.onClose}
			onEsc={props.onClose}
			onClickOutside={props.onClose}
		>
			<Image
				fit="contain"
				src={props.src}
			/>
		</Modal>
	);
};

interface DetectedItemModalProps {
	detectedItem: DTO<Item>;
	detectedScreenshot: string;
	detectedCondition: ProductCondition;
	detectedBrands: DTO<Brand>[];
	onClose(): void;
	onAccepted(item: DTO<Item> | null, itemInput: string, condition: ProductCondition, media: DTO<ProductMedia>, brand: DTO<Brand> | null): Promise<void>;
}


export const DetectedItemModal: React.FC<DetectedItemModalProps> = (props) => {
	const items = useAppSelector(selectItems);
	const [isEditing, setIsEditing] = useState(false);
	const [isShowingPreview, setIsShowingPreview] = useState(false);
	const [autofillItems, setAutofillItems] = useState<Item[]>([]);

	const [state, setState] = useState<Partial<DTO<Product>>>({});

	useEffect(() => {
		console.log("PROPS CHANGED!", props);
		setState({
			...state,
			item: props.detectedItem as Item,
			item_input: props.detectedItem?.name ?? "",
			condition: props.detectedCondition
		});
	}, [props.detectedItem, props.detectedCondition]);

	function handleProceed(): void {
		props.onAccepted(
			state.item || props.detectedItem || null,
			state.item_input || "",
			state.condition as ProductCondition,
			{
				name: "capture.png",
				content: decodeDataUrl(props.detectedScreenshot),
				content_type: MediaContentType.PNG,
				extension: MediaExtension.PNG
			} as ProductMedia,
			state.brand ?? null
		);
	}

	useEffect(() => {
		console.log("STATE CHANGED!", state);
	}, [state]);

	function queryItems(query: string): void {
		ReferenceService.queryItems(query)
			.then(results => {
				setAutofillItems([...results]);
			})
			.catch(err => {
				console.error("Failed to query items", err);
			});
	}

	function updateFormState(changes: Partial<DTO<Product>>): void {
		const item = (autofillItems.length > 0)
			? autofillItems.find(i => i.name === changes.item_input) ?? null
			: changes.item ?? state.item ?? null;

		setState((state) => {
			const newState = {
				...state,
				...changes,
				item
			};
			return newState;
		});
	}

	return (
		<Modal
			onClickClose={() => {
				console.log("CALLING ON CLICK CLOSE");
				props.onClose();
			}}
			onClickOutside={() => {
				console.log("CALLING ON CLICK OUTSIDE");
				props.onClose();
			}}
			onEsc={() => {
				console.log("CALLING ON ESC");
				props.onClose();
			}}
		>
			{isShowingPreview && (
				<PreviewImageModal
					src={props.detectedScreenshot}
					onClose={() => {
						setIsShowingPreview(false);
					}}
				/>
			)}
			<Box margin="medium" gap="small">
				<Box>
					<SlimHeading level="3">Here's what we found</SlimHeading>
				</Box>
				<Box gap="medium">
					<Form
						value={state}
						onChange={updateFormState}
					>
						<FormField
							label="Item"
							name="item_input"
							value={state.item?.name ?? state.item_input}
							onChange={(event) => {
								queryItems(event.target.value);
							}}
						>
							<TextInput
								disabled={!isEditing}
								name="item_input"
								suggestions={autofillItems.map(s => s.name)}
								onSuggestionSelect={(selected) => {
									updateFormState({
										item: autofillItems.find(i => i.name === selected.suggestion)
									});
								}}
							/>
						</FormField>
						<FormField
							label="Condition"
							onChange={(event) => {
								console.log("IN CONDITION CHANGED!", event, event.target.value);
								setState((state) => {
									return {
										...state,
										condition: event.target.value as ProductCondition
									};
								});
							}}
						>
							<Select
								disabled={!isEditing}
								options={Object.values(ProductCondition).sort()}
								value={state.condition ?? ""}
							/>
						</FormField>

						<FormField
							label="Brand"
							help="We think it's one of these"
							contentProps={{ border: undefined }}
						>
							<Box gap="small" margin="small">
								{props.detectedBrands.concat([{ name: "Other" } as Brand]).map(brand => (
									<RadioButton
										name="brand"
										key={brand.name}
										label={brand.name}
										checked={brand.name === "Other" ? state.brand === null : state.brand?.name === brand.name}
										onChange={(event) => {
											if(event.target.checked) {
												setState({
													...state,
													brand: brand?.name === "Other" ? null : brand as Brand
												});
											}
										}}
									/>
								))}
							</Box>
						</FormField>
					</Form>

				</Box>
				<Box gap="small">
					<SlimHeading level="4">Did we get it right?</SlimHeading>
					<Box direction="row" align="center" justify="center" gap="large" margin="small">
						<Button
							label="Make Changes"
							primary
							onClick={() => setIsEditing(true)}
						/>
						<Button
							label="Looks Good"
							primary
							onClick={handleProceed}
						/>

					</Box>
				</Box>
				<Box align="center" justify="center">
					<Anchor
						label="View Captured Image"
						onClick={() => {
							setIsShowingPreview(true);
						}}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

interface EligibleDetection {
	class: string;
	score: number;
	item: DTO<Item>;
	screenshot: string;
	condition: ProductCondition;
	brands: DTO<Brand>[];
}

interface ScanControllerState {
	isLoaded: boolean;
}

export const ScanController: React.FC = (props) => {
	const webcam = useWebcam();
	const snack = useSnackbar();
	const items = useAppSelector(selectItems);
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const [detection, setDetection] = useState<DetectedObject | null>(null);
	const [eligibleDetection, setEligibleDetection] = useState<EligibleDetection | null>(null);
	const [state, setState] = useState<ScanControllerState>({
		isLoaded: false
	});

	const canvasWidth = useMemo(() => {
		return Math.min(
			400,
			Number((dimensions.width * .80).toFixed(0))
		);
	}, [dimensions]);

	const canvasHeight = useMemo(() => {
		return Math.min(
			//getCanvasWidth(),
			Number((dimensions.height * .80).toFixed(0))
		);
	}, [dimensions]);

	function handleDetectionCallback(detection: DetectedObject): void {
		setDetection({
			...detection
		});
	}

	const detectionLoop = useDetectionLoop({
		callback: handleDetectionCallback,
		webcam: webcam.ref,
		detectionLoopInterval: 500,
		minimumDetectionConfidence: 0.50
	});

	function handleDetectionNotFound(): void {
		snack.enqueueSnackbar("No eligible items found", {
			variant: "warning"
		});
	}

	function handleCaptureClicked(): void {
		if(detection) {
			const item = resolveItem(items, detection.class);
			if(!item) {
				handleDetectionNotFound();
				return;
			}

			// setEligibleDetection({
			// 	class: detection.class,
			// 	score: detection.score,
			// 	item,
			// 	condition: resolveCondition(detection.score)
			// });

			if(detectionLoop.isLooping) {
				detectionLoop.suspend();
			}
		}
		else {
			handleDetectionNotFound();
		}
	}

	useEffect(() => {
		dispatch(fetchItems()).unwrap()
			.catch(err => {
				console.error("Failed to load items", err);
			});
	}, []);

	useEffect(() => {
		console.log("IN EFFECT", state.isLoaded);
		if(webcam.isLoaded && detectionLoop.coco && items.length > 0 && !state.isLoaded) {
			setState({
				...state,
				isLoaded: true
			});

			detectionLoop.start();
		}
	}, [webcam.isLoaded, detectionLoop.coco, items]);

	return (
		<Loader visible={!state.isLoaded}>
			<Box fill direction="column" align="center" justify="center">
				<Box background="lightgray" style={{ width: canvasWidth, height: canvasHeight }}>
					<Stack fill>
						<Box fill>
							{(!webcam.isLoaded || !webcam.hasWebcam || !webcam.hasWebcamPermission)
								? (() => {
									if(!webcam.isLoaded) {
										return (
											<Box direction="column" justify="center" align="center" fill>
												<Spinner size="large" />
											</Box>
										);
									}

									if(!webcam.hasWebcam) {
										return (
											<Box background="white">
												<Heading level="4" margin="small">
													You need a device with a camera in order to play with the demo
												</Heading>
											</Box>
										);
									}

									if(!webcam.hasWebcamPermission) {
										return (
											<Box background="white">
												<Heading level="4" margin="small">
													We need camera permission before we can launch the demo. Please grant permission and then refresh this page
												</Heading>
											</Box>
										);
									}
								})()
								: (
									<Box direction="row" justify="center" align="center" fill>
										<Stack fill>
											<ScanWebcam
												reference={webcam.ref}
											/>
											<ScanCanvas
												reference={canvasRef}
												width={canvasWidth}
												height={canvasHeight}
												isLooping={detectionLoop.isLooping}
												detection={detection}
											/>
										</Stack>
									</Box>
								)}
						</Box>
						<ScanGuidelines />
						<ScanCaptureButton
							onCaptureClicked={handleCaptureClicked}
						/>
					</Stack>
				</Box>
			</Box>
		</Loader>
	);
};

interface ScanModalProps {
	onClose(): void;
	onSuccessfullCapture(item: DTO<Item>, item_input: string, condition: ProductCondition, media: DTO<ProductMedia>, brand: DTO<Brand> | null): Promise<void>;
}

export const ScanModal: React.FC<ScanModalProps> = (props) => {
	return (
		<Modal
			full
			onEsc={props.onClose}
			onClickOutside={props.onClose}
			onClickClose={props.onClose}
		>
			<ScanDemo
				onSuccessfullCapture={props.onSuccessfullCapture}
			/>
		</Modal>
	);
};

interface DetectionState {
	item: DTO<Item> | null;
	condition: ProductCondition | null;
	brands: DTO<Brand>[];
	class: string;
	score: number;
	isModalVisible: boolean;
}

export const ScanDemo: React.FC<ScanDemoProps> = (props) => {
	const items = useAppSelector(selectItems);
	const brands = useAppSelector(selectBrands);
	const webcam = useWebcam();
	const coco = useCoco();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLooping, setLooping] = useState(false);
	const [loopInterval, setLoopInterval] = useState<NodeJS.Timeout | null>(null);
	const [counter, setCounter] = useState(0);
	const [detection, setDetection] = useState<DetectedObject | null>(null);
	const [eligibleDetection, setEligibleDetection] = useState<EligibleDetection | null>(null);
	const [isModalVisible, setModalVisible] = useState(false);

	function getCanvasWidth(): number {
		return Math.min(
			400,
			Number((dimensions.width * .80).toFixed(0))
		);
	}

	function getCanvasHeight(): number {
		return Math.min(
			//getCanvasWidth(),
			Number((dimensions.height * .80).toFixed(0))
		);
	}

	function handleStartLooping(): void {
		console.log("starting detection loop");
		setCounter(counter + 1);
		//  Loop and detect hands
		if(isLooping || loopInterval || !coco) {
			return;
		}

		setLoopInterval(
			setInterval(() => {
				detect(coco);
			}, 500)
		);
		setLooping(true);
	}

	function handleStopLooping(): void {
		console.log("stopping detection loop");
		clearCanvas();
		if(loopInterval) {
			clearInterval(loopInterval);
		}
		setLoopInterval(null);
		setLooping(false);
	}

	useEffect(() => {
		Promise.all([
			dispatch(fetchItems()).unwrap(),
			dispatch(fetchBrands()).unwrap()
		])
			.catch(err => {
				console.error("Failed to load reference items", err);
			});

		return () => {
			if(loopInterval) {
				clearTimeout(loopInterval);
			}
		};
	}, []);

	useEffect(() => {
		if(webcam.isLoaded && coco && items.length > 0 && brands.length > 0) {
			setIsLoaded(true);
		}
	}, [coco, items, brands, webcam.isLoaded]);

	useEffect(() => {
		if(isLoaded && !isLooping) {
			handleStartLooping();
		}
	}, [isLoaded]);

	const detect = async (net: ObjectDetection) => {
		//short circuit the detection loop stopping
		if(!isLooping) {
			// return;
		}

		// Check data is available
		if(
			typeof webcam.ref.current !== "undefined" &&
			webcam.ref.current !== null &&
			webcam.ref.current.video?.readyState === 4
		) {
			// Get Video Properties
			const video = webcam.ref.current.video;
			const videoWidth = webcam.ref.current.video.videoWidth;
			const videoHeight = webcam.ref.current.video.videoHeight;

			// Set video width
			webcam.ref.current.video.width = videoWidth;
			webcam.ref.current.video.height = videoHeight;

			// Set canvas height and width
			if(canvasRef.current) {
				canvasRef.current.width = videoWidth;
				canvasRef.current.height = videoHeight;
			}

			// Make Detections
			const obj = await net.detect(video);

			const detections = obj.filter(d => d.score > .60).sort((a, b) => {
				return b.score - a.score;
			});

			if(detections.length > 0) {
				const detection = detections[0];
				setDetection({
					...detection
				});

				// Draw mesh
				const ctx = canvasRef.current?.getContext("2d") ?? null;
				//drawRect(detections[0], ctx);
			}
		}
	};

	const drawRect = (prediction: DetectedObject, ctx: CanvasRenderingContext2D | null) => {
		if(!ctx) {
			console.error("missing ctx for detections", prediction);
			return;
		}

		// Extract boxes and classes
		const [x, y, width, height] = prediction["bbox"];
		const text = prediction["class"];

		// Set styling
		const color = Math.floor(Math.random() * 16777215).toString(16);
		ctx.strokeStyle = '#' + color;
		ctx.font = '18px Arial';

		// Draw rectangles and text
		ctx.beginPath();
		ctx.fillStyle = '#' + color;
		ctx.fillText(text, x, y);
		ctx.rect(x, y, width, height);
		ctx.stroke();
	};

	function clearCanvas(): void {
		if(!canvasRef.current) {
			console.log("current not found in canvas ref");
			return;
		}

		const canvas = canvasRef.current?.getContext("2d") ?? null;
		if(!canvas) {
			console.log("could not get canvas from ref");
			return;
		}

		canvas.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
	}

	useEffect(() => {
		if(detection) {
			handleCaptureDetection(detection);
		}
	}, [detection]);

	function handleDetectionNotFound(): void {
		snack.enqueueSnackbar("No eligible items found", {
			variant: "warning"
		});
	}

	function handleCaptureDetection(detection: DetectedObject): void {
		const item = resolveItem(items, detection.class);
		if(!item) {
			return;
		}

		const screenshot = (() => {
			if(webcam?.ref?.current) {
				return webcam.ref.current.getScreenshot() ?? "";
			}

			return "";
		})();

		setEligibleDetection({
			class: detection.class,
			score: detection.score,
			brands: resolveBrands(brands, detection.class),
			item,
			screenshot,
			condition: resolveCondition(detection.score)
		});

		handleStopLooping();
	}

	function handleCaptureClicked(): void {
		if(detection) {
			const item = resolveItem(items, detection.class);
			if(!item) {
				handleDetectionNotFound();
				return;
			}

			const screenshot = (() => {
				if(webcam?.ref?.current) {
					return webcam.ref.current.getScreenshot() ?? "";
				}

				return "";
			})();

			setEligibleDetection({
				class: detection.class,
				score: detection.score,
				brands: resolveBrands(brands, detection.class),
				item,
				screenshot,
				condition: resolveCondition(detection.score)
			});

			handleStopLooping();
		}
		else {
			handleDetectionNotFound();
		}
	}

	function handleCloseModal(): void {
		console.log("closing modal and clearing eligible detection");
		setEligibleDetection(null);

		if(!isLooping) {
			handleStartLooping();
		}
	}

	return (
		<Fragment>
			{eligibleDetection && (
				<DetectedItemModal
					detectedCondition={eligibleDetection.condition as ProductCondition}
					detectedItem={eligibleDetection.item as DTO<Item>}
					detectedScreenshot={eligibleDetection.screenshot}
					detectedBrands={eligibleDetection.brands}
					onClose={handleCloseModal}
					onAccepted={props.onSuccessfullCapture}
				/>
			)}
			<Box fill direction="column" align="center" justify="center">
				<Box background="lightgray" style={{ width: getCanvasWidth(), height: getCanvasHeight() }}>
					<Stack fill>
						<Box fill>
							{(!webcam.isLoaded || !webcam.hasWebcam || !webcam.hasWebcamPermission)
								? (() => {
									if(!webcam.isLoaded) {
										return (
											<Box direction="column" justify="center" align="center" fill>
												<Spinner size="large" />
											</Box>
										);
									}

									if(!webcam.hasWebcam) {
										return (
											<Box background="white">
												<Heading level="4" margin="small">
													You need a device with a camera in order to play with the demo
												</Heading>
											</Box>
										);
									}

									if(!webcam.hasWebcamPermission) {
										return (
											<Box background="white">
												<Heading level="4" margin="small">
													We need camera permission before we can launch the demo. Please grant permission and then refresh this page
												</Heading>
											</Box>
										);
									}
								})()
								: (
									<Box direction="row" justify="center" align="center" fill>
										<Stack fill>
											<Webcam
												className="webcam"
												ref={webcam.ref}
												muted={true}
												videoConstraints={{
													facingMode: {
														ideal: "environment"
													}
												}}
												style={{
													textAlign: "center",
													width: "100%",
													height: "100%",
													objectFit: "cover"
												}}
												screenshotFormat="image/png"
												screenshotQuality={1}
											/>
											<canvas
												ref={canvasRef}
												style={{
													position: "absolute",
													marginLeft: "auto",
													marginRight: "auto",
													left: 0,
													right: 0,
													textAlign: "center",
													zIndex: 0,
													width: getCanvasWidth(),
													height: getCanvasHeight()
												}}
											/>
										</Stack>
									</Box>
								)}
						</Box>
						<Box fill align="center" justify="around" direction="row">
							<Box
								margin={{ vertical: "small", horizontal: "medium" }}
								height="30%"
								width="20%"
								border={{ side: "all", color: "green", size: "medium" }}
								style={{ borderRight: "none" }}
							/>
							<Box
								margin={{ vertical: "small", horizontal: "medium" }}
								height="30%"
								width="20%"
								border={{ side: "all", color: "green", size: "medium" }}
								style={{ borderLeft: "none" }}
							/>
						</Box>
						<Box fill direction="row" align="center" justify="center">
							<Box alignSelf="end" justify="center" margin="medium" round>
								<Button
									hoverIndicator
									onClick={handleCaptureClicked}
									icon={<Radial size="xlarge" />}
								/>
							</Box>
						</Box>
					</Stack>
				</Box>
			</Box>
		</Fragment>
	);
};