import { DeliveryAddress, States, Timezone } from "../../../types";
import { Box, Button, Spinner } from "grommet";
import { useEffect, useState } from "react";
import { AddressForm } from "../components";
import { useScreenSize, MapComponent, SlimHeading } from "../../common";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { push } from "connected-react-router";
import { selectReferral, setOrder, updateOrderAddress } from "../../../app/store/order";
import { useSnackbar } from "notistack";
import { useOrder } from "../controller";
import { isBuildingPartner } from "../../../helpers";

interface OrderAddressState {
	isSaving: boolean;
	address?: DeliveryAddress;
}

export const OrderAddressForm: React.FC = (props) => {
	const size = useScreenSize();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const { order } = useOrder();
	const referral = useAppSelector(selectReferral);

	useEffect(() => {
		//Make sure previous steps completed
		//If not products, push back to order dashboard
		if(!order || !order.products?.length) {
			dispatch(push("/order"));
		}

		if(order?.address) {
			console.debug("GOT EXISTING ADDRESS!", order.address);
			setAddress(order.address);
		}
	}, []);

	useEffect(() => {
		if(referral && isBuildingPartner(referral.partner)) {
			const partnerAddress = referral.partner.address;
			const address = { ...order.address ?? {} };
			address.address_line_one = partnerAddress.address_line_one;
			address.city = partnerAddress.city;
			address.state = partnerAddress.state;
			address.zip = partnerAddress.zip;
			dispatch(setOrder({ ...order, address }));
		}
	}, []);

	const [state, setState] = useState<OrderAddressState>({
		isSaving: false
	});

	function setAddress(address: DeliveryAddress): void {
		setState((oldState) => {
			return {
				...oldState,
				address
			};
		});
	}

	function updateOrderAddressAndAdvance(address: DeliveryAddress): void {
		console.debug("IN UPDATE ORDER AND ADVANCE");
		setState({
			...state,
			address: {
				...address
			},
			isSaving: true
		});

		dispatch(updateOrderAddress({
			orderId: order.id,
			address: {
				address_line_one: address?.address_line_one ?? "",
				address_line_two: address?.address_line_two ?? "",
				city: address?.city ?? "",
				state: (address?.state ?? "") as States.Abbreviation,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code,
				zip: address?.zip ?? "",
				zip_plus_four: address?.zip_plus_four ?? "",
				latitude: address?.latitude ?? "",
				longitude: address?.longitude ?? "",
				floor: Number(address?.floor || 1),
				has_driveway: address?.has_driveway || false,
				has_elevator: address?.has_elevator || false,
				has_parking: address?.has_parking || false,
				notes: address?.notes ?? ""
			}
		})).unwrap()
			.then((order) => {
				dispatch(push("/order/timing"));
			})
			.catch(err => {
				console.error("Failed to update order address", err);
				snack.enqueueSnackbar("We ran into some trouble saving your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isSaving: false
				});
			});
	}

	return (
		<Box gap="small">
			<Box>
				<SlimHeading level="3">Pickup Address</SlimHeading>
				<SlimHeading level="5">Let us know where we will be picking up from</SlimHeading>
			</Box>
			<Box direction="row-responsive" gap="small">
				<Box flex>
					<AddressForm
						address={order?.address ?? {}}
						onAddressSelected={(address) => {
							setAddress(address);
						}}
						onFormSubmit={(address) => {
							updateOrderAddressAndAdvance(address);
						}}
					>
						<Box direction="row" justify="between">
							<Button
								primary
								label="Previous"
								disabled={state.isSaving}
								onClick={() => {
									dispatch(push("/order/products"));
								}}
							/>
							<Button
								primary
								label="Continue"
								type="submit"
								disabled={state.isSaving}
								icon={state.isSaving ? <Spinner /> : undefined}
							/>
						</Box>
					</AddressForm>
				</Box>
				{size !== "small" && (
					<Box flex>
						<MapComponent
							markers={state.address?.latitude ? [
								{
									latitude: Number(state.address.latitude),
									longitude: Number(state.address.longitude)
								}
							] : []}
							requestCurrentLocation={true}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};