import { Anchor, AnchorProps, Button, ButtonProps } from "grommet";
import { push } from "redux-first-history";
import { useAppDispatch } from "../../../app/store";

export const Link: React.FC<AnchorProps & { to: string; target?: "_blank"; }> = (props) => {
	const dispatch = useAppDispatch();
	return (
		<Anchor
			{...props}
			target={props.target}
			href={props.target === "_blank" ? props.to : undefined}
			onClick={!props.target ? (() => {
				dispatch(push(props.to));
			}) : undefined}
		/>
	);
};

interface LinkButtonProps extends ButtonProps {
	variant: "white" | undefined;
}

export const LinkButton: React.FC<LinkButtonProps & { to: string; }> = (props) => {
	const dispatch = useAppDispatch();
	return (
		<Button {...props}
			color={props.variant === "white" ? "white" : undefined}
			onClick={(() => {
				dispatch(push(props.to));
			})}
		/>
	);
};