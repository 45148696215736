import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Card, CardBody, CheckBox, ColumnConfig, DataTable, FormField, Grid, Select, Spinner, TextInput } from "grommet";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { push } from "connected-react-router";
import { Delivery, DeliveryConfirmation, DeliveryWindow, DTO, ScheduleHistory, Service, Timezone } from "@rego-app/common";
import { fetchDeliveries, fetchDelivery, fetchDeliveryWithExpand, selectActiveDelivery, selectDeliveries, selectTodaysDeliveries } from "../../../app/store/admin";
import { useSnackbar } from "notistack";
import { CopyToClipboardField, extendedPickupWindows, FieldWithActionButton, Loader, LocalDateField, SlimHeading, useTimezone, useWindowDimensions } from "../../common";
import moment from "moment";
import { UpdateScheduledDate } from "../components";
import { DeliveryService } from "../../../app/services/delivery";
import { Calendar, FormCheckmark, FormNext, User } from "grommet-icons";
import { navigateToCustomer, navigateToOrder, navigateToStore, navigateToDonation, navigateToRemoval } from "../routes";
import { isCustomerConfirmation, isDonation, isOrderDelivery, isPurchaseDelivery, isRemoval, isStoreConfirmation, parseDateFromUTC, parseTimestampFromUTC } from "../../../helpers";
import { LinearProgress } from "@mui/material";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code;

const recentDeliveryColumns: ColumnConfig<DTO<Delivery>>[] = [
	{
		property: "number",
		header: "Delivery Number",
		primary: true,
		sortable: true,
		render: (delivery) => delivery.number
	},
	{
		property: "scheduled_at",
		header: "Scheduled At",
		primary: false,
		sortable: true,
		render: (delivery) => parseTimestampFromUTC(delivery.scheduled_at, timezone)
	},
	{
		property: "scheduled_for",
		header: "Scheduled For",
		primary: false,
		sortable: true,
		render: (delivery) => (delivery.window) ? `${parseDateFromUTC(delivery.window.date, timezone)} (${moment().hours(delivery.window.from).minutes(0).format("hh:mm A")} - ${moment().hours(delivery.window.to).minutes(0).format("hh:mm A")})` : ""
	},
	{
		property: "completed_at",
		header: "Completed At",
		primary: false,
		sortable: true,
		render: (delivery) => parseTimestampFromUTC(delivery.completed_at, timezone)
	}
];

interface AdminDeliveriesPageState {
	isLoading: boolean;
	loadedDeliveries: boolean;
	isModalVisible: boolean;
	showDelivery: boolean;
	activeDelivery?: DTO<Delivery>;
}

export const AdminDeliveriesPage: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [state, setState] = useState<AdminDeliveriesPageState>({
		isLoading: false,
		showDelivery: false,
		isModalVisible: false,
		loadedDeliveries: false
	});

	const deliveries = useAppSelector(selectDeliveries);
	const todaysDeliveries = useAppSelector(selectTodaysDeliveries);

	function handleNavigate(delivery: DTO<Delivery>): void {
		dispatch(push(`/admin/dashboard/deliveries/${delivery.id}`));
	}

	const upcomingDeliveries = useMemo(() => {
		return deliveries.filter(d => {
			return d.scheduled && moment.tz(d.window.date, d.window.timezone).unix() > moment().startOf("day").unix();
		}).sort((a, b) => {
			const aWindow = a.window;
			const aMoment = moment.tz(aWindow.date, aWindow.timezone).hours(aWindow.from).minutes(0).seconds(0).milliseconds(0);

			const bWindow = b.window;
			const bMoment = moment.tz(bWindow.date, bWindow.timezone).hours(bWindow.from).minutes(0).seconds(0).milliseconds(0);

			return aMoment.unix() - bMoment.unix();
		});
	}, [deliveries]);

	useEffect(() => {
		setState({ ...state, isLoading: true, loadedDeliveries: false });

		dispatch(fetchDeliveries({}))
			.catch(err => {
				console.error(`Failed to fetch recent deliveries`, err);
				snack.enqueueSnackbar("Failed to load recent deliveries", {
					variant: "error"
				});
			})
			.finally(() => {
				setState(state => {
					return { ...state, loadedDeliveries: true };
				});
			});
	}, []);

	useEffect(() => {
		if(state.loadedDeliveries) {
			setState({
				...state,
				isLoading: false
			});
		}
	}, [state.loadedDeliveries, state.isLoading]);

	return (
		<Box margin="small" gap="large">
			<Box gap="small">
				<Box>
					<SlimHeading level="3">Today's Deliveries</SlimHeading>
					<Box>
						{(state.isLoading || !state.loadedDeliveries) && (
							<LinearProgress />
						)}
						<DataTable
							pad="small"
							columns={recentDeliveryColumns}
							data={todaysDeliveries}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
				<Box>
					<SlimHeading level="3">Upcoming Deliveries</SlimHeading>
					<Box>
						{(state.isLoading || !state.loadedDeliveries) && (
							<LinearProgress />
						)}
						<DataTable
							pad="small"
							columns={recentDeliveryColumns}
							data={upcomingDeliveries}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
interface AdminDeliveryDetailsState {
	isLoading: boolean;
}

export const AdminDeliveryDetails: React.FC = (props) => {
	const params = useParams();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const delivery = useAppSelector(selectActiveDelivery);
	const dimensions = useWindowDimensions();

	const [columns, setColumns] = useState(2);

	useEffect(() => {
		if(dimensions.width > 1200) {
			setColumns(2);
			return;
		}

		setColumns(1);
	}, [dimensions.width]);

	const [state, setState] = useState<AdminDeliveryDetailsState>({
		isLoading: false
	});

	function updateState(changes: Partial<AdminDeliveryDetailsState>): void {
		setState((state) => {
			return {
				...state,
				...changes
			};
		});
	}

	function handleFetchDelivery(deliveryId: string): void {
		updateState({ isLoading: true });

		dispatch(fetchDelivery({ deliveryId, expand: ["order", "service", "purchase"] }))
			.catch(err => {
				console.error("Failed to load delivery", err);
				snack.enqueueSnackbar("Failed to load delivery", { variant: "error" });
			})
			.finally(() => {
				updateState({ isLoading: false });
			});
	}

	useEffect(() => {
		if(!params.deliveryId) {
			dispatch(push("/admin/dashboard/deliveries"));
			return;
		}

		handleFetchDelivery(params.deliveryId);
	}, [params]);

	return (
		<Loader visible={state.isLoading || !delivery}>
			{delivery && (
				<Box margin="medium" gap="small">
					<Grid columns={{ count: columns, size: "auto" }} gap="small">
						<Box flex gap="small">
							<SlimHeading level="3">
								Delivery Details
							</SlimHeading>
							<DeliveryDetails
								delivery={delivery}
							/>
						</Box>
						<Box flex gap="small">
							<SlimHeading level="3">
								Schedule History
							</SlimHeading>
							<DeliveryScheduleHistory
								delivery={delivery}
							/>
						</Box>
					</Grid>
					{(delivery.confirmations ?? []).length > 0 && (
						<Box flex gap="small">
							<SlimHeading level="3">
								Delivery Confirmations
							</SlimHeading>
							<DeliveryConfirmations
								delivery={delivery}
							/>
						</Box>
					)}
				</Box>
			)}
		</Loader>
	);
};

interface DeliveryDetailsProps {
	delivery: DTO<Delivery>;
}

interface DeliveryDetailsState {
	isUpdatingCompletedAt: boolean;
	isUpdatingScheduledWindow: boolean;
}

export const DeliveryDetails: React.FC<DeliveryDetailsProps> = (props) => {
	const snack = useSnackbar();
	const timezone = useTimezone();
	const dispatch = useAppDispatch();
	const [state, setState] = useState<DeliveryDetailsState>({
		isUpdatingCompletedAt: false,
		isUpdatingScheduledWindow: false
	});

	const [service, setService] = useState<DTO<Service> | null>(null);

	const isLocked = useMemo(() => {
		return !!props.delivery.completed;
	}, [props.delivery]);

	useEffect(() => {
		DeliveryService.getDeliveryService(props.delivery.id)
			.then(res => {
				setService({ ...res });
			})
			.catch(err => {
				console.error("failed to load delivery service", err);
			});
	}, [props.delivery]);

	function handleRescheduleDelivery(window: DeliveryWindow): Promise<void> {
		return DeliveryService.scheduleDelivery(props.delivery.id, window)
			.then(() => dispatch(fetchDeliveryWithExpand(props.delivery.id)))
			.then(() => { });
	}

	function handleUpdateCompletedAt(): Promise<void> {
		setState({ ...state, isUpdatingCompletedAt: true });
		return DeliveryService.completeDelivery(props.delivery.id)
			.then(() => dispatch(fetchDeliveryWithExpand(props.delivery.id)))
			.then(() => { })
			.catch(err => {
				console.error("Failed to update completed at", err);
				snack.enqueueSnackbar("Failed to update delivery", { variant: "error" });
			})
			.finally(() => {
				setState({ ...state, isUpdatingCompletedAt: false });
			});
	}

	return (
		<Box flex>
			{state.isUpdatingScheduledWindow && (
				<UpdateScheduledDate
					isScheduled={props.delivery.scheduled}
					onWindowSelected={handleRescheduleDelivery}
					onClose={() => {
						setState({
							...state,
							isUpdatingScheduledWindow: false
						});
					}}
				/>
			)}
			<CopyToClipboardField
				name="id"
				label="Delivery Id"
				value={props.delivery.id}
			/>
			<CopyToClipboardField
				name="number"
				label="Delivery Number"
				value={String(props.delivery.number ?? "")}
			/>
			<CopyToClipboardField
				name="reference_id"
				label="Partner Delivery Number"
				value={String(props.delivery.reference_id ?? "")}
			/>
			{(isOrderDelivery(props.delivery) && props.delivery.order) && (
				<FieldWithActionButton
					name="order"
					label="Order"
					value={props.delivery.order.id}
					icon={<FormNext />}
					onClick={() => {
						isOrderDelivery(props.delivery) && navigateToOrder(props.delivery.order.id, dispatch);
					}}
				/>
			)}
			{(isPurchaseDelivery(props.delivery) && props.delivery.purchase) && (
				<FieldWithActionButton
					name="purchase"
					label="Purchase"
					value={props.delivery.purchase.id}
					icon={<FormNext />}
					onClick={() => {
						isPurchaseDelivery(props.delivery) && navigateToOrder(props.delivery.purchase.id, dispatch);
					}}
				/>
			)}
			{(service && isDonation(service)) && (
				<FieldWithActionButton
					name="donation"
					label="Donation"
					value={service.id}
					icon={<FormNext />}
					onClick={() => {
						service && navigateToDonation(service.id, dispatch);
					}}
				/>
			)}
			{(service && isRemoval(service)) && (
				<FieldWithActionButton
					name="removal"
					label="Removal"
					value={service.id}
					icon={<FormNext />}
					onClick={() => {
						service && navigateToRemoval(service.id, dispatch);
					}}
				/>
			)}
			<FormField
				label="Scheduled Date"
			>
				<Box direction="row" fill="horizontal">
					<TextInput
						disabled
						plain
						value={props.delivery.window ? moment(props.delivery.window.date).format("MM/DD/YYYY") : ""}
					/>
					<Button
						icon={<Calendar />}
						hoverIndicator
						disabled={isLocked}
						onClick={() => {
							setState({
								...state,
								isUpdatingScheduledWindow: true
							});
						}}
					/>
				</Box>
			</FormField>
			<FormField
				label="Scheduled Window"
			>
				<Select
					disabled
					options={extendedPickupWindows.map(w => w.label)}
					value={props.delivery.window ? extendedPickupWindows.find(w => w.from === props.delivery.window.from && w.to === props.delivery.window.to)?.label : ""}
				/>
			</FormField>
			<FormField
				label="Completed"
				contentProps={{ border: undefined }}
			>
				<CheckBox
					readOnly
					checked={props.delivery.completed}
				/>
			</FormField>
			<FormField
				name="completed_at"
				label="Completed At"
			>
				<Box direction="row" fill="horizontal">
					<TextInput
						plain
						value={parseTimestampFromUTC(props.delivery.completed_at, timezone)}
						disabled
						name="completed_at"
					/>
					{!props.delivery.completed_at && (
						<Button
							icon={state.isUpdatingCompletedAt ? <Spinner /> : <FormCheckmark color="green" />}
							disabled={state.isUpdatingCompletedAt}
							hoverIndicator
							onClick={handleUpdateCompletedAt}
						/>
					)}
				</Box>
			</FormField>
		</Box>
	);
};

interface DeliveryConfirmationsProps {
	delivery: DTO<Delivery>;
}

export const DeliveryConfirmations: React.FC<DeliveryConfirmationsProps> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const [confirmations, setConfirmations] = useState<DTO<DeliveryConfirmation>[]>([]);
	const [columns, setColumns] = useState(3);
	const dimensions = useWindowDimensions();
	const [isConfirming, setIsConfirming] = useState("");

	useEffect(() => {
		if(dimensions.width > 1200) {
			setColumns(3);
			return;
		}

		if(dimensions.width > 800) {
			setColumns(2);
			return;
		}

		setColumns(1);
		return;
	}, [dimensions.width]);

	useEffect(() => {
		DeliveryService.getDeliveryConfirmations(props.delivery.id)
			.then(results => {
				setConfirmations([
					...results
				]);
			});
	}, [props.delivery]);

	function handleConfirmDelivery(confirmation: DTO<DeliveryConfirmation>): void {
		if(isCustomerConfirmation(confirmation)) {
			setIsConfirming(confirmation.id);
			DeliveryService.confirmCustomerDelivery(props.delivery.id, confirmation.customer.id)
				.then(() => dispatch(fetchDeliveryWithExpand(props.delivery.id)).unwrap())
				.catch(err => {
					console.error("Failed to confirm delivery", err);
					snack.enqueueSnackbar(err?.error?.message ?? "Failed to confirm delivery", { variant: "error" });
				})
				.finally(() => {
					setIsConfirming("");
				});
		}

		if(isStoreConfirmation(confirmation)) {
			setIsConfirming(confirmation.id);
			DeliveryService.confirmStoreDelivery(props.delivery.id, confirmation.store.id)
				.then(() => dispatch(fetchDeliveryWithExpand(props.delivery.id)).unwrap())
				.catch(err => {
					console.error("Failed to confirm delivery", err);
					snack.enqueueSnackbar(err?.error?.message ?? "Failed to confirm delivery", { variant: "error" });
				})
				.finally(() => {
					setIsConfirming("");
				});
		}
	}

	return (
		<Box flex>
			<Grid columns={{ count: columns, size: "auto" }}>
				{confirmations.map(confirmation => (
					<DeliveryConfirmationCard
						delivery={props.delivery}
						confirmation={confirmation}
						isConfirmingDelivery={isConfirming === confirmation.id}
						onConfirmDelivery={handleConfirmDelivery}
					/>
				))}
			</Grid>
		</Box>
	);
};

interface DeliveryConfirmationCardProps {
	delivery: DTO<Delivery>;
	confirmation: DTO<DeliveryConfirmation>;
	isConfirmingDelivery: boolean;
	onConfirmDelivery(confirmation: DTO<DeliveryConfirmation>): void;
}

export const DeliveryConfirmationCard: React.FC<DeliveryConfirmationCardProps> = (props) => {
	const dispatch = useAppDispatch();

	return (
		<Card>
			<CardBody margin="medium">
				<Grid columns={{ count: 1, size: "auto" }}>
					{isCustomerConfirmation(props.confirmation) && (
						<Box flex>
							<FieldWithActionButton
								name="customer"
								label="Customer"
								icon={<User />}
								value={`${props.confirmation.customer.first_name} ${props.confirmation.customer.last_name}`}
								onClick={() => {
									const confirmation = isCustomerConfirmation(props.confirmation) && props.confirmation;
									if(confirmation) {
										navigateToCustomer(confirmation.customer.id, dispatch);
									}
								}}
							/>
							<LocalDateField
								label="Confirmed At"
								name="confirmed_at"
								value={props.confirmation.confirmed_at}
							/>
						</Box>
					)}
					{isStoreConfirmation(props.confirmation) && (
						<Box flex>
							<FieldWithActionButton
								name="customer"
								label="Customer"
								icon={<User />}
								value={`${props.confirmation.store.name}`}
								onClick={() => {
									const confirmation = isStoreConfirmation(props.confirmation) && props.confirmation;
									if(confirmation) {
										navigateToStore(confirmation.store.id, dispatch);
									}
								}}
							/>
							<LocalDateField
								label="Confirmed At"
								name="confirmed_at"
								value={props.confirmation.confirmed_at}
							/>
						</Box>
					)}
					<Box align="start">
						{!props.confirmation.confirmed && (
							<Button
								label="Manually Confirm"
								disabled={props.isConfirmingDelivery}
								icon={props.isConfirmingDelivery ? <Spinner /> : undefined}
								primary
								onClick={() => {
									props.onConfirmDelivery(props.confirmation);
								}}
							/>
						)}
					</Box>
				</Grid>
			</CardBody>
		</Card>
	);
};

export const DeliveryScheduleHistoryItem: React.FC<{ history: DTO<ScheduleHistory>; }> = (props) => {
	return (
		<Grid columns={["auto", "flex"]} gap="small">
			<Box>
				<LocalDateField
					plain
					label="Scheduled At"
					name="scheduled_at"
					value={props.history.scheduled_at}
				/>
			</Box>
			<Box direction="row" gap="small">
				<LocalDateField
					omitTimestamp
					plain
					label="Scheduled Date"
					value={props.history.scheduled_for.date}
				/>
				<FormField
					name="window"
					label="Window"
				>
					<TextInput
						disabled
						readOnly
						value={`${moment(props.history.scheduled_for.from).format("hh A")} - ${moment(props.history.scheduled_for.to).format("hh A")}`}
					/>
				</FormField>
			</Box>
		</Grid>
	);
};

export const DeliveryScheduleHistory: React.FC<{ delivery: DTO<Delivery>; }> = (props) => {
	const history = useMemo(() => {
		return [...(props.delivery.schedule_history ?? [])].sort((a, b) => {
			return moment(b.scheduled_at).unix() - moment(a.scheduled_at).unix();
		});
	}, [props.delivery]);

	return (
		<Box flex>
			{history.map(h => (
				<DeliveryScheduleHistoryItem
					key={h.id}
					history={h}
				/>
			))}
		</Box>
	);
};