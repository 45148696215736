import { DTO, Product } from "../../types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { Cart, CreatePurchaseIntent, ListingChannel, Market, Payment, PaymentMethod, Purchase, PurchaseEstimate, PurchaseIntent, RelationExpand } from "@rego-app/common";
import { PurchaseService } from "../services/purchase";
import { CustomerService, PaymentService, ReferenceService } from "../services";

export interface PurchaseState {
	payment: DTO<Payment> | null;
	purchase: DTO<Purchase> | null;
	estimate: DTO<PurchaseEstimate> | null;
	cart: DTO<Cart> | null;
	localCart: DTO<Product>[];
	market: DTO<Market> | null;
	shouldBeginCheckout: boolean;
	isPurchaseInProgress: boolean;
	cartProductQuantity: Record<string, number>;
}

const initialState: PurchaseState = {
	payment: null,
	purchase: null,
	estimate: null,
	cart: null,
	localCart: [],
	market: null,
	shouldBeginCheckout: false,
	isPurchaseInProgress: false,
	cartProductQuantity: {}
};

export const fetchPurchase = createAsyncThunk<DTO<Purchase>, { purchaseId: string, expand?: RelationExpand<Purchase>; }, { state: RootState; }>(
	"purchase/fetchPurchase",
	async (data, thunk) => {
		try {
			return await PurchaseService.getPurchase(data.purchaseId, data.expand);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const fetchActivePurchase = createAsyncThunk<DTO<Purchase> | null, void, { state: RootState; }>(
	"purchase/fetchActivePurchase",
	async (data, thunk) => {
		try {
			return await CustomerService.getActiveCustomerPurchase(thunk.getState().application.customer?.id ?? "");
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const fetchPayment = createAsyncThunk<DTO<Payment>, { paymentId: string; }, { state: RootState; }>(
	"purchase/fetchPayment",
	async (data, thunk) => {
		try {
			return await PaymentService.getPayment(data.paymentId, []);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const createPurchaseEstimate = createAsyncThunk<DTO<PurchaseEstimate>, string, { state: RootState; }>(
	"purchase/createPurchaseEstimate",
	async (purchaseId, thunk) => {
		try {
			return await PurchaseService.createPurchaseEstimate(purchaseId);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

interface UpdatePurchaseRequest {
	purchaseId: string;
	updates: Partial<DTO<Purchase>>;
}

export const updatePurchase = createAsyncThunk<DTO<Purchase>, UpdatePurchaseRequest, { state: RootState; }>(
	"purchase/updatePurchase",
	async (data, thunk) => {
		try {
			return await PurchaseService.updatePurchase(data.purchaseId, data.updates);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);


export const cancelPurchase = createAsyncThunk<DTO<Purchase>, string, { state: RootState; }>(
	"purchase/cancelPurchase",
	async (purchaseId, thunk) => {
		try {
			return await PurchaseService.cancelPurchase(purchaseId);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const fetchCustomerCart = createAsyncThunk<DTO<Cart> | null, void, { state: RootState; }>(
	"purchase/fetchCustomerCart",
	async (data, thunk) => {
		try {
			const customer = thunk.getState().application.customer;
			if(!customer) {
				console.debug(`No customer found in state ... cannot fetch cart`);
				return null;
			}

			return await CustomerService.getCustomerCart(customer.id, ["products", "market"]);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const addProductToCart = createAsyncThunk<{ cart: DTO<Cart> | null, localCart: DTO<Product>[]; }, DTO<Product>, { state: RootState; }>(
	"purchase/addProductToCart",
	async (product, thunk) => {
		try {
			const customer = thunk.getState().application.customer;

			if(customer) {
				//Clear local cart
				const localCart = thunk.getState().purchase.localCart;

				while(localCart.length > 0) {
					const product = localCart.shift();
					if(!product) {
						continue;
					}

					await CustomerService.addProductToCart(
						customer.id,
						product.id
					);
				}

				const cart = await CustomerService.addProductToCart(
					customer.id,
					product.id
				);

				return {
					cart,
					localCart: []
				};
			}

			return {
				cart: null,
				localCart: [
					...thunk.getState().purchase.localCart,
					product
				]
			};
		}
		catch(e) {
			console.error(e);
			return thunk.rejectWithValue(e);
		}
	}
);

export const removeProductFromCart = createAsyncThunk<{ cart: DTO<Cart> | null, localCart: DTO<Product>[]; }, DTO<Product>, { state: RootState; }>(
	"purchase/removeProductFromCart",
	async (product, thunk) => {
		try {
			const customer = thunk.getState().application.customer;

			if(customer) {
				let cart = await CustomerService.getCustomerCart(customer.id, ["products"]);

				//if cart is found, do DELETE, otherwise remove from local cart
				if(cart) {
					cart = await CustomerService.removeProductFromCart(
						customer.id,
						product.id
					);
					return {
						cart,
						localCart: []
					};
				}
			}

			return {
				cart: null,
				localCart: thunk.getState().purchase.localCart.filter(p => p.id !== product.id)
			};
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const syncLocalCartToServer = createAsyncThunk<DTO<Cart>, void, { state: RootState; }>(
	"purchase/syncLocalCartToServer",
	async (_, thunk) => {
		try {
			const customer = thunk.getState().application.customer;
			if(!customer) {
				throw new Error("No customer found in state");
			}

			const localCart = [...thunk.getState().purchase.localCart];
			while(localCart.length > 0) {
				const product = localCart.shift();
				if(!product) {
					continue;
				}

				await CustomerService.addProductToCart(
					customer.id,
					product.id
				);
			}

			const cart = await CustomerService.getCustomerCart(
				customer.id,
				["products"]
			);

			if(!cart) {
				throw new Error(`Could not get cart for customer [${customer.id}]`);
			}

			return cart;
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const convertCartToPurchase = createAsyncThunk<DTO<Purchase>, CreatePurchaseIntent[], { state: RootState; }>(
	"purchase/convertCartToPurchase",
	async (intents, thunk) => {
		try {
			const customer = thunk.getState().application.customer;
			if(!customer) {
				throw new Error("No customer found in state");
			}
			return await CustomerService.convertCartToPurchase(customer.id, intents);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const createPurchase = createAsyncThunk<DTO<Purchase>, CreatePurchaseIntent[], { state: RootState; }>(
	"purchase/createPurchase",
	async (intents, thunk) => {
		try {
			const marketCode = ReferenceService.getMarketCode();
			if(!marketCode) {
				throw new Error(`could not get market from state`);
			}

			return PurchaseService.createPurchase(
				marketCode,
				intents
			);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const createPurchasePaymentIntent = createAsyncThunk<DTO<Payment>, { purchaseId: string, estimateId: string, paymentMethod?: DTO<PaymentMethod>; }, { state: RootState; }>(
	"purchase/createPurchasePaymentIntent",
	async (data, thunk) => {
		try {
			return await PurchaseService.createPurchasePaymentIntent(
				data.purchaseId,
				data.estimateId,
				data.paymentMethod
			);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const updatePurchaseProductIntent = createAsyncThunk<DTO<PurchaseIntent>, { purchaseId: string, intentId: string, updates: Partial<DTO<PurchaseIntent>>; }, { state: RootState; }>(
	"purchase/updatePurchaseProductIntent",
	async (data, thunk) => {
		try {
			return await PurchaseService.updatePurchaseProductIntent(
				data.purchaseId,
				data.intentId,
				data.updates
			);
		}
		catch(e) {
			return thunk.rejectWithValue(e);
		}
	}
);

export const purchaseSlice = createSlice({
	name: "purchase",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setPurchase(state: typeof initialState, action: PayloadAction<DTO<Purchase> | null>): void {
			state.purchase = action.payload;
		},
		setPayment(state: typeof initialState, action: PayloadAction<DTO<Payment> | null>): void {
			state.payment = action.payload;
		},
		setPurchaseEstimate(state: typeof initialState, action: PayloadAction<DTO<PurchaseEstimate>>): void {
			state.estimate = action.payload;
		},
		setMarket(state: typeof initialState, action: PayloadAction<DTO<Market>>): void {
			ReferenceService.setMarketCode(action.payload.code);
			state.market = action.payload;
		},
		setShouldBeginCheckout(state: typeof initialState, action: PayloadAction<boolean>): void {
			state.shouldBeginCheckout = action.payload;
		},
		setIsPurchaseInProgress(state: typeof initialState, action: PayloadAction<boolean>): void {
			state.isPurchaseInProgress = action.payload;
		},
		resetCartProductQuantity(state: typeof initialState, action: PayloadAction<void>): void {
			state.cartProductQuantity = {};
		},
		updateCartProductQuantity(state: typeof initialState, action: PayloadAction<{ productId: string, quantity: number; }>): void {
			const { productId, quantity } = action.payload;
			state.cartProductQuantity = {
				...state.cartProductQuantity,
				[productId]: quantity
			};
		}
	},
	extraReducers: (builder) => {
		builder.addCase(createPurchaseEstimate.fulfilled, (state, action) => {
			state.estimate = action.payload;
		});
		builder.addCase(updatePurchase.fulfilled, (state, action) => {
			state.purchase = action.payload;
		});
		builder.addCase(fetchCustomerCart.fulfilled, (state, action) => {
			state.cart = action.payload;
		});
		builder.addCase(addProductToCart.fulfilled, (state, action) => {
			const { cart, localCart } = action.payload;

			if(cart) {
				state.cart = {
					...cart
				};
				return;
			}

			state.localCart = [
				...localCart
			];
		});
		builder.addCase(removeProductFromCart.fulfilled, (state, action) => {
			const { cart, localCart } = action.payload;

			if(cart) {
				state.cart = {
					...cart
				};
				return;
			}

			state.localCart = [
				...localCart
			];
		});
		builder.addCase(syncLocalCartToServer.fulfilled, (state, action) => {
			state.cart = {
				...action.payload
			};
			state.localCart = [
				...[]
			];
		});
		builder.addCase(convertCartToPurchase.fulfilled, (state, action) => {
			state.isPurchaseInProgress = true;
			state.purchase = action.payload;
			state.payment = null;
		});
		builder.addCase(createPurchase.fulfilled, (state, action) => {
			state.isPurchaseInProgress = true;
			state.purchase = action.payload;
			state.payment = null;
		});
		builder.addCase(createPurchasePaymentIntent.fulfilled, (state, action) => {
			state.payment = action.payload;
			state.isPurchaseInProgress = false;
		});
		builder.addCase(fetchPurchase.fulfilled, (state, action) => {
			state.purchase = action.payload;
		});
		builder.addCase(fetchActivePurchase.fulfilled, (state, action) => {
			if(action.payload) {
				state.purchase = action.payload;
			}
		});
		builder.addCase(fetchPayment.fulfilled, (state, action) => {
			state.payment = action.payload;
		});
		builder.addCase(cancelPurchase.fulfilled, (state, action) => {
			state.purchase = null;
			state.isPurchaseInProgress = false;
		});
	}
});

export const {
	setPurchase,
	setPurchaseEstimate,
	setMarket,
	setPayment,
	setShouldBeginCheckout,
	setIsPurchaseInProgress,
	updateCartProductQuantity,
	resetCartProductQuantity
} = purchaseSlice.actions;

export const selectCurrentMarket = (state: RootState): DTO<Market> | null => state.purchase.market;

export const selectCart = (state: RootState): DTO<Cart> | null => state.purchase.cart;
export const selectCartProductQuantity = (state: RootState): Record<string, number> => state.purchase.cartProductQuantity;
export const selectLocalCart = (state: RootState): DTO<Product>[] => state.purchase.localCart;
export const selectCartProducts = (state: RootState): DTO<Product>[] => {
	if(state.purchase.cart) {
		return state.purchase.cart.products;
	}

	return state.purchase.localCart;
};

export const selectPayment = (state: RootState): DTO<Payment> | null => state.purchase.payment;
export const selectPurchase = (state: RootState): DTO<Purchase> | null => state.purchase.purchase;
export const selectPurchaseEstimate = (state: RootState): DTO<PurchaseEstimate> | null => state.purchase.estimate;
export const selectShouldBeginCheckout = (state: RootState): boolean => state.purchase.shouldBeginCheckout;
export const selectIsPurchaseInProgress = (state: RootState): boolean => state.purchase.isPurchaseInProgress;

export default purchaseSlice.reducer;