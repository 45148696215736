import { Box, Heading, Layer } from "grommet";
import { Link } from "./Link";

export const Unauthorized: React.FC = (props) => {
	return (
		<Layer>
			<Box margin="large">
				<Heading level="3">
					You are not authorized to view this page
				</Heading>
				<Link to="/" label="Return Home"/>	
			</Box>
		</Layer>
	)
};