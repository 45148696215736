import React, { useEffect, useState } from "react";
import { Anchor, Box, Button, Form, FormField, Grid, Heading, Image, Layer, MaskedInput, Spinner, Text, TextInput } from "grommet";
import regoIcon from "../../../img/icon.png";
import { AuthService, CustomerService, UtilService } from "../../../app/services";
import { useAppDispatch, setLogin, setRequiresRegistration, setRequiresLogin } from "../../../app/store";
import { Contact, ContactType, Customer, DTO } from "../../../types";
import { Alert, FormCheckmark, Mail, Phone } from "grommet-icons";
import { isErrorResponse } from "../../../helpers";
import { SlimHeading } from "../../common";

const PasswordComplexity: React.FC<{ label: string, valid: boolean; }> = (props) => {
	return (
		<Box direction="row" gap="small" align="center">
			{props.valid
				? (
					<FormCheckmark
						color="green"
					/>
				)
				: (
					<Alert
						color="red"
					/>
				)}
			<Text>
				{props.label}
			</Text>
		</Box>
	);
};

interface RegisterProps {
	username?: string;
	isVisible: boolean;
}

interface RegisterState {
	username: string;
	password: string;
	confirm: string;
	wasConfirmationSent: boolean;
	confirmationSentAt?: number;
	registerErrorMessage?: string;
	usernameLoginType: ContactType;
	isAttemptingRegister?: boolean;
	firstName: string;
	lastName: string;
	customer?: DTO<Customer>;
	code: string;
	hasNumber: boolean;
	hasUpperCase: boolean;
	hasLowerCase: boolean;
	hasMinimumLength: boolean;
}

export const Register: React.FC<RegisterProps> = (props: RegisterProps) => {
	const dispatch = useAppDispatch();

	const [loginState, setLoginState] = useState<RegisterState>({
		username: props.username ?? "",
		password: "",
		confirm: "",
		wasConfirmationSent: false,
		registerErrorMessage: "",
		usernameLoginType: ContactType.PHONE,
		firstName: "",
		lastName: "",
		code: "",
		hasLowerCase: false,
		hasMinimumLength: false,
		hasNumber: false,
		hasUpperCase: false
	});

	function updateState(changes: Partial<RegisterState>): void {
		setLoginState((state) => {
			return {
				...state,
				...changes
			};
		});
	}

	useEffect(() => {
		const { password } = loginState;
		updateState({
			hasNumber: /\d{1,}/.test(password),
			hasLowerCase: /[a-z]{1,}/.test(password),
			hasUpperCase: /[A-Z]{1,}/.test(password),
			hasMinimumLength: password.length >= 8
		});
	}, [loginState.password]);

	function login(): void {
		dispatch(setRequiresRegistration(false));
		dispatch(setRequiresLogin(true));
	}

	const toggleUsernameLoginType = (): void => {
		setLoginState({
			...loginState,
			username: "",
			usernameLoginType: loginState.usernameLoginType === ContactType.EMAIL
				? ContactType.PHONE
				: ContactType.EMAIL
		});
	};

	const sendVerificationCode = async (): Promise<void> => {
		try {
			//Check sent timestamp
			if(loginState.wasConfirmationSent) {
				//can't send another code until 60 seconds
				const LIMIT = 60 * 1000;
				if(Date.now() <= LIMIT + (loginState.confirmationSentAt ?? 0)) {
					setLoginState({
						...loginState,
						registerErrorMessage: "You can't request another login code yet"
					});
					return;
				}
			}

			if(!loginState.customer) {
				setLoginState({
					...loginState,
					wasConfirmationSent: false,
					confirmationSentAt: 0,
					registerErrorMessage: ""
				});
				return;
			}

			const customer = loginState.customer;
			if(!await CustomerService.sendVerificationCode(customer.id, customer.contacts[0].id)) {
				throw new Error("Failed to send verification code");
			};

			setLoginState({
				...loginState,
				wasConfirmationSent: true,
				confirmationSentAt: Date.now(),
				registerErrorMessage: ""
			});
		}
		catch(e) {
			console.error("Failed to send login code", e);
			setLoginState({
				...loginState,
				wasConfirmationSent: false,
				registerErrorMessage: "Something wen't wrong, and we weren't able to send the code. Make sure your username is correct and try again"
			});
		}
	};

	const register = async (): Promise<void> => {
		try {
			setLoginState({
				...loginState,
				isAttemptingRegister: true
			});

			if(loginState.wasConfirmationSent) {
				if(!loginState.customer) {
					throw new Error("confirmation was sent but no customer in state");
				}

				const customer = loginState.customer;

				const contact = await CustomerService.confirmVerificationCode(
					customer.id,
					customer.contacts[0].id,
					loginState.code
				)
					.catch(err => {
						console.error("Failed to verify code", err);
						setLoginState({
							...loginState,
							isAttemptingRegister: false,
							registerErrorMessage: "We weren't able to confirm that code"
						});
						return false;
					});

				if(!contact) {
					return;
				}

				const user = await AuthService.login(
					(loginState.usernameLoginType === ContactType.PHONE)
						? UtilService.formatPhoneToNumber(loginState.username)
						: loginState.username,
					loginState.password
				);

				if(!user) {
					console.error("Failed to login user");
					setLoginState({
						...loginState,
						isAttemptingRegister: false,
						registerErrorMessage: "We weren't able to get you logged in"
					});
					return;
				}

				setLoginState({
					...loginState,
					isAttemptingRegister: false,
					registerErrorMessage: ""
				});

				dispatch(setLogin({
					user,
					customer: await CustomerService.getCustomerSelf()
				}));
				return;
			}

			const customerData: DTO<Customer> = {
				first_name: loginState.firstName,
				last_name: loginState.lastName,
				contacts: [
					{
						type: loginState.usernameLoginType,
						value: loginState.usernameLoginType === ContactType.PHONE
							? UtilService.formatPhoneToNumber(loginState.username)
							: loginState.username,
						is_primary: true
					} as Contact
				]
			} as Customer;

			const customer = await AuthService.register(customerData, loginState.password);
			console.debug("GOT CUSTOMER", customer);

			if(!await CustomerService.sendVerificationCode(customer.id, customer.contacts[0].id)) {
				//TODO: This will throw on retry due to conflict on contacts
				setLoginState({
					...loginState,
					isAttemptingRegister: false,
					customer,
					registerErrorMessage: "We weren't able to send a verification code. Try again"
				});
				return;
			}

			setLoginState({
				...loginState,
				customer,
				isAttemptingRegister: false,
				wasConfirmationSent: true
			});
		}
		catch(e) {
			let errorMessage = "Something went wrong and we weren't able to create your account";
			console.error(e);

			if(isErrorResponse(e)) {
				errorMessage = e.error.errors?.find(e => e.friendly)?.message ?? errorMessage;
			}
			setLoginState({
				...loginState,
				isAttemptingRegister: false,
				registerErrorMessage: errorMessage
			});
		}
	};

	return (
		<React.Fragment>
			{props.isVisible && (
				<Box overflow="auto">
					<Layer responsive>
						<Box
							pad={{
								top: "small",
								left: "medium",
								right: "medium",
								bottom: "small"
							}}
							flex
						>
							<Box
								align="center"
								margin={{
									bottom: "small"
								}}
							>
								<Heading
									level="3"
									margin="small"
								>Create Account</Heading>
							</Box>
							{loginState.wasConfirmationSent && (
								<Box>
									<SlimHeading level={5}>
										We need to verify your provided contact method before completing your registration. Enter the code sent to you below
									</SlimHeading>
								</Box>
							)}
							<Form
								validate="change"
								messages={{
									required: "This field is required",
									invalid: "This doesn't match the required format"
								}}
								value={loginState}
								onChange={nextValue => setLoginState({
									...nextValue,
									registerErrorMessage: ""
								})}
								onSubmit={({ value }) => {
									register().catch(err => {
										console.error("Failed at register", err);
									});
								}}
							>
								<Box direction="column" flex>
									<Box gap="xsmall" direction="column" overflow={{ vertical: "scroll" }}>
										<Box direction="column">
											<FormField
												margin="xsmall"
												name="username"
												htmlFor="username"
												label={
													loginState.usernameLoginType === ContactType.EMAIL
														? "Email Address"
														: "Phone Number"
												}
												info={
													loginState.usernameLoginType === ContactType.EMAIL
														? <Anchor disabled={loginState.wasConfirmationSent} onClick={toggleUsernameLoginType}>Register with phone?</Anchor>
														: <Anchor disabled={loginState.wasConfirmationSent} onClick={toggleUsernameLoginType}>Register with email? </Anchor>
												}
												readOnly={loginState.wasConfirmationSent}
												validate={[
													(value: unknown) => {
														if(!value) {
															return {
																status: "error",
																message: "This field is required"
															};
														}

														if(loginState.usernameLoginType === ContactType.PHONE) {
															try {
																UtilService.formatPhoneToNumber(loginState.username);
															}
															catch(e) {
																return {
																	status: "error",
																	message: "Please enter a valid phone number"
																};
															}
														}
													}
												]}
											>
												{loginState.usernameLoginType === ContactType.EMAIL
													? (<MaskedInput
														id="username"
														name="username"
														inputMode={"email"}
														type="email"
														mask={[
															{
																regexp: /^[\w\-_.]+$/,
																placeholder: 'example',
															},
															{ fixed: '@' },
															{
																regexp: /^[\w]+$/,
																placeholder: 'rego-app',
															},
															{
																fixed: '.'
															},
															{
																regexp: /^[\w]+$/,
																placeholder: 'com',
															},
														]}
														icon={<Mail />}
													/>)
													: (<MaskedInput
														id="username"
														name="username"
														inputMode={"tel"}
														mask={[
															{ fixed: '1 (' },
															{
																length: 3,
																regexp: /^[0-9]{1,3}$/,
																placeholder: 'xxx',
															},
															{ fixed: ')' },
															{ fixed: ' ' },
															{
																length: 3,
																regexp: /^[0-9]{1,3}$/,
																placeholder: 'xxx',
															},
															{ fixed: '-' },
															{
																length: 4,
																regexp: /^[0-9]{1,4}$/,
																placeholder: 'xxxx',
															},
														]}
														icon={<Phone />}
													/>)
												}
											</FormField>

											{loginState.wasConfirmationSent
												? (
													<Box gap="small" direction="column">
														<FormField
															margin="xsmall"
															inputMode="tel"
															required
															name="code"
															htmlFor="code"
															label="Login Code"
														>
															<TextInput autoComplete="one-time-code" id="code" type="text" name="code" pattern="[0-9]{6}" />
														</FormField>
														<Box direction="column" align="start">
															<Anchor onClick={sendVerificationCode}>Send another code?</Anchor>
														</Box>
													</Box>
												)
												: (
													<Box>
														<Grid columns={{ count: 2, size: "auto" }} gap="small">
															<FormField
																margin="xsmall"
																required
																name="firstName"
																htmlFor="firstName"
																label="First Name"
															>
																<TextInput
																	id="firstName"
																	name="firstName"
																/>
															</FormField>
															<FormField
																margin="xsmall"
																required
																name="lastName"
																htmlFor="lastName"
																label="Last Name"
															>
																<TextInput
																	id="lastName"
																	name="lastName"
																/>
															</FormField>
														</Grid>
														<Grid columns={{ count: 2, size: "auto" }} gap="small">
															<FormField
																margin="xsmall"
																required
																name="password"
																htmlFor="password"
																label="Password"
																validate={[
																	(value: unknown) => {
																		if(!value) {
																			return {
																				status: "error",
																				message: "This field is required"
																			};
																		}

																		const { hasLowerCase, hasMinimumLength, hasNumber, hasUpperCase } = loginState;
																		if(!hasLowerCase || !hasMinimumLength || !hasNumber || !hasUpperCase) {
																			return {
																				status: "error",
																				message: "Password does not meet complexity requirements"
																			};
																		}
																	}
																]}
															>
																<TextInput id="password" type="password" name="password" />
															</FormField>
															<FormField
																margin="xsmall"
																name="confirm"
																htmlFor="confirm"
																label="Confirm"
																validate={[
																	(value: unknown) => {
																		if(!value) {
																			return {
																				status: "error",
																				message: "This field is required"
																			};
																		}

																		if(value !== loginState.password) {
																			return {
																				status: "error",
																				message: "Passwords do not match"
																			};
																		}
																	}
																]}
															>
																<TextInput id="confirm" type="password" name="confirm" />
															</FormField>
														</Grid>
														<Box gap="xsmall" margin="small">
															<PasswordComplexity
																valid={loginState.hasLowerCase}
																label="Contains a lower case letter"
															/>
															<PasswordComplexity
																valid={loginState.hasUpperCase}
																label="Contains an upper case letter"
															/>
															<PasswordComplexity
																valid={loginState.hasNumber}
																label="Contains a number"
															/>
															<PasswordComplexity
																valid={loginState.hasMinimumLength}
																label="Contains 8 characters"
															/>
														</Box>
													</Box>
												)
											}
										</Box>
										<FormField contentProps={{ border: false }} pad={false} margin="none" hidden error={loginState.registerErrorMessage} />
									</Box>
									<Box direction="column" gap="small" justify="end" align="center" flex>
										<Button
											type="submit"
											icon={loginState.isAttemptingRegister ? <Spinner height="inherit" size="xsmall" /> : undefined}
											primary
											label={loginState.isAttemptingRegister ? "" : "Create Account"}
										/>
										<Anchor onClick={login}>Login?</Anchor>
									</Box>
								</Box>
							</Form>
						</Box>
					</Layer>
				</Box>
			)}
		</React.Fragment>
	);
};