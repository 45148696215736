import { CreatePurchaseIntent, DeliveryWindow, DTO, ListingChannel, Payment, PaymentMethod, Product, Purchase, PurchaseDelivery, PurchaseEstimate, PurchaseIntent, RelationExpand, Vehicle } from "@rego-app/common";
import qs from "qs";
import { ServerResponse } from "../../types";
import { RequestService } from "./request";

export const PurchaseService = {
	async getPurchase(purchaseId: string, expand?: RelationExpand<Purchase>): Promise<DTO<Purchase>> {
		const result = await RequestService
			.get<ServerResponse<DTO<Purchase>>>(
				`/api/v1/purchase/${purchaseId}/?` + qs.stringify({ expand: (expand ?? []).join(",") })
			);
		return result.data.result;
	},

	async listPurchases(filters: Partial<Purchase>, expand?: RelationExpand<Purchase>): Promise<DTO<Purchase>[]> {
		const result = await RequestService
			.get<ServerResponse<Purchase[]>>(
				`/api/v1/purchase/?` + qs.stringify({ ...filters, expand: (expand ?? []).join(",") })
			);
		return result.data.result;
	},

	async createPurchase(marketCode: string, products: CreatePurchaseIntent[]): Promise<DTO<Purchase>> {
		const result = await RequestService
			.post<ServerResponse<DTO<Purchase>>>(`/api/v1/purchase`, {
				products,
				market_code: marketCode
			});
		return result.data.result;
	},

	async updatePurchase(purchaseId: string, updates: Partial<DTO<Purchase>>): Promise<DTO<Purchase>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<Purchase>>>(`/api/v1/purchase/${purchaseId}`, {
				...updates
			});
		return result.data.result;
	},

	async extendPurchase(purchaseId: string): Promise<DTO<Purchase>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<Purchase>>>(`/api/v1/purchase/${purchaseId}/extend`);
		return result.data.result;
	},

	async cancelPurchase(purchaseId: string): Promise<DTO<Purchase>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<Purchase>>>(`/api/v1/purchase/${purchaseId}/cancel`);
		return result.data.result;
	},

	async createPurchaseEstimate(purchaseId: string): Promise<DTO<PurchaseEstimate>> {
		const result = await RequestService
			.post<ServerResponse<DTO<PurchaseEstimate>>>(`/api/v1/purchase/${purchaseId}/estimate`);
		return result.data.result;
	},

	async createPurchasePaymentIntent(purchaseId: string, estimateId: string, paymentMethod?: DTO<PaymentMethod>): Promise<DTO<Payment>> {
		const result = await RequestService
			.post<ServerResponse<DTO<Payment>>>(`/api/v1/purchase/${purchaseId}/estimate/${estimateId}/payment`, {
				payment_method: paymentMethod
			});
		return result.data.result;
	},

	async createPurchaseDelivery(purchaseId: string, window: DeliveryWindow, products: DTO<Product>[], requiresHelper: boolean, vehicle: DTO<Vehicle>): Promise<DTO<PurchaseDelivery>> {
		const result = await RequestService
			.put<ServerResponse<DTO<PurchaseDelivery>>>(`/api/v1/purchase/${purchaseId}/delivery`, {
				window,
				products,
				vehicle,
				requires_helper: requiresHelper
			});
		return result.data.result;
	},

	async updatePurchaseProductIntent(purchaseId: string, intentId: string, updates: Partial<DTO<PurchaseIntent>>): Promise<DTO<PurchaseIntent>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<PurchaseIntent>>>(`/api/v1/purchase/${purchaseId}/intent/${intentId}`, {
				...updates
			});
		return result.data.result;
	},

	async getPurchaseEstimates(purchaseId: string, expand: RelationExpand<PurchaseEstimate>): Promise<DTO<PurchaseEstimate>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<PurchaseEstimate>[]>>(`/api/v1/purchase/${purchaseId}/estimate`);
		return result.data.result;
	},

	async getLastPurchaseEstimates(purchaseId: string): Promise<DTO<PurchaseEstimate> | null> {
		const result = await RequestService
			.get<ServerResponse<DTO<PurchaseEstimate> | null>>(`/api/v1/purchase/${purchaseId}/estimate/last`);
		return result.data.result;
	},
};