import { ProductIntent } from "../../../types";
import { Route, Routes } from "react-router-dom";
import { ErrorPage } from "../../common";
import { CaptureOrderIntent, OrderController, SelectOrderIntent } from "../controller";
import { OrderAddressForm, OrderHome, OrderProductController, OrderTiming } from "../pages";
import { OrderSummary } from "../pages/review";
import { OrderContactDetails } from "../pages/contact";
import { OrderConfirmation } from "../pages/confirmation";

export const OrderRouter: React.FC = (props) => {
	return (
		<Routes>
			<Route path="/" element={<OrderController />}>
				<Route path="" element={
					<SelectOrderIntent />
				} />
				<Route path="dashboard" element={
					<OrderHome />
				} />
				<Route path="sell" element={
					<SelectOrderIntent
						intent={ProductIntent.SELL}
					/>
				} />
				<Route path="donate" element={
					<SelectOrderIntent
						intent={ProductIntent.DONATE}
					/>
				} />
				<Route path="junk" element={
					<SelectOrderIntent
						intent={ProductIntent.JUNK}
					/>
				} />
				<Route path="address" element={
					<OrderAddressForm />
				} />
				<Route path="products" element={
					<OrderProductController />
				} />
				<Route path="timing" element={
					<OrderTiming />
				} />
				<Route path="contact" element={
					<OrderContactDetails />
				} />
				<Route path="review" element={
					<OrderSummary />
				} />
				<Route path="confirmation" element={
					<OrderConfirmation />
				} />
			</Route>
			<Route element={
				<ErrorPage errorCode={404} />
			} />
		</Routes>
	);
};