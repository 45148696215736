import { DTO, ProductIntent, Service, ServiceGratuity, ServiceReview } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, Form, FormField, Grid, Heading, RadioButton, Spinner, Text, TextArea, TextInput } from "grommet";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../app/store";
import { getNumberFormValidations, getOptionalNumberFormValidations, getStandardFormValidations, isDonation, isRemoval } from "../../../helpers";
import { SlimHeading, useScreenSize } from "../../common";
import { Star, StarOutline } from "@mui/icons-material";
import { BRAND_COLOR } from "../../../theme";
import { OrderService } from "../../../app/services";
import { useSnackbar } from "notistack";

interface ServiceReviewPageProps {
	service: DTO<Service>;
}

export const ServiceReviewPage: React.FC<ServiceReviewPageProps> = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [service, setService] = useState(props.service);

	const [state, setState] = useState<ReviewFormState>({
		isSubmitting: false,
		comments: service.review?.comments ?? "",
		rating: service.review?.rating,
		gratuity: service.gratuity?.amount ?? 0
	});

	const [review, setReview] = useState<DTO<ServiceReview> | null>(service.review);
	const [gratuity, setGratuity] = useState<DTO<ServiceGratuity> | null>(service.gratuity ?? null);

	useEffect(() => {
		setService({ ...props.service });
	}, [props.service]);

	useEffect(() => {
		setState({
			...state,
			rating: service.review?.rating ?? state.rating,
			comments: service.review?.comments ?? state.comments,
			gratuity: service.gratuity?.amount ?? state.gratuity
		});
	}, [service]);

	function wrapStateUpdates(updates: Partial<ReviewFormState>): void {
		setState((old) => {
			return {
				...old,
				...updates
			};
		});
	}

	const intent = useMemo(() => {
		if(isDonation(props.service)) {
			return ProductIntent.DONATE;
		}

		if(isRemoval(props.service)) {
			return ProductIntent.JUNK;
		}

		throw new Error(`could not determine intent for service`);
	}, [service]);

	function handleCreateReview(rating: number, comments: string): Promise<DTO<Service>> {
		return OrderService.createServiceReview(props.service.id, rating, comments)
			.then(res => {
				setReview({ ...res });
				return OrderService.getService(service.id);
			});
	}

	function handleCreateGratuity(amount: number): Promise<DTO<Service>> {
		return OrderService.createServiceGratuity(service.id, amount).then(res => {
			setGratuity({ ...res });
			return OrderService.getService(service.id);
		});
	}

	async function handleCreates(): Promise<DTO<Service>> {
		let updatedService = service;

		if(review) {
			console.debug("found existing review", review);
		}
		else {
			updatedService = await handleCreateReview(
				Number(state.rating),
				state.comments
			).catch(err => {
				console.error("Failed to create review", err);
				snack.enqueueSnackbar("We ran into an issue submitting your review", { variant: "error" });
				throw err;
			});
		}

		if(gratuity) {
			console.debug(`found existing gratuity`, gratuity);
			return updatedService;
		}
		else {
			const amount = state.gratuity;
			if(isNaN(Number(amount)) || Number(amount) <= 0) {
				console.debug("got amount", amount, "not creating gratuity");
				return updatedService;
			}

			return updatedService = await handleCreateGratuity(Number(amount)).catch(err => {
				console.error("Failed during gratuity submit", err);
				snack.enqueueSnackbar("Your review was submitted, but we ran into an issue submitting your tip", { variant: "error" });
				throw err;
			});
		}
	}

	function handleFormSubmit(): void {
		wrapStateUpdates({ isSubmitting: true });
		handleCreates()
			.then(() => {
				snack.enqueueSnackbar("Your review was submitted successfully!", { variant: "success" });
				dispatch(push("/dashboard"));
			})
			.catch(err => {
				console.error("Catching error from creates", err);
			})
			.finally(() => {
				wrapStateUpdates({ isSubmitting: false });
			});
	}

	return (
		<Box margin="medium" align="center" justify="center">
			<ReviewForm
				intent={intent}
				state={state}
				setState={wrapStateUpdates}
				onSubmit={handleFormSubmit}
				wasAlreadySubmitted={!!props.service.review?.rating}
			/>
		</Box>
	);
};

interface ReviewFormState {
	isSubmitting: boolean;
	rating?: number | undefined;
	comments: string;
	gratuity: number | string;
}

interface ReviewFormProps {
	state: ReviewFormState;
	intent: ProductIntent;
	setState(updates: Partial<ReviewFormState>): void;
	onSubmit(): void;
	wasAlreadySubmitted: boolean;
}

export const ReviewForm: React.FC<ReviewFormProps> = (props) => {
	const size = useScreenSize();
	const [otherTipAmount, setOtherTipAmount] = useState(false);

	function handleRatingIconClick(index: number) {
		props.setState({ rating: index });
	}

	const ratingQuestion = useMemo(() => {
		if(props.intent === ProductIntent.DONATE) {
			return "How satisfied were you with your donation pickup service?";
		}

		if(props.intent === ProductIntent.JUNK) {
			return "How satisfied were you with your junk removal service?";
		}

		return "How satisfied were you with your pickup service?";
	}, [props.intent]);

	return (
		<Box gap="small" style={{ maxWidth: "600px" }}>
			<Box align="center" gap="small">
				<Heading level="1" color="brand">
					Rate Your Order
				</Heading>
				<Form
					value={props.state}
					validate="submit"
					onSubmit={props.onSubmit}
				>
					<Box margin="medium" gap="small">
						<Box align="start">
							<SlimHeading level="4">
								{ratingQuestion}
							</SlimHeading>
						</Box>
						<FormField
							name="rating"
							disabled={props.wasAlreadySubmitted}
							value={props.state.rating}
							contentProps={{
								border: undefined
							}}
							validate={[
								...getStandardFormValidations()
							]}
						>
							<Box gap="small" justify="around" align="center" direction="row">
								<RatingIcon disabled={props.wasAlreadySubmitted} index={1} rating={props.state.rating ?? 0} onClick={handleRatingIconClick} />
								<RatingIcon disabled={props.wasAlreadySubmitted} index={2} rating={props.state.rating ?? 0} onClick={handleRatingIconClick} />
								<RatingIcon disabled={props.wasAlreadySubmitted} index={3} rating={props.state.rating ?? 0} onClick={handleRatingIconClick} />
								<RatingIcon disabled={props.wasAlreadySubmitted} index={4} rating={props.state.rating ?? 0} onClick={handleRatingIconClick} />
								<RatingIcon disabled={props.wasAlreadySubmitted} index={5} rating={props.state.rating ?? 0} onClick={handleRatingIconClick} />
							</Box>
						</FormField>
						<FormField
							name="comments"
							disabled={props.wasAlreadySubmitted}
							label="Would you like to leave any comments?"
						>
							<TextArea
								rows={3}
								name="comments"
								value={props.state.comments}
								disabled={props.wasAlreadySubmitted}
								onChange={(event) => props.setState({ comments: event.target.value })}
							/>
						</FormField>
						<SlimHeading level="3">
							Would you like to leave a tip?
						</SlimHeading>
						{otherTipAmount && (
							<FormField
								name="tip"
								disabled={props.wasAlreadySubmitted}
								validate={[
									...getOptionalNumberFormValidations()
								]}
							>

								<TextInput
									name="tip"
									icon={<Text size="large" weight="bold">$</Text>}
									inputMode="numeric"
									value={props.state.gratuity}
									disabled={props.wasAlreadySubmitted}
									onChange={(event) => props.setState({ gratuity: event.target.value })}
								/>
							</FormField>
						)}
						<Grid columns={size === "small" ? { count: 2, size: "flex" } : { count: 4, size: "flex" }} gap="small">
							<Button
								label="No Tip"
								primary={props.state.gratuity === 0 && !otherTipAmount}
								onClick={() => {
									setOtherTipAmount(false);
									props.setState({ gratuity: 0 });
								}}
								disabled={props.wasAlreadySubmitted}
							/>
							<Button
								label="$10"
								primary={props.state.gratuity === 10 && !otherTipAmount}
								onClick={() => {
									setOtherTipAmount(false);
									props.setState({ gratuity: 10 });
								}}
								disabled={props.wasAlreadySubmitted}
							/>
							<Button
								label="$20"
								primary={props.state.gratuity === 20 && !otherTipAmount}
								onClick={() => {
									setOtherTipAmount(false);
									props.setState({ gratuity: 20 });
								}}
								disabled={props.wasAlreadySubmitted}
							/>
							<Button
								label="Other"
								primary={otherTipAmount}
								onClick={() => setOtherTipAmount(true)}
								disabled={props.wasAlreadySubmitted}
							/>
						</Grid>
					</Box>
					<Box align="end" margin={{ top: "medium" }}>
						<Button
							primary
							label="Submit Review"
							type="submit"
							disabled={props.state.isSubmitting || props.wasAlreadySubmitted}
							icon={props.state.isSubmitting ? <Spinner /> : undefined}
						/>
					</Box>
				</Form>
			</Box>
		</Box>
	);
};

interface RatingIconProps {
	index: number;
	rating: number;
	onClick(index: number): void;
	disabled: boolean;
}

export const RatingIcon: React.FC<RatingIconProps> = (props) => {
	const isActive = useMemo(() => {
		return props.rating >= props.index;
	}, [props.index, props.rating]);

	return (
		<Box
			pad="small"
			align="center"
			justify="center"
			onClick={() => !props.disabled && props.onClick(props.index)}
		>
			<RadioButton
				name="rating"
				value={props.index}
			>
				{() => (
					<Fragment>
						{isActive
							? (
								<Star
									sx={{ color: BRAND_COLOR }}
									fontSize="large"
								/>
							)
							: (
								<StarOutline
									fontSize="large"
								/>
							)}
					</Fragment>
				)}
			</RadioButton>
		</Box>
	);
};