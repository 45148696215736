import { Box, Button, Layer } from "grommet";
import { FormClose } from "grommet-icons";
import config from "../../../app/config";
import { SlimHeading } from "../../common";

export const ForgotPassword: React.FC<{ onClose: () => void; }> = (props) => {
	return (
		<Layer
			onClickOutside={props.onClose}
			onEsc={props.onClose}
		>
			<Box margin="medium">
				<Box align="end" justify="center">
					<Button
						icon={<FormClose />}
						hoverIndicator
						onClick={props.onClose}
					/>
				</Box>
				<Box gap="medium">
					<SlimHeading level="3">Forgot your password?</SlimHeading>
					<Box margin="small" gap="small">
						<SlimHeading level="5">Try logging in with a login code to your registered email or phone number. If you don't have access or are unable to login, get in touch with us.</SlimHeading>
						<Box align="start">
							<Button
								label="Call Us"
								href={`tel:${config.contact.phone}`}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Layer>
	);
};