import React, { createContext, useContext, useEffect, useState } from "react";
import { StepsProvider } from "react-step-builder";
import { selectIsLoaded, useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchFrontendConfig, selectConfig, selectFatalError, selectUser, setLoaded } from "../../../app/store/application";
import { Timezone } from "../../../types";
import { AuthProvider } from "../../auth";
import { Loader } from "../components";
import { Error500 } from "../pages/500";
import { AlertProvider } from "./alert";
import { GoogleProvider } from "./google";
import { ModalProvider } from "./modal";
import { ErrorBoundary } from "react-error-boundary";
import { push } from "connected-react-router";
import { StoreProvider } from "../../partner";

export const ApplicationContext = createContext({
	timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code
});

export const ApplicationProvider: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);
	const config = useAppSelector(selectConfig);
	const isLoaded = useAppSelector(selectIsLoaded);
	const fatalError = useAppSelector(selectFatalError);
	const [keys, setKeys] = useState<Record<string, unknown>>({});

	useEffect(() => {
		function handleKeyUp(event: KeyboardEvent): void {
			setKeys((state) => {
				return { ...state, [event.key]: false };
			});
		}

		function handleKeyDown(event: KeyboardEvent): void {
			setKeys((state) => {
				return { ...state, [event.key]: true };
			});
		}

		window.addEventListener("keyup", handleKeyUp);
		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("keyup", handleKeyUp);
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	useEffect(() => {
		if(keys.Control && keys.Shift && keys.D) {
			dispatch(push("/admin/dashboard"));
		}
	}, [keys]);

	function checkIfLoaded(): boolean {
		return !!config;
	}

	useEffect(() => {
		if(isLoaded) {
			//we already loaded .. no need to check
			return;
		}

		if(!config) {
			dispatch(fetchFrontendConfig());
		}

		if(checkIfLoaded()) {
			dispatch(setLoaded());
		}

	}, [config, user]);

	if(fatalError.hasFatalError) {
		return (
			<Error500 />
		);
	}

	return (
		<ErrorBoundary
			FallbackComponent={Error500}
			onError={(error, info) => {
				console.error("Caught error in error boundary", error);
				console.debug(info);
			}}
		>
			<ApplicationContext.Provider value={{ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code }}>
				<Loader visible={!isLoaded}>
					<GoogleProvider>
						<ModalProvider>
							<AlertProvider>
								<StepsProvider>
									<AuthProvider>
										<StoreProvider>
											{props.children}
										</StoreProvider>
									</AuthProvider>
								</StepsProvider>
							</AlertProvider>
						</ModalProvider>
					</GoogleProvider>
				</Loader>
			</ApplicationContext.Provider>
		</ErrorBoundary>
	);
};

export function useTimezone(): Timezone.Code {
	const context = useContext(ApplicationContext);
	return context.timezone;
};