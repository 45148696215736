import { Box } from "grommet";
import React, { useEffect, useState } from "react";
import { useGoogle } from "../provider";
import { Loader } from "./Loader";
export interface MapMarker {
	latitude: number;
	longitude: number;
	label?: string;
}

interface MapProps {
	center?: google.maps.LatLng;
	markers: MapMarker[];
	allowFreeZoom?: boolean;
	requestCurrentLocation: boolean;
	ignoreMaxHeight?: boolean;
	ignoreMargin?: boolean;
}

export const MapComponent: React.FC<MapProps> = (props) => {
	const googleHook = useGoogle({ requestLocation: props.requestCurrentLocation });

	const [map, setMap] = useState<google.maps.Map>(null as unknown as google.maps.Map);
	const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
	const [mapId, setMapId] = useState();

	useEffect(() => {
		console.debug("GOOGLE HOOK LOADED!", googleHook);
		if(googleHook.isLoaded && !map) {
			console.debug("GOT LOADED ... CREATING MAP");
			const tempMap = new google.maps.Map(
				document.querySelector("#google-map") as HTMLElement, {
				center: props.center ?? googleHook.currentLocation ?? { lat: 39.952584, lng: -75.165221 },
				maxZoom: props.allowFreeZoom ? undefined : 14,
				zoom: 14,
				fullscreenControl: false
			});
			setMap(tempMap);
		}
	}, [googleHook.isLoaded]);

	useEffect(() => {
		if(map && googleHook.currentLocation && !props.markers.length) {
			map.setCenter(googleHook.currentLocation);
		}
	}, [map, googleHook.currentLocation]);

	useEffect(() => {
		console.debug("GOT MARKERS", props.markers);
		if(map) {
			if(props.markers.length === 0) return;

			markers.forEach(marker => {
				marker.setMap(null);
			});

			const newMarkers = props.markers.map(latLng => {
				return new google.maps.Marker({
					label: latLng.label,
					position: new google.maps.LatLng({ lat: latLng.latitude, lng: latLng.longitude }),
					map
				});
			});

			const bounds = new google.maps.LatLngBounds();

			props.markers.forEach(marker => {
				bounds.extend(new google.maps.LatLng({ lat: marker.latitude, lng: marker.longitude }));
			});

			/**
			 * https://stackoverflow.com/a/5345708
			 */
			// Don't zoom in too far on only one marker
			if(bounds.getNorthEast().equals(bounds.getSouthWest())) {
				const extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
				const extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
				bounds.extend(extendPoint1);
				bounds.extend(extendPoint2);
			}

			map.fitBounds(bounds);

			setMarkers([...newMarkers]);
		}
	}, [props.markers, map]);

	return (
		<Loader visible={!googleHook.isLoaded}>
			<Box margin={props.ignoreMargin ? undefined : "medium"} align="center" fill flex="grow">
				<div style={{ maxHeight: props.ignoreMaxHeight ? undefined : "500px", width: "100%", height: "100%" }} id="google-map"></div>
			</Box>
		</Loader>
	);
};