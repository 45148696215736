import { DTO, Media, ProductMediaContext, sanitize } from "../../../types";
import { Box, FormField, Spinner, Text, } from "grommet";
import { Upload } from "grommet-icons";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { SlimHeading } from ".";
import { fileToMedia, mediaToFile } from "../../../helpers";

type FileWithPreview = File & { preview: string; };

interface DropzoneProps<T extends DTO<Media>> {
	media: T[];
	disabled: boolean;
	isUploading: boolean;
	onMediaAdded: (files: T[]) => void;
	onMediaRemoved?: (files: T[]) => void;
}

const Preview: React.FC<{ name: string; }> = (props) => {
	return (
		<Text weight="bold">
			{props.name}
		</Text>
	);
};

export const Dropzone: React.FC<DropzoneProps<DTO<Media>>> = (props) => {
	const snack = useSnackbar();
	const [files, setFiles] = useState<FileWithPreview[]>([]);

	const onDrop = useCallback((acceptedFiles: File[]) => {
		Promise.all(acceptedFiles.map(file => fileToMedia(file, ProductMediaContext.OTHER)))
			.then(media => {
				const filesWithPreview = media.map(m => {
					const file = mediaToFile(m) as FileWithPreview;
					file.preview = URL.createObjectURL(file);
					return file as File & { preview: string; };
				});

				const filteredExisting = files.filter(f => !filesWithPreview.some(newFile => newFile.name === f.name));
				setFiles([
					...filteredExisting,
					...filesWithPreview
				]);

				props.onMediaAdded(media);
			})
			.catch(err => {
				console.error("Failed to parse file", err);
				snack.enqueueSnackbar("We had some trouble uploading that image", {
					variant: "error"
				});
			});
	}, [files, props.onMediaAdded, props]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		disabled: props.disabled,
		accept: 'image/png, image/jpg, image/jpeg, application/pdf',
		onDrop
	});

	return (
		<Box gap="small">
			<Box
				{...getRootProps()}
				border={{ color: "brand", side: "all", size: "small" }}
				round
				style={{
					minHeight: "200px"
				}}
			>
				<FormField
					name="mediaFiles"
					contentProps={{ border: undefined }}
					validate={[
						() => {
							if(files.length <= 0) {
								return {
									status: "error",
									message: "At least one image is required"
								};
							}

							return undefined;
						}
					]}
				>
					<input name="mediaFiles" {...sanitize(getInputProps(), ["style"])} style={{ ...getInputProps().style, border: undefined }} />
				</FormField>
				<Box fill>
					<Box align="center">
						{
							isDragActive
								? (
									<Box align="center">
										<SlimHeading level="5">
											Drop the files here ...
										</SlimHeading>
									</Box>
								)
								: (
									<Box align="center" margin="small">
										<SlimHeading level="5">
											Drag 'n' drop some files here, or click to select files
										</SlimHeading>
									</Box>
								)
						}
					</Box>
					<Box align="center" justify="center" margin={{ top: "large" }}>
						<Box>
							<Upload size="large" />
						</Box>
					</Box>
				</Box>
			</Box>
			{props.isUploading && (
				<Box direction="row" gap="small">
					<Spinner />
					<Text>
						Uploading ...
					</Text>
				</Box>
			)}
			<Box direction="row" wrap gap="small" margin="small">
				{files.map(file => (
					<Preview key={file.name} name={file.name} />
				))}
			</Box>
		</Box>
	);
};