import { Box, Button, Card, CardBody, CardHeader, Grid, Heading, Image } from "grommet";
import { SlimHeading, useAlert } from "../../common";

import placeholder from "../../../img/placeholder.jpg";
import { Chip } from "@mui/material";
import { Order } from "../../../types";
import { DTO, OrderStatus } from "@rego-app/common";
import { listCustomerOrders, loadActiveOrder, selectCustomerOrders, useAppDispatch, useAppSelector } from "../../../app/store";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { push } from "connected-react-router";
import { selectCustomer } from "../../../app/store/application";

interface OrderCardProps {
	order: DTO<Order>;
}

export const OrderCard: React.FC<OrderCardProps> = (props) => {
	const { address, completed_at, cancelled_at } = props.order;
	return (
		<Card
			margin="small"
			hoverIndicator={true}
			onClick={() => {
				console.debug("CLICKED!");
			}}
		>
			<CardHeader>
				<Box align="end" justify="center" margin="small" fill="horizontal">
					{cancelled_at
						? (
							<Chip label="Canceled" color="warning" />
						)
						: (completed_at)
							? (
								<Chip label="Completed" color="success" />
							)
							: (
								<Chip label="In Progress" color="primary" />
							)
					}
				</Box>
			</CardHeader>
			<CardBody>
				<Box align="center" justify="center" pad="small">
					<Image src={placeholder} width="90%" />
				</Box>
				<Box margin="medium">
					<SlimHeading textAlign="center" level="5">
						{[address.address_line_one, address.address_line_two].filter(v => !!v).join(", ")}
					</SlimHeading>
					<SlimHeading textAlign="center" level="5">
						{address.city}, {address.state} {address.zip}
					</SlimHeading>
				</Box>
				<Box align="center" margin="small">
					<Button
						primary
						label="View Details"
					/>
				</Box>
			</CardBody>
		</Card>
	);
};

// const orders: Order[] = [
// 	{
// 		address: {
// 			address_line_one: "1151 N 3rd St",
// 			address_line_two: "Unit 309",
// 			city: "Philadelphia",
// 			state: "PA",
// 			zip: "19123"
// 		} as DeliveryAddress
// 	} as Order,
// 	{
// 		address: {
// 			address_line_one: "10 Fern Drive",
// 			city: "Millstone Township",
// 			state: "NJ",
// 			zip: "08510"
// 		} as DeliveryAddress,
// 		completed_at: new Date()
// 	} as Order,
// 	{
// 		address: {
// 			address_line_one: "2929 Arch Street",
// 			city: "Philadelphia",
// 			state: "PA",
// 			zip: "19104"
// 		} as DeliveryAddress,
// 		cancelled_at: new Date()
// 	} as Order
// ];

interface OrderHomeState {
	isLoading: boolean;
	isLoadingOrder: boolean;
	isCreatingOrder: boolean;
}

export const OrderHome: React.FC = (props) => {
	const alert = useAlert();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const customer = useAppSelector(selectCustomer);
	const orders = useAppSelector(selectCustomerOrders);
	const [state, setState] = useState<OrderHomeState>({
		isLoading: true,
		isLoadingOrder: false,
		isCreatingOrder: false
	});

	function navigateToOrderForm(): void {
		dispatch(push("/order/start"));
	}

	function checkHasOrderInProgress(): boolean {
		return orders.some(o => {
			return o.status === OrderStatus.CREATED || o.status === OrderStatus.ORDERED;
		});
	}

	function loadCustomerOrders(): void {
		if(!customer) {
			setState({
				...state,
				isLoading: false
			});
			return;
		}

		dispatch(listCustomerOrders()).unwrap()
			.catch(err => {
				console.error("Error loading customer orders at order home", err);
				snack.enqueueSnackbar("We ran into some trouble loading your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isLoading: false
				});
			});
	}

	function startNewOrder(): void {
		if(checkHasOrderInProgress()) {
			alert.display<boolean>((closeCallback) => (
				<Box margin="small" >
					<Box margin="medium" >
						<Heading level="4" >
							You have an existing order in progress. Are you sure you want to create a new one?
						</Heading>
					</Box>
					<Box direction="row" justify="between" >
						<Button color="status-error" primary label="No" onClick={() => {
							closeCallback(false);
						}} />
						<Button color="status-ok" primary label="Yes, create a new one" onClick={() => {
							closeCallback(true);
						}} />
					</Box>
				</Box>
			)
			).then((shouldCreateNewOrder) => {
				if(shouldCreateNewOrder) {
					navigateToOrderForm();
				}
			});

			return;
		}

		navigateToOrderForm();
	}

	function loadExistingOrder(orderId: string): void {
		setState({
			...state,
			isLoadingOrder: true
		});

		dispatch(loadActiveOrder(orderId)).unwrap()
			.then((order) => {

			})
			.catch(err => {
				console.error(`Error loading customer orders [${orderId}]`, err);
				snack.enqueueSnackbar("We ran into some trouble loading your order information", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isLoadingOrder: false
				});
			});
	}

	useEffect(() => {
		loadCustomerOrders();
	}, []);

	return (
		<Box>
			<Box direction="row">
				<Heading>Order Home</Heading>
				<Button
					primary
					label="Start New Order"
					onClick={startNewOrder}
				/>
			</Box>
			<Grid columns="medium">
				{orders.map(order => (
					<OrderCard key={order.id} order={order} />
				))}
			</Grid>
		</Box>
	);
};