import { DTO, Store } from "@rego-app/common";
import { push } from "connected-react-router";
import { Anchor, Box, Button, Text, Image, Layer, Grid } from "grommet";
import { FormClose } from "grommet-icons";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectActiveStore } from "../../../app/store/order";
import { StoreHoursList } from "../../admin/components";
import { Loader, MapComponent, SlimHeading, useAlert, useScreenSize, useWindowDimensions } from "../../common";
import couch from "../../../img/couch.jpg";

export const StartDonationButton: React.FC<{ store: DTO<Store>; }> = (props) => {
	const alert = useAlert();
	const dispatch = useAppDispatch();

	function showInterruptModal() {
		alert.display<boolean>((closeCallback) => (
			<Box margin="large">
				<Box gap="medium">
					<Box>
						<SlimHeading level="4" >
							Rego works with stores like {props.store.name} to help you donate furniture. When free store pickup isn't available, we can offer flexible pickup options in as little as 24 hours. We even arrange for a donation receipt for items accepted for donation.
						</SlimHeading>
					</Box>
					<Box flex="grow" align="end" justify="center">
						<Button
							primary
							label="Continue"
							onClick={() => {
								closeCallback(true);
							}} />
					</Box>
				</Box>
			</Box>
		)
		).then((shouldAdvance) => {
			if(!shouldAdvance) {
				return;
			}

			dispatch(push("/order"));
		});

	}
	return (
		<Button
			primary
			size="large"
			margin="medium"
			label="Start Donation"
			onClick={showInterruptModal}
		/>
	);
};

export const StoreAddressModal: React.FC<{ store: DTO<Store>, onClose: () => void; }> = (props) => {
	const getStores = useCallback(() => {
		return [
			props.store,
			...(props.store.children ?? [])
		];
	}, [props.store]);
	return (
		<Layer
			onClickOutside={props.onClose}
			onEsc={props.onClose}
		>

			<Box align="end" justify="center" fill="horizontal">
				<Button
					margin="small"
					icon={<FormClose />}
					onClick={props.onClose}
					hoverIndicator
				/>
			</Box>
			<Box margin="medium" gap="small">
				{getStores().map(store => (
					<Box gap="small" key={store.id}>
						<SlimHeading level="4">{store.name}</SlimHeading>
						<Box margin="medium">
							<Text weight="bold">{store.address.address_line_one}</Text>
							{store.address.address_line_two && (
								<Text weight="bold">{store.address.address_line_two}</Text>
							)}
							<Text weight="bold">{`${store.address.city}, ${store.address.state} ${store.address.zip}`}</Text>
						</Box>
					</Box>
				))}
			</Box>
		</Layer>
	);
};

export const StoreTermsModal: React.FC<{ store: DTO<Store>, onClose: () => void; }> = (props) => {
	return (
		<Layer
			onClickOutside={props.onClose}
			onEsc={props.onClose}
		>

			<Box align="end" justify="center" fill="horizontal">
				<Button
					margin="small"
					icon={<FormClose />}
					onClick={props.onClose}
					hoverIndicator
				/>
			</Box>
			<Box margin="medium">
				{props.store.policy && (
					<Box gap="small">
						<SlimHeading level="3">Policy</SlimHeading>
						<Box margin="medium">
							<Text weight="bold">{props.store.policy}</Text>
						</Box>
					</Box>
				)}
				{props.store.terms && (
					<Box gap="small">
						<SlimHeading level="3">Terms</SlimHeading>
						<Box margin="medium">
							<Text weight="bold">{props.store.terms}</Text>
						</Box>
					</Box>
				)}
			</Box>
		</Layer>
	);
};

export const DonationReferral: React.FC = (props) => {
	const size = useScreenSize();
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();
	const activeStore = useAppSelector(selectActiveStore);
	const [isMapHidden, setIsMapHidden] = useState(false);
	const [showPolicyModal, setShowPolicyModal] = useState(false);
	const [showAddressModal, setShowAddressModal] = useState(false);

	function handleHideAddressModal(): void {
		setShowAddressModal(false);
	}

	function handleHidePolicyModal(): void {
		setShowPolicyModal(false);
	}

	function handleShowAddressModal(): void {
		setShowAddressModal(true);
	}

	function handleShowPolicyModal(): void {
		setShowPolicyModal(true);
	}

	const getStores = useCallback(() => {
		if(!activeStore) {
			return [];
		}

		return [
			activeStore,
			...(activeStore.children ?? [])
		];
	}, [activeStore]);

	useEffect(() => {
		if(!activeStore) {
			console.debug("no active store found ... pushing to home");
			dispatch(push("/"));
		}
	}, []);

	useEffect(() => {
		if(dimensions.width < 1200 && size === "medium") {
			setIsMapHidden(true);
			return;
		}

		if(size === "small") {
			setIsMapHidden(true);
			return;
		}

		setIsMapHidden(false);
	}, [dimensions]);

	return (
		<Loader visible={!activeStore}>
			{showPolicyModal && (
				<StoreTermsModal
					onClose={handleHidePolicyModal}
					store={activeStore as DTO<Store>}
				/>
			)}
			{showAddressModal && (
				<StoreAddressModal
					onClose={handleHideAddressModal}
					store={activeStore as DTO<Store>}
				/>
			)}
			{activeStore && (
				<Box direction={size === "small" ? "column" : "row-responsive"} margin="small">
					<Box gap="small" background={{ image: `url("${couch}")`, size: "cover" }} style={{ minHeight: 300 }}>
						<Box flex="grow" align="center" justify="center" gap="medium " margin="large">
							<Box>
								<SlimHeading
									color="white"
									level="3"
								>{activeStore.description}
								</SlimHeading>
							</Box>
							<Box margin="medium">
								<StartDonationButton
									store={activeStore}
								/>
							</Box>
						</Box>
						<Box>
							<Box background={{ color: "brand", opacity: "strong" }}>
								<Box direction="row" justify="between" align="center">
									<Box margin="small">
										{(activeStore.terms || activeStore.policy) && (
											<Anchor
												color="white"
												label="Terms & Conditions"
												onClick={handleShowPolicyModal}
											/>
										)}
									</Box>
									<Box margin="small">
										<Anchor
											color="white"
											label="Store Address"
											onClick={handleShowAddressModal}
										/>
									</Box>
								</Box>

								<Box align="end"
									justify="between"
									direction="row"
									background={{ color: "brand", opacity: "strong" }}
								>

								</Box>
							</Box>
						</Box>
					</Box>
					<Box flex="grow" margin="small" gap="small">
						<Box align="center" justify="center">
							<Image
								src={activeStore.logo_url}
								width={300}
							/>
						</Box>
						<Box direction={size === "small" ? "column" : "row-responsive"} gap="small" margin="small">
							<Box gap="small" flex={isMapHidden ? "grow" : undefined}>
								<SlimHeading textAlign="center" level="3">Donation Hours</SlimHeading>
								<Grid columns={{ size: "small", count: "fit" }} gap="medium">
									{getStores().map(store => (
										<Box key={store.id} gap="small">
											<SlimHeading level="5">{store.name}</SlimHeading>
											<StoreHoursList hours={store.hours} />
										</Box>
									))}
								</Grid>
							</Box>
							{!isMapHidden && (
								<Box
									flex="grow"
									align="center"
									justify="center"
									style={{ minHeight: size === "small" ? 300 : undefined }}
								>
									<MapComponent
										ignoreMaxHeight
										markers={getStores().map(store => {
											return {
												latitude: Number(store.address.latitude),
												longitude: Number(store.address.longitude)
											};
										})}
										allowFreeZoom
										requestCurrentLocation={false}
									/>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			)}
		</Loader>
	);
};