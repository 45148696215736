import { Anchor, Avatar, Box, Heading, Text } from "grommet";
import React, { useState } from "react";

export const HowItWorks: React.FC = (props) => {

	const [itemSold, setItemSold] = useState<boolean | undefined>(undefined);
	const [itemDonated, setItemDonated] = useState<boolean | undefined>(undefined);

	return (
		<Box direction="column" gap="large" margin="medium">
			<Box gap="medium" direction="column">
				<Box direction="row" gap="medium">
					<Avatar border={{ size: "small", color: "brand" }}>
						<Text weight="bolder">1</Text>
					</Avatar>
					<Heading margin="none" level="4">
						Use our fancy tool to scan in any furniture items you want to sell, donate, or just need out (more on that later)
					</Heading>
				</Box>
				<Box align="center" gap="medium">
					<Anchor
						style={{textAlign: "center"}}
						onClick={() => {
							setItemSold(true);
							setItemDonated(undefined);
						}}
						label="So what happens when an item sells?"
					/>

					{itemSold === true && (
						<Box direction="row" gap="medium">
							<Heading margin={{vertical: "none", horizontal: "large"}} level="4">
								Congrats! You're getting paid. Rego will handle the communication, pickup, and delivery - so you don't have to.
							</Heading>
						</Box>
					)}

					<Anchor
						style={{textAlign: "center"}}
						onClick={() => {
							setItemSold(false);
						}}
						label="And what about when it doesn't sell?"
					/>
				</Box>
			</Box>
			{itemSold === false && (
				<Box direction="column" gap="medium">
					<Box direction="row" gap="medium">
						<Avatar border={{ size: "small", color: "brand" }}>
							<Text weight="bolder">2</Text>
						</Avatar>
						<Heading margin="none" level="4">
							We'll check our network for any charitable organizations accepting that particular item (remember, not everything is accepted)
						</Heading>
					</Box>
					<Box direction="column" align="center" gap="medium">
						<Anchor
							style={{textAlign: "center"}}
							onClick={() => {
								setItemDonated(true);
							}}
							label="Great, an organization accepted my item. Now what?"
						/>

						{itemDonated === true && (
							<Box direction="row" gap="medium">
								<Heading margin={{vertical: "none", horizontal: "large"}} level="4">
									Rego handles the scheduling and pickup of any accepted donation items. We'll first check for any free pickup options offered by the charitable organization. If none are available we can offer paid options that work with your schedule. Once the pickup is completed, we'll send you a copy of your donation receipt to keep for your records.
								</Heading>
							</Box>
						)}

						<Anchor
							style={{textAlign: "center"}}
							onClick={() => {
								setItemDonated(false);
							}}
							label="But what if they don't?"
						/>
					</Box>
				</Box>
			)}
			{itemDonated === false && (
				<Box direction="row" gap="medium">
					<Avatar border={{ size: "small", color: "brand" }}>
						<Text weight="bolder">3</Text>
					</Avatar>
					<Heading margin="none" level="4">
						We'll connect you with responsible disposal and recycling services. Rego handles all payment, scheduling, and communication with our partners to streamline the process.
					</Heading>
				</Box>
			)}
		</Box>
	);
};