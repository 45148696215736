import { push } from "connected-react-router";
import { Anchor, Box, Grid } from "grommet";
import React from "react";
import { Outlet } from "react-router-dom";
import { selectCustomerActionItems, useAppDispatch, useAppSelector } from "../../../app/store";
import { useScreenSize } from "../../common";
import { BottomNavigation, DashboardSidebar } from "../components";
import { DashboardProvider } from "../provider";

export const DashboardController: React.FC = (props) => {
	const size = useScreenSize();
	const dispatch = useAppDispatch();
	const actionItems = useAppSelector(selectCustomerActionItems);

	return (
		<Box flex>
			{size === "small" && (
				<Box align="center" margin="large">
					<Anchor
						color="brand"
						label={`Action Items (${actionItems.length})`}
						onClick={() => {
							dispatch(push("/dashboard/actions"));
						}}
					/>
				</Box>
			)}
			<Box>
				<Grid columns={size === "small" ? "100%" : ["small", "flex"]} id="dashboard-grid">
					<DashboardSidebar />
					<DashboardProvider>
						<Outlet />
					</DashboardProvider>
				</Grid>
			</Box>
			{size === "small" && (
				<Box flex="grow" justify="end">
					<BottomNavigation />
				</Box>
			)}
		</Box >
	);
};