import { useEffect, useState } from "react";
import { Address, Geolocation, States } from "../../../types";
import { useGoogle } from "../provider";

let autocomplete: google.maps.places.Autocomplete;

export const useAddressAutocomplete = () => {
	const googleHook = useGoogle();

	const [ready, setReady] = useState(false);

	useEffect(() => {
		if(googleHook.isLoaded) {
			setReady(true);
		}
	}, [googleHook]);

	function parseAddress(callback: (address: Address & Geolocation) => void): void {
		const place = autocomplete.getPlace();

		const address = {
			latitude: String(place.geometry?.location?.lat() ?? ""),
			longitude: String(place.geometry?.location?.lng() ?? "")
		} as Address & Geolocation;

		for(const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
			const componentType = component.types[0];

			switch(componentType) {
				case "street_number": {
					address.address_line_one = component.long_name;
					break;
				}

				case "route": {
					address.address_line_one += ` ${component.long_name}`;
					break;
				}

				case "postal_code": {
					address.zip = component.long_name;
					break;
				}

				case "locality":
					address.city = component.long_name;
					break;

				case "administrative_area_level_1": {
					address.state = component.short_name as States.Abbreviation;
					break;
				}
			}
		}

		callback(address);
	}

	function initialize(inputElement: HTMLInputElement, callback: (address: Address & Geolocation) => void): void {
		autocomplete = new google.maps.places.Autocomplete(inputElement, {
			componentRestrictions: {
				country: ["us"]
			},
			bounds: googleHook.currentLocation ? new google.maps.LatLngBounds(googleHook.currentLocation) : undefined,
			fields: ["address_components", "geometry"],
			types: ["address"]
		});
		autocomplete.addListener("place_changed", () => {
			parseAddress(callback);
		});
	}

	return {
		ready,
		initialize,
		autocomplete
	};
};