import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Tab, Tabs } from "grommet";
import { ListingCard, ListingDetails, ServiceCardContainer } from "../components";
import { Loader, PaginatedItem, useWindowDimensions } from "../../common";
import { listCustomerProducts, selectCustomerActionItems, selectCustomerProducts, useAppDispatch, useAppSelector } from "../../../app/store";
import { useSnackbar } from "notistack";
import { CustomerProduct, DTO, Product, ProductIntent } from "@rego-app/common";

export enum ActiveScreen {
	PURCHASED,
	FOR_SALE,
	UNDER_REVIEW
}

export const DashboardListings: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();

	const actionItems = useAppSelector(selectCustomerActionItems);
	const products = useAppSelector(selectCustomerProducts);

	const [isLoaded, setIsLoaded] = useState(false);
	const [activeScreen, setActiveScreen] = useState<ActiveScreen>(ActiveScreen.PURCHASED);
	const [selectedProduct, setSelectedProduct] = useState<DTO<Product> | null>(null);
	const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);

	function handleViewListing(product: DTO<Product>): void {
		setSelectedProduct(product);
		setDetailsModalOpen(true);
	}

	const approvedProducts = useMemo(() => {
		return products.filter(p => p.submitted && p.intent === ProductIntent.SELL && p.approved && !p.disposition);
	}, [products]);

	const purchasedProducts = useMemo(() => {
		return products.filter(p => p.submitted && p.disposition === ProductIntent.SELL);
	}, [products]);

	const productsUnderReview = useMemo(() => {
		return products.filter(p => p.submitted && p.intent === ProductIntent.SELL && !p.reviewed);
	}, [products]);

	const columns = useMemo(() => {
		switch(dimensions.size) {
			case "small": {
				return 1;
			}
			case "medium": {
				return 1;
			}
			case "large": {
				return 2;
			}
			case "xlarge": {
				return 3;
			}
		}
	}, [dimensions.size]);

	const renderItems = useCallback((items: DTO<CustomerProduct>[]) => {
		return (
			<Grid columns={{ count: columns, size: "auto" }}>
				{items.map(product => (
					<ListingCard
						key={product.id}
						onClickOpen={handleViewListing}
						product={product}
					/>
				))}
			</Grid>
		);
	}, [activeScreen, products, selectedProduct, columns, dimensions.size]);

	useEffect(() => {
		dispatch(listCustomerProducts({ expand: ["deliveries"] })).unwrap()
			.catch(err => {
				console.error("Failed to load customer products", err);
				snack.enqueueSnackbar("We ran into an issue loading your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}, [actionItems]);

	function handleSwitchTab(index: number): void {
		setActiveScreen(index);
	}

	return (
		<Loader visible={!isLoaded}>
			{isDetailsModalOpen && selectedProduct && (
				<ListingDetails
					product={selectedProduct}
					onClose={() => {
						setDetailsModalOpen(false);
					}}
				/>
			)}
			<Box margin="small">
				<Tabs
					justify="start"
					margin="medium"
					onActive={handleSwitchTab}
					activeIndex={activeScreen}
				>
					<Tab
						title="For Sale"
					>
						<ServiceCardContainer
							title="Currently for Sale"
							subtitle="These items are currently for sale on the Rego marketplace"
							noItemsMessage="nothing for sale right now"
							largeScreenPageSize={9}
							mediumScreenPageSize={6}
							smallScreenPageSize={4}
							isViewingAll={true}
							items={approvedProducts}
							renderItems={renderItems}
						/>
					</Tab>
					<Tab
						title="Purchased"
					>
						<ServiceCardContainer
							title="Purchased"
							subtitle="These items have already been purchased"
							noItemsMessage="nothing purchased yet"
							largeScreenPageSize={9}
							mediumScreenPageSize={6}
							smallScreenPageSize={4}
							isViewingAll={true}
							items={purchasedProducts}
							renderItems={renderItems}
						/>
					</Tab>
					<Tab
						title="Under Review"
					>
						<ServiceCardContainer
							title="Under Review"
							subtitle="These items are still under review by the Rego team"
							noItemsMessage="nothing under review right now"
							largeScreenPageSize={9}
							mediumScreenPageSize={6}
							smallScreenPageSize={4}
							isViewingAll={true}
							items={productsUnderReview}
							renderItems={renderItems}
						/>
					</Tab>
				</Tabs>
			</Box>
		</Loader>
	);

};