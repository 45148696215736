import { DeliveryType, DTO, Purchase, PurchaseIntent } from "@rego-app/common";
import { Box, Button, Card, CardBody, CardHeader, Text } from "grommet";
import moment from "moment";
import { useMemo, useState } from "react";
import config from "../../../app/config";
import { formatCurrency, parseDateFromUTC, prettyPrintHour } from "../../../helpers";
import { SlimHeading, useTimezone } from "../../common";

export const PendingPickupContent: React.FC<{ intents: DTO<PurchaseIntent>[], purchase: DTO<Purchase>; }> = (props) => {
	return (
		<Box gap="small">

		</Box>
	);
};

export const PendingDeliveryContent: React.FC<{ intents: DTO<PurchaseIntent>[], purchase: DTO<Purchase>; }> = (props) => {
	return (
		<Box gap="small">

		</Box>
	);
};

export const PurchaseCardContent: React.FC<PurchaseCardProps> = (props) => {
	const [isLoading, setIsLoading] = useState(false);

	const pendingPickups = useMemo(() => {
		return (props.purchase.intents ?? []).filter(i => !i.delivered && i.delivery_type === DeliveryType.PICKUP);
	}, [props.purchase]);

	const isPickupOnly = useMemo(() => {
		const intents = props.purchase.intents ?? [];
		return intents.every(i => i.delivery_type === DeliveryType.PICKUP);
	}, [props.purchase]);

	const isPickupPending = useMemo(() => {
		const intents = props.purchase.intents ?? [];
		return intents.some(i => i.delivery_type === DeliveryType.PICKUP && !i.delivered);
	}, [props.purchase]);

	const pendingDeliveries = useMemo(() => {
		return (props.purchase.intents ?? []).filter(i => !i.delivered && i.delivery_type === DeliveryType.DELIVERY);
	}, [props.purchase]);

	const isDeliveryOnly = useMemo(() => {
		const intents = props.purchase.intents ?? [];
		return intents.every(i => i.delivery_type === DeliveryType.DELIVERY);
	}, [props.purchase]);

	const isDeliveryPending = useMemo(() => {
		const intents = props.purchase.intents ?? [];
		return intents.some(i => i.delivery_type === DeliveryType.DELIVERY && !i.delivered);
	}, [props.purchase]);

	if(!isPickupPending && !isDeliveryPending) {
		return (
			<Box>
				<Text>
					Your purchase is complete!
				</Text>
			</Box>
		);
	}

	return (
		<Box gap="small">
			{isPickupPending && (
				<Box gap="small">
					<SlimHeading level="4">Pickup Details</SlimHeading>
					<Text>
						Your item(s) are ready for pickup. Please refer to your email confirmation for specific pickup instructions.
					</Text>
				</Box>
			)}
			{isDeliveryPending && (
				<Box gap="small">
					<SlimHeading level="4">Delivery Details</SlimHeading>
					{!props.purchase.delivery_window
						? (
							<Box gap="small">
								<Text>
									We don't have a delivery date scheduled for your purchase yet. Please get in touch with us to confirm a delivery time.
								</Text>
								<Box align="center">
									<Button
										label="Call Us"
										href={`tel:${config.contact.phone}`}
									/>
								</Box>
							</Box>
						)
						: (
							<Box>
								<Text>
									Your order is currently scheduled for delivery on&nbsp;
									<Text weight="bold">
										{moment.tz(props.purchase.delivery_window.date, props.purchase.delivery_window.timezone).format("MM/DD/YYYY")}&nbsp;
									</Text>
									between <Text weight="bold">{prettyPrintHour(String(props.purchase.delivery_window.from))} and {prettyPrintHour(String(props.purchase.delivery_window.to))}</Text>
								</Text>
							</Box>
						)}
				</Box>
			)}
		</Box>
	);
};

interface PurchaseCardProps {
	purchase: DTO<Purchase>;
}

export const PurchaseCard: React.FC<PurchaseCardProps> = (props) => {
	const timezone = useTimezone();

	return (
		<Box flex>
			<Card margin="small" fill="vertical">
				<CardHeader background="light grey">
					<Box direction="row" justify="evenly" margin="small" flex>
						<Box>
							<Text>Order Placed</Text>
							<Text weight="bold" textAlign="center">{parseDateFromUTC(props.purchase.paid_at, timezone)}</Text>
						</Box>
						<Box>
							<Text>Purchase Total</Text>
							<Text weight="bold" textAlign="center">{formatCurrency(props.purchase?.payment?.amount ?? 0)}</Text>
						</Box>
					</Box>
				</CardHeader>
				<CardBody margin="medium" gap="medium">
					<PurchaseCardContent
						purchase={props.purchase}
					/>
				</CardBody>
			</Card >
		</Box >
	);
};