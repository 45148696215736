import { DTO, Product, QueryProductsRequest } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, Spinner, Text } from "grommet";
import { Cart } from "grommet-icons";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ProductService, ReferenceService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCurrentMarket } from "../../../app/store/purchase";
import { fetchItems, getOrFetchMarket } from "../../../app/store/reference";
import { useWindowDimensions, Loader, SlimHeading } from "../../common";
import { ProductGallery } from "../components";
import { isMobile, browserName } from "react-device-detect";
import { DesktopShopPage, MobileShopPage } from "../pages";
import { selectProductFilters } from "../../../app/store/shop";


interface ShopInitState {
	isLoading: boolean;
	wasLoaded: boolean;
	isLoadingMarket: boolean;
	wasMarketLoaded: boolean;
	isLoadingProducts: boolean;
	isLoadingReference: boolean;
	wasProductsLoaded: boolean;
	wasReferenceLoaded: boolean;
}

export const ShopController: React.FC = (props) => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const currentMarket = useAppSelector(selectCurrentMarket);
	const dimensions = useWindowDimensions();

	const [ state, setState ] = useState<ShopInitState>({
		isLoading: false,
		wasLoaded: false,
		isLoadingReference: false,
		isLoadingMarket: false,
		wasMarketLoaded: false,
		isLoadingProducts: false,
		wasProductsLoaded: false,
		wasReferenceLoaded: false
	});

	function wrapStateUpdate(changes: Partial<ShopInitState>): void {
		setState((state) => {
			return ({
				...state,
				...changes
			});
		});
	}

	useEffect(() => {
		wrapStateUpdate({ isLoadingReference: true });
		dispatch(fetchItems()).unwrap()
			.catch(err => {
				console.error("Failed to load items", err);
			})
			.finally(() => {
				wrapStateUpdate({ isLoadingReference: false, wasReferenceLoaded: true });
			});
	}, []);

	useEffect(() => {
		const isLoading = state.isLoadingMarket || state.isLoadingProducts || state.isLoadingReference;
		if(isLoading !== state.isLoading) {
			setState({
				...state,
				isLoading
			});
		}
	}, [ state.isLoadingMarket, state.isLoadingProducts ]);

	function fetchMarket(code: string): void {
		setState({
			...state,
			isLoadingMarket: true
		});
		dispatch(getOrFetchMarket(code)).unwrap()
			.catch(err => {
				console.error("Failed to load markets", err);
			})
			.finally(() => {
				wrapStateUpdate({
					isLoadingMarket: false,
					wasMarketLoaded: true
				});
			});
	}

	useEffect(() => {
		if(!params.code) {
			throw new Error("No market code found in state");
		}

		if(params.code !== currentMarket?.code) {
			fetchMarket(params.code);
			return;
		}

		wrapStateUpdate({ wasMarketLoaded: true });

	}, [ params, currentMarket ]);

	const filters = useAppSelector(selectProductFilters);
	const [ products, setProducts ] = useState<DTO<Product>[]>([]);
	const [ limit, setLimit ] = useState(20);
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ step, setStep ] = useState(4);
	const [ totalPages, setTotalPages ] = useState(0);

	const queryProductsRequest = useMemo(() => {
		return {
			page: currentPage,
			limit,
			market: currentMarket?.id ?? "",
			filters: {
				...filters
			}
		} as QueryProductsRequest;
	}, [ limit, currentPage, currentMarket ]);

	useEffect(() => {
		setProducts([]);
		setCurrentPage(1);
		fetchProducts([]);
	}, [ currentMarket ]);

	function fetchProducts(oldProducts: DTO<Product>[]): void {
		if(!currentMarket) {
			console.debug("No market found in state");
			return;
		}

		wrapStateUpdate({
			isLoadingProducts: true
		});
		ProductService.queryProducts(queryProductsRequest)
			.then(result => {
				if(result.pagination) {
					setTotalPages(result.pagination.page.total);
				}
				setProducts([ ...oldProducts.concat([ ...result.result ]) ]);
			})
			.catch(err => {
				console.error("Failed listing products", err);
			})
			.finally(() => {
				wrapStateUpdate({
					isLoadingProducts: false,
					wasProductsLoaded: true
				});
			});
	}

	useEffect(() => {
		if(state.wasMarketLoaded && state.wasProductsLoaded && !state.wasLoaded) {
			wrapStateUpdate({
				wasLoaded: true
			});
		}
	}, [ state.wasMarketLoaded, state.wasProductsLoaded ]);

	useEffect(() => {
		fetchProducts(products);
	}, [ currentPage ]);

	useEffect(() => {
		if(dimensions.width >= 1200) {
			setStep(3);
			return;
		}

		if(dimensions.width >= 900) {
			setStep(3);
			return;
		}

		if(dimensions.width >= 600) {
			setStep(2);
			return;
		}

		setStep(1);
	}, [ dimensions.width ]);

	function fetchNextProducts(): void {
		if(currentPage >= totalPages) {
			return;
		}

		setCurrentPage(currentPage + 1);
	}

	const hasNextPage = useMemo(() => {
		return currentPage < totalPages;
	}, [ currentPage, totalPages ]);

	return (
		<Loader visible={!state.wasLoaded}>
			<DesktopShopPage
				products={products}
				productStep={step}
				hasNextPage={hasNextPage}
				isLoadingProducts={state.isLoadingProducts}
				fetchNextProducts={fetchNextProducts}
			/>
		</Loader >
	);
};