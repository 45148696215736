import { CustomerProduct, DTO, StoreProduct } from "@rego-app/common";
import { LayerProps } from "grommet";

export enum OrderScreen {
	"PRODUCT" = "PRODUCT",
	"LISTING" = "LISTING",
	"DONATION" = "DONATION",
	"JUNK" = "JUNK",
	"CONFIRMATION" = "CONFIRMATION"
}

export enum ProductUploadType {
	AUTOMATIC = "AUTOMATIC",
	MANUAL = "MANUAL"
}

export enum DashboardScreen {
	"HOME" = "HOME",
	"LISTINGS" = "LISTINGS",
	"DONATIONS" = "DONATION",
	"REMOVALS" = "REMOVALS",
	"PURCHASES" = "PURCHASES"
}

export interface Modal extends LayerProps {
	id: string;
	content: JSX.Element;
	isVisible: boolean;
}

export type ScreenSize =
	| "small"
	| "medium"
	| "large";

export interface RecentRecord {
	id: string;
	timestamp: number;
}

export interface CloseableModal {
	onClose(): void;
}

export * from "@rego-app/common";

export type UnionProduct = DTO<CustomerProduct> & DTO<StoreProduct>;