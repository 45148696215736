import { ContactType } from "@rego-app/common";
import { Anchor, FormField, FormFieldProps, MaskedInput } from "grommet";
import { Mail, Phone } from "grommet-icons";
import { UtilService } from "../../../app/services";

interface UsernameFieldProps extends FormFieldProps {
	username: string;
	type: ContactType;
	disabled?: boolean;
	toggleType: () => void;
	phoneFieldLabel: string;
	emailFieldLabel: string;
	disableToggle?: boolean;
}

export const UsernameField: React.FC<UsernameFieldProps> = (props) => {
	return (
		<FormField
			name="username"
			htmlFor="username"
			label={
				props.disableToggle
					? undefined
					: props.type === ContactType.EMAIL
						? <Anchor disabled={props.disabled} onClick={props.disabled ? undefined : props.toggleType}>{props.emailFieldLabel}</Anchor>
						: <Anchor disabled={props.disabled} onClick={props.disabled ? undefined : props.toggleType}>{props.phoneFieldLabel}</Anchor>
			}
			readOnly={props.disabled}
			validate={[
				(value: unknown) => {
					if(!value) {
						return {
							status: "error",
							message: "This field is required"
						};
					}

					if(props.type === ContactType.PHONE) {
						try {
							UtilService.formatPhoneToNumber(props.username);
						}
						catch(e) {
							return {
								status: "error",
								message: "Please enter a valid phone number"
							};
						}
					}
				}
			]}
		>
			{props.type === ContactType.EMAIL
				? (<MaskedInput
					id="username"
					name="username"
					inputMode={"email"}
					type="email"
					mask={[
						{
							regexp: /^[\w\-_.]+$/,
							placeholder: 'example',
						},
						{ fixed: '@' },
						{
							regexp: /^[\w]+$/,
							placeholder: 'rego-app',
						},
						{
							fixed: '.'
						},
						{
							regexp: /^[\w]+$/,
							placeholder: 'com',
						},
					]}
					icon={<Mail />}
				/>)
				: (<MaskedInput
					id="username"
					name="username"
					inputMode={"tel"}
					mask={[
						{ fixed: '1 (' },
						{
							length: 3,
							regexp: /^[0-9]{1,3}$/,
							placeholder: 'xxx',
						},
						{ fixed: ')' },
						{ fixed: ' ' },
						{
							length: 3,
							regexp: /^[0-9]{1,3}$/,
							placeholder: 'xxx',
						},
						{ fixed: '-' },
						{
							length: 4,
							regexp: /^[0-9]{1,4}$/,
							placeholder: 'xxxx',
						},
					]}
					icon={<Phone />}
				/>)
			}
		</FormField>
	);
};