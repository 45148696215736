import { DTO, Store } from "@rego-app/common";
import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PartnerService } from "../../../app/services";
import { useAppDispatch } from "../../../app/store";
import { Loader } from "../../common";

export const SlugRedirectController: React.FC = (props) => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const [store, setStore] = useState<DTO<Store> | null>(null);

	function handleFetchStore(slug: string): void {
		PartnerService.getStore(slug).then(res => {
			setStore({ ...res });
		}).catch(err => {
			console.error(`failed to find store with slug [${slug}]`, err);
			dispatch(push("/shop"));
		});
	}

	useEffect(() => {
		if(!params.slug) {
			dispatch(push("/shop"));
			return;
		}

		handleFetchStore(params.slug);
	}, [params]);

	useEffect(() => {
		if(store) {
			dispatch(push(`/shop/store/${store.slug}`));
			//dispatch(push(`/shop/store/${store.name}`));
		}
	}, [store]);

	return (
		<Loader visible>

		</Loader>
	);
};