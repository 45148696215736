import { push } from "connected-react-router";
import { Anchor, Box, Button } from "grommet";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchPayment, setPayment } from "../../../app/store/checkout";
import { fetchPurchase, selectPayment, selectPurchase, setIsPurchaseInProgress, setPurchase } from "../../../app/store/purchase";
import { formatCurrency } from "../../../helpers";
import { Loader, SlimHeading } from "../../common";


export const CheckoutConfirmationPage: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const payment = useAppSelector(selectPayment);
	const purchase = useAppSelector(selectPurchase);

	const [wasLoaded, setWasLoaded] = useState(false);

	function refresh(): void {
		if(!payment || !purchase) {
			return;
		}

		Promise.all([
			dispatch(fetchPurchase({ purchaseId: purchase.id, expand: ["products"] })).unwrap(),
			dispatch(fetchPayment({ paymentId: payment.id })).unwrap()
		])
			.catch(err => {
				console.error("failed to fetch payment or purchase", err);
				snack.enqueueSnackbar("We ran into an issue loading your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setWasLoaded(true);
			});
	}

	useEffect(() => {
		refresh();
	}, []);

	function resetPurchaseState(): void {
		dispatch(setPayment(null));
		dispatch(setPurchase(null));
		dispatch(setIsPurchaseInProgress(false));
	}

	function handleContinueShopping(): void {
		resetPurchaseState();
		dispatch(push("/shop"));
	}

	function handleContinueToDashboard(): void {
		resetPurchaseState();
		dispatch(push("/dashboard/purchases"));
	}

	useEffect(() => {
		if(!payment || !purchase) {
			dispatch(push("/shop"));
		}
	}, []);

	return (
		<Loader visible={!wasLoaded}>
			{payment && (
				<Box margin="medium" align="center">
					<Box gap="medium">
						<Box gap="small">
							<SlimHeading level="4">
								Your payment method was successfully charged for {formatCurrency(payment.amount)}. Your payment reference number is {payment.number}.
							</SlimHeading>
							<SlimHeading level="4">
								We're quickly working to get your delivery window approved with the seller. We'll let you know once the delivery date is confirmed and your item(s) are on the way!
							</SlimHeading>
						</Box>
						<Box gap="small">
							<Anchor
								label="Continue Shopping"
								onClick={handleContinueShopping}
							/>
							<Anchor
								label="Continue to Dashboard"
								onClick={handleContinueToDashboard}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</Loader>
	);
};