import { loadStripe, Stripe } from "@stripe/stripe-js";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/store";
import { selectConfig } from "../../../app/store/application";
import { Loader } from "../../common";

interface IStripeContext {
	publicKey: string;
	stripePromise: Promise<Stripe | null> | null;
}

export const StripeContext = createContext<IStripeContext>({
	publicKey: "",
	stripePromise: null
});

export const StripeProvider: React.FC = (props) => {
	const [publicKey, setPublicKey] = useState("");
	const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
	const config = useAppSelector(selectConfig);

	useEffect(() => {
		if(config?.applications.stripe?.publicKey) {
			setPublicKey(config.applications.stripe.publicKey);
		}
	}, [config]);

	useEffect(() => {
		if(publicKey) {
			setStripePromise(loadStripe(publicKey));
		}
	}, [publicKey]);

	return (
		<Loader visible={!publicKey || !stripePromise}>
			<StripeContext.Provider value={{ publicKey, stripePromise }}>
				{props.children}
			</StripeContext.Provider>
		</Loader>
	);
};


export const useStripeProvider = () => {
	return useContext(StripeContext);
};