import { LinearProgress } from "@material-ui/core";
import { DTO, Product, ProductMedia, ProductMediaContext, Purchase, PurchaseIntent, Store, StoreProduct } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, Grid, Text } from "grommet";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { buildStoreProduct, PartnerService, ProductService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchStore, selectActiveStore, selectActiveStoreProduct, setActiveStoreProduct, StoreProductState } from "../../../app/store/partners";
import { fetchBrands } from "../../../app/store/reference";
import { fileToMedia } from "../../../helpers";
import { Loader, LoadingButton, useWindowDimensions, ElevatedCard } from "../../common";
import { StoreProductForm, CreateStoreProductPreview, StoreProducts, StoreDetails, StoreProductFormContainer, StoreDonationProducts, ElevatedCardHeader, StoreUsers, StorePurchases, StorePurchaseGraph, PurchaseDetails, PurchaseDetailsCard, PurchasedItemsContainer } from "../components";
import { navigateToStoreDashboard } from "../helpers";

export const StoreDashboard: React.FC = (props) => {
	const params = useParams();
	const snack = useSnackbar();
	const store = useAppSelector(selectActiveStore);
	const dimensions = useWindowDimensions();
	const [ columns, setColumns ] = useState(1);
	const dispatch = useAppDispatch();
	const [ isLoadingPurchases, setIsLoadingPurchases ] = useState(false);
	const [ purchases, setPurchases ] = useState<DTO<Purchase>[]>([]);

	useEffect(() => {
		switch(dimensions.size) {
			case "small":
			case "medium":
			case "large": {
				setColumns(1);
				break;
			}
			case "xlarge": {
				setColumns(2);
				break;
			}
		}
	}, [ dimensions.size ]);

	useEffect(() => {
		if(!params.storeId) {
			dispatch(push("/"));
			return;
		}

		handleFetchStore(params.storeId);

	}, [ params ]);

	useEffect(() => {
		if(store) {
			fetchStorePurchases(store.id);
		}
	}, [ store ]);

	function handleFetchStore(storeId: string): void {
		console.debug("fetching store", storeId);
		dispatch(fetchStore(storeId)).unwrap()
			.catch(err => {
				console.error("Failed to load store", err);
				snack.enqueueSnackbar("We ran into an issue loading your information");
			});
	}

	function fetchStorePurchases(storeId: string): void {
		if(!store) {
			return;
		}

		console.debug(`fetching store purchases`, storeId);
		setIsLoadingPurchases(true);
		PartnerService.listStorePurchases(storeId, {}, [ "intents" ]).then(res => {
			setPurchases([ ...res ]);
		}).catch(err => {
			console.error("Failed to load store purchases", err);
			snack.enqueueSnackbar("We ran into an issue loading your purchase history", { variant: "error" });
		}).finally(() => {
			setIsLoadingPurchases(false);
		});
	}

	return (
		<Loader visible={!store}>
			{store && (
				<Box margin="medium">
					<Grid columns={{ count: columns, size: "auto" }} gap="medium">
						<ElevatedCard
							header={
								<ElevatedCardHeader
									title="Store Details"
								/>}
						>
							<StoreDetails
								store={store}
							/>
						</ElevatedCard>
						{store.is_seller && (
							<ElevatedCard
								header={
									<ElevatedCardHeader
										title="Products"
										action={(
											<Button
												primary
												label="New Product"
												onClick={(() => {
													dispatch(push(`/partner/store/${store.id}/products/create`));
												})}
											/>
										)}
									/>}
							>
								<StoreProducts
									store={store}
								/>
							</ElevatedCard>
						)}
						{store.is_seller && (
							<ElevatedCard
								header={
									<ElevatedCardHeader title="Store Purchases" />
								}
							>
								<StorePurchases
									store={store}
									isLoading={isLoadingPurchases}
									purchases={purchases}
								/>
							</ElevatedCard>
						)}
						{store.is_nonprofit && (
							<ElevatedCard
								header={
									<ElevatedCardHeader
										title="Donation Queue"
									/>}
							>
								<StoreDonationProducts
									store={store}
								/>
							</ElevatedCard>
						)}
						<ElevatedCard
							header={
								<ElevatedCardHeader
									title="Store Users"
									action={(
										<Button
											primary
											label="Add User"
										/>
									)}
								/>}
						>
							<StoreUsers
								store={store}
							/>
						</ElevatedCard>
					</Grid>
				</Box>
			)}
		</Loader>
	);
};

interface StoreProductFormState {
	isUpdating: boolean,
	isSubmitting: boolean,
	isUploadingMedia: boolean;
}

export const StoreProductsPage: React.FC = (props) => {
	return (
		<Box>
			STORE PRODUCTS!
		</Box>
	);
};

export const CreateStoreProductPage: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();
	const snack = useSnackbar();

	const store = useAppSelector(selectActiveStore);
	const product = useAppSelector(selectActiveStoreProduct);

	useEffect(() => {
		if(!product) {
			dispatch(setActiveStoreProduct({
				...buildStoreProduct({})
			}));
		}
	}, [ product ]);

	const columns = useMemo(() => {
		const size = dimensions.size;

		if(size === "small") return "100%";
		if(size === "medium") return [ "1/2", "1/2" ];
		return [ "2/3", "1/3" ];
	}, [ dimensions.size ]);

	const [ state, setState ] = useState<StoreProductFormState>({
		isUpdating: false,
		isSubmitting: false,
		isUploadingMedia: false
	});

	useEffect(() => {
		dispatch(fetchBrands());
	}, []);

	function updateProductState(changes: Partial<StoreProductState>): void {
		if(!product) return;
		dispatch(setActiveStoreProduct({
			...product,
			...changes
		}));
	}

	function updateState(changes: Partial<StoreProductFormState>): void {
		setState((old) => {
			return {
				...old,
				...changes
			};
		});
	}

	function handleUploadMedia(files: File[]): Promise<void> {
		updateState({ isUploadingMedia: true });
		return Promise.all(files.map(file => fileToMedia(file, ProductMediaContext.OTHER)))
			.then(media => {
				const merged = Array.from(product?.media ?? []);
				for(const m of media) {
					if(!merged.find(existing => existing.content_hash === m.content_hash)) {
						merged.push(m as ProductMedia);
					}
				}

				updateProductState({ media: [ ...merged ] });
			})
			.catch(err => {
				console.error("Failed during file upload", err);
				snack.enqueueSnackbar("We ran into an issue uploading", { variant: "error" });
			})
			.finally(() => {
				updateState({ isUploadingMedia: false });
			});
	}

	function handleDeleteMedia(media: DTO<ProductMedia>): Promise<boolean> {
		updateState({ isUploadingMedia: true });

		return new Promise<boolean>((resolve, reject) => {
			const existing = product?.media ?? [];
			if(existing.find(m => m.content_hash === media.content_hash)) {
				const index = existing.findIndex(m => m.content_hash === media.content_hash);
				existing.splice(index, 1);
				updateProductState({ media: [ ...existing ] });
				return true;
			}

			return false;
		}).finally(() => {
			updateState({ isUploadingMedia: false });
		});
	}

	function handleCreateProduct(): Promise<void> {
		if(!store || !product) {
			return Promise.resolve();
		}

		updateState({ isSubmitting: true });
		return PartnerService.createStoreProduct(store.id, product.quantity ?? 1, {
			title: product.title,
			description: product.description,
			condition: product.condition,
			price: product.price,
			price_compare: product.price_compare,
			brand: product.brand,
			brand_input: product.brand_input,
			delivery_types: product.delivery_types,
			item: product.item,
			item_input: product.item_input,
			weight: { unit: "pounds", value: 0 },
			dimensions: product.dimensions ?? { unit: "inches", width: 0, depth: 0, height: 0 }
		}).then(res => {
			updateProductState({ ...res });
			return Promise.all((product.media ?? []).map(media => {
				return ProductService.putProductMedia(res.id, media).catch(err => {
					console.error(`failed uploading product media`, media.name, media.content_hash);
				});
			}));
		}).then(() => {
			snack.enqueueSnackbar("Product created successfully", { variant: "success" });
			dispatch(push(`/partner/store/${store.id}/dashboard`));
		}).catch(err => {
			console.error("Failed during product create", err);
			snack.enqueueSnackbar("We ran into an issue creating your item", { variant: "error" });
		}).finally(() => {
			updateState({ isSubmitting: false });
		});
	}

	return (
		<Loader visible={!store}>
			{!!store && (
				<Box margin="medium" gap="small">
					<Grid columns={columns} gap="small">
						<StoreProductForm
							store={store}
							onSubmit={handleCreateProduct}
							onMediaAccepted={handleUploadMedia}
							onMediaDeleted={handleDeleteMedia}
							isSubmitting={state.isSubmitting}
							isUpdating={state.isUpdating}
							isUploadingMedia={state.isUploadingMedia}
						/>
						{dimensions.size !== "small" && (
							<CreateStoreProductPreview
								product={product ?? {} as DTO<Product>}
							/>
						)}
					</Grid>
				</Box>
			)}
		</Loader>
	);
};

export const UpdateStoreProductPage: React.FC = (props) => {
	const params = useParams();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const store = useAppSelector(selectActiveStore);

	const [ isDeletingProduct, setIsDeletingProduct ] = useState(false);
	const [ isUpdatingProduct, setIsUpdatingProduct ] = useState(false);
	const [ isSubmittingProduct, setIsSubmittingProduct ] = useState(false);
	const [ isUploadingMedia, setIsUploadingMedia ] = useState(false);
	const product = useAppSelector(selectActiveStoreProduct);

	function handleFetchProduct(storeId: string, productId: string): Promise<void> {
		return PartnerService.getStoreProduct(storeId, productId, [ "store" ]).then(res => {
			dispatch(setActiveStoreProduct({ ...res }));
		}).catch(err => {
			console.error("Failed loading product", err);
			snack.enqueueSnackbar("We ran into an error loading your product details", { variant: "error" });
		});
	}

	function handleDeleteProduct(): void {
		if(!product || !store) return;
		setIsDeletingProduct(true);
		PartnerService.deleteStoreProduct(store.id, product.id).then(() => {
			snack.enqueueSnackbar("Successfully deleted product", { variant: "success" });
			navigateToStoreDashboard(store.id, dispatch);
		}).catch(err => {
			console.error("Failed to delete product", err);
			snack.enqueueSnackbar("We ran into an issue deleting your product", { variant: "error" });
		}).finally(() => {
			setIsDeletingProduct(false);
		});
	}

	useEffect(() => {
		dispatch(fetchBrands());
	}, []);

	useEffect(() => {
		if(!store) {
			return;
		}

		if(!params.productId) {
			dispatch(push("/"));
			return;
		}

		handleFetchProduct(store.id, params.productId);
	}, [ store, params ]);

	async function handleUploadMedia(files: File[]): Promise<void> {
		if(!product || !store) {
			return;
		}

		setIsUpdatingProduct(true);
		setIsUploadingMedia(true);
		return await Promise.all(
			files.map(file => {
				return fileToMedia(file, ProductMediaContext.OTHER).then(media => {
					return ProductService.putProductMedia(product.id, media as DTO<ProductMedia>);
				})
					.catch(err => {
						console.error("Failed during media upload", err);
						snack.enqueueSnackbar(`Upload failed for file ${file.name}`);
					});
			})
		).then(() => {
			return PartnerService.getStoreProduct(store.id, product.id, [ "store" ]).then(res => {
				console.log("GOT MEDIA AFTER UPLOAD", res.media);
				dispatch(setActiveStoreProduct({ ...res }));
				snack.enqueueSnackbar("Files uploaded successfully", { variant: "success" });
			});
		}).finally(() => {
			setIsUpdatingProduct(false);
			setIsUploadingMedia(false);
		});
	}

	async function handleDeleteMedia(media: DTO<ProductMedia>): Promise<boolean> {
		if(!product || !store) {
			return Promise.resolve(false);
		}

		setIsUpdatingProduct(true);
		setIsUploadingMedia(true);

		return ProductService.deleteProductMedia(product.id, media.id).then(() => {
			return PartnerService.getStoreProduct(store.id, product.id, [ "store" ]).then(res => {
				dispatch(setActiveStoreProduct({ ...res }));
				snack.enqueueSnackbar("File deleted successfully", { variant: "success" });
			});
		}).then(() => {
			return true;
		}).catch(err => {
			return false;
		}).finally(() => {
			setIsUpdatingProduct(false);
			setIsUploadingMedia(false);
		});
	}

	function handleUpdateProduct(updates: Partial<DTO<StoreProduct> & { quantity: number; }>): Promise<void> {
		if(!product || !store) {
			return Promise.resolve();
		}

		setIsUpdatingProduct(true);
		return PartnerService.updateStoreProduct(store.id, product.id, updates)
			.then(res => {
				dispatch(setActiveStoreProduct({ ...res, store: store as Store }));
				snack.enqueueSnackbar("Changes saved successfully", { variant: "success" });
			})
			.catch(err => {
				console.error("Failed to update store product", updates, err);
				snack.enqueueSnackbar("We ran into an issue updating your product details", { variant: "error" });
			}).finally(() => {
				setIsUpdatingProduct(false);
			});
	}

	function handleSubmitProduct(store: DTO<Store>, product: DTO<StoreProduct>): void {
		setIsSubmittingProduct(true);
		PartnerService.submitStoreProduct(store.id, product.id).then(res => {
			dispatch(setActiveStoreProduct({ ...res, store: store as Store }));
		}).catch(err => {
			console.error("Failed to submit product", err);
			snack.enqueueSnackbar("We ran into an issue updating your product", { variant: "error" });
		}).finally(() => {
			setIsSubmittingProduct(false);
		});
	}

	function toggleProductVisibility(store: DTO<Store>, product: DTO<StoreProduct>, visible: boolean): void {
		setIsUpdatingProduct(true);
		PartnerService.updateStoreProduct(store.id, product.id, { visible }).then(res => {
			dispatch(setActiveStoreProduct({ ...res, store: store as Store }));
		}).catch(err => {
			console.error("Failed to submit product", err);
			snack.enqueueSnackbar("We ran into an issue updating your product", { variant: "error" });
		}).finally(() => {
			setIsUpdatingProduct(false);
		});
	}

	return (
		<Loader visible={!store || !product}>
			{!!(store && product) && (
				<Box gap="small">
					<StoreProductFormContainer
						product={product}
						isUpdating={isUpdatingProduct}
						isSubmitting={isSubmittingProduct}
						isUploadingMedia={isUploadingMedia}
						onFormSubmit={handleUpdateProduct}
						onFilesAccepted={handleUploadMedia}
						onMediaDeleted={handleDeleteMedia}
					>
						<Box align="start" margin="small" direction="row" gap="small">
							{!product.submitted && (
								<LoadingButton
									primary
									label="Submit for Sale"
									hoverIndicator
									isLoading={isSubmittingProduct}
									onClick={() => handleSubmitProduct(store, product)}
								/>
							)}
							{!product.visible && product.submitted && (
								<LoadingButton
									primary
									isLoading={isUpdatingProduct}
									label="Show in Store"
									onClick={() => toggleProductVisibility(store, product, true)}
								/>
							)}
							{product.visible && product.submitted && (
								<LoadingButton
									primary
									isLoading={isUpdatingProduct}
									label="Hide from Store"
									onClick={() => toggleProductVisibility(store, product, false)}
								/>
							)}
							<LoadingButton
								primary
								color="status-error"
								isLoading={isDeletingProduct}
								label="Remove Product"
								onClick={handleDeleteProduct}
							/>
						</Box>
					</StoreProductFormContainer>
					<Grid columns={{ count: 2, size: "auto" }} margin="small" gap="small">
						<ElevatedCard
							header={
								<ElevatedCardHeader
									title="Purchase History"
								/>
							}
						>
							<Box align="center" justify="center">
								<Text weight="bold">
									Coming Soon
								</Text>
							</Box>
						</ElevatedCard>
						<ElevatedCard
							header={
								<ElevatedCardHeader
									title="Delivery / Pickup History"
								/>
							}
						>
							<Box align="center" justify="center">
								<Text weight="bold">
									Coming Soon
								</Text>
							</Box>
						</ElevatedCard>
					</Grid>
				</Box>
			)}
		</Loader>
	);
};

export const StorePurchasesPage: React.FC = (props) => {
	const snack = useSnackbar();
	const location = useLocation();
	const dimensions = useWindowDimensions();
	const [ isLoading, setIsLoading ] = useState(false);

	const store = useAppSelector(selectActiveStore);
	const [ purchases, setPurchases ] = useState<DTO<Purchase>[]>([]);

	const columns = useMemo(() => {
		switch(dimensions.size) {
			case "small":
			case "medium": {
				return 1;
			}
			default: {
				return 2;
			}
		}
	}, [ dimensions.size ]);

	function fetchStorePurchases(storeId: string): void {
		if(!store) {
			return;
		}

		setIsLoading(true);
		PartnerService.listStorePurchases(storeId, {}, [ "intents" ]).then(res => {
			setPurchases([ ...res ]);
		}).catch(err => {
			console.error("Failed to load store purchases", err);
			snack.enqueueSnackbar("We ran into an issue loading your purchase history", { variant: "error" });
		}).finally(() => {
			setIsLoading(false);
		});
	}

	useEffect(() => {
		if(store) {
			fetchStorePurchases(store.id);
		}
	}, [ store ]);

	useEffect(() => {

	}, [ location.search ]);

	function handleConfirmPurchasePickup(purchase: DTO<Purchase>, intent: DTO<PurchaseIntent>): Promise<void> {
		if(!store) return Promise.resolve();
		return PartnerService.updatePurchaseIntentCompleted(store.id, purchase.id, intent.id).then(() => {
			return;
		}).catch(err => {
			console.error("Failed to update purchase intent", err);
			snack.enqueueSnackbar("We ran into an issue updating this record", { variant: "error" });
		});
	}

	return (
		<Box flex margin="medium" gap="small">
			{isLoading && (
				<LinearProgress />
			)}
			{store && (
				<Box gap="small">
					<Grid columns={{ count: columns, size: "auto" }} gap="medium">
						<ElevatedCard
							header={
								<ElevatedCardHeader title="Purchase Summary" />
							}
						>
							<StorePurchases
								store={store}
								purchases={purchases}
								isLoading={isLoading}
							/>
						</ElevatedCard>
						<ElevatedCard
							header={
								<ElevatedCardHeader title="Reports" />
							}
						>
							<StorePurchaseGraph
								purchases={purchases}
							/>
						</ElevatedCard>
					</Grid>
					<Box flex>
						<PurchaseDetails
							purchases={purchases}
							onConfirmPickedUp={handleConfirmPurchasePickup}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export const StorePurchasePage: React.FC = (props) => {
	const params = useParams();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const store = useAppSelector(selectActiveStore);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ purchase, setPurchase ] = useState<DTO<Purchase> | null>(null);

	useEffect(() => {
		if(!store) {
			return;
		}

		if(!params.purchaseId) {
			dispatch(push("/"));
			return;
		}

		handleFetchPurchase(store.id, params.purchaseId);
	}, [ store, params ]);

	function handleFetchPurchase(storeId: string, purchaseId: string): Promise<void> {
		setIsLoading(true);
		return PartnerService.getStorePurchase(storeId, purchaseId, [ "intents", "customer" ]).then(res => {
			setPurchase({ ...res });
		}).catch(err => {
			console.error("Failed to load purchase", err);
			snack.enqueueSnackbar("We ran into an issue loading the purchase details", { variant: "error" });
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const columns = useMemo(() => {
		switch(size) {
			case "small":
			case "medium": {
				return 1;
			}
			default: {
				return 2;
			}
		}
	}, [ size ]);

	function handleConfirmPickup(intent: DTO<PurchaseIntent>): Promise<void> {
		if(!store || !purchase) return Promise.resolve();
		return PartnerService.updatePurchaseIntentCompleted(store.id, purchase.id, intent.id).then(() => {
			return handleFetchPurchase(store.id, purchase.id);
		}).then(() => {
			return;
		}).catch(err => {
			console.error("failed to confirm pickup", err);
			snack.enqueueSnackbar("We ran into an issue updating this purchase", { variant: "error" });
		});
	}

	return (
		<Loader visible={!purchase}>
			<Box margin="medium">
				{purchase && (
					<Box gap="small">
						<Grid columns={{ count: columns, size: "auto" }} gap="small">
							<PurchaseDetailsCard
								purchase={purchase}
								hideViewMore
								header={
									<ElevatedCardHeader title="Purchase Details" />
								}
							/>
							<Box>

							</Box>
						</Grid>
						<ElevatedCard
							header={
								<ElevatedCardHeader title="Purchased Products" />
							}
						>
							<PurchasedItemsContainer
								purchase={purchase}
								intents={purchase.intents}
								onConfirmPickedUp={handleConfirmPickup}
							/>
						</ElevatedCard>
					</Box>
				)}
			</Box>
		</Loader>
	);
};