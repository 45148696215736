import { LinearProgress } from "@material-ui/core";
import type { Customer, DTO } from "../../../types";
import { Box, Button, ColumnConfig, DataTable, Grid } from "grommet";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchCustomer, selectActiveCustomer, selectCustomerHistory, selectCustomers } from "../../../app/store/admin";
import { Loader, SlimHeading, useScreenSize } from "../../common";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";
import { CustomerDonations, CustomerInformation, CustomerOrders, CustomerPurchases, CustomerRemovals, SearchCustomerModal } from "../components";

const recentCustomerColumns: ColumnConfig<Customer>[] = [
	{
		property: "last_name",
		header: "Last Name",
		primary: true,
		sortable: true,
		render: (customer) => customer.last_name
	},
	{
		property: "first_name",
		header: "First Name",
		primary: false,
		render: (customer) => customer.first_name
	},
	{
		property: "email_address",
		header: "Email Address",
		primary: false,
		render: (customer) => customer.email_address ?? ""
	},
	{
		property: "phone_number",
		header: "Phone Number",
		primary: false,
		render: (customer) => customer.phone_number ?? ""
	}
];

interface CustomerScreenState {
	isLoading: boolean;
	isModalVisible: boolean;
	showCustomer: boolean;
	activeCustomer?: Customer;
}

export const CustomerScreen: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [state, setState] = useState<CustomerScreenState>({
		isLoading: false,
		isModalVisible: false,
		showCustomer: false
	});

	const customers = useAppSelector(selectCustomers);
	const recentCustomers = useAppSelector(selectCustomerHistory);

	function handleNavigate(customer: DTO<Customer>): void {
		dispatch(push(`/admin/dashboard/customers/${customer.id}`));
	}

	useEffect(() => {
		setState({ ...state, isLoading: true });
		Promise.all(recentCustomers.map(record => {
			return dispatch(fetchCustomer(record.id)).unwrap();
		}))
			.catch(err => {
				console.error(`Failed to fetch recent customers`, err);
				snack.enqueueSnackbar("Failed to load recent customers", {
					variant: "error"
				});
			})
			.finally(() => {
				setState(state => {
					return { ...state, isLoading: false };
				});
			});
	}, []);

	return (
		<Box margin="small" gap="small">
			<Box direction="row">
				<Button label="Find Customer" onClick={() => {
					setState({ ...state, isModalVisible: true });
				}} />
			</Box>
			<Box>
				{state.isLoading && (
					<LinearProgress />
				)}
				<DataTable
					columns={recentCustomerColumns}
					data={customers}
					step={10}
					onClickRow={(event) => {
						handleNavigate(event.datum);
					}}
				/>
			</Box>

			<SearchCustomerModal
				visible={state.isModalVisible}
				customer={state.activeCustomer}
				close={() => {
					setState({ ...state, isModalVisible: false });
				}}
			/>
		</Box>
	);
};

interface CustomerDetailsState {
	isLoadingCustomer: boolean;
}

export const CustomerDetailsScreen: React.FC = (props) => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [state, setState] = useState<CustomerDetailsState>({
		isLoadingCustomer: false
	});
	const size = useScreenSize();
	const customer = useAppSelector(selectActiveCustomer);

	useEffect(() => {
		if(!params.customerId) {
			dispatch(push("/admin/dashboard/customers"));
			return;
		}

		handleFetchCustomer(params.customerId);
	}, [params]);

	function wrapStateUpdate(changes: Partial<CustomerDetailsState>) {
		setState(state => {
			return {
				...state,
				...changes
			};
		});
	}

	function handleFetchCustomer(customerId: string): void {
		wrapStateUpdate({ isLoadingCustomer: true });

		dispatch(fetchCustomer(customerId)).unwrap()
			.then(customer => {
				console.debug("Loaded Customer", customer);
			})
			.catch(err => {
				console.error("Failed to fetch customer", err);
				snack.enqueueSnackbar("Failed to get customer", {
					variant: "error"
				});
			})
			.finally(() => {
				wrapStateUpdate({ isLoadingCustomer: false });
			});
	}

	return (
		<Loader visible={state.isLoadingCustomer}>
			{customer && (
				<Box margin="medium" gap="small">
					<CustomerInformation
						customer={customer}
					/>
					<Grid columns={{ count: size === "small" ? 1 : 2, size: "flex" }} rows="flex" gap="small">
						<Box gap="small" flex>
							<SlimHeading level="3">Customer Orders</SlimHeading>
							<CustomerOrders customer={customer} />
						</Box>
						<Box gap="small" flex>
							<SlimHeading level="3">Customer Purchases</SlimHeading>
							<CustomerPurchases customer={customer} />
						</Box>
						<Box gap="small">
							<SlimHeading level="3">Customer Donations</SlimHeading>
							<CustomerDonations customer={customer} />
						</Box>
						<Box gap="small">
							<SlimHeading level="3">Customer Removals</SlimHeading>
							<CustomerRemovals customer={customer} />
						</Box>
					</Grid>
				</Box>
			)}
		</Loader>
	);
};