import { Badge } from "@material-ui/core";
import { DTO, Product } from "@rego-app/common";
import { Anchor, Box, Button, Spinner, Stack, Text } from "grommet";
import { Cart, Radial, Shop } from "grommet-icons";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { push } from "redux-first-history";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCartProducts, removeProductFromCart, addProductToCart } from "../../../app/store/purchase";

export const CartIcon: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const cart = useAppSelector(selectCartProducts);

	return (
		<Box pad="small">
			<Badge badgeContent={cart.length || ""} color={cart.length ? "primary" : undefined}>
				<Shop size="large" onClick={(() => {
					dispatch(push("/cart"));
				})} />
			</Badge>
		</Box>
	);
};

export const AddToCartButton: React.FC<{ product: DTO<Product>; }> = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const cart = useAppSelector(selectCartProducts);
	const [isLoading, setIsLoading] = useState(false);

	function handleNavigateToCart(): void {
		dispatch(push("/shop/cart"));
	}

	const isInCart = useMemo(() => {
		return cart.findIndex(p => p.id === props.product.id) !== -1;
	}, [props.product, cart]);

	function displayAddedToCartMessage(): void {
		snack.enqueueSnackbar(
			<Text>Successfully added to cart. <Anchor onClick={handleNavigateToCart} color="white">Checkout now?</Anchor></Text>, {
			variant: "success"
		});
	}

	function displayRemovedFromCartMessage(): void {
		snack.enqueueSnackbar(
			<Text>Successfully removed from cart.</Text>, {
			variant: "success"
		});
	}

	function handleAddOrRemoveFromCart(): void {
		setIsLoading(true);

		if(isInCart) {
			dispatch(removeProductFromCart(props.product)).unwrap()
				.then(res => {
					displayRemovedFromCartMessage();
				})
				.catch(err => {
					console.error(`Failed to remove product [${props.product.id}] from cart`, err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		else {
			dispatch(addProductToCart(props.product)).unwrap()
				.then(res => {
					displayAddedToCartMessage();
				})
				.catch(err => {
					console.error(`Failed to add product [${props.product.id}] to cart`, err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}

	function getIconColor(): string {
		if(isLoading) {
			return "blue";
		}

		return (isInCart)
			? "red"
			: "green";
	}

	return (
		<Box
			hoverIndicator
			onClick={handleAddOrRemoveFromCart}
		>
			<Stack>
				<Box>
					<Button
						hoverIndicator
						onClick={handleAddOrRemoveFromCart}
						icon={<Cart />}
					/>
				</Box>
				<Box align="center" justify="center">
					{isLoading
						? (
							<Spinner
								size="medium"
							/>
						)
						: (
							<Radial
								color={getIconColor()}
								size="large"
							/>
						)
					}
				</Box>
			</Stack>
		</Box>
		// <Badge
		// 	badgeContent={isInCart
		// 		? (
		// 			<FormSubtract color="red" />
		// 		)
		// 		: (
		// 			<FormAdd color="green" />
		// 		)
		// 	}
		// >
		// 	<Box
		// 		round
		// 		border={{ size: "small", color: "black" }}
		// 	>
		// 		<Button
		// 			hoverIndicator
		// 			onClick={handleAddOrRemoveFromCart}
		// 			icon={<Cart />}
		// 		/>
		// 	</Box>
		// </Badge>
	);
};