
export const config = {
	google: {
		tagManager: {
			GTM: "GTM-TDPVHVS"
		}
	},
	contact: {
		phone: "12157706989"
	}
};

export default config;