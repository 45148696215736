import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Box, Heading, Tab, Tabs, Text } from "grommet";
import { useEffect, useState } from "react";
import { useWindowDimensions } from "../hooks";

type FAQScope =
	| "BUYER"
	| "DONOR"
	| "SELLER";

interface FAQItem {
	scope: FAQScope[];
	question: string;
	answer: string;
}

const questions: FAQItem[] = [
	{
		scope: ["BUYER", "SELLER"],
		question: "How does delivery work?",
		answer: "Once an item sells, delivery is pre-arranged with a professional furniture delivery team."
	},
	{
		scope: ["BUYER", "SELLER"],
		question: "Who pays for delivery?",
		answer: "Typically, the buyer covers the cost of delivery. However, the seller has the option to cover a portion or all the delivery fee!"
	},
	{
		scope: ["BUYER"],
		question: "Where does Rego deliver? ",
		answer: "Currently, Rego operates in Philadelphia and Atlanta Metro area. Deliveries are only within each respective market. If you see an item you want to purchase in another city, contact our support team and we’ll provide a custom shipping quote for you!"
	},
	{
		scope: ["BUYER"],
		question: "What is your refund policy, and how do I make a claim?",
		answer: "If you believe the furniture your purchased doesn’t match the sales listing, you have 24 hours to make a claim for a partial or full refund. If there was material misrepresentation from the seller outside of the course of minor, common second-hand furniture damage, then you may be able to receive a partial or full refund of your payment, and we will work with you to remove the furniture from your home if needed. If it is determined there was no misrepresentation, you will not be entitled to a refund. Please be advised that second-hand furniture often has minor damages that are not easily noticeable or negatively impact the use of the furniture."
	},
	{
		scope: ["SELLER"],
		question: "What happens if I sold furniture, and a claim is filed against my item?",
		answer: "This happens when the buyer believes there is a material misrepresentation between your sales listing and the actual furniture. Please keep in mind that while we do everything possible to support our furniture sellers, we must give our buyers a fair chance if they believe there is a discrepancy between the furniture listing and the purchase. Don’t worry, if a claim is filed, we’ll work with all parties to determine a resolution! If it is determined that you misrepresented your furniture, then you will not receive any payout and you will be charged the delivery fee. Rego does its best to avoid this from the beginning by thoroughly screening furniture listings."
	},
	{
		scope: ["BUYER"],
		question: "Can I pick up the furniture on my own?",
		answer: "At this time, buyers are not able to pick-up furniture from sellers. We do this to protect the privacy and safety of our sellers while offering convenience and white glove experience for our buyers."
	},
	{
		scope: ["BUYER", "SELLER", "DONOR"],
		question: "Does Rego assemble/disassemble items?",
		answer: "At this time, only minor assembly/disassembly is available. Minor assembly includes simple tasks such as removing the legs of a table. Please be advised Rego will not remove any doors or move any household items to get your furniture in or out. Disassembly may be available for items that are eligible for donation or disposal. This is determined on a case by case basis."
	},
	{
		scope: ["BUYER"],
		question: "How does payment work?",
		answer: "Rego only accepts electronic forms of payment such as credit or debit card. Once you place an order your card will be charged. You have 24 hours once your furniture is delivered to file a claim and request a refund."
	},
	{
		scope: ["DONOR"],
		question: "How does payment work?",
		answer: "Rego only accepts electronic forms of payment such as credit or debit card. You will not be charged until your pickup is completed. However, if you cancel on the same day as your scheduled pickup, we charge a 50% dispatch fee."
	},
	{
		scope: ["SELLER"],
		question: "How do I get paid as a seller?",
		answer: "Rego uses Stripe as our payment provider which is secure, fast, and trusted by thousands of businesses. Once your furniture is sold and delivered, you’ll receive your payout within 1-2 weeks. You’ll update your payout preference at the time of your listing."
	},
	{
		scope: ["SELLER"],
		question: "How do I remove or edit a listing?",
		answer: "If you need to make any changes to your existing listings just give us a call."
	},
	{
		scope: ["SELLER"],
		question: "What happens if my furniture doesn’t sell?",
		answer: "If your furniture is unsold, Rego will notify you of alternative removal options approaching your cut-off date. Depending on the furniture item and geographic market, Rego may be able to help arrange your furniture to go to a donation center. For more information, please visit our For Donors section."
	},
	{
		scope: ["SELLER"],
		question: "Can I price my own furniture?",
		answer: "Yes you can price your own furniture. However, we can also recommend pricing. Furniture loses much of its value once it leaves the original retailer’s store, and one of the biggest benefits of shopping second-hand furniture is cost savings. If you choose to price your own furniture we recommend anywhere from 40%-90% off the original retail value depending on the condition, age, and whether or not the furniture was exposed to certain external factors, such as pets or smoke."
	},
	{
		scope: ["SELLER", "BUYER"],
		question: "How does Rego price furniture?",
		answer: "Rego utilizes data to determine the price of furniture. Additionally, Rego looks to price furniture to sell, while trying to maximize the outcome for our sellers. Our goal is to help get sellers’ furniture out as quickly as possible to hit cut-off dates. This uniquely helps buyers score incredible finds and great deals on second-hand furniture."
	},
	{
		scope: ["DONOR"],
		question: "What happens if my item is not accepted?",
		answer: "If we can't find a charitable organization in our network who will accept your item, we can offer flexible disposal options instead."
	},
	{
		scope: ["DONOR"],
		question: "Do I get a donation receipt?",
		answer: "We do our best to get you a donation receipt. Depending on the organization, we may not be able to get you a donation receipt. If we do receive a receipt, we will post it to your dashboard."
	}
];

/**
 * //TODO: Update FAQs's once offer is done
 * Can I make an offer? 
 * 
 * Yes, you can make an offer above or below the asking price on the sales listing. Rego will present the counteroffer to the seller, and the seller will accept or deny the counteroffer.
 * 
 * 
 * //TODO: Update FAQ's when delivery cost sharing is done
 * HOW DOES IT WORK IF I COVER ALL OR A PORTION OF THE DELIVERY? 
 * 
 * If you choose to cover a portion or all of the delivery fee then the cost will be deducted from your sales payout. If your payout is not enough to cover your portion of the delivery cost, you can add a payment method to cover the difference. Covering all or a portion of the delivery cost is a great way to incentive shoppers to purchase your furniture. Think about it this way, you may have to pay a nominal fee to get the furniture out, but the savings are incredibly high in comparison to other alternatives to furniture hauling.  
 */

const FAQ: React.FC<FAQItem> = (props) => {
	const [expanded, setExpanded] = useState(false);

	return (
		<Accordion>
			<AccordionSummary
				onClick={() => {
					setExpanded(!expanded);
				}}
				expandIcon={<ExpandMore />}
			>
				<Text weight="bold">
					{props.question}
				</Text>
			</AccordionSummary>
			<AccordionDetails
				onClick={() => {
					setExpanded(!expanded);
				}}
			>
				<Text>
					{props.answer}
				</Text>
			</AccordionDetails>
		</Accordion>
	);
};

export const FAQPage: React.FC = (props) => {

	const [scope, setScope] = useState<FAQScope>("BUYER");
	const [index, setIndex] = useState(0);
	const dimensions = useWindowDimensions();

	useEffect(() => {
		switch(index) {
			case 0: {
				setScope("BUYER");
				break;
			}
			case 1: {
				setScope("DONOR");
				break;
			}
			case 2: {
				setScope("SELLER");
				break;
			}
		}
	}, [index]);

	return (
		<Box margin="medium" gap="small" align="center">
			<Box align="center">
				<Heading color="brand">
					FAQ's
				</Heading>
			</Box>
			<Box style={dimensions.width > 800 ? { maxWidth: "700px" } : { width: "-webkit-fill-available" }}>
				<Tabs
					activeIndex={index}
					onActive={(index) => {
						setIndex(index);
					}}
				>
					<Tab
						title="For Buyers"
					>
						{questions.filter(q => q.scope.includes(scope)).map(question => (
							<FAQ
								key={question.answer}
								{...question}
							/>
						))}
					</Tab>
					<Tab
						title="For Donors"
					>
						{questions.filter(q => q.scope.includes(scope)).map(question => (
							<FAQ
								key={question.answer}
								{...question}
							/>
						))}
					</Tab>
					<Tab
						title="For Sellers"
					>
						{questions.filter(q => q.scope.includes(scope)).map(question => (
							<FAQ
								key={question.answer}
								{...question}
							/>
						))}
					</Tab>
				</Tabs>
			</Box>
		</Box>
	);
};