import { Box, Heading } from "grommet";
import { SlimHeading } from "../components";
import { useWindowDimensions } from "../hooks";

export const TermsPage: React.FC = (props) => {
	const dimensions = useWindowDimensions();
	return (
		<Box margin="medium" gap="small" align="center">
			<Box align="center">
				<Heading color="brand">
					Terms of Service
				</Heading>
			</Box>
			<div style={dimensions.width > 800 ? { maxWidth: "700px" } : { width: "-webkit-fill-available" }}>
				<p>
					In favor of Entrego Inc., a Delaware corporation, dba Rego (“Rego”) its directors, officers, employees, agents, owners, equity holders and affiliates (collectively, the “Company”).
				</p>
				<p>
					Thank you for using Rego’s services to purchase certain goods, including such services with respect to the delivery and installation, and or Pickup/Removal of items, of such goods (the “Installation” or “Pickup/Removal”). As a condition to the Installation/Pickup/Removal/Delivery/Moving and as lawful consideration for being provided the services with respect to such Installation/Pickup/Removal/Delivery/Moving, Customer and/or Guardian agree to all the terms and conditions set forth in this Release.
				</p>
				<p>
					Customer and/or Guardian understand that the activities involved in the Installation/Pickup/Removal/Delivery/Moving may be dangerous and involve the risk of serious injury and/or death and/or property damage. Customer and/or Guardian acknowledge that any injuries that Customer sustains may be caused by the negligence of the Company and/or compounded by negligent emergency response operations of the Company. Customer and/or Guardian acknowledge that Customer is voluntarily participating in the activities with knowledge of the danger involved and hereby agrees to accept and assume any and all risks of injury, death or property damage, whether caused by the negligence of the Company or otherwise.
				</p>
				<p>
					Customer and/or Guardian do hereby freely, voluntarily and without duress execute this Release under the following terms:
				</p>
				<ol>
					<li>
						Waiver and Release. Customer and/or Guardian do hereby release and forever discharge and hold harmless the Company and its successors and assigns from any and all liability, claims and demands of whatever kind or nature, either in law or in equity, which arise or may hereafter arise from the Installation/Pickup/Removal/Delivery/Moving. Customer and/or Guardian understand that this Release discharges the Company from any liability or claim that Customer and/or Guardian may have against the Company with respect to any bodily injury, personal injury, illness, death or property damage that may result from the Installation, whether caused by the negligence of the Company or otherwise. Customer and/or Guardian also understand that the Company does not assume any responsibility for or obligation to provide financial assistance or other assistance, including but not limited to medical, health or disability insurance in the event of injury or illness.
					</li>
					<li>
						Medical Treatment. Customer and/or Guardian do hereby release and forever discharge the Company from any claim whatsoever that arises or may hereafter arise on account of any first aid, treatment or service rendered in connection with the Installation/Pickup/Removal.
					</li>
					<li>
						Assumption of the Risk. Customer and/or Guardian understand that the Installation/Pickup/Removal/Delivery/Moving may include activities that may be hazardous to Customer. Customer and/or Guardian hereby expressly and specifically assume the risk of injury or harm in these activities and release the Company from all liability for injury, illness, death or property damage resulting from the activities with respect to the Installation/Pickup/Removal.
					</li>
					<li>
						Insurance. Customer and/or Guardian understand that the Company does not carry or maintain health, medical or disability insurance coverage for any Customer. Each Customer is expected and encouraged to obtain his or her own medical or health insurance coverage.
					</li>
					<li>
						Other. Customer and/or Guardian expressly agree that this Release is intended to be as broad and inclusive as permitted by the laws of the Commonwealth of Pennsylvania, and that this Release shall be governed by and interpreted in accordance with the laws of the Commonwealth of Pennsylvania. Customer and/or Guardian agree that in the event that any clause or provision of this Release shall be held to be invalid by any court of competent jurisdiction, the invalidity of such clause or provision shall not otherwise affect the remaining provisions of this Release which shall continue to be enforceable. If, despite signing this Release, Customer brings a lawsuit against the Company, Customer agrees to pay for any and all court costs and attorney fees incurred by the Company as a result of such litigation. This Release constitutes the sole and entire agreement of the Company and the Customer and/or Guardian with respect to the subject matter contained herein and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to such subject matter.
					</li>
				</ol>
				<p>
					BY SIGNING, I ACKNOWLEDGE THAT I HAVE READ AND UNDERSTOOD ALL OF THE TERMS OF THIS RELEASE AND THAT I AM VOLUNTARILY GIVING UP SUBSTANTIAL LEGAL RIGHTS, INCLUDING THE RIGHT TO SUE THE COMPANY.
				</p>
				<h3>
					Coronavirus/COVID-19 RELEASE AND WAIVER OF LIABILITY
				</h3>
				<p>
					I acknowledge the contagious nature of the Coronavirus/COVID-19 and that the CDC and many other public health authorities still recommend practicing social distancing.
				</p>
				<p>
					I further acknowledge that Rego has put in place preventative measures to reduce the spread of the Coronavirus/COVID-19.
				</p>
				<p>
					I further acknowledge that Rego cannot guarantee that I will not become infected with the Coronavirus/Covid-19. I understand that the risk of becoming exposed to and/or infected by the Coronavirus/COVID-19 may result from the actions, omissions, or negligence of myself and others, including, but not limited to, Rego staff, and other Rego clients and their families. I voluntarily seek services provided by Rego and acknowledge that I am increasing my risk to exposure to the Coronavirus/COVID-19. I acknowledge that I must comply with all set procedures to reduce the spread while attending my appointment.
				</p>
				<p>
					I attest that:
				</p>
				<ul>
					<li>
						I am not experiencing any symptom of illness such as cough, shortness of breath or difficulty breathing, fever, chills, repeated shaking with chills, muscle pain, headache, sore throat, or new loss of taste or smell.
					</li>
					<li>
						I have not traveled internationally within the last 14 days.
					</li>
					<li>
						I have not traveled to a highly impacted area within the United States of America in the last 14 days.
					</li>
					<li>
						I do not believe I have been exposed to someone with a suspected and/or confirmed case of the Coronavirus/COVID-19.
					</li>
					<li>
						I have not been diagnosed with Coronavirus/Covid-19 and not yet cleared as non-contagious by state or local public health authorities.
					</li>
					<li>
						I am following all CDC recommended guidelines as much as possible and limiting my exposure to the Coronavirus/COVID-19.
					</li>
				</ul>
				<p>
					I hereby release and agree to hold Rego harmless from, and waive on behalf of myself, my heirs, and any personal representatives any and all causes of action, claims, demands, damages, costs, expenses and compensation for damage or loss to myself and/or property that may be caused by any act, or failure to act of Rego, or that may otherwise arise in any way in connection with any services received from Rego. I understand that this release discharges Rego from any liability or claim that I, my heirs, or any personal representatives may have against Rego with respect to any bodily injury, illness, death, medical treatment, or property damage that may arise from, or in connection to, any services received from Rego. This liability waiver and release extends to Rego together with all owners, partners, and employees.
				</p>
			</div>
		</Box>
	);
};