import { Box, BoxTypes, Card, CardFooter, CardHeader } from "grommet";

interface ElevatedCardProps {
	cardProps?: BoxTypes;
	header?: JSX.Element;
	headerProps?: BoxTypes;
	footer?: JSX.Element;
	footerProps?: BoxTypes;
}

export const ElevatedCard: React.FC<ElevatedCardProps> = (props) => {
	return (
		<Card {...props.cardProps}>
			{!!props.header && (
				<CardHeader {...props.headerProps}>
					{props.header}
				</CardHeader>
			)}
			<Box gap="small" margin="small" flex>
				{props.children}
			</Box>
			<CardFooter {...props.footerProps}>
				{props.footer}
			</CardFooter>
		</Card>
	);
};