import { useAppSelector } from "../../../app/store";
import { selectConfig } from "../../../app/store/application";

export function useConfig() {
	const config = useAppSelector(selectConfig);

	if(!config) {
		throw new Error("Config has not been set in state");
	}

	return config;
}