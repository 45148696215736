import { asEnum, CustomerProduct, DeliveryWindow, Donation, DonationMedia, DonationMediaContext, DTO, Estimate, formatCurrency, Media, Payment, Product, ProductIntent, Service, ServiceInvoice, Vehicle } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, CheckBox, DateInput, Form, FormField, Grid, Layer, Select, Spinner, Text, TextInput } from "grommet";
import { Calendar, FormCheckmark, FormClose, FormNext } from "grommet-icons";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { OrderService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchDonation, fetchDonationOrRemoval, fetchRemoval } from "../../../app/store/admin";
import { getOrFetchVehicles, selectVehicles } from "../../../app/store/reference";
import { Pagination, extendedPickupWindows, SlimHeading, useTimezone, Modal, Dropzone, Loader, LocalDateField, FieldWithActionButton, CustomerNavigateField, LoadingButton, useWindowDimensions } from "../../common";
import { ProductSummary } from "./orders";
import { parseTimestampFromUTC } from "../../../helpers";
import { navigateToOrder, navigateToPayment } from "../routes";
import { useViewReview } from "./review";

interface AddProductToServiceProps {
	service: DTO<Service>;
	onClose: () => void;
	onSave: (products: DTO<CustomerProduct>[]) => void;
	isSaving: boolean;
}

export const AddProductToService: React.FC<AddProductToServiceProps> = (props) => {
	const snack = useSnackbar();
	const [ selectedProducts, setSelectedProducts ] = useState<DTO<CustomerProduct>[]>([]);
	const [ loadedProducts, setLoadedProducts ] = useState(false);
	const [ products, setProducts ] = useState<DTO<CustomerProduct>[]>([]);

	useEffect(() => {
		if(props.service.order) {
			OrderService.listOrderProducts(props.service.order.id, {}, [ "service", "customer" ])
				.then(products => {
					setProducts(products);
				})
				.catch(err => {
					console.error("Failed to load order products", err);
					snack.enqueueSnackbar("Failed to load order products", {
						variant: "error"
					});
				})
				.finally(() => {
					setLoadedProducts(true);
				});
		}
	}, [ props.service ]);

	function addProductToService(product: DTO<CustomerProduct>): void {
		const updated = [
			...selectedProducts.filter(p => p.id !== product.id),
			product
		];

		setSelectedProducts([ ...updated ]);
	}

	function removeProductFromService(product: DTO<CustomerProduct>): void {
		const updated = [
			...selectedProducts
		].filter(p => p.id !== product.id);

		setSelectedProducts([ ...updated ]);
	}

	function handleSaveProducts(): void {
		if(selectedProducts.length === 0) {
			props.onClose();
			return;
		}

		props.onSave(selectedProducts);
	}
	return (
		<Layer
			onClickOutside={props.onClose}
			onEsc={props.onClose}
		>
			<Loader visible={!loadedProducts}>
				<Box margin="medium">
					<Box>
						{products.map((product) => (
							<ProductSummary product={product} key={product.id} permitAI>
								<Box align="end" justify="center" flex="grow">
									<Box margin="small">
										<CheckBox
											label={<Text weight="bold">Select?</Text>}
											disabled={!!product.service || props.service.scheduled}
											onClick={(event) => {
												event.stopPropagation();
											}}
											checked={!!selectedProducts.find(p => p.id === product.id)}
											onChange={(event) => {
												return (event.target.checked)
													? addProductToService(product)
													: removeProductFromService(product);
											}}
										/>
									</Box>
								</Box>
							</ProductSummary>
						))}
					</Box>
					<Box align="end">
						<Button
							label="Save"
							icon={props.isSaving ? <Spinner /> : undefined}
							disabled={props.isSaving}
							onClick={handleSaveProducts}
						/>
					</Box>
				</Box>
			</Loader>
		</Layer>
	);
};


interface ServiceProductsProps {
	intent: ProductIntent;
	service: DTO<Service>;
}

export const ServiceProducts: React.FC<ServiceProductsProps> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const [ removingProduct, setRemovingProduct ] = useState("");
	const [ products, setProducts ] = useState<DTO<Product>[]>(props.service.products ?? []);

	useEffect(() => {
		setProducts(props.service.products.map(product => {
			return {
				...product,
				service: props.service as unknown as Service
			};
		}));
	}, [ props.service ]);

	useEffect(() => {
		dispatch(getOrFetchVehicles())
			.catch(err => {
				console.error("Failed to load vehicles", err);
				snack.enqueueSnackbar("Error loading vehicle data", {
					variant: "error"
				});
			});
	}, []);

	function removeProduct(productId: string): void {
		setRemovingProduct(productId);
		OrderService.removeProductFromService(props.service.id, productId)
			.then(() => {
				return (props.intent === ProductIntent.DONATE)
					? dispatch(fetchDonation(props.service.id)).unwrap()
					: dispatch(fetchRemoval(props.service.id)).unwrap();
			})
			.catch(err => {
				console.error("Failed to remove product", err);
				snack.enqueueSnackbar("Error removing product", {
					variant: "error"
				});
			})
			.finally(() => {
				setRemovingProduct("");
			});
	}

	function addProduct(productId: string): void {

	}

	const defaultRenderProducts = (products: DTO<CustomerProduct>[]) => (
		<Box>
			{products.map(product => (
				<ProductSummary
					permitAI
					product={product}
					key={product.id}
					overrideOnClick={() => {
						dispatch(push(`/admin/dashboard/products/${product.id}`));
					}}
				>
					<Box align="center" justify="center" flex="grow">
						<Button
							disabled={removingProduct === product.id || props.service.scheduled}
							icon={removingProduct === product.id ? <Spinner /> : <FormClose />}
							label="Remove?"
							onClick={(event) => {
								removeProduct(product.id);
								event.stopPropagation();
							}}
						/>
					</Box>
				</ProductSummary>
			))}
		</Box>
	);

	return (
		<Box gap="small" flex="grow">
			<Pagination
				items={products}
				pageSize={5}
				renderItems={defaultRenderProducts}
			/>
		</Box>
	);
};

interface UpdateScheduledDateState {
	date: string;
	window: string;
}

interface UpdateScheduledDateProps {
	isScheduled: boolean;
	onClose(): void;
	onWindowSelected(window: DeliveryWindow): Promise<void>;
}

export const UpdateScheduledDate: React.FC<UpdateScheduledDateProps> = (props) => {
	const snack = useSnackbar();
	const timezone = useTimezone();
	const [ isSaving, setIsSaving ] = useState(false);

	const [ state, setState ] = useState<UpdateScheduledDateState>({
		date: "",
		window: ""
	});

	function handleReschedule(): void {
		if(!state.date || !state.window) {
			return;
		}

		const { date, window } = state;

		const pickupWindow = extendedPickupWindows.find(w => w.label === window);
		if(!pickupWindow) {
			return;
		}

		setIsSaving(true);
		props.onWindowSelected({
			date: new Date(date),
			from: pickupWindow.from,
			to: pickupWindow.to,
			timezone
		})
			.then(() => {
				props.onClose();
			})
			.catch(err => {
				console.error("Failed to schedule", err);
				snack.enqueueSnackbar("Failed to schedule pickup", { variant: "error" });
			})
			.finally(() => {
				setIsSaving(false);
			});
	}

	return (
		<Layer
			onClickOutside={props.onClose}
			onEsc={props.onClose}
		>
			<Box margin="medium" gap="small">
				<Box align="center">
					<SlimHeading level="3">
						{props.isScheduled ? "Reschedule Pickup" : "Schedule Pickup"}
					</SlimHeading>
				</Box>
				<Form
					value={state}
					onChange={(changes) => {
						setState({
							...state,
							...changes
						});
					}}
					onSubmit={handleReschedule}
					validate="submit"
				>
					<FormField
						name="date"
						label="Date"
					>
						<DateInput
							name="date"
							format="mm/dd/yyyy"
							calendarProps={{
								firstDayOfWeek: 0
							}}
						/>
					</FormField>
					<FormField
						label="Pickup Window"
						name="window"
						onChange={(event) => {
							setState({
								...state,
								window: event.target.value
							});
						}}
					>
						<Select
							name="window"
							options={extendedPickupWindows.map(w => w.label)}
							value={state.window}
						/>
					</FormField>
					<Box flex direction="row" justify="between" gap="medium" margin={{ top: "medium" }}>
						<Button
							label="Cancel"
							onClick={props.onClose}
						/>
						<Button
							primary
							label={props.isScheduled ? "Reschedule" : "Schedule"}
							icon={isSaving ? <Spinner /> : undefined}
							disabled={isSaving}
							type="submit"
						/>
					</Box>
				</Form>
			</Box>
		</Layer>
	);
};

interface ServiceDetailsState {
	isSavingVehicle: boolean;
	isSavingRequiresHelper: boolean;
	isUpdatingScheduledWindow: boolean;
}

export const ServiceDetails: React.FC<{ service: DTO<Service>; intent: ProductIntent; }> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const vehicles = useAppSelector(selectVehicles);
	const viewReview = useViewReview();

	const [ state, setState ] = useState<ServiceDetailsState>({
		isSavingRequiresHelper: false,
		isSavingVehicle: false,
		isUpdatingScheduledWindow: false
	});

	const isLocked = useMemo(() => {
		return !!props.service.completed_at;
	}, [ props.service, props.intent ]);

	function updateVehicle(name: string): void {
		setState({
			...state,
			isSavingVehicle: true
		});

		const vehicle = vehicles.find(v => v.name === name);
		if(!vehicle) {
			console.error("could not find vehicle with name", name);
			snack.enqueueSnackbar(`Could not find vehicle [${name}]`, {
				variant: "warning"
			});
			return;
		}

		OrderService.updateService(props.service.id, { vehicle: vehicle as Vehicle })
			.then(() => {
				return dispatch(fetchDonationOrRemoval(props.intent)(props.service.id)).unwrap();
			})
			.catch(err => {
				console.error("Failed to update vehicle", err);
				snack.enqueueSnackbar("Error updating donation vehicle", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isSavingVehicle: false
				});
			});
	}

	function updateRequiresHelper(requires_helper: boolean): void {
		setState({
			...state,
			isSavingRequiresHelper: true
		});

		OrderService.updateService(props.service.id, { requires_helper })
			.then(() => {
				return dispatch(fetchDonationOrRemoval(props.intent)(props.service.id)).unwrap();
			})
			.catch(err => {
				console.error("Failed to update donation", err);
				snack.enqueueSnackbar("Error updating donation", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isSavingRequiresHelper: false
				});
			});
	}

	function handleRescheduleService(window: DeliveryWindow): Promise<void> {
		return OrderService.scheduleServiceDelivery(props.service.id, window)
			.then(() => dispatch(fetchDonationOrRemoval(props.intent)(props.service.id)).unwrap())
			.then(() => { });
	}

	return (
		<Box>
			{state.isUpdatingScheduledWindow && (
				<UpdateScheduledDate
					isScheduled={props.service.scheduled}
					onWindowSelected={handleRescheduleService}
					onClose={() => {
						setState({
							...state,
							isUpdatingScheduledWindow: false
						});
					}}
				/>
			)}
			{props.service.store && (
				<FormField
					label="Store Name"
				>
					<TextInput
						value={props.service.store.name}
						readOnly
					/>
				</FormField>
			)}
			<FormField
				label="Vehicle"
			>
				<Select
					icon={state.isSavingVehicle ? <Spinner /> : undefined}
					disabled={state.isSavingVehicle || props.service.scheduled || isLocked}
					value={props.service.vehicle.name}
					options={vehicles.map(v => v.name)}
					onChange={(event) => {
						updateVehicle(event.target.value);
					}}
				/>
			</FormField>
			<FormField
				label="Requires Helper?"
				contentProps={{ border: undefined }}
			>
				<CheckBox
					onChange={(event) => {
						updateRequiresHelper(event.target.checked);
					}}
					checked={props.service.requires_helper}
					disabled={state.isSavingRequiresHelper || props.service.scheduled || isLocked}
					label={state.isSavingRequiresHelper ? <Spinner /> : undefined}
				/>
			</FormField>
			<FormField
				label="Scheduled"
				contentProps={{ border: undefined }}
			>
				<CheckBox
					disabled
					readOnly
					checked={props.service.scheduled}
				/>
			</FormField>
			<FormField
				label="Scheduled Window"
			>
				<Box direction="row" fill="horizontal">
					<TextInput
						disabled
						plain
						value={props.service.scheduled_window ? moment(props.service.scheduled_window.date).format("MM/DD/YYYY") : ""}
					/>
					<Button
						icon={<Calendar />}
						hoverIndicator
						disabled={isLocked}
						onClick={() => {
							setState({
								...state,
								isUpdatingScheduledWindow: true
							});
						}}
					/>
				</Box>
			</FormField>
			<FormField>
				<Select
					disabled
					options={extendedPickupWindows.map(w => w.label)}
					value={props.service.scheduled_window ? extendedPickupWindows.find(w => w.from === props.service.scheduled_window.from && w.to === props.service.scheduled_window.to)?.label : ""}
				/>
			</FormField>
			{props.service.order && (
				<FieldWithActionButton
					name="order"
					label="Order"
					value={props.service.order.id}
					icon={<FormNext />}
					onClick={() => {
						navigateToOrder(props.service.order.id, dispatch);
					}}
				/>
			)}
			{props.service.order?.customer && (
				<CustomerNavigateField
					customer={props.service.order.customer}
				/>
			)}
			{props.service.review && (
				<Box align="start" margin={{ vertical: "small" }}>
					<Button
						primary
						label="View Review"
						onClick={() => {
							props.service.review && viewReview.show(props.service.review);
						}}
					/>
				</Box>
			)}
			{(props.service.gratuity && props.service.gratuity.payment && Number(props.service.gratuity.amount > 0)) && (
				<FieldWithActionButton
					name="tip"
					label="Tip"
					value={formatCurrency(props.service.gratuity.amount)}
					icon={<FormNext />}
					onClick={() => {
						navigateToPayment(props.service.gratuity.payment.id, dispatch);
					}}
				/>
			)}
		</Box>
	);
};

interface ServiceTrackingDatesState {
	isUpdatingStartedAt: boolean;
	isUpdatingAcceptedAt: boolean;
	isUpdatingCompletedAt: boolean;
}

export const ServiceTrackingDates: React.FC<{ service: DTO<Service>; intent: ProductIntent; }> = (props) => {
	const snack = useSnackbar();
	const timezone = useTimezone();
	const dispatch = useAppDispatch();

	const [ state, setState ] = useState<ServiceTrackingDatesState>({
		isUpdatingStartedAt: false,
		isUpdatingAcceptedAt: false,
		isUpdatingCompletedAt: false
	});

	function updateState(updates: Partial<ServiceTrackingDatesState>): void {
		setState((state) => {
			return {
				...state,
				...updates
			};
		});
	}

	function handleUpdateStartedAt(): void {
		updateState({ isUpdatingStartedAt: true });
		OrderService.startService(props.service.id)
			.then(() => dispatch(fetchDonationOrRemoval(props.intent)(props.service.id)).unwrap())
			.catch(err => {
				console.error("Failed to update service started", err);
				snack.enqueueSnackbar("Failed to update started at", { variant: "error" });
			})
			.finally(() => {
				updateState({ isUpdatingStartedAt: false });
			});
	}

	function handleUpdateAcceptedAt(): void {
		updateState({ isUpdatingAcceptedAt: true });
		OrderService.acceptService(props.service.id)
			.then(() => dispatch(fetchDonationOrRemoval(props.intent)(props.service.id)).unwrap())
			.catch(err => {
				console.error("Failed to update service accepted", err);
				snack.enqueueSnackbar("Failed to update accepted at", { variant: "error" });
			})
			.finally(() => {
				updateState({ isUpdatingAcceptedAt: false });
			});
	}

	function handleUpdateCompletedAt(): void {
		updateState({ isUpdatingCompletedAt: true });
		OrderService.completeService(props.service.id)
			.then(() => dispatch(fetchDonationOrRemoval(props.intent)(props.service.id)).unwrap())
			.catch(err => {
				console.error("Failed to update service completed", err);
				snack.enqueueSnackbar("Failed to update completed at", { variant: "error" });
			})
			.finally(() => {
				updateState({ isUpdatingCompletedAt: false });
			});
	}

	return (
		<Box flex>
			<FormField
				name="started_at"
				label="Started At"
				help="date / time service began (items picked up)"
			>
				<Box direction="row" fill="horizontal">
					<TextInput
						plain
						value={parseTimestampFromUTC(props.service.started_at, timezone)}
						disabled
						name="started_at"
					/>
					{!props.service.started_at && (
						<Button
							icon={state.isUpdatingStartedAt ? <Spinner /> : <FormCheckmark color="green" />}
							disabled={state.isUpdatingStartedAt}
							hoverIndicator
							onClick={handleUpdateStartedAt}
						/>
					)}
				</Box>
			</FormField>
			{(props.service as DTO<Donation>)?.accepted_at && (
				<FormField
					name="accepted_at"
					label="Accepted At"
					help="date / time donation accepted by store (items dropped off)"
				>
					<Box direction="row" fill="horizontal">
						<TextInput
							plain
							value={parseTimestampFromUTC((props.service as DTO<Donation>).accepted_at, timezone)}
							disabled
							name="accepted_at"
						/>
						{!(props.service as DTO<Donation>).accepted_at && (
							<Button
								icon={state.isUpdatingAcceptedAt ? <Spinner /> : <FormCheckmark color="green" />}
								disabled={state.isUpdatingAcceptedAt}
								hoverIndicator
								onClick={handleUpdateAcceptedAt}
							/>
						)}
					</Box>
				</FormField>
			)}
			<FormField
				name="completed_at"
				label="Completed At"
				help="date / time service was completed (all items dropped off)"
			>
				<Box direction="row" fill="horizontal">
					<TextInput
						plain
						value={parseTimestampFromUTC(props.service.completed_at, timezone)}
						disabled
						name="completed_at"
					/>
					{!props.service.completed_at && (
						<Button
							icon={state.isUpdatingCompletedAt ? <Spinner /> : <FormCheckmark color="green" />}
							disabled={state.isUpdatingCompletedAt}
							hoverIndicator
							onClick={handleUpdateCompletedAt}
						/>
					)}
				</Box>
			</FormField>
		</Box>
	);
};

interface ServiceEstimateDetailsProps {
	service: DTO<Service>;
	estimate: DTO<Estimate>;
	invoice?: DTO<ServiceInvoice>;
	onClick?(): void;
}

export const ServiceEstimateDetails: React.FC<ServiceEstimateDetailsProps> = (props) => {
	const { size } = useWindowDimensions();
	return (
		<Box
			border
			hoverIndicator
			onClick={props.onClick}
		>
			<Box gap="small" margin="small">
				<Grid columns={{ count: size === "small" ? 1 : 2, size: "auto" }} gap="small">
					<LocalDateField
						label="Created At"
						value={props.estimate.created_at}
					/>
					<FormField
						label="Customer Amount"
					>
						<TextInput
							readOnly
							disabled
							value={formatCurrency(props.estimate.total_amount)}
						/>
					</FormField>
					{props.invoice && (
						<Fragment>
							<FormField
								label="Vendor Price"
							>
								<TextInput
									readOnly
									disabled
									value={formatCurrency(props.invoice.vendor_price)}
								/>
							</FormField>
							<FormField
								label="Tip Amount"
							>
								<TextInput
									readOnly
									disabled
									value={formatCurrency(props.invoice.tip_amount)}
								/>
							</FormField>
						</Fragment>
					)}
				</Grid>
			</Box>
		</Box>
	);
};

interface ServicePaymentDetailsProps {
	service: DTO<Service>;
	payment: DTO<Payment>;
}

export const ServicePaymentDetails: React.FC<ServicePaymentDetailsProps> = (props) => {
	const dispatch = useAppDispatch();

	return (
		<Box
			border
			hoverIndicator
			onClick={() => {
				dispatch(push(`/admin/dashboard/payments/${props.payment.id}`));
			}}
		>
			<Box direction="row" margin="small" gap="small">
				<FormField
					label="Amount"
				>
					<TextInput
						readOnly
						disabled
						value={props.payment.amount ?? ""}
					/>
				</FormField>
				<FormField
					label="Authorized"
					contentProps={{ border: undefined }}
				>
					<CheckBox
						readOnly
						checked={props.payment.authorized}
					/>
				</FormField>
				<FormField
					label="Captured"
					contentProps={{ border: undefined }}
				>
					<CheckBox
						readOnly
						checked={props.payment.captured}
					/>
				</FormField>
			</Box>
		</Box>
	);
};

interface UpdateInvoiceModalProps {
	service: DTO<Service>;
	invoice: DTO<ServiceInvoice>;
	onClose(): void;
	isUpdating: boolean;
	onUpdate(serviceId: string, invoiceId: string, data: Partial<ServiceInvoice>): void;
}

export const UpdateInvoiceModal: React.FC<UpdateInvoiceModalProps> = (props) => {
	const [ formState, setFormState ] = useState({
		tipAmount: props.invoice.tip_amount ?? 0,
		vendorPrice: props.invoice.vendor_price ?? 0
	});

	function handleUpdate(): void {
		props.onUpdate(props.service.id, props.invoice.id, {
			vendor_price: formState.vendorPrice,
			tip_amount: formState.tipAmount
		});
	}

	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box flex margin="medium" gap="medium">
				<Form
					value={formState}
					onChange={(nextValue) => setFormState({ ...nextValue })}
				>
					<FormField
						name="vendorPrice"
						label="Vendor Price"
					>
						<TextInput
							name="vendorPrice"
						/>
					</FormField>
					<FormField
						name="tipAmount"
						label="Tip Amount"
					>
						<TextInput
							name="tipAmount"
						/>
					</FormField>
				</Form>
				<Box flex align="end">
					<LoadingButton
						primary
						label="Save Changes"
						onClick={handleUpdate}
						isLoading={props.isUpdating}
					/>
				</Box>
			</Box>
		</Modal>
	);
};


interface ServiceEstimateProps {
	intent: ProductIntent;
	service: DTO<Service>;
	invoices: DTO<ServiceInvoice>[];
	onReloadInvoices(): void;
}

export const ServiceEstimates: React.FC<ServiceEstimateProps> = (props) => {
	const [ estimates, setEstimates ] = useState<DTO<Estimate>[]>([]);

	const [ selectedInvoice, setSelectedInvoice ] = useState<string | null>(null);
	const [ isUpdatingInvoice, setIsUpdatingInvoice ] = useState<boolean>(false);

	useEffect(() => {
		setEstimates(props.service.estimates ?? []);
	}, [ props.service ]);

	function updateInvoice(invoiceId: string, data: Partial<ServiceInvoice>) {
		setIsUpdatingInvoice(true);
		OrderService.updateServiceInvoice(props.service.id, invoiceId, data).then((updated) => {
			props.onReloadInvoices();
		}).catch(err => {
			console.error("Failed to update", err);
		}).finally(() => {
			setIsUpdatingInvoice(false);
		});
	}

	const renderItems = useCallback((items: DTO<ServiceInvoice>[]) => {
		return (
			<Grid columns={{ count: 1, size: "auto" }} gap="small">
				{items.map(invoice => (
					<ServiceEstimateDetails
						key={invoice.id}
						estimate={invoice.estimate}
						service={props.service}
						invoice={invoice}
						onClick={() => setSelectedInvoice(invoice.id)}
					/>
				))}
			</Grid>
		);
	}, [ props.service, estimates ]);

	return (
		<Box gap="small" flex="grow">
			{selectedInvoice && (
				<UpdateInvoiceModal
					service={props.service}
					invoice={props.invoices.find(invoice => invoice.id === selectedInvoice) as DTO<ServiceInvoice>}
					isUpdating={isUpdatingInvoice}
					onClose={() => setSelectedInvoice(null)}
					onUpdate={(serviceId: string, invoiceId: string, data: Partial<ServiceInvoice>) => updateInvoice(invoiceId, data)}
				/>
			)}
			<Pagination
				items={props.invoices}
				pageSize={5}
				renderItems={renderItems}
			/>
		</Box>
	);
};

interface ServicePaymentsProps {
	intent: ProductIntent;
	service: DTO<Service>;
}

export const ServicePayments: React.FC<ServicePaymentsProps> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const [ payments, setPayments ] = useState<DTO<Payment>[]>([]);

	useEffect(() => {
		setPayments(props.service.payments ?? []);
	}, [ props.service ]);

	const renderItems = useCallback((items: DTO<Payment>[]) => {
		return (
			<Grid columns={{ count: 1, size: "auto" }} gap="small">
				{items.map(payment => (
					<ServicePaymentDetails
						key={payment.id}
						payment={payment}
						service={props.service}
					/>
				))}
			</Grid>
		);
	}, [ props.service, payments ]);

	return (
		<Box gap="small" flex="grow">
			<Pagination
				items={payments}
				pageSize={5}
				renderItems={renderItems}
			/>
		</Box>
	);
};

interface UploadDonationMediaModalProps {
	onClose(): void;
	donation: DTO<Donation>;
}

export const UploadDonationMediaModal: React.FC<UploadDonationMediaModalProps> = (props) => {
	const snack = useSnackbar();
	const [ media, setMedia ] = useState<DTO<DonationMedia>[]>([]);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isUploading, setIsUploading ] = useState(false);
	const [ context, setContext ] = useState<DonationMediaContext | undefined>();

	useEffect(() => {
		setIsLoading(true);
		OrderService.listDonationMedia(props.donation.id)
			.then(result => {
				setMedia([ ...result ]);
			})
			.catch(err => {
				console.error("Failed to load donation media", err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	function handleUploadMedia(mediaToUpload: DTO<Media>[]): void {
		if(!context) {
			return;
		}

		const media = mediaToUpload as DTO<DonationMedia>[];
		setIsUploading(true);

		Promise.all(media.map(item => {
			item.donation_context = context;
			return OrderService.putDonationMedia(props.donation.id, item);
		}))
			.then(result => {
				setMedia([
					...result
				]);
			})
			.catch(err => {
				console.error("failed uploading donation media", err);
				snack.enqueueSnackbar("Failed to upload media", { variant: "error" });
			})
			.finally(() => {
				setIsUploading(false);
			});
	}

	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box margin="small">
				<Loader visible={isLoading}>
					<FormField
						label="Document Type"
						name="context"
						onChange={(event) => {
							setContext(asEnum(DonationMediaContext, event.target.value));
						}}
					>
						<Select
							name="context"
							value={context ?? undefined}
							options={Object.values(DonationMediaContext)}
						/>
					</FormField>
					<Dropzone
						isUploading={isUploading}
						media={media}
						disabled={!context || isUploading}
						onMediaAdded={handleUploadMedia.bind(this)}
						onMediaRemoved={(media) => {
							//TODO: Remove donation media
						}}
					/>
				</Loader>
			</Box>
		</Modal>
	);
};