import { Customer, DTO, User, UserRole } from "../../types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { FrontendConfig, RelationExpand } from "@rego-app/common";
import { ConfigService } from "../services/config";
import { AuthService, CustomerService, updateBaseUrl } from "../services";

export interface ApplicationState {
	isLoggedIn: boolean;
	isLoaded: boolean;
	requiresLogin: boolean;
	requiresRegistration: boolean;
	user: DTO<User> | null;
	customer: DTO<Customer> | null;
	hasFatalError: boolean;
	fatalErrorMessage: string;
	isDemoMode: boolean;
	config?: FrontendConfig;
	forceHideFooter: boolean;
	forceHideHeader: boolean;
}

const initialState: ApplicationState = {
	isLoaded: false,
	isLoggedIn: false,
	requiresLogin: false,
	requiresRegistration: false,
	user: null,
	customer: null,
	hasFatalError: false,
	fatalErrorMessage: "",
	isDemoMode: false,
	forceHideFooter: false,
	forceHideHeader: false
};

export const fetchFrontendConfig = createAsyncThunk<FrontendConfig, void, { state: RootState; }>(
	"application/fetchConfig",
	async (_, thunk) => {
		return await ConfigService.getConfig();
	}
);

export const fetchCustomer = createAsyncThunk<DTO<Customer>, { expand?: RelationExpand<Customer>; }, { state: RootState; }>(
	"application/fetchCustomer",
	async (data, thunk) => {
		return await CustomerService.getCustomerSelf(data.expand);
	}
);

export const logout = createAsyncThunk<void, void, { state: RootState; }>(
	"application/logout",
	async (_, { dispatch }) => {
		await AuthService.logout();
		dispatch(setLogout());
	}
);

export const applicationSlice = createSlice({
	name: "application",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setLogin: (state, action: PayloadAction<{ user: DTO<User>, customer: DTO<Customer> | null; }>) => {
			state.user = action.payload.user;
			state.customer = action.payload.customer ?? state.customer ?? null;
			state.isLoggedIn = true;
			state.requiresLogin = false;
			state.requiresRegistration = false;

			//initializeFeatures(
			//	action.payload.user,
			//	action.payload.customer ?? undefined
			//);
		},
		setLogout: (state) => {
			state.user = null;
			state.isLoggedIn = false;
			state.customer = null;
		},
		setLoaded: (state) => {
			state.isLoaded = true;
		},
		setRequiresLogin: (state, action: PayloadAction<boolean>) => {
			state.requiresLogin = action.payload;
		},
		setRequiresRegistration: (state, action: PayloadAction<boolean>) => {
			state.requiresRegistration = action.payload;
		},
		setFatalError: (state, action: PayloadAction<string>) => {
			state.hasFatalError = true;
			state.fatalErrorMessage = action.payload;
		},
		setDemoMode: (state, action: PayloadAction<boolean>) => {
			state.isDemoMode = action.payload;
		},
		setForceHideFooter: (state, action: PayloadAction<boolean>) => {
			state.forceHideFooter = action.payload;
		},
		setForceHideHeader: (state, action: PayloadAction<boolean>) => {
			state.forceHideHeader = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchFrontendConfig.fulfilled, (state, action: PayloadAction<FrontendConfig>) => {
			updateBaseUrl(action.payload.host);
			state.config = action.payload;
		});
		builder.addCase(fetchFrontendConfig.rejected, (state) => {
			state.hasFatalError = true;
			state.fatalErrorMessage = "There was an issue during application startup";
		});
		builder.addCase(fetchCustomer.fulfilled, (state, action: PayloadAction<DTO<Customer>>) => {
			state.customer = action.payload;
		});
	}
});

export const {
	setLogin,
	setLogout,
	setLoaded,
	setDemoMode,
	setFatalError,
	setRequiresLogin,
	setRequiresRegistration,
	setForceHideFooter,
	setForceHideHeader
} = applicationSlice.actions;

export const selectConfig = (state: RootState): FrontendConfig | undefined => state.application.config;
export const selectDemoMode = (state: RootState): boolean => state.application.isDemoMode;
export const selectCustomer = (state: RootState): DTO<Customer> | null => state.application.customer;
export const selectUser = (state: RootState): DTO<User> | null => state.application.user;
export const selectIsLoaded = (state: RootState): boolean => state.application.isLoaded;
export const selectIsLoggedIn = (state: RootState): boolean => state.application.isLoggedIn && !!state.application.customer;
export const selectIsAdmin = (state: RootState): boolean => state.application.user?.role === UserRole.ADMIN;
export const selectRequiresLogin = (state: RootState): boolean => state.application.requiresLogin;
export const selectRequiresRegistration = (state: RootState): boolean => state.application.requiresRegistration;
export const selectFatalError = (state: RootState): { hasFatalError: boolean, fatalErrorMessage: string; } => {
	return {
		fatalErrorMessage: state.application.fatalErrorMessage,
		hasFatalError: state.application.hasFatalError
	};
};

export const selectForceHideFooter = (state: RootState): boolean => state.application.forceHideFooter;
export const selectForceHideHeader = (state: RootState): boolean => state.application.forceHideHeader;

export default applicationSlice.reducer;