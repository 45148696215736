import { Chip, Divider, LinearProgress, SpeedDial, SpeedDialAction, SpeedDialIcon, Select as SelectMui } from "@mui/material";
import { CustomerProduct, DTO, Item, Order, Product, ProductDimensions, ProductIntent, ProductMedia, ProductMediaContext, sanitize, Timezone } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, CheckBox, FileInput, Form, FormField, Grid, Layer, Spinner, TextArea, Text, TextInput, ColumnConfig, DataTable, Heading, Select } from "grommet";
import { Copy, Document, FormClose, User } from "grommet-icons";
import { useSnackbar } from "notistack";
import { Fragment, createRef, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderService, ProductService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchProduct, fetchProductWithExpand, selectActiveProduct, updateProduct } from "../../../app/store/admin";
import { copyToClipboard, fileToMedia, getNumberFormValidations, getStandardFormValidations, parseDateFromUTC, parseTimestampFromUTC } from "../../../helpers";
import { UnionProduct } from "../../../types";
import { ImageCarousel, LocalDateField, ProductItemField, SlimHeading, useAlert } from "../../common";
import { navigateToProduct } from "../routes";
import { ListingDecisionDetails, ListingDecisionModal } from "./listings";
import { Detection, useDetection } from "../../demo/components/ScanDemo";
import { Code } from "@mui/icons-material";

export const RemoveProductModal: React.FC<{ product: DTO<Product>, order: DTO<Order>, onClose: (wasUpdated: boolean) => void; }> = (props) => {
	const snack = useSnackbar();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ includeNotification, setIncludeNotification ] = useState(false);

	function handleRemoveProduct(): void {
		setIsLoading(true);

		OrderService.removeProductFromOrder(
			props.order.id,
			props.product.id,
			includeNotification
		)
			.then(res => {
				props.onClose(true);
			})
			.catch(err => {
				console.error("Failed to remove product", err);
				snack.enqueueSnackbar("Failed to remove product", { variant: "error" });
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	return (
		<Layer
			onClickOutside={() => props.onClose(false)}
			onEsc={() => props.onClose(false)}
		>
			<Box gap="small" margin="medium">
				<Box>
					<SlimHeading level="4">
						Remove Product
					</SlimHeading>
				</Box>
				<Box>
					<Form
						value={{
							includeNotification
						}}
						onChange={(changes) => {
							setIncludeNotification(changes.includeNotification);
						}}
					>
						<FormField
							name="includeNotification"
							contentProps={{ border: undefined }}
						>
							<CheckBox
								name="includeNotification"
								label="Send confirmation email to customer?"
							/>
						</FormField>
					</Form>
				</Box>
				<Box direction="row" justify="between">
					<Button
						label="Cancel"
						onClick={() => props.onClose(false)}
					/>
					<Button
						label="Remove"
						primary
						disabled={isLoading}
						icon={isLoading ? <Spinner /> : undefined}
						onClick={handleRemoveProduct}
					/>
				</Box>
			</Box>
		</Layer>
	);
};

interface AdminProductDetailsState {
	hasFormChanges: boolean;
	isLoadingProduct: boolean;
	isUpdatingProduct: boolean;
	isExpiringProduct: boolean;
	isUploadingMedia: boolean;
	isDeletingMedia?: string | null;
	launchApprovalFlow: boolean;
}

interface ProductFormState extends Partial<DTO<Product>> {
	weight_input?: number | undefined,
	depth_input?: number | undefined,
	width_input?: number | undefined,
	height_input?: number | undefined,
	hasProductWeight: boolean;
	hasProductDimensions: boolean;
	hasProductComparePrice: boolean;
}

export const AdminProductDetailsPage: React.FC = (props) => {
	const alerts = useAlert();
	const params = useParams();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const [ state, setState ] = useState<AdminProductDetailsState>({
		hasFormChanges: false,
		isLoadingProduct: true,
		isExpiringProduct: false,
		isUpdatingProduct: false,
		isUploadingMedia: false,
		isDeletingMedia: null,
		launchApprovalFlow: false
	});

	const [ isRemoving, setIsRemoving ] = useState(false);
	const activeProduct = useAppSelector(selectActiveProduct);
	const [ formState, setFormState ] = useState<ProductFormState>({
		hasProductWeight: false,
		hasProductDimensions: false,
		hasProductComparePrice: false
	});

	useEffect(() => {
		if(activeProduct) {
			setFormState({
				...formState,
				...activeProduct,
				hasProductComparePrice: (!!activeProduct.price_compare),
				hasProductWeight: !!(activeProduct.weight?.unit),
				weight_input: activeProduct.weight?.value ?? undefined,
				hasProductDimensions: !!(activeProduct.dimensions.width || activeProduct.dimensions.height || activeProduct.dimensions.depth),
				depth_input: activeProduct.dimensions?.depth ?? undefined,
				width_input: activeProduct.dimensions?.width ?? undefined,
				height_input: activeProduct.dimensions?.height ?? undefined,
				item_input: activeProduct?.item?.name ?? activeProduct?.item_input ?? ""
			});
		}
	}, [ activeProduct ]);

	function handleCopyToClipboard(value: string) {
		copyToClipboard(value)
			.then(() => {
				snack.enqueueSnackbar(`Copied ${value} to clipboard`, {
					variant: "success"
				});
			});
	}

	function handleFetchProduct(productId: string): void {
		dispatch(fetchProductWithExpand(productId))
			.catch(err => {
				console.error("Error loading product", err);
				snack.enqueueSnackbar("Failed to load product details", { variant: "error" });
			})
			.finally(() => {
				setState((state) => {
					return {
						...state,
						isLoadingProduct: false
					};
				});
			});
	}

	function handleUpdateProduct(product: DTO<Product>, updates: Partial<DTO<Product>>): Promise<DTO<Product>> {
		return dispatch(updateProduct({ productId: product.id, updates }))
			.unwrap()
			.catch(err => {
				console.error("Failed to update product", activeProduct, err);
				throw err;
			});
	}

	function handleDeleteMedia(media: DTO<ProductMedia>): Promise<boolean> {
		if(!activeProduct) {
			return Promise.resolve(false);
		}

		const mediaId = media.id;
		setState({
			...state,
			isDeletingMedia: mediaId
		});

		return alerts.display<boolean>((callback) => (
			<Box margin="small">
				<Box margin="medium">
					<Heading level="4">
						Are you sure you want to delete?
					</Heading>
				</Box>
				<Box direction="row" justify="between">
					<Button color="status-error" primary label="No, cancel" onClick={() => {
						callback(false);
					}} />
					<Button color="status-ok" primary label="Yes, delete" onClick={() => {
						callback(true);
					}} />
				</Box>
			</Box>
		)).then((shouldDelete) => {
			if(shouldDelete) {
				return ProductService.deleteProductMedia(activeProduct.id, mediaId)
					.then(() => dispatch(fetchProduct({ productId: activeProduct.id })))
					.then(res => {
						return true;
					})
					.catch(err => {
						console.error("Failed to delete media");
						return false;
					})
					.finally(() => {
						setState({
							...state,
							isDeletingMedia: null
						});
					});
			}

			return false;
		});
	}


	function handleMediaUploadEvent(event: React.ChangeEvent<HTMLInputElement>): void {
		if(!activeProduct) {
			return;
		}

		setState({
			...state,
			isUploadingMedia: true
		});

		const files: File[] = [];
		if(event.target.files) {
			for(const file of event.target.files) {
				files.push(file);
			}
		}

		Promise.all(files.map(file => fileToMedia<DTO<ProductMedia>>(file, ProductMediaContext.OTHER)))
			.then(media => Promise.all(media.map(m => handleUploadMedia(activeProduct.id, m))))
			.then(() => dispatch(fetchProductWithExpand(activeProduct.id)).unwrap())
			.catch(err => {

			})
			.finally(() => {
				setState({
					...state,
					isUploadingMedia: false
				});
			});
	}

	async function handleUploadMedia(productId: string, media: DTO<ProductMedia>): Promise<void> {
		if(!activeProduct) {
			return;
		}

		setState({
			...state,
			isUploadingMedia: true
		});

		ProductService.putProductMedia(activeProduct.id, media)
			.then(() => dispatch(fetchProductWithExpand(productId)))
			.catch(err => {

			})
			.finally(() => {
				setState({
					...state,
					isUploadingMedia: false
				});
			});
	}

	function handleSaveProductChanges(): void {
		if(!activeProduct) {
			return;
		}

		setState((state) => {
			return {
				...state,
				isUpdatingProduct: true
			};
		});

		handleUpdateProduct(activeProduct, formState)
			.then(() => {
				setState((state) => {
					return {
						...state,
						hasFormChanges: false
					};
				});
			})
			.catch(err => {
				snack.enqueueSnackbar("Failed to save changes", { variant: "error" });
			})
			.finally(() => {
				setState((state) => {
					return {
						...state,
						isUpdatingProduct: false
					};
				});
			});
	}

	useEffect(() => {
		if(params.productId) {
			handleFetchProduct(params.productId);
			return;
		}

		dispatch(push("/admin/dashboard/products"));
	}, []);

	function handleCloseRemoveProductModal(wasUpdated: boolean): void {
		if(!activeProduct || !wasUpdated) {
			setIsRemoving(false);
			return;
		}

		dispatch(fetchProductWithExpand(activeProduct.id)).unwrap()
			.catch(err => {
				console.error("failed to fetch product", err);
			})
			.finally(() => {
				setIsRemoving(false);
			});
	}

	function handleViewCustomer(): void {
		dispatch(push(`/admin/dashboard/customers/${activeProduct?.customer?.id}`));
	}

	const { isReady, detect } = useDetection();
	const [ detections, setDetections ] = useState<Detection[]>([]);
	const elementRef = createRef<HTMLImageElement>();

	useEffect(() => {
		if(!activeProduct) return;
		(async () => {
			if(elementRef.current && isReady) {
				const media = activeProduct.media ?? [];
				for(const image of media) {
					const element = elementRef.current;

					const src = await ProductService.getMediaURL(activeProduct.id, image.id);
					const [ path, query ] = src.split("?");

					element.src = path + "?" + query + "&t=" + Date.now() + "&r=" + Math.random();
					console.log("SRC: ", element.src);

					await new Promise<void>((r, j) => {
						function loaded(event: any): any {
							detect(element).then(detections => {
								console.log("Detections: ", detections);
								setDetections([ ...detections ]);
							}).catch(err => {
								console.error("Failed to detect", err);
							}).finally(() => {
								element.removeEventListener("load", loaded);
								r();
							});
						}

						element.addEventListener("load", loaded);
					});
				}
			}
		})();
	}, [ activeProduct, isReady, elementRef.current ]);

	const bestDetection = useMemo(() => {
		if(detections.length) {
			const best = detections.reduce((prev, curr) => {
				if(curr.score > prev.score) {
					return curr;
				}

				return prev;
			}, detections[ 0 ]);

			return best;
		}

		return null;
	}, [ detections ]);

	return (
		<Box margin="medium" gap="small">
			{(isRemoving && activeProduct) && (
				<RemoveProductModal
					order={activeProduct.order as DTO<Order>}
					product={activeProduct}
					onClose={handleCloseRemoveProductModal}
				/>
			)}
			{state.isLoadingProduct && (
				<LinearProgress />
			)}
			<img
				alt=""
				id="image-preview"
				ref={elementRef}
				crossOrigin="anonymous"
				style={{ display: "none" }}
			/>
			<SpeedDial
				ariaLabel="SpeedDial"
				sx={{ position: "fixed", bottom: 16, right: 16 }}
				icon={<SpeedDialIcon />}
			>
				{activeProduct?.order && (
					<SpeedDialAction
						icon={<Document />}
						tooltipTitle={"View Order"}
						onClick={() => {
							dispatch(push(`/admin/dashboard/orders/${activeProduct?.order?.id}`));
						}}
					/>
				)}
				{activeProduct?.customer && (
					<SpeedDialAction
						icon={<User />}
						tooltipTitle={"View Customer"}
						onClick={handleViewCustomer}
					/>
				)}
				{!activeProduct?.removed && (
					<SpeedDialAction
						color="primary"
						icon={<FormClose />}
						tooltipTitle={"Remove Product"}
						onClick={() => {
							setIsRemoving(true);
						}}
					/>
				)}
			</SpeedDial>
			{activeProduct && (
				<Box style={{ minHeight: "400px", maxHeight: "600px" }} gap="small">
					<Box align="center" justify="center" fill>
						<ImageCarousel
							fit="contain"
							width={"100%"}
							height={"100%"}
							product={activeProduct}
							handleDeleteMedia={handleDeleteMedia}
						/>
					</Box>
					<Box>
						<FileInput
							multiple={true}
							///@ts-ignore
							onChange={handleMediaUploadEvent}
						/>
					</Box>
				</Box>
			)}
			<Box gap="medium">
				<Form
					value={formState}
					onChange={(state) => {
						setState((state) => {
							return {
								...state,
								hasFormChanges: true
							};
						});
						setFormState((oldState) => {
							return {
								...oldState,
								...sanitize(state, [ "item" ]),
								weight: ((oldState.weight_input) && {
									unit: "pounds",
									value: oldState.weight_input
								}) || undefined,
								dimensions: ((oldState.depth_input || oldState.width_input || oldState.height_input) && {
									unit: "inches",
									depth: oldState.depth_input,
									width: oldState.width_input,
									height: oldState.height_input
								} as ProductDimensions) || undefined
							};
						});
					}}
					onSubmit={handleSaveProductChanges}
					validate="submit"
				>
					<Box gap="small">
						<FormField
							name="id"
							label="Product Id"
						>
							<Box direction="row">
								<TextInput
									plain
									height={"100%"}
									value={activeProduct?.id}
									disabled
									name="id"
								/>
								<Button
									icon={<Copy />}
									hoverIndicator
									onClick={() => {
										handleCopyToClipboard(activeProduct?.id ?? "");
									}}
								/>
							</Box>
						</FormField>
						<FormField
							name="number"
							label="Product Number"
						>
							<TextInput
								readOnly
								disabled
								name="number"
								value={activeProduct?.number}
							/>
						</FormField>
						{bestDetection && (
							<Box gap="small">
								<Text weight="bold">Item Predictions</Text>
								<Box direction="row" gap="small">
									<Chip
										icon={<Code />}
										label={bestDetection.name}
									/>
									{(bestDetection.score > .50)
										? (
											<Chip
												label="Donate"
												icon={(<Text>{Math.round(bestDetection.score * 100)}%</Text>)}
											/>
										)
										: (
											<Chip
												label="Disposal"
												icon={(<Text>{100 - Math.round(bestDetection.score * 100)}%</Text>)}
											/>
										)
									}
								</Box>
							</Box>
						)}
						{bestDetection && (
							<Box gap="small">
								<Text weight="bold">Estimated Materials</Text>
								<SelectMui
									multiple
									disabled
									value={bestDetection.materials}
									renderValue={(selected) => (
										<Box gap="small" direction="row">
											{selected.map((value) => (
												<Chip key={value} label={value} />
											))}
										</Box>
									)}
								/>
							</Box>
						)}
						<LocalDateField
							label="Submitted At"
							value={activeProduct?.order?.ordered_at}
						/>
						<FormField
							label="Market"
						>
							<TextInput
								readOnly
								disabled
								value={activeProduct?.market?.code}
							/>
						</FormField>
						<FormField
							label="Goal"
						>
							<TextInput
								readOnly
								disabled
								value={activeProduct?.goal}
							/>
						</FormField>
						<FormField
							label="Intent"
						>
							<TextInput
								readOnly
								disabled
								value={activeProduct?.intent}
							/>
						</FormField>
						<FormField
							label="Disposition"
						>
							<TextInput
								readOnly
								disabled
								value={activeProduct?.disposition}
							/>
						</FormField>
						<FormField
							label="Donated"
							contentProps={{ border: undefined }}
						>
							<CheckBox
								readOnly
								disabled
								checked={activeProduct?.donated ?? false}
							/>
						</FormField>
						{activeProduct?.donated && (
							<LocalDateField
								label="Donated At"
								value={activeProduct?.donated_at}
							/>
						)}
						{activeProduct?.donated_to && (
							<FormField
								label="Donated To"
							>
								<TextInput
									readOnly
									disabled
									value={activeProduct.donated_to.name}
								/>
							</FormField>
						)}
						{(activeProduct?.service && activeProduct?.intent === ProductIntent.DONATE) && (
							<Box align="start">
								<Button
									label="View Donation"
									primary
									onClick={() => {
										dispatch(push(`/admin/dashboard/donations/${activeProduct.service?.id}`));
									}}
								/>
							</Box>
						)}
						<FormField
							label="Disposed"
							contentProps={{ border: undefined }}
						>
							<CheckBox
								readOnly
								disabled
								checked={activeProduct?.disposed ?? false}
							/>
						</FormField>
						{activeProduct?.disposed_at && (
							<LocalDateField
								label="Disposed At"
								value={activeProduct?.disposed_at}
							/>
						)}
						{activeProduct?.disposed_to && (
							<FormField
								label="Disposed To"
							>
								<TextInput
									readOnly
									disabled
									value={activeProduct.disposed_to.name}
								/>
							</FormField>
						)}
						{(activeProduct?.service && activeProduct?.intent === ProductIntent.JUNK) && (
							<Box align="start">
								<Button
									label="View Removal"
									primary
									onClick={() => {
										dispatch(push(`/admin/dashboard/removals/${activeProduct.service?.id}`));
									}}
								/>
							</Box>
						)}
						{/* <FormField
							label="Purchased"
							contentProps={{ border: undefined }}
						>
							<CheckBox
								readOnly
								disabled
								checked={activeProduct?.purchased ?? false}
							/>
						</FormField>
						{(activeProduct?.purchased && activeProduct?.purchased_by) && (
							<FormField
								label="Purchased By"
							>
								<Box direction="row">
									<TextInput
										readOnly
										disabled
										plain
										height={"100%"}
										value={`${activeProduct.purchased_by.first_name} ${activeProduct.purchased_by.last_name}`}
									/>
									<Button
										icon={<User />}
										hoverIndicator
										onClick={() => {
											if(activeProduct.purchased_by) {
												dispatch(push(`/admin/dashboard/customers/${activeProduct.purchased_by.id}`));
											}
										}}
									/>
								</Box>
							</FormField>
						)}
						{activeProduct?.purchased && (
							<LocalDateField
								label="Purchased At"
								value={activeProduct?.purchased_at}
							/>
						)}
						{activeProduct?.purchase && (
							<Box align="start">
								<Button
									primary
									label="View Purchase"
									onClick={() => {
										dispatch(push(`/admin/dashboard/purchases/${activeProduct.purchase?.id}`));
									}}
								/>
							</Box>
						)} */}
						<Divider />
						<Box align="center" margin="small">
							<Text weight="bold">
								Make Changes Below
							</Text>
						</Box>
						<FormField
							label="Title"
							name="title"
						>
							<TextInput
								name="title"
							/>
						</FormField>
						<FormField
							label="Description"
							name="description"
						>
							<TextArea
								name="description"
							/>
						</FormField>
						<ProductItemField
							name="item_input"
							label="Item Type"
							onSuggestionSelected={(item) => {
								setFormState((state) => {
									return {
										...state,
										item: item as Item
									};
								});
							}}
						/>
						<FormField
							name="intent"
							label="Product Intent"
						>
							<Select
								name="intent"
								options={Object.values(ProductIntent)}
							/>
						</FormField>
						<FormField
							name="price"
							label="Product Price"
							validate={[
								...getStandardFormValidations(),
								...getNumberFormValidations()
							]}
						>
							<TextInput
								name="price"
							/>
						</FormField>
						<FormField
							name="hasProductComparePrice"
							contentProps={{ border: undefined }}
						>
							<CheckBox
								name="hasProductComparePrice"
								label={<Text weight="bold">Has Compare Price?</Text>}
							/>
						</FormField>
						{formState.hasProductComparePrice && (
							<FormField
								name="price_compare"
								label="Compare Price"
								validate={[
									...getStandardFormValidations(),
									...getNumberFormValidations()
								]}
							>
								<TextInput
									name="price_compare"
								/>
							</FormField>
						)}
						<FormField
							name="hasProductWeight"
							contentProps={{ border: undefined }}
						>
							<CheckBox
								name="hasProductWeight"
								label={<Text weight="bold">Has Product Weight?</Text>}
							/>
						</FormField>
						{formState.hasProductWeight && (
							<FormField
								label="Weight"
								name="weight_input"
								info="pounds (lbs)"
								validate={[
									...getStandardFormValidations(),
									...getNumberFormValidations()
								]}
							>
								<TextInput
									name="weight_input"
									inputMode="numeric"
								/>
							</FormField>
						)}
						<FormField
							name="hasProductDimensions"
							contentProps={{ border: undefined }}
						>
							<CheckBox
								name="hasProductDimensions"
								label={<Text weight="bold">Has Product Dimensions?</Text>}
							/>
						</FormField>
						{formState.hasProductDimensions && (
							<Grid columns={{ count: 3, size: "auto" }} gap="small">
								<FormField
									label="Height"
									name="height_input"
									info="inches"
									validate={[
										...getStandardFormValidations(),
										...getNumberFormValidations()
									]}
								>
									<TextInput
										name="height_input"
										inputMode="numeric"
									/>
								</FormField>
								<FormField
									label="Width"
									name="width_input"
									info="inches"
									validate={[
										...getStandardFormValidations(),
										...getNumberFormValidations()
									]}
								>
									<TextInput
										name="width_input"
										inputMode="numeric"
									/>
								</FormField>
								<FormField
									label="Depth"
									name="depth_input"
									info="inches"
									validate={[
										...getStandardFormValidations(),
										...getNumberFormValidations()
									]}
								>
									<TextInput
										name="depth_input"
										inputMode="numeric"
									/>
								</FormField>
							</Grid>
						)}
						<Box align="end" justify="end" flex>
							<Button
								type="submit"
								label="Save Changes"
								icon={state.isUpdatingProduct ? <Spinner /> : undefined}
								disabled={state.isUpdatingProduct || !state.hasFormChanges}
							/>
						</Box>
					</Box>
				</Form>
				<Divider />
				{activeProduct && (
					<Fragment>
						<ListingDecisionDetails
							product={activeProduct}
						/>
						<Box align="start" gap="small">
							{!activeProduct?.reviewed && (
								<Button
									primary
									label="Start Product Approval"
									onClick={() => {
										setState({
											...state,
											launchApprovalFlow: true
										});
									}}
								/>
							)}
							{!activeProduct.removed && (
								<Button
									primary
									label="Expire Product"
									onClick={() => {
										setState({
											...state,
											launchApprovalFlow: true,
											isExpiringProduct: true
										});
									}}
								/>
							)}
						</Box>
						{state.launchApprovalFlow && (
							<ListingDecisionModal
								onClose={() => {
									setState({
										...state,
										launchApprovalFlow: false,
										isExpiringProduct: false
									});
								}}
								isExpirationFlow={state.isExpiringProduct}
							/>
						)}
					</Fragment>
				)}
			</Box>
		</Box>
	);
};


const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code;

const recentProductColumns: ColumnConfig<DTO<CustomerProduct>>[] = [
	{
		property: "number",
		header: "Product Number",
		primary: true,
		sortable: true,
		render: (product) => product.number
	},
	{
		property: "order_number",
		header: "Order Number",
		primary: false,
		sortable: true,
		render: (product) => product.order?.number
	},
	{
		property: "order_cutoff_date",
		header: "Cutoff Date",
		primary: false,
		sortable: true,
		render: (product) => parseDateFromUTC(product.remove_scheduled_for, timezone)
	},
	{
		property: "submitted_at",
		header: "Submitted At",
		primary: false,
		sortable: true,
		render: (product) => parseTimestampFromUTC(product.submitted_at, timezone)
	},
	{
		property: "intent",
		header: "Intent",
		primary: false,
		sortable: true,
		render: (product) => product.intent
	},
	{
		property: "disposition",
		header: "Disposition",
		primary: false,
		sortable: true,
		render: (product) => product.disposition
	}
];

interface AdminProductsState {
	isLoading: boolean;
	loadedProducts: boolean;
	isLoadingUrgentProducts: boolean;
	loadedUrgentProducts: boolean;
	isModalVisible: boolean;
	showProduct: boolean;
	activeProduct?: DTO<UnionProduct>;
}

export const AdminProductsPage: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [ state, setState ] = useState<AdminProductsState>({
		isLoading: false,
		isLoadingUrgentProducts: false,
		showProduct: false,
		isModalVisible: false,
		loadedProducts: false,
		loadedUrgentProducts: false
	});

	const [ urgentProducts, setUrgentProducts ] = useState<DTO<CustomerProduct>[]>([]);

	function handleNavigate(product: DTO<CustomerProduct>): void {
		navigateToProduct(product.id, dispatch);
	}

	// useEffect(() => {
	// 	setState({ ...state, isLoading: true, loadedProducts: false });

	// 	dispatch(listProducts({ filters: { limit: 100 } as Partial<Product> }))
	// 		.catch(err => {
	// 			console.error(`Failed to fetch recent products`, err);
	// 			snack.enqueueSnackbar("Failed to load recent products", {
	// 				variant: "error"
	// 			});
	// 		})
	// 		.finally(() => {
	// 			setState(state => {
	// 				return { ...state, loadedProducts: true };
	// 			});
	// 		});
	// }, []);

	useEffect(() => {
		setState({ ...state, isLoading: true, loadedUrgentProducts: false });

		ProductService.listUrgentProducts()
			.then(products => {
				setUrgentProducts([ ...products ]);
			})
			.catch(err => {
				console.error(`Failed to fetch urgent products`, err);
				snack.enqueueSnackbar("Failed to load urgent products", {
					variant: "error"
				});
			})
			.finally(() => {
				setState(state => {
					return { ...state, loadedUrgentProducts: true };
				});
			});
	}, []);

	useEffect(() => {
		if(state.isLoading) {
			if(/*state.loadedProducts && */state.loadedUrgentProducts) {
				setState({
					...state,
					isLoading: false
				});
			}
		}
	}, [ state.loadedProducts, state.isLoading, state.loadedUrgentProducts ]);

	return (
		<Box margin="small" gap="large">
			{/* <Box gap="small">
				<Box>
					<SlimHeading level="3">Recent Products</SlimHeading>
					<Box>
						{(state.isLoading || !state.loadedProducts) && (
							<LinearProgress />
						)}
						<DataTable
							pad="small"
							columns={recentProductColumns}
							data={products}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box> */}
			<Box gap="small">
				<Box>
					<Box gap="small">
						<SlimHeading level="3">Urgent Products</SlimHeading>
						<Text>cutoff date is approaching</Text>
					</Box>
					<Box>
						{(state.isLoading || !state.loadedUrgentProducts) && (
							<LinearProgress />
						)}
						<DataTable
							pad="small"
							columns={recentProductColumns}
							data={urgentProducts}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};