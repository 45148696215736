import { createContext } from "react";
import { useGoogleTagManager } from "../hooks";

export * from "./alert";
export * from "./application";
export * from "./google";

export const GlobalContext = createContext({});

export const GlobalProvider: React.FC = (props) => {
	useGoogleTagManager();
	return (
		<GlobalContext.Provider value={{}}>
			{props.children}
		</GlobalContext.Provider>
	);
};