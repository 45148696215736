import { LinearProgress } from "@mui/material";
import { DTO, Store } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, ColumnConfig, DataTable, FormField, Grid, TextArea, TextInput } from "grommet";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchStore, listStores, selectActiveStore, selectStores } from "../../../app/store/admin";
import { CopyToClipboardField, Loader, MapComponent, MapMarker, SlimHeading, useWindowDimensions } from "../../common";
import { StoreHoursList } from "../components";
import { navigateToStore } from "../routes";
import moment from "moment";

interface StoreDetailsState {
	isLoadingStore: boolean;
	isUpdatingStore: boolean;
}

export const AdminStoreDetailsPage: React.FC = (props) => {
	const params = useParams();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();

	const store = useAppSelector(selectActiveStore);

	const [state, setState] = useState<StoreDetailsState>({
		isLoadingStore: false,
		isUpdatingStore: false
	});

	const storeContact = useMemo(() => {
		if(store && store.contacts?.length > 0) {
			return store.contacts[0];
		}

		return null;
	}, [store]);

	const exceptionClosedDate = useMemo(() => {
		if(store && store.hours?.exceptions?.length > 0) {
			return store.hours.exceptions.filter(value => {
				return !value.open && moment(value.date).unix() > moment().unix();
			});
		}

		return [];
	}, [store]);

	const columns = useMemo(() => {
		switch(dimensions.size) {
			case "small": {
				return 1;
			}
			case "medium": {
				return 1;
			}
			case "large": {
				return 2;
			}
			case "xlarge": {
				return 2;
			}
		}
	}, [dimensions.size]);

	function handleFetchStore(storeId: string): void {
		setState({
			...state,
			isLoadingStore: true
		});

		dispatch(fetchStore(storeId)).unwrap()
			.catch(err => {
				console.error("Failed to load store", err);
				snack.enqueueSnackbar("Failed to load store", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isLoadingStore: false
				});
			});
	}

	useEffect(() => {
		if(!params.storeId) {
			dispatch(push("/admin/dashboard/stores"));
			return;
		}

		handleFetchStore(params.storeId);
	}, []);

	const markers = useMemo((): MapMarker[] => {
		if(store && store.address) {
			return [{ latitude: Number(store.address.latitude), longitude: Number(store.address.longitude) }];
		}

		return [];
	}, [store]);

	return (
		<Loader visible={!store}>
			{store && (
				<Box margin="large" gap="small">
					<Grid columns={{ count: columns, size: "auto" }} gap="small">
						<Box gap="small" flex>
							<SlimHeading level="3">
								Store Details
							</SlimHeading>
							<Box>
								<CopyToClipboardField
									name="id"
									label="Store Id"
									value={store.id}
								/>
								<FormField
									name="service"
									label="Store Type"
								>
									<TextInput
										name="service"
										readOnly
										disabled
										value={store.service}
									/>
								</FormField>
							</Box>
						</Box>
						<Box flex>
							<Box align="center" justify="center" fill>
								<MapComponent
									markers={markers}
									requestCurrentLocation={false}
								/>
							</Box>
						</Box>
						<Box gap="small" flex>
							<SlimHeading level="3">
								Store Hours
							</SlimHeading>
							<Box>
								<StoreHoursList hours={store.hours} />
							</Box>
							{exceptionClosedDate.length > 0 && (
								<Box gap="small">
									<SlimHeading level="3">
										Store is Also Closed On
									</SlimHeading>
									<Box gap="small">

									</Box>
								</Box>
							)}
						</Box>
						<Box gap="small">
							<SlimHeading level="3">
								Store Contact
							</SlimHeading>
							<Box gap="small">
								<Box gap="small">
									<SlimHeading level="4">Drop-Off Instructions</SlimHeading>
									<TextArea
										rows={4}
										value={store.delivery_instructions}
									/>
								</Box>
								<Box gap="small">
									<FormField
										label="Contact Person"
									>
										<TextInput
											value={storeContact ? [storeContact.first_name, storeContact.last_name].filter(v => !!v).join(" ") : ""}
										/>
									</FormField>
									<FormField
										label="Contact Email"
										readOnly
									>
										<TextInput
											value={storeContact?.email_address ?? ""}
										/>
									</FormField>
									<FormField
										label="Contact Phone"
										readOnly
									>
										<TextInput
											value={storeContact?.phone_number ?? ""}
										/>
									</FormField>
								</Box>
							</Box>
						</Box>
					</Grid>
				</Box>
			)}
		</Loader>
	);
};

const storeColumns: ColumnConfig<DTO<Store>>[] = [
	{
		property: "name",
		header: "Store Name",
		primary: true,
		sortable: true,
		render: (store) => store.name
	},
	{
		property: "type",
		header: "Store Type",
		primary: false,
		sortable: true,
		render: (store) => store.service
	}
];

interface AdminStorePageState {
	isLoading: boolean;
	loadedStores: boolean;
}

export const AdminStorePage: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [state, setState] = useState<AdminStorePageState>({
		isLoading: false,
		loadedStores: false
	});

	const stores = useAppSelector(selectStores);

	function handleNavigate(store: DTO<Store>): void {
		navigateToStore(store.id, dispatch);
	}

	useEffect(() => {
		setState({ ...state, isLoading: true, loadedStores: false });

		dispatch(listStores({}))
			.catch(err => {
				console.error(`Failed to fetch stores`, err);
				snack.enqueueSnackbar("Failed to load stores", {
					variant: "error"
				});
			})
			.finally(() => {
				setState(state => {
					return { ...state, loadedStores: true };
				});
			});
	}, []);

	useEffect(() => {
		if(state.loadedStores) {
			setState({
				...state,
				isLoading: false
			});
		}
	}, [state.loadedStores, state.isLoading]);

	return (
		<Box margin="small" gap="large">
			<Box gap="small">
				<Box>
					<SlimHeading level="3">Stores</SlimHeading>
					<Box>
						{(state.isLoading || !state.loadedStores) && (
							<LinearProgress />
						)}
						<DataTable
							pad="small"
							columns={storeColumns}
							data={stores}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};