import { Select, SelectProps } from "grommet";
import moment from "moment";
import { useMemo } from "react";

export const pickupWindows: Array<{ label: string, from: number, to: number; }> = [
	{
		from: 8,
		to: 9,
		label: "08:00 AM - 09:00 AM"
	},
	{
		from: 9,
		to: 10,
		label: "09:00 AM - 10:00 AM"
	},
	{
		from: 10,
		to: 11,
		label: "10:00 AM - 11:00 AM"
	},
	{
		from: 11,
		to: 12,
		label: "11:00 AM - 12:00 PM"
	},
	{
		from: 12,
		to: 13,
		label: "12:00 PM - 01:00 PM"
	},
	{
		from: 13,
		to: 14,
		label: "01:00 PM - 02:00 PM"
	},
	{
		from: 14,
		to: 15,
		label: "02:00 PM - 03:00 PM"
	},
	{
		from: 15,
		to: 16,
		label: "03:00 PM - 04:00 PM"
	},
	{
		from: 16,
		to: 17,
		label: "04:00 PM - 05:00 PM"
	},
	{
		from: 17,
		to: 18,
		label: "05:00 PM - 06:00 PM"
	}
];

export const extendedPickupWindows: PickupWindowType[] = [
	...pickupWindows,
	{
		from: 8,
		to: 12,
		label: "08:00 AM - 12:00 PM"
	},
	{
		from: 12,
		to: 16,
		label: "12:00 PM - 04:00 PM"
	},
	{
		from: 16,
		to: 20,
		label: "04:00 PM - 08:00 PM"
	}
];

type TimeFormat = `${number}${number}:${number}${number}`;

export type PickupWindowType = typeof pickupWindows[0];

interface PickupWindowProps extends Partial<SelectProps> {
	minimumStartTime?: string | undefined;
	maximumEndTime?: string | undefined;
	windows?: typeof pickupWindows;
}

export const PickupWindow: React.FC<Partial<PickupWindowProps>> = (props) => {
	const windows = useMemo(() => {
		return (props.windows ?? pickupWindows).filter(w => {
			if(props.minimumStartTime) {
				const hours = Number(props.minimumStartTime.split(":")[0]);
				const minutes = Number(props.minimumStartTime.split(":")[1]);

				if(moment().hours(w.from).minutes(0).seconds(0).milliseconds(0).unix() < moment().hours(hours).minutes(minutes).seconds(0).milliseconds(0).unix()) {
					return false;
				}
			}

			if(props.maximumEndTime) {
				const hours = Number(props.maximumEndTime.split(":")[0]);
				const minutes = Number(props.maximumEndTime.split(":")[1]);

				if(moment().hours(w.to).minutes(0).seconds(0).milliseconds(0).unix() > moment().hours(hours).minutes(minutes).seconds(0).milliseconds(0).unix()) {
					return false;
				}
			}

			return true;
		});
	}, [props.minimumStartTime, props.maximumEndTime, props.windows]);

	return (
		<Select
			name="window"
			{...props}
			options={windows.map(w => w.label)}
		/>
	);
};