import { Customer, DTO } from "@rego-app/common";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { push } from "connected-react-router";
import { Box, Button, Form, Spinner } from "grommet";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { CustomerService } from "../../../app/services";
import { useAppDispatch } from "../../../app/store";
import { Loader, Modal, SlimHeading } from "../../common";
import { StripeProvider, useStripeProvider } from "../../payment/provider";

export const PaymentStatusController: React.FC = (props) => {
	const stripe = useStripeProvider();

	return (
		<Elements stripe={stripe.stripePromise}>
			{props.children}
		</Elements>
	);
};

interface PaymentStatusProps {
	onClose: () => void;
	customer: DTO<Customer>;
}

export const PaymentStatus: React.FC<PaymentStatusProps> = (props) => {
	const stripe = useStripe();
	const dispatch = useAppDispatch();
	const [hasError, setHasError] = useState(false);
	const [wasLoaded, setWasLoaded] = useState(false);
	const [message, setMessage] = useState<string | null>(null);

	useEffect(() => {
		console.debug("GOT STRIPE!", stripe);
		if(!stripe) {
			return;
		}

		// Retrieve the "setup_intent_client_secret" query parameter appended to
		// your return_url by Stripe.js
		const clientSecret = new URLSearchParams(window.location.search).get(
			"setup_intent_client_secret"
		);
		console.debug("GOT CLIENT SECRET", clientSecret);

		if(!clientSecret) {
			dispatch(push("/account"));
			return;
		}

		// Retrieve the SetupIntent
		stripe
			.retrieveSetupIntent(clientSecret)
			.then(({ setupIntent }) => {
				console.debug("GOT SETUP INTENT", setupIntent);
				// Inspect the SetupIntent `status` to indicate the status of the payment
				// to your customer.
				//
				// Some payment methods will [immediately succeed or fail][0] upon
				// confirmation, while others will first enter a `processing` state.
				//
				// [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
				switch(setupIntent?.status) {
					case "succeeded":
						setMessage("Success! Your payment method has been saved.");
						break;

					case "processing":
						setMessage("Processing payment details. We'll update you when processing is complete.");
						break;

					case "requires_payment_method":
						// Redirect your user back to your payment page to attempt collecting
						// payment again
						setHasError(true);
						setMessage("Failed to process payment details. Please try another payment method.");
						break;
				}

				setWasLoaded(true);
			});
	}, [stripe]);

	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
		>
			<Loader visible={!wasLoaded}>
				<Box margin="medium">
					{hasError
						? (
							<Box>
								<CreatePaymentMethodForm
									clientSecret={new URLSearchParams(window.location.search).get("setup_intent_client_secret") ?? ""}
									returnURL={`${window.location.protocol}//${window.location.host}/account/payment-methods`}
									message={message ?? ""}
								/>
							</Box>
						)
						: (
							<Box align="start">
								<SlimHeading level="4">{message}</SlimHeading>
							</Box>
						)}
				</Box>
			</Loader>
		</Modal>
	);

};

export const CreatePaymentMethodForm: React.FC<{ clientSecret: string, returnURL: string, message?: string; }> = (props) => {
	const stripe = useStripe();
	const elements = useElements();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(props.message ?? null);

	const handleSubmit = async (event: React.FormEvent) => {
		setIsSubmitting(true);
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if(!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const { error } = await stripe.confirmSetup({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url: props.returnURL
			}
		});

		if(error) {
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Show error to your customer (for example, payment
			// details incomplete)
			setErrorMessage(error.message ?? "");
		} else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}

		setIsSubmitting(false);
	};

	return (
		<Form
			onSubmit={handleSubmit}
		>
			<Box gap="small">
				<PaymentElement />
				<Button
					disabled={isSubmitting}
					icon={isSubmitting ? <Spinner /> : undefined}
					type="submit"
					label="Submit"
				/>
				<Box>
					{errorMessage && (
						<SlimHeading level="4" color="red">{errorMessage}</SlimHeading>
					)}
				</Box>
			</Box>
		</Form>
	);
};

export const CreatePaymentMethodController: React.FC<{ clientSecret: string, errorMessage?: string; }> = (props) => {
	const stripe = useStripeProvider();

	const elementsOptions: StripeElementsOptions = {
		clientSecret: props.clientSecret
	};

	return (
		<Elements stripe={stripe.stripePromise} options={elementsOptions}>
			{props.children}
		</Elements>
	);
};

interface CreatePaymentMethodProps {
	customer: DTO<Customer>;
	returnURL: string;
	onClose: () => void;
}


export const CreatePaymentMethod: React.FC<CreatePaymentMethodProps> = (props) => {
	const snack = useSnackbar();
	const [clientSecret, setClientSecret] = useState("");

	function createPaymentMethodIntent(): void {
		CustomerService.createPaymentMethodIntent(props.customer.id)
			.then(result => {
				setClientSecret(result);
			})
			.catch(err => {
				console.error("Failed to create setup intent", err);
				snack.enqueueSnackbar("We ran into an issue getting your information", { variant: "error" });
			});
	}

	useEffect(() => {
		createPaymentMethodIntent();
	}, []);

	return (
		<Modal
			onEsc={props.onClose}
			onClickOutside={props.onClose}
			onClickClose={props.onClose}
		>
			<Loader visible={!clientSecret}>
				<StripeProvider>
					<CreatePaymentMethodController clientSecret={clientSecret}>
						<CreatePaymentMethodForm
							clientSecret={clientSecret}
							returnURL={props.returnURL}
						/>
					</CreatePaymentMethodController>
				</StripeProvider>
			</Loader>
		</Modal>
	);
};