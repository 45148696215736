import { Badge } from "@mui/material";
import { CarbonEquivalent, CustomerProduct, DTO, Product, ProductMedia, ProductQuery, Store, StoreProduct } from "@rego-app/common";
import { push } from "connected-react-router";
import { Anchor, Avatar, Box, Button, Card, CardBody, CardHeader, Grid, InfiniteScroll, Spinner, Stack, Text, Image, Layer, CardFooter, FormField, Select, TextInput, Form } from "grommet";
import { Cart, FormSubtract, Money, Search } from "grommet-icons";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useMemo, useState } from "react";
import { ProductService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { addProductToCart, setShouldBeginCheckout } from "../../../app/store/purchase";
import { ImageCarousel, ImageItemFullViewModal, Link, SlimHeading, useWindowDimensions } from "../../common";
import { useCart } from "../hooks";
import { AddToCartButton } from "./CartIcon";
import VisibilitySensor from "react-visibility-sensor";
import "../pages/scroll.css";
import { selectFilteredCategories, selectFilteredStores, selectPriceHighFilter, selectPriceLowFilter, setFilters, setPriceHighFilter, setPriceLowFilter } from "../../../app/store/shop";
import { fetchItems, selectItems } from "../../../app/store/reference";
import { getOptionalNumberFormValidations, sortPrimaryMedia } from "../../../helpers";
import Fuse from "fuse.js";

export const DefaultProductAction: React.FC<{ product: DTO<Product>; }> = (props) => {
	const dispatch = useAppDispatch();
	const [ isLoading, setIsLoading ] = useState(false);

	function handleBuyNow(): void {
		setIsLoading(true);
		dispatch(addProductToCart(props.product)).unwrap()
			.catch(err => {
				console.error("failed to add product to card", props.product, err);
			})
			.then(() => {
				dispatch(setShouldBeginCheckout(true));
				dispatch(push("/shop/cart"));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}
	return (
		<Box justify="between" margin="small" direction="row">
			<Box gap="small">
				<AddToCartButton
					product={props.product}
				/>
			</Box>
			<Box align="center" justify="center">
				<Button
					label="Buy Now"
					disabled={isLoading}
					icon={isLoading ? <Spinner /> : undefined}
					primary
					onClick={handleBuyNow}
				/>
			</Box>
		</Box>
	);
};

interface ImpactScorecardProps {
	carbonEquivalent: CarbonEquivalent;
	onClose: () => void;
}

export const ImpactScorecard: React.FC<ImpactScorecardProps> = (props) => {
	function parseNumber(input: number): number {
		return Math.round(input);
	}

	const percentageGasolineSavings = useMemo(() => {
		const { manufacture, transportation } = props.carbonEquivalent.gasoline_equivalent;
		return Math.round(
			(-1 * (transportation - manufacture) / manufacture) * 100
		);
	}, [ props.carbonEquivalent ]);

	return (
		<Layer
			onEsc={props.onClose}
			onClickOutside={props.onClose}
			style={{ maxWidth: "500px" }}
		>
			<Box margin="medium" gap="medium">
				<Box gap="small">
					<Text>
						The average {props.carbonEquivalent.type} uses the equivalent of <Text weight="bold">{parseNumber(props.carbonEquivalent.gasoline_equivalent.manufacture)} gallons of gasoline</Text> to manufacture.
					</Text>
					<Text>
						Buying used instead of new reduces that impact by about <Text weight="bold">{percentageGasolineSavings}%</Text>.
					</Text>
				</Box>
				<Box align="end">
					<Button
						label="Close"
						onClick={props.onClose}
					/>
				</Box>
			</Box>
		</Layer>
	);
};

interface ProductCardProps {
	product: DTO<Product>;
	footer?: JSX.Element;
}

export const ProductCard: React.FC<ProductCardProps> = (props) => {
	const dispatch = useAppDispatch();
	const [ showImpactScorecard, setShowImpactScorecard ] = useState(false);
	const hasComparePrice = useMemo(() => {
		return isNaN(Number(props.product.price_compare)) ? false : Number(props.product.price_compare) > 0;
	}, [ props.product.price_compare ]);

	function handleShowImpactScorecard(): void {
		if(props.product.item) {
			setShowImpactScorecard(true);
		}
	}

	return (
		<Card fill>
			{(showImpactScorecard && props.product.item?.carbon_equivalent) && (
				<ImpactScorecard
					carbonEquivalent={props.product.item.carbon_equivalent}
					onClose={() => {
						setShowImpactScorecard(false);
					}}
				/>
			)}
			<CardHeader fill="horizontal">
				<ImageCarousel
					width={"100%"}
					product={props.product}
				/>
			</CardHeader>
			<CardBody>
				<Box gap="small" margin="small" align="start" fill="vertical">
					<Text className="text-max-2-lines" weight="bold">
						{props.product.title}
					</Text>
					<ProductDescriptionAnchor
						productId={props.product.id}
						description={props.product.description}
					/>
					<Box flex justify="end" fill="horizontal">
						<Box direction="column" align="start">
							<SlimHeading level="4">${props.product.price}</SlimHeading>
							{hasComparePrice && (
								<Text>Was: <Text style={{ textDecoration: "line-through" }}>${props.product.price_compare}</Text></Text>
							)}
						</Box>
						{props.product.brand && (
							<Text>
								Brand: <Text weight="bold">{props.product.brand.name}</Text>
							</Text>
						)}
						{props.product.condition && (
							<Text>
								Condition: <Text weight="bold">{props.product.condition}</Text>
							</Text>
						)}
						{props.children}
					</Box>
				</Box>
			</CardBody>
			<CardFooter background="accent-1">
				{props.footer}
			</CardFooter>
		</Card>
	);
};

interface ProductGalleryProps {
	step: number;
	hasNextPage: boolean;
	products: DTO<Product>[];
	fetchNextProducts?: () => void;
	endOfGalleryMessage?: string;
}


interface SoldByStoreFooterProps {
	store: DTO<Store>;
}

export const SoldByStoreFooter: React.FC<SoldByStoreFooterProps> = (props) => {
	return (
		<Box pad="small" background="accent-1">
			<Link target="_blank" color="white" to={`/s/${props.store.slug}`}>Sold by {props.store.name}</Link>
		</Box>
	);
};

interface ProductFiltersProps {
	hideSoldBy?: boolean;
}

export const ProductFilters: React.FC<ProductFiltersProps> = (props) => {
	const items = useAppSelector(selectItems);
	const dispatch = useAppDispatch();
	const [ state, setState ] = useState<ProductQuery>({});

	function handleUpdateFilters(): void {
		dispatch(setFilters(state));
	}

	useEffect(() => {
		dispatch(fetchItems());
	}, []);

	const options = useMemo(() => {
		const filtered = items.map(i => i.short_name).filter(v => !!v);
		return filtered.filter((value, index) => {
			return index === filtered.indexOf(value);
		});
	}, [ items ]);

	return (
		<Box flex margin="small">
			<Form
				onSubmit={handleUpdateFilters}
			>
				{!props.hideSoldBy && (
					<FormField
						name="store"
						label="Sold By"
					>
						<Select
							name="store"
							options={[]}
						/>
					</FormField>
				)}
				<FormField
					name="category"
					label="Category"
				>
					<Select
						name="category"
						multiple
						closeOnChange={false}
						options={options}
						value={state.categories ?? []}
						onChange={({ option }) => {
							if(!state.categories) {
								setState({
									...state,
									categories: [ option ]
								});

								return;
							}

							if(state.categories.includes(option)) {
								setState({
									...state,
									categories: [ ...state.categories.filter(c => c !== option) ]
								});
							}
							else {
								setState({
									...state,
									categories: [ ...state.categories, option ]
								});
							}
						}}
					/>
				</FormField>
				<FormField
					name="price_low"
					label="Min Price"
					value={state.priceGreaterThan}
					onChange={(event) => {
						setState({
							...state,
							priceGreaterThan: event.target.value as unknown as number
						});
					}}
					validate={[
						...getOptionalNumberFormValidations()
					]}
				>
					<TextInput
						name="price_low"
						inputMode="numeric"
						icon={<Text weight="bold">$</Text>}
					/>
				</FormField>
				<FormField
					name="price_high"
					label="Max Price"
					value={state.priceLessThan}
					onChange={(event) => {
						setState({
							...state,
							priceLessThan: event.target.value as unknown as number
						});
					}}
					validate={[
						...getOptionalNumberFormValidations()
					]}
				>
					<TextInput
						name="price_high"
						inputMode="numeric"
						icon={<Text weight="bold">$</Text>}
					/>
				</FormField>
				<Box margin="small" align="center">
					<Button
						primary
						type="submit"
						label="Update"
					/>
				</Box>
			</Form>
		</Box>
	);
};

export const ProductGallery: React.FC<ProductGalleryProps> = (props) => {
	const [ search, setSearch ] = useState("");

	const fuseOptions: Fuse.IFuseOptions<DTO<Product>> = {
		keys: [
			"name",
			"title",
			"item.name",
			"item.short_name",
			"item.keywords",
			"item_input",
		],
		threshold: 0.2,
		ignoreLocation: true,
		ignoreFieldNorm: true
	};

	const filteredProducts = useMemo(() => {
		if(search) {
			const fuse = new Fuse(props.products, fuseOptions);
			return fuse.search(search).map(r => r.item);
		}

		return props.products;
	}, [ props.products, search ]);

	return (
		<Box gap="medium">
			<Box align="start">
				<FormField
					name="search"
					label="Search"
					value={search}
					onChange={(event) => {
						setSearch(event.target.value);
					}}
				>
					<TextInput
						name="search"
						icon={
							<Search />
						}
					/>
				</FormField>
			</Box>
			<Grid columns={{ size: "auto", count: props.step }} gap="medium">
				<InfiniteScroll items={filteredProducts} onMore={(props.hasNextPage && props.fetchNextProducts) ? props.fetchNextProducts : undefined} step={props.step}>
					{(product: DTO<StoreProduct & CustomerProduct>) => (
						<Box key={product.id}>
							<ProductCard
								product={product}
								footer={product.store ? (
									<SoldByStoreFooter store={product.store} />
								) : undefined}
							>
								<DefaultProductAction
									product={product}
								/>
							</ProductCard>
						</Box>
					)}
				</InfiniteScroll>
			</Grid>
			{props.endOfGalleryMessage && (
				<Box align="center">
					<SlimHeading level="4">{props.endOfGalleryMessage}</SlimHeading>
				</Box>
			)}
		</Box>
	);
};

interface MobileCartButtonProps {
	product: DTO<Product>;
}

export const MobileCartButton: React.FC<MobileCartButtonProps> = (props) => {
	const cart = useCart();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const [ isLoading, setIsLoading ] = useState(false);

	const isInCart = useMemo(() => {
		return cart.isInCart(props.product);
	}, [ cart.products, props.product ]);

	function handleNavigateToCart(): void {
		dispatch(push("/shop/cart"));
	}

	function displayErrorMessage(): void {
		snack.enqueueSnackbar(
			<Text>We ran into an issue updating your information</Text>, {
			variant: "error"
		});
	}

	function displayAddedToCartMessage(): void {
		snack.enqueueSnackbar(
			<Text>Successfully added to cart. <Anchor onClick={handleNavigateToCart} color="white">Checkout now?</Anchor></Text>, {
			variant: "success"
		});
	}

	function displayRemovedFromCartMessage(): void {
		snack.enqueueSnackbar(
			<Text>Successfully removed from cart.</Text>, {
			variant: "success"
		});
	}

	function handleRemoveProductFromCart(): void {
		if(!isInCart) {
			return;
		}

		setIsLoading(true);
		cart.removeProductFromCart(props.product)
			.then((result) => {
				if(result) {
					displayRemovedFromCartMessage();
				}
				else {
					throw new Error("Failed to add product to cart");
				}
			})
			.catch(err => {
				displayErrorMessage();
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	function handleAddProductToCart(): void {
		if(isInCart) {
			return;
		}

		setIsLoading(true);
		cart.addProductToCart(props.product)
			.then((result) => {
				if(result) {
					displayAddedToCartMessage();
				}
				else {
					throw new Error("Failed to add product to cart");
				}
			})
			.catch(err => {
				displayErrorMessage();
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	return (
		<Box margin="small" style={{ zIndex: 1 }}>
			<Stack>
				<Box align="center" justify="center">
					<Button
						icon={(
							<Avatar background="brand">
								<Badge badgeContent={
									<Text weight="bold" size="large" color={isInCart ? "red" : "green"}>
										{isInCart ? "-" : "+"}
									</Text>
								}>
									<Cart color="white" />
								</Badge>
							</Avatar>
						)}
						onClick={isInCart ? handleRemoveProductFromCart : handleAddProductToCart}
						hoverIndicator
						disabled={isLoading}
					/>
				</Box>
				{isLoading && (
					<Box align="center" justify="center" fill>
						<Spinner size="medium" color="white" />
					</Box>
				)}
			</Stack>
		</Box>
	);
};

interface MobileBuyNowButtonProps {
	product: DTO<Product>;
}

export const MobileBuyNowButton: React.FC<MobileBuyNowButtonProps> = (props) => {
	const cart = useCart();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const [ isLoading, setIsLoading ] = useState(false);

	const isInCart = useMemo(() => {
		return cart.isInCart(props.product);
	}, [ cart, props.product ]);

	function handleNavigateToCart(): void {
		dispatch(push("/shop/cart"));
	}

	function displayErrorMessage(): void {
		snack.enqueueSnackbar(
			<Text>We ran into an issue updating your information</Text>, {
			variant: "error"
		});
	}

	function handleBuyNow(): void {
		if(isInCart) {
			dispatch(setShouldBeginCheckout(true));
			handleNavigateToCart();
			return;
		}

		setIsLoading(true);
		dispatch(addProductToCart(props.product)).unwrap()
			.then(() => {
				dispatch(setShouldBeginCheckout(true));
				handleNavigateToCart();
			})
			.catch(err => {
				console.error("failed to add product to card", props.product, err);
				displayErrorMessage();
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	return (
		<Box margin="small" style={{ zIndex: 1 }}>
			<Stack>
				<Box align="center" justify="center">
					<Button
						icon={(
							<Avatar background="brand">
								<Money color="white" />
							</Avatar>
						)}
						onClick={handleBuyNow}
						hoverIndicator
						disabled={isLoading}
					/>
				</Box>
				{isLoading && (
					<Box align="center" justify="center" fill>
						<Spinner size="medium" color="white" />
					</Box>
				)}
			</Stack>
		</Box>
	);
};

interface MobileProductCardProps {
	product: DTO<Product>;
	shouldLoad: boolean;
}

export const MobileProductCard: React.FC<MobileProductCardProps> = (props) => {
	const dispatch = useAppDispatch();
	const [ wasLoaded, setWasLoaded ] = useState(false);

	useEffect(() => {
		if(props.shouldLoad) {
			setWasLoaded(true);
		}
	}, [ props.shouldLoad ]);
	const hasComparePrice = useMemo(() => {
		return isNaN(Number(props.product.price_compare)) ? false : Number(props.product.price_compare) > 0;
	}, [ props.product.price_compare ]);

	return (
		<Card fill round={false}>
			<CardBody fill>
				<Box flex>
					<Stack fill>
						<Box fill>
							<Box flex align="end" justify="end">
								<MobileBuyNowButton
									product={props.product}
								/>
								<MobileCartButton
									product={props.product}
								/>
							</Box>
						</Box>
						{(props.shouldLoad || wasLoaded) && (
							<MediaScrollContainer
								product={props.product}
							/>
						)}
					</Stack>
				</Box>
				<Box>
					<Box background="brand" style={{ opacity: ".6" }}>
						<Box margin="medium" gap="small">
							<Box>
								<SlimHeading
									color="white"
									level="3">${props.product.price}</SlimHeading>
								{hasComparePrice && (
									<Text color="white" weight="bold">Was: <Text weight="bold" style={{ textDecoration: "line-through" }}>${props.product.price_compare}</Text></Text>
								)}
							</Box>
							<Anchor
								color="white"
								weight={"bolder"}
								label={props.product.description}
								style={{ opacity: "1" }}
								onClick={() => {
									dispatch(push(`/shop/products/${props.product.id}`));
								}}
							/>
						</Box>
					</Box>
				</Box>
			</CardBody>
			{/* <CardHeader>

			</CardHeader>
			<CardBody>
				<Box gap="small" margin="small" align="start" fill="vertical">
					<Anchor
						label={props.product.description}
					/>
					<Box direction="column" align="start">
						<SlimHeading level="4">${props.product.price}</SlimHeading>
						{hasComparePrice && (
							<Text>Was: <Text style={{ textDecoration: "line-through" }}>${props.product.price_compare}</Text></Text>
						)}
					</Box>
					<Box flex justify="end" fill="horizontal">
						{props.children}
					</Box>
				</Box>
			</CardBody> */}
		</Card>
	);
};



interface MediaScrollContainerProps {
	product: DTO<Product>;
}

export const MediaScrollContainer: React.FC<MediaScrollContainerProps> = (props) => {
	const elementId = "media-scroll-container" as const;
	const dimensions = useWindowDimensions();

	const [ viewFull, setViewFull ] = useState(false);
	const [ media, setMedia ] = useState<DTO<ProductMedia>[]>([]);
	const [ activeMedia, setActiveMedia ] = useState<DTO<ProductMedia> | null>(null);

	useEffect(() => {
		const values = Array.isArray(props.product.media) ? props.product.media : [];
		setMedia(values.sort(sortPrimaryMedia));
	}, [ props.product ]);

	useEffect(() => {
		if(!media.length) {
			return;
		}

		if(!activeMedia) {
			setActiveMedia({
				...media[ 0 ]
			});
			return;
		}
	}, [ activeMedia, media ]);

	function handleActiveMediaChange(media: DTO<ProductMedia>): void {
		setActiveMedia({ ...media });
	}

	function isActive(item: DTO<ProductMedia>): boolean {
		const index = media.findIndex(m => m.id === item.id);
		if(index === -1) {
			return false;
		}

		if(activeMedia) {
			return activeMedia.id === item.id;
		}

		return index === 0;
	}

	function isNext(item: DTO<ProductMedia>): boolean {
		const index = media.findIndex(m => m.id === item.id);
		if(index === -1) {
			return false;
		}

		if(activeMedia) {
			const activeIndex = media.findIndex(m => m.id === activeMedia.id);
			return activeIndex + 1 === index;
		}

		//no active media (should be second item)
		return index === 1;
	}

	function isPrevious(item: DTO<ProductMedia>): boolean {
		const index = media.findIndex(m => m.id === item.id);
		if(index === -1) {
			return false;
		}

		if(activeMedia) {
			const activeIndex = media.findIndex(m => m.id === activeMedia.id);
			return activeIndex - 1 === index;
		}

		//no active media ... false
		return false;
	}

	return (
		<Stack fill>
			{(viewFull) && (
				<ImageItemFullViewModal
					product={props.product}
					media={media}
					onClose={() => {
						setViewFull(false);
					}}
				/>
			)}
			<Box fill="vertical" justify="end" align="center" style={{ width: dimensions.width }}>
				<Box margin="small" gap="small" direction="row">
					{media.map((media, index) => (
						<FormSubtract
							key={media.id}
							size="large"
							color={activeMedia ? activeMedia?.id === media.id ? "brand" : "grey" : index === 0 ? "brand" : "grey"}
							style={{ zIndex: 1 }}
						/>
					))}
				</Box>
			</Box>
			<Box
				id={elementId}
				fill
			>
				<Box
					fill
					style={{
						width: dimensions.width,
						overflow: "auto",
						position: "relative",
						scrollSnapType: "x mandatory",
						WebkitOverflowScrolling: "touch"
					}}
					align="start"
				>
					<Box
						fill
						direction="row"
					>
						{media.map((media, index) => (
							<MediaScrollElement
								key={`${props.product.id}-${media.id}`}
								media={media}
								product={props.product}
								shouldLoad={activeMedia ? activeMedia.id === media.id : index === 0}
								reportActiveChild={handleActiveMediaChange}
								isActive={isActive(media)}
								isNext={isNext(media)}
								isPrevious={isPrevious(media)}
							/>
						))}
					</Box>
				</Box>
			</Box>
		</Stack>
	);

	// return (
	// 	<Stack fill>
	// 		<div className="example-wrapper" style={{ width: "100%", height: "100%" }}>
	// 			<div
	// 				className="container x-mandatory align-start"
	// 				style={{ height: "100%" }}
	// 			>
	// 				<Box className="wrapper" direction="row" fill="vertical">
	// 					{media.map(media => (
	// 						<MediaScrollElement
	// 							key={`${props.product.id}-${media.id}`}
	// 							media={media}
	// 							product={props.product}
	// 							shouldLoad={true}
	// 						/>
	// 					))}
	// 				</Box>
	// 			</div>
	// 		</div>
	// 	</Stack>
	// );
};

interface MediaScrollElementProps {
	product: DTO<Product>;
	media: DTO<ProductMedia>;
	shouldLoad: boolean;
	isActive: boolean;
	isNext: boolean;
	isPrevious: boolean;
	reportActiveChild(media: DTO<ProductMedia>): void;
}

export const MediaScrollElement: React.FC<MediaScrollElementProps> = (props) => {
	const elementId = `${props.product.id}-${props.media.id}` as const;
	const dimensions = useWindowDimensions();
	const [ src, setSrc ] = useState("");
	const [ isLoading, setIsLoading ] = useState(false);
	const [ viewFull, setViewFull ] = useState(false);

	useEffect(() => {
		const element = document.getElementById(elementId);
		if(element) {
			element.scrollIntoView({
				inline: "start",
				block: "nearest",
				behavior: "smooth"
			});
		}
	}, [ props.isActive ]);

	useEffect(() => {
		if(!src && !isLoading && (props.isActive || props.isNext)) {
			setIsLoading(true);
			ProductService.getMediaURL(props.product.id, props.media.id)
				.then((url) => {
					setSrc(url);
				})
				.catch(err => {

				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [ props.media, props.isActive, props.isNext, src, isLoading ]);

	function handleVisibilityChange(isVisible: boolean) {
		if(isVisible) {
			props.reportActiveChild(props.media);
		}
	}

	return (
		<Fragment>
			<VisibilitySensor
				scrollCheck
				onChange={handleVisibilityChange}
				partialVisibility={false}
			>

				<div className="element media-element" style={{ height: "100%", width: "100%" }}>
					{isLoading
						? (
							<Box
								fill="vertical"
								align="center"
								justify="center"
								style={{
									width: dimensions.width
								}}
							>
								<Spinner
									size="large"
								/>
							</Box>
						)
						: (
							<Image
								src={src}
								fit="cover"
								width={dimensions.width}
								onClick={() => setViewFull(true)}
							/>
						)
					}
				</div>
			</VisibilitySensor>
		</Fragment>
	);
};

export const ProductDescriptionAnchor: React.FC<{
	productId: string;
	description: string;
}> = ({ productId, description }) => {
	const dispatch = useAppDispatch();
	return (
		<Anchor
			className="text-max-4-lines"
			label={description}
			onClick={() => {
				dispatch(push(`/shop/products/${productId}`));
			}}
		/>
	);
};