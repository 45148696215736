import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import type { Customer, DTO, Order, RecentRecord, UnionProduct } from "../../types";
import { AdminService, CustomerService, OrderService, PartnerService, PaymentService, ProductService } from "../services";
import { CustomerProduct, Delivery, Donation, DTOWithoutBase, InboundReferral, OrderDelivery, Payment, Product, ProductIntent, Purchase, PurchaseDelivery, RelationExpand, Removal, Store, StoreProduct } from "@rego-app/common";
import { PurchaseService } from "../services/purchase";
import { DeliveryService } from "../services/delivery";
import moment from "moment-timezone";

export interface AdminState {
	customerHistory: RecentRecord[];
	customers: Customer[];
	customerOrders: DTO<Order>[];
	customerRemovals: DTO<Removal>[];
	customerPurchases: DTO<Purchase>[];
	customerDonations: DTO<Donation>[];
	orderHistory: RecentRecord[];
	removalHistory: RecentRecord[];
	donationHistory: RecentRecord[];
	orders: DTO<Order>[];
	stores: DTO<Store>[];
	removals: DTO<Removal>[];
	donations: DTO<Donation>[];
	purchases: DTO<Purchase>[];
	activeStore?: DTO<Store>;
	activePayment: DTO<Payment> | null;
	activeDelivery: DTO<Delivery> | null;
	activeDonation?: DTO<Donation>;
	activeRemoval?: DTO<Removal>;
	activeCustomer: DTO<Customer> | null;
	productApprovalQueue: DTO<UnionProduct>[];
	activeProduct: DTO<UnionProduct> | null;
	isUpdatingProduct: boolean;
	inboundReferral: DTO<InboundReferral> | null;
	selectedProductsForService: DTO<CustomerProduct>[];
	selectedProductsForDelivery: DTO<UnionProduct>[];
	payments: DTO<Payment>[];
	deliveries: DTO<Delivery>[];
	todaysDeliveries: DTO<Delivery>[];
	products: DTO<UnionProduct>[];
}

const initialState: AdminState = {
	customers: [],
	customerHistory: AdminService.getRecentCustomers(),
	customerOrders: [],
	customerRemovals: [],
	customerDonations: [],
	customerPurchases: [],
	removalHistory: [],
	donationHistory: [],
	orders: [],
	orderHistory: AdminService.getRecentOrders(),
	stores: [],
	removals: [],
	donations: [],
	purchases: [],
	productApprovalQueue: [],
	activePayment: null,
	activeProduct: null,
	activeDelivery: null,
	isUpdatingProduct: false,
	inboundReferral: null,
	selectedProductsForService: [],
	selectedProductsForDelivery: [],
	activeCustomer: null,
	payments: [],
	deliveries: [],
	todaysDeliveries: [],
	products: []
};

export const DEFAULT_ADMIN_PRODUCT_EXPAND: RelationExpand<CustomerProduct & StoreProduct> = [
	"customer",
	"market",
	"order",
	"item",
	"media",
	"approved_by",
	"rejected_by",
	"purchases",
	"donated_to",
	"service",
	"store"
];

export const fetchCustomer = createAsyncThunk<Customer, string, { state: RootState; }>(
	"admin/fetchCustomer",
	async (customerId, thunk) => {
		return await CustomerService.getCustomer(customerId);
	}
);

export const fetchDeliveries = createAsyncThunk<[DTO<Delivery>[], DTO<Delivery>[]], {}, { state: RootState; }>(
	"admin/fetchDeliveries",
	async (_, thunk) => {
		const deliveries = await DeliveryService.listDeliveries({}, []);

		return [
			deliveries,
			deliveries.filter(d => {
				if(!d.window) return false;
				const today = moment().startOf("day");
				const value = moment.tz(d.window.date, d.window.timezone);
				return value.format("YYYY-MM-DD") === today.format("YYYY-MM-DD");
			})
		];
	}
);

export const fetchOrder = createAsyncThunk<DTO<Order>, { orderId: string, expand?: RelationExpand<Order>; }, { state: RootState; }>(
	"admin/fetchOrder",
	async (data, thunk) => {
		return await OrderService.getOrder(data.orderId, data.expand);
	}
);

export const fetchInboundReferral = createAsyncThunk<DTO<InboundReferral> | null, { orderId: string, expand: RelationExpand<InboundReferral>; }, { state: RootState; }>(
	"admin/fetchInboundReferral",
	async (data, thunk) => {
		return await OrderService.getOrderInboundReferral(data.orderId, data.expand);
	}
);

export const fetchPurchase = createAsyncThunk<DTO<Purchase>, { purchaseId: string, expand?: RelationExpand<Purchase>; }, { state: RootState; }>(
	"admin/fetchPurchase",
	async (data, thunk) => {
		return await PurchaseService.getPurchase(data.purchaseId, data.expand);
	}
);

export const fetchPayment = createAsyncThunk<DTO<Payment>, { paymentId: string, expand?: RelationExpand<Payment>; }, { state: RootState; }>(
	"admin/fetchPayment",
	async (data, thunk) => {
		return await PaymentService.getPayment(data.paymentId, ["customer", "estimate", "order", "purchase", "partner", "refunds", "payment_method", "payout", "service"]);
	}
);

export const listOrders = createAsyncThunk<DTO<Order>[], { filters: Partial<Order>, expand?: (keyof DTOWithoutBase<Order>)[]; }, { state: RootState; }>(
	"admin/listOrders",
	async (data, thunk) => {
		return await OrderService.listOrders(data.filters, data.expand);
	}
);

export const listProducts = createAsyncThunk<DTO<UnionProduct>[], { filters: Partial<UnionProduct>, expand?: RelationExpand<UnionProduct>; }, { state: RootState; }>(
	"admin/listProducts",
	async (data, thunk) => {
		return await ProductService.listProductsUnion(data.filters, data.expand);
	}
);

export const listPurchases = createAsyncThunk<DTO<Purchase>[], { filters: Partial<Purchase>, expand?: RelationExpand<Purchase>; }, { state: RootState; }>(
	"admin/listPurchases",
	async (data, thunk) => {
		return await PurchaseService.listPurchases(data.filters, data.expand);
	}
);

export const fetchStore = createAsyncThunk<DTO<Store>, string, { state: RootState; }>(
	"admin/fetchStore",
	async (storeId, thunk) => {
		return await PartnerService.getStore(storeId);
	}
);

export const listDonations = createAsyncThunk<DTO<Donation>[], { filters: Partial<Donation>, expand?: RelationExpand<Donation>; }, { state: RootState; }>(
	"admin/listDonations",
	async (data, _thunk) => {
		return await OrderService.listDonations(data.filters, data.expand);
	}
);

export const listRemovals = createAsyncThunk<DTO<Removal>[], { filters: Partial<Removal>, expand?: RelationExpand<Removal>; }, { state: RootState; }>(
	"admin/listRemovals",
	async (data, _thunk) => {
		return await OrderService.listRemovals(data.filters, data.expand);
	}
);

export const fetchDonationOrRemoval = (intent: ProductIntent) => {
	switch(intent) {
		case ProductIntent.DONATE: {
			return fetchDonation;
		}
		case ProductIntent.JUNK: {
			return fetchRemoval;
		}
		default: {
			throw new Error(`invalid intent [${intent}]`);
		}
	}
};

export const fetchDonation = createAsyncThunk<DTO<Donation>, string, { state: RootState; }>(
	"admin/fetchDonation",
	async (donationId, thunk) => {
		return await OrderService.getService(donationId) as Donation;
	}
);

export const fetchRemoval = createAsyncThunk<DTO<Removal>, string, { state: RootState; }>(
	"admin/fetchRemoval",
	async (removalId, thunk) => {
		return await OrderService.getService(removalId) as Removal;
	}
);

export const fetchProduct = createAsyncThunk<DTO<UnionProduct>, { productId: string, expand?: RelationExpand<Product>; }, { state: RootState; }>(
	"admin/fetchProduct",
	async (data, thunk) => {
		return await ProductService.getUnionProduct(data.productId, data.expand);
	}
);

export const fetchProductWithExpand = createAsyncThunk<DTO<UnionProduct>, string, { state: RootState; }>(
	"admin/fetchProduct",
	async (productId, thunk) => {
		return await ProductService.getUnionProduct(productId, DEFAULT_ADMIN_PRODUCT_EXPAND);
	}
);

export const updateProduct = createAsyncThunk<DTO<UnionProduct>, { productId: string, updates: Partial<DTO<Product>>; }, { state: RootState; }>(
	"admin/updateProduct",
	async (data, thunk) => {
		thunk.dispatch(setIsUpdatingProduct(true));
		await ProductService.updateProduct(data.productId, data.updates);
		return thunk.dispatch(fetchProductWithExpand(data.productId)).unwrap();
	}
);

export const rejectProduct = createAsyncThunk<DTO<UnionProduct>, { productId: string, reason: string; }, { state: RootState; }>(
	"admin/rejectProduct",
	async (data, thunk) => {
		thunk.dispatch(setIsUpdatingProduct(true));
		await ProductService.rejectProduct(data.productId, data.reason);
		return thunk.dispatch(fetchProductWithExpand(data.productId)).unwrap();
	}
);

export const expireProduct = createAsyncThunk<DTO<UnionProduct>, { productId: string; }, { state: RootState; }>(
	"admin/expireProduct",
	async (data, thunk) => {
		thunk.dispatch(setIsUpdatingProduct(true));
		await ProductService.expireProduct(data.productId);
		return thunk.dispatch(fetchProductWithExpand(data.productId)).unwrap();
	}
);

export const approveProduct = createAsyncThunk<DTO<UnionProduct>, { productId: string; }, { state: RootState; }>(
	"admin/approveProduct",
	async (data, thunk) => {
		thunk.dispatch(setIsUpdatingProduct(true));
		await ProductService.approveProduct(data.productId);
		return thunk.dispatch(fetchProductWithExpand(data.productId)).unwrap();
	}
);

export const listStores = createAsyncThunk<DTO<Store>[], Partial<Store>, { state: RootState; }>(
	"admin/listStores",
	async (filters, thunk) => {
		return await PartnerService.listStores(filters);;
	}
);

export const listCustomerOrders = createAsyncThunk<DTO<Order>[], { customerId: string, filters: Partial<Order>; }, { state: RootState; }>(
	"admin/listCustomerOrders",
	async (data, thunk) => {
		return await CustomerService.listCustomerOrders(data.customerId, data.filters);
	}
);

export const listCustomerRemovals = createAsyncThunk<DTO<Removal>[], { customerId: string, filters: Partial<Removal>; }, { state: RootState; }>(
	"admin/listCustomerRemovals",
	async (data, thunk) => {
		return await CustomerService.listCustomerRemovals(data.customerId, data.filters);
	}
);

export const listCustomerDonations = createAsyncThunk<DTO<Donation>[], { customerId: string, filters: Partial<Donation>; }, { state: RootState; }>(
	"admin/listCustomerDonations",
	async (data, thunk) => {
		return await CustomerService.listCustomerDonations(data.customerId, data.filters);
	}
);

export const listCustomerPurchases = createAsyncThunk<DTO<Purchase>[], { customerId: string, filters: Partial<Purchase>; }, { state: RootState; }>(
	"admin/listCustomerPurchases",
	async (data, thunk) => {
		return await CustomerService.listCustomerPurchases(data.customerId, data.filters);
	}
);

export const fetchProductApprovalQueue = createAsyncThunk<DTO<UnionProduct>[], void, { state: RootState; }>(
	"admin/fetchProductApprovalQueue",
	async (_, thunk) => {
		return await ProductService.getProductsToReview();
	}
);

export const fetchDelivery = createAsyncThunk<DTO<Delivery>, { deliveryId: string, expand?: RelationExpand<OrderDelivery & PurchaseDelivery>; }, { state: RootState; }>(
	"admin/fetchDelivery",
	async (data, thunk) => {
		return await DeliveryService.getDelivery(data.deliveryId, data.expand);
	}
);

export const fetchDeliveryWithExpand = (deliveryId: string) => {
	return fetchDelivery({ deliveryId, expand: ["attempts", "schedule_history", "products", "partner", "confirmations", "segments"] });
};


export const fetchPayments = createAsyncThunk<DTO<Payment>[], { filters: Partial<DTO<Payment>>; }, { state: RootState; }>(
	"admin/fetchPayments",
	async (data) => {
		return await PaymentService.listPayments(data.filters, ["customer", "estimate", "order", "purchase", "partner", "refunds", "payment_method", "payout"]);
	}
);


export const adminSlice = createSlice({
	name: "admin",
	initialState,
	reducers: {
		setCustomers: (state: typeof initialState, action: PayloadAction<Customer[]>) => {
			state.customers = action.payload;
		},
		openCustomer: (state: typeof initialState, action: PayloadAction<Customer>) => {
			AdminService.recordRecentCustomer(action.payload);
			state.customerHistory = [
				...AdminService.getRecentCustomers()
			];

			const customers = [...state.customers];
			customers.push(action.payload);

			state.customers = customers.filter((customer, index) => {
				return customers.findIndex(c => c.id === customer.id) === index;
			});
		},
		recordOpenedOrder: (state: typeof initialState, action: PayloadAction<DTO<Order>>) => {
			AdminService.recordRecentOrder(action.payload);
			state.orderHistory = [
				...AdminService.getRecentOrders()
			];
		},
		recordOpenedDonation: (state: typeof initialState, action: PayloadAction<DTO<Donation>>) => {
			AdminService.recordRecentDonation(action.payload);
			state.donationHistory = [
				...AdminService.getRecentDonations()
			];
		},
		recordOpenedRemoval: (state: typeof initialState, action: PayloadAction<DTO<Removal>>) => {
			AdminService.recordRecentRemoval(action.payload);
			state.removalHistory = [
				...AdminService.getRecentRemovals()
			];
		},
		setActiveProduct: (state: typeof initialState, action: PayloadAction<DTO<UnionProduct> | null>) => {
			state.activeProduct = action.payload;
		},
		setActiveCustomer: (state: typeof initialState, action: PayloadAction<DTO<Customer> | null>) => {
			state.activeCustomer = action.payload;
		},
		setActiveDelivery: (state: typeof initialState, action: PayloadAction<DTO<Delivery> | null>) => {
			state.activeDelivery = action.payload;
		},
		setIsUpdatingProduct: (state: typeof initialState, action: PayloadAction<boolean>) => {
			state.isUpdatingProduct = action.payload;
		},
		addSelectedProductForDelivery(state: typeof initialState, action: PayloadAction<DTO<UnionProduct>>): void {
			const products = state.selectedProductsForDelivery;
			if(!products.find(p => p.id === action.payload.id)) {
				products.push(action.payload);
				state.selectedProductsForDelivery = [
					...products
				];
			}
		},
		removeSelectedProductForDelivery(state: typeof initialState, action: PayloadAction<DTO<UnionProduct>>): void {
			const products = state.selectedProductsForDelivery.filter(p => p.id !== action.payload.id);
			state.selectedProductsForDelivery = [
				...products
			];
		},
		addSelectedProductForService(state: typeof initialState, action: PayloadAction<DTO<UnionProduct>>): void {
			const products = state.selectedProductsForService;
			if(!products.find(p => p.id === action.payload.id)) {
				products.push(action.payload);
				state.selectedProductsForService = [
					...products
				];
			}
		},
		removeSelectedProductForService(state: typeof initialState, action: PayloadAction<DTO<UnionProduct>>): void {
			const products = state.selectedProductsForService.filter(p => p.id !== action.payload.id);
			state.selectedProductsForService = [
				...products
			];
		},
		resetSelectedProductForService(state: typeof initialState): void {
			state.selectedProductsForService = [

			];
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCustomer.fulfilled, (state, action) => {
				const customers = [...state.customers];
				customers.push(action.payload);

				state.customers = customers.filter((customer, index) => {
					return customers.findIndex(c => c.id === customer.id) === index;
				});

				state.activeCustomer = {
					...action.payload
				};
			})
			.addCase(fetchOrder.fulfilled, (state, action) => {
				const orders = [...state.orders];
				orders.push(action.payload);

				state.orders = orders.filter((order, index) => {
					return orders.findIndex(c => c.id === order.id) === index;
				});
			})
			.addCase(fetchPurchase.fulfilled, (state, action) => {
				const purchases = [...state.purchases];
				purchases.push(action.payload);

				state.purchases = purchases.filter((purchase, index) => {
					return purchases.findIndex(c => c.id === purchase.id) === index;
				});
			})
			.addCase(fetchStore.fulfilled, (state, action) => {
				state.activeStore = action.payload;
			})
			.addCase(fetchDonation.fulfilled, (state, action) => {
				state.activeDonation = action.payload;
			})
			.addCase(fetchRemoval.fulfilled, (state, action) => {
				state.activeRemoval = action.payload;
			})
			.addCase(listStores.fulfilled, (state, action) => {
				state.stores = [
					...action.payload
				];
			})
			.addCase(listOrders.fulfilled, (state, action) => {
				state.orders = [
					...action.payload
				];
			})
			.addCase(listDonations.fulfilled, (state, action) => {
				state.donations = [
					...action.payload
				];
			})
			.addCase(listRemovals.fulfilled, (state, action) => {
				state.removals = [
					...action.payload
				];
			})
			.addCase(listPurchases.fulfilled, (state, action) => {
				state.purchases = [
					...action.payload
				];
			})
			.addCase(listCustomerOrders.fulfilled, (state, action) => {
				state.customerOrders = [
					...action.payload
				];
			})
			.addCase(listCustomerRemovals.fulfilled, (state, action) => {
				state.customerRemovals = [
					...action.payload
				];
			})
			.addCase(listCustomerDonations.fulfilled, (state, action) => {
				state.customerDonations = [
					...action.payload
				];
			})
			.addCase(listCustomerPurchases.fulfilled, (state, action) => {
				state.customerPurchases = [
					...action.payload
				];
			})
			.addCase(fetchProductApprovalQueue.fulfilled, (state, action) => {
				state.productApprovalQueue = [
					...action.payload
				];
			})
			.addCase(fetchProduct.fulfilled, (state, action) => {
				state.activeProduct = action.payload;
				state.isUpdatingProduct = false;
			})
			.addCase(fetchProduct.rejected, (state, action) => {
				state.isUpdatingProduct = false;
			})
			.addCase(approveProduct.fulfilled, (state, action) => {
				state.activeProduct = action.payload;
				state.isUpdatingProduct = false;
			})
			.addCase(rejectProduct.fulfilled, (state, action) => {
				state.activeProduct = action.payload;
				state.isUpdatingProduct = false;
			})
			.addCase(expireProduct.fulfilled, (state, action) => {
				state.activeProduct = action.payload;
				state.isUpdatingProduct = false;
			})
			.addCase(approveProduct.rejected, (state, action) => {
				state.isUpdatingProduct = false;
			})
			.addCase(rejectProduct.rejected, (state, action) => {
				state.isUpdatingProduct = false;
			})
			.addCase(expireProduct.rejected, (state, action) => {
				state.isUpdatingProduct = false;
			})
			.addCase(fetchInboundReferral.fulfilled, (state, action) => {
				state.inboundReferral = action.payload;
			})
			.addCase(fetchDelivery.fulfilled, (state, action) => {
				state.activeDelivery = action.payload;
			})
			.addCase(fetchPayment.fulfilled, (state, action) => {
				state.activePayment = action.payload;
			})
			.addCase(fetchPayments.fulfilled, (state, action) => {
				state.payments = action.payload;
			})
			.addCase(listProducts.fulfilled, (state, action) => {
				state.products = action.payload;
			})
			.addCase(fetchDeliveries.fulfilled, (state, action) => {
				state.deliveries = action.payload[0].sort((a, b) => {
					if(!a.window) {
						return 1;
					}

					if(!b.window) {
						return -1;
					}

					const aMoment = moment(a.window.date).hours(0).minutes(0).seconds(0).milliseconds(0);
					const bMoment = moment(b.window.date).hours(0).minutes(0).seconds(0).milliseconds(0);

					if(aMoment.unix() === bMoment.unix()) {
						return moment(b.updated_at).unix() - moment(a.updated_at).unix();
					}

					return bMoment.unix() - aMoment.unix();
				});

				state.todaysDeliveries = action.payload[1].sort((a, b) => {
					if(!a.window) {
						return 1;
					}

					if(!b.window) {
						return -1;
					}

					const aMoment = moment(a.window.date).hours(0).minutes(0).seconds(0).milliseconds(0);
					const bMoment = moment(b.window.date).hours(0).minutes(0).seconds(0).milliseconds(0);

					if(aMoment.unix() === bMoment.unix()) {
						return moment(b.updated_at).unix() - moment(a.updated_at).unix();
					}

					return bMoment.unix() - aMoment.unix();
				});
			});
	}
});

export const {
	setCustomers,
	openCustomer,
	recordOpenedOrder,
	recordOpenedDonation,
	recordOpenedRemoval,
	setActiveProduct,
	setActiveCustomer,
	setActiveDelivery,
	setIsUpdatingProduct,
	addSelectedProductForDelivery,
	removeSelectedProductForDelivery,
	addSelectedProductForService,
	removeSelectedProductForService,
	resetSelectedProductForService
} = adminSlice.actions;

export const selectCustomers = (state: RootState): Customer[] => state.admin.customers;
export const selectCustomerHistory = (state: RootState): RecentRecord[] => state.admin.customerHistory;
export const selectCustomerOrders = (state: RootState): DTO<Order>[] => state.admin.customerOrders;
export const selectCustomerRemovals = (state: RootState): DTO<Removal>[] => state.admin.customerRemovals;
export const selectCustomerDonations = (state: RootState): DTO<Donation>[] => state.admin.customerDonations;
export const selectCustomerPurchases = (state: RootState): DTO<Purchase>[] => state.admin.customerPurchases;
export const selectOrders = (state: RootState): DTO<Order>[] => state.admin.orders;
export const selectPurchases = (state: RootState): DTO<Purchase>[] => state.admin.purchases;
export const selectActivePurchases = (state: RootState): DTO<Purchase>[] => state.admin.purchases.filter(purchase => {
	return !purchase.expired;
});

export const selectCompletedPurchases = (state: RootState): DTO<Purchase>[] => state.admin.purchases.filter(purchase => {
	return purchase.paid;
});

export const selectActiveDonationOrRemoval = (intent: ProductIntent) => {
	switch(intent) {
		case ProductIntent.DONATE: {
			return selectActiveDonation;
		}
		case ProductIntent.JUNK: {
			return selectActiveRemoval;
		}
		default: {
			throw new Error(`invalid intent [${intent}]`);
		}
	}
};

export const selectActiveServiceProducts = (state: RootState): DTO<CustomerProduct>[] => state.admin.selectedProductsForService;
export const selectActiveDeliveryProducts = (state: RootState): DTO<UnionProduct>[] => state.admin.selectedProductsForDelivery;
export const selectInboundReferral = (state: RootState): DTO<InboundReferral> | null => state.admin.inboundReferral;
export const selectOrderHistory = (state: RootState): RecentRecord[] => state.admin.orderHistory;
export const selectStores = (state: RootState): DTO<Store>[] => state.admin.stores;
export const selectActiveStore = (state: RootState): DTO<Store> | undefined => state.admin.activeStore;
export const selectActivePayment = (state: RootState): DTO<Payment> | null => state.admin.activePayment;
export const selectActiveDonation = (state: RootState): DTO<Donation> | undefined => state.admin.activeDonation;
export const selectActiveRemoval = (state: RootState): DTO<Removal> | undefined => state.admin.activeRemoval;
export const selectActiveCustomer = (state: RootState): DTO<Customer> | null => state.admin.activeCustomer;
export const selectProductApprovalQueue = (state: RootState): DTO<UnionProduct>[] => state.admin.productApprovalQueue;
export const selectActiveProduct = (state: RootState): DTO<UnionProduct> | null => state.admin.activeProduct;
export const selectIsUpdatingProduct = (state: RootState): boolean => state.admin.isUpdatingProduct;

export const selectPayments = (state: RootState): DTO<Payment>[] => state.admin.payments;
export const selectRemovals = (state: RootState): DTO<Removal>[] => state.admin.removals;
export const selectRemovalHistory = (state: RootState): RecentRecord[] => state.admin.removalHistory;
export const selectDonations = (state: RootState): DTO<Donation>[] => state.admin.donations;
export const selectDonationHistory = (state: RootState): RecentRecord[] => state.admin.donationHistory;
export const selectActiveDelivery = (state: RootState): DTO<Delivery> | null => state.admin.activeDelivery;

export const selectProducts = (state: RootState): DTO<UnionProduct>[] => state.admin.products;
export const selectDeliveries = (state: RootState): DTO<Delivery>[] => state.admin.deliveries;
export const selectTodaysDeliveries = (state: RootState): DTO<Delivery>[] => state.admin.todaysDeliveries;

export default adminSlice.reducer;