import { push } from "connected-react-router";
import { Box, Button } from "grommet";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../app/store";
import { SIDEBAR_BUTTONS, SidebarButtonData } from "./sidebar";

interface BottomNavigationButtonProps extends SidebarButtonData {
	selected: boolean;
	onSelected(data: SidebarButtonData): void;
}

export const BottomNavigationButton: React.FC<BottomNavigationButtonProps> = (props) => {
	return (
		<Box
			hoverIndicator={true}
			focusIndicator={false}
			border={{ color: !props.selected ? "brand" : "accent-2", side: "all", size: "small" }}
			onClick={() => {
				props.onSelected(props);
			}}
		>
			<Button
				icon={props.icon}
				size="medium"
				focusIndicator={false}
			/>
		</Box>
	);
};

export const BottomNavigation: React.FC = (props) => {
	const location = useLocation();
	const dispatch = useAppDispatch();

	function handleNavigate(data: SidebarButtonData): void {
		dispatch(push(data.path));
	}

	return (
		<Box background="brand" fill>
			<Box direction="row" gap="small" justify="between" align="around" margin="medium">
				{SIDEBAR_BUTTONS.map(data => (
					<BottomNavigationButton
						key={data.type}
						icon={data.icon}
						label={data.label}
						type={data.type}
						path={data.path}
						selected={data.path === location.pathname}
						onSelected={() => handleNavigate(data)}
					/>
				))}
			</Box>
		</Box>
	);
};