import { Chip } from "@mui/material";
import { Customer, Delivery, DTO, Purchase, PurchaseIntent } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, FormField, Text, TextInput } from "grommet";
import { User } from "grommet-icons";
import { useAppDispatch } from "../../../app/store";
import { parseTimestampFromUTC } from "../../../helpers";
import { UnionProduct } from "../../../types";
import { FieldWithActionButton, LocalDateField, Pagination, useTimezone } from "../../common";
import { navigateToCustomer } from "../routes";
import { DeliverySummary } from "./delivery";
import { ProductSummary } from "./orders";

export const PurchaseSummary: React.FC<{ purchase: DTO<Purchase>; }> = (props) => {
	const dispatch = useAppDispatch();

	function handleOrderNavigate(): void {
		dispatch(push(`/admin/dashboard/purchases/${props.purchase.id}`));
	}

	return (
		<Box
			hoverIndicator
			border
			gap="small"
			margin="small"
			onClick={handleOrderNavigate}
		>
			<Box margin="small" gap="small">
				<Box direction="row" align="center" justify="end">
					<Chip label={"TODO"} />
				</Box>
				<Box gap="small" align="start">
					{props.purchase.created_at && (
						<LocalDateField
							label="Created At"
							value={props.purchase.created_at}
						/>
					)}
					{props.purchase.paid_at && (
						<LocalDateField
							label="Paid At"
							value={props.purchase.paid_at}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export const PurchaseDetails: React.FC<{ purchase: DTO<Purchase>; customer?: DTO<Customer> | null; }> = (props) => {
	const timezone = useTimezone();
	const dispatch = useAppDispatch();

	return (
		<Box>
			<FormField
				label="Purchase Id"
			>
				<TextInput
					readOnly
					value={props.purchase.id}
				/>
			</FormField>
			<FormField
				label="Purchase Number"
			>
				<TextInput
					readOnly
					value={props.purchase.number}
				/>
			</FormField>
			{props.customer && (
				<FieldWithActionButton
					label="Buyer"
					name="buyer"
					icon={<User />}
					value={`${props.customer.first_name} ${props.customer.last_name}`}
					onClick={() => {
						props.customer && navigateToCustomer(props.customer.id, dispatch);
					}}
				/>
			)}
			<FormField
				label="Paid At"
			>
				<TextInput
					readOnly
					value={parseTimestampFromUTC(props.purchase.paid_at, timezone)}
				/>
			</FormField>
			<FormField
				label="Payment Number"
			>
				<TextInput
					readOnly
					value={props.purchase.payment?.number}
				/>
			</FormField>
		</Box>
	);
};


interface PurchaseProductsProps {
	useDefaultOnClick?: boolean;
	intents: DTO<PurchaseIntent>[];
	renderProducts?: (intents: DTO<PurchaseIntent>[]) => JSX.Element;
}

export const PurchaseProducts: React.FC<PurchaseProductsProps> = (props) => {
	const dispatch = useAppDispatch();

	const defaultRenderProducts = (intents: DTO<PurchaseIntent>[]) => (
		<Box>
			{intents.map(intent => (
				<ProductSummary
					key={intent.id}
					permitAI={false}
					product={intent.product as unknown as DTO<UnionProduct>}
					overrideOnClick={props.useDefaultOnClick ? undefined : () => {
						dispatch(push(`/admin/dashboard/products/${intent.id}`));
					}}
				>
					<Box gap="small">
						<Text>Quantity: <Text weight="bold">{intent.quantity}</Text></Text>
						<Text>Delivery Type: <Text weight="bold">{intent.delivery_type}</Text></Text>
					</Box>
				</ProductSummary>
			))}
		</Box>
	);

	return (
		<Box gap="small" flex>
			<Pagination
				items={props.intents}
				pageSize={5}
				renderItems={props.renderProducts ?? defaultRenderProducts}
			/>
		</Box>
	);
};

export const PurchaseDeliveries: React.FC<{ purchase: DTO<Purchase>; }> = (props) => {
	const defaultRenderProducts = (deliveries: DTO<Delivery>[]) => (
		<Box>
			{deliveries.map(delivery => (
				<DeliverySummary
					delivery={delivery}
				/>
			))}
		</Box>
	);

	return (
		<Box gap="small">
			<Pagination
				items={props.purchase.deliveries ?? []}
				pageSize={4}
				renderItems={defaultRenderProducts}
			/>
		</Box>
	);
};