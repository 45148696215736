import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/store";
import { fetchCustomerCart } from "../../../app/store/purchase";
import { Loader } from "../../common";

export const ShopProvider: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const [loaded, setLoaded] = useState(false);

	function initialize(): void {
		dispatch(fetchCustomerCart()).unwrap()
			.catch(err => {
				console.error("Failed to initialize shop provider", err);
			})
			.finally(() => {
				setLoaded(true);
			});
	}

	useEffect(() => {
		initialize();
	}, []);

	return (
		<Loader visible={!loaded}>
			{props.children}
		</Loader>
	);
};