import { DTO, Market } from "@rego-app/common";
import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { ReferenceService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCurrentMarket, setMarket } from "../../../app/store/purchase";
import { getOrFetchMarket, getOrFetchMarkets } from "../../../app/store/reference";
import { Loader } from "../../common";
import { SelectMarketPage } from "../pages";

interface MarketControllerState {
	wasLoaded: boolean;
}

export const MarketController: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const market = useAppSelector(selectCurrentMarket);

	const [state, setState] = useState<MarketControllerState>({
		wasLoaded: false
	});

	function fetchStoredMarket(): Promise<DTO<Market> | null> {
		const storedCode = ReferenceService.getMarketCode();
		if(storedCode) {
			return dispatch(getOrFetchMarket(storedCode)).unwrap();
		}

		return Promise.resolve(null);
	}

	function initialize(): void {
		dispatch(getOrFetchMarkets()).unwrap()
			.then(fetchStoredMarket)
			.catch(err => {
				console.error("Failed to initialize market controller", err);
			})
			.finally(() => {
				setState({
					...state,
					wasLoaded: true
				});
			});
	}

	function selectMarket(market: DTO<Market>): void {
		dispatch(setMarket(market));
		dispatch(push(`/shop/${market.code}`));
	}

	useEffect(() => {
		initialize();
	}, []);

	useEffect(() => {
		if(market) {
			dispatch(push(`/shop/${market.code}`));
		}
	}, [market]);

	return (
		<Loader visible={!state.wasLoaded}>
			<SelectMarketPage
				onClick={selectMarket}
			/>
		</Loader>
	);
};