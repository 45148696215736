import { DTO, Product } from "../../../types";
import { Anchor, Box, Button, Card, CardBody, CardFooter, CardHeader, CheckBox, DateInput, FormField, Grid, Heading, Image, Layer, Spinner, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { Loader, SlimHeading, useWindowDimensions } from "../../common";
import { Steps, useSteps } from "react-step-builder";
import { ProductDetailsForm } from "../components/ProductDetails";
import { ProductUploadForm } from "../components";
import { ProductConfirmation } from "../components/ProductConfirmation";
import { Brand, CustomerProduct, Item, ProductCondition, ProductMedia } from "@rego-app/common";
import { push } from "connected-react-router";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { useOrder } from "../controller";
import { refreshOrder, removeOrderProduct, selectActiveProduct, selectCutoffDate, selectPromptedForCutoffDate, setActiveProduct as setActiveProductStore, setCutoffDate, setPromptedForCutoffDate, upsertOrDeferOrderProduct } from "../../../app/store/order";
import { Descending, FormClose, StatusGood } from "grommet-icons";
import { useSnackbar } from "notistack";
import { buildCustomerProduct, ProductService } from "../../../app/services";
import { FLAGS } from "../../../app/flags";
import { ScanModal } from "../../scan/components/Scan";
import { useMemo } from "react";
import placeholder from "../../../img/placeholder.jpg";
import { mediaToFile } from "../../../helpers";
import { Chip } from "@mui/material";
import moment from "moment-timezone";
import { useIntent } from "../hooks";

export const ProductImagePreview: React.FC<{ content: string; }> = (props) => {
	return (
		<Box margin="small" align="center" justify="center">
			{!props.content
				? (
					<Spinner />
				)
				: (
					<Image
						height="100px"
						style={{ maxWidth: "100px" }}
						src={props.content}
					/>
				)}
		</Box>
	);
};

interface ExistingProductCardProps {
	product: DTO<Product>;
	isRemoving: boolean;
	onSelectProduct(product: DTO<Product>): void;
	onRemoveProduct(product: DTO<Product>): void;
}

export const ExistingProductCard: React.FC<ExistingProductCardProps> = (props) => {
	const [src, setSrc] = useState("");

	const itemName = useMemo(() => {
		return props.product.item?.name ?? props.product.item_input ?? "";
	}, [props.product]);

	useEffect(() => {
		const media = (props.product.media ?? [])[0];
		if(!media) {
			setSrc(placeholder);
			return;
		}

		if(media.content) {
			const file = mediaToFile(media);
			setSrc(URL.createObjectURL(file));
			return;
		}

		ProductService.getMediaURL(props.product.id, media.id)
			.then(content => {
				setSrc(content);
			})
			.catch(err => {
				console.error("Failed to load product media content", media, err);
				setSrc(placeholder);
			});
	}, [props.product]);

	return (
		<Card
			// width="medium"
			height="medium"
			hoverIndicator
		>
			<CardHeader flex style={{ minHeight: "200px" }}>
				<Box align="center" justify="center" fill style={{ minHeight: "200px" }}>
					{(!src)
						? (
							<Spinner
								size="medium"
							/>
						)
						: (
							<Image
								fit="cover"
								height="100%"
								width="100%"
								src={src}
							/>
						)}
				</Box>
			</CardHeader>
			<CardBody>
				<Box margin="medium" gap="small">
					<Text weight="bold">
						{itemName}
					</Text>
					<Box align="start">
						{props.product.media.length <= 0 && (
							<Chip
								label="Incomplete"
								size="medium"
								color="warning"
							/>
						)}
					</Box>
				</Box>
			</CardBody>
			<CardFooter>
				<Box align="end" justify="between" margin="medium" direction="row" flex>
					<Button
						label="Remove"
						color="status-error"
						icon={props.isRemoving ? <Spinner /> : <FormClose />}
						disabled={props.isRemoving}
						hoverIndicator
						onClick={(event) => {
							props.onRemoveProduct(props.product);
							event.stopPropagation();
						}}
					/>
					<Button
						label="Edit"
						primary
						disabled={props.isRemoving}
						onClick={(event) => {
							props.onSelectProduct(props.product);
							event.stopPropagation();
						}}
					/>
				</Box>
			</CardFooter>
		</Card>
	);
};

export const ExistingProductContainer: React.FC = (props) => {
	const dimensions = useWindowDimensions();
	const [columns, setColumns] = useState(2);

	useEffect(() => {
		const width = dimensions.width;
		if(width >= 1200) {
			setColumns(3);
			return;
		}

		if(width >= 700) {
			setColumns(2);
			return;
		}

		setColumns(1);
	}, [dimensions.width]);

	return (
		<Grid
			gap="small"
			columns={{ count: columns, size: "auto" }}
		>
			{props.children}
		</Grid>
	);
};

interface ProductItemProps {
	product: DTO<Product>;
	onSelectProduct(product: DTO<Product>): void;
	onRemoveProduct(product: DTO<Product>): void;
}

export const ProductItem: React.FC<ProductItemProps> = (props) => {
	return (
		<Box
			border="all"
			hoverIndicator
			onClick={() => {
				props.onSelectProduct(props.product);
			}}
		>
			<Grid columns={["flex", "small"]} margin="small">
				<Box justify="center">
					<SlimHeading level="4">
						{props.product.item?.name || props.product.item_input}
					</SlimHeading>
				</Box>
				<Box align="end" justify="center">
					<Button
						plain
						hoverIndicator
						onClick={() => {
							props.onRemoveProduct(props.product);
						}}
						icon={<FormClose size="medium" />}
					/>
				</Box>
			</Grid>
		</Box>
	);
};

function shouldUseAutomaticScan(): boolean {
	const { ENABLE_AUTOMATIC_PRODUCT_SCAN } = FLAGS;
	return ENABLE_AUTOMATIC_PRODUCT_SCAN.isEnabled();
}

interface CutoffDateModalProps {
	onClose(): void;
}

const CutoffDateModal: React.FC<CutoffDateModalProps> = (props) => {
	const dispatch = useAppDispatch();
	const [hasCutoffDate, setHasCutoffDate] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [wasLoaded, setWasLoaded] = useState(false);
	const cutoffDate = useAppSelector(selectCutoffDate);

	useEffect(() => {
		if(cutoffDate && moment(cutoffDate).unix() < moment().unix()) {
			setHasCutoffDate(false);
			setWasLoaded(false);
			setIsLoading(false);
			dispatch(setCutoffDate(null));
		}
	}, [cutoffDate]);

	useEffect(() => {
		if(cutoffDate && !wasLoaded) {
			setIsLoading(true);
			setWasLoaded(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 1000);
		}
	}, [wasLoaded, cutoffDate]);

	function handleSelectDate(date: Date): void {
		setIsLoading(true);
		dispatch(setCutoffDate(date.toISOString()));

		new Promise<void>(resolve => {
			setTimeout(() => {
				return resolve();
			}, 2000);
		}).then(() => {
			setIsLoading(false);
		});
	}

	return (
		<Layer>
			<Box margin="medium" gap="medium">
				<SlimHeading level="3">
					Need it out fast?
				</SlimHeading>
				<Text>
					Rego helps you make money - or save money - on furniture that you need out fast.
				</Text>
				<FormField
					contentProps={{ border: undefined }}
				>
					<CheckBox
						label={
							<Text weight="bold">Do you need everything out by a specific date?</Text>
						}
						checked={hasCutoffDate}
						onChange={(event) => {
							setHasCutoffDate(event.target.checked);
						}}
					/>
				</FormField>
				{hasCutoffDate && (
					<Box gap="small" flex>
						<FormField>
							<DateInput
								format="mm/dd/yyyy"
								value={cutoffDate ?? undefined}
								onChange={(event) => {
									const value = event.value;
									handleSelectDate(Array.isArray(value) ? new Date(value[0]) : new Date(value));
								}}
								calendarProps={{
									bounds: [
										moment().add(1, "days").toDate().toISOString(),
										moment().add(365, "days").toDate().toISOString()
									],
									firstDayOfWeek: 0
								}}
							/>
						</FormField>
						{(wasLoaded && !!cutoffDate) && (
							<Grid columns={["1/4", "3/4"]}>
								<Box flex align="center" justify="center">
									{isLoading
										? (
											<Spinner

											/>
										)
										: (
											<StatusGood
												size="large"
												color="brand"
											/>
										)}
								</Box>
								<Box flex>
									{isLoading
										? (
											<Text>
												Checking if we can make that date work.
											</Text>
										)
										: (
											<Text>
												<Text weight="bold">
													Great News!&nbsp;
												</Text>
												We can help get your furniture out by <Text weight="bold">{moment(cutoffDate).format("MMMM DD")}</Text>
											</Text>
										)}
								</Box>
							</Grid>
						)}
					</Box>
				)}
				<Box align="end" justify="end">
					<Button
						label="Continue"
						disabled={isLoading || (hasCutoffDate && !cutoffDate)}
						onClick={props.onClose}
					/>
				</Box>
			</Box>
		</Layer>
	);
};

export const OrderProductController: React.FC = (props) => {
	const { current, jump } = useSteps();
	const { order } = useOrder();
	const { goal, intent, forcedIntent } = useIntent();
	const cutoffDate = useAppSelector(selectCutoffDate);
	const activeProductFromStore = useAppSelector(selectActiveProduct);
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRemoving, setIsRemoving] = useState<DTO<Product> | null>(null);
	const [activeProduct, setActiveProduct] = useState<DTO<CustomerProduct>>(activeProductFromStore ?? buildCustomerProduct({
		goal: goal ?? undefined,
		intent: intent ?? undefined
	}));
	const [isScanModalOpen, setIsScanModalOpen] = useState(shouldUseAutomaticScan());
	const promptedForCutoffDate = useAppSelector(selectPromptedForCutoffDate);
	const [showCutoffDateModal, setShowCutoffDateModal] = useState(false);

	useEffect(() => {
		if(!promptedForCutoffDate && !cutoffDate) {
			setShowCutoffDateModal(true);
		}
	}, [promptedForCutoffDate, cutoffDate]);

	useEffect(() => {
		console.debug("CURRENT CHANGED!!", current);

		if(current === 1 && shouldUseAutomaticScan()) {
			setIsScanModalOpen(true);
		}

	}, [current]);

	function fetchExistingOrder(): void {
		if(order?.id) {
			setIsLoading(true);
			dispatch(refreshOrder()).unwrap()
				.catch(err => {
					console.error("Failed to refresh order");
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}

	useEffect(() => {
		fetchExistingOrder();
	}, []);

	function updateActiveProductFromAutomaticScan(item: DTO<Item>, item_input: string, condition: ProductCondition, media: DTO<ProductMedia>, brand: DTO<Brand> | null): Promise<void> {
		const product = {
			goal: intent,
			intent: intent,
			item: item || null,
			item_input: item_input ?? "",
			condition: condition,
			media: [
				media
			],
			brand: brand || null
		} as DTO<CustomerProduct>;

		setIsSaving(true);
		setIsScanModalOpen(false);

		return new Promise<void>((resolve, reject) => {
			dispatch(upsertOrDeferOrderProduct(product)).unwrap()
				.then(res => {
					setActiveProduct(res);
					jump(3);
					resolve();
				})
				.catch(err => {
					console.error("Failed to save product", product, err);
					snack.enqueueSnackbar("We ran into an issue saving your information", {
						variant: "error"
					});
					reject();
				})
				.finally(() => {
					setIsSaving(false);
				});
		});
	}

	function updateActiveProduct(product: DTO<CustomerProduct>): Promise<void> {
		product.goal = forcedIntent ?? product.goal ?? intent ?? undefined;
		product.intent = forcedIntent ?? product.intent ?? product.goal ?? intent ?? undefined;

		setIsSaving(true);
		return new Promise<void>((resolve, reject) => {
			dispatch(upsertOrDeferOrderProduct(product)).unwrap()
				.then(res => {
					setActiveProduct({ ...res });
					resolve();
				})
				.catch(err => {
					console.error("Failed to save product", product, err);
					snack.enqueueSnackbar("We ran into an issue saving your information", {
						variant: "error"
					});
					reject();
				})
				.finally(() => {
					setIsSaving(false);
				});
		});
	}

	function updateActiveProductMedia(media: DTO<ProductMedia>[]): void {
		const current = activeProduct?.media ?? [];

		for(const item of media) {
			if(!current.find(m => m.content_hash === item.content_hash)) {
				current.push(item as ProductMedia);
			}
		}

		setActiveProduct((state) => {
			return {
				...state,
				media: [...current]
			};
		});
	}

	function addProductMedia(items: DTO<ProductMedia>[]): Promise<void> {
		setIsSaving(true);
		return new Promise((resolve, reject) => {
			const media = Array.from(activeProduct.media ?? []);
			for(const item of items) {
				media.push(item as ProductMedia);
			}

			const product = {
				...activeProduct,
				media
			};

			dispatch(upsertOrDeferOrderProduct(product)).unwrap()
				.then(res => {
					console.debug("AFTER MEDIA ADDED", res);
					setActiveProduct(res);
					resolve();
				})
				.catch(err => {
					console.error("Failed to save product media", { ...media, content: undefined }, err);
					snack.enqueueSnackbar("We ran into an issue saving your information", {
						variant: "error"
					});
					reject();
				})
				.finally(() => {
					setIsSaving(false);
				});
		});
	}

	function updateProductMedia(item: DTO<ProductMedia>): Promise<void> {
		setIsSaving(true);
		return new Promise((resolve, reject) => {
			const media = Array.from(activeProduct.media ?? []).map(m => {
				if((item.created_at && item.id === m.id) || (m.name === item.name)) {
					return {
						...m,
						...item
					} as DTO<ProductMedia>;
				}
				return m;
			});

			const product = {
				...activeProduct,
				media: media as ProductMedia[]
			};

			dispatch(upsertOrDeferOrderProduct(product)).unwrap()
				.then(res => {
					console.debug("AFTER MEDIA UPDATED", res);
					setActiveProduct(res);
					resolve();
				})
				.catch(err => {
					console.error("Failed to save product media", { ...media, content: undefined }, err);
					snack.enqueueSnackbar("We ran into an issue saving your information", {
						variant: "error"
					});
					reject();
				})
				.finally(() => {
					setIsSaving(false);
				});
		});
	}

	function removeProductMedia(item: DTO<ProductMedia>): Promise<void> {
		setIsSaving(true);
		return new Promise((resolve, reject) => {
			const media = Array.from(activeProduct.media ?? []);
			let removed = false;

			const product = {
				...activeProduct,
				media: media.filter(m => {
					if(!removed && ((item.id && item.id === m.id) || (item.name === m.name))) {
						removed = true;
						return false;
					}

					return true;
				}) as ProductMedia[]
			};

			dispatch(upsertOrDeferOrderProduct(product)).unwrap()
				.then(res => {
					console.debug("AFTER MEDIA DELETED", res);
					setActiveProduct(res);
					resolve();
				})
				.catch(err => {
					console.error("Failed to save product media", { ...media, content: undefined }, err);
					snack.enqueueSnackbar("We ran into an issue saving your information", {
						variant: "error"
					});
					reject();
				})
				.finally(() => {
					setIsSaving(false);
				});
		});
	}

	function removeProduct(product: DTO<CustomerProduct>): Promise<void> {
		setIsSaving(true);
		setIsRemoving({ ...product });
		return new Promise<void>((resolve, reject) => {
			dispatch(removeOrderProduct(product)).unwrap()
				.then(res => {
					console.debug("REMOVED PRODUCT", res);
				})
				.catch(err => {
					console.error("Failed to remove product", product, err);
					snack.enqueueSnackbar("We ran into an issue saving your information", {
						variant: "error"
					});
					reject(err);
				})
				.finally(() => {
					setIsSaving(false);
					setIsRemoving(null);
				});
		});
	}

	function getExistingProducts(): DTO<CustomerProduct>[] {
		return order?.products?.filter(p => p.id !== activeProduct.id) ?? [];
	}

	function switchActiveProduct(product: DTO<CustomerProduct>): void {
		setActiveProduct({
			...product
		});
		dispatch(
			setActiveProductStore({
				...product
			})
		);
	}

	function saveProduct(addAnother: boolean, changes?: Partial<DTO<CustomerProduct>>): Promise<void> {
		setIsSaving(true);

		const product = {
			...activeProduct,
			...changes
		};

		return dispatch(upsertOrDeferOrderProduct(product)).unwrap()
			.then(() => {
				if(addAnother) {
					setActiveProduct(buildCustomerProduct({}));
					jump(1);
					return;
				}

				dispatch(push("/order/address"));
			})
			.catch(err => {
				console.error("Failed during product update", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsSaving(false);
			});
	}

	const steps = [
		"Details",
		"Images",
		"Review"
	];

	return (
		<Loader visible={isLoading}>
			{isScanModalOpen && (
				<ScanModal
					onClose={() => {
						setIsScanModalOpen(false);
					}}
					onSuccessfullCapture={updateActiveProductFromAutomaticScan}
				/>
			)}
			{showCutoffDateModal && (
				<CutoffDateModal
					onClose={() => {
						setShowCutoffDateModal(false);
						dispatch(setPromptedForCutoffDate(true));
					}}
				/>
			)}
			<Box margin="small" align="center">
				<Box>
					<Heading level="2">Tell us about your item(s)</Heading>
				</Box>
				<Box margin="small" gap="small">
					<Box>
						<Stepper activeStep={current - 1} alternativeLabel>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>
										<SlimHeading level="4">
											{label}
										</SlimHeading>
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Box>
					{!!(getExistingProducts().length) && (
						<Box gap="small">
							<Box direction="row" fill="vertical" align="center" justify="center" flex>
								<Box align="center" justify="center">
									<SlimHeading level="3">
										Already Added
									</SlimHeading>
								</Box>
								<Box align="center" fill="vertical">
									<Descending
										color="black"
										size="large"
									/>
								</Box>
							</Box>
							{/* <Text>
								These products have already been added. Click to make changes or press "Remove" to remove this item from your order
							</Text> */}
							<Box gap="small">
								<ExistingProductContainer>
									{getExistingProducts().map(p => (
										<ExistingProductCard
											key={p.id}
											product={p}
											isRemoving={p.id === isRemoving?.id}
											onSelectProduct={switchActiveProduct}
											onRemoveProduct={removeProduct}
										/>
									))}
								</ExistingProductContainer>
							</Box>
							<Box margin={{ vertical: "medium" }} border={{ size: "medium", side: "bottom", color: "black" }}>

							</Box>
						</Box>
					)}
					<Box>
						{FLAGS.ENABLE_AUTOMATIC_PRODUCT_SCAN.isEnabled() && (
							<Anchor
								label="Open Scan Tool"
								onClick={() => {
									setIsScanModalOpen(true);
								}}
							/>
						)}
					</Box>
					<Box gap="small">
						<Steps>
							<ProductDetailsForm
								isSaving={isSaving}
								existingProducts={getExistingProducts()}
								onProductUpdated={updateActiveProduct}
								product={activeProduct}
							/>
							<ProductUploadForm
								isSaving={isSaving}
								onMediaCreated={addProductMedia}
								onMediaUpdated={updateProductMedia}
								onMediaDeleted={removeProductMedia}
								product={activeProduct}
							/>
							<ProductConfirmation
								isProductSaving={isSaving}
								onProductSave={saveProduct}
								product={activeProduct}
							/>
						</Steps>
					</Box>
				</Box>
			</Box>
		</Loader>
	);
};