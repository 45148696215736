import { Product, ProductMedia } from "@rego-app/common";
import { Box, Button, Carousel, CarouselProps, CheckBox, Image, Layer, Spinner, Stack } from "grommet";
import { Fragment, useEffect, useMemo, useState } from "react";
import { ProductService } from "../../../app/services";
import { mediaToFile, sortPrimaryMedia } from "../../../helpers";
import { DTO } from "../../../types";

import placeholder from "../../../img/placeholder.jpg";
import { Modal } from "./Modal";
import { FormClose } from "grommet-icons";

interface ImageItemFullViewModalProps {
	product: DTO<Product> | DTO<Product>[];
	media: DTO<ProductMedia>[];
	onClose: () => void;
	initial?: number;
}

export const ImageItemFullViewModal: React.FC<ImageItemFullViewModalProps> = (props) => {
	const media = useMemo(() => {
		const products = Array.isArray(props.product) ? props.product : [ props.product ];
		return products.flatMap(product => {
			return product.media.map(media => {
				return {
					...media,
					product: product as Product
				};
			});
		}).sort(sortPrimaryMedia);
	}, [ props.product ]);

	return (
		<Layer
			onEsc={props.onClose}
			onClickOutside={props.onClose}
			full
		>
			<Box fill>
				<Box align="end" margin="small">
					<Button
						hoverIndicator
						onClick={(event) => {
							event.stopPropagation();
							props.onClose();
						}}
						icon={
							<FormClose size="medium" />
						}
					/>
				</Box>
				<Box fill>
					<Carousel id="carousel" wrap initialChild={props.initial ?? media.length - 1} activeChild={media.length - 1} margin="medium" height={"100%"}>
						{media.map(m => (
							<Box fill align="center" justify="center">
								<LoadImageContent
									product={m.product}
									media={m}
									key={`${m.product.id}-${m.id}`}
									fit="contain"
								/>
							</Box>
						))}
					</Carousel>
				</Box>
			</Box>
		</Layer>
	);
};

interface LoadImageContentProps {
	media: DTO<ProductMedia>;
	product: DTO<Product>;
	fit: "cover" | "contain";
}

export const LoadImageContent: React.FC<LoadImageContentProps> = (props) => {
	const [ src, setSrc ] = useState("");

	useEffect(() => {
		if(!props.media.content) {
			//Need to get content from API
			ProductService.getMediaURL(
				props.product.id,
				props.media.id
			)
				.then(content => {
					setSrc(content);
				})
				.catch(err => {
					console.error("Failed to load product media content", props.media, err);
					setSrc(placeholder);
				});
		}
		else {
			const file = mediaToFile(props.media);
			setSrc(URL.createObjectURL(file));
		}
	}, [ props.media ]);

	return (
		<Box align="center" justify="center" fill>
			{!!src
				? (
					<Image
						key={props.media.id}
						fit={props.fit ?? "cover"}
						src={src}
						width="100%"
					/>
				)
				: (
					<Spinner size="large" />
				)}
		</Box>
	);
};

interface ImageItemProps {
	product: DTO<Product>,
	media: DTO<ProductMedia>;
	width?: number;
	height?: number;
	handleDeleteMedia?: (media: DTO<ProductMedia>) => Promise<boolean>;
	handleOnClick?(): void;
	blockOnClick?: boolean;
	ignoreWidth?: boolean;
	ignoreHeight?: boolean;
	fit?: "contain" | "cover";
}

export const ImageItem: React.FC<ImageItemProps> = (props) => {
	const [ src, setSrc ] = useState("");
	const [ isDeleting, setIsDeleting ] = useState(false);

	function handleDeleteMedia(): void {
		if(!props.handleDeleteMedia) {
			return;
		}

		setIsDeleting(true);
		props.handleDeleteMedia(props.media)
			.then(res => {

			})
			.catch(err => {

			})
			.finally(() => {
				setIsDeleting(false);
			});
	}

	useEffect(() => {
		if(!props.media.content) {
			//Need to get content from API
			ProductService.getMediaURL(
				props.product.id,
				props.media.id
			)
				.then(content => {
					setSrc(content);
				})
				.catch(err => {
					console.error("Failed to load product media content", props.media, err);
					setSrc(placeholder);
				});
		}
		else {
			const file = mediaToFile(props.media);
			setSrc(URL.createObjectURL(file));
		}
	}, [ props.media ]);

	if(!src) {
		return (
			<Box align="center" justify="center" fill>
				<Spinner size="large" />
			</Box>
		);
	}

	return (
		<Fragment>
			<Stack fill>
				{props.handleDeleteMedia && (
					<Box align="end" margin="small">
						<Button
							style={{ zIndex: 1 }}
							color="status-error"
							primary
							icon={isDeleting ? <Spinner /> : <FormClose />}
							disabled={isDeleting}
							onClick={(event) => {
								event.stopPropagation();
								handleDeleteMedia();
							}}
						/>
					</Box>
				)}
				<Image
					alignSelf="center"
					width={props.ignoreWidth ? undefined : "100%"}
					//width={props.width}
					//height={props.height}
					height={props.ignoreHeight ? undefined : "100%"}
					key={props.media.id}
					fit={props.fit ?? "cover"}
					src={src}
					onClick={() => {
						if(!props.blockOnClick && props.handleOnClick) {
							props.handleOnClick();
						}
					}}
				/>
			</Stack>
		</Fragment>
	);
};

interface ImageCarouselProps {
	fit?: "contain" | "cover";
	product: DTO<Product> | DTO<Product>[];
	height?: CarouselProps[ "height" ];
	width?: CarouselProps[ "width" ];
	handleDeleteMedia?: (media: DTO<ProductMedia>) => Promise<boolean>;
	filterMedia?: (media: DTO<ProductMedia>[]) => DTO<ProductMedia>[];
	initial?: number;
}

export const ImageCarousel: React.FC<ImageCarouselProps> = (props) => {
	const [ showFullScreen, setShowFullScreen ] = useState(false);

	const media = useMemo(() => {
		const products = Array.isArray(props.product) ? props.product : [ props.product ];
		const mapped = products.flatMap(product => {
			const productMedia = Array.from(product.media ?? []);
			return productMedia.map(media => {
				return {
					...media,
					product: product as Product
				};
			});
		});

		if(props.filterMedia) {
			return props.filterMedia(mapped).sort(sortPrimaryMedia);
		}

		return mapped.sort(sortPrimaryMedia);
	}, [ props.product, props.filterMedia ]);

	return (
		<Fragment>
			{showFullScreen && (
				<ImageItemFullViewModal
					product={props.product}
					media={media}
					onClose={() => {
						setShowFullScreen(false);
					}}
				/>
			)}
			<Carousel
				wrap
				initialChild={props.initial ?? 0}
				activeChild={0}
				width={props.width ?? "medium"}
				height={props.height ?? "medium"}
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				{media.map(m => (
					<ImageItem
						fit={props.fit ?? undefined}
						product={m.product}
						media={m}
						key={`${m.product.id}-${m.id}`}
						handleDeleteMedia={props.handleDeleteMedia}
						handleOnClick={() => {
							setShowFullScreen(true);
						}}
					/>
				))}
			</Carousel>
		</Fragment>
	);
};