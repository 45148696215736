import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Form, FormField, Grid, Heading, List, Menu, Spinner, Tab, Tabs, Text, TextInput } from "grommet";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchCustomer, selectCustomer, setRequiresLogin } from "../../../app/store/application";
import { Contact, ContactType, Customer, DTO } from "../../../types";
import { CustomerService, UtilService } from "../../../app/services";
import { Add, Alert, Checkmark, Clear, More, Shield, Star } from "grommet-icons";
import { useSnackbar } from "notistack";
import { CreateContactModal, VerifyContactModal } from "../components";
import { SlimHeading, useScreenSize, useWindowDimensions } from "../../common";
import { CreatePaymentMethod, PaymentStatus, PaymentStatusController } from "../components/CreatePaymentMethod";
import { PaymentMethod } from "@rego-app/common";
import Cards from "react-credit-cards";
import 'react-credit-cards/es/styles-compiled.css';
import { StripeProvider, useStripeProvider } from "../../payment/provider";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { push } from "connected-react-router";



interface AccountComponentProps {
	customer: DTO<Customer>;
}

interface ContactInformationState {
	first_name: string;
	last_name: string;
	contacts: DTO<Contact>[];
	primary_phone: string;
	primary_email: string;
	wasCustomerModified: boolean;
	displayCreateContact: boolean;
	displayContactVerify: boolean;
	contactToVerify: null | DTO<Contact>;
	isUpdatingContact: boolean;
}

interface ContactMethodProps {
	contacts: DTO<Contact>[];
	renderVerifyContact: (contact: DTO<Contact>) => void;
	makePrimary(contact: DTO<Contact>): Promise<void>;
	removeContact(contact: DTO<Contact>): Promise<void>;
}

const ContactMethod: React.FC<ContactMethodProps> = (props) => {
	return (
		<Box>
			<List
				data={props.contacts.sort((a, b) => Number(b.is_primary) - Number(a.is_primary))}
				pad={{ left: 'small', right: 'none' }}
				action={(contact, index) => (
					<Menu
						dropAlign={{ right: "right", top: "top" }}
						key={index}
						icon={<More />}
						hoverIndicator
						items={[
							{
								name: "PRIMARY",
								label: (
									<Button plain icon={<Checkmark color="green" />} label="Make Primary" />
								),
								onClick: () => {
									props.makePrimary(contact);
								}
							},
							{
								name: "VERIFY",
								label: (
									<Button plain icon={<Shield color="blue" />} label="Verify" />
								),
								onClick: () => {
									props.renderVerifyContact(contact);
								}
							},
							{
								name: "DELETE",
								label: (
									<Button plain icon={<Clear color="red" />} label="Delete" />
								),
								onClick: () => {
									props.removeContact(contact);
								}
							}
						].filter(item => {
							//dont render if already verified
							if(item.name === "VERIFY" && contact.is_verified) {
								return false;
							}

							//dont render if already primary
							if(item.name === "PRIMARY" && contact.is_primary) {
								return false;
							}

							//cant delete when only 1 is left (need login method)
							if(item.name === "DELETE" && props.contacts.filter(c => c.type === contact.type).length <= 1) {
								return false;
							}

							return true;
						})}
					/>
				)}
			>
				{(contact: DTO<Contact>) => (
					<Button plain icon={(contact.is_primary) ? <Star color="green" /> : undefined} label={(
						<Box gap="small" direction="row" overflow="hidden" margin="small">
							<Text weight="bold" style={{ overflow: "hidden" }}>
								{contact.type === ContactType.PHONE
									? UtilService.formatPhoneToDisplay(contact.value)
									: contact.value
								}
							</Text>
							{
								!contact.is_verified && (
									<Button
										plain
										tip="Verified"
										icon={<Alert color="red" />}
									/>
								)
							}
						</Box>
					)}
					/>
				)}
			</List>
		</Box>
	);
};

export const PaymentMethodRecord: React.FC<{ paymentMethod: DTO<PaymentMethod>; }> = (props) => {
	function formatCardNumber(brand: string, input: string | number): string {
		if(brand === "amex") {
			return "**** ****** *" + input;
		}

		return "**** **** **** " + input;
	}

	function formatExpiration(input: string | number): string {
		const value = Number(input);
		if(isNaN(value)) {
			return "";
		}

		if(value < 10) {
			return "0" + value;
		}

		return String(value);
	}

	return (
		<Cards
			name=" "
			issuer={props.paymentMethod.brand}
			expiry={`${formatExpiration(props.paymentMethod.expiration_month)}/${formatExpiration(props.paymentMethod.expiration_year)}`}
			preview
			cvc="***"
			number={formatCardNumber(props.paymentMethod.brand, props.paymentMethod.last_four)}
		/>
	);
};

interface PaymentMethodProps {
	customer: DTO<Customer>;
}

export const PaymentMethods: React.FC<PaymentMethodProps> = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const dimensions = useWindowDimensions();
	const location = useLocation();
	const [ isCreatingPaymentMethod, setIsCreatingPaymentMethod ] = useState(false);
	const [ isSuccessCallback, setIsSuccessCallback ] = useState(false);
	const [ deletingId, setDeletingId ] = useState("");

	useEffect(() => {
		if(location.pathname === "/account/payment-methods") {
			setIsSuccessCallback(true);
		}
	}, []);

	function deletePaymentMethod(paymentMethodId: string): void {
		setDeletingId(paymentMethodId);

		CustomerService.deletePaymentMethod(props.customer.id, paymentMethodId)
			.then(() => {
				return dispatch(fetchCustomer({ expand: [ "payment_methods", "contacts" ] })).unwrap();
			})
			.then(() => {
				snack.enqueueSnackbar("Successfully deleted payment method", {
					variant: "success"
				});
			})
			.catch(err => {
				console.error("Failed to delete payment method", paymentMethodId, err);
				snack.enqueueSnackbar("We ran into an issue deleting your payment method", {
					variant: "error"
				});
			})
			.finally(() => {
				setDeletingId("");
			});
	}

	function handleCreatePaymentMethod(): void {
		setIsCreatingPaymentMethod(true);
	}

	return (
		<Box margin="medium" gap="medium">
			{isSuccessCallback && (
				<StripeProvider>
					<PaymentStatusController>
						<PaymentStatus
							customer={props.customer}
							onClose={() => {
								setIsSuccessCallback(false);
							}}
						/>
					</PaymentStatusController>
				</StripeProvider>
			)}
			{isCreatingPaymentMethod && (
				<CreatePaymentMethod
					onClose={() => {
						setIsCreatingPaymentMethod(false);
					}}
					returnURL={`${window.location.protocol}//${window.location.host}/account/payment-methods`}
					customer={props.customer}
				/>
			)}
			<Box align="start">
				<Button
					label="Add Payment Method"
					onClick={handleCreatePaymentMethod}
				/>
			</Box>
			{props.customer?.payment_methods?.length
				? (
					<Box direction={dimensions.width <= 750 ? "column" : "row"} gap="medium">
						{props.customer.payment_methods.map(m => (
							<Box key={m.id} gap="small">
								<PaymentMethodRecord
									paymentMethod={m}
								/>
								<Box align="center">
									<Button
										onClick={() => {
											deletePaymentMethod(m.id);
										}}
										disabled={deletingId === m.id}
										icon={deletingId === m.id ? <Spinner /> : undefined}
										label="Remove"
									/>
								</Box>
							</Box>

						))}
					</Box>
				)
				: (
					<Box align="center">
						<SlimHeading level="4">no payment methods added</SlimHeading>
					</Box>
				)}
		</Box>
	);
};

export const AccountContactInformation: React.FC<AccountComponentProps> = (props) => {
	const alerts = useSnackbar();

	const getPrimaryEmail = (contacts: DTO<Contact>[]): string => {
		return contacts.find(c => c.type === ContactType.EMAIL && c.is_primary)?.value ?? "";
	};

	const getPrimaryPhone = (contacts: DTO<Contact>[]): string => {
		return contacts.find(c => c.type === ContactType.PHONE && c.is_primary)?.value ?? "";
	};

	const [ contactState, setContactState ] = useState<ContactInformationState>({
		first_name: props.customer.first_name,
		last_name: props.customer.last_name,
		contacts: props.customer.contacts ?? [],
		primary_email: getPrimaryEmail(props.customer.contacts ?? []),
		primary_phone: getPrimaryPhone(props.customer.contacts ?? []),
		wasCustomerModified: false,
		displayCreateContact: false,
		displayContactVerify: false,
		contactToVerify: null,
		isUpdatingContact: false
	});

	const updateCustomer = async (customer: DTO<Customer>) => {
		try {
			setContactState({
				...contactState,
				isUpdatingContact: true
			});

			await CustomerService.updateCustomer(props.customer.id, {
				first_name: customer.first_name,
				last_name: customer.last_name
			} as DTO<Customer>);

			alerts.enqueueSnackbar("Contact information updated!", {
				variant: "success",
				preventDuplicate: true
			});
		}
		catch(e) {
			alerts.enqueueSnackbar("Sorry, something went wrong", {
				variant: "error",
				preventDuplicate: true
			});
		}
		finally {
			setContactState({
				...contactState,
				isUpdatingContact: false,
				wasCustomerModified: false
			});
		}
	};

	const onContactCreated = (contact?: DTO<Contact>): void => {
		CustomerService.getContacts(props.customer.id)
			.then(contacts => {
				setContactState({
					...contactState,
					primary_email: getPrimaryEmail(contacts),
					primary_phone: getPrimaryPhone(contacts),
					displayCreateContact: false,
					contacts
				});
			})
			.catch(err => {
				console.error("failed to fetch contacts", err);
				setContactState({
					...contactState,
					displayCreateContact: false
				});
			});
	};

	const onContactVerified = async (contact?: DTO<Contact>): Promise<void> => {
		try {
			const contacts = await CustomerService.getContacts(props.customer.id)
				.catch(err => {
					console.error("Failed to fetch contacts", err);
					return null;
				});

			setContactState({
				...contactState,
				displayCreateContact: false,
				contactToVerify: null,
				contacts: contacts ?? contactState.contacts
			});
		}
		catch(e) {
			setContactState({
				...contactState,
				displayCreateContact: false,
				contactToVerify: null
			});
		}
	};

	const reloadContacts = async () => {
		const contacts = await CustomerService.getContacts(props.customer.id)
			.catch(err => {
				console.error("Failed to fetch contacts", err);
				return null;
			});

		if(contacts) {
			setContactState((state) => {
				return {
					...state,
					contacts,
					primary_email: getPrimaryEmail(contacts),
					primary_phone: getPrimaryPhone(contacts)
				};
			});
		}
	};

	const removeContact = async (contact: DTO<Contact>): Promise<void> => {
		if(!await CustomerService.deleteContact(props.customer.id, contact.id)
			.then(() => true)
			.catch((err) => {
				console.error("Failed to delete contact", err);
				return false;
			})) {
			alerts.enqueueSnackbar("Failed to delete contact", {
				variant: "error"
			});
			return;
		}

		await reloadContacts();
		alerts.enqueueSnackbar("Successfully deleted contact", {
			variant: "success"
		});
	};

	const renderVerifyContact = (contact: DTO<Contact>): void => {
		setContactState({
			...contactState,
			displayContactVerify: true,
			contactToVerify: contact
		});
	};

	const makeContactPrimary = async (contact: DTO<Contact>): Promise<void> => {
		const result = await CustomerService.updateContact(props.customer.id, {
			id: contact.id,
			is_primary: true
		} as DTO<Contact>)
			.catch(err => {
				console.error("Failed to update contact to primary", contact, err);
				return false;
			});

		if(!result) {
			alerts.enqueueSnackbar(`Failed to set primary contact`, {
				variant: "error"
			});
			return;
		}

		await reloadContacts();
		alerts.enqueueSnackbar(`Successfully updated primary contact`, {
			variant: "success"
		});
		return;
	};

	const handleSaveCustomerData = () => {
		updateCustomer({
			first_name: contactState.first_name,
			last_name: contactState.last_name
		} as DTO<Customer>);
	};

	const size = useScreenSize();


	return (
		<React.Fragment>
			{contactState.displayCreateContact && (
				<CreateContactModal
					onCreatedCallback={onContactCreated}
					customer={props.customer}
				/>
			)}
			{contactState.displayContactVerify && contactState.contactToVerify && (
				<VerifyContactModal
					onVerifiedCallback={onContactVerified}
					customer={props.customer}
					contact={contactState.contactToVerify}
				/>
			)}
			<Box>
				<Grid responsive columns={size === "small" ? "medium" : { count: 2, size: "medium" }} gap="medium">
					<Box>
						<Box direction="row" justify="between">
							<Heading level={4}>Personal Information</Heading>
							{contactState.wasCustomerModified && (
								<Box>
									<Button
										onClick={handleSaveCustomerData}
										icon={contactState.isUpdatingContact ? <Spinner /> : undefined}
										reverse
										label={
											(contactState.isUpdatingContact)
												? "Saving"
												: "Save Changes"
										} />
								</Box>
							)}
						</Box>
						<Form
							value={contactState}
							onChange={(state) => {
								setContactState({
									...state,
									wasCustomerModified: true
								});
							}}
						>
							<Box direction="row-responsive" gap="small">
								<Box flex>
									<FormField label="First Name" name="first_name" id="first_name">
										<TextInput name="first_name" />
									</FormField>
								</Box>
								<Box flex>
									<FormField label="Last Name" name="last_name" id="last_name">
										<TextInput name="last_name" />
									</FormField>
								</Box>
							</Box>
							<FormField readOnly label="Primary Phone Number" name="primary_phone" id="primary_phone">
								<TextInput disabled name="primary_phone" />
							</FormField>
							<FormField readOnly label="Primary Email Address" name="primary_email" id="primary_email">
								<TextInput disabled name="primary_email" />
							</FormField>
						</Form>
					</Box>
					<Box direction="column">
						<Box direction="row" justify="between">
							<Box>
								<Heading level={4}>Contact Information</Heading>
							</Box>
							<Box align="end" justify="center">
								<Box round="full" overflow="hidden" background="neutral-1">
									<Button icon={<Add />} hoverIndicator onClick={() => {
										setContactState({
											...contactState,
											displayCreateContact: true
										});
									}} />
								</Box>
							</Box>
						</Box>
						<Box gap="small">
							<Box gap="medium">
								<Text weight="bold">Phone Numbers</Text>
								<ContactMethod
									renderVerifyContact={renderVerifyContact}
									removeContact={removeContact}
									makePrimary={makeContactPrimary}
									contacts={contactState.contacts.filter(c => c.type === ContactType.PHONE)}
								/>
							</Box>
							<Box gap="small">
								<Text weight="bold">Email Addresses</Text>
								<ContactMethod
									renderVerifyContact={renderVerifyContact}
									removeContact={removeContact}
									makePrimary={makeContactPrimary}
									contacts={contactState.contacts.filter(c => c.type === ContactType.EMAIL)}
								/>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Box>
		</React.Fragment>
	);
};

export const AccountPreferences: React.FC = (props) => {
	return (
		<Box pad="medium">
			<p>Preferences</p>
		</Box>
	);
};

export const Account: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const customer = useAppSelector(selectCustomer);

	const [ index, setIndex ] = useState(0);
	const onActive = (nextIndex: number) => {
		setIndex(nextIndex);
		dispatch(push("/account"));
	};

	useEffect(() => {
		if(location.pathname === "/account/payment-methods") {
			setIndex(1);
		}
	}, []);

	useEffect(() => {
		if(!customer) {
			dispatch(setRequiresLogin(true));
		}
	});

	useEffect(() => {
		dispatch(fetchCustomer({ expand: [ "contacts", "payment_methods" ] }));
	}, []);

	if(!customer) {
		return (
			<></>
		);
	}

	return (
		<Box direction="column" margin="large">
			<Heading size="medium" level={3}>My Account</Heading>
			<Tabs
				margin="small"
				alignControls="start"
				activeIndex={index}
				onActive={onActive}
			>
				<Tab title="Contact Information">
					<AccountContactInformation customer={customer} />
				</Tab>
				<Tab title="Payment Methods">
					<PaymentMethods customer={customer} />
				</Tab>
				{/* <Tab title="Preferences">
					<Box margin="small">The second tab is active.</Box>
				</Tab>
				<Tab title="Change Password">
					<Box margin="small">The third tab is active.</Box>
				</Tab> */}
			</Tabs>
		</Box>
	);
};