import { DTO, Market } from "@rego-app/common";
import { Anchor, Box, Card, CardBody, CardHeader, Image } from "grommet";

interface MarketCardProps {
	market: DTO<Market>;
	onClick: (market: DTO<Market>) => void;
}

export const MarketCard: React.FC<MarketCardProps> = (props) => {
	return (
		<Card
			round
			width="large"
			fill
			hoverIndicator
			onClick={() => props.onClick(props.market)}
		>
			<CardHeader>
				<Image src={props.market.image_url} fit="cover" />
			</CardHeader>
			<CardBody margin="medium">
				<Box>
					<Anchor
						size="xlarge"
						label={props.market.short_name}
						onClick={() => props.onClick(props.market)}
					/>
				</Box>
			</CardBody>
		</Card>
	);
};