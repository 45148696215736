import { Contact, ContactType, DTO } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, FormField, CheckBox, TextInput, Form } from "grommet";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchCustomer, selectCustomer } from "../../../app/store/application";
import { getStandardFormValidations } from "../../../helpers";
import { CreateContactModal } from "../../auth";
import { Loader, SlimHeading } from "../../common";

interface OrderContactDetailsState {
	isCreatingContact: boolean;
	isCreatingContactType?: ContactType;
	consent: boolean;
	email_address?: string;
	phone_number?: string;
}

export const OrderContactDetails: React.FC = (props) => {
	const snack = useSnackbar();
	const customer = useAppSelector(selectCustomer);
	const dispatch = useAppDispatch();
	const [state, setState] = useState<OrderContactDetailsState>({
		isCreatingContact: false,
		consent: false,
		email_address: customer?.email_address ?? "",
		phone_number: customer?.phone_number ?? ""
	});

	useEffect(() => {
		setState((oldState) => {
			return {
				...oldState,
				email_address: customer?.email_address ?? "",
				phone_number: customer?.phone_number ?? ""
			};
		});
	}, [customer]);

	function renderCreateContact(type: ContactType): void {
		setState({
			...state,
			isCreatingContact: true,
			isCreatingContactType: type
		});
	}

	function hideContactCreate(): void {
		setState((oldState) => {
			return {
				...oldState,
				isCreatingContact: false
			};
		});
	}

	function onContactCreated(contact?: DTO<Contact>): void {
		if(!contact) {
			return hideContactCreate();
		}

		dispatch(fetchCustomer({})).unwrap()
			.catch(err => {
				console.error("Failed to load customer", err);
				snack.enqueueSnackbar("We ran into an issue loading your information", {
					variant: "error"
				});
			})
			.finally(() => {
				hideContactCreate();
			});
	}

	if(!customer) {
		return (
			<Loader visible={!customer}>

			</Loader>
		);
	}

	return (
		<Box margin="small" gap="small" align="center">
			{state.isCreatingContact && (
				<CreateContactModal
					forcePrimary={true}
					customer={customer}
					type={state.isCreatingContactType}
					onCreatedCallback={onContactCreated}
				/>
			)}
			<Box>
				<SlimHeading level="2">Contact Details</SlimHeading>
			</Box>
			<Box gap="small">
				<Form
					value={{
						phone_number: state.phone_number,
						email_address: state.email_address,
						consent: state.consent
					}}
					onChange={(changes) => {
						delete changes.phone_number;
						delete changes.email_address;
						setState({
							...state,
							...changes
						});
					}}
					validate="submit"
					onSubmit={() => {
						dispatch(push("/order/review"));
					}}
				>
					<Box>
						<FormField
							label="Email Address"
							name="email_address"
							onClick={() => {
								if(customer.email_address) {
									return;
								}
								renderCreateContact(ContactType.EMAIL);
							}}
							validate={[
								...getStandardFormValidations()
							]}
						>
							<TextInput
								name="email_address"
							/>
						</FormField>
					</Box>
					<Box>
						<FormField
							label="Phone Number"
							name="phone_number"
							onClick={() => {
								if(customer.phone_number) {
									return;
								}
								renderCreateContact(ContactType.PHONE);
							}}
							validate={[
								...getStandardFormValidations()
							]}
						>
							<TextInput
								name="phone_number"
							/>
						</FormField>
					</Box>
					<Box gap="small">
						<Box>
							<SlimHeading level="6" size="small">
								By providing this information, I authorize Rego to contact me at the phone number and email address provided for status updates and other account related notifications
							</SlimHeading>
						</Box>
						<Box>
							<FormField
								name="consent"
								contentProps={{ border: undefined }}
								validate={[
									(value) => {
										if(!value) {
											return {
												message: "You must confirm your consent",
												status: "error"
											};
										}
									}
								]}
							>
								<CheckBox
									name="consent"
									label="I Agree"
								/>
							</FormField>
						</Box>
					</Box>
					<Box direction="row" justify="between">
						<Button
							primary
							label="Previous"
							onClick={() => {
								dispatch(push("/order/timing"));
							}}
						/>
						<Button
							primary
							label="Continue"
							type="submit"
						/>
					</Box>
				</Form>
			</Box>
		</Box>

	);
};;