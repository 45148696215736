import { DeliveryAddress, DTO, Product } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, FormField, Grid, Spinner, TextInput, Text } from "grommet";
import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCustomer } from "../../../app/store/application";
import { setActiveProduct, submitOrder } from "../../../app/store/order";
import { SlimHeading, useScreenSize, useWindowDimensions } from "../../common";
import { useOrder } from "../controller";
import { ProductConfirmationCard } from "../components/ProductConfirmation";
import { Chip } from "@mui/material";

interface OrderSummaryState {
	isCreating: boolean;
	orderAddress: DeliveryAddress;
	orderProducts: DTO<Product>[];
	wasOrderCreated: boolean;
	createdProducts: DTO<Product>[];
	wereProductsCreated: boolean;
	wasProductMediaUploaded: boolean;
	errorDuringProductMediaUpload: boolean;
}

export const OrderSummary: React.FC = (props) => {
	const dimensions = useWindowDimensions();
	const { order } = useOrder();
	const snack = useSnackbar();
	const customer = useAppSelector(selectCustomer);
	const dispatch = useAppDispatch();
	const size = useScreenSize();
	const [columns, setColumns] = useState(3);

	const [state, setState] = useState<OrderSummaryState>({
		isCreating: false,
		orderAddress: order?.address ?? {},
		orderProducts: order?.products ?? [],
		wasOrderCreated: false,
		createdProducts: [],
		wereProductsCreated: false,
		wasProductMediaUploaded: false,
		errorDuringProductMediaUpload: false
	});

	async function doOrderCreate(): Promise<void> {
		setState({
			...state,
			isCreating: true
		});

		dispatch(submitOrder(order.id)).unwrap()
			.then(() => {
				dispatch(push("/order/confirmation"));
			})
			.catch(err => {
				console.error("Failed to submit order", err);
				snack.enqueueSnackbar("We ran into an issue submitting your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isCreating: false
				});
			});
	}

	function getOrderAddressLine(): string {
		return [
			order.address.address_line_one,
			order.address.address_line_two
		].filter(v => !!v).join(", ");
	}

	function getOrderCityLine(): string {
		const { address } = order;
		return `${address.city}, ${address.state} ${address.zip}`;
	}

	useEffect(() => {
		const width = dimensions.width;

		if(width >= 1200) {
			setColumns(3);
			return;
		}

		if(width >= 700) {
			setColumns(2);
			return;
		}

		setColumns(1);
	}, [dimensions.width]);

	function isProductComplete(product: DTO<Product>): boolean {
		return (!!product.item || !!product.item_input) && product.media.length > 0;
	}

	const canAdvance = useMemo(() => {
		return (order?.products ?? []).every(product => isProductComplete(product));
	}, [order]);

	const incompleteProduct = useMemo(() => {
		return (order?.products ?? []).find(product => !isProductComplete(product)) ?? null;
	}, [order]);

	return (
		<Box margin="large" gap="medium" align="center">
			<SlimHeading level="2">Order Summary</SlimHeading>
			<Grid gap="small" columns={{ count: columns, size: "auto" }}>
				{(order?.products ?? []).map(product => (
					<ProductConfirmationCard
						ignoreWidth
						key={product.id}
						product={product}
						onClick={() => {
							dispatch(
								setActiveProduct({
									...product
								})
							);
							dispatch(push("/order/products"));
						}}
						hoverIndicator
					>
						{!isProductComplete(product) && (
							<Box align="center" justify="center">
								<Chip
									label="Incomplete"
									color="warning"
								/>
							</Box>
						)}
					</ProductConfirmationCard>
				))}
			</Grid>
			<Box direction="row-responsive" justify="between" gap="medium">
				<Box flex="grow" gap="medium">
					<Box>
						<Box>
							<SlimHeading level="3">Pickup Address</SlimHeading>
						</Box>
						<Box margin="small">
							<SlimHeading level="5">{getOrderAddressLine()}</SlimHeading>
							<SlimHeading level="5">{getOrderCityLine()}</SlimHeading>
						</Box>
					</Box>
					<Box>
						<Box>
							<SlimHeading level="3">Contact Details</SlimHeading>
						</Box>
						<Box margin="small">
							<FormField
								label="Email Address"
							>
								<TextInput
									readOnly
									value={customer?.email_address}
								/>
							</FormField>
							<FormField
								label="Phone Number"
							>
								<TextInput
									readOnly
									value={customer?.phone_number}
								/>
							</FormField>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box gap="small" fill="horizontal">
				<Box direction="row" justify={size === "small" ? "between" : "around"}>
					<Button
						label="Previous"
						primary
						onClick={() => {
							dispatch(push("/order/contact"));
						}}
					/>
					<Button
						label="Submit"
						disabled={state.isCreating || !canAdvance}
						icon={state.isCreating ? <Spinner /> : undefined}
						primary
						onClick={doOrderCreate}
					/>
				</Box>
				{incompleteProduct && (
					<Box margin="medium" gap="small">
						<Text color="status-error">
							Looks like one of your items is incomplete. Please finish uploading all of the required information before proceeding.
						</Text>
						<Box align="start" margin="small">
							<Button
								label="Finish Editing"
								onClick={() => {
									dispatch(
										setActiveProduct({
											...incompleteProduct
										})
									);
									dispatch(push("/order/products"));
								}}
							/>
						</Box>
					</Box>
				)}
			</Box>
		</Box >
	);
};