import { Box, Button, Heading, Image, Stack } from "grommet";

import regoIcon from "../../../img/icon.png";

const Error404: React.FC = (props) => {
	return (
		<Box direction="column" align="center" pad={{
			top: "small",
			left: "xlarge",
			right: "xlarge",
			bottom: "small"
		}}>
			<Stack>
				<Box align="center">
					<Image src={regoIcon} width="150px" />
				</Box>
				<Box align="center">
					{/* <Heading size="xlarge" color="brand">404</Heading> */}
				</Box>
			</Stack>
			<Heading>Oops ... we couldn't find that</Heading>
			<Box direction="row" gap="medium">
				<Button label="Shop" />
				<Button label="Sell" />
				<Button label="Donate" />
			</Box>
			<Heading size="small">
				try one of these instead
			</Heading>
		</Box>
	);
};

interface ErrorProps {
	errorCode: number;
	errorMessage?: string;
}

export const ErrorPage: React.FC<ErrorProps> = (props) => {

	if(props.errorCode === 404) {
		return <Error404 />;
	}

	return (
		<Heading>Sorry about that</Heading>
	);
};