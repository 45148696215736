import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import type { DTO, Payment } from "../../types";
import { OrderService, PaymentService } from "../services";

export interface CheckoutState {
	payment: DTO<Payment> | null;
	paymentId: string;
	paymentIntent: string;
	clientSecret: string;
	isPaymentInProgress: boolean;
}

const initialState: CheckoutState = {
	paymentId: "",
	clientSecret: "",
	paymentIntent: "",
	payment: null,
	isPaymentInProgress: false
};

export const createPaymentIntent = createAsyncThunk<DTO<Payment>, { orderId: string, estimateId: string; }, { state: RootState; }>(
	"checkout/createPaymentIntent",
	async ({ orderId, estimateId }, thunk) => {
		return await OrderService.createOrderPaymentIntent(
			orderId,
			estimateId
		);
	}
);

export const fetchPayment = createAsyncThunk<DTO<Payment>, { paymentId: string, expandPaymentMethod?: boolean; }, { state: RootState; }>(
	"checkout/fetchPayment",
	async ({ paymentId, expandPaymentMethod }, thunk) => {
		return await PaymentService.getPayment(
			paymentId,
			expandPaymentMethod ? ["payment_method"] : []
		);
	}
);

export const fetchClientSecret = createAsyncThunk<string, { paymentId: string; }, { state: RootState; }>(
	"checkout/fetchClientSecret",
	async ({ paymentId }, thunk) => {
		return await PaymentService.getPaymentClientSecret(paymentId);
	}
);

export const checkoutSlice = createSlice({
	name: "checkout",
	initialState,
	reducers: {
		setPayment: (state, action: PayloadAction<DTO<Payment> | null>) => {
			state.payment = action.payload;
		},
		setPaymentId: (state, action: PayloadAction<string>) => {
			state.paymentId = action.payload;
		},
		setPaymentInProgress: (state, action: PayloadAction<boolean>) => {
			state.isPaymentInProgress = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(createPaymentIntent.fulfilled, (state, action: PayloadAction<DTO<Payment>>) => {
			state.payment = action.payload;
			state.paymentId = action.payload.id;
			state.paymentIntent = action.payload.intent_id;
		});
		builder.addCase(fetchPayment.fulfilled, (state, action: PayloadAction<DTO<Payment>>) => {
			state.payment = action.payload;
			state.paymentId = action.payload.id;
			state.paymentIntent = action.payload.intent_id;
		});
		builder.addCase(fetchClientSecret.fulfilled, (state, action: PayloadAction<string>) => {
			state.clientSecret = action.payload;
		});
	}
});

export const {
	setPayment,
	setPaymentId
} = checkoutSlice.actions;

export const selectPayment = (state: RootState): DTO<Payment> | null => state.checkout.payment;
export const selectPaymentId = (state: RootState): string => state.checkout.paymentId;
export const selectClientSecret = (state: RootState): string => state.checkout.clientSecret;

export default checkoutSlice.reducer;