import React from "react";
import { Route, Routes } from "react-router-dom";
import { FAQPage, Home, PrivacyPage, TermsPage } from "../pages";
import { ErrorPage } from "../pages/Error";
import { Account, LoginGuard } from "../../auth";
import { DashboardRouter } from "../../dashboard";
import { AdminRouter } from "../../admin";
import { OrderRouter } from "../../order";
import { ShopRouter } from "../../shop";
import { CheckoutRouter } from "../../payment";
import { ScheduleHome } from "../../schedule";
import { Logout } from "../../auth/pages/Logout";
import { DonationReferral, ReferPage } from "../../refer/pages";
import { DemoPage } from "../../demo/pages";
import { PartnerRoutes } from "../../partner";
import { ReviewController } from "../../review";
import { SlugRedirectController } from "../../shop/controller/slug";

export const Router: React.FC = (props) => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/admin/*" element={<AdminRouter />} />
			<Route path="/demo" element={<DemoPage />} />
			<Route path="/dashboard/*" element={
				<LoginGuard>
					<DashboardRouter />
				</LoginGuard>
			} />
			<Route path="/schedule/*" element={
				<LoginGuard>
					<ScheduleHome />
				</LoginGuard>
			} />
			<Route path="/order/*" element={
				<OrderRouter />
			} />
			<Route path="/refer/*" element={
				<ReferPage />
			}>
				<Route path="donation" element={
					<DonationReferral />
				} />
			</Route>
			<Route path="/partner/*" element={
				<PartnerRoutes />
			} />
			<Route path="/faq" element={<FAQPage />} />
			<Route path="/terms" element={<TermsPage />} />
			<Route path="/privacy" element={<PrivacyPage />} />

			<Route path="shop/*" element={<ShopRouter />} />
			<Route path="/account/*" element={<Account />} />
			<Route path="/checkout/*" element={<CheckoutRouter />} />
			<Route path="/review/*" element={<ReviewController />} />
			<Route path="/logout" element={<Logout />} />

			{/* Redirect Routes */}
			{/* Redirect Stores Based on Slug */}
			<Route path="/s/:slug" element={<SlugRedirectController />} />
			{/* Redirect Buildings Based on Slug */}
			<Route path="/b/:slug" element={<SlugRedirectController />} />

			<Route element={<ErrorPage errorCode={404} />} />
		</Routes>
	);
};