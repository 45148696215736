import { push } from "connected-react-router";
import { Box, Button, Grid, Heading, Paragraph } from "grommet";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectIsLoggedIn } from "../../../app/store/application";
import { fetchPayment, selectPayment } from "../../../app/store/checkout";
import { Loader, SlimHeading } from "../../common";
import { toCurrency } from "../components";

export const CheckoutConfirmation: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const payment = useAppSelector(selectPayment);
	const location = useLocation();
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Load Payment data...
	 * 
	 * Your card has been authorized for XYZ and will be captured once service completed blah blah
	 */

	useEffect(() => {
		const paymentId = new URLSearchParams(location.search).get("paymentId");
		if(!paymentId) {
			console.debug("No payment id found in redirect ... returning to dashboard");
			dispatch(push("/dashboard"));
			return;
		}

		if(!isLoggedIn) {
			console.debug("User is not logged in");
			dispatch(push("/dashboard"));
			return;
		}

		setIsLoading(true);
		dispatch(fetchPayment({ paymentId, expandPaymentMethod: true })).unwrap()
			.catch(err => {
				console.error("Error fetching payment", err);
				snack.enqueueSnackbar("We ran into some trouble loading your payment information", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<Loader visible={isLoading}>
			<Box align="center">
				<Box align="center">
					<Heading level="3">Payment Confirmation</Heading>
				</Box>
				<Box margin="medium">
					<SlimHeading level="5">
						Your card has been pre-authorized for {toCurrency(payment?.amount ?? 0)}. The charge will not be completed until after the Rego service is performed.
					</SlimHeading>
				</Box>
				<Button
					onClick={() => {
						dispatch(push("/dashboard"));
					}}
					label="Continue to Dashboard"
				/>
			</Box>
		</Loader>
	);
};