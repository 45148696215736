import React from "react";
import { Grommet } from "grommet";
import theme from "./theme";
import { ApplicationProvider, Footer, Header, Router } from "./features/common";
import { Main } from "./features/common";

function App() {
	return (
		<Grommet theme={theme} themeMode="dark">
			<Header />
			<Main>
				<ApplicationProvider>
					<Router />
				</ApplicationProvider>
			</Main>
			<Footer />
		</Grommet>
	);
}

export default App;
