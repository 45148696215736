import { ProductIntent } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box } from "grommet";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { createInboundReferral, selectActivePartner, selectInitialIntent, setForcedIntent, setInitialIntent } from "../../../app/store/order";
import { isBuildingPartner, isStorePartner } from "../../../helpers";
import { Loader } from "../../common";
import { BuildingReferral } from "./building";
import { DonationReferral } from "./donation";

export const ReferPage: React.FC = (props) => {
	const location = useLocation();
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const [isLoaded, setIsLoaded] = useState(false);
	const [hasError, setHasError] = useState(false);
	const activePartner = useAppSelector(selectActivePartner);
	const [isDonation, setIsDonation] = useState(false);
	const [isBuilding, setIsBuilding] = useState(false);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const partnerId = params.get("sid") ?? params.get("pid");
		if(!partnerId) {
			console.debug("No partner id found ... returning to home");
			dispatch(push("/"));
			return;
		}

		dispatch(createInboundReferral({ partnerId: partnerId, params: new URLSearchParams(location.search) })).unwrap()
			.catch(err => {
				console.error("Failed to create referral", err);
				snack.enqueueSnackbar("We ran into an issue processing your request", {
					variant: "error"
				});
				setHasError(true);
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}, []);

	useEffect(() => {
		if(activePartner) {
			console.debug("Got active partner", activePartner);
			if(isStorePartner(activePartner) && activePartner.service === ProductIntent.DONATE) {
				setIsDonation(true);
				console.debug("got service type", activePartner.service);
				return;
			}

			if(isBuildingPartner(activePartner)) {
				setIsBuilding(true);
				dispatch(setForcedIntent(ProductIntent.DONATE));
				dispatch(push("/order"));
				return;
			}

			console.debug("did not route to page ... pushing to home");
			dispatch(push("/"));
		}
	}, [activePartner]);

	//TODO: ERROR PAGE!
	return (
		<Loader visible={!isLoaded}>
			{isDonation && (
				<DonationReferral />
			)}
			{isBuilding && (
				<BuildingReferral />
			)}
		</Loader>
	);
};

export * from "./donation";