import { Box, Button, Heading, Layer, Text } from "grommet";
import { FormClose } from "grommet-icons";
import { useState } from "react";
import { Diagram } from "../components/Diagram";
import { HowItWorks } from "../components/HowItWorks";
import { ScanDemo, ScanModal } from "../../scan/components/Scan";

export const DemoPage: React.FC = (props) => {

	const [isDemoVisible, setDemoVisible] = useState(false);

	return (
		<Box direction="column" align="center" margin={{ bottom: "large" }}>
			<Box>
				<Heading>What's a Rego?</Heading>
			</Box>
			<Box>
				<Diagram />
			</Box>
			<Box margin="large">
				<Text weight="bolder">
					Rego is one simple platform that sells, donates, and responsibly disposes of furniture you no longer need.
				</Text>
			</Box>
			<Box>
				<Heading>Here's how it works</Heading>
			</Box>
			<Box margin="medium">
				<HowItWorks />
			</Box>
			<Box margin={{ horizontal: "large" }}>
				<Heading level="4" margin="small">
					Remember that cool fancy tool? Try it out below!
				</Heading>
				<Button
					primary
					label={(
						<Heading level="3" margin="none">Launch Demo</Heading>
					)}
					onClick={() => setDemoVisible(true)}
				/>
			</Box>
			<Box>
				{isDemoVisible && (
					<ScanModal
						onClose={() => setDemoVisible(false)}
						onSuccessfullCapture={(item, condition, media) => {
							return new Promise(() => { });
						}}
					/>
				)}
			</Box>
		</Box>
	);
};