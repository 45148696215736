import { Box, Button, Layer, LayerExtendedProps } from "grommet";
import { FormClose } from "grommet-icons";

interface ModalProps extends LayerExtendedProps {
	onClickClose?: () => void;
	onCloseAll?: () => void;
}

export const Modal: React.FC<ModalProps> = (props) => {

	function getLayerProps() {
		const layerProps = { ...props };
		delete layerProps.onClickClose;
		return layerProps;
	}

	return (
		<Layer {...getLayerProps()}>
			<Box margin="small" flex gap="small">
				{props.onClickClose && (
					<Box align="end">
						<Button
							hoverIndicator
							onClick={props.onClickClose}
							icon={
								<FormClose size="medium" />
							}
						/>
					</Box>
				)}
				{props.children}
			</Box>
		</Layer>
	);
};