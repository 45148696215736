import { Box, Button, Form, FormField, TextInput, Text, Card, CardBody, CardHeader, TextArea } from "grommet";
import { Add } from "grommet-icons";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectIsLoggedIn, setRequiresLogin, setRequiresRegistration } from "../../../app/store/application";
import { selectForcedIntent, selectOrderGoal, selectOrderIntent } from "../../../app/store/order";
import { formatCurrency } from "../../../helpers";
import { CustomerProduct, DTO, Product, ProductIntent } from "../../../types";
import { ImageCarousel, SlimHeading } from "../../common";
import { FormProgressButtons } from "./FormProgressButtons";
import { LoginRequired } from "./LoginRequired";

interface ProductConfirmationProps {
	product: DTO<CustomerProduct>;
	isProductSaving: boolean;
	onProductSave(addAnother: boolean, changes?: Partial<DTO<CustomerProduct>>): Promise<void>;
}

export const ProductConfirmation: React.FC<ProductConfirmationProps> = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const orderGoal = useAppSelector(selectOrderGoal);
	const orderIntent = useAppSelector(selectOrderIntent);
	const forcedIntent = useAppSelector(selectForcedIntent);
	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const [isSaving, setIsSaving] = useState(false);
	const [showLoginPrompt, setShowLoginPrompt] = useState(false);

	const goal = useMemo(() => {
		return orderGoal ?? orderIntent ?? forcedIntent ?? ProductIntent.SELL;
	}, [orderGoal, forcedIntent, orderIntent]);

	const intent = useMemo(() => {
		return forcedIntent ?? props.product.intent ?? orderIntent;
	}, [forcedIntent, orderIntent, props.product]);

	function handleAddAnotherProduct(): void {
		if(!isLoggedIn) {
			setShowLoginPrompt(true);
			return;
		}

		setIsSaving(true);

		props.onProductSave(true, {
			...props.product,
			goal,
			intent
		})
			.catch(err => {
				console.error("Failed to save product", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", { variant: "error" });
			})
			.finally(() => {
				setIsSaving(false);
			});
	}

	function handleContinue(): void {
		if(!isLoggedIn) {
			setShowLoginPrompt(true);
			return;
		}

		props.onProductSave(false, {
			...props.product,
			goal,
			intent
		})
			.catch(err => {
				console.error("Failed to save product", err);
				snack.enqueueSnackbar("We ran into an issue saving your information", { variant: "error" });
			})
			.finally(() => {
				setIsSaving(false);
			});
	}

	function handleRegister(): void {
		dispatch(setRequiresRegistration(true));
	}

	function handleLogin(): void {
		dispatch(setRequiresLogin(true));
	}

	useEffect(() => {
		setShowLoginPrompt(false);
	}, [isLoggedIn]);

	return (
		<Form
			validate="submit"
			onSubmit={handleContinue}
		>
			<Box gap="medium" id="product-confirmation" style={{ minWidth: "300px" }} align="center">
				<ProductConfirmationCard
					product={props.product}
				/>
				<Box gap="small">
					<SlimHeading level="4">
						Having something else to add?
					</SlimHeading>
					<Button
						primary
						icon={<Add />}
						label="Add Another"
						onClick={handleAddAnotherProduct}
					/>
				</Box>
				<Box fill="horizontal">
					<FormProgressButtons
						isLoading={props.isProductSaving || isSaving}
						forceContinueEnabled
					/>
					{showLoginPrompt && (
						<LoginRequired
							selectedLogin={handleLogin}
							selectedRegister={handleRegister}
						/>
					)}
				</Box>
			</Box>
		</Form >
	);
};

interface ProductConfirmationCardProps {
	product: DTO<CustomerProduct>;
	ignoreWidth?: boolean;
	onClick?: () => void;
	hoverIndicator?: boolean;
}

export const ProductConfirmationCard: React.FC<ProductConfirmationCardProps> = (props) => {
	const itemDescription = useMemo(() => {
		return props.product.item?.name ?? props.product.item_input ?? "";
	}, [props.product]);

	const forcedIntent = useAppSelector(selectForcedIntent);

	return (
		<Card
			width={props.ignoreWidth ? undefined : "medium"}
			onClick={props.onClick ?? undefined}
			hoverIndicator={props.hoverIndicator}
		>
			{props.product.media?.length > 0 && (
				<CardHeader flex height="small">
					<ImageCarousel
						width={"100%"}
						height={"100%"}
						product={props.product as DTO<Product>}
					/>
				</CardHeader>
			)}
			<CardBody flex>
				<Box gap="small" margin="medium">
					<Text>
						You have a(n)&nbsp;
						<Text weight="bold">
							{itemDescription}
						</Text> in&nbsp;
						<Text weight="bold">
							{props.product.condition}
						</Text> condition
					</Text>
					{(!!props.product.price) && (
						<Box flex>
							<FormField
								name="price"
								label="Listing Price"
								readOnly
								pad={false}
								contentProps={{ border: undefined }}
							>
								<TextInput
									name="price"
									disabled
									value={formatCurrency(props.product.price)}
								/>
							</FormField>
						</Box>
					)}
					{!forcedIntent && (
						<Box flex>
							<FormField
								label="Item Goal"
								readOnly
								pad={false}
								contentProps={{ border: undefined }}
							>
								<TextInput
									value={props.product.intent}
									disabled
									readOnly
									onClick={(event) => {
										event.stopPropagation();
									}}
								/>
							</FormField>
						</Box>
					)}
					{(!!props.product.description) && (
						<Box flex>
							<FormField
								label="Item Description"
								readOnly
								pad={false}
								contentProps={{ border: undefined }}
							>
								<TextArea
									resize={false}
									value={props.product.description}
									disabled
									rows={4}
									readOnly
									onClick={(event) => {
										event.stopPropagation();
									}}
								/>
							</FormField>
						</Box>
					)}
					{props.children}
				</Box>
			</CardBody>
		</Card>
	);
};