import { DayOfWeek } from "@rego-app/common";
import { Box, Text } from "grommet";
import React from "react";
import { prettyPrintHoursForDay } from "../../../helpers";
import type { StoreHours } from "../../../types";

export const StoreHoursList: React.FC<{ hours: StoreHours; }> = (props) => {
	function getStoreHours(day: DayOfWeek): string {
		if(!props.hours) {
			return "-";
		}

		return prettyPrintHoursForDay(props.hours, day);
	}

	return (
		<Box gap="small">
			<Box direction="row" gap="small" justify="between">
				<Text weight="bold">Sunday</Text>
				<Text textAlign="end" margin={{ horizontal: "small" }}>{getStoreHours(DayOfWeek.SUNDAY)}</Text>
			</Box>
			<Box direction="row" gap="small" justify="between">
				<Text weight="bold">Monday</Text>
				<Text textAlign="end" margin={{ horizontal: "small" }}>{getStoreHours(DayOfWeek.MONDAY)}</Text>
			</Box>
			<Box direction="row" gap="small" justify="between">
				<Text weight="bold">Tuesday</Text>
				<Text textAlign="end" margin={{ horizontal: "small" }}>{getStoreHours(DayOfWeek.TUESDAY)}</Text>
			</Box>
			<Box direction="row" gap="small" justify="between">
				<Text weight="bold">Wednesday</Text>
				<Text textAlign="end" margin={{ horizontal: "small" }}>{getStoreHours(DayOfWeek.WEDNESDAY)}</Text>
			</Box>
			<Box direction="row" gap="small" justify="between">
				<Text weight="bold">Thursday</Text>
				<Text textAlign="end" margin={{ horizontal: "small" }}>{getStoreHours(DayOfWeek.THURSDAY)}</Text>
			</Box>
			<Box direction="row" gap="small" justify="between">
				<Text weight="bold">Friday</Text>
				<Text textAlign="end" margin={{ horizontal: "small" }}>{getStoreHours(DayOfWeek.FRIDAY)}</Text>
			</Box>
			<Box direction="row" gap="small" justify="between">
				<Text weight="bold">Saturday</Text>
				<Text textAlign="end" margin={{ horizontal: "small" }}>{getStoreHours(DayOfWeek.SATURDAY)}</Text>
			</Box>
		</Box>
	);
};