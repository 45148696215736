import { push } from "connected-react-router";
import { Box, Button, CheckBox, DateInput, Form, FormField, Spinner, TextInput } from "grommet";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCutoffDate, setCutoffDate, updateOrder } from "../../../app/store/order";
import { useOrder } from "../controller";
import moment from "moment-timezone";
import { getStandardFormValidations } from "../../../helpers";
import { DTO, MoveDetails, Order } from "@rego-app/common";
import { ReferenceService } from "../../../app/services";
import { useTimezone } from "../../common";

export const OrderTiming: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const cutoffDate = useAppSelector(selectCutoffDate);
	const snack = useSnackbar();
	const { order } = useOrder();
	const timezone = useTimezone();
	const [isMoving, setIsMoving] = useState(!!order?.move_details);
	const [wantsMovingHelp, setWantsMovingHelp] = useState(!!order?.move_details);
	const [destinationZipCode, setDestinationZipCode] = useState(order?.move_details?.destination?.zip ?? "");
	const [hasCutoffDate, setHasCutoffDate] = useState(!!(order.cutoff_date ?? cutoffDate));
	const [isSaving, setIsSaving] = useState(false);

	async function handleOrderUpdates(): Promise<DTO<Order>> {
		const updates: Partial<Order> = {
			cutoff_date: (hasCutoffDate && cutoffDate) ? new Date(cutoffDate) : null
		};

		if(isMoving && wantsMovingHelp && hasCutoffDate && cutoffDate) {
			const zone = await ReferenceService.getZoneByZipCode(destinationZipCode).catch(err => {
				console.error(`failed to get zone with zip [${destinationZipCode}]`, err);
				return null;
			});
			console.debug(`got zone from zip [${destinationZipCode}]`, zone);

			if(zone) {
				updates.move_details = {
					date: moment.tz(new Date(cutoffDate), timezone).toDate(),
					origin: order.address,
					destination: {
						address_line_one: "",
						address_line_two: "",
						city: zone.primary_city,
						state: zone.primary_state,
						zip: destinationZipCode,
						has_driveway: false,
						has_elevator: false,
						has_parking: false,
						floor: 1,
						notes: "",
						latitude: "",
						longitude: "",
						timezone
					}
				} as MoveDetails;
			}
		}

		return await dispatch(updateOrder({ orderId: order.id, data: updates })).unwrap();
	}

	function saveOrder(): void {
		setIsSaving(true);

		handleOrderUpdates()
			.then(() => {
				dispatch(push("/order/contact"));
			})
			.catch(err => {
				console.error("failed to update order", err);
				snack.enqueueSnackbar("We ran into an issue updating your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsSaving(false);
			});

		return;;
	}

	return (
		<Box margin="large" gap="small" align="center">
			<Box>

			</Box>
			<Form
				value={{
					isMoving,
					hasCutoffDate,
					cutoffDate,
					destinationZipCode,
					wantsMovingHelp
				}}
				validate="submit"
				onSubmit={saveOrder}
				onChange={(changes) => {
					setHasCutoffDate(changes.hasCutoffDate);
					setIsMoving(changes.isMoving);
					setDestinationZipCode(changes.destinationZipCode);
					setWantsMovingHelp(changes.wantsMovingHelp);
					dispatch(setCutoffDate(changes.cutoffDate));
				}}
			>
				<Box gap="small">
					<Box>
						<FormField
							name="hasCutoffDate"
							label="Do you need your item(s) out by a specific date?"
							help="move out date, renovation date, etc."
							contentProps={{ border: undefined }}
						>
							<CheckBox
								name="hasCutoffDate"
								label="Yes"
							/>
						</FormField>
						{hasCutoffDate && (
							<Box>
								<FormField
									label="Move Out / Cutoff Date"
									name="cutoffDate"
									info="We'll do our best to make sure your item(s) are picked up before this date"
									validate={[
										...getStandardFormValidations()
									]}
								>
									<DateInput
										format="mm/dd/yyyy"
										name="cutoffDate"
										calendarProps={{
											bounds: [
												moment().add(1, "days").toDate().toISOString(),
												moment().add(365, "days").toDate().toISOString()
											],
											firstDayOfWeek: 0
										}}
									/>
								</FormField>
								<FormField
									name="isMoving"
									label="Are you moving?"
									contentProps={{ border: undefined }}
								>
									<CheckBox
										name="isMoving"
										label="Yes"
									/>
								</FormField>
								{isMoving && (
									<>
										<FormField
											name="wantsMovingHelp"
											label="Do you need moving help?"
											help="We can connect you one of our preferred moving partners who can offer flexible moving options"
											contentProps={{ border: undefined }}
										>
											<CheckBox
												name="wantsMovingHelp"
												label="Yes"
											/>
										</FormField>
										{wantsMovingHelp && (
											<FormField
												name="destinationZipCode"
												label="What zip code are you moving to?"
												validate={[
													...getStandardFormValidations(),
													(value: unknown) => {
														if(!/\d{5}/.test(String(value))) {
															return {
																status: "error",
																message: "Enter a valid zip code"
															};
														}
													}
												]}
												info="By providing this information, you are authorizing Rego to share your name and email address with our partners. You may unsubscribe at any tine."
											>
												<TextInput
													name="destinationZipCode"
												/>
											</FormField>
										)}
									</>
								)}
							</Box>
						)}
					</Box>
					<Box direction="row" justify="between">
						<Button
							primary
							label="Previous"
							disabled={isSaving}
							onClick={() => {
								dispatch(push("/order/address"));
							}}
						/>
						<Button
							primary
							label="Continue"
							type="submit"
							disabled={isSaving}
							icon={isSaving ? <Spinner /> : undefined}
						/>
					</Box>
				</Box>
			</Form>
		</Box>
	);
};