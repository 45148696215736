import { LinearProgress } from "@material-ui/core";
import { CustomerProduct, Delivery, DeliveryWindow, Donation, DonationMedia, DTO, Estimate, Order, Product, ProductIntent, Removal, Service, ServiceInvoice, Store, StoreContact, StoreHours, Timezone, Vehicle } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, CheckBox, ColumnConfig, DataTable, Form, FormField, Grid, Select, Spinner, Text, TextArea, TextInput } from "grommet";
import { FormNext } from "grommet-icons";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { addSelectedProductForService, fetchDonation, fetchDonationOrRemoval, fetchInboundReferral, fetchOrder, fetchRemoval, listDonations, listRemovals, listStores, recordOpenedDonation, recordOpenedRemoval, removeSelectedProductForService, resetSelectedProductForService, selectActiveDonation, selectActiveRemoval, selectActiveServiceProducts, selectDonationHistory, selectDonations, selectInboundReferral, selectRemovalHistory, selectRemovals, selectStores } from "../../../app/store/admin";
import { getOrFetchVehicles, selectVehicles } from "../../../app/store/reference";
import { formatCurrency, getNumberFormValidations, getStandardFormValidations, isDonation, isStorePartner, parseDateFromUTC, parseTimestampFromUTC } from "../../../helpers";
import { RecentRecord, UnionProduct } from "../../../types";
import { FieldWithActionButton, Loader, MapComponent, MapMarker, Modal, Pagination, pickupWindows, SlimHeading, useWindowDimensions } from "../../common";
import { AddProductToService, DonationMediaContainer, ProductSummary, ServiceDetails, ServiceEstimates, ServicePayments, ServiceProducts, ServiceTrackingDates, StoreHoursList, UploadDonationMediaModal } from "../components";
import { navigateToDelivery, navigateToDonation, navigateToRemoval } from "../routes";


interface ServiceStepProps {
	state: SubmitServiceControllerState;
	updateState(updates: Partial<SubmitServiceControllerState>): void;
}

interface CreateServiceEstimateStepProps extends ServiceStepProps {
	onCreateEstimate(price: number, vendorPrice: number): void;
	onSelectEstimate(estimate: DTO<Estimate>): void;
}

export const CreateServiceEstimateStep: React.FC<CreateServiceEstimateStepProps> = (props) => {
	const [ price, setPrice ] = useState("");
	const [ vendorPrice, setVendorPrice ] = useState("");
	const [ existingEstimate, setExistingEstimate ] = useState<DTO<Estimate> | null>(null);

	useEffect(() => {
		if(props.state.service.estimates?.length > 0) {
			const estimates = [ ...props.state.service.estimates ].sort((a, b) => {
				return moment(b.created_at).unix() - moment(a.created_at).unix();
			});

			setExistingEstimate(estimates[ 0 ]);
		}
	}, [ props.state.service ]);

	return (
		<Box gap="small" flex>
			<Text>
				This step creates the estimate for the service.
			</Text>
			<Form
				validate="submit"
				onSubmit={() => {
					props.onCreateEstimate(Number(price), Number(vendorPrice));
				}}
			>
				<FormField
					name="price"
					label="Price"
					validate={[
						...getStandardFormValidations(),
						...getNumberFormValidations()
					]}
					onChange={(event) => {
						setPrice(event.target.value);
					}}
				>
					<TextInput
						name="price"
						value={price}
					/>
				</FormField>
				<FormField
					name="vendorPrice"
					label="Vendor Price"
					validate={[
						...getStandardFormValidations(),
						...getNumberFormValidations()
					]}
					onChange={(event) => {
						setVendorPrice(event.target.value);
					}}
				>
					<TextInput
						name="vendorPrice"
						value={vendorPrice}
					/>
				</FormField>
				<Box align="end" justify="end" flex>
					<Button
						type="submit"
						primary
						disabled={props.state.isCreatingEstimate}
						icon={props.state.isCreatingEstimate ? <Spinner /> : undefined}
						label="Create Estimate"
					/>
				</Box>
			</Form>
			{existingEstimate && (
				<Box gap="small">
					<Text>
						We found an existing estimate for this service with a price of {formatCurrency(existingEstimate.base_amount)}. Do you want to use that one?
					</Text>
					<Button
						primary
						label="Use Existing Estimate"
						onClick={() => {
							props.onSelectEstimate(existingEstimate);
						}}
					/>
				</Box>
			)}
			<Box>

			</Box>
		</Box>
	);
};

interface ActivateEstimateStepProps extends ServiceStepProps {
	onSelectEstimate(estimate: DTO<Estimate>): void;
}

export const ActivateEstimateStep: React.FC<ActivateEstimateStepProps> = (props) => {
	return (
		<Box gap="small" flex>
			<Text>
				This step selects the estimate (created earlier) and triggers the email to the customer.
			</Text>
			<Box gap="small" flex>
				<Box flex justify="end" align="end">
					<Button
						primary
						label="Select Estimate"
						disabled={props.state.isUpdatingEstimate}
						icon={props.state.isUpdatingEstimate ? <Spinner /> : undefined}
						onClick={() => props.onSelectEstimate(props.state.estimate as DTO<Estimate>)}
					/>
				</Box>
			</Box>
		</Box>
	);
};

interface CreateServiceDeliveryStepProps extends ServiceStepProps {
	onCreateDelivery(): void;
}

export const CreateServiceDeliveryStep: React.FC<CreateServiceDeliveryStepProps> = (props) => {
	const [ scheduleNow, setScheduleNow ] = useState(false);

	return (
		<Box gap="small" flex>
			<Text>
				This step creates the pickup record for the service (does not send to vendor).
			</Text>
			<Box gap="small" flex>
				<Box flex justify="end" align="end">
					<Button
						primary
						label="Create Delivery"
						disabled={props.state.isCreatingDelivery}
						icon={props.state.isCreatingDelivery ? <Spinner /> : undefined}
						onClick={props.onCreateDelivery}
					/>
				</Box>
			</Box>
		</Box>
	);
};

interface MakeServiceVisibleStepProps extends ServiceStepProps {
	onMakeVisible(): void;
}

export const MakeServiceVisibleStep: React.FC<MakeServiceVisibleStepProps> = (props) => {
	return (
		<Box gap="small" flex>
			<Text>
				This service is not visible yet. Customers cannot schedule from the dashboard until the service is made visible to them.
			</Text>
			<Box gap="small" flex>
				<Box flex justify="end" align="end">
					<Button
						primary
						label="Make Visible"
						disabled={props.state.isUpdatingService}
						icon={props.state.isUpdatingService ? <Spinner /> : undefined}
						onClick={props.onMakeVisible}
					/>
				</Box>
			</Box>
		</Box>
	);
};

interface SubmitServiceControllerState {
	isUpdatingService: boolean;
	isCreatingEstimate: boolean;
	isCreatingDelivery: boolean;
	isUpdatingDelivery: boolean;
	isUpdatingEstimate: boolean;
	service: DTO<Service>;
	delivery: DTO<Delivery> | null;
	estimate: DTO<Estimate> | null;
}

interface SubmitServiceControllerProps {
	intent: ProductIntent;
	service: DTO<Service>;
	onClose(): void;
}

export const SubmitServiceController: React.FC<SubmitServiceControllerProps> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const [ state, setState ] = useState<SubmitServiceControllerState>({
		estimate: null,
		service: props.service,
		delivery: props.service.delivery ?? null,
		isUpdatingService: false,
		isCreatingDelivery: false,
		isCreatingEstimate: false,
		isUpdatingDelivery: false,
		isUpdatingEstimate: false
	});

	useEffect(() => {
		if(props.service) {
			updateState({
				service: props.service,
				delivery: props.service.delivery ?? state.delivery
			});
		}
	}, [ props.service, state.delivery ]);

	useEffect(() => {
		if(props.service) {
			const estimate = (props.service.estimates ?? []).find(e => e.selected);
			if(estimate) {
				updateState({ estimate });
			}
		}
	}, [ props.service ]);

	function updateState(changes: Partial<SubmitServiceControllerState>): void {
		setState((state) => {
			return {
				...state,
				...changes
			};
		});
	}

	function createDelivery(): void {
		updateState({ isCreatingDelivery: true });

		OrderService.createServiceDelivery(
			props.service.order.id,
			props.service.id,
			props.service.vehicle,
			true,
			props.service.products
		)
			.then(delivery => {
				updateState({ delivery });
				return (props.intent === ProductIntent.DONATE)
					? dispatch(fetchDonation(props.service.id))
					: dispatch(fetchRemoval(props.service.id));
			})
			.catch(err => {
				console.error("Failed to create delivery", err);
				snack.enqueueSnackbar("Failed to create delivery", { variant: "error" });
			})
			.finally(() => {
				updateState({ isCreatingDelivery: false });
			});
	}

	function createEstimate(price: number, vendorPrice: number): void {
		updateState({ isCreatingEstimate: true });

		OrderService.createServiceEstimate(
			props.service.order.id,
			props.service.id,
			props.service.vehicle,
			{} as DeliveryWindow,
			true,
			price,
			vendorPrice
		)
			.then(estimate => {
				updateState({ estimate });
				return dispatch(fetchDonation(props.service.id));
			})
			.catch(err => {
				console.error("Failed to create estimate", err);
				snack.enqueueSnackbar("Failed to create estimate", { variant: "error" });
			})
			.finally(() => {
				updateState({ isCreatingEstimate: false });
			});
	}

	function selectExistingEstimate(estimate: DTO<Estimate>): void {
		updateState({ estimate });
	}

	function makeServiceVisible(): void {
		updateState({ isUpdatingService: true });

		OrderService.updateService(props.service.id, { visible: true })
			.then(() => {
				return (props.intent === ProductIntent.DONATE)
					? dispatch(fetchDonation(props.service.id)).unwrap()
					: dispatch(fetchRemoval(props.service.id)).unwrap();
			})
			.catch(err => {
				console.error("Failed to update service", err);
				snack.enqueueSnackbar("Failed to update service", { variant: "error" });
			})
			.finally(() => {
				updateState({ isUpdatingService: false });
			});
	}

	function updateAndSelectEstimate(estimate: DTO<Estimate>): void {
		updateState({ isUpdatingEstimate: true });

		OrderService.selectServiceEstimate(props.service.order.id, props.service.id, estimate.id)
			.then((estimate) => {
				updateState({ estimate });
				return (props.intent === ProductIntent.DONATE)
					? dispatch(fetchDonation(props.service.id)).unwrap()
					: dispatch(fetchRemoval(props.service.id)).unwrap();
			})
			.catch(err => {
				console.error("Failed to update estimate", err);
				snack.enqueueSnackbar("Failed to update estimate", { variant: "error" });
			})
			.finally(() => {
				updateState({ isUpdatingEstimate: false });
			});
	}

	if(!state.estimate) {
		return (
			<CreateServiceEstimateStep
				state={state}
				updateState={updateState}
				onCreateEstimate={createEstimate}
				onSelectEstimate={selectExistingEstimate}
			/>
		);
	}

	if(!state.delivery) {
		return (
			<CreateServiceDeliveryStep
				state={state}
				updateState={updateState}
				onCreateDelivery={createDelivery}
			/>
		);
	}

	if(!state.estimate.selected) {
		return (
			<ActivateEstimateStep
				state={state}
				updateState={updateState}
				onSelectEstimate={updateAndSelectEstimate}
			/>
		);
	}

	if(!state.service.visible) {
		return (
			<MakeServiceVisibleStep
				state={state}
				updateState={updateState}
				onMakeVisible={makeServiceVisible}
			/>
		);
	}

	return (
		<Box gap="small" flex>
			<Text>
				All done! This service is ready to be scheduled by the customer.
			</Text>
			<Box flex align="end" justify="end">
				<Button
					primary
					label="Close"
					onClick={props.onClose}
				/>
			</Box>
		</Box>
	);
};

interface SubmitServiceModalProps {
	intent: ProductIntent;
	service: DTO<Service>;
	onClose(): void;
}

export const SubmitServiceModal: React.FC<SubmitServiceModalProps> = (props) => {
	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box margin="small" style={{ minHeight: "200px", minWidth: "400px", maxWidth: "600px" }}>
				<SubmitServiceController
					intent={props.intent}
					onClose={props.onClose}
					service={props.service}
				/>
			</Box>
		</Modal>
	);
};



interface ServiceDetailsState {
	isLoadingInvoices: boolean;
	isLoadingService: boolean;
	isAddingProduct: boolean;
	isUpdatingVisibility: boolean;
	isConfirmationFlow: boolean;
	isUpdatingProducts: boolean;
	isUploadingMedia: boolean;
}

interface AdminServiceDetailsScreenProps {
	intent: ProductIntent;
}

export const AdminServiceDetailsScreen: React.FC<AdminServiceDetailsScreenProps> = (props) => {
	const params = useParams();
	const snack = useSnackbar();
	const dimensions = useWindowDimensions();
	const dispatch = useAppDispatch();
	const [ media, setMedia ] = useState<DTO<DonationMedia>[]>([]);

	const _removal = useAppSelector(selectActiveRemoval);
	const _donation = useAppSelector(selectActiveDonation);

	const service = useMemo(() => {
		return (props.intent === ProductIntent.DONATE)
			? _donation
			: _removal;
	}, [ props.intent, _donation, _removal ]);
	const [ invoices, setInvoices ] = useState<DTO<ServiceInvoice>[]>([]);

	const [ state, setState ] = useState<ServiceDetailsState>({
		isLoadingInvoices: false,
		isLoadingService: false,
		isAddingProduct: false,
		isUpdatingVisibility: false,
		isConfirmationFlow: false,
		isUpdatingProducts: false,
		isUploadingMedia: false
	});

	useEffect(() => {
		if(service && isDonation(service)) {
			OrderService.listDonationMedia(service.id)
				.then(res => {
					setMedia([ ...res ]);
				})
				.catch(err => {
					console.error("Failed to load donation media", err);
				});
		}
	}, [ service ]);

	function loadInvoices(serviceId: string): void {
		setState({ ...state, isLoadingInvoices: true });
		OrderService.listServiceInvoices(serviceId, [ "estimate" ]).then(res => {
			setInvoices([ ...res ]);
		}).catch(err => {
			console.error("Failed to get invoices", err);
		}).finally(() => {
			setState({ ...state, isLoadingInvoices: false });
		});
	}

	useEffect(() => {
		if(service) {
			loadInvoices(service.id);
		}
	}, [ service ]);

	const columns = useMemo(() => {
		switch(dimensions.size) {
			case "small": {
				return 1;
			}
			case "medium": {
				return 1;
			}
			case "large": {
				return 2;
			}
			case "xlarge": {
				return 2;
			}
		}
	}, [ dimensions.size ]);

	const serviceName = useMemo(() => {
		switch(props.intent) {
			case ProductIntent.DONATE: {
				return "Donation";
			}
			case ProductIntent.JUNK: {
				return "Removal";
			}
			default: {
				throw new Error(`invalid intent [${props.intent}]`);
			}
		}
	}, [ props.intent ]);

	function toggleVisibility(): void {
		if(!service) {
			return;
		}

		const estimate = service.estimates ?? [];
		if(!service.visible && !estimate.some(e => e.selected)) {
			snack.enqueueSnackbar("This service has not been confirmed. Please complete the confirmation flow before making this service visible.", { variant: "warning" });
			return;
		}

		setState({
			...state,
			isUpdatingVisibility: true
		});

		OrderService.updateService(service.id, { visible: !service.visible })
			.then(() => {
				return dispatch(fetchDonationOrRemoval(props.intent)(service.id)).unwrap();
			})
			.catch(err => {
				console.error("Failed to update service visibility", err);
				snack.enqueueSnackbar("Failed to update service", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isUpdatingVisibility: false
				});
			});
	}

	function renderAddProductModal(): void {
		setState({
			...state,
			isAddingProduct: true
		});
	}

	function handleFetchService(serviceId: string): void {
		setState({
			...state,
			isLoadingService: true
		});

		dispatch(fetchDonationOrRemoval(props.intent)(serviceId)).unwrap()
			.catch(err => {
				console.error("Failed to load service", err);
				snack.enqueueSnackbar("Failed to load service", {
					variant: "error"
				});
			})
			.finally(() => {
				setState({
					...state,
					isLoadingService: false
				});
			});
	}

	function handleAddProducts(products: DTO<Product>[]): void {
		if(!service) {
			return;
		}

		setState({
			...state,
			isUpdatingProducts: true
		});

		Promise.all(products.map(product => {
			return OrderService.addProductToService(
				service.id,
				product.id
			);
		}))
			.then(() => {
				return dispatch(fetchDonationOrRemoval(props.intent)(service.id)).unwrap();
			})
			.catch(err => {
				console.error("Failed to add product to service", err);
				snack.enqueueSnackbar("Failed to add product to service", err);
			})
			.finally(() => {
				setState({
					...state,
					isAddingProduct: false,
					isUpdatingProducts: false
				});
			});
	}

	useEffect(() => {
		if(!params.serviceId) {
			dispatch(push("/admin/dashboard/orders"));
			return;
		}

		handleFetchService(params.serviceId);
	}, []);

	return (
		<Box margin="large" gap="medium">
			<Box direction="row-responsive" gap="small">
				{(service && !service.scheduled) && (
					<Button
						primary
						label="Start Confirmation Flow"
						onClick={() => {
							setState({
								...state,
								isConfirmationFlow: true
							});
						}}
					/>
				)}
			</Box>
			{(service && state.isConfirmationFlow) && (
				<SubmitServiceModal
					intent={ProductIntent.DONATE}
					service={service}
					onClose={() => {
						setState({
							...state,
							isConfirmationFlow: false
						});
					}}
				/>
			)}
			{state.isAddingProduct && (
				<AddProductToService
					service={service as DTO<Service>}
					isSaving={state.isUpdatingProducts}
					onSave={handleAddProducts}
					onClose={() => {
						setState({
							...state,
							isAddingProduct: false
						});
					}}
				/>
			)}
			{(state.isUploadingMedia && service && isDonation(service)) && (
				<UploadDonationMediaModal
					donation={service}
					onClose={() => setState({ ...state, isUploadingMedia: false })}
				/>
			)}
			<Grid columns={{ count: columns, size: "auto" }} gap="small">
				<Box flex>
					{service
						? (
							<Box gap="small" flex>
								<SlimHeading level="3">
									{serviceName} Details
								</SlimHeading>
								<ServiceDetails
									intent={props.intent}
									service={service}
								/>
							</Box>
						)
						: (
							<Box align="center" justify="center" flex>
								<Spinner size="large" />
							</Box>
						)
					}
				</Box>
				<Box flex="grow" gap="small">
					<Box gap="small" flex>
						<SlimHeading level="3">Pickup Details</SlimHeading>
						{!!service
							? (
								<Box>
									<FieldWithActionButton
										name="id"
										label="Delivery Id"
										disabled={!service.delivery?.id}
										icon={<FormNext />}
										value={service.delivery?.id ?? ""}
										onClick={() => {
											service.delivery && navigateToDelivery(service.delivery.id, dispatch);
										}}
									/>
									<FormField
										label="Rego Delivery Number"
									>
										<TextInput
											value={service.delivery?.number}
										/>
									</FormField>
									<FormField
										label="Delivery Partner"
									>
										<TextInput
											value={service.delivery?.partner?.name}
										/>
									</FormField>
									<FormField
										label="Delivery Partner Reference Id"
									>
										<TextInput
											value={service.delivery?.reference_id}
										/>
									</FormField>
								</Box>
							)
							: (
								<Box align="center" justify="center" flex>
									<Spinner size="large" />
								</Box>
							)
						}
					</Box>
				</Box>
				<Box gap="small" flex>
					<SlimHeading level="3">
						Tracking Dates
					</SlimHeading>
					{service
						? (
							<ServiceTrackingDates
								intent={props.intent}
								service={service}
							/>
						)
						: (
							<Box align="center" justify="center" flex>
								<Spinner size="large" />
							</Box>
						)}
				</Box>
				<Box flex="grow">
					{(service?.products ?? []).length > 0 && service
						? (
							<Box gap="small" flex>
								<SlimHeading level="3">
									{serviceName} Products
								</SlimHeading>
								<ServiceProducts
									intent={props.intent}
									service={service}
								/>
							</Box>
						)
						: (
							<Box align="center" justify="center" flex>
								<SlimHeading level="4">no service products</SlimHeading>
							</Box>
						)
					}
				</Box>
				<Box flex="grow">
					<Box gap="small" flex>
						<SlimHeading level="3">
							{serviceName} Estimates
						</SlimHeading>
						{(service?.estimates ?? []).length > 0 && service
							? (
								<Loader visible={state.isLoadingInvoices}>
									<ServiceEstimates
										intent={props.intent}
										service={service}
										invoices={invoices}
										onReloadInvoices={() => loadInvoices(service.id)}
									/>
								</Loader>
							)
							: (
								<Box align="center" justify="center" flex>
									<SlimHeading level="4">no service estimates</SlimHeading>
								</Box>
							)
						}
					</Box>
				</Box>
				<Box flex="grow">
					<Box gap="small" flex>
						<SlimHeading level="3">
							{serviceName} Payments
						</SlimHeading>
						{(service?.payments ?? []).length > 0 && service
							? (
								<ServicePayments
									intent={props.intent}
									service={service}
								/>
							)
							: (
								<Box align="center" justify="center" flex>
									<SlimHeading level="4">no service payments</SlimHeading>
								</Box>
							)
						}
					</Box>
				</Box>
				{(service && isDonation(service) && media.length > 0) && (
					<Box flex="grow">
						<Box gap="small" flex>
							<SlimHeading level="3">
								Donation Media
							</SlimHeading>
							<DonationMediaContainer
								media={media}
								donation={service}
							/>
						</Box>
					</Box>
				)}
			</Grid>
			<Box gap="small" align="start" direction="row-responsive">
				<Button
					primary
					label={service?.visible ? "Hide From Customer" : "Make Visible"}
					icon={state.isUpdatingVisibility ? <Spinner /> : undefined}
					disabled={state.isUpdatingVisibility || service?.scheduled}
					onClick={toggleVisibility}
				/>
				<Button
					primary
					label="Add Product"
					disabled={service?.scheduled}
					onClick={renderAddProductModal}
				/>
				{props.intent === ProductIntent.DONATE && (
					<Button
						primary
						label="Upload Donation Media"
						onClick={() => setState({ ...state, isUploadingMedia: true })}
					/>
				)}
			</Box>
		</Box>
	);
};

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code;

const recentServiceColumns: ColumnConfig<DTO<Service>>[] = [
	{
		property: "number",
		header: "Service Number",
		primary: true,
		sortable: true,
		render: (service) => service.number
	},
	{
		property: "scheduled_at",
		header: "Scheduled At",
		primary: false,
		sortable: true,
		render: (service) => parseTimestampFromUTC(service.scheduled_at, timezone)
	},
	{
		property: "scheduled_for",
		header: "Scheduled For",
		primary: false,
		sortable: true,
		render: (service) => (service.scheduled_window) ? `${parseDateFromUTC(service.scheduled_window.date, timezone)} (${moment().hours(service.scheduled_window.from).minutes(0).format("hh:mm A")} - ${moment().hours(service.scheduled_window.to).minutes(0).format("hh:mm A")})` : ""
	},
	{
		property: "completed_at",
		header: "Completed At",
		primary: false,
		sortable: true,
		render: (service) => parseTimestampFromUTC(service.completed_at, timezone)
	},
	{
		property: "store",
		header: "Store",
		primary: false,
		sortable: true,
		render: (service) => service.store?.name ?? ""
	}
];

interface AdminServicesPageState {
	isLoading: boolean;
	loadedRecentServices: boolean;
	loadedServices: boolean;
	isModalVisible: boolean;
	showService: boolean;
	activeService?: DTO<Service>;
	recentServices: DTO<Service>[];
}

export const AdminServicesPage: React.FC<{ intent: ProductIntent; }> = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [ state, setState ] = useState<AdminServicesPageState>({
		isLoading: false,
		isModalVisible: false,
		showService: false,
		loadedServices: false,
		loadedRecentServices: false,
		recentServices: []
	});

	const donations = useAppSelector(selectDonations);
	const recentDonations = useAppSelector(selectDonationHistory);
	const removals = useAppSelector(selectRemovals);
	const recentRemovals = useAppSelector(selectRemovalHistory);

	const services = useMemo((): DTO<Service>[] => {
		switch(props.intent) {
			case ProductIntent.DONATE: {
				return donations;
			}
			case ProductIntent.JUNK: {
				return removals;
			}
			default: {
				throw new Error(`Invalid intent [${props.intent}]`);
			}
		}
	}, [ props.intent, donations, removals ]);

	const recentServices = useMemo((): RecentRecord[] => {
		switch(props.intent) {
			case ProductIntent.DONATE: {
				return recentDonations;
			}
			case ProductIntent.JUNK: {
				return recentRemovals;
			}
			default: {
				throw new Error(`Invalid intent [${props.intent}]`);
			}
		}
	}, [ props.intent, recentDonations, recentRemovals ]);

	function handleNavigate(service: DTO<Service>): void {
		switch(props.intent) {
			case ProductIntent.DONATE: {
				dispatch(recordOpenedDonation(service as DTO<Donation>));
				dispatch(push(`/admin/dashboard/donations/${service.id}`));
				break;
			}
			case ProductIntent.JUNK: {
				dispatch(recordOpenedRemoval(service as DTO<Removal>));
				dispatch(push(`/admin/dashboard/removals/${service.id}`));
				break;
			}
			default: {
				throw new Error(`Invalid intent [${props.intent}]`);
			}
		}
	}

	useEffect(() => {
		if(state.loadedServices && !state.loadedRecentServices) {
			setState({ ...state, isLoading: true });
			Promise.all(recentServices.map(record => {
				const existing = services.find(o => o.id === record.id);
				if(existing) {
					return existing;
				}

				return OrderService.getService(record.id);
			}))
				.then(results => {
					setState(state => {
						return { ...state, recentServices: [ ...results ] };
					});
				})
				.catch(err => {
					console.error(`Failed to fetch recent services`, err);
					snack.enqueueSnackbar("Failed to load recent services", {
						variant: "error"
					});
				})
				.finally(() => {
					setState(state => {
						return { ...state, loadedRecentServices: true };
					});
				});
		}
	}, [ state.loadedServices, state.loadedRecentServices ]);

	useEffect(() => {
		setState({ ...state, isLoading: true, loadedRecentServices: false, loadedServices: false });

		(() => {
			switch(props.intent) {
				case ProductIntent.DONATE: {
					return dispatch(listDonations({ filters: {}, expand: [ "delivery", "order", "products", "store" ] })).unwrap();
				}
				case ProductIntent.JUNK: {
					return dispatch(listRemovals({ filters: {}, expand: [ "delivery", "order", "products", "store" ] })).unwrap();
				}
				default: {
					throw new Error(`Invalid intent [${props.intent}]`);
				}
			}
		})()
			.catch(err => {
				console.error(`Failed to fetch recent services`, err);
				snack.enqueueSnackbar("Failed to load recent services", {
					variant: "error"
				});
			})
			.finally(() => {
				setState(state => {
					return { ...state, loadedServices: true };
				});
			});
	}, [ props.intent ]);

	useEffect(() => {
		if(state.loadedServices && state.loadedRecentServices) {
			setState({
				...state,
				isLoading: false
			});
		}
	}, [ state.loadedServices, state.loadedRecentServices, state.isLoading ]);

	return (
		<Box margin="small" gap="large">
			<Box gap="small">
				<Box>
					<SlimHeading level="3">Recently Viewed</SlimHeading>
					<Box>
						{(state.isLoading || !state.loadedRecentServices) && (
							<LinearProgress />
						)}
						<DataTable
							pad="small"
							columns={recentServiceColumns}
							data={state.recentServices}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box>
			<Box gap="small">
				<Box>
					<SlimHeading level="3">Recently Updated</SlimHeading>
					<Box>
						{(state.isLoading || !state.loadedServices) && (
							<LinearProgress />
						)}
						<DataTable
							pad="small"
							columns={recentServiceColumns}
							data={services}
							step={10}
							paginate
							onClickRow={(event) => {
								handleNavigate(event.datum);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

interface CreateServiceState {
	wasLoaded: boolean;
	wasOrderLoaded: boolean;
	wasStoresLoaded: boolean;
	wasReferralLoaded: boolean;
	wasProductsLoaded: boolean;
	isLoadingReferral: boolean;
	isLoadingOrder: boolean;
	isCreatingService: boolean;
	order: DTO<Order> | null;
	service: DTO<Service> | null;
	estimate: DTO<Estimate> | null;
	selectedStore: DTO<Store> | null;
	selectedVehicle: DTO<Vehicle> | null;
	selectedRequiresHelper: boolean;
	selectedPickupWindow?: typeof pickupWindows[ number ];
}

export const CreateServiceScreen: React.FC<{ intent: ProductIntent; }> = (props) => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const stores = useAppSelector(selectStores);
	const snack = useSnackbar();
	const vehicles = useAppSelector(selectVehicles);
	const [ state, setState ] = useState<CreateServiceState>({
		isLoadingOrder: true,
		isLoadingReferral: false,
		isCreatingService: false,
		wasStoresLoaded: false,
		wasLoaded: false,
		wasOrderLoaded: false,
		wasProductsLoaded: false,
		wasReferralLoaded: false,
		order: null,
		service: null,
		selectedStore: null,
		selectedVehicle: null,
		selectedRequiresHelper: false,
		estimate: null
	});
	const inboundReferral = useAppSelector(selectInboundReferral);
	const selectedProducts = useAppSelector(selectActiveServiceProducts);
	const [ products, setProducts ] = useState<DTO<CustomerProduct>[]>([]);

	useEffect(() => {
		if(state.order) {
			OrderService.listOrderProducts(state.order.id, {}, [ "service", "customer" ])
				.then(products => {
					setProducts(products);
				})
				.catch(err => {
					console.error("Failed to load order products", err);
					snack.enqueueSnackbar("Failed to load order products", {
						variant: "error"
					});
				})
				.finally(() => {
					wrapStateUpdate({ wasProductsLoaded: true });
				});
		}
	}, [ state.order ]);

	useEffect(() => {
		dispatch(resetSelectedProductForService());
	}, []);

	useEffect(() => {
		if(state.service) {
			if(props.intent === ProductIntent.DONATE) {
				navigateToDonation(state.service.id, dispatch);
			}

			if(props.intent === ProductIntent.JUNK) {
				navigateToRemoval(state.service.id, dispatch);
			}
		}
	}, [ state.service ]);

	useEffect(() => {
		dispatch(getOrFetchVehicles())
			.catch(err => {
				console.error("Failed to load vehicles", err);
				snack.enqueueSnackbar("Error loading vehicle data", {
					variant: "error"
				});
			});
	}, []);

	useEffect(() => {
		if(!state.wasLoaded) {
			if(state.wasStoresLoaded && state.wasOrderLoaded && state.wasReferralLoaded) {
				wrapStateUpdate({ wasLoaded: true });
			}
		}
	}, [ state.wasStoresLoaded, state.isLoadingOrder, state.wasLoaded ]);

	function loadDonationStores(): void {
		dispatch(listStores({ service: ProductIntent.DONATE })).unwrap()
			.catch(err => {
				console.error("Failed to load stores", err);
				snack.enqueueSnackbar("Failed to load stores", {
					variant: "error"
				});
			})
			.finally(() => {
				wrapStateUpdate({ wasStoresLoaded: true });
			});
	}

	useEffect(() => {
		loadDonationStores();
	}, []);

	useEffect(() => {
		if(!params.orderId) {
			dispatch(push("/admin/dashboard/orders"));
			return;
		}

		handleFetchOrder(params.orderId);
	}, []);

	useEffect(() => {
		if(inboundReferral?.partner && isStorePartner(inboundReferral.partner)) {
			wrapStateUpdate({ selectedStore: inboundReferral.partner });
		}
	}, [ inboundReferral ]);

	function wrapStateUpdate(changes: Partial<CreateServiceState>) {
		setState(state => {
			return {
				...state,
				...changes
			};
		});
	}

	function handleFetchOrder(orderId: string): void {
		wrapStateUpdate({ isLoadingOrder: true });

		dispatch(fetchOrder({ orderId, expand: [] })).unwrap()
			.then(order => {
				console.debug("Loaded order", order);
				setState((current) => {
					return {
						...current,
						order
					};
				});

				return dispatch(fetchInboundReferral({ orderId, expand: [ "partner" ] })).unwrap();
			})
			.then(res => {
				setState((current) => {
					return {
						...current,
						wasReferralLoaded: true
					};
				});
			})
			.catch(err => {
				console.error("Failed to fetch order", err);
				snack.enqueueSnackbar("Failed to get order", {
					variant: "error"
				});
			})
			.finally(() => {
				wrapStateUpdate({ isLoadingOrder: false, wasOrderLoaded: true });
			});
	}

	function createService(): void {
		if(!state.order || !state.selectedVehicle) {
			return;
		}

		if(props.intent === ProductIntent.DONATE && !state.selectedStore) {
			return;
		}

		setState({
			...state,
			isCreatingService: true
		});

		const handler = (props.intent === ProductIntent.DONATE)
			? OrderService.createStoreDonation(
				state.order.id,
				//we check earlier that this is here
				state.selectedStore?.id ?? "",
				{
					vehicle: state.selectedVehicle,
					requires_helper: state.selectedRequiresHelper,
					products: selectedProducts.map(p => p.id)
				}
			)
			: OrderService.createOrderRemoval(
				state.order.id,
				state.selectedVehicle,
				selectedProducts
			);

		handler
			.then(res => {
				setState({
					...state,
					service: res
				});
			})
			.catch(err => {
				console.error("Failed to create service", err);
				snack.enqueueSnackbar("Failed to create service", err);
			})
			.finally(() => {
				setState({
					...state,
					isCreatingService: false
				});
			});
	}

	const markers = useMemo(() => {
		const markers: MapMarker[] = [];

		if(state.order) {
			markers.push({
				latitude: Number(state.order.address.latitude),
				longitude: Number(state.order.address.longitude),
				label: "A"
			});
		}

		if(state.selectedStore) {
			markers.push({
				latitude: Number(state.selectedStore.address.latitude),
				longitude: Number(state.selectedStore.address.longitude),
				label: "B"
			});
		}

		return markers;
	}, [ state.order, state.selectedStore ]);;


	const storeContact = useMemo(() => {
		return state.selectedStore?.contacts?.[ 0 ] ?? {} as StoreContact;
	}, [ state.selectedStore ]);

	return (
		<Loader visible={!state.wasLoaded}>
			<Box margin="medium">
				<Form
					validate="submit"
					onSubmit={() => {
						createService();
					}}
				>
					<Grid fill="horizontal" rows={[ "medium", "flex" ]}>
						<Box align="center" justify="center" fill>
							<MapComponent
								markers={markers}
								requestCurrentLocation={true}
							/>
						</Box>
						<Grid columns={{ count: 2, size: "flex" }} rows="auto" gap="medium" margin="small">
							{props.intent === ProductIntent.DONATE && (
								<Box gap="small">
									<FormField
										name="store"
										label="Donation Partner / Store"
										validate={[
											...getStandardFormValidations(),
											(value: any) => {
												if(!stores.find(s => s.name === value)) {
													return {
														status: "error",
														message: "Select a store from the dropdown"
													};
												}
											}
										]}
									>
										<TextInput
											name="store"
											value={state?.selectedStore?.name}
											suggestions={stores.map(s => s.name)}
											onSuggestionSelect={(suggestion) => {
												setState({
													...state,
													selectedStore: stores.find(s => s.name === suggestion.suggestion) ?? null
												});
											}}
										/>
									</FormField>
									<Grid columns={{ count: "fit", size: "auto" }} gap="small">
										<StoreHoursList hours={state.selectedStore?.hours as StoreHours} />
										<Box gap="small">
											<Box gap="small">
												<SlimHeading level="4">Drop-Off Instructions</SlimHeading>
												<TextArea
													value={state.selectedStore?.delivery_instructions}
												/>
											</Box>
											<Box gap="small">
												<FormField
													label="Contact Person"
												>
													<TextInput
														value={[ storeContact.first_name, storeContact.last_name ].filter(v => !!v).join(" ")}
													/>
												</FormField>
												<FormField
													label="Contact Email"
													readOnly
												>
													<TextInput
														value={storeContact.email_address}
													/>
												</FormField>
												<FormField
													label="Contact Phone"
													readOnly
												>
													<TextInput
														value={storeContact.phone_number}
													/>
												</FormField>
											</Box>
										</Box>
									</Grid>
								</Box>
							)}
							<Box flex>
								<SlimHeading level="4">
									Order Products
								</SlimHeading>
								{(state.order && state.wasProductsLoaded)
									? (
										<Box overflow={{ vertical: "auto" }}>
											{products.map((product) => (
												<ProductSummary product={product} key={product.id} permitAI>
													<Box align="end" justify="center" flex="grow">
														<Box margin="small">
															<CheckBox
																label={<Text weight="bold">Select?</Text>}
																disabled={!!product.service}
																onClick={(event) => {
																	event.stopPropagation();
																}}
																onChange={(event) => {
																	event.target.checked
																		? dispatch(addSelectedProductForService(product as DTO<UnionProduct>))
																		: dispatch(removeSelectedProductForService(product as DTO<UnionProduct>));
																}}
																checked={!!selectedProducts.find(p => p.id === product.id)}
															/>
														</Box>
													</Box>
												</ProductSummary>
											))}
										</Box>
									)
									: (
										<Loader visible={true}>

										</Loader>
									)
								}
							</Box>
							<Box gap="small">
								<SlimHeading level="4">
									Pickup Options
								</SlimHeading>
								<Box>
									<FormField
										name="vehicle"
										label="Vehicle"
										onChange={(event) => {
											wrapStateUpdate({ selectedVehicle: vehicles.find(v => v.name === event.target.value) ?? null });
										}}
										validate={[
											...getStandardFormValidations()
										]}
									>
										<Select
											name="vehicle"
											options={vehicles.map(v => v.name)}
										/>
									</FormField>
									<FormField
										name="requires_helper"
										label="Requires Helper?"
										onChange={(event) => {

										}}
										contentProps={{ border: undefined }}
									>
										<CheckBox
											name="requires_helper"
										/>
									</FormField>
									<FormField
										name="selected_product"
										contentProps={{ border: undefined }}
										validate={[
											(value: any) => {
												if(selectedProducts.length <= 0) {
													return {
														status: "error",
														message: "At least one product must be selected"
													};
												}
											}
										]}
									/>
								</Box>
							</Box>
						</Grid>
					</Grid>
					<Box align="end">
						<Button
							label={props.intent === ProductIntent.DONATE ? "Create Donation" : "Create Removal"}
							disabled={state.isCreatingService || state.isLoadingOrder}
							icon={state.isCreatingService ? <Spinner /> : undefined}
							type="submit"
						/>
					</Box>
				</Form>
			</Box>
		</Loader>
	);
};