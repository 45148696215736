import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import type { Brand, Material, Item, Vehicle, DTO, Market } from "../../types";
import { ReferenceService } from "../services";
import { setMarket } from "./purchase";

export interface ReferenceState {
	markets: DTO<Market>[],
	materials: DTO<Material>[],
	brands: DTO<Brand>[];
	vehicles: DTO<Vehicle>[];
	items: DTO<Item>[];
}

const initialState: ReferenceState = {
	markets: [],
	materials: [],
	brands: [],
	vehicles: [],
	items: []
};

export const fetchBrands = createAsyncThunk<DTO<Brand>[], void, { state: RootState; }>(
	"reference/fetchBrands",
	async (_, thunk) => {
		return await ReferenceService.listBrands();
	}
);

export const fetchItems = createAsyncThunk<DTO<Item>[], void, { state: RootState; }>(
	"reference/fetchItems",
	async (_, thunk) => {
		return await ReferenceService.listItems();
	}
);

export const getOrFetchVehicles = createAsyncThunk<DTO<Vehicle>[], void, { state: RootState; }>(
	"reference/getOrFetchVehicles",
	async (_, thunk) => {
		return (thunk.getState().reference.vehicles.length)
			? thunk.getState().reference.vehicles
			: await ReferenceService.listVehicles();
	}
);

export const getOrFetchMarkets = createAsyncThunk<DTO<Market>[], void, { state: RootState; }>(
	"reference/getOrFetchMarkets",
	async (_, thunk) => {
		return (thunk.getState().reference.markets.length)
			? thunk.getState().reference.markets
			: await ReferenceService.listMarkets();
	}
);

export const getOrFetchMarket = createAsyncThunk<DTO<Market>, string, { state: RootState; }>(
	"reference/getOrFetchMarket",
	async (code, thunk) => {
		const markets = await thunk.dispatch(getOrFetchMarkets()).unwrap()
		const found = markets.find(market => market.code === code);

		if(!found) {
			throw new Error(`Could not find market with code [${code}]`);
		}

		thunk.dispatch(setMarket(found));
		return found;
	}
);

export const referenceSlice = createSlice({
	name: "reference",
	initialState,
	reducers: {
		setBrands: (state, action: PayloadAction<DTO<Brand>[]>) => {
			state.brands = action.payload;
		},
		setMaterials: (state, action: PayloadAction<Material[]>) => {
			state.materials = action.payload;
		},
		setItems: (state, action: PayloadAction<Item[]>) => {
			state.items = action.payload;
		},
		setVehicles: (state, action: PayloadAction<Vehicle[]>) => {
			state.vehicles = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchBrands.fulfilled, (state, action: PayloadAction<DTO<Brand>[]>) => {
			state.brands = [
				...(action.payload.map(brand => ({ ...brand })))
			];
		});
		builder.addCase(fetchItems.fulfilled, (state, action: PayloadAction<DTO<Item>[]>) => {
			state.items = [
				...(action.payload.map(item => ({ ...item })))
			];
		});
		builder.addCase(getOrFetchVehicles.fulfilled, (state, action: PayloadAction<DTO<Vehicle>[]>) => {
			state.vehicles = [
				...(action.payload.map(vehicle => ({ ...vehicle })))
			];
		});
		builder.addCase(getOrFetchMarkets.fulfilled, (state, action: PayloadAction<DTO<Market>[]>) => {
			state.markets = [
				...(action.payload.map(market => ({ ...market })))
			];
		});
	}
});

export const {
	setBrands: setProductBrands,
	setItems,
	setMaterials: setProductMaterials,
	setVehicles
} = referenceSlice.actions;

export const selectBrands = (state: RootState): DTO<Brand>[] => state.reference.brands;
export const selectItems = (state: RootState): DTO<Item>[] => state.reference.items;
export const selectMaterials = (state: RootState): DTO<Material>[] => state.reference.materials;
export const selectVehicles = (state: RootState): DTO<Vehicle>[] => state.reference.vehicles;
export const selectMarkets = (state: RootState): DTO<Market>[] => state.reference.markets;

export default referenceSlice.reducer;