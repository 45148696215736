import { Anchor, Box, Button, Heading, Text } from "grommet";
import { useCallback, useEffect, useState } from "react";
import { PaginatedItem, Pagination, SlimHeading, useScreenSize } from "../../common";

interface DashboardGroupProps {
	title: string;
	actionTitle?: string;
	onActionClicked?: () => void;
}

export const DashboardGroup: React.FC<DashboardGroupProps> = (props) => {
	return (
		<Box direction="column" margin={{ horizontal: "large", vertical: "small" }} round style={{ minHeight: "400px" }} flex="grow">
			<Box direction="row" justify="between" background="brand" round={{ corner: "top" }} border={{ size: "small", color: "dark" }} fill="horizontal">
				<Box align="center" justify="center" margin="xsmall">
					<Heading size="large" margin="medium" level="4">{props.title}</Heading>
				</Box>
				<Box align="center" justify="center" margin={{ horizontal: "small", vertical: "xsmall" }}>
					{props.actionTitle && props.onActionClicked && (
						<Button primary label={props.actionTitle} onClick={props.onActionClicked} />
					)}
				</Box>
			</Box>
			<Box fill="horizontal" border={{ side: "vertical", size: "small", color: "brand" }} style={{ minHeight: "50px" }}>
				{props.children}
			</Box>
			<Box round={{ corner: "bottom" }} background="brand" style={{ height: "20px" }} fill>

			</Box>
		</Box>
	);
};

export interface ServiceCardContainerProps {
	title: string;
	subtitle?: string;
	noItemsMessage: string;
	items: PaginatedItem[];
	isViewingAll: boolean;
	toggleViewAll?: () => void;
	smallScreenPageSize?: number;
	mediumScreenPageSize?: number;
	largeScreenPageSize?: number;
	renderItems(items: PaginatedItem[]): JSX.Element;
}

export const ServiceCardContainer: React.FC<ServiceCardContainerProps> = (props) => {
	const screenSize = useScreenSize();
	const [currentPage, setCurrentPage] = useState(0);

	useEffect(() => {
		setCurrentPage(0);
		return () => {
			setCurrentPage(0);
		};
	}, []);

	const fetchPageSize = useCallback(() => {
		switch(screenSize) {
			case "large": {
				return props.largeScreenPageSize ?? 8;
			}
			case "medium": {
				return props.mediumScreenPageSize ?? 8;
			}
			case "small": {
				return props.smallScreenPageSize ?? 4;
			}
		}
	}, [screenSize, props.smallScreenPageSize, props.mediumScreenPageSize, props.largeScreenPageSize]);

	const renderItems = useCallback((items: PaginatedItem[]) => {
		return props.renderItems(items);
	}, [props.isViewingAll, props.items, currentPage, props.renderItems]);

	return (
		<Box margin="medium">
			<Box align="center" justify="between" margin="small" direction="row">
				<Box>
					<SlimHeading level="3">{props.title}</SlimHeading>
					{props.subtitle && (
						<Text>
							{props.subtitle}
						</Text>
					)}
				</Box>
				<Box>
					<Anchor label={props.toggleViewAll ? props.isViewingAll ? "View Active" : "View All" : ""} onClick={props.toggleViewAll} />
				</Box>
			</Box>
			{props.items.length === 0
				? (
					<Box margin="large" align="center" justify="center">
						<SlimHeading level="4">{props.noItemsMessage}</SlimHeading>
					</Box>
				)
				: (
					<Pagination
						items={props.items}
						pageSize={fetchPageSize()}
						renderItems={renderItems}
						onChange={(size, current, total) => {
							setCurrentPage(current);
						}}
					/>
				)}

		</Box>
	);
};