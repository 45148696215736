import { Divider } from "@mui/material";
import { push, replace } from "connected-react-router";
import { Anchor, Box, Button, ButtonExtendedProps, Form, FormField, Grid, Image, RadioButtonGroup, Select, Spinner, Text } from "grommet";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { cancelPurchase, createPurchaseEstimate, createPurchasePaymentIntent, fetchPurchase, selectPayment, selectPurchase, selectPurchaseEstimate, setIsPurchaseInProgress, setPayment, setPurchase, setPurchaseEstimate, updatePurchase } from "../../../app/store/purchase";
import { Countdown, ElevatedCard, LoadingButton, Modal, SlimHeading, useWindowDimensions } from "../../common";
import { asEnum, Customer, DeliveryAddress, DeliveryType, DeliveryWindow, DTO, FeeType, formatCurrency, Payment, PaymentMethod, Product, Purchase, PurchaseEstimate, PurchaseIntent, RelationExpand, toProperCase } from "@rego-app/common";
import { getNumberFormValidations, isErrorResponse } from "../../../helpers";
import { useSnackbar } from "notistack";
import moment from "moment";
import { ProductService } from "../../../app/services";
import { placeholder } from "../../../img";
import { PurchaseService } from "../../../app/services/purchase";
import { CheckoutAddressForm, CheckoutConfirmationScreen, CheckoutContactDetails, CheckoutDeliveryTiming, CheckoutSelectPaymentMethod } from "../components";
import { Steps, useSteps } from "react-step-builder";
import { useLocation } from "react-router-dom";
import { selectCustomer } from "../../../app/store/application";

enum CheckoutStep {
	SHIPPING = "SHIPPING",
	ADDRESS = "ADDRESS",
	TIMING = "TIMING",
	PAYMENT = "PAYMENT",
	CONTACT = "CONTACT",
	REVIEW = "REVIEW"
}


const StepToIndexMapping: Record<CheckoutStep, number> = {
	[CheckoutStep.SHIPPING]: 1,
	[CheckoutStep.ADDRESS]: 2,
	[CheckoutStep.TIMING]: 3,
	[CheckoutStep.CONTACT]: 4,
	[CheckoutStep.PAYMENT]: 5,
	[CheckoutStep.REVIEW]: 6
};

const IndexToStepMapping: Record<number, CheckoutStep> = {
	1: CheckoutStep.SHIPPING,
	2: CheckoutStep.ADDRESS,
	3: CheckoutStep.TIMING,
	4: CheckoutStep.CONTACT,
	5: CheckoutStep.PAYMENT,
	6: CheckoutStep.REVIEW
};

export interface CheckoutState {
	isLoading: boolean;
	isLoadingEstimate: boolean;
	wasEstimateCreated: boolean;
	isSavingAddress: boolean;
	isSavingWindow: boolean;
	isCreatingPayment: boolean;
	checkoutStep: CheckoutStep | null;
	paymentMethod: DTO<PaymentMethod> | null;
	isExtendingPurchase: boolean;
	isUpdatingIntent: boolean;
	isCancellingPurchase: boolean;
	isCompletingPurchase: boolean;
	canCompletePurchase: boolean;
}

interface CheckoutPageProps {
	customer: DTO<Customer>;
}

export const CheckoutPage: React.FC<CheckoutPageProps> = (props) => {
	const dimensions = useWindowDimensions();
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const payment = useAppSelector(selectPayment);
	const estimate = useAppSelector(selectPurchaseEstimate);
	const purchase = useAppSelector(selectPurchase);
	const customer = useAppSelector(selectCustomer);

	const isFullWidth = useMemo(() => {
		return ["small", "medium"].includes(dimensions.size);
	}, [dimensions.size]);

	function getDefaultCheckoutStep(): CheckoutStep {
		try {
			const checkoutStep = new URLSearchParams(window.location.search).get(
				"checkoutStep"
			);
			if(checkoutStep) {
				return asEnum(CheckoutStep, checkoutStep);
			}
			else {
				return CheckoutStep.ADDRESS;
			}
		}
		catch(e) {
			return CheckoutStep.SHIPPING;
		}
	}

	const [state, setState] = useState<CheckoutState>({
		isLoading: false,
		isLoadingEstimate: false,
		wasEstimateCreated: false,
		isSavingAddress: false,
		isSavingWindow: false,
		isCreatingPayment: false,
		checkoutStep: getDefaultCheckoutStep(),
		paymentMethod: null,
		isUpdatingIntent: false,
		isExtendingPurchase: false,
		isCompletingPurchase: false,
		canCompletePurchase: false,
		isCancellingPurchase: false
	});

	useEffect(() => {
		setState({
			...state,
			canCompletePurchase: !!purchase && !!purchase.address && !!state.paymentMethod && !purchase.paid && !!customer?.email_address && !!customer.phone_number
		});
	}, [purchase, state.paymentMethod]);

	useEffect(() => {
		if(purchase && purchase.expired) {
			dispatch(setPurchase(null));
			dispatch(setIsPurchaseInProgress(false));
			dispatch(push("/shop/cart"));
		}

		if(purchase && !estimate) {
			PurchaseService.getLastPurchaseEstimates(purchase.id).then(res => {
				if(res) {
					dispatch(setPurchaseEstimate(res));
				}
			});
		}

		if(purchase?.payment) {
			dispatch(setPayment(purchase.payment));
			if(purchase.payment.payment_method) {
				updateState({ paymentMethod: purchase.payment.payment_method });
			}
		}
	}, [purchase, estimate]);

	const updateState = useCallback((updates: Partial<CheckoutState>): void => {
		setState((old) => {
			return {
				...old,
				...updates
			};
		});
	}, [state, setState]);

	useEffect(() => {
		if(purchase?.address && purchase.intents && !purchase.paid) {
			createEstimate();
		}
	}, [purchase?.address, purchase?.intents]);

	function createEstimate(): void {
		if(!purchase) return;

		setState({ ...state, isLoadingEstimate: true });
		dispatch(createPurchaseEstimate(purchase.id)).unwrap()
			.catch(err => {
				console.error("Failed to create estimate", err);
			})
			.finally(() => {
				setState({
					...state,
					isLoadingEstimate: false
				});
			});
	}

	function handleFetchPurchase(): Promise<void> {
		if(!purchase) return Promise.resolve();
		return dispatch(fetchPurchase({ purchaseId: purchase.id, expand: ["intents", "payment", "payment.payment_method"] as RelationExpand<Purchase> })).unwrap().then(() => {
			return;
		});
	}

	const handleAddressSubmitted = useCallback((address: DeliveryAddress): Promise<boolean> => {
		if(!purchase) return Promise.resolve(false);
		updateState({ isSavingAddress: true });

		return PurchaseService.updatePurchase(purchase.id, { address }).then(() => {
			return handleFetchPurchase();
		}).then(() => {
			return true;
		}).catch(err => {
			if(isErrorResponse(err) && err.error.errors?.find(e => e.friendly)) {
				const error = err.error.errors?.find(e => e.friendly);
				snack.enqueueSnackbar(error?.message, {
					variant: "error"
				});
				return false;
			}

			return false;
		}).finally(() => {
			updateState({ isSavingAddress: false });
		});
	}, [purchase, state]);

	function handleSaveWindow(window: DeliveryWindow): Promise<boolean> {
		if(!purchase) return Promise.resolve(false);
		updateState({ isSavingWindow: true });

		return PurchaseService.updatePurchase(purchase.id, { delivery_window: window }).then(() => {
			return true;
		}).catch(err => {
			if(isErrorResponse(err) && err.error.errors?.find(e => e.friendly)) {
				const error = err.error.errors?.find(e => e.friendly);
				if(error?.message) {
					snack.enqueueSnackbar(error?.message, {
						variant: "error"
					});
				}
			}

			return false;
		}).finally(() => {
			updateState({ isSavingWindow: false });
		});
	}

	function handleSavePaymentMethod(paymentMethod: DTO<PaymentMethod>): void {
		if(!purchase) return;
		setState({
			...state,
			paymentMethod: { ...paymentMethod },
			checkoutStep: null
		});
	}

	function handleCompleteCheckout(): Promise<boolean> {
		if(!state.paymentMethod || !estimate || !purchase) {
			return Promise.resolve(false);
		}

		setState({
			...state,
			isCreatingPayment: true,
			isCompletingPurchase: true
		});
		return dispatch(createPurchasePaymentIntent({ purchaseId: purchase.id, estimateId: estimate.id, paymentMethod: state.paymentMethod })).unwrap()
			.then(() => handleFetchPurchase())
			.then(() => {
				return true;
			})
			.catch(err => {
				console.error("failed to create purchase payment intent", err);
				snack.enqueueSnackbar("We ran into an issue completing your purchase. Your card has not been charged", {
					variant: "error"
				});
				return false;
			})
			.finally(() => {
				setState({
					...state,
					isCreatingPayment: false,
					isCompletingPurchase: false
				});
			});
	}

	const handleUpdateIntentDeliveryType = useCallback((intent: DTO<PurchaseIntent>, type: DeliveryType): Promise<void> => {
		if(!purchase) return Promise.resolve();
		updateState({ isUpdatingIntent: true });
		return PurchaseService.updatePurchaseProductIntent(purchase.id, intent.id, { delivery_type: type }).then(() => {
			return handleFetchPurchase();
		}).catch(err => {
			console.error("Failed to update delivery type", err);
			snack.enqueueSnackbar("We ran into an issue updating your purchase information", { variant: "error" });
		}).finally(() => {
			updateState({ isUpdatingIntent: false });
		});
	}, [purchase, state.isUpdatingIntent]);

	const handleUpdateQuantity = useCallback((intent: DTO<PurchaseIntent>, quantity: number): Promise<void> => {
		if(!purchase) return Promise.resolve();
		updateState({ isUpdatingIntent: true });
		return PurchaseService.updatePurchaseProductIntent(purchase.id, intent.id, { quantity }).then(() => {
			return handleFetchPurchase();
		}).catch(err => {
			console.error("Failed to update delivery type", err);
			snack.enqueueSnackbar("We ran into an issue updating your purchase information", { variant: "error" });
		}).finally(() => {
			updateState({ isUpdatingIntent: false });
		});
	}, [purchase, state.isUpdatingIntent]);

	function handleExtendPurchase(): void {
		if(!purchase) return;
		setState({ ...state, isExtendingPurchase: true });
		PurchaseService.extendPurchase(purchase.id).then(() => {
			return handleFetchPurchase();
		}).catch(err => {
			console.error("Failed to extend purchase", err);
			snack.enqueueSnackbar("We ran into an issue extending your purchase", { variant: "error" });
		}).finally(() => {
			setState({ ...state, isExtendingPurchase: false });
		});
	}

	function handleCancelPurchase(): Promise<boolean> {
		if(!purchase) return Promise.resolve(false);
		updateState({ isCancellingPurchase: true });

		return dispatch(cancelPurchase(purchase.id)).unwrap()
			.then(() => handleFetchPurchase())
			.then(() => {
				snack.enqueueSnackbar("Purchase cancelled successfully", { variant: "success" });
				return true;
			})
			.catch(err => {
				console.error("Failed to cancel order", err);
				return false;
			})
			.finally(() => {
				dispatch(push("/shop/cart"));
				updateState({ isCancellingPurchase: false });
			});
	}

	return (
		<Box margin="medium">
			{purchase && (
				<Grid columns={isFullWidth ? "100%" : ["flex", "auto"]} gap="medium">
					{isFullWidth && (
						<CheckoutSummary
							isCreatingEstimate={state.isLoadingEstimate}
							isCancellingPurchase={state.isCancellingPurchase}
							isCompletingPurchase={state.isCompletingPurchase}
							estimate={estimate}
							purchase={purchase}
							onCancelPurchase={handleCancelPurchase}
							onCompletePurchase={handleCompleteCheckout}
							canCompleteCheckout={state.canCompletePurchase}
							isExtendingPurchase={state.isExtendingPurchase}
							onExtendPurchase={handleExtendPurchase}
						/>
					)}
					<CheckoutStepsController
						payment={payment}
						purchase={purchase}
						onCompletePurchase={handleCompleteCheckout}
						isSavingWindow={state.isSavingWindow}
						paymentMethod={state.paymentMethod}
						handleAddressSubmitted={handleAddressSubmitted}
						handleSavePaymentMethod={handleSavePaymentMethod}
						handleSaveWindow={handleSaveWindow}
						handleQuantityUpdated={handleUpdateQuantity}
						handleUpdateIntentDeliveryType={handleUpdateIntentDeliveryType}
					/>
					<Box>
						<CheckoutSummary
							isCreatingEstimate={state.isLoadingEstimate}
							isCancellingPurchase={state.isCancellingPurchase}
							isCompletingPurchase={state.isCompletingPurchase}
							estimate={estimate}
							purchase={purchase}
							onCancelPurchase={handleCancelPurchase}
							onCompletePurchase={handleCompleteCheckout}
							canCompleteCheckout={state.canCompletePurchase}
							isExtendingPurchase={state.isExtendingPurchase}
							onExtendPurchase={handleExtendPurchase}
						/>
						<Box flex>

						</Box>
					</Box>
				</Grid>
			)}
		</Box>
	);
};

interface CheckoutStepsControllerProps {
	isSavingWindow: boolean;
	purchase: DTO<Purchase>;
	payment: DTO<Payment> | null;
	paymentMethod: DTO<PaymentMethod> | null;
	onCompletePurchase(): Promise<boolean>;
	handleSaveWindow(window: DeliveryWindow): Promise<boolean>;
	handleAddressSubmitted(address: DeliveryAddress): Promise<boolean>;
	handleSavePaymentMethod(paymentMethod: DTO<PaymentMethod>): void;
	handleQuantityUpdated(intent: DTO<PurchaseIntent>, quantity: number): Promise<void>;
	handleUpdateIntentDeliveryType(intent: DTO<PurchaseIntent>, type: DeliveryType): Promise<void>;
}

export const CheckoutStepsController: React.FC<CheckoutStepsControllerProps> = (props) => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { next, current } = useSteps();

	const startingStep = useMemo(() => {
		if(props.purchase.paid) return StepToIndexMapping[CheckoutStep.REVIEW];
		const step = new URLSearchParams(location.search).get("step");
		if(step) {
			const index = StepToIndexMapping[step as CheckoutStep];
			return index;
		}
	}, [location.search, props.purchase]);

	const hasPickupType = useMemo(() => {
		return props.purchase?.intents?.some(i => i.delivery_type === DeliveryType.PICKUP);
	}, [props.purchase]);

	const hasDeliveryType = useMemo(() => {
		return props.purchase?.intents?.some(i => i.delivery_type === DeliveryType.DELIVERY);
	}, [props.purchase]);

	const handleStepChanged = useCallback(() => {
		const step = IndexToStepMapping[current];
		if(step) {
			dispatch(replace(`/shop/checkout?purchaseId=${props.purchase.id}&step=${step}`));
		}
	}, [current]);

	const deliveryTimingTitle = useMemo(() => {
		if(hasPickupType && !hasDeliveryType) {
			return "Pickup Details";
		}

		if(hasDeliveryType && !hasPickupType) {
			return "Delivery Details";
		}

		return "Pickup / Delivery Details";
	}, [hasPickupType, hasDeliveryType]);

	return (
		<Steps
			startsFrom={startingStep}
			onStepChange={() => {
				handleStepChanged();
			}}
		>
			<CheckoutStepWrapper
				header={<CheckoutStepHeader title="Review Items and Shipping" />}
			>
				<CheckoutProductsContainer
					purchase={props.purchase}
					intents={props.purchase.intents}
					onQuantityUpdated={props.handleQuantityUpdated}
					onDeliveryTypeUpdated={props.handleUpdateIntentDeliveryType}
				/>
			</CheckoutStepWrapper>
			<CheckoutStepWrapper
				header={<CheckoutStepHeader title="Shipping / Billing Address" />}
			>
				<CheckoutAddressForm
					purchase={props.purchase}
					onSuperNext={next}
					onSaveAddress={props.handleAddressSubmitted}
				/>
			</CheckoutStepWrapper>
			<CheckoutStepWrapper
				header={<CheckoutStepHeader title={deliveryTimingTitle} />}
			>
				<CheckoutDeliveryTiming
					onSuperNext={next}
					purchase={props.purchase}
					hasPickupType={hasPickupType}
					hasDeliveryType={hasDeliveryType}
					onWindowUpdated={props.handleSaveWindow}
				/>
			</CheckoutStepWrapper>
			<CheckoutStepWrapper
				header={<CheckoutStepHeader title="Contact Details" />}
			>
				<CheckoutContactDetails
					onSuperNext={next}
				/>
			</CheckoutStepWrapper>
			<CheckoutStepWrapper
				header={<CheckoutStepHeader title="Payment Method" />}
			>
				<CheckoutSelectPaymentMethod
					onSuperNext={next}
					paymentMethod={props.paymentMethod ?? undefined}
					handleSavePaymentMethod={props.handleSavePaymentMethod}
					returnUrl={`${window.location.protocol}//${window.location.host}/shop/checkout?purchaseId=${props.purchase.id}&checkoutStep=PAYMENT`}
				/>
			</CheckoutStepWrapper>
			<CheckoutStepWrapper
				header={<CheckoutStepHeader title="Review" />}
			>
				<CheckoutConfirmationScreen
					purchase={props.purchase}
					payment={props.payment}
					paymentMethod={props.paymentMethod as DTO<PaymentMethod>}
					onSubmitPurchase={props.onCompletePurchase}
				/>
			</CheckoutStepWrapper>
		</Steps>
	);
};

interface CheckoutAccordionProps {
	state: CheckoutState;
	purchase: DTO<Purchase>;
	handleSaveWindow(window: DeliveryWindow): void;
	updateState(updates: Partial<CheckoutState>): void;
	handleAddressSubmitted(address: DeliveryAddress): void;
	handleSavePaymentMethod(paymentMethod: DTO<PaymentMethod>): void;
	handleUpdateIntentDeliveryType(intent: DTO<PurchaseIntent>, type: DeliveryType): void;
}

interface CheckoutStepWrapperProps {
	header: JSX.Element;
}

export const CheckoutStepWrapper: React.FC<CheckoutStepWrapperProps> = (props) => {
	return (
		<ElevatedCard header={props.header}>
			{props.children}
		</ElevatedCard>
	);
};

interface CheckoutStepControlButtonsProps {
	nextProps?: ButtonExtendedProps;
	previousProps?: ButtonExtendedProps;
	nextLabel?: string;
	previousLabel?: string;
	forceNext?: boolean;
	disableNext?: boolean;
	hidePrevious?: boolean;
	disablePrevious?: boolean;
	onClickNext?(): void;
	onClickPrevious?(): void;
	isLoadingNext?: boolean;
	isLoadingPrevious?: boolean;
}

export const CheckoutStepControlButtons: React.FC<CheckoutStepControlButtonsProps> = (props) => {
	const { hasPrev, hasNext, prev, next } = useSteps();

	const isOnClickNextDefined = useMemo(() => {
		return Object.keys(props).includes("onClickNext");
	}, [props.onClickNext]);

	return (
		<Box direction="row" justify="between">
			<Box>
				{!props.hidePrevious && (
					<Button
						primary
						disabled={!hasPrev}
						color="status-warning"
						label="Go Back"
						onClick={props.onClickPrevious ? props.onClickPrevious : prev}
					/>
				)}
			</Box>
			{(hasNext || props.forceNext) && (
				<LoadingButton
					{...props.nextProps}
					primary
					label={props.nextLabel ?? "Continue"}
					isLoading={props.isLoadingNext ?? false}
					disabled={props.disableNext ?? false}
					onClick={isOnClickNextDefined ? props.onClickNext : next}
				/>
			)}
		</Box>
	);
};

interface CheckoutSummaryProps {
	canCompleteCheckout: boolean;
	purchase: DTO<Purchase>;
	estimate: DTO<PurchaseEstimate> | null;
	isCancellingPurchase: boolean;
	onCancelPurchase(): void;
	isCompletingPurchase: boolean;
	onCompletePurchase(): void;
	isCreatingEstimate: boolean;
	isExtendingPurchase: boolean;
	onExtendPurchase(): void;
}

export const CheckoutSummary: React.FC<CheckoutSummaryProps> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const [lessThanFiveMinutesRemaining, setLessThanFiveMinutesRemaining] = useState(false);

	function handleExtendPurchase(): void {
		props.onExtendPurchase();
	}

	function getDeliveryFee(): number {
		return props.estimate?.fees?.find(fee => fee.type === FeeType.DELIVERY)?.total_fee ?? 0;
	}

	const date = useMemo(() => {
		setLessThanFiveMinutesRemaining(false);
		return moment(props.purchase?.expired_at).toDate();
	}, [props.purchase]);

	return (
		<ElevatedCard>
			<Box margin="small">
				<Box align="center" gap="small">
					<SlimHeading level="3">
						Order Summary
					</SlimHeading>
					{!props.purchase.paid && (
						<Box direction="row" gap="small" justify="between">
							<Text>
								Time Remaining:
							</Text>
							<Countdown
								weight="bold"
								to={date}
								onExpiredCallback={() => {
									snack.enqueueSnackbar("Your purchase has expired", {
										variant: "warning"
									});

									dispatch(setPurchase(null));
									dispatch(push("/shop/cart"));
								}}
								listeners={[
									{
										secondsRemaining: 60 * 5,
										callback: () => {
											setLessThanFiveMinutesRemaining(true);
										}
									},
									{
										secondsRemaining: 60 * 4,
										callback: () => {
											setLessThanFiveMinutesRemaining(true);
										}
									},
									{
										secondsRemaining: 60 * 3,
										callback: () => {
											setLessThanFiveMinutesRemaining(true);
										}
									},
									{
										secondsRemaining: 60,
										callback: () => {
											setLessThanFiveMinutesRemaining(true);
										}
									},
									{
										secondsRemaining: 30,
										callback: () => {
											setLessThanFiveMinutesRemaining(true);
										}
									}
								]}
							/>
						</Box>
					)}
					{lessThanFiveMinutesRemaining && (
						<Box direction="row" gap="small">
							<Anchor
								disabled={props.isExtendingPurchase}
								label="Need more time?"
								onClick={handleExtendPurchase}
							/>
							{props.isExtendingPurchase && (
								<Spinner />
							)}
						</Box>
					)}
					{props.isCreatingEstimate && (
						<Box pad="small" align="center" justify="center">
							<Spinner size="medium" />
						</Box>
					)}
					{props.estimate && (
						<Box fill="horizontal">
							<Box gap="small">
								<Box gap="xsmall">
									<SlimHeading level="4">Items</SlimHeading>
									<Box margin={{ left: "small" }} gap="xsmall">
										{props.estimate.line_items.map(item => (
											<Box key={item.name} direction="row" justify="between" gap="small">
												<Text>{item.description}:</Text>
												<Text>{formatCurrency(Number(item.amount ?? 0))}</Text>
											</Box>
										))}
									</Box>
								</Box>
								<Box gap="xsmall">
									<SlimHeading level="4">Fees</SlimHeading>
									<Box direction="row" justify="between" margin={{ left: "small" }} gap="small">
										<Text>Delivery:</Text>
										<Text>{formatCurrency(Number(getDeliveryFee()))}</Text>
									</Box>
								</Box>
								<Box gap="xsmall">
									<Box direction="row" justify="between">
										<Text weight="bold">Total Before Tax:</Text>
										<Text>{formatCurrency(Number(props.estimate.subtotal_amount))}</Text>
									</Box>
								</Box>
								<Box gap="xsmall">
									<Box direction="row" justify="between" >
										<Text>Taxes:</Text>
										<Text>{formatCurrency(Number(props.estimate.tax_amount))}</Text>
									</Box>
								</Box>
								<Divider />
								<Box gap="xsmall">
									<Box direction="row" justify="between" >
										<Text weight="bold">Total:</Text>
										<Text weight="bold">{formatCurrency(Number(props.estimate.total_amount))}</Text>
									</Box>
								</Box>
							</Box>
						</Box>
					)}
					<Box margin="small" align="center" justify="between" direction="row" flex gap="medium">
						<LoadingButton
							primary
							color="status-error"
							label="Cancel Order"
							disabled={props.purchase.paid || props.purchase.expired}
							isLoading={props.isCancellingPurchase}
							onClick={() => {
								props.onCancelPurchase();
							}}
						/>
						<LoadingButton
							isLoading={props.isCompletingPurchase}
							onClick={() => props.onCompletePurchase()}
							label="Place Order"
							primary
							disabled={!props.canCompleteCheckout || props.purchase.paid || props.purchase.expired}
						/>
					</Box>
				</Box>
			</Box>
		</ElevatedCard>
	);
};

interface CheckoutProductsContainerProps {
	purchase: DTO<Purchase>;
	intents: DTO<PurchaseIntent>[];
	onQuantityUpdated(intent: DTO<PurchaseIntent>, quantity: number): Promise<void>;
	onDeliveryTypeUpdated(intent: DTO<PurchaseIntent>, type: DeliveryType): Promise<void>;
}

export const CheckoutStepHeader: React.FC<{ title: string; }> = (props) => {
	return (
		<Box background="brand" pad="small" flex>
			<SlimHeading level="3">
				{props.title}
			</SlimHeading>
		</Box>
	);
};

export const CheckoutProductsContainer: React.FC<CheckoutProductsContainerProps> = (props) => {
	return (
		<Box>
			<Box margin="small" gap="small">
				{props.intents.map(intent => (
					<CheckoutProductCard
						key={intent.id}
						intent={intent}
						product={intent.product}
						onQuantityUpdated={props.onQuantityUpdated}
						onDeliveryTypeSelected={props.onDeliveryTypeUpdated}
					/>
				))}
			</Box>
			<Box align="end" justify="end" margin="small">
				<CheckoutSubtotal
					intents={props.intents}
				/>
			</Box>
			<CheckoutStepControlButtons
				disableNext={props.intents.some(intent => !intent.delivery_type)}
			/>
		</Box>
	);
};

interface UpdateQuantityModalProps {
	product: DTO<Product>;
	intent: DTO<PurchaseIntent>;
	onClose(): void;
	onQuantityUpdated(quantity: number): Promise<void>;
}

export const UpdateQuantityModal: React.FC<UpdateQuantityModalProps> = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [quantity, setQuantity] = useState(props.intent.quantity);

	const productInventoryOptions = useMemo(() => {
		const inventory = props.product.inventory?.quantity_available ?? 0;
		const options = [];
		for(let i = 0; i < inventory; i++) {
			options.push(String(i + 1));
		}

		return options;;
	}, [props.product]);

	function handleSubmit(): void {
		setIsLoading(true);
		props.onQuantityUpdated(quantity).then(() => {
			props.onClose();
		}).finally(() => {
			setIsLoading(false);
		});
	}

	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box margin="small">
				<Form
					validate="submit"
					onSubmit={handleSubmit}
				>
					<Box gap="small">
						<FormField
							margin={"none"}
							label="Quantity"
							name="quantity"
							onChange={(event) => {
								setQuantity(event.target.value as unknown as number);
							}}
							validate={[
								...getNumberFormValidations()
							]}
						>
							<Select
								name="quantity"
								value={String(quantity)}
								options={productInventoryOptions}
							/>
						</FormField>
						<Box align="end" margin="small">
							<LoadingButton
								isLoading={isLoading}
								primary
								type="submit"
								label="Update"
							/>
						</Box>
					</Box>
				</Form>
			</Box>
		</Modal>
	);
};

interface CheckoutProductCardProps {
	product: DTO<Product>;
	intent: DTO<PurchaseIntent>;
	onQuantityUpdated(intent: DTO<PurchaseIntent>, quantity: number): Promise<void>;
	onDeliveryTypeSelected(intent: DTO<PurchaseIntent>, deliveryType: DeliveryType): Promise<void>;
}

export const CheckoutProductCard: React.FC<CheckoutProductCardProps> = (props) => {
	const dimensions = useWindowDimensions();
	const [thumbnail, setThumbnail] = useState("");
	const [isUpdatingIntent, setIsUpdatingIntent] = useState(false);
	const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false);
	const [deliveryTypeFriendly, setDeliveryTypeFriendly] = useState<DeliveryOptionFriendly | null>(
		props.intent.delivery_type === DeliveryType.DELIVERY
			? "Delivery"
			: props.intent.delivery_type === DeliveryType.PICKUP
				? "Free Store Pickup"
				: null
	);

	const quantity = useMemo(() => {
		return props.intent.quantity;
	}, [props.intent]);

	const deliveryType = useMemo(() => {
		return props.intent.delivery_type;
	}, [props.intent]);

	useEffect(() => {
		switch(deliveryType) {
			case DeliveryType.DELIVERY: {
				setDeliveryTypeFriendly("Delivery");
				break;
			}
			case DeliveryType.PICKUP: {
				setDeliveryTypeFriendly("Free Store Pickup");
				break;
			}
		}
	}, [deliveryType]);

	function updateDeliveryType(type: DeliveryOptionFriendly): void {
		setIsUpdatingIntent(true);
		switch(type) {
			case "Delivery": {
				setDeliveryTypeFriendly(type);
				props.onDeliveryTypeSelected(props.intent, DeliveryType.DELIVERY).finally(() => {
					setIsUpdatingIntent(false);
				});
				break;
			}
			case "Free Store Pickup": {
				setDeliveryTypeFriendly(type);
				props.onDeliveryTypeSelected(props.intent, DeliveryType.PICKUP).finally(() => {
					setIsUpdatingIntent(false);
				});
				break;
			}
		}
	}

	function updateQuantity(quantity: number): Promise<void> {
		if(quantity === props.intent.quantity) {
			return Promise.resolve();
		}

		setIsUpdatingIntent(true);
		return props.onQuantityUpdated(props.intent, quantity).finally(() => {
			setIsUpdatingIntent(false);
		});
	}

	useEffect(() => {
		if(props.product.media.length) {
			ProductService.getMediaURL(props.product.id, props.product.media[0].id)
				.then(res => {
					setThumbnail(res);
				})
				.catch(err => {
					console.error("Failed to load product media", props.product, err);
					setThumbnail(placeholder);
				});
		}
		else {
			setThumbnail(placeholder);
		}
	}, []);

	const subtotal = useMemo(() => {
		return Number(props.product.price) * quantity;
	}, [props.product, quantity]);

	const isFullWidth = useMemo(() => {
		return ["small", "medium"].includes(dimensions.size);
	}, [dimensions.size]);

	type DeliveryOptionFriendly = "Free Store Pickup" | "Delivery";

	const deliveryOptions = useMemo(() => {
		const types: DeliveryOptionFriendly[] = [];
		if(props.product.delivery_types.includes(DeliveryType.DELIVERY)) {
			types.push("Delivery");


		}
		if(props.product.delivery_types.includes(DeliveryType.PICKUP)) {
			types.push("Free Store Pickup");
		}

		return types;
	}, [props.product.delivery_types, props.intent.delivery_type]);

	useEffect(() => {
		if(!props.intent.delivery_type) {
			if(props.product.delivery_types.length === 1 && props.product.delivery_types.includes(DeliveryType.DELIVERY)) {
				updateDeliveryType("Delivery");
			}
			if(props.product.delivery_types.length === 1 && props.product.delivery_types.includes(DeliveryType.PICKUP)) {
				updateDeliveryType("Free Store Pickup");
			}
		}

	}, [props.product.delivery_types, props.intent.delivery_type]);

	// useEffect(() => {
	// 	if(deliveryOptions.length === 1) {
	// 		updateDeliveryType(deliveryOptions[0]);
	// 	}
	// }, [deliveryOptions]);

	const imageComponent = useMemo(() => {
		return (
			<Box align="center" justify="start">
				<Box>
					{!thumbnail
						? (
							<Box style={{ height: 150, width: 150 }} align="center" justify="center">
								<Spinner size="medium" />
							</Box>
						)
						: (
							<Image height={150} width={150} src={thumbnail} />
						)
					}
				</Box>
			</Box>
		);
	}, [thumbnail]);

	const actionComponent = useMemo(() => {
		return (
			<FormField
				label={
					<Box direction="row" gap="small" align="start">
						<Text weight="bold">
							Shipping Type
						</Text>
						{isUpdatingIntent && (
							<Spinner />
						)}
					</Box>
				}
				contentProps={{ border: undefined }}
				name={`deliveryType-${props.intent.id}`}
			>
				<RadioButtonGroup
					id={`deliveryType-${props.intent.id}`}
					name={`deliveryType-${props.intent.id}`}
					options={deliveryOptions}
					value={deliveryTypeFriendly ?? undefined}
					disabled={deliveryOptions.length <= 1 || isUpdatingIntent}
					onChange={(event) => {
						updateDeliveryType(event.target.value as DeliveryOptionFriendly);
					}}
				/>
			</FormField>
		);
	}, [deliveryOptions, props.intent, isUpdatingIntent, deliveryTypeFriendly]);

	const priceComponent = useMemo(() => {
		return (
			<Box justify="start" align="end">
				<Text weight="bold">{formatCurrency(subtotal)}</Text>
				<Text size="small">
					Quantity: <Text weight="bold" size="small">{quantity}</Text>
				</Text>
				{props.product?.inventory?.quantity_available > 1 && (
					<Box align="center" fill="horizontal">
						<Anchor
							size="small"
							label="Edit?"
							onClick={() => {
								setIsUpdatingQuantity(true);
							}}
						/>
					</Box>
				)}
			</Box>
		);
	}, [subtotal, quantity]);

	const descriptionComponent = useMemo(() => {
		return (
			<Box flex gap="small">
				<Text color="brand" weight="bold" wordBreak="break-word">
					{props.product.description}
				</Text>

			</Box>
		);
	}, [props.product]);

	return (
		<Box margin="small" gap="medium">
			{isUpdatingQuantity && (
				<UpdateQuantityModal
					intent={props.intent}
					product={props.product}
					onClose={() => {
						setIsUpdatingQuantity(false);
					}}
					onQuantityUpdated={updateQuantity}
				/>
			)}
			{isFullWidth && (
				<Box flex gap="small">
					<Box direction="row" justify="between">
						{descriptionComponent}
						<Box justify="start" align="end">
							{priceComponent}
							<Box align="end" justify="end" flex>
								{actionComponent}
							</Box>
						</Box>
					</Box>
				</Box>
			)}
			{!isFullWidth && (
				<Grid columns={["auto", "flex", "auto"]} gap="small">
					{imageComponent}
					<Box>
						{descriptionComponent}
						{actionComponent}
					</Box>
					{priceComponent}
				</Grid>
			)}
			<Divider />
		</Box>
	);
};

interface CheckoutSubtotalProps {
	intents: DTO<PurchaseIntent>[];
}

export const CheckoutSubtotal: React.FC<CheckoutSubtotalProps> = (props) => {

	const subtotalMessage = useMemo(() => {
		const count = props.intents.map(intent => {
			return intent.quantity;
		}).reduce((p, c) => p + c, 0);

		const subtotal = props.intents.map(intent => {
			const quantity = intent.quantity;
			return Number(intent.product.price) * quantity;
		}).reduce((p, c) => p + c, 0);
		const itemsMessage = count > 1 || count === 0 ? "items" : "item";
		return (
			<Text weight="bold">
				Subtotal ({count} {itemsMessage}): <Text weight="bolder">{formatCurrency(subtotal)}</Text>
			</Text>
		);
	}, [props.intents]);

	return (
		<Box align="start">
			{subtotalMessage}
		</Box>
	);
};