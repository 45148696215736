import { DTO, Purchase, Service } from "@rego-app/common";
import { Box } from "grommet";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { OrderService } from "../../../app/services";
import { Loader } from "../../common";
import { ServiceReviewPage } from "../pages";


export const ReviewController: React.FC = (props) => {
	const location = useLocation();
	const [service, setService] = useState<DTO<Service> | null>(null);
	const [purchase, setPurchase] = useState<DTO<Purchase> | null>(null);
	const [type, setType] = useState<"service" | "purchase" | null>(null);
	const [hasError, setHasError] = useState(false);

	function handleFetchService(serviceId: string): void {
		OrderService.getService(serviceId)
			.then(res => {
				setService({ ...res });
				setType("service");
			})
			.catch(err => {
				console.error("Failed to load service", err);
				setHasError(true);
			});
	}

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		if(params.get("sid")) {
			handleFetchService(params.get("sid") ?? "");
		}
	}, [location.search]);

	if(hasError) {
		throw new Error(`failed in review controller`);
	}

	if(type === "service" && service) {
		return (
			<ServiceReviewPage
				service={service}
			/>
		);
	}

	if(type === "purchase") {

	}

	return (
		<Loader visible={true} />
	);
};