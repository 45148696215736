import type { Contact, Customer, DTO, Order, Product, ServerResponse } from "../../types";
import { RequestService } from "./request";
import { ActionItem, Cart, CreatePurchaseIntent, CustomerProduct, Donation, PaymentMethod, ProductIntent, Purchase, RelationExpand, Removal } from "@rego-app/common";

export const CustomerService = {

	async getCustomer(customerId: string): Promise<Customer> {
		try {
			const result = await RequestService.get<ServerResponse<Customer>>(`/api/v1/customer/${customerId}`);
			return result.data.result;
		}
		catch(e) {
			throw e;
		}
	},

	async getCustomerSelf(expand?: RelationExpand<Customer>): Promise<DTO<Customer>> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<Customer>>>("/api/v1/customer/me", {
				params: {
					expand: expand?.join(",") ?? undefined
				}
			});
			return result.data.result;
		}
		catch(e) {
			throw e;
		}
	},

	async searchCustomerByContact(value: string): Promise<Customer | null> {
		try {
			const result = await RequestService.get<ServerResponse<Customer | null>>(`/api/v1/customer/search?query=${value}`);
			return result.data.result;
		}
		catch(e) {
			throw e;
		}
	},

	async updateCustomer(customerId: string, data: DTO<Customer>): Promise<DTO<Customer>> {
		try {
			const result = await RequestService.patch<ServerResponse<DTO<Customer>>>(`/api/v1/customer/${customerId}`, {
				...data
			});
			return result.data.result;
		}
		catch(e) {
			throw e;
		}
	},

	async createContact(customerId: string, contact: Partial<DTO<Contact>>): Promise<DTO<Contact>> {
		try {
			const result = await RequestService.post<ServerResponse<DTO<Contact>>>(`/api/v1/customer/${customerId}/contact`, {
				...contact
			});
			return result.data.result;
		}
		catch(e) {
			throw e;
		}
	},

	async updateContact(customerId: string, contact: DTO<Contact>): Promise<DTO<Contact>> {
		try {
			const result = await RequestService.patch<ServerResponse<DTO<Contact>>>(`/api/v1/customer/${customerId}/contact/${contact.id}`, {
				...contact
			});
			return result.data.result;
		}
		catch(e) {
			throw e;
		}
	},

	async getContacts(customerId: string): Promise<DTO<Contact>[]> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<Contact>[]>>(`/api/v1/customer/${customerId}/contact`);
			return result.data.result;
		}
		catch(e) {
			throw e;
		}
	},

	async deleteContact(customerId: string, contactId: string): Promise<DTO<Contact>> {
		try {
			const result = await RequestService.delete<ServerResponse<DTO<Contact>>>(`/api/v1/customer/${customerId}/contact/${contactId}`);
			return result.data.result;
		}
		catch(e) {
			throw e;
		}
	},

	async sendVerificationCode(customerId: string, contactId: string): Promise<boolean> {
		try {
			const result = await RequestService.put<ServerResponse<boolean>>(`/api/v1/customer/${customerId}/contact/${contactId}/verify`);
			return result.data.result;
		}
		catch(e) {
			console.error("Failed to send verification code", e);
			return false;
		}
	},

	async confirmVerificationCode(customerId: string, contactId: string, code: string): Promise<DTO<Contact>> {
		try {
			const result = await RequestService.post<ServerResponse<DTO<Contact>>>(`/api/v1/customer/${customerId}/contact/${contactId}/verify`, {
				token: code
			});
			return result.data.result;
		}
		catch(e) {
			console.error("Failed to confirm verification code", e);
			throw e;
		}
	},

	async listCustomerProducts(customerId: string, expand?: RelationExpand<CustomerProduct>): Promise<DTO<CustomerProduct>[]> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<CustomerProduct>[]>>(`/api/v1/customer/${customerId}/product`, {
				params: {
					expand: (expand ?? []).join(",")
				}
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch products for customer [${customerId}]`, e);
			throw e;
		}
	},

	async listCustomerOrders(customerId: string, filters: Partial<Order>, expand?: RelationExpand<Order>): Promise<DTO<Order>[]> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<Order>[]>>(`/api/v1/customer/${customerId}/order`, {
				params: {
					...filters,
					expand: (expand ?? []).join(",")
				}
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch orders for customer [${customerId}]`, e);
			throw e;
		}
	},

	async listCustomerDonations(customerId: string, filters: Partial<Donation>): Promise<DTO<Donation>[]> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<Donation>[]>>(`/api/v1/customer/${customerId}/donation`, {
				params: filters
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch donations for customer [${customerId}]`, e);
			throw e;
		}
	},

	async listCustomerRemovals(customerId: string, filters: Partial<Removal>): Promise<DTO<Removal>[]> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<Removal>[]>>(`/api/v1/customer/${customerId}/removal`, {
				params: filters
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch removals for customer [${customerId}]`, e);
			throw e;
		}
	},

	async listCustomerPurchases(customerId: string, filters: Partial<Purchase>, expand?: RelationExpand<Purchase>): Promise<DTO<Purchase>[]> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<Purchase>[]>>(`/api/v1/customer/${customerId}/purchase`, {
				params: {
					...filters,
					expand: expand ? expand.join(",") : undefined
				}
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch purchases for customer [${customerId}]`, e);
			throw e;
		}
	},

	async getActiveCustomerPurchase(customerId: string, expand?: RelationExpand<Purchase>): Promise<DTO<Purchase> | null> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<Purchase> | null>>(`/api/v1/customer/${customerId}/purchase/active`, {
				params: {
					expand: expand ? expand.join(",") : undefined
				}
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch active purchase for customer [${customerId}]`, e);
			throw e;
		}
	},

	async createCustomerProduct(customerId: string, product: DTO<CustomerProduct>): Promise<DTO<CustomerProduct>> {
		try {
			const result = await RequestService.put<ServerResponse<DTO<CustomerProduct>>>(`/api/v1/customer/${customerId}/product`, {
				...product,
				id: undefined
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to create product customer [${customerId}]`, e);
			throw e;
		}
	},

	async createPaymentMethodIntent(customerId: string): Promise<string> {
		try {
			const result = await RequestService.put<ServerResponse<string>>(`/api/v1/customer/${customerId}/payment-method`);
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch orders for customer [${customerId}]`, e);
			throw e;
		}
	},

	async listCustomerPaymentMethods(customerId: string, filters: Partial<PaymentMethod>): Promise<DTO<PaymentMethod>[]> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<PaymentMethod>[]>>(`/api/v1/customer/${customerId}/payment-method`, {
				params: filters
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch orders for customer [${customerId}]`, e);
			throw e;
		}
	},

	async deletePaymentMethod(customerId: string, paymentMethodId: string): Promise<DTO<PaymentMethod>> {
		try {
			const result = await RequestService.delete<ServerResponse<DTO<PaymentMethod>>>(`/api/v1/customer/${customerId}/payment-method/${paymentMethodId}`);
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch orders for customer [${customerId}]`, e);
			throw e;
		}
	},

	async getCustomerCart(customerId: string, expand?: RelationExpand<Cart>): Promise<DTO<Cart> | null> {
		try {
			const result = await RequestService.get<ServerResponse<DTO<Cart> | null>>(`/api/v1/customer/${customerId}/cart`, {
				params: {
					expand: expand ? expand.join(",") : undefined
				}
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch cart for customer [${customerId}]`, e);
			throw e;
		}
	},

	async addProductToCart(customerId: string, productId: string): Promise<DTO<Cart>> {
		try {
			const result = await RequestService.put<ServerResponse<DTO<Cart>>>(`/api/v1/customer/${customerId}/cart/product/${productId}`);
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to add product [${productId}] to cart for customer [${customerId}]`, e);
			throw e;
		}
	},

	async removeProductFromCart(customerId: string, productId: string): Promise<DTO<Cart>> {
		try {
			const result = await RequestService.delete<ServerResponse<DTO<Cart>>>(`/api/v1/customer/${customerId}/cart/product/${productId}`);
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to remove product [${productId}] orders for customer [${customerId}]`, e);
			throw e;
		}
	},

	async convertCartToPurchase(customerId: string, products: CreatePurchaseIntent[]): Promise<DTO<Purchase>> {
		try {
			const result = await RequestService.put<ServerResponse<DTO<Purchase>>>(`/api/v1/customer/${customerId}/cart/purchase`, {
				products
			});
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch cart for customer [${customerId}]`, e);
			throw e;
		}
	},

	async getCustomerActionItems(customerId: string): Promise<ActionItem[]> {
		try {
			const result = await RequestService.get<ServerResponse<ActionItem[]>>(`/api/v1/customer/${customerId}/action`);
			return result.data.result;
		}
		catch(e) {
			console.error(`Failed to fetch action items for customer [${customerId}]`, e);
			throw e;
		}
	}
};