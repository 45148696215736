import { Box } from "grommet";
import React from "react";
import { SlimHeading } from "../../common";
import { useOrder } from "../controller";

export const OrderConfirmation: React.FC = (props) => {
	const { order } = useOrder();

	return (
		<Box margin="large" gap="small" align="center">
			<SlimHeading level="3">Your order has been submitted</SlimHeading>
		</Box>
	);
};