import { ProductIntent } from "@rego-app/common";
import { useMemo } from "react";
import { useAppSelector } from "../../app/store";
import { selectForcedIntent, selectOrderGoal, selectOrderIntent } from "../../app/store/order";


export function useIntent() {
	const goal = useAppSelector(selectOrderGoal);
	const intent = useAppSelector(selectOrderIntent);
	const forcedIntent = useAppSelector(selectForcedIntent);

	const isSalesFlow = useMemo(() => {
		if(forcedIntent && forcedIntent !== ProductIntent.SELL) {
			return false;
		}

		return intent === ProductIntent.SELL;
	}, [ intent, forcedIntent, goal]);

	const isDonateFlow = useMemo(() => {
		if(forcedIntent && forcedIntent !== ProductIntent.DONATE) {
			return false;
		}

		return intent === ProductIntent.DONATE;
	}, [ intent, forcedIntent, goal]);

	const isJunkFlow = useMemo(() => {
		if(forcedIntent && forcedIntent !== ProductIntent.JUNK) {
			return false;
		}

		return intent === ProductIntent.JUNK;
	}, [ intent, forcedIntent, goal]);

	return {
		goal,
		intent,
		forcedIntent,
		isSalesFlow,
		isDonateFlow,
		isJunkFlow,
		isForced: !!forcedIntent
	};
}