import { DetectedObject, ObjectDetection } from "@tensorflow-models/coco-ssd";
import { Box, Button, Heading, ResponsiveContext, Spinner, Stack } from "grommet";
import { Radial } from "grommet-icons";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useCoco, useWebcam, useWindowDimensions } from "../../common";
import { Media } from "@rego-app/common";
import { ProductService } from "../../../app/services";

export const Materials = [
	"Wood",
	"Plastic",
	"Metal",
	"Foam",
	"Leather",
	"Fabric"
] as const;

export interface Detection {
	x: number;
	y: number;
	width: number;
	height: number;
	score: number;
	name: string;
	materials?: Array<typeof Materials[ number ]>;
}

export function useDetection() {
	const coco = useCoco();

	const detect = useCallback(async (element: HTMLImageElement): Promise<Detection[]> => {
		if(!coco) throw new Error("Coco is not ready");
		return (await coco.detect(element)).map(d => {
			const materials = ((): Detection[ "materials" ] => {
				switch(d.class) {
					case "couch": {
						return [ "Wood", "Foam", "Fabric" ];
					}
					case "chair": {
						return [ "Wood", "Fabric", "Foam" ];
					}
					default: {
						return [];
					}
				}
			})();
			return {
				x: d.bbox[ 0 ],
				y: d.bbox[ 1 ],
				width: d.bbox[ 2 ],
				height: d.bbox[ 3 ],
				score: d.score,
				name: d.class,
				materials
			};
		});
	}, [ coco ]);

	return {
		detect,
		isReady: !!coco
	};
}

export function useProductDetection(ref: React.RefObject<HTMLImageElement>) {
	const { isReady, detect } = useDetection();
	const [ detections, setDetections ] = useState<Detection[]>([]);

	const fetchDetections = useCallback(async (productId: string, media: Media[]) => {
		console.log("Fetching detections for product: ", productId, media, ref);
		if(ref.current && isReady) {
			for(const image of media) {
				const element = ref.current;

				const src = await ProductService.getMediaURL(productId, image.id);
				const [ path, query ] = src.split("?");

				element.src = path + "?" + query + "&t=" + Date.now() + "&r=" + Math.random();
				console.log("SRC: ", element.src);

				await new Promise<void>((r, j) => {
					function loaded(event: any): any {
						console.log("GOT LOADED!", element, event);
						if(!detect) return;

						detect(element).then(detections => {
							console.log("Detections: ", detections);
							setDetections([ ...detections ]);
						}).catch(err => {
							console.error("Failed to detect", err);
						}).finally(() => {
							element.removeEventListener("load", loaded);
							r();
						});
					}

					element.addEventListener("load", loaded);
				});
			}
		}
	}, [ ref, isReady ]);

	const bestDetection = useMemo(() => {
		if(detections.length) {
			const best = detections.reduce((prev, curr) => {
				if(curr.score > prev.score) {
					return curr;
				}

				return prev;
			}, detections[ 0 ]);

			return best;
		}

		return null;
	}, [ detections ]);

	return {
		detections,
		bestDetection,
		fetchDetections
	};
}



export const ScanDemo: React.FC = () => {
	const webcam = useWebcam();
	const coco = useCoco();
	const dimensions = useWindowDimensions();
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const [ isLooping, setLooping ] = useState(false);
	const [ loopInterval, setLoopInterval ] = useState<NodeJS.Timeout | null>(null);
	const [ counter, setCounter ] = useState(0);
	const [ detections, setDetections ] = useState<Detection[]>([]);

	function getCanvasWidth(): number {
		return Math.min(
			400,
			Number((dimensions.width * .80).toFixed(0))
		);
	}

	function getCanvasHeight(): number {
		return Math.min(
			//getCanvasWidth(),
			Number((dimensions.height * .80).toFixed(0))
		);
	}

	// Main function
	const runCoco = async () => {
		setCounter(counter + 1);
		//  Loop and detect hands
		if(isLooping || loopInterval || !coco) {
			return;
		}

		setLoopInterval(
			setInterval(() => {
				detect(coco);
			}, 50)
		);
		setLooping(true);
	};

	useEffect(() => {
		if(coco && !isLooping) {
			runCoco();
		}
	}, [ coco ]);

	function stopLooping(): void {
		if(loopInterval) {
			clearInterval(loopInterval);
		}
		setLoopInterval(null);
		setLooping(false);
	}

	const detect = async (net: ObjectDetection) => {
		// Check data is available
		if(
			typeof webcam.ref.current !== "undefined" &&
			webcam.ref.current !== null &&
			webcam.ref.current.video?.readyState === 4
		) {
			// Get Video Properties
			const video = webcam.ref.current.video;
			const videoWidth = webcam.ref.current.video.videoWidth;
			const videoHeight = webcam.ref.current.video.videoHeight;

			// Set video width
			webcam.ref.current.video.width = videoWidth;
			webcam.ref.current.video.height = videoHeight;

			// Set canvas height and width
			if(canvasRef.current) {
				canvasRef.current.width = videoWidth;
				canvasRef.current.height = videoHeight;
			}

			// Make Detections
			const obj = await net.detect(video);
			console.debug(obj);

			// Draw mesh
			const ctx = canvasRef.current?.getContext("2d") ?? null;
			drawRect(obj, ctx);
		}
	};


	const drawRect = (detections: DetectedObject[], ctx: CanvasRenderingContext2D | null) => {
		// Loop through each prediction
		const currentDetections: Detection[] = [];

		detections.forEach(prediction => {
			if(!ctx) {
				console.error("missing ctx for detections", detections);
				return;
			}

			// Extract boxes and classes
			const [ x, y, width, height ] = prediction[ "bbox" ];
			const text = prediction[ "class" ];
			console.debug(`predicted [${text}] with score [${prediction.score}]`);

			if(prediction.score > .60) {
				currentDetections.push({
					x,
					y,
					width,
					height,
					name: text,
					score: prediction.score
				});

				// Set styling
				const color = Math.floor(Math.random() * 16777215).toString(16);
				ctx.strokeStyle = '#' + color;
				ctx.font = '18px Arial';

				// Draw rectangles and text
				ctx.beginPath();
				ctx.fillStyle = '#' + color;
				ctx.fillText(text, x, y);
				ctx.rect(x, y, width, height);
				ctx.stroke();
			}

		});

		setDetections(currentDetections);
	};

	return (
		<ResponsiveContext.Consumer>
			{(size: string) => (
				<Box fill direction="column" align="center" justify="center">
					<Box background="lightgray" style={{ width: getCanvasWidth(), height: getCanvasHeight() }}>
						<Stack fill>
							<Box fill>
								{(!webcam.isLoaded || !webcam.hasWebcam || !webcam.hasWebcamPermission)
									? (() => {
										if(!webcam.isLoaded) {
											return (
												<Box direction="column" justify="center" align="center" fill>
													<Spinner size="large" />
												</Box>
											);
										}

										if(!webcam.hasWebcam) {
											return (
												<Box background="white">
													<Heading level="4" margin="small">
														You need a device with a camera in order to play with the demo
													</Heading>
												</Box>
											);
										}

										if(!webcam.hasWebcamPermission) {
											return (
												<Box background="white">
													<Heading level="4" margin="small">
														We need camera permission before we can launch the demo. Please grant permission and then refresh this page
													</Heading>
												</Box>
											);
										}
									})()
									: (
										<Box direction="row" justify="center" align="center" fill>
											<Stack fill>
												<Webcam
													className="webcam"
													ref={webcam.ref}
													muted={true}
													videoConstraints={{
														facingMode: {
															ideal: "environment"
														}
													}}
													style={{
														textAlign: "center",
														width: "100%",
														height: "100%",
														objectFit: "cover"
													}}
												/>
												<canvas
													ref={canvasRef}
													style={{
														position: "absolute",
														marginLeft: "auto",
														marginRight: "auto",
														left: 0,
														right: 0,
														textAlign: "center",
														zIndex: 0,
														width: getCanvasWidth(),
														height: getCanvasHeight()
													}}
												/>
											</Stack>
										</Box>
									)}
							</Box>
							<Box fill align="center" justify="around" direction="row">
								<Box
									margin={{ vertical: "small", horizontal: "medium" }}
									height="30%"
									width="20%"
									border={{ side: "all", color: "green", size: "medium" }}
									style={{ borderRight: "none" }}
								/>
								<Box
									margin={{ vertical: "small", horizontal: "medium" }}
									height="30%"
									width="20%"
									border={{ side: "all", color: "green", size: "medium" }}
									style={{ borderLeft: "none" }}
								/>
							</Box>
							<Box fill direction="row" align="center" justify="center">
								<Box alignSelf="end" justify="center" margin="medium" round>
									<Button
										hoverIndicator
										onClick={() => {
											alert("CLICKED!");
										}}
										icon={<Radial size="xlarge" />}
									/>
								</Box>
							</Box>
						</Stack>
					</Box>
				</Box>
			)}
		</ResponsiveContext.Consumer>
	);
};