import { Order, ProductIntent } from "../../../types";
import { Box, Button, Grid, Heading } from "grommet";
import { FormClose, Gift, Money, Trash } from "grommet-icons";
import React, { useEffect, useMemo, useState } from "react";
import { Loader, SlimHeading, useAlert, useScreenSize, useWindowDimensions } from "../../common";
import { listCustomerOrders, useAppDispatch, useAppSelector } from "../../../app/store";
import { push } from "connected-react-router";
import { cancelOrder, createOrDeferCustomerOrder, resetOrderState, selectForcedIntent, selectOrder, selectOrderInProgress, selectOrderIntent, setInboundReferral, setOrder, setOrderIntent } from "../../../app/store/order";
import { Outlet } from "react-router-dom";
import { isInboundReferral } from "../../../helpers";

interface SellActionProps {
	onSelected(intent: ProductIntent): void;
}

const SellAction: React.FC<SellActionProps> = (props) => {
	const alerts = useAlert();
	const dispatch = useAppDispatch();

	return (
		<Button
			primary
			label="Sell"
			onClick={() => {
				alerts.display<ProductIntent>((callback) => (
					<Box margin="medium" gap="medium">
						<SlimHeading level="3">Coming Soon!</SlimHeading>
						<SlimHeading level="5">
							Selling with Rego is coming soon, but in the meantime, we can still help you donate or remove furniture. Click below to get started
						</SlimHeading>

						<Grid columns={{ size: "auto", count: 2 }} gap="small">
							<Button
								primary
								label="Donate"
								onClick={() => {
									callback(ProductIntent.DONATE);
								}}
							/>
							<Button
								primary
								label="Junk Removal"
								onClick={() => {
									callback(ProductIntent.JUNK);
								}}
							/>
						</Grid>
					</Box>
				), { closeOnClickOutside: true, closeOnEscape: true })
					.then(intent => {
						if(intent) {
							props.onSelected(intent);
						}
					})
					.catch(err => {
						console.error("Error in alert", err);
					});
			}}
		/>
	);
};

interface ProductIntentData {
	title: string;
	description: string;
	icon: JSX.Element;
	path: string;
	intent: ProductIntent;
}

const DATA: ProductIntentData[] = [
	{
		title: "Sell",
		description: "Sell your furniture on the Rego marketplace. We'll handle the listings, communication, payments, and delivery.",
		icon: <Money color="brand" size="large" />,
		path: "/order/sell",
		intent: ProductIntent.SELL
	},
	{
		title: "Donate",
		description: "Auto-match with local charitable organizations while we take care of the communication, scheduling, and logistics.",
		icon: <Gift color="brand" size="large" />,
		path: "/order/donate",
		intent: ProductIntent.DONATE
	},
	{
		title: "Junk Removal",
		description: "When needed, you're auto matched with licensed, professional recycling and removal services. We handle the communication, payments, and scheduling so you don't have to.",
		icon: <Trash color="brand" size="large" />,
		path: "/order/junk",
		intent: ProductIntent.JUNK
	}
];

interface ProductIntentCardProps extends ProductIntentData {
	onClick: (intent: ProductIntent) => void;
}

export const OrderController: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const order = useAppSelector(selectOrder);

	useEffect(() => {
		if(!order) {
			dispatch(setOrder({

			} as Order));
		}
	}, []);

	return (
		<Box margin="large">
			<Outlet />
		</Box>
	);
};

export const SelectOrderIntent: React.FC<{ intent?: ProductIntent; }> = (props) => {
	const screenSize = useScreenSize();
	const { size } = useWindowDimensions();
	const alert = useAlert();
	const dispatch = useAppDispatch();
	const forcedIntent = useAppSelector(selectForcedIntent);
	const orderInProgress = useAppSelector(selectOrderInProgress);

	const [state, setState] = useState<CaptureOrderIntentState>({
		hasError: false,
		isLoading: true
	});

	const columns = useMemo(() => {
		switch(size) {
			case "small": {
				return 1;
			}
			case "medium": {
				return 2;
			}
			case "large": {
				return 2;
			}
			default: {
				return 3;
			}
		}
	}, [size]);

	useEffect(() => {
		dispatch(resetOrderState());
		dispatch(listCustomerOrders()).unwrap()
			.catch(err => {
				console.error("Error loading customer orders", err);
			})
			.finally(() => {
				setState({
					...state,
					isLoading: false
				});
			});
	}, []);

	useEffect(() => {
		if(!state.isLoading) {
			if(props.intent) {
				handleIntentSelected(props.intent);
			}
			else {
				setState({
					...state,
					isLoading: false
				});
			}
		}
	}, [state.isLoading]);

	function handleIntentSelected(intent: ProductIntent, forceCreateOrder?: boolean): void {
		dispatch(setOrderIntent(intent));

		if(forceCreateOrder) {
			return startOrder();
		}

		startOrderWithInterrupt();
	}

	useEffect(() => {
		if(forcedIntent) {
			handleIntentSelected(forcedIntent, true);
		}
	}, [forcedIntent]);

	function startOrderWithInterrupt(): void {
		if(orderInProgress) {
			alert.display<"CANCEL" | "CONTINUE" | "CREATE">((closeCallback) => (
				<Box>
					<Box align="end">
						<Button
							margin="small"
							hoverIndicator
							icon={<FormClose size="medium" />}
							onClick={() => closeCallback("CANCEL")}
						/>
					</Box>
					<Box margin={{ horizontal: "medium", bottom: "medium" }} gap="medium">
						<Box>
							<SlimHeading level="4" >
								You have an existing order in progress. Are you sure you want to create a new one?
							</SlimHeading>
						</Box>
						<Box direction="row" justify="between">
							<Button
								color="status-warning"
								primary
								label="Continue"
								onClick={() => {
									closeCallback("CONTINUE");
								}} />
							<Button
								color="status-ok"
								primary
								label="Create New"
								onClick={() => {
									closeCallback("CREATE");
								}} />
						</Box>
					</Box>
				</Box>
			)
			).then((shouldAdvance) => {
				switch(shouldAdvance) {
					case "CANCEL": {
						break;
					}
					case "CONTINUE": {
						const referrals = orderInProgress.referrals ?? [];
						const inbound = referrals.find(r => isInboundReferral(r));
						if(inbound && isInboundReferral(inbound) && inbound.partner) {
							dispatch(setInboundReferral(inbound));
						}

						dispatch(setOrder(orderInProgress));
						dispatch(push("/order/products"));
						break;
					}
					case "CREATE": {
						dispatch(cancelOrder(orderInProgress.id)).unwrap()
							.then(() => {
								dispatch(resetOrderState());
							})
							.catch(err => {
								console.error("Failed to cancel order", orderInProgress, err);
							})
							.finally(() => {
								startOrder();
							});
						break;
					}
				}
			});

			return;
		}

		startOrder();
	}

	function startOrder(): void {
		dispatch(createOrDeferCustomerOrder({})).unwrap()
			.then(() => {
				dispatch(push("/order/products"));
			})
			.catch(err => {
				setState({
					...state,
					isLoading: false
				});
			});
	}

	return (
		<Loader visible={state.isLoading}>
			<Box margin="large">
				<Grid columns={{ count: columns, size: "auto" }} align="between" gap="medium">
					<ProductIntentCard
						{...DATA.find(d => d.intent === ProductIntent.SELL) ?? {} as ProductIntentData}
						intent={ProductIntent.SELL}
						onClick={() => {
							handleIntentSelected(ProductIntent.SELL);
						}}
					/>
					<ProductIntentCard
						{...DATA.find(d => d.intent === ProductIntent.DONATE) ?? {} as ProductIntentData}
						intent={ProductIntent.DONATE}
						onClick={() => {
							handleIntentSelected(ProductIntent.DONATE);
						}}
					/>
					<ProductIntentCard
						{...DATA.find(d => d.intent === ProductIntent.JUNK) ?? {} as ProductIntentData}
						intent={ProductIntent.JUNK}
						onClick={() => {
							handleIntentSelected(ProductIntent.JUNK);
						}}
					/>
				</Grid>
			</Box>
		</Loader>
	);
};

const ProductIntentCard: React.FC<ProductIntentCardProps> = (props) => {
	return (
		<Box elevation="large" round>
			<Box margin="large" gap="medium" fill="vertical">
				<Box gap="medium">
					<Box align="center" justify="center">
						{props.icon}
					</Box>
					<Box>
						<SlimHeading level={5}>
							{props.description}
						</SlimHeading>
					</Box>
				</Box>
				<Box justify="end" flex="grow">
					<Button
						primary
						onClick={() => {
							props.onClick(props.intent);
						}}
						label={props.title}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export function useOrder(): { order: Order, intent: ProductIntent; } {
	const dispatch = useAppDispatch();
	const order = useAppSelector(selectOrder);
	const intent = useAppSelector(selectOrderIntent);

	if(!intent || !order) {
		dispatch(push("/order"));
	}

	if(order?.ordered_at) {
		//Order already in ordered status
		console.debug(`Order [${order.id}] has already been ordered`, order);
		dispatch(push("/dashboard"));
	}

	return {
		intent: intent as ProductIntent,
		order: order as Order
	};
}

interface CaptureOrderIntentState {
	hasError: boolean;
	isLoading: boolean;
}

export const CaptureOrderIntent: React.FC<{ intent: ProductIntent; }> = (props) => {
	const alert = useAlert();
	const dispatch = useAppDispatch();
	const orderInProgress = useAppSelector(selectOrderInProgress);

	const [state, setState] = useState<CaptureOrderIntentState>({
		hasError: false,
		isLoading: true
	});

	function startOrderWithInterrupt(): void {
		if(orderInProgress) {
			alert.display<boolean>((closeCallback) => (
				<Box margin="small" >
					<Box margin="medium" >
						<Heading level="4" >
							You have an existing order in progress. Are you sure you want to create a new one?
						</Heading>
					</Box>
					<Box direction="row" justify="between" >
						<Button color="status-error" primary label="No" onClick={() => {
							closeCallback(false);
						}} />
						<Button color="status-ok" primary label="Yes, create a new one" onClick={() => {
							closeCallback(true);
						}} />
					</Box>
				</Box>
			)
			).then((shouldAdvance) => {
				if(shouldAdvance) {
					dispatch(cancelOrder(orderInProgress.id)).unwrap()
						.catch(err => {
							console.error("Failed to cancel order", orderInProgress, err);
						})
						.finally(() => {
							startOrder();
						});
				}
			});

			return;
		}

		startOrder();
	}

	function startOrder(): void {
		dispatch(createOrDeferCustomerOrder({})).unwrap()
			.then(() => {
				dispatch(push("/order/products"));
			})
			.catch(err => {
				setState({
					...state,
					isLoading: false
				});
			});
	}

	useEffect(() => {
		dispatch(resetOrderState());
		dispatch(setOrderIntent(props.intent));
		startOrder();
	}, []);

	return (
		<Loader visible={false}>
			<Box margin="large">
				<Box align="center" gap="medium">
					<SlimHeading level="4">
						We ran into an issue getting your order started. Click below to try again.
					</SlimHeading>
					<Button
						primary
						label="Start Order"
						onClick={startOrderWithInterrupt}
					/>
				</Box>
				<Box>

				</Box>
			</Box>
		</Loader>
	);
};