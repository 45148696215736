import { LinearProgress } from "@mui/material";
import { Customer, Donation, DTO, Order, Purchase, Removal } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Grid, FormField, TextInput, Button, Form } from "grommet";
import { Search } from "grommet-icons";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { CustomerService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { listCustomerDonations, listCustomerOrders, listCustomerPurchases, listCustomerRemovals, openCustomer, selectCustomerDonations, selectCustomerOrders, selectCustomerPurchases, selectCustomerRemovals } from "../../../app/store/admin";
import { CopyToClipboardField, Loader, Modal, Pagination, SlimHeading } from "../../common";
import { DonationSummary } from "./donation";
import { OrderSummary } from "./orders";
import { PurchaseSummary } from "./purchases";
import { RemovalSummary } from "./removals";

export const CustomerInformation: React.FC<{ customer: DTO<Customer>; }> = (props) => {
	const { customer } = props;

	return (
		<Box>
			<Grid columns={{ count: 2, size: "flex" }} gap="small">
				<CopyToClipboardField
					name="customer_id"
					label="Customer Id"
					value={customer.id ?? ""}
				/>
				<CopyToClipboardField
					name="number"
					label="Customer Number"
					value={String(customer.number ?? "")}
				/>
			</Grid>
			<Grid columns={{ count: 2, size: "flex" }} gap="small">
				<Box>
					<FormField
						name="first_name"
						label="First Name"
						value={customer.first_name}
					>
						<TextInput value={customer.first_name} disabled name="first_name" />
					</FormField>
				</Box>
				<Box>
					<FormField
						name="last_name"
						label="Last Name"
						value={customer.last_name}
					>
						<TextInput value={customer.last_name} disabled name="last_name" />
					</FormField>
				</Box>
			</Grid>
			<Grid columns={{ count: 2, size: "flex" }} gap="small">
				<CopyToClipboardField
					name="email_address"
					label="Email Address"
					value={customer.email_address}
				/>
				<CopyToClipboardField
					name="phone_number"
					label="Phone Number"
					value={customer.phone_number}
				/>
			</Grid>
		</Box>
	);
};


export const CustomerOrders: React.FC<{ customer: DTO<Customer>; }> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const orders = useAppSelector(selectCustomerOrders);

	const [isLoaded, setIsLoaded] = useState(false);

	function loadCustomerOrders() {
		setIsLoaded(false);
		dispatch(listCustomerOrders({ customerId: props.customer.id, filters: {} })).unwrap()
			.catch(err => {
				console.error("Failed to load customer orders", err);
				snack.enqueueSnackbar("Failed to load customer orders", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}

	useEffect(() => {
		loadCustomerOrders();
	}, [props.customer]);

	return (
		<Loader visible={!isLoaded}>
			{orders.length
				? (
					<Box flex>
						<Pagination
							items={orders}
							pageSize={4}
							renderItems={(items) => (
								<Grid columns={{ count: 2, size: "flex" }} gap="small">
									{items.map(item => (
										<OrderSummary order={item as DTO<Order>} key={item.id} />
									))}
								</Grid>
							)}
						/>
					</Box>
				)
				: (
					<Box>
						<SlimHeading level="5">No customer orders</SlimHeading>
					</Box>
				)
			}

		</Loader>
	);
};

export const CustomerPurchases: React.FC<{ customer: DTO<Customer>; }> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const purchases = useAppSelector(selectCustomerPurchases);

	const [isLoaded, setIsLoaded] = useState(false);

	function loadCustomerPurchases() {
		setIsLoaded(false);
		dispatch(listCustomerPurchases({ customerId: props.customer.id, filters: { expired: false, paid: true } })).unwrap()
			.catch(err => {
				console.error("Failed to load customer purchases", err);
				snack.enqueueSnackbar("Failed to load customer purchases", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}

	useEffect(() => {
		loadCustomerPurchases();
	}, [props.customer]);

	return (
		<Loader visible={!isLoaded}>
			{purchases.length
				? (
					<Box flex>
						<Pagination
							items={purchases}
							pageSize={4}
							renderItems={(items) => (
								<Grid columns={{ count: 2, size: "flex" }} gap="small">
									{items.map(item => (
										<PurchaseSummary purchase={item as DTO<Purchase>} key={item.id} />
									))}
								</Grid>
							)}
						/>
					</Box>
				)
				: (
					<Box flex align="center" justify="center">
						<SlimHeading level="5">No customer purchases</SlimHeading>
					</Box>
				)
			}
		</Loader>
	);
};

export const CustomerRemovals: React.FC<{ customer: DTO<Customer>; }> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const removals = useAppSelector(selectCustomerRemovals);

	const [isLoaded, setIsLoaded] = useState(false);

	function loadCustomerRemovals() {
		setIsLoaded(false);
		dispatch(listCustomerRemovals({ customerId: props.customer.id, filters: {} })).unwrap()
			.catch(err => {
				console.error("Failed to load customer removals", err);
				snack.enqueueSnackbar("Failed to load customer removals", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}

	useEffect(() => {
		loadCustomerRemovals();
	}, [props.customer]);

	return (
		<Loader visible={!isLoaded}>
			{removals.length
				? (
					<Box flex>
						<Pagination
							items={removals}
							pageSize={4}
							renderItems={(items) => (
								<Grid columns={{ count: 1, size: "flex" }} gap="small">
									{items.map(item => (
										<RemovalSummary removal={item as DTO<Removal>} key={item.id} />
									))}
								</Grid>
							)}
						/>
					</Box>
				)
				: (
					<Box flex align="center" justify="center">
						<SlimHeading level="5">No customer donations</SlimHeading>
					</Box>
				)
			}
		</Loader>
	);
};

export const CustomerDonations: React.FC<{ customer: DTO<Customer>; }> = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const donations = useAppSelector(selectCustomerDonations);

	const [isLoaded, setIsLoaded] = useState(false);

	function loadCustomerDonations() {
		setIsLoaded(false);
		dispatch(listCustomerDonations({ customerId: props.customer.id, filters: {} })).unwrap()
			.catch(err => {
				console.error("Failed to load customer donations", err);
				snack.enqueueSnackbar("Failed to load customer donations", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}

	useEffect(() => {
		loadCustomerDonations();
	}, [props.customer]);

	return (
		<Loader visible={!isLoaded}>
			{donations.length
				? (
					<Box flex>
						<Pagination
							items={donations}
							pageSize={4}
							renderItems={(items) => (
								<Grid columns={{ count: 1, size: "flex" }} gap="small">
									{items.map(item => (
										<DonationSummary donation={item as DTO<Donation>} key={item.id} />
									))}
								</Grid>
							)}
						/>
					</Box>
				)
				: (
					<Box flex align="center" justify="center">
						<SlimHeading level="5">No customer donations</SlimHeading>
					</Box>
				)
			}
		</Loader>
	);
};

interface CustomerModalState {
	activeCustomer: Customer | null;
	isLoadingCustomer: boolean;
	customerSearchValue: string;
	isProductsOpen: boolean;
}


export const SearchCustomerModal: React.FC<{ customer?: Customer, visible: boolean, close: () => void; }> = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const [state, setState] = useState<CustomerModalState>({
		isLoadingCustomer: false,
		activeCustomer: null,
		customerSearchValue: "",
		isProductsOpen: false
	});

	useEffect(() => {
		if(props.customer) {
			wrapStateUpdate({ activeCustomer: props.customer });
		}
	}, [props.customer]);

	function handleModalClose(): void {
		setState((state) => {
			return { ...state, customerSearchValue: "" };
		});
		props.close();
	}

	function wrapStateUpdate(changes: Partial<CustomerModalState>) {
		setState(state => {
			return {
				...state,
				...changes
			};
		});
	}

	function handleSearchCustomer(contactValue: string): void {
		wrapStateUpdate({ isLoadingCustomer: true, activeCustomer: null });

		//handle missing +1
		if(/\d{10}/.test(contactValue)) {
			contactValue = "1" + contactValue;
		}

		CustomerService.searchCustomerByContact(contactValue)
			.then(res => {
				if(res) {
					dispatch(openCustomer(res));
					wrapStateUpdate({ activeCustomer: res });
				}
				else {
					snack.enqueueSnackbar("No customer found", {
						variant: "warning"
					});
				}
			})
			.catch(err => {
				console.error(`Failed to search for customer [${contactValue}]`, err);
				snack.enqueueSnackbar("Failed to search for customer", {
					variant: "error"
				});
			})
			.finally(() => {
				wrapStateUpdate({ isLoadingCustomer: false });
			});
	}

	function handleViewCustomer() {
		if(!state.activeCustomer) {
			return;
		}

		dispatch(push(`/admin/dashboard/customers/${state.activeCustomer.id}`));
	}

	return (
		<>
			{props.visible && (
				<Modal
					onClickClose={() => {
						handleModalClose();
					}}
					onClickOutside={() => {
						handleModalClose();
					}}
					onEsc={() => {
						handleModalClose();
					}}
				>
					<Box margin="small" gap="small">
						<Box>
							<Form
								value={state}
								onChange={(changes) => {
									wrapStateUpdate(changes);
								}}
							>
								{state.isLoadingCustomer && (
									<LinearProgress />
								)}
								<FormField
									name="customerSearchValue"
									placeholder="Email or phone"
								>
									<TextInput
										name="customerSearchValue"
										placeholder="Email or phone"
										icon={
											<Search onClick={() => {
												handleSearchCustomer(state.customerSearchValue);
											}} />
										}
										reverse
										onKeyDown={(event) => {
											if(event.key === "Enter") {
												handleSearchCustomer(state.customerSearchValue);
											}
										}}
									/>
								</FormField>
							</Form>
						</Box>
						<Box>
							<CustomerInformation customer={state.activeCustomer ?? {} as Customer} />
						</Box>
						{state.activeCustomer && (
							<Box align="center">
								<Button
									label="View More"
									onClick={handleViewCustomer}
								/>
							</Box>
						)}
					</Box>
				</Modal>
			)}
		</>

	);
};