import type { RecentRecord, Customer, Order, DTO, Donation, Removal } from "../../types";

const RECENT_CUSTOMERS = "admin-recent-customers" as const;
const RECENT_ORDERS = "admin-recent-orders" as const;
const RECENT_DONATIONS = "admin-recent-donations" as const;
const RECENT_REMOVALS = "admin-recent-removals" as const;

export const AdminService = {

	getRecentCustomers(): RecentRecord[] {
		try {
			const items = localStorage.getItem(RECENT_CUSTOMERS);
			return JSON.parse(items ?? "") as RecentRecord[];
		}
		catch(e) {
			return [];
		}
	},

	getRecentOrders(): RecentRecord[] {
		try {
			const items = localStorage.getItem(RECENT_ORDERS);
			return JSON.parse(items ?? "") as RecentRecord[];
		}
		catch(e) {
			return [];
		}
	},

	getRecentDonations(): RecentRecord[] {
		try {
			const items = localStorage.getItem(RECENT_DONATIONS);
			return JSON.parse(items ?? "") as RecentRecord[];
		}
		catch(e) {
			return [];
		}
	},

	getRecentRemovals(): RecentRecord[] {
		try {
			const items = localStorage.getItem(RECENT_REMOVALS);
			return JSON.parse(items ?? "") as RecentRecord[];
		}
		catch(e) {
			return [];
		}
	},

	recordRecentCustomer(customer: Customer): void {
		const current = this.getRecentCustomers();
		current.push({
			id: customer.id,
			timestamp: Date.now()
		});

		const sorted = current.filter((r, index) => {
			return current.findIndex(c => c.id === r.id) === index;
		}).sort((a, b) => b.timestamp - a.timestamp).slice(0, 10);

		localStorage.setItem(RECENT_CUSTOMERS, JSON.stringify(sorted));
	},

	recordRecentOrder(order: DTO<Order>): void {
		const current = this.getRecentOrders();
		current.push({
			id: order.id,
			timestamp: Date.now()
		});

		const sorted = current.filter((r, index) => {
			return current.findIndex(c => c.id === r.id) === index;
		}).sort((a, b) => b.timestamp - a.timestamp).slice(0, 10);

		localStorage.setItem(RECENT_ORDERS, JSON.stringify(sorted));
	},

	recordRecentDonation(donation: DTO<Donation>): void {
		const current = this.getRecentDonations();
		current.push({
			id: donation.id,
			timestamp: Date.now()
		});

		const sorted = current.filter((r, index) => {
			return current.findIndex(c => c.id === r.id) === index;
		}).sort((a, b) => b.timestamp - a.timestamp).slice(0, 10);

		localStorage.setItem(RECENT_DONATIONS, JSON.stringify(sorted));
	},

	recordRecentRemoval(removal: DTO<Removal>): void {
		const current = this.getRecentRemovals();
		current.push({
			id: removal.id,
			timestamp: Date.now()
		});

		const sorted = current.filter((r, index) => {
			return current.findIndex(c => c.id === r.id) === index;
		}).sort((a, b) => b.timestamp - a.timestamp).slice(0, 10);

		localStorage.setItem(RECENT_REMOVALS, JSON.stringify(sorted));
	}
};
