import React, { createContext, useContext, useState } from "react";
import { Modal } from "../../../types";
import { Layer } from "grommet";

const ModalContext = createContext<{ modals: Modal[], setModals: (modals: Modal[]) => void; }>({ modals: [], setModals: () => { } });

export const ModalProvider: React.FC = (props) => {

	const [ modals, setModals ] = useState<Modal[]>([]);
	const components = modals.filter(m => m.isVisible).map(m => (
		// eslint-disable-next-line react/jsx-no-comment-textnodes
		///@ts-ignore
		// eslint-disable-next-line react/jsx-no-comment-textnodes
		<Layer {...m} id={m.id} key={m.id}>
			///@ts-ignore
			{m.content}
		</Layer>
	));

	return (
		<ModalContext.Provider value={{ modals, setModals }}>
			{props.children}
			{components}
		</ModalContext.Provider>
	);
};

export function useModal() {
	const { modals, setModals } = useContext(ModalContext);

	return {
		push(modal: Modal): Modal {
			const existing = modals.findIndex(m => m.id === modal.id);

			if(existing !== -1) {
				modals[ existing ] = modal;
			}
			else {
				modals.push(modal);
			}

			setModals([ ...modals ]);

			return modal;
		},
		close(modal: Modal) {
			const existing = modals.findIndex(m => m.id === modal.id);

			if(existing !== -1) {
				modals.splice(existing, 1);
				setModals([ ...modals ]);
			}
		},
		closeById(id: string) {
			return this.close({ id: id } as Modal);
		}
	};
}