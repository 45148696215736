import { Route, Routes } from "react-router-dom";
import { StorePageController } from "../../partner/controller";
import { CheckoutController, MarketController, ShopController } from "../controller";
import { CartPage, ProductPage } from "../pages";
import { CheckoutConfirmationPage } from "../pages/confirmation";
import { ShopProvider } from "../provider";

export const ShopRouter: React.FC = (props) => {
	return (
		<ShopProvider>
			<Routes>
				<Route index element={<MarketController />} />
				<Route path='/cart' element={<CartPage />} />
				<Route path='/checkout' element={<CheckoutController />} />
				<Route path='/confirmation' element={<CheckoutConfirmationPage />} />
				<Route path='/:code' element={<ShopController />} />
				<Route path="/products/:productId" element={<ProductPage />} />
				<Route path="/store/:storeIdOrSlug" element={<StorePageController />} />
			</Routes>
		</ShopProvider>
	);
};