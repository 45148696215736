import { createHashMD5, DTO, ErrorResponse, ProductMedia } from "./types";
import moment from "moment-timezone";
import { Building, CustomerDeliveryConfirmation, CustomerProduct, DayOfWeek, Delivery, DeliveryConfirmation, Donation, InboundReferral, Media, OrderDelivery, Partner, PartnerType, PhysicalLocation, Product, ProductMediaContext, PurchaseDelivery, Referral, ReferralDirection, Removal, Service, Store, StoreDeliveryConfirmation, StoreHours, StoreProduct, Timezone } from "@rego-app/common";
import { ProviderContext, SnackbarAction, SnackbarProvider } from "notistack";

export function isErrorResponse(error: unknown | ErrorResponse): error is ErrorResponse {
	return !!(error as ErrorResponse)?.error;
}

export function decodeDataUrl(input: string): string {
	let encoded = input.toString().replace(/^data:(.*,)?/, '');
	if((encoded.length % 4) > 0) {
		encoded += '='.repeat(4 - (encoded.length % 4));
	}

	return encoded;
}

export const fileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = (): void => {
		if(!reader.result) throw new Error("No content in File");
		let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
		if((encoded.length % 4) > 0) {
			encoded += '='.repeat(4 - (encoded.length % 4));
		}
		resolve(encoded);
	};
	reader.onerror = (error): void => reject(error);
});

export async function fileToMedia<T extends DTO<Media> = DTO<Media>>(file: File, context: ProductMediaContext): Promise<T> {
	const content = await fileToBase64(file);
	return {
		name: file.name,
		content,
		content_hash: createHashMD5(content),
		content_type: file.type.toLowerCase(),
		extension: file.name.split(".").reverse()[0].toLowerCase(),
		context
	} as unknown as T;
}

export function mediaToFile(media: DTO<Media>): File {
	return new File(
		[Buffer.from(media.content ?? "", "base64")],
		media.name,
		{ type: media.content_type }
	);
}

export function getStandardFormValidations() {
	return [
		(value: unknown) => {
			if(!value) {
				return {
					status: "error",
					message: "This field is required"
				};
			}
		}
	];
}

export function getNumberFormValidations() {
	return [
		(value: unknown) => {
			if(isNaN(Number(value))) {
				return {
					status: "error",
					message: "Please enter a number"
				};
			}
		}
	];
}

export function getOptionalNumberFormValidations() {
	return [
		(value: unknown) => {
			if(!value) return;

			if(isNaN(Number(value))) {
				return {
					status: "error",
					message: "Please enter a number"
				};
			}
		}
	];
}

export function copyToClipboard(value: string): Promise<void> {
	return navigator.clipboard.writeText(value);
}

export function handleCopyToClipboard(value: string, snack: ProviderContext): void {
	copyToClipboard(value)
		.then(() => {
			snack.enqueueSnackbar(`Copied ${value} to clipboard`, {
				variant: "success"
			});
		});
}

export function parseTimestampFromUTC(date: Date | string | null, timezone: Timezone.Code): string {
	if(!date) {
		return "";
	}
	return moment.tz(date, "UTC").tz(timezone).format("MM/DD/YYYY hh:mm A");
}

export function parseDateFromUTC(date: Date | string | null, timezone: Timezone.Code): string {
	if(!date) {
		return "";
	}
	return moment.tz(date, "UTC").tz(timezone).format("MM/DD/YYYY");
}

export function prettyPrintHour(time: string): string {
	return moment().set("hours", Number(time.split(":")[0])).set("minutes", Number(time.split(":")[1] ?? 0)).format("hh:mm A");
}

export function prettyPrintStoreHours(hours: Store["hours"]): string[] {
	const keys = Object.keys(hours.daily) as (keyof typeof hours.daily)[];
	return keys.map(day => {
		const value = hours.daily[day];

		if(!value) {
			return `${day}: CLOSED`;
		}

		return `${day}: ${prettyPrintHour(value.from)} - ${prettyPrintHour(value.to)}`;
	});
}

export function prettyPrintHoursForDay(hours: StoreHours, dayOfWeek: DayOfWeek): string {
	const value = hours?.daily?.[dayOfWeek];
	if(!value) {
		return "Closed";
	}

	return `${prettyPrintHour(value.from)} - ${prettyPrintHour(value.to)}`;
}

export function dayOfWeekToNumber(dayOfWeek: DayOfWeek): number {
	switch(dayOfWeek) {
		case DayOfWeek.SUNDAY: {
			return 0;
		}
		case DayOfWeek.MONDAY: {
			return 1;
		}
		case DayOfWeek.TUESDAY: {
			return 2;
		}
		case DayOfWeek.WEDNESDAY: {
			return 3;
		}
		case DayOfWeek.THURSDAY: {
			return 4;
		}
		case DayOfWeek.FRIDAY: {
			return 5;
		}
		case DayOfWeek.SATURDAY: {
			return 6;
		}
	}
}

export function numberToDayOfWeek(number: number): DayOfWeek {
	switch(number) {
		case 0: {
			return DayOfWeek.SUNDAY;
		}
		case 1: {
			return DayOfWeek.MONDAY;
		}
		case 2: {
			return DayOfWeek.TUESDAY;
		}
		case 3: {
			return DayOfWeek.WEDNESDAY;
		}
		case 4: {
			return DayOfWeek.THURSDAY;
		}
		case 5: {
			return DayOfWeek.FRIDAY;
		}
		case 6: {
			return DayOfWeek.SATURDAY;
		}
		default: {
			throw new Error(`Invalid weekday number [${number}]`);
		}
	}
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 2
});

export function formatCurrency(value: number): string {
	return currencyFormatter.format(value);
}

export function findProductMedia(source: DTO<ProductMedia>[], to: DTO<ProductMedia>): DTO<ProductMedia> | null {
	return source.find(from => (to.id && to.id === from.id) || (to.name === from.name)) ?? null;
}

export function isRemoval(service: DTO<Service>): service is DTO<Removal> {
	return !Object.prototype.hasOwnProperty.call(service, "accepted");
}

export function isDonation(service: DTO<Service>): service is DTO<Donation> {
	return Object.prototype.hasOwnProperty.call(service, "accepted");
}

export function isInboundReferral(referral: DTO<Referral> | Referral): referral is DTO<InboundReferral> {
	return (referral as DTO<InboundReferral>).direction === ReferralDirection.INBOUND;
}

export function isCustomerConfirmation(confirmation: DTO<DeliveryConfirmation>): confirmation is DTO<CustomerDeliveryConfirmation> {
	return !!(confirmation as DTO<CustomerDeliveryConfirmation>).customer || confirmation.type === "Customer";
}

export function isStoreConfirmation(confirmation: DTO<DeliveryConfirmation>): confirmation is DTO<StoreDeliveryConfirmation> {
	return !!(confirmation as DTO<StoreDeliveryConfirmation>).store || confirmation.type === "Store";
}

export function isOrderDelivery(delivery: DTO<Delivery>): delivery is DTO<OrderDelivery> {
	const toTest = delivery as DTO<OrderDelivery>;
	return !!toTest?.order || !!toTest?.service;
}

export function isPurchaseDelivery(delivery: DTO<Delivery>): delivery is DTO<PurchaseDelivery> {
	const toTest = delivery as DTO<PurchaseDelivery>;
	return !!toTest?.purchase;
}

export function isStorePartner(partner: DTO<Partner>): partner is DTO<Store> {
	return (partner as Store).type === PartnerType.STORE;
}

export function isBuildingPartner(partner: DTO<Partner>): partner is DTO<Building> {
	return (partner as Building).type === PartnerType.BUILDING;
}

export function isLocationPartner(partner: DTO<Partner>): partner is DTO<PhysicalLocation> {
	return !!(partner as PhysicalLocation).address;
}

export function isStoreProduct(product: DTO<Product>): product is DTO<StoreProduct> {
	return !!(product as DTO<StoreProduct>).store || product.type === "Store";
}

export function isCustomerProduct(product: DTO<Product>): product is DTO<CustomerProduct> {
	return !!(product as DTO<CustomerProduct>).customer || product.type === "Customer";
}

export function sortPrimaryMedia(a: DTO<ProductMedia>, b: DTO<ProductMedia>): number {
	if(a.is_primary) return -1;
	if(b.is_primary) return 1;
	if(a.created_at && b.created_at) {
		return moment(a.created_at).unix() - moment(b.created_at).unix();
	}

	return 0;
}