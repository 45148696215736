import { LinearProgress } from "@mui/material";
import { DTO, Store, DeliveryType, StoreProduct, MatchQueryType, Match, ListingRemovedReason, Customer, Purchase, PurchaseIntent, Product, CustomerProduct } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Form, FormField, TextInput, TextArea, Heading, CheckBox, Button, Tab, Tabs, DataTable, Text, Grid, Anchor } from "grommet";
import { FormClose, FormNext, Refresh, User } from "grommet-icons";
import moment from "moment";
import { useState, Fragment, useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { PartnerService } from "../../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { fetchStore, selectActiveStore } from "../../../../app/store/partners";
import { parseDateFromUTC, parseTimestampFromUTC } from "../../../../helpers";
import { CopyToClipboardField, ElevatedCard, FieldWithActionButton, Loader, LoadingButton, LocalDateField, Modal, Pagination, SlimHeading, useTimezone, useWindowDimensions } from "../../../common";
import { navigateToStorePurchases } from "../../helpers";
import { StoreProductReviewModal, DonationProductCard, StoreProductSummary } from "../store";
import Fuse from "fuse.js";

interface StoreDetailsState {
	isSaving: boolean;
	wasChanged: boolean;
	allowsPickup: boolean;
	allowsDelivery: boolean;
	description: string;
	seller_description: string;
	pickup_instructions: string;
}

interface StoreDetailsProps {
	store: DTO<Store>;
}

export const StoreDetails: React.FC<StoreDetailsProps> = (props) => {
	const dispatch = useAppDispatch();
	const [state, setState] = useState<StoreDetailsState>({
		isSaving: false,
		wasChanged: false,
		allowsPickup: (props.store.delivery_types ?? []).includes(DeliveryType.PICKUP),
		allowsDelivery: (props.store.delivery_types ?? []).includes(DeliveryType.DELIVERY),
		description: props.store.description,
		seller_description: props.store.seller_description,
		pickup_instructions: props.store.pickup_instructions ?? ""
	});

	function handleSaveChanges(): void {
		setState({
			...state,
			isSaving: true
		});

		const deliveryTypes: DeliveryType[] = [];
		if(state.allowsDelivery) {
			deliveryTypes.push(DeliveryType.DELIVERY);
		}

		if(state.allowsPickup) {
			deliveryTypes.push(DeliveryType.PICKUP);
		}

		const updates: Partial<DTO<Store>> = {
			delivery_types: deliveryTypes,
			description: state.description,
			seller_description: state.seller_description,
			pickup_instructions: state.pickup_instructions
		};

		PartnerService.updateStore(props.store.id, updates).then(store => {
			return dispatch(fetchStore(props.store.id));
		}).catch(err => {
			console.error("Failed to update store", err);
		}).finally(() => {
			setState({
				...state,
				isSaving: false,
				wasChanged: false
			});
		});
	}

	return (
		<Box>
			<Form
				value={state}
				onChange={(changes) => {
					setState({
						...state,
						...changes,
						wasChanged: true
					});
				}}
			>
				<FormField
					name="name"
					label="Name"
				>
					<TextInput
						readOnly
						disabled
						value={props.store.name}
					/>
				</FormField>
				{props.store.slug && (
					<FieldWithActionButton
						name="slug"
						label="Slug"
						value={props.store.slug ?? ""}
						icon={<FormNext />}
						onClick={() => {
							window.open(`/s/${props.store.slug}`, "_blank");
						}}
					/>
				)}
				<FormField
					name="description"
					label="Description"
					help="shown to users when donating furniture"
				>
					<TextArea
						name="description"
						rows={3}
					/>
				</FormField>
				{props.store.is_seller && (
					<Fragment>
						<FormField
							name="seller_description"
							label="Seller Description"
							help="shown to users when purchasing furniture"
						>
							<TextArea
								rows={3}
								name="seller_description"
							/>
						</FormField>
						<Heading level="4">Delivery Options</Heading>
						<FormField
							name="allowsDelivery"
							contentProps={{ border: undefined }}
						>
							<CheckBox
								toggle
								name="allowsDelivery"
								label="Allow Delivery"
							/>
						</FormField>
						<FormField
							name="allowsPickup"
							contentProps={{ border: undefined }}
						>
							<CheckBox
								toggle
								name="allowsPickup"
								label="Allow Store Pickup"
							/>
						</FormField>
						{state.allowsPickup && (
							<FormField
								name="pickup_instructions"
								label="Pickup Instructions"
								help="shown to users during checkout"
							>
								<TextArea
									name="pickup_instructions"
									rows={3}
								/>
							</FormField>
						)}
					</Fragment>
				)}
				{state.wasChanged && (
					<Box align="start" margin="small">
						<LoadingButton
							primary
							label="Save Changes"
							isLoading={state.isSaving}
							onClick={handleSaveChanges}
						/>
					</Box>
				)}
			</Form>
		</Box>
	);
};

interface StorePurchasesProps {
	store: DTO<Store>;
	isLoading: boolean;
	purchases: DTO<Purchase>[];
}

export const StorePurchases: React.FC<StorePurchasesProps> = (props) => {
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();

	const columns = useMemo(() => {
		switch(dimensions.size) {
			default: {
				return 2;
			}
		}
	}, [dimensions.size]);

	const purchases = useMemo(() => props.purchases, [props.purchases]);

	const awaitingPickup = useMemo(() => {
		return purchases.filter(purchase => {
			return purchase.intents.some(i => i.delivery_type === DeliveryType.PICKUP && !i.delivered);
		});
	}, [purchases]);

	const awaitingDelivery = useMemo(() => {
		return purchases.filter(purchase => {
			return purchase.intents.some(i => i.delivery_type === DeliveryType.DELIVERY && !i.delivered);
		});
	}, [purchases]);

	function handleNavigateToStorePurchases(type?: string): void {
		navigateToStorePurchases(props.store.id, type ?? "", dispatch);
	}

	return (
		<Box flex gap="small">
			{props.isLoading && (
				<LinearProgress />
			)}
			<Grid columns={{ count: columns, size: "auto" }} gap="medium">
				<ElevatedCard cardProps={{
					hoverIndicator: true,
					onClick: () => {
						handleNavigateToStorePurchases("pickup");
					}
				}}>
					<Box flex align="center" justify="center" pad="medium">
						<Text size="large" weight="bold">{awaitingPickup.length}</Text>
						<Text textAlign="center">Awaiting Pickup</Text>
					</Box>
				</ElevatedCard>
				<ElevatedCard cardProps={{
					hoverIndicator: true,
					onClick: () => {
						handleNavigateToStorePurchases("delivery");
					}
				}}>
					<Box flex align="center" justify="center" pad="medium">
						<Text size="large" weight="bold">{awaitingDelivery.length}</Text>
						<Text textAlign="center">Awaiting Delivery</Text>
					</Box>
				</ElevatedCard>
				<ElevatedCard cardProps={{
					hoverIndicator: true,
					onClick: () => {
						handleNavigateToStorePurchases();
					}
				}}>
					<Box flex align="center" justify="center" pad="medium">
						<Text size="large" weight="bold">{purchases.length}</Text>
						<Text textAlign="center">Total Purchases</Text>
					</Box>
				</ElevatedCard>
			</Grid>
		</Box>
	);
};


interface StoreProductsProps {
	store: DTO<Store>;
}

export const StoreProducts: React.FC<StoreProductsProps> = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [products, setProducts] = useState<DTO<StoreProduct>[]>([]);
	const [searchQuery, setSearchQuery] = useState("");
	const { size } = useWindowDimensions();

	const fuseOptions = {
		keys: [
			"name",
			"title",
			"item.name",
			"item_input"
		],
		threshold: 0.4,
		ignoreLocation: true,
		ignoreFieldNorm: true
	};

	const renderItems = useCallback((items: DTO<StoreProduct>[]) => {
		return (
			<Box flex>
				{items.map(product => (
					<StoreProductSummary
						key={product.id}
						product={product}
						store={props.store}
						onClick={() => {
							window.open(`/partner/store/${props.store.id}/products/${product.id}`, "_blank");
						}}
					/>
				))}
			</Box>
		);
	}, [props.store]);

	const productsNotSubmitted = useMemo(() => {
		const filtered = products.filter(p => !p.submitted);
		if(searchQuery) {
			const fuse = new Fuse(filtered, fuseOptions);
			return fuse.search(searchQuery).map(r => r.item);
		}

		return filtered;
	}, [products, searchQuery]);

	const productsNotVisible = useMemo(() => {
		const filtered = products.filter(p => p.submitted && !p.visible);
		if(searchQuery) {
			const fuse = new Fuse(filtered, fuseOptions);
			return fuse.search(searchQuery).map(r => r.item);
		}

		return filtered;
	}, [products, searchQuery]);

	const productsForSale = useMemo(() => {
		const filtered = products.filter(p => {
			return p.inventory?.quantity > 0 && p.submitted && p.visible;
		});

		if(searchQuery) {
			const fuse = new Fuse(filtered, fuseOptions);
			return fuse.search(searchQuery).map(r => r.item);
		}

		return filtered;
	}, [products, searchQuery]);

	const productsSoldOut = useMemo(() => {
		const filtered = products.filter(p => (p.inventory.quantity <= 0 || (p.removed && p.removed_reason === ListingRemovedReason.OUT_OF_STOCK)) && p.submitted);
		if(searchQuery) {
			const fuse = new Fuse(filtered, fuseOptions);
			return fuse.search(searchQuery).map(r => r.item);
		}

		return filtered;
	}, [products, searchQuery]);

	function loadStoreProducts(): void {
		setIsLoading(true);
		PartnerService.listStoreProducts(props.store.id, {}).then(res => {
			setProducts([...res]);
		}).catch(err => {
			console.error("failed loading store products", err);
		}).finally(() => {
			setIsLoading(false);
		});
	}

	useEffect(() => {
		loadStoreProducts();
	}, [props.store]);

	const pageSize = useMemo(() => {
		return size === "small" ? 4 : 6;
	}, [size]);

	return (
		<Loader visible={isLoading}>
			<Box flex id="store-products" gap="small">
				<Grid margin="small" columns={["flex", "auto"]} align="center">
					<FormField
						name="search"
						margin="none"
						onChange={(event) => {
							setSearchQuery(event.target.value);
						}}
					>
						<Box direction="row" flex style={{ maxHeight: "44px" }}>
							<TextInput
								plain
								name="search"
								placeholder="search..."
								style={{ height: "100%" }}
								value={searchQuery ?? undefined}
							/>
							<Button
								icon={<FormClose />}
								hoverIndicator
								onClick={() => setSearchQuery("")}
							/>
						</Box>
					</FormField>
					<Button
						hoverIndicator
						onClick={() => loadStoreProducts()}
						icon={<Refresh />}
					/>
				</Grid>
				<Tabs flex className="rego-tab">
					<Tab
						style={{ display: "flex" }}
						title={`Not Submitted (${productsNotSubmitted.length})`}
					>
						<Pagination
							pageSize={pageSize}
							items={productsNotSubmitted}
							renderItems={renderItems}
						/>
					</Tab>
					<Tab
						style={{ display: "flex" }}
						title={`Not Visible (${productsNotVisible.length})`}
					>
						<Pagination
							pageSize={pageSize}
							items={productsNotVisible}
							renderItems={renderItems}
						/>
					</Tab>
					<Tab
						style={{ display: "flex" }}
						title={`For Sale (${productsForSale.length})`}
					>
						<Pagination
							pageSize={pageSize}
							items={productsForSale}
							renderItems={renderItems}
						/>
					</Tab>
					<Tab
						style={{ display: "flex" }}
						title={`Out of Stock (${productsSoldOut.length})`}
					>
						<Pagination
							pageSize={pageSize}
							items={productsSoldOut}
							renderItems={renderItems}
						/>
					</Tab>
				</Tabs>
			</Box>

		</Loader>
	);
};

interface StoreDonationProductsProps {
	store: DTO<Store>;
}

interface StoreDonationProductsState {
	wasLoaded: boolean;
	isDetailsModalOpen: boolean;
	selectedProduct: DTO<CustomerProduct> | null;
	pendingProducts: DTO<CustomerProduct>[];
	isLoadingScheduledProducts: boolean;
	loadedScheduledProducts: boolean;
	scheduledProducts: DTO<CustomerProduct>[];
	isLoadingCompletedProducts: boolean;
	loadedCompletedProducts: boolean;
	completedProducts: DTO<CustomerProduct>[];
}

export const StoreDonationProducts: React.FC<StoreDonationProductsProps> = (props) => {

	const [state, setState] = useState<StoreDonationProductsState>({
		wasLoaded: false,
		isLoadingCompletedProducts: false,
		loadedCompletedProducts: false,
		pendingProducts: [],
		completedProducts: [],
		isLoadingScheduledProducts: false,
		loadedScheduledProducts: false,
		scheduledProducts: [],
		isDetailsModalOpen: false,
		selectedProduct: null
	});

	function updateState(updates: Partial<StoreDonationProductsState>): void {
		setState((old) => {
			return {
				...old,
				...updates
			};
		});
	}

	useEffect(() => {
		if(!state.wasLoaded && state.loadedScheduledProducts && state.loadedCompletedProducts) {
			updateState({ wasLoaded: true });
		}
	}, [state.wasLoaded, state.loadedScheduledProducts, state.loadedCompletedProducts]);

	function fetchScheduledDonationProducts(): void {
		updateState({ isLoadingScheduledProducts: true });
		PartnerService.fetchScheduledDonationProducts(props.store.id).then(products => {
			updateState({
				scheduledProducts: [...products],
				loadedScheduledProducts: true
			});
		}).catch(err => {
			console.error("failed to load scheduled donation products", err);
		}).finally(() => {
			updateState({ isLoadingScheduledProducts: false });
		});
	}

	function fetchCompletedDonationProducts(): void {
		updateState({ isLoadingScheduledProducts: true });
		PartnerService.fetchCompletedDonationProducts(props.store.id).then(products => {
			updateState({
				completedProducts: [...products],
				pendingProducts: [...products].filter(p => !p.disposition),
				loadedCompletedProducts: true
			});
		}).catch(err => {
			console.error("failed to load completed donation products", err);
		}).finally(() => {
			updateState({ isLoadingCompletedProducts: false });
		});
	}

	function fetchProducts(): Promise<void> {
		return Promise.all([
			fetchScheduledDonationProducts(),
			fetchCompletedDonationProducts()
		]).then(() => { });
	}

	useEffect(() => {
		fetchProducts();
	}, [props.store, props.store.id]);

	const renderItems = useCallback((items: DTO<CustomerProduct>[]) => {
		return (
			<Box gap="small" margin="small" flex>
				{items.map(product => (
					<DonationProductCard
						store={props.store}
						product={product}
						onClick={(product) => {
							updateState({ selectedProduct: product, isDetailsModalOpen: true });
						}}
					/>
				))}
			</Box>
		);
	}, [props.store]);

	const [isConfirming, setIsConfirming] = useState(false);
	function handleConfirmDonationProduct(product: DTO<Product>): Promise<void> {
		setIsConfirming(true);
		return PartnerService.confirmDonationProduct(props.store.id, product.id)
			.then(() => {
				updateState({ selectedProduct: null });
				return fetchCompletedDonationProducts();
			}).finally(() => {
				setIsConfirming(false);
			});
	}

	const [isRejecting, setIsRejecting] = useState(false);
	function handleRejectDonationProduct(product: DTO<Product>): Promise<void> {
		setIsRejecting(true);
		return PartnerService.rejectDonationProduct(props.store.id, product.id)
			.then(() => {
				updateState({ selectedProduct: null });
				return fetchCompletedDonationProducts();
			}).finally(() => {
				setIsRejecting(false);
			});
	}

	useEffect(() => {
		console.log(state.selectedProduct);
	}, [state.selectedProduct]);

	return (
		<Loader visible={!state.wasLoaded}>
			{(state.isDetailsModalOpen && state.selectedProduct) && (
				<StoreProductReviewModal
					hasDecision={!state.selectedProduct.disposition && state.selectedProduct?.service?.completed}
					isRejecting={isRejecting}
					isConfirming={isConfirming}
					product={state.selectedProduct}
					onClose={() => {
						updateState({ isDetailsModalOpen: false });
					}}
					onReject={handleRejectDonationProduct}
					onConfirm={handleConfirmDonationProduct}
				/>
			)}
			<Box flex>
				<Tabs flex className="rego-tab">
					<Tab
						style={{ display: "flex", outline: "red", border: "red" }}
						title={`On the Way (${state.scheduledProducts.length})`}
					>
						<Pagination
							items={state.scheduledProducts}
							renderItems={renderItems}
							pageSize={4}
						/>
					</Tab>
					<Tab
						style={{ display: "flex" }}
						title={`Completed (${state.completedProducts.length})`}
					>
						<Pagination
							items={state.completedProducts}
							renderItems={renderItems}
							pageSize={4}
						/>
					</Tab>
					<Tab
						style={{ display: "flex" }}
						title={`Action Items (${state.pendingProducts.length})`}
					>
						<Pagination
							items={state.pendingProducts}
							renderItems={renderItems}
							pageSize={4}
						/>
					</Tab>
				</Tabs>
			</Box>
		</Loader>
	);
};


interface StoreUsersProps {
	store: DTO<Store>;
}

interface StoreUsersState {
	wasLoaded: boolean;
	users: DTO<Customer>[];
}

export const StoreUsers: React.FC<StoreUsersProps> = (props) => {

	const [state, setState] = useState<StoreUsersState>({
		wasLoaded: false,
		users: []
	});

	function updateState(updates: Partial<StoreUsersState>): void {
		setState((old) => {
			return {
				...old,
				...updates
			};
		});
	}

	function handleFetchUsers(): void {
		PartnerService.listStoreUsers(props.store.id).then(users => {
			updateState({ users });
		}).catch(err => {
			console.error("Failed to load users", err);
		}).finally(() => {
			updateState({ wasLoaded: true });
		});
	}

	useEffect(() => {
		handleFetchUsers();
	}, [props.store]);

	return (
		<Box flex>
			{!state.wasLoaded && (
				<LinearProgress />
			)}
			<DataTable
				data={state.users}
				paginate
				step={10}
				columns={[
					{
						property: "first_name",
						header: "First Name",
						primary: false,
						render: (user) => user.first_name
					},
					{
						property: "last_name",
						header: "Last Name",
						primary: false,
						render: (user) => user.last_name
					},
					{
						property: "email_address",
						header: "Email",
						primary: true,
						render: (user) => user.email_address
					}
				]}
			/>
		</Box>
	);
};


enum PurchaseDetailType {
	ALL,
	AWAITING_PICKUP,
	AWAITING_DELIVERY,
	COMPLETE
}

interface PurchaseDetailsProps {
	purchases: DTO<Purchase>[];
	onConfirmPickedUp(purchase: DTO<Purchase>, intent: DTO<PurchaseIntent>): Promise<void>;
}

export const PurchaseDetails: React.FC<PurchaseDetailsProps> = (props) => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [index, setIndex] = useState(0);
	const store = useAppSelector(selectActiveStore);

	function updateActiveIndex(index: number): void {
		setIndex(index);
		if(!store) {
			return;
		}

		if(index === PurchaseDetailType.AWAITING_PICKUP) {
			navigateToStorePurchases(store.id, "pickup", dispatch);
			return;
		}

		if(index === PurchaseDetailType.AWAITING_DELIVERY) {
			navigateToStorePurchases(store.id, "delivery", dispatch);
			return;
		}

		if(index === PurchaseDetailType.COMPLETE) {
			navigateToStorePurchases(store.id, "complete", dispatch);
			return;
		}

		navigateToStorePurchases(store.id, "", dispatch);
		return;
	}

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const active = query.get("active");

		if(!active) {
			setIndex(PurchaseDetailType.ALL);
			return;
		}

		switch(active) {
			case "pickup": {
				setIndex(PurchaseDetailType.AWAITING_PICKUP);
				break;
			}
			case "delivery": {
				setIndex(PurchaseDetailType.AWAITING_DELIVERY);
				break;
			}
			default: {
				setIndex(PurchaseDetailType.ALL);
				break;
			}
		}
	}, [location.search]);

	const awaitingPickup = useMemo(() => {
		return props.purchases.filter(p => p.intents.some(i => i.delivery_type === DeliveryType.PICKUP && !i.delivered));
	}, [props.purchases]);

	const awaitingDelivery = useMemo(() => {
		return props.purchases.filter(p => p.intents.some(i => i.delivery_type === DeliveryType.DELIVERY && !i.delivered));
	}, [props.purchases]);

	const completed = useMemo(() => {
		return props.purchases.filter(p => p.intents.every(i => i.delivered));
	}, [props.purchases]);

	return (
		<Box>
			<Tabs activeIndex={index} onActive={updateActiveIndex} className="rego-tab">
				<Tab title="All Purchases">
					<PurchaseDetailsPagination
						purchases={props.purchases}
						onConfirmPickedUp={props.onConfirmPickedUp}
					/>
				</Tab>
				<Tab title="Awaiting Pickup">
					<PurchaseDetailsPagination
						purchases={awaitingPickup}
						onConfirmPickedUp={props.onConfirmPickedUp}
					/>
				</Tab>
				<Tab title="Awaiting Delivery">
					<PurchaseDetailsPagination
						purchases={awaitingDelivery}
						onConfirmPickedUp={props.onConfirmPickedUp}
					/>
				</Tab>
				<Tab title="Completed">
					<PurchaseDetailsPagination
						purchases={completed}
						onConfirmPickedUp={props.onConfirmPickedUp}
					/>
				</Tab>
			</Tabs>
		</Box>
	);
};

interface PurchaseDetailsPaginationProps {
	purchases: DTO<Purchase>[];
	onConfirmPickedUp(purchase: DTO<Purchase>, intent: DTO<PurchaseIntent>): Promise<void>;
}

export const PurchaseDetailsPagination: React.FC<PurchaseDetailsPaginationProps> = (props) => {
	const dimensions = useWindowDimensions();
	const [columns, setColumns] = useState(2);

	useEffect(() => {
		switch(dimensions.size) {
			case "small": {
				setColumns(1);
				break;
			}
			case "medium": {
				setColumns(1);
				break;
			}
			default: {
				setColumns(2);
				break;
			}
		}
	}, [dimensions.size]);

	const render = useCallback((purchases: DTO<Purchase>[]) => (
		<Grid columns={{ count: columns, size: "auto" }} gap="small">
			{purchases.map(purchase => (
				<PurchaseDetailsCard
					key={purchase.id}
					purchase={purchase}
				/>
			))}
		</Grid>
	), [props.purchases, columns, dimensions.size]);

	return (
		<Box flex margin={{ top: "small" }}>
			<Pagination
				items={props.purchases}
				renderItems={render}
				pageSize={4}
			/>
		</Box>
	);
};

interface PurchaseDetailsCardProps {
	purchase: DTO<Purchase>;
	hideViewMore?: boolean;
	header?: JSX.Element;
}

export const PurchaseDetailsCard: React.FC<PurchaseDetailsCardProps> = (props) => {
	const dispatch = useAppDispatch();
	const store = useAppSelector(selectActiveStore);
	const [isLoading, setIsLoading] = useState(false);
	const [purchase, setPurchase] = useState(props.purchase);
	const [isViewingCustomer, setIsViewingCustomer] = useState(false);

	useEffect(() => {
		if(!store) return;
		setIsLoading(true);
		PartnerService.getStorePurchase(store.id, props.purchase.id, ["customer"]).then(res => {
			setPurchase({ ...res, intents: props.purchase.intents });
		}).catch(err => {
			console.error("Failed to hydrate purchase record", err);
		}).finally(() => {
			setIsLoading(false);
		});
	}, [props.purchase]);

	function handleNavigate(): void {
		dispatch(push(`/partner/store/${store?.id}/purchases/${purchase?.id}`));
	}

	return (
		<ElevatedCard header={props.header}>
			{(isViewingCustomer && purchase.customer) && (
				<CustomerDetailsModal
					onClose={() => setIsViewingCustomer(false)}
					customer={purchase.customer}
				/>
			)}
			<Box flex gap="small">
				{isLoading && (
					<LinearProgress />
				)}
				<FormField
					label="Purchase Number"
				>
					<TextInput
						value={purchase.number}
						disabled
					/>
				</FormField>
				<LocalDateField
					label="Purchase Date"
					value={purchase.paid_at}
				/>
				{purchase.customer && (
					<FieldWithActionButton
						name="customer"
						label="Customer"
						value={Customer.create({ ...purchase.customer }).getFullName()}
						icon={<User />}
						onClick={() => setIsViewingCustomer(true)}
					/>
				)}
				{!props.hideViewMore && (
					<Box flex align="end" justify="end">
						<Button
							primary
							label="View More ->"
							onClick={handleNavigate}
						/>
					</Box>
				)}
				{/* <PurchasedItemsContainer
					purchase={purchase}
					intents={purchase.intents}
					onConfirmPickedUp={props.onConfirmPickedUp}
				/> */}
			</Box>
		</ElevatedCard>
	);
};

interface CustomerDetailsModalProps {
	onClose(): void;
	customer: DTO<Customer>;
}

export const CustomerDetailsModal: React.FC<CustomerDetailsModalProps> = (props) => {
	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box>
				<FormField
					label="First Name"
				>
					<TextInput
						value={props.customer.first_name}
						disabled
					/>
				</FormField>
				<FormField
					label="Last Name"
				>
					<TextInput
						value={props.customer.last_name}
						disabled
					/>
				</FormField>
				<CopyToClipboardField
					label="Email Address"
					name="email"
					value={props.customer.email_address}
				/>
				<CopyToClipboardField
					label="Phone Number"
					name="phone"
					value={props.customer.phone_number}
				/>
				<Box flex align="end" justify="end">
					<Button
						primary
						label="Close"
						onClick={props.onClose}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

interface PurchasedItemsContainerProps {
	purchase: DTO<Purchase>;
	intents: DTO<PurchaseIntent>[];
	onConfirmPickedUp(intent: DTO<PurchaseIntent>): Promise<void>;
}

export const PurchasedItemsContainer: React.FC<PurchasedItemsContainerProps> = (props) => {
	const { size } = useWindowDimensions();

	const columns = useMemo(() => {
		switch(size) {
			case "small":
			case "medium": {
				return 1;
			}
			default: {
				return 2;
			}
		}
	}, [size]);

	const render = useCallback((items: DTO<PurchaseIntent>[]) => {
		return (
			<Grid gap="small" columns={{ count: columns, size: "auto" }}>
				{props.intents.map(intent => (
					<PurchasedItemCard
						key={intent.id}
						intent={intent}
						onConfirmPickedUp={props.onConfirmPickedUp}
					/>
				))}
			</Grid>
		);
	}, [props.purchase, columns]);

	return (
		<Pagination
			items={props.intents}
			renderItems={render}
			pageSize={8}
		/>
	);
};

interface PurchasedItemCardProps {
	intent: DTO<PurchaseIntent>;
	onConfirmPickedUp(intent: DTO<PurchaseIntent>): Promise<void>;
}

export const PurchasedItemCard: React.FC<PurchasedItemCardProps> = (props) => {
	const store = useAppSelector(selectActiveStore);
	const { size } = useWindowDimensions();

	const isFullWidth = useMemo(() => {
		return ["medium", "small"].includes(size);
	}, [size]);

	return (
		<ElevatedCard>
			<Grid columns={isFullWidth ? "100%" : ["flex", "small"]} gap="small">
				<Box gap="small">
					<Anchor
						target="_blank"
						href={`/partner/store/${store?.id}/products/${props.intent.product.id}`}
						label={props.intent.product.description}
					/>
					{!isFullWidth && (
						<Fragment>
							<Text>Delivery Type: <Text weight="bold">{props.intent.delivery_type}</Text></Text>
							<Text>Quantity: <Text weight="bold">{props.intent.quantity}</Text></Text>
						</Fragment>
					)}
				</Box>
				<Box direction={isFullWidth ? "row" : "column"} gap="small">
					{isFullWidth && (
						<Box gap="small">
							<Text>Delivery Type: <Text weight="bold">{props.intent.delivery_type}</Text></Text>
							<Text>Quantity: <Text weight="bold">{props.intent.quantity}</Text></Text>
						</Box>
					)}
					<PurchasedItemContent
						intent={props.intent}
						onConfirmPickedUp={props.onConfirmPickedUp}
					/>
				</Box>
			</Grid>
		</ElevatedCard>
	);
};

interface PurchasedItemContentProps {
	intent: DTO<PurchaseIntent>;
	onConfirmPickedUp(intent: DTO<PurchaseIntent>): Promise<void>;
}

export const PurchasedItemContent: React.FC<PurchasedItemContentProps> = (props) => {
	const timezone = useTimezone();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const deliveredMessage = useMemo(() => {
		const intent = props.intent;
		const message = (props.intent.delivery_type === DeliveryType.PICKUP)
			? "picked up by"
			: "delivered to";

		return `This item was ${message} the customer on ${parseDateFromUTC(intent.delivered_at, timezone)}`;
	}, [props.intent]);

	function handleConfirmPickup(): void {
		setIsSubmitting(true);
		props.onConfirmPickedUp(props.intent).finally(() => {
			setIsSubmitting(false);
		});
	}

	if(props.intent.delivered) {
		return (
			<Text>{deliveredMessage}</Text>
		);
	}

	if(!props.intent.delivered && props.intent.delivery_type === DeliveryType.PICKUP) {
		return (
			<Box gap="small">
				<Text weight="bold">Has this item been pickup up yet?</Text>
				<LoadingButton
					label="Confirm Pickup"
					primary
					isLoading={isSubmitting}
					onClick={handleConfirmPickup}
				/>
			</Box>
		);
	}

	if(!props.intent.delivered && props.intent.delivery_type === DeliveryType.DELIVERY) {
		return (
			<Box>
				<Text>This item is awaiting delivery.</Text>
			</Box>
		);
	}

	return <></>;
};