import React from "react";
import { Route, Routes } from "react-router-dom";
import { ErrorPage } from "../../common";
import { StoreController } from "../controller";
import { CreateStoreProductPage, StoreDashboard, StoreProductsPage, StorePurchasePage, StorePurchasesPage, UpdateStoreProductPage } from "../pages";

export const PartnerRoutes: React.FC = (props) => {
	return (
		<Routes>
			<Route path="store/:storeId" element={<StoreController />}>
				<Route path="dashboard" element={<StoreDashboard />} />
				<Route path="purchases" element={<StorePurchasesPage />} />
				<Route path="purchases/:purchaseId" element={<StorePurchasePage />} />
				<Route path="products" element={<StoreProductsPage />} />
				<Route path="products/create" element={<CreateStoreProductPage />} />
				<Route path="products/:productId" element={<UpdateStoreProductPage />} />
			</Route>
			<Route element={
				<ErrorPage errorCode={404} />
			} />
		</Routes>
	);
};