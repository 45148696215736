import React, { useEffect, useRef, useState } from "react";
import { DTO, Product } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, Grid, Select, Spinner, Text } from "grommet";
import { Cart } from "grommet-icons";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCurrentMarket } from "../../../app/store/purchase";
import { SlimHeading, useStructure, useWindowDimensions } from "../../common";
import { DefaultProductAction, MobileProductCard, ProductFilters, ProductGallery } from "../components";
import VisibilitySensor from "react-visibility-sensor";
import "./scroll.css";
import { selectMarkets } from "../../../app/store/reference";
import { setForceHideFooter } from "../../../app/store/application";

interface ShopProps {
	header?: JSX.Element;
	productStep: number;
	hasNextPage: boolean;
	fetchNextProducts(): void;
	products: DTO<Product>[];
	isLoadingProducts: boolean;
}

interface DesktopShopProps extends ShopProps {

}

export const DesktopShopPage: React.FC<DesktopShopProps> = (props) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const markets = useAppSelector(selectMarkets);
	const currentMarket = useAppSelector(selectCurrentMarket);

	const defaultHeader = useMemo(() => {
		return (
			<Box margin="medium" direction={size === "small" ? "column" : "row"} justify="between" gap={size === "small" ? "small" : undefined}>
				<Box justify="center">
					<Text weight="bolder" size="large">
						Items for sale in {currentMarket?.short_name}
					</Text>
				</Box>
				<Box align="center" direction="row" gap="medium">
					<Button
						primary
						label="View Cart"
						icon={<Cart />}
						onClick={() => dispatch(push("/shop/cart"))}
					/>
					<Select
						options={markets.map(m => m.short_name)}
						value={currentMarket?.short_name}
						onChange={(event) => {
							const market = markets.find(m => m.short_name === event.target.value);
							if(market) {
								dispatch(push(`/shop/${market.code}`));
							}
						}}
					/>
				</Box>
			</Box>
		);
	}, [currentMarket, size]);

	return (
		<Box margin="medium" gap="medium">
			{props.header ?? defaultHeader}
			{/* <Grid columns={["small", "flex"]} gap="small">
				<Box>
					<ProductFilters />
				</Box>
				{props.products.length
					? (
						<ProductGallery
							step={props.productStep}
							products={props.products}
							hasNextPage={props.hasNextPage}
							fetchNextProducts={props.fetchNextProducts}
							endOfGalleryMessage={"That's all for now"}
						/>
					)
					: (props.isLoadingProducts)
						? (
							<Box align="center" justify="center">
								<Spinner size="large" />
							</Box>
						)
						: (
							<Box align="center" justify="center">
								<SlimHeading level="2">nothing for sale yet</SlimHeading>
							</Box>
						)
				}
			</Grid> */}
			{props.products.length
				? (
					<ProductGallery
						step={props.productStep}
						products={props.products}
						hasNextPage={props.hasNextPage}
						fetchNextProducts={props.fetchNextProducts}
						endOfGalleryMessage={"That's all for now"}
					/>
				)
				: (props.isLoadingProducts)
					? (
						<Box align="center" justify="center">
							<Spinner size="large" />
						</Box>
					)
					: (
						<Box align="center" justify="center">
							<SlimHeading level="2">nothing for sale yet</SlimHeading>
						</Box>
					)
			}
		</Box>
	);
};

export const MobileShopPage: React.FC<ShopProps> = (props) => {
	const dispatch = useAppDispatch();
	const structure = useStructure();
	const dimensions = useWindowDimensions();

	const containerHeight = useMemo(() => {
		const header = document.getElementById("store-header");
		if(header) {
			header.scrollIntoView(true);
		}

		return dimensions.height - 100;
	}, [structure.headerHeight, props.header]);

	useEffect(() => {
		dispatch(setForceHideFooter(true));

		return () => {
			dispatch(setForceHideFooter(false));
		};
	}, []);

	return (
		<Box>
			{props.header}
			<ProductScrollContainer
				containerHeight={containerHeight}
				products={props.products}
				isLoadingProducts={props.isLoadingProducts}
				fetchNextProducts={props.fetchNextProducts}
			/>
		</Box>
	);
};


interface ProductScrollContainerProps {
	fetchNextProducts(): void;
	containerHeight: number;
	products: DTO<Product>[];
	isLoadingProducts: boolean;
}

export const ProductScrollContainer: React.FC<ProductScrollContainerProps> = (props) => {
	const dimensions = useWindowDimensions();
	const elementId = "product-scroll-container" as const;
	const [activeProduct, setActiveProduct] = useState<DTO<Product> | null>(null);

	useEffect(() => {
		const element = document.getElementById(elementId);
		if(element) {
			element.scrollIntoView(false);
		}
	}, [dimensions.height, dimensions.width]);

	function handleActiveProductChange(product: DTO<Product>): void {
		setActiveProduct({ ...product });
	}

	function isLast(product: DTO<Product>): boolean {
		const index = props.products.findIndex(m => m.id === product.id);
		if(index === -1) {
			return false;
		}

		return index === (props.products.length - 1);
	}

	function isActive(product: DTO<Product>): boolean {
		const index = props.products.findIndex(m => m.id === product.id);
		if(index === -1) {
			return false;
		}

		if(activeProduct) {
			return activeProduct.id === product.id;
		}

		return index === 0;
	}

	function isNext(item: DTO<Product>): boolean {
		const index = props.products.findIndex(m => m.id === item.id);
		if(index === -1) {
			return false;
		}

		if(activeProduct) {
			const activeIndex = props.products.findIndex(m => m.id === activeProduct.id);
			return activeIndex + 1 === index;
		}

		//no active media (should be second item)
		return index === 1;
	}

	function isPrevious(item: DTO<Product>): boolean {
		const index = props.products.findIndex(m => m.id === item.id);
		if(index === -1) {
			return false;
		}

		if(activeProduct) {
			const activeIndex = props.products.findIndex(m => m.id === activeProduct.id);
			return activeIndex - 1 === index;
		}

		//no active media ... false
		return false;
	}

	useEffect(() => {
		//fetch more on next 2 products
		if(activeProduct) {
			const index = props.products.findIndex(m => m.id === activeProduct.id);

			if(index === -1) {
				return;
			}

			if(index >= (props.products.length - 2)) {
				props.fetchNextProducts();
			}
		}

	}, [activeProduct, props.products]);

	function handleScroll(): void {
		const element = document.getElementById(elementId);
		if(element) {
			element.scrollIntoView(false);
		}
	}

	return (
		<div
			id={elementId}
			className="example-wrapper" style={{ width: "100%" }}
		>
			<div
				className="container y-mandatory both-scroll both-mandatory align-start"
				style={{ height: props.containerHeight }}
				onScroll={handleScroll}
			>
				{props.products.length > 0
					? (
						<Box className="wrapper">
							{props.products.map(product => (
								<ProductScrollElement
									key={product.id}
									height={props.containerHeight}
									product={product}
									reportActiveProduct={handleActiveProductChange}
									isActive={isActive(product)}
									isNext={isNext(product)}
									isPrevious={isPrevious(product)}
								/>
							))}
						</Box>
					)
					: (props.isLoadingProducts)
						? (
							<Box align="center" justify="center" margin="medium">
								<Spinner size="large" />
							</Box>
						)
						: (
							<Box align="center" justify="center" margin="medium">
								<SlimHeading level="2">nothing for sale yet</SlimHeading>
							</Box>
						)
				}
			</div>
		</div>
	);
};

interface ProductScrollElementProps {
	isActive: boolean;
	isNext: boolean;
	isPrevious: boolean;
	reportActiveProduct(product: DTO<Product>): void;
	product: DTO<Product>;
	height: number;
}

export const ProductScrollElement: React.FC<ProductScrollElementProps> = (props) => {
	function handleReportActiveProduct(isVisible: boolean): void {
		if(isVisible) {
			props.reportActiveProduct(props.product);
		}
	}

	return (
		<VisibilitySensor
			scrollCheck
			partialVisibility={false}
			onChange={handleReportActiveProduct}
		>
			<div className="element" style={{ height: props.height }}>
				<MobileProductCard
					shouldLoad={props.isActive || props.isNext || props.isPrevious}
					product={props.product}
				>
					<DefaultProductAction
						product={props.product}
					/>
				</MobileProductCard>
			</div>
		</VisibilitySensor>
	);
};