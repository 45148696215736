import { ProductIntent } from "@rego-app/common";
import { push } from "connected-react-router";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { AppDispatch } from "../../../app/store";
import { AdminGuard } from "../../auth";
import { ErrorPage } from "../../common";
import { AdminDashboardController } from "../controller";
import { AdminHome, AdminPurchasesPage, AdminStorePage, CreateServiceScreen, CustomerDetailsScreen, CustomerScreen, PurchaseDetailsPage, OrderDetailsPage, CreatePurchaseDeliveryPage, AdminOrdersPage, AdminPaymentDetailsScreen, AdminServicesPage, AdminServiceDetailsScreen, AdminProductDetailsPage, AdminListingsPage, AdminDeliveriesPage, AdminDeliveryDetails, AdminProductsPage, AdminStoreDetailsPage, AdminPaymentsPage } from "../pages";

export const AdminRouter: React.FC = (props) => {
	return (
		<AdminGuard>
			<Routes>
				<Route path="/dashboard" element={<AdminDashboardController />}>
					<Route path="" element={<AdminHome />} />
					<Route path="customers" element={<CustomerScreen />} />
					<Route path="customers/:customerId" element={<CustomerDetailsScreen />} />
					<Route path="orders" element={<AdminOrdersPage />} />
					<Route path="orders/:orderId" element={<OrderDetailsPage />} />
					<Route path="orders/:orderId/donation" element={<CreateServiceScreen intent={ProductIntent.DONATE} />} />
					<Route path="orders/:orderId/removal" element={<CreateServiceScreen intent={ProductIntent.JUNK} />} />
					<Route path="donations" element={<AdminServicesPage intent={ProductIntent.DONATE} />} />
					<Route path="donations/:serviceId" element={<AdminServiceDetailsScreen intent={ProductIntent.DONATE} />} />
					<Route path="removals" element={<AdminServicesPage intent={ProductIntent.JUNK} />} />
					<Route path="removals/:serviceId" element={<AdminServiceDetailsScreen intent={ProductIntent.JUNK} />} />
					<Route path="payments" element={<AdminPaymentsPage />} />
					<Route path="payments/:paymentId" element={<AdminPaymentDetailsScreen />} />
					<Route path="products" element={<AdminProductsPage />} />
					<Route path="products/:productId" element={<AdminProductDetailsPage />} />
					<Route path="purchases" element={<AdminPurchasesPage />} />
					<Route path="purchases/:purchaseId" element={<PurchaseDetailsPage />} />
					<Route path="purchases/:purchaseId/delivery" element={<CreatePurchaseDeliveryPage />} />
					<Route path="listings" element={<AdminListingsPage />} />
					<Route path="deliveries" element={<AdminDeliveriesPage />} />
					<Route path="deliveries/:deliveryId" element={<AdminDeliveryDetails />} />
					<Route path="stores" element={<AdminStorePage />} />
					<Route path="stores/:storeId" element={<AdminStoreDetailsPage />} />
				</Route>
				<Route element={
					<ErrorPage errorCode={404} />
				} />
			</Routes>
		</AdminGuard>
	);
};

const ADMIN_URL_BASE = "/admin/dashboard" as const;

export function adminNavigate(pathWithLeadingSlash: string, dispatch: AppDispatch): void {
	dispatch(push(ADMIN_URL_BASE + pathWithLeadingSlash));
}

export function navigateToCustomer(customerId: string, dispatch: AppDispatch): void {
	adminNavigate(`/customers/${customerId}`, dispatch);
}

export function navigateToDelivery(deliveryId: string, dispatch: AppDispatch): void {
	adminNavigate(`/deliveries/${deliveryId}`, dispatch);
}

export function navigateToDonation(donationId: string, dispatch: AppDispatch): void {
	adminNavigate(`/donations/${donationId}`, dispatch);
}

export function navigateToOrder(orderId: string, dispatch: AppDispatch): void {
	adminNavigate(`/orders/${orderId}`, dispatch);
}

export function navigateToPayment(paymentId: string, dispatch: AppDispatch): void {
	adminNavigate(`/payments/${paymentId}`, dispatch);
}

export function navigateToProduct(productId: string, dispatch: AppDispatch): void {
	adminNavigate(`/products/${productId}`, dispatch);
}

export function navigateToPurchase(purchaseId: string, dispatch: AppDispatch): void {
	adminNavigate(`/purchases/${purchaseId}`, dispatch);
}

export function navigateToRemoval(removalId: string, dispatch: AppDispatch): void {
	adminNavigate(`/removals/${removalId}`, dispatch);
}

export function navigateToStore(storeId: string, dispatch: AppDispatch): void {
	adminNavigate(`/stores/${storeId}`, dispatch);
}
