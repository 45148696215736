import { configureStore } from "@reduxjs/toolkit";
import applicationReducer from "./application";
import adminReducer from "./admin";
import checkoutReducer from "./checkout";
import dashboardReducer from "./dashboard";
import orderReducer from "./order";
import referenceReducer from "./reference";
import partnerReducer from "./partners";
import purchaseReducer from "./purchase";
import shopReducer from "./shop";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";

const {
	createReduxHistory,
	routerMiddleware,
	routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
	reducer: {
		application: applicationReducer,
		admin: adminReducer,
		checkout: checkoutReducer,
		dashboard: dashboardReducer,
		reference: referenceReducer,
		order: orderReducer,
		partner: partnerReducer,
		purchase: purchaseReducer,
		router: routerReducer,
		shop: shopReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(routerMiddleware)
});

export const history = createReduxHistory(store);

export {
	setLogin,
	setLogout,
	selectIsLoaded,
	selectRequiresLogin,
	selectRequiresRegistration,
	setLoaded,
	setRequiresLogin,
	setRequiresRegistration
} from "./application";

export * from "./dashboard";

export * from "./hooks";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;