import { DTO, Market } from "@rego-app/common";
import { Box, Grid } from "grommet";
import { useAppSelector } from "../../../app/store";
import { selectMarkets } from "../../../app/store/reference";
import { useScreenSize } from "../../common";
import { MarketCard } from "../components";

interface SelectMarketPageProps {
	onClick: (market: DTO<Market>) => void;
}

export const SelectMarketPage: React.FC<SelectMarketPageProps> = (props) => {
	const size = useScreenSize();
	const markets = useAppSelector(selectMarkets);

	return (
		<Grid gap="medium" margin="large" columns={{ size: "auto", count: size === "small" ? 1 : 2 }}>
			{markets.map(market => (
				<Box key={market.id}>
					<MarketCard
						onClick={props.onClick}
						market={market}
					/>
				</Box>
			))}
		</Grid>
	);
};