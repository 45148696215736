import { Divider } from "@mui/material";
import { Box, Button, Grid, Layer } from "grommet";
import { SlimHeading } from "../../common";

interface LoginRequiredProps {
	heading?: string;
	message?: string;
	selectedRegister(): void;
	selectedLogin(): void;
}

export const LoginRequired: React.FC<LoginRequiredProps> = (props) => {
	return (
		<Layer>
			<Box margin="medium" gap="small">
				<Box align="center" gap="small">
					<SlimHeading level="3">{props.heading ?? "Login to Continue"}</SlimHeading>

					<SlimHeading level="5">
						{props.message ?? "Creating an account lets us save your information so you can come back and view the status"}
					</SlimHeading>
				</Box>
				<Box direction="row" justify="between" align="center">
					<Grid columns={["small", "flex", "small"]}>
						<Box align="center" margin="small" gap="small">
							<SlimHeading level="4">Existing User?</SlimHeading>
							<Button
								primary
								label="Login Now"
								onClick={props.selectedLogin}
							/>
						</Box>
						<Box>
							<Divider orientation="vertical" />
						</Box>
						<Box align="center" margin="small" gap="small">
							<SlimHeading level="4">New Here?</SlimHeading>
							<Button
								primary
								label="Register"
								onClick={props.selectedRegister}
							/>
						</Box>
					</Grid>
				</Box>
			</Box>
		</Layer>
	);
};