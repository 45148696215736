import { Contact, ContactType, DeliveryAddress, DeliveryType, DeliveryWindow, DTO, formatCurrency, Payment, PaymentMethod, Purchase, PurchaseIntent, Store, toProperCase } from "@rego-app/common";
import { push } from "connected-react-router";
import { Anchor, Box, Calendar, CheckBox, FormField, Spinner, Text, TextInput, ThemeContext } from "grommet";
import { FormAdd } from "grommet-icons";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ProductService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchCustomer, selectCustomer } from "../../../app/store/application";
import { parseDateFromUTC } from "../../../helpers";
import { UnionProduct } from "../../../types";
import { CreateContactModal } from "../../auth";
import { CreatePaymentMethod } from "../../auth/components/CreatePaymentMethod";
import { ElevatedCard, Loader, PickupWindow, SlimHeading, useTimezone } from "../../common";
import { AddressForm } from "../../order";
import { CheckoutStepControlButtons } from "../pages";

interface CheckoutAddressFormProps {
	purchase: DTO<Purchase>;
	onSuperNext(): void;
	onSaveAddress(address: DeliveryAddress): Promise<boolean>;
}

export const CheckoutAddressForm: React.FC<CheckoutAddressFormProps> = (props) => {
	const [isSaving, setIsSaving] = useState(false);
	const [address, setAddress] = useState<DeliveryAddress>(props.purchase.address ?? {} as DeliveryAddress);

	function handleSaveAddress(address: DeliveryAddress): void {
		setIsSaving(true);
		setAddress(address);
		props.onSaveAddress(address)
			.then((wasSuccessfull) => wasSuccessfull && props.onSuperNext())
			.finally(() => {
				setIsSaving(false);
			});
	}

	const addressOnly = useMemo(() => {
		return props.purchase?.intents?.every(e => e.delivery_type === DeliveryType.PICKUP) ?? false;
	}, [props.purchase]);

	return (
		<AddressForm
			addressOnly={addressOnly}
			address={address}
			onAddressSelected={(changedAddress) => {
				setAddress({
					...address,
					...changedAddress
				});
			}}
			onFormSubmit={handleSaveAddress}
		>
			<CheckoutStepControlButtons
				isLoadingNext={isSaving}
				nextLabel="Save Address"
				nextProps={{
					type: "submit"
				}}
				onClickNext={undefined}
			/>
		</AddressForm>
	);
};

interface PaymentMethodItemProps {
	isSelected: boolean;
	paymentMethod: DTO<PaymentMethod>;
	selectPaymentMethod(paymentMethod: DTO<PaymentMethod>): void;
}

export function formatCardDescription(method: DTO<PaymentMethod>): string {
	return `${toProperCase(method.brand)} ending in ${method.last_four}`;
}

export const PaymentMethodItem: React.FC<PaymentMethodItemProps> = (props) => {
	return (
		<Box
			direction="row"
			align="center"
			justify="between"
			border
			margin="small"
			hoverIndicator
			onClick={() => {
				props.selectPaymentMethod(props.paymentMethod);
			}}
		>
			<Text weight="bold" margin="small">
				{formatCardDescription(props.paymentMethod)}
			</Text>
			<FormField
				contentProps={{ border: undefined }}
				margin="none"
			>
				<CheckBox
					reverse
					checked={props.isSelected}
				/>
			</FormField>
		</Box>
	);
};

interface CheckoutSelectPaymentMethodProps {
	paymentMethod?: DTO<PaymentMethod>;
	returnUrl: string;
	onSuperNext(): void;
	handleSavePaymentMethod(paymentMethod: DTO<PaymentMethod>): void;
}

export const CheckoutSelectPaymentMethod: React.FC<CheckoutSelectPaymentMethodProps> = (props) => {
	const dispatch = useAppDispatch();
	const customer = useAppSelector(selectCustomer);
	const [paymentMethod, setPaymentMethod] = useState<DTO<PaymentMethod> | null>(props.paymentMethod ?? null);
	const [wasLoaded, setWasLoaded] = useState(false);
	const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(false);

	function handleFetchCustomer(): void {
		dispatch(fetchCustomer({ expand: ["contacts", "payment_methods"] })).unwrap()
			.catch(err => {

			})
			.finally(() => {
				setWasLoaded(true);
			});
	}

	function selectPaymentMethod(paymentMethod: DTO<PaymentMethod>): void {
		setPaymentMethod({ ...paymentMethod });
	}

	useEffect(() => {
		handleFetchCustomer();
	}, []);

	return (
		<Box gap="small" flex>
			{(showPaymentMethodForm && customer) && (
				<CreatePaymentMethod
					onClose={() => {
						setShowPaymentMethodForm(false);
					}}
					returnURL={props.returnUrl}
					customer={customer}
				/>
			)}
			{!wasLoaded
				? (
					<Box align="start" justify="center" margin="small">
						<Spinner size="medium" />
					</Box>
				)
				: customer?.payment_methods?.map(method => (
					<PaymentMethodItem
						key={method.id}
						paymentMethod={method}
						selectPaymentMethod={selectPaymentMethod}
						isSelected={method.id === paymentMethod?.id}
					/>
				)
				)}
			<Box margin="small">
				<Anchor
					icon={<FormAdd />}
					label="Add Payment Method"
					onClick={() => {
						setShowPaymentMethodForm(true);
					}}
				/>
			</Box>
			<Box flex justify="end">
				<CheckoutStepControlButtons
					forceNext
					disableNext={!paymentMethod}
					onClickNext={() => {
						if(paymentMethod) {
							props.handleSavePaymentMethod(paymentMethod);
							props.onSuperNext();
						}
					}}
				/>
			</Box>
		</Box>
	);
};

interface CheckoutDeliveryTimingProps {
	purchase: DTO<Purchase>;
	hasPickupType: boolean;
	hasDeliveryType: boolean;
	onSuperNext(): void;
	onWindowUpdated(window: DeliveryWindow): Promise<boolean>;
}

export const purchasePickupWindows = [
	{
		from: 8,
		to: 12,
		label: "08:00 AM - 12:00 PM"
	},
	{
		from: 12,
		to: 16,
		label: "12:00 PM - 04:00 PM"
	},
	{
		from: 16,
		to: 20,
		label: "04:00 PM - 08:00 PM"
	}
];

interface CheckoutDeliveryTimingState {
	isLoading: boolean;
	selectedDate: Date | null;
	selectedWindow: typeof purchasePickupWindows[0] | null;
}


export const CheckoutDeliveryTiming: React.FC<CheckoutDeliveryTimingProps> = (props) => {
	const timezone = useTimezone();
	const [state, setState] = useState<CheckoutDeliveryTimingState>({
		isLoading: false,
		selectedWindow: props.purchase?.delivery_window
			? purchasePickupWindows.find(w => w.from === props.purchase.delivery_window.from && w.to === props.purchase.delivery_window.to) ?? null
			: null,
		selectedDate: props.purchase?.delivery_window
			? moment.tz(props.purchase.delivery_window.date, props.purchase.delivery_window.timezone).toDate()
			: null
	});

	const calculateDateBounds = useCallback((): [Date, Date] => {
		return [
			moment().add(1, "day").toDate(),
			moment.min(
				moment().add(1, "week")
			).toDate()
		];
	}, [props.purchase]);

	const excludedDates = useMemo(() => {
		return [] as Date[];
	}, [props.purchase]);

	function onDateSelected(date: Date): void {
		setState({
			...state,
			selectedDate: date
		});
	}

	function onWindowSelected(label: string): void {
		const window = purchasePickupWindows.find(w => w.label === label);
		if(!window) {
			return;
		}

		setState({
			...state,
			selectedWindow: window
		});
	}

	function handleSaveTiming(): void {
		if(!state.selectedDate || !state.selectedWindow) {
			return;
		}

		setState({
			...state,
			isLoading: true
		});

		const window: DeliveryWindow = {
			date: state.selectedDate,
			from: state.selectedWindow.from,
			to: state.selectedWindow.to,
			timezone
		};
		props.onWindowUpdated(window)
			.then(wasSuccessful => {
				if(wasSuccessful) {
					props.onSuperNext();
				}
			})
			.finally(() => {
				setState({
					...state,
					isLoading: false
				});
			});
	}

	return (
		<Box gap="small" margin="small" flex id="container">
			{props.hasPickupType && (
				<PickupDetails
					intents={props.purchase.intents ?? []}
				/>
			)}
			{props.hasDeliveryType && (
				<Box>
					<Box margin="medium">
						<Text weight="bold">
							Select your desired delivery date. We will work with the seller(s) to find a time that works for all parties. Please note we may need to change this date in order to accommodate any timing requirements.
						</Text>
					</Box>
					<Box>
						<ThemeContext.Extend
							value={{
								global: {
									focus: {
										border: {
											color: "transparent",
										},
									},
								},
							}}>
							<Calendar
								firstDayOfWeek={0}
								style={{ outline: "none !important", boxShadow: "none !important", width: "auto" }}
								margin="small"
								date={state.selectedDate?.toISOString() ?? undefined}
								showAdjacentDays="trim"
								bounds={calculateDateBounds().map(d => d.toISOString())}
								disabled={excludedDates.map(d => d.toISOString())}
								onSelect={(date) => {
									onDateSelected(new Date(Array.isArray(date) ? date[0] : date));
								}}
							/>
						</ThemeContext.Extend>
					</Box>
					<Box>
						<FormField
							name="window"
							label="Select your desired delivery window"
						>
							<PickupWindow
								name="window"
								value={state.selectedWindow?.label ?? ""}
								windows={purchasePickupWindows}
								onChange={(event) => {
									onWindowSelected(event.target.value);
								}}
							/>
						</FormField>
					</Box>
				</Box>
			)}
			<Box flex justify="end">
				<CheckoutStepControlButtons
					isLoadingNext={state.isLoading}
					disableNext={props.hasDeliveryType && (!state.selectedDate || !state.selectedWindow)}
					onClickNext={props.hasDeliveryType ? handleSaveTiming : props.onSuperNext}
				/>
			</Box>
		</Box>
	);
};

interface PickupDetailsProps {
	intents: DTO<PurchaseIntent>[];
}

export const PickupDetails: React.FC<PickupDetailsProps> = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [products, setProducts] = useState<DTO<UnionProduct>[]>(props.intents.map(i => i.product as unknown as DTO<UnionProduct>));

	const storeProducts = useMemo(() => {
		const stores = products
			.map(p => p.store)
			.filter((v) => !!v)
			.filter((store, index) => products.findIndex(product => product.store?.id === store?.id) === index);

		return stores.map(store => {
			return {
				store: store as DTO<Store>,
				products: products.filter(p => p.store?.id === store?.id)
			};
		});
	}, [products]);

	useEffect(() => {
		setIsLoading(true);
		Promise.all(products.map(product => ProductService.getUnionProduct(product.id, ["store"])))
			.then(res => {
				setProducts([...res]);
			})
			.catch(err => {
				console.error("Failed to load products", err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<Box margin="small">
			<Loader visible={isLoading}>
				{storeProducts.map(storeProduct => {
					if(storeProduct.store) {
						return (
							<StorePickupInstructionsCard
								store={storeProduct.store}
								key={storeProduct.store.id}
							/>
						);
					}

					return <PickupInstructionsCard />;
				})}
			</Loader>
		</Box>
	);
};

export const PickupInstructionsCard: React.FC = (props) => {
	return (
		<Box>
			<Text>Please get in touch with us to confirm the details of your pickup.</Text>
		</Box>
	);
};

interface StorePickupInstructionsCardProps {
	store: DTO<Store>;
}

export const StorePickupInstructionsCard: React.FC<StorePickupInstructionsCardProps> = (props) => {
	return (
		<Box margin="small" gap="small">
			<Text weight="bold" size="large">{`Pickup Instructions from ${props.store.name}`}</Text>
			<ElevatedCard cardProps={{ pad: "medium" }}>
				<Text weight="bold">{props.store.pickup_instructions}</Text>
			</ElevatedCard>
		</Box>
	);
};


interface CheckoutConfirmationScreenProps {
	purchase: DTO<Purchase>;
	payment: DTO<Payment> | null;
	paymentMethod: DTO<PaymentMethod> | null;
	onSubmitPurchase(): Promise<boolean>;
}

export const CheckoutConfirmationScreen: React.FC<CheckoutConfirmationScreenProps> = (props) => {
	const dispatch = useAppDispatch();
	const timezone = useTimezone();
	const [isSubmitting, setIsSubmitting] = useState(false);

	function handleSubmitPurchase(): void {
		setIsSubmitting(true);
		props.onSubmitPurchase().then(wasSuccessful => {

		}).finally(() => {
			setIsSubmitting(false);
		});
	}

	return (
		<Box gap="small" margin="medium" flex>
			<Box gap="small">
				<Box gap="small">
					<SlimHeading level="4">Address</SlimHeading>
					<Box margin="small">
						<Text>
							{props.purchase.address?.address_line_one}
						</Text>
						<Text>
							{props.purchase.address?.city}, {props.purchase.address?.state} {props.purchase.address?.zip}
						</Text>
					</Box>
				</Box>
				<Box gap="small">
					<SlimHeading level="4">Payment Method</SlimHeading>
					<Box margin="small">
						{props.paymentMethod && (
							<Text>
								{formatCardDescription(props.paymentMethod)}
							</Text>
						)}
					</Box>
				</Box>
			</Box>
			{props.purchase.paid && (
				<Box gap="small">
					<Text>
						Your purchase was completed on <Text weight="bold">{parseDateFromUTC(props.purchase.paid_at, timezone)}</Text>.
					</Text>
					{props.payment && (
						<Text>
							Your payment method was successfully charged for <Text weight="bold">{formatCurrency(props.payment.amount)}</Text>. Your payment reference number is <Text weight="bold">{props.payment.number}</Text>.
						</Text>
					)}
					<Box gap="small">
						<Anchor
							label="Continue Shopping?"
							onClick={() => dispatch(push("/shop"))}
						/>
					</Box>
				</Box>
			)}
			{!props.purchase.paid && (
				<Box flex justify="end">
					<CheckoutStepControlButtons
						isLoadingNext={isSubmitting}
						onClickNext={handleSubmitPurchase}
					/>
				</Box>
			)}
		</Box>
	);
};


interface CheckoutContactDetailsProps {
	onSuperNext(): void;
}

export const CheckoutContactDetails: React.FC<CheckoutContactDetailsProps> = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const customer = useAppSelector(selectCustomer);
	const [wasLoaded, setWasLoaded] = useState(false);
	const [addingContact, setAddingContact] = useState<ContactType | null>(null);

	const canProceed = useMemo(() => {
		return !!customer?.email_address && !!customer.phone_number;
	}, [customer]);

	function handleFetchCustomer(): void {
		dispatch(fetchCustomer({ expand: ["contacts", "payment_methods"] })).unwrap()
			.catch(err => {

			})
			.finally(() => {
				setWasLoaded(true);
			});
	}

	useEffect(() => {
		handleFetchCustomer();
	}, []);

	function onContactCreated(contact?: DTO<Contact>): void {
		if(!contact) {
			return setAddingContact(null);
		}

		dispatch(fetchCustomer({})).unwrap()
			.catch(err => {
				console.error("Failed to load customer", err);
				snack.enqueueSnackbar("We ran into an issue loading your information", {
					variant: "error"
				});
			})
			.finally(() => {
				return setAddingContact(null);
			});
	}

	return (
		<Box gap="small" flex>
			{(addingContact && customer) && (
				<CreateContactModal
					forcePrimary={true}
					customer={customer}
					type={addingContact}
					onCreatedCallback={onContactCreated}
				/>
			)}
			<FormField
				name="email"
				label="Email Address"
			>
				<TextInput
					name="email"
					readOnly={!!customer?.email_address}
					disabled={!!customer?.email_address}
					value={customer?.email_address}
					onClick={() => {
						setAddingContact(ContactType.EMAIL);
					}}
				/>
			</FormField>
			<FormField
				name="phone"
				label="Phone Number"
			>
				<TextInput
					name="phone"
					readOnly={!!customer?.phone_number}
					disabled={!!customer?.phone_number}
					value={customer?.phone_number}
					onClick={() => {
						setAddingContact(ContactType.PHONE);
					}}
				/>
			</FormField>
			<Box flex justify="end">
				<CheckoutStepControlButtons
					forceNext
					disableNext={!canProceed}
					onClickNext={() => {
						props.onSuperNext();
					}}
				/>
			</Box>
		</Box>
	);
};