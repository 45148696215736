import axios from "axios";
import { setupInterceptorsTo } from "./intercept";
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import { AccessTokenResponse, ServerResponse } from "../../../types";
import { AuthService } from "../auth";

const axiosInstance = axios.create({
	withCredentials: true
});

export function updateBaseUrl(url: string) {
	//FIXME: Config does not include backslash

	if(url.charAt(url.length - 1) !== "/") {
		console.debug(`Appending [/] to base url: [${url}]`);
		url += "/";
	}

	console.debug("Setting base url to: ", url);
	axiosInstance.defaults.baseURL = url;
}

async function refreshAuthToken(failedRequest: any) {
	try {
		console.debug("Attempting token refresh");

		if(!AuthService.getRefreshToken()) {
			console.debug("No refresh token found");
			return Promise.resolve();
		}

		const tokenRefreshResponse = await axiosInstance.post<ServerResponse<AccessTokenResponse>>("/auth/refresh", {
			user_id: AuthService.getUserId(),
			refresh_token: AuthService.getRefreshToken()
		}, { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig);
		AuthService.setAccessTokenResponse(tokenRefreshResponse.data.result);
		failedRequest.response.config.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.result.access_token;
		return await Promise.resolve();
	} catch(err) {
		console.error("Refresh failed", err);
		AuthService.logout();
		throw err;
	}
}

createAuthRefreshInterceptor(axiosInstance, refreshAuthToken);

export const RequestService = setupInterceptorsTo(axiosInstance);