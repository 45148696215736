import * as cocossd from "@tensorflow-models/coco-ssd";
import { useEffect, useState } from "react";
import useTensorflow from "./useTensorflow";

export function useCoco() {
	const tensorflow = useTensorflow();
	const [coco, setCoco] = useState<cocossd.ObjectDetection | null>(null);
	useEffect(() => {
		(async () => {
			if(tensorflow.isReady) {
				console.debug("got tensorflow ready ... loading coco-ssd");
				const initialized = await cocossd.load().catch(err => {
					console.error("Error loading coco-ssd", err);
					return null;
				});

				console.debug("coco loaded");
				setCoco(initialized);

				return () => {
					initialized?.dispose();
				};
			}
		})();
	}, [tensorflow.isReady]);

	return coco;
}

export default useCoco;