import { DTO, Item } from "@rego-app/common";
import { FormField, FormFieldExtendedProps, TextInput } from "grommet";
import { useState } from "react";
import { ReferenceService } from "../../../app/services";
import { getStandardFormValidations } from "../../../helpers";

interface ProductItemFieldProps extends FormFieldExtendedProps {
	onSuggestionSelected(item: DTO<Item>): void;
}

export const ProductItemField: React.FC<ProductItemFieldProps> = (props) => {
	const [autofillItems, setAutofillItems] = useState<Item[]>([]);

	function queryItems(query: string): void {
		ReferenceService.queryItems(query)
			.then(results => {
				setAutofillItems([...results]);
			})
			.catch(err => {
				console.error("Failed to query items", err);
			});
	}

	return (
		<FormField
			{...{ ...props, onSuggestionSelected: undefined }}
			onChange={(event) => {
				queryItems(event.target.value);
			}}
			validate={[
				...getStandardFormValidations()
			]}
		>
			<TextInput
				name={props.name}
				suggestions={autofillItems.map(s => s.name)}
				onSuggestionSelect={(selected) => {
					const found = autofillItems.find(i => i.name === selected.suggestion);
					if(found) {
						props.onSuggestionSelected(found);
					}
				}}
			/>
		</FormField>
	);
};