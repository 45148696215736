import React from "react";
import { Button, ButtonProps } from "grommet";

export const ButtonWhite: React.FC<ButtonProps> = (props) => {
	return (
		<Button
			color="white"
			{...props}
		/>
	)
};