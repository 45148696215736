import { DTO, Product } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, Grid, Spinner } from "grommet";
import { useSteps } from "react-step-builder";
import { useAppDispatch } from "../../../app/store";
import { Link, useScreenSize } from "../../common";

interface FormProgressButtonsProps {
	isLoading?: boolean;
	forceContinueEnabled?: boolean;
	products?: DTO<Product>[];
}

export const FormProgressButtons: React.FC<FormProgressButtonsProps> = (props) => {
	const { hasNext, hasPrev, next, prev, jump } = useSteps();
	return (
		<Box gap="small">
			<Box direction="row" justify="between">
				<Box>
					<Button
						secondary
						disabled={!hasPrev}
						label="Go Back"
						onClick={prev}
					/>
				</Box>
				<Box direction="row" gap="small">
					<Button
						primary
						icon={props.isLoading ? <Spinner /> : undefined}
						disabled={(!hasNext && !props.forceContinueEnabled) || props.isLoading}
						type="submit"
						label="Continue"
					/>
				</Box>
			</Box>
			<Box align="end">
				{props?.products?.length
					? (
						<Link
							label="Skip ->"
							to="/order/address"
						/>
					)
					: undefined}
			</Box>
		</Box>
	);
};