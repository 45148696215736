import { DTO, Purchase } from "@rego-app/common";
import { Box, Grid } from "grommet";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, selectActiveCustomerPurchases, listCustomerPurchases } from "../../../app/store";
import { Loader } from "../../common";
import { PurchaseCard, ServiceCardContainer } from "../components";

export const DashboardPurchases: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const activePurchases = useAppSelector(selectActiveCustomerPurchases);

	const [isLoaded, setIsLoaded] = useState(false);
	const [selectedPurchase, setSelectedPurchase] = useState<DTO<Purchase> | null>(null);
	const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);

	function handleViewPurchase(purchase: DTO<Purchase>): void {
		setSelectedPurchase(purchase);
		setDetailsModalOpen(true);
	}

	useEffect(() => {
		dispatch(listCustomerPurchases({})).unwrap()
			.catch(err => {
				console.error("Failed to load customer purchases", err);
				snack.enqueueSnackbar("We ran into an issue loading your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}, []);

	return (
		<Loader visible={!isLoaded}>
			<ServiceCardContainer
				title="Your Purchases"
				noItemsMessage="nothing in progress right now"
				largeScreenPageSize={9}
				mediumScreenPageSize={6}
				smallScreenPageSize={4}
				isViewingAll={true}
				items={activePurchases}
				renderItems={(items) => (
					<Grid columns="medium">
						{items.map(purchase => (
							<Box key={purchase.id}>
								<PurchaseCard
									purchase={purchase as unknown as DTO<Purchase>}
								/>
							</Box>
						))}
					</Grid>
				)}
			/>
		</Loader>
	);
};