import { Customer, CustomerProduct, DTO, DTOWithoutBase, InboundReferral, Match, MatchQueryType, PaginatedServerResponse, Partner, Product, Purchase, PurchaseIntent, QueryProductsRequest, RelationExpand, Store, StoreProduct } from "@rego-app/common";
import { ServerResponse } from "../../types";
import { RequestService } from "./request";
import { UtilService } from "./util";

export const PartnerService = {
	async getPartner(partnerId: string, expand?: (keyof DTOWithoutBase<Store>)[]): Promise<DTO<Partner>> {
		const result = await RequestService
			.get<ServerResponse<DTO<Store>>>(`/api/v1/partner/${partnerId}`, {
				params: {
					expand: expand ? expand.join(",") : undefined
				}
			});
		return result.data.result;
	},

	async getStore(storeId: string, expand?: (keyof DTOWithoutBase<Store>)[]): Promise<DTO<Store>> {
		const result = await RequestService
			.get<ServerResponse<DTO<Store>>>(`/api/v1/store/${storeId}`, {
				params: {
					expand: expand ? expand.join(",") : undefined
				}
			});
		return result.data.result;
	},

	async updateStore(storeId: string, data: Partial<Store>): Promise<DTO<Store>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<Store>>>(`/api/v1/store/${storeId}`, {
				...data
			});
		return result.data.result;
	},

	async listStores(filters?: Partial<Store>): Promise<DTO<Store>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Store>[]>>(`/api/v1/store`, {
				params: filters
			});
		return result.data.result;
	},

	async createReferral(partnerId: string, params: URLSearchParams): Promise<DTO<InboundReferral>> {
		const attribution = UtilService.buildAttribution(params);
		const result = await RequestService
			.post<ServerResponse<DTO<InboundReferral>>>(
				`/api/v1/referral`,
				{
					partner: {
						id: partnerId
					}
				},
				{
					params: attribution
				}
			);
		return result.data.result;
	},

	async authorizeStoreUser(storeId: string): Promise<boolean> {
		const result = await RequestService
			.get<ServerResponse<boolean>>(`/api/v1/store/${storeId}/authorize`);
		return result.data.result;
	},

	async listStoreMatches(storeId: string, type: MatchQueryType): Promise<DTO<Match>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Match>[]>>(`/api/v1/store/${storeId}/match`, {
				params: {
					type
				}
			});
		return result.data.result;
	},

	async confirmDonationProduct(storeId: string, productId: string): Promise<DTO<Product>> {
		const result = await RequestService
			.post<ServerResponse<DTO<Product>>>(`/api/v1/store/${storeId}/product/${productId}/confirm-donation`);
		return result.data.result;
	},
	async rejectDonationProduct(storeId: string, productId: string): Promise<DTO<Product>> {
		const result = await RequestService
			.post<ServerResponse<DTO<Product>>>(`/api/v1/store/${storeId}/product/${productId}/reject-donation`);
		return result.data.result;
	},

	async listStoreUsers(storeId: string): Promise<DTO<Customer>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Customer>[]>>(`/api/v1/store/${storeId}/user`);
		return result.data.result;
	},

	async createStoreProduct(storeId: string, quantity: number, data: Partial<DTO<StoreProduct>>): Promise<DTO<StoreProduct>> {
		const result = await RequestService
			.put<ServerResponse<DTO<StoreProduct>>>(`/api/v1/store/${storeId}/product`, {
				quantity,
				...data
			});
		return result.data.result;
	},

	async updateStoreProduct(storeId: string, productId: string, updates: Partial<DTO<StoreProduct>>): Promise<DTO<StoreProduct>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<StoreProduct>>>(`/api/v1/store/${storeId}/product/${productId}`, {
				...updates
			});
		return result.data.result;
	},

	async submitStoreProduct(storeId: string, productId: string): Promise<DTO<StoreProduct>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<StoreProduct>>>(`/api/v1/store/${storeId}/product/${productId}/submit`);
		return result.data.result;
	},

	async listStoreProducts(storeId: string, filters?: Partial<StoreProduct>): Promise<DTO<StoreProduct>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<StoreProduct>[]>>(`/api/v1/store/${storeId}/product`, {
				params: {
					...filters ?? {}
				}
			});
		return result.data.result;
	},

	async listStorePurchases(storeId: string, filters?: Partial<StoreProduct>, expand?: RelationExpand<Purchase>): Promise<DTO<Purchase>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Purchase>[]>>(`/api/v1/store/${storeId}/purchase`, {
				params: {
					...filters ?? {},
					expand: (expand ?? []).join(",")
				}
			});
		return result.data.result;
	},

	async getStorePurchase(storeId: string, purchaseId: string, expand?: RelationExpand<Purchase>): Promise<DTO<Purchase>> {
		const result = await RequestService
			.get<ServerResponse<DTO<Purchase>>>(`/api/v1/store/${storeId}/purchase/${purchaseId}`, {
				params: {
					expand: (expand ?? []).join(",")
				}
			});
		return result.data.result;
	},

	async listStorePurchaseIntents(storeId: string, purchaseId: string): Promise<DTO<PurchaseIntent>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<PurchaseIntent>[]>>(`/api/v1/store/${storeId}/purchase/${purchaseId}/intent`);
		return result.data.result;
	},

	async updatePurchaseIntentCompleted(storeId: string, purchaseId: string, intentId: string): Promise<DTO<PurchaseIntent>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<PurchaseIntent>>>(`/api/v1/store/${storeId}/purchase/${purchaseId}/intent/${intentId}/complete`);
		return result.data.result;
	},

	async getStoreProduct(storeId: string, productId: string, expand?: RelationExpand<StoreProduct>): Promise<DTO<StoreProduct>> {
		const result = await RequestService
			.get<ServerResponse<DTO<StoreProduct>>>(`/api/v1/store/${storeId}/product/${productId}`, {
				params: {
					expand: (expand ?? []).join(",")
				}
			});
		return result.data.result;
	},

	async deleteStoreProduct(storeId: string, productId: string, expand?: RelationExpand<StoreProduct>): Promise<DTO<StoreProduct>> {
		const result = await RequestService
			.delete<ServerResponse<DTO<StoreProduct>>>(`/api/v1/store/${storeId}/product/${productId}`);
		return result.data.result;
	},

	async queryStoreProducts(storeId: string, request: QueryProductsRequest): Promise<PaginatedServerResponse<DTO<StoreProduct>[]>> {
		const result = await RequestService
			.post<PaginatedServerResponse<DTO<StoreProduct>[]>>(`/api/v1/store/${storeId}/product/query`, request);
		return result.data;
	},

	async fetchScheduledDonationProducts(storeId: string): Promise<DTO<CustomerProduct>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<CustomerProduct>[]>>(`/api/v1/store/${storeId}/donation/scheduled-products`);
		return result.data.result;
	},

	async fetchCompletedDonationProducts(storeId: string): Promise<DTO<CustomerProduct>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<CustomerProduct>[]>>(`/api/v1/store/${storeId}/donation/completed-products`);
		return result.data.result;
	},

	async fetchCompletedDonationPendingProducts(storeId: string): Promise<DTO<CustomerProduct>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<CustomerProduct>[]>>(`/api/v1/store/${storeId}/donation/completed-pending-products`);
		return result.data.result;
	}
};