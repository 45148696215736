import { Chip } from "@mui/material";
import { CustomerProduct, DTO, ListingRemovedReason, Match, MatchQueryType, Product, ProductMedia, ProductMediaContext, Purchase, Store, StoreProduct } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Text, Image, Grid, Spinner, Button, DataChart, Stack, FormField, TextInput } from "grommet";
import { useState, useEffect, useCallback, useMemo } from "react";
import { PartnerService, ProductService } from "../../../../app/services";
import { useAppDispatch } from "../../../../app/store";
import { isCustomerProduct, isStoreProduct, prettyPrintHour } from "../../../../helpers";
import { ImageCarousel, Loader, LoadingButton, Modal, Pagination, SlimHeading, useTimezone, useWindowDimensions } from "../../../common";
import { MediaDropzone } from "../../../order";
import { Dimensions, ProductCard, SoldByStoreFooter } from "../../../shop";
import placeholder from "../../../../img/placeholder.jpg";
import { useSnackbar } from "notistack";
import moment from "moment-timezone";
import { setActiveStoreProduct } from "../../../../app/store/partners";
import { UnionProduct } from "../../../../types";

export const ElevatedCardHeader: React.FC<{ title: string, action?: JSX.Element; }> = (props) => {
	return (
		<Box direction="row" justify="between" background="brand" flex pad="small" style={{ minHeight: "60px" }}>
			<SlimHeading level="3">
				{props.title}
			</SlimHeading>
			{!!props.action && (
				<Box align="end">
					{props.action}
				</Box>
			)}
		</Box>
	);
};


interface CreateStoreProductPreviewProps {
	product: DTO<Product>;
}

export const CreateStoreProductPreview: React.FC<CreateStoreProductPreviewProps> = (props) => {
	return (
		<Box pad="medium" align="center" justify="center" height="fit-content">
			<ProductCard
				product={props.product}
				footer={(isStoreProduct(props.product) && props.product.store) ? (
					<SoldByStoreFooter store={props.product.store} />
				) : undefined}
			/>
		</Box>
	);
};

interface MediaUploadModalProps {
	onClose(): void;
	product: Partial<DTO<Product>>;
	onFilesAccepted(files: File[], context: ProductMediaContext): Promise<void>;
	onMediaDeleted(media: DTO<ProductMedia>): Promise<boolean>;
}

export const MediaUploadModal: React.FC<MediaUploadModalProps> = (props) => {
	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box margin="medium">
				<MediaDropzone
					label="Item Image(s)"
					product={props.product}
					isValidating={false}
					onSkipUpload={() => { }}
					onFilesAccepted={props.onFilesAccepted}
					handleDeleteMedia={props.onMediaDeleted}
				/>
			</Box>
		</Modal>
	);
};

interface UpdatePrimaryMediaModalProps {
	onClose(): void;
	store: DTO<Store>;
	product: DTO<Product>;
}

interface MediaWithSource extends DTO<ProductMedia> {
	src: string;
}

export const UpdatePrimaryMediaModal: React.FC<UpdatePrimaryMediaModalProps> = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const [media, setMedia] = useState<MediaWithSource[]>([]);
	const [isUpdatingMedia, setIsUpdatingMedia] = useState("");
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();

	const [primary, setPrimary] = useState<DTO<ProductMedia> | null>(null);
	const [isLoadingPrimary, setIsLoadingPrimary] = useState<string | null>(null);

	useEffect(() => {
		Promise.all((props.product.media ?? []).map(m => {
			return ProductService.getMediaURL(props.product.id, m.id)
				.then(src => {
					const item = { ...m } as MediaWithSource;
					item.src = src;
					return item;
				});
		}))
			.then(res => setMedia([...res]))
			.catch(err => {
				console.error("Failed to load media urls", err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	function makePrimary(mediaId: string): void {
		setIsUpdatingMedia(mediaId);
		setIsLoadingPrimary(mediaId);
		ProductService.updateProductMediaPrimary(props.product.id, mediaId)
			.then(() => {
				const existing = [...media];
				for(const item of existing) {
					if(item.id === mediaId) {
						item.is_primary = true;
					}
					else {
						item.is_primary = false;
					}
				}

				setMedia([...existing]);
			})
			.then(() => {
				return PartnerService.getStoreProduct(props.store.id, props.product.id, ["store"]).then(res => {
					dispatch(setActiveStoreProduct({ ...res }));
				});
			})
			.catch(err => {
				console.error("Failed to update image primary", err);
				snack.enqueueSnackbar("We ran into an issue updating your information", { variant: "error" });
			})
			.finally(() => {
				setIsUpdatingMedia("");
				setIsLoadingPrimary("");
			});
	}

	const columns = useMemo(() => {
		switch(size) {
			case "small": {
				return 1;
			}
			case "medium": {
				return 2;
			}
			default: {
				return 3;
			}
		}
	}, [size]);

	useEffect(() => {
		const found = media.find(m => m.is_primary);
		if(found) {
			setPrimary({ ...found });
		}
	}, [media]);

	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
			full
		>
			<Loader visible={isLoading}>
				<Box margin="small">
					<Text weight="bold">Click to select the primary image</Text>
				</Box>
				<Box margin="medium" height="large" gap="medium" overflow={{ vertical: "scroll" }}>
					{media.length <= 0 && (
						<Box align="center" justify="center">
							<SlimHeading level="4">No images uploaded</SlimHeading>
						</Box>
					)}
					<Grid justify="center" columns={{ count: columns, size: "auto" }} gap="medium">
						{media.map(item => (
							<Box
								height="small"
								width="small"
								hoverIndicator
								onClick={() => {
									console.log("CLICKED!");
									setPrimary({ ...item });
									makePrimary(item.id);
								}}
								border={item.id === primary?.id ? { side: "all", color: "brand", size: "large" } : undefined}
							>
								<Stack fill>
									<Box fill>
										<Image
											fit="cover"
											src={item.src}
										/>
									</Box>
									{isLoadingPrimary === item.id && (
										<Box align="center" justify="center" fill background="lightgrey" style={{ opacity: .20, zIndex: "5" }}>
											<Spinner size="larger" />
										</Box>
									)}
								</Stack>
								{/* <Box align="center" justify="center">
									<Button
										primary
										disabled={item.is_primary || item.id === isUpdatingMedia}
										label="Make Primary"
										icon={item.id === isUpdatingMedia ? <Spinner /> : undefined}
										onClick={() => makePrimary(item.id)}
									/>
								</Box> */}
							</Box>
						))}
					</Grid>
				</Box>
			</Loader>
		</Modal>
	);
};


interface StoreProductSummaryProps {
	store: DTO<Store>;
	product: DTO<Product>;
	onClick?(product: DTO<Product>): void;
}

export const StoreProductSummary: React.FC<StoreProductSummaryProps> = (props) => {
	const dispatch = useAppDispatch();
	const { size } = useWindowDimensions();
	const [thumbnail, setThumbnail] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [product, setProduct] = useState<DTO<Product>>(props.product);

	useEffect(() => {
		if(props.product.media.length) {
			const media = props.product.media.find(m => m.is_primary) ?? props.product.media[0];
			ProductService.getMediaURL(props.product.id, media.id)
				.then(res => {
					setThumbnail(res);
				})
				.catch(err => {
					console.error("Failed to load product media", props.product, err);
					setThumbnail(placeholder);
				});
		}
		else {
			setThumbnail(placeholder);
		}
	}, []);

	useEffect(() => {
		if(props.product) {
			setIsLoading(true);
			ProductService.getUnionProduct(props.product.id, ["service", "store", "order", "customer", "purchase", "purchases"])
				.then(res => {
					setProduct({ ...res });
				})
				.catch(err => {
					console.error("Failed to load hydrated product", err);
				});
		}
	}, [props.product]);

	return (
		<Box>
			<Box
				border
				hoverIndicator
				margin="small"
				onClick={(event) => {
					if(props.onClick) {
						props.onClick(props.product);
						return;
					}

					dispatch(push(`/partner/store/${props.store.id}/products/${props.product.id}`));
				}}
			>
				{size === "small" && (
					<Box gap="small" margin="medium">
						<Grid columns={["xsmall", "flex"]}>
							<Box align="center" justify="center">
								<Box margin="small">
									{!thumbnail
										? (
											<Box>
												<Spinner size="medium" fill />
											</Box>
										)
										: (
											<Image height={75} width={75} src={thumbnail} />
										)
									}
								</Box>
							</Box>
							<StoreProductSummaryChip
								product={props.product}
							/>
						</Grid>
						<Box gap="small" justify="center">
							<Text weight="bold">
								{props.product.item?.name ?? props.product?.item_input}
							</Text>
							{(isCustomerProduct(props.product) && props.product?.service?.scheduled_window?.date) && (
								<Text>
									Pickup Date: <Text weight="bold">{moment(props.product.service.scheduled_window.date).format("MM/DD/YYYY")}</Text>
								</Text>
							)}
						</Box>
					</Box>
				)}
				{size !== "small" && (
					<Grid columns={["xsmall", "flex"]}>
						<Box align="center" justify="center">
							<Box margin="small">
								{!thumbnail
									? (
										<Box>
											<Spinner size="medium" fill />
										</Box>
									)
									: (
										<Image height={75} width={75} src={thumbnail} />
									)
								}
							</Box>
						</Box>
						<Grid columns={["flex", "auto"]} margin="small" gap="small">
							<Box gap="small" justify="center">
								<Text>
									Type: <Text weight="bold">{props.product.item?.name ?? props.product?.item_input}</Text>
								</Text>
								{(isCustomerProduct(props.product) && props.product?.service?.scheduled_window?.date) && (
									<Text>
										Pickup Date: <Text weight="bold">{moment(props.product.service.scheduled_window.date).format("MM/DD/YYYY")}</Text>
									</Text>
								)}
							</Box>
							<StoreProductSummaryChip
								product={props.product}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
		</Box>
	);
};

interface StoreProductSummaryContentProps {
	product: DTO<Product>;
}

export const StoreProductSummaryChip: React.FC<StoreProductSummaryContentProps> = (props) => {
	const isOutOfStock = useMemo(() => {
		const product = props.product;
		return (product.removed && product.rejected_reason === ListingRemovedReason.OUT_OF_STOCK) || (product.inventory && !props.product.inventory.quantity);
	}, [props.product]);

	return (
		<Box align="end" justify="center" margin="small" flex>
			{props.product.submitted && isOutOfStock && (
				<Chip size="medium" color="warning" label={"Out of Stock"} />
			)}
			{props.product.visible && props.product.submitted && !isOutOfStock && (
				<Chip size="medium" color="primary" label={`${props.product.inventory.quantity} in stock`} />
			)}
			{!props.product.submitted && (
				<Chip size="medium" color="warning" label={"Not Visible"} />
			)}
		</Box>
	);
};


interface DonationProductCardProps {
	store: DTO<Store>;
	product: DTO<CustomerProduct>;
	onClick?(product: DTO<CustomerProduct>): void;
}

export const DonationProductCard: React.FC<DonationProductCardProps> = (props) => {
	return (
		<StoreProductSummary
			store={props.store}
			product={props.product}
			onClick={props.onClick}
		/>
	);
};

interface DonationProductContainerProps {
	store: DTO<Store>;
	products: DTO<CustomerProduct>[];
}

export const DonationProductContainer: React.FC<DonationProductContainerProps> = (props) => {
	const renderItems = useCallback((items: DTO<CustomerProduct>[]) => {
		return (
			<Box gap="small" margin="small" flex>
				{items.map(product => (
					<DonationProductCard
						store={props.store}
						product={product}
					/>
				))}
			</Box>
		);
	}, [props.store, props.products]);

	return (
		<Box flex>
			<Pagination
				items={props.products}
				renderItems={renderItems}
				pageSize={4}
			/>
		</Box>
	);
};

export const DonationProductContent: React.FC<{ product: DTO<CustomerProduct | StoreProduct>; }> = (props) => {
	return (
		<Box margin="small" gap="small" direction="row">
			<Box gap="small" justify="center">
				<Text>
					Item Type: <Text weight="bold">	{props.product.item?.name ?? props.product.item_input}</Text>
				</Text>
				<Text>
					Item Condition: <Text weight="bold">{props.product.condition}</Text>
				</Text>
			</Box>
		</Box>
	);
};

interface StoreProductReviewModalProps {
	isRejecting: boolean;
	isConfirming: boolean;
	hasDecision: boolean;
	product: DTO<Product>;
	onClose(): void;
	onReject(product: DTO<Product>): void;
	onConfirm(product: DTO<Product>): void;
}

export const StoreProductReviewModal: React.FC<StoreProductReviewModalProps> = (props) => {
	return (
		<Modal
			onEsc={props.onClose}
			onClickClose={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box flex width="medium">
				<ImageCarousel
					width="100%"
					product={props.product}
				/>
				<Box gap="xsmall" margin="small" flex>
					<FormField
						label="Item Type"
					>
						<TextInput
							readOnly
							disabled
							value={props.product.item?.name ?? props.product.item_input ?? "Unknown"}
						/>
					</FormField>
					{(isCustomerProduct(props.product) && !!props.product?.service?.scheduled_window?.date) && (
						<FormField
							label="Pickup Date"
						>
							<TextInput
								readOnly
								disabled
								value={moment(props.product.service.scheduled_window.date).format("MM/DD/YYYY")}
							/>
						</FormField>
					)}
					<Box flex justify="end">
						{props.hasDecision && (
							<Box direction="row" justify="between" gap="medium">
								<LoadingButton
									primary
									isLoading={props.isRejecting}
									onClick={() => props.onReject(props.product)}
									label="Not Accepted"
									color="status-error"
								/>
								<LoadingButton
									primary
									isLoading={props.isConfirming}
									onClick={() => props.onConfirm(props.product)}
									label="Accepted"
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

interface StorePurchaseGraphProps {
	purchases: DTO<Purchase>[];
}

export const StorePurchaseGraph: React.FC<StorePurchaseGraphProps> = (props) => {
	const timezone = useTimezone();

	const unitSaleData = useMemo(() => {
		const purchases = props.purchases.flatMap(purchase => {
			return {
				date: moment.tz(purchase.paid_at, "UTC").tz(timezone).format("YYYY-MM-DD"),
				amount: purchase.intents.reduce((p, c) => p + c.quantity, 0)
			};
		});

		const data = [];
		const cursor = moment().subtract(7, "days");
		for(let i = 0; i < 7; i++) {
			data.push({
				date: cursor.toISOString(),
				amount: purchases
					.filter(p => p.date === cursor.format("YYYY-MM-DD"))
					.map(p => p.amount)
					.reduce((p, c) => p + c, 0)
			});
			cursor.add(1, "day");
		}

		return data;
	}, [props.purchases]);

	const volumeSaleData = useMemo(() => {
		const purchases = props.purchases.flatMap(purchase => {
			return {
				date: moment.tz(purchase.paid_at, "UTC").tz(timezone).format("YYYY-MM-DD"),
				amount: purchase.intents.reduce((p, c) => p + (c.quantity * c.product.price), 0)
			};
		});

		const data = [];
		const cursor = moment().subtract(7, "days");
		for(let i = 0; i < 7; i++) {
			data.push({
				date: cursor.toISOString(),
				amount: purchases
					.filter(p => p.date === cursor.format("YYYY-MM-DD"))
					.map(p => p.amount)
					.reduce((p, c) => p + c, 0)
			});
			cursor.add(1, "day");
		}

		return data;
	}, [props.purchases]);

	return (
		<Box gap="small" flex>
			<SlimHeading level="4">Total Items Purchased (last 7 days)</SlimHeading>
			<DataChart
				detail
				bounds="align"
				size={{ width: "large" }}
				data={unitSaleData}
				series={["date", { property: "amount", prefix: "" }]}
				chart={[
					{ property: "amount", type: "line", opacity: "medium", thickness: "xsmall", round: true },
					{ property: "amount", type: "point", point: "circle", thickness: "small" }
				]}
				guide={{ x: { granularity: "fine" } }}
				axis={{ x: "date", y: { property: "amount", granularity: "fine" } }}
			/>
			<SlimHeading level="4">Total Purchase Amount (last 7 days)</SlimHeading>
			<DataChart
				detail
				bounds="align"
				size={{ width: "large" }}
				data={volumeSaleData}
				series={["date", { property: "amount", prefix: "$" }]}
				chart={[
					{ property: "amount", type: "line", opacity: "medium", thickness: "xsmall", round: true },
					{ property: "amount", type: "point", point: "circle", thickness: "small" }
				]}
				guide={{ x: { granularity: "fine" } }}
				axis={{ x: "date", y: { property: "amount", granularity: "fine" } }}
			/>
		</Box>

	);
};