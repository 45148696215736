import { Box } from "grommet";
import React, { useMemo } from "react";
import { selectIsLoaded, useAppSelector } from "../../../app/store";
import { useWindowDimensions } from "../hooks";

export const Main: React.FC = (props) => {
	const structure = useStructure();

	return (
		<Box style={{ minHeight: structure.minimumHeight }} fill id="main">
			{props.children}
		</Box>
	);
};

export const useStructure = () => {
	const isLoaded = useAppSelector(selectIsLoaded);
	const dimensions = useWindowDimensions();

	const footerHeight = useMemo(() => {
		return document.getElementById("footer")?.clientHeight ?? 0;
	}, [document.getElementById("footer")?.clientHeight, dimensions]);

	const headerHeight = useMemo(() => {
		return document.getElementById("header")?.clientHeight ?? 0;
	}, [document.getElementById("header")?.clientHeight, dimensions]);

	const minimumHeight = useMemo(() => {
		const currentViewport = dimensions.height;
		return currentViewport - headerHeight - footerHeight - 10;
	}, [dimensions, isLoaded]);

	return {
		footerHeight,
		headerHeight,
		minimumHeight
	};
};