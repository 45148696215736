import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
    applicationId: "867d59d3-2979-4a15-a283-1708d889046e",
    clientToken: "pub0961f3e04c5fc052354eca6d99a484d2",
    site: "datadoghq.com",
    service: "frontend",
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: process.env.REACT_APP_VERSION ?? undefined,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingOrigins: [
        "https://api.regoapp.io",
        /https:\/\/.*\.api\.regoapp\.io/
    ]
});

datadogRum.startSessionReplayRecording();