import React from "react";
import { SnackbarProvider } from "notistack";
import { Box, Button, Grommet, Layer, Text } from "grommet";
import theme from "../../../theme";
import { confirmAlert } from "react-confirm-alert";
import { SlimHeading } from "../components";

export const AlertProvider: React.FC = (props) => {

	return (
		<SnackbarProvider
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right"
			}}
			autoHideDuration={2500}
		>
			{props.children}
		</SnackbarProvider>
	);
};

interface DisplayAlertsOptions {
	closeOnClickOutside?: boolean;
	closeOnEscape?: boolean;
}

export function useAlert() {
	function display<T>(close: (callback: (result?: T) => void) => JSX.Element, options?: DisplayAlertsOptions) {
		return new Promise<T | void>((resolve) => {
			confirmAlert({
				closeOnClickOutside: options?.closeOnClickOutside === true,
				closeOnEscape: options?.closeOnEscape === true,
				customUI: ({ onClose }) => {
					return (
						<Grommet theme={theme}>
							<Layer>
								{(() => {
									return close((result) => {
										resolve(result);
										onClose();
									});
								})()}
							</Layer>
						</Grommet>
					);
				}
			});
		});
	}

	function displayConfirmAlert(title: string, message: JSX.Element): void {
		confirmAlert({
			closeOnClickOutside: true,
			closeOnEscape: true,
			customUI: ({ onClose }) => {
				return (
					<Grommet theme={theme}>
						<Layer
							onClickOutside={onClose}
							onEsc={onClose}
							style={{ zIndex: 100 }}
						>
							<Box margin="medium" gap="medium">
								<SlimHeading level={3}>{title}</SlimHeading>
								{message}
								<Button
									primary
									label={<Text weight="bold">OK</Text>}
									onClick={onClose}
									hoverIndicator
								/>
							</Box>
						</Layer>
					</Grommet>
				);
			}
		});
	}

	return {
		display,
		displayConfirmAlert
	};
}