import { Brand, Material, ServerResponse, Item, Vehicle, Market, DTO, Zone } from "../../types";
import { RequestService } from "./request";

const MARKET = "market" as const;

export const ReferenceService = {

	setMarketCode(code: string): void {
		return localStorage.setItem(MARKET, code);
	},

	getMarketCode(): string | null {
		return localStorage.getItem(MARKET);
	},

	async listVehicles(): Promise<Vehicle[]> {
		const result = await RequestService
			.get<ServerResponse<Vehicle[]>>("/api/v1/reference/vehicle");
		return result.data.result;
	},

	async listItems(): Promise<Item[]> {
		const result = await RequestService
			.get<ServerResponse<Item[]>>("/api/v1/reference/item");
		return result.data.result;
	},

	async queryItems(query: string): Promise<Item[]> {
		const result = await RequestService
			.get<ServerResponse<Item[]>>(`/api/v1/reference/item?query=${query}&_dc=${Date.now()}`);
		return result.data.result;
	},

	async listBrands(): Promise<Brand[]> {
		const result = await RequestService
			.get<ServerResponse<Brand[]>>("/api/v1/reference/brand");
		return result.data.result;
	},

	async listMaterials(): Promise<Material[]> {
		const result = await RequestService
			.get<ServerResponse<Material[]>>("/api/v1/reference/material");
		return result.data.result;
	},

	async listMarkets(): Promise<DTO<Market>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Market>[]>>("/api/v1/reference/market");
		return result.data.result;
	},

	async getZoneByZipCode(zipCode: string): Promise<DTO<Zone> | null> {
		const result = await RequestService
			.get<ServerResponse<DTO<Zone> | null>>(`/api/v1/reference/zone/${zipCode}`);
		return result.data.result;
	}
};