import { push } from "connected-react-router";
import { AppDispatch } from "../../app/store";

export function navigateToStoreDashboard(storeId: string, dispatch: AppDispatch): void {
	dispatch(push(`/partner/store/${storeId}/dashboard`));
}

export function navigateToStorePurchases(storeId: string, type: string, dispatch: AppDispatch): void {
	dispatch(push(`/partner/store/${storeId}/purchases?active=${type}`));
}

export function navigateToStoreProduct(storeId: string, productId: string, dispatch: AppDispatch): void {
	dispatch(push(`/partner/store/${storeId}/products/${productId}`));
}