import type { DTO, Estimate, Order, ServerResponse } from "../../types";
import { RequestService } from "./request";
import { CreateOrderRequest, CustomerProduct, Delivery, DeliveryWindow, Donation, DonationMedia, DTOWithoutBase, InboundReferral, OrderDelivery, OrderMilestone, OrderNote, Payment, PaymentMethod, Product, Referral, RelationExpand, Removal, Service, ServiceGratuity, ServiceInvoice, ServiceReview, Vehicle } from "@rego-app/common";
import qs from "qs";

export const OrderService = {
	async listOrders(filters: Partial<Order>, expand?: (keyof DTOWithoutBase<Order>)[]): Promise<DTO<Order>[]> {
		const result = await RequestService
			.get<ServerResponse<Order[]>>(
				`/api/v1/order/?` + qs.stringify({ ...filters, expand: (expand ?? []).join(",") })
			);
		return result.data.result;
	},

	async listUrgentOrders(): Promise<DTO<Order>[]> {
		const result = await RequestService
			.get<ServerResponse<Order[]>>(`/api/v1/order/urgent`);
		return result.data.result;
	},

	async getOrder(orderId: string, expand?: RelationExpand<Order>): Promise<DTO<Order>> {
		const result = await RequestService
			.get<ServerResponse<DTO<Order>>>(`/api/v1/order/${orderId}`, {
				params: {
					expand: (expand ?? []).join(",")
				}
			});
		return result.data.result;
	},

	async getOrderReferrals(orderId: string, filters: Partial<Referral>, expand: RelationExpand<Referral>): Promise<DTO<Referral>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Referral>[]>>(`/api/v1/order/${orderId}/referral`, {
				params: {
					...filters,
					expand: (expand ?? []).join(",")
				}
			});
		return result.data.result;
	},

	async getOrderInboundReferral(orderId: string, expand: RelationExpand<InboundReferral>): Promise<DTO<InboundReferral> | null> {
		const result = await RequestService
			.get<ServerResponse<DTO<InboundReferral>>>(`/api/v1/order/${orderId}/referral/inbound`, {
				params: {
					expand: (expand ?? []).join(",")
				}
			});
		return result.data.result;
	},

	async getService(serviceId: string): Promise<Service> {
		const result = await RequestService
			.get<ServerResponse<Service>>(`/api/v1/service/${serviceId}?expand=order,order.customer,store,products,delivery,delivery.partner,estimates,payments,review,gratuity,gratuity.payment`);
		return result.data.result;
	},

	async updateService(serviceId: string, data: Partial<DTO<Service>>): Promise<Service> {
		const result = await RequestService.patch<ServerResponse<Service>>(
			`/api/v1/service/${serviceId}`,
			data
		);
		return result.data.result;
	},

	async addProductToService(serviceId: string, productId: string): Promise<DTO<Product>> {
		const result = await RequestService
			.put<ServerResponse<DTO<Product>>>(`/api/v1/service/${serviceId}/product/${productId}`);
		return result.data.result;
	},

	async removeProductFromService(serviceId: string, productId: string): Promise<DTO<Product>> {
		const result = await RequestService
			.delete<ServerResponse<DTO<Product>>>(`/api/v1/service/${serviceId}/product/${productId}`);
		return result.data.result;
	},

	async createServiceEstimate(orderId: string, serviceId: string, vehicle: DTO<Vehicle>, window: DeliveryWindow, requiresHelper: boolean, price?: number, vendorPrice?: number): Promise<Estimate> {
		const result = await RequestService
			.put<ServerResponse<Estimate>>(`/api/v1/order/${orderId}/service/${serviceId}/estimate`, {
				requires_helper: requiresHelper,
				vehicle,
				window,
				price,
				vendor_price: vendorPrice
			});
		return result.data.result;
	},

	async createServiceEstimates(serviceId: string, from: Date, to: Date): Promise<Estimate[]> {
		const result = await RequestService
			.post<ServerResponse<Estimate[]>>(`/api/v1/service/${serviceId}/estimate`, {
				from: from.toISOString(),
				to: to.toISOString()
			});
		return result.data.result;
	},

	async createOrder(data: CreateOrderRequest): Promise<Order> {
		console.debug("Creating order with payload", data);
		const result = await RequestService.post<ServerResponse<Order>>(
			`/api/v1/order/`,
			data
		);
		return result.data.result;
	},

	async updateOrder(orderId: string, data: Partial<DTO<Order>>): Promise<Order> {
		const result = await RequestService.patch<ServerResponse<Order>>(
			`/api/v1/order/${orderId}`,
			data
		);
		return result.data.result;
	},

	async createOrderPaymentIntent(orderId: string, estimateId: string): Promise<DTO<Payment>> {
		const result = await RequestService.post<ServerResponse<DTO<Payment>>>(
			`/api/v1/order/${orderId}/estimate/${estimateId}/payment`
		);
		return result.data.result;
	},

	async addProductToOrder(orderId: string, productId: string): Promise<DTO<CustomerProduct>> {
		const result = await RequestService.put<ServerResponse<DTO<CustomerProduct>>>(
			`/api/v1/order/${orderId}/product/${productId}`
		);
		return result.data.result;
	},

	async removeProductFromOrder(orderId: string, productId: string, includeCustomerNotification: boolean): Promise<DTO<CustomerProduct>> {
		const result = await RequestService.patch<ServerResponse<DTO<CustomerProduct>>>(
			`/api/v1/order/${orderId}/product/${productId}`, {
			include_customer_notification: includeCustomerNotification
		}
		);
		return result.data.result;
	},

	async submitOrder(orderId: string): Promise<DTO<Order>> {
		const result = await RequestService.post<ServerResponse<DTO<Order>>>(
			`/api/v1/order/${orderId}/submit`
		);
		return result.data.result;
	},

	async cancelOrder(orderId: string): Promise<DTO<Order>> {
		const result = await RequestService.post<ServerResponse<DTO<Order>>>(
			`/api/v1/order/${orderId}/cancel`
		);
		return result.data.result;
	},

	async completeOrder(orderId: string): Promise<DTO<Order>> {
		const result = await RequestService.post<ServerResponse<DTO<Order>>>(
			`/api/v1/order/${orderId}/complete`
		);
		return result.data.result;
	},

	async updateOrderMilestone(orderId: string, milestone: OrderMilestone): Promise<DTO<Order>> {
		const result = await RequestService.post<ServerResponse<DTO<Order>>>(
			`/api/v1/order/${orderId}/milestone`, {
			milestone
		}
		);
		return result.data.result;
	},

	async listOrderProducts(orderId: string, filters: Partial<DTO<CustomerProduct>>, expand?: (keyof DTO<CustomerProduct>)[]): Promise<DTO<CustomerProduct>[]> {
		const result = await RequestService.get<ServerResponse<DTO<CustomerProduct>[]>>(
			`/api/v1/order/${orderId}/product`,
			{
				params: {
					...filters,
					expand: (expand ?? []).join(",")
				}
			}
		);
		return result.data.result;
	},

	async listOrderRemovals(orderId: string, filters: Partial<DTO<Removal>>, expand?: RelationExpand<Removal>): Promise<DTO<Removal>[]> {
		const result = await RequestService.get<ServerResponse<DTO<Removal>[]>>(
			`/api/v1/order/${orderId}/removal`,
			{
				params: {
					...filters,
					expand: (expand ?? []).join(",")
				}
			}
		);
		return result.data.result;
	},

	async listOrderDonations(orderId: string, filters: Partial<DTO<Donation>>, expand?: RelationExpand<Donation>): Promise<DTO<Donation>[]> {
		const result = await RequestService.get<ServerResponse<DTO<Donation>[]>>(
			`/api/v1/order/${orderId}/donation`,
			{
				params: {
					...filters,
					expand: (expand ?? []).join(",")
				}
			}
		);
		return result.data.result;
	},

	async createStoreDonation(orderId: string, storeId: string, data: CreateStoreDonationRequest): Promise<DTO<Donation>> {
		const result = await RequestService.put<ServerResponse<DTO<Donation>>>(
			`/api/v1/order/${orderId}/store/${storeId}/donation`,
			data
		);
		return result.data.result;
	},

	async createOrderNote(orderId: string, value: string): Promise<DTO<OrderNote>> {
		const result = await RequestService.put<ServerResponse<DTO<OrderNote>>>(
			`/api/v1/order/${orderId}/note`,
			{ value }
		);
		return result.data.result;
	},

	async createServiceDelivery(orderId: string, serviceId: string, vehicle: DTO<Vehicle>, requiresHelper: boolean, products: DTO<Product>[]): Promise<DTO<OrderDelivery>> {
		const result = await RequestService.put<ServerResponse<DTO<OrderDelivery>>>(
			`/api/v1/order/${orderId}/service/${serviceId}/delivery`, {
			vehicle,
			products,
			requires_helper: requiresHelper
		}
		);
		return result.data.result;
	},

	async createServicePaymentIntent(orderId: string, serviceId: string, estimateId: string, paymentMethod: DTO<PaymentMethod>): Promise<DTO<Payment>> {
		const result = await RequestService.post<ServerResponse<DTO<Payment>>>(
			`/api/v1/order/${orderId}/service/${serviceId}/estimate/${estimateId}/payment`, {
			payment_method: paymentMethod
		}
		);
		return result.data.result;
	},

	async selectServiceEstimate(orderId: string, serviceId: string, estimateId: string): Promise<DTO<Estimate>> {
		const result = await RequestService.post<ServerResponse<DTO<Estimate>>>(
			`/api/v1/order/${orderId}/service/${serviceId}/estimate/${estimateId}/select`
		);
		return result.data.result;
	},

	async scheduleServiceDelivery(serviceId: string, window: DeliveryWindow): Promise<DTO<Delivery>> {
		const result = await RequestService.put<ServerResponse<DTO<Delivery>>>(
			`/api/v1/service/${serviceId}/schedule`, {
			window
		}
		);
		return result.data.result;
	},

	async createOrderRemoval(orderId: string, vehicle: DTO<Vehicle>, products: DTO<Product>[]): Promise<DTO<Removal>> {
		const result = await RequestService.put<ServerResponse<DTO<Removal>>>(
			`/api/v1/order/${orderId}/removal`, {
			products,
			vehicle
		}
		);
		return result.data.result;
	},

	async startService(serviceId: string): Promise<DTO<Service>> {
		const result = await RequestService.post<ServerResponse<DTO<Service>>>(
			`/api/v1/service/${serviceId}/start`
		);
		return result.data.result;
	},

	async acceptService(serviceId: string): Promise<DTO<Service>> {
		const result = await RequestService.post<ServerResponse<DTO<Service>>>(
			`/api/v1/service/${serviceId}/accept`
		);
		return result.data.result;
	},

	async completeService(serviceId: string): Promise<DTO<Service>> {
		const result = await RequestService.post<ServerResponse<DTO<Service>>>(
			`/api/v1/service/${serviceId}/complete`
		);
		return result.data.result;
	},

	async listDonations(filters?: Partial<DTO<Donation>>, expand?: RelationExpand<Donation>): Promise<DTO<Donation>[]> {
		const result = await RequestService.get<ServerResponse<DTO<Donation>[]>>(
			`/api/v1/donation/`, {
			params: {
				...(filters ?? {}),
				expand: expand?.join(",") ?? undefined
			}
		}
		);
		return result.data.result;
	},

	async listDonationMedia(donationId: string): Promise<DTO<DonationMedia>[]> {
		const result = await RequestService.get<ServerResponse<DTO<DonationMedia>[]>>(`/api/v1/donation/${donationId}/media`);
		return result.data.result;
	},

	async putDonationMedia(donationId: string, media: DTO<DonationMedia>): Promise<DonationMedia> {
		const result = await RequestService
			.put<ServerResponse<DonationMedia>>(`/api/v1/donation/${donationId}/media/`, {
				...media,
				id: undefined
			});
		return result.data.result;
	},

	async listRemovals(filters?: Partial<DTO<Removal>>, expand?: RelationExpand<Removal>): Promise<DTO<Removal>[]> {
		const result = await RequestService.get<ServerResponse<DTO<Removal>[]>>(
			`/api/v1/removal/`, {
			params: {
				...(filters ?? {}),
				expand: expand?.join(",") ?? undefined
			}
		}
		);
		return result.data.result;
	},

	async getMediaContent(donationId: string, mediaId: string): Promise<string> {
		const result = await RequestService
			.get<ServerResponse<string>>(`/api/v1/donation/${donationId}/media/${mediaId}/content`);
		return result.data.result;
	},

	async getMediaURL(donationId: string, mediaId: string): Promise<string> {
		const result = await RequestService
			.get<ServerResponse<string>>(`/api/v1/donation/${donationId}/media/${mediaId}/view`);
		return result.data.result;
	},

	async createServiceReview(serviceId: string, rating: number, comments: string): Promise<DTO<ServiceReview>> {
		const result = await RequestService
			.put<ServerResponse<DTO<ServiceReview>>>(`/api/v1/service/${serviceId}/review`, {
				rating,
				comments
			});
		return result.data.result;
	},

	async createServiceGratuity(serviceId: string, amount: number): Promise<DTO<ServiceGratuity>> {
		const result = await RequestService
			.put<ServerResponse<DTO<ServiceGratuity>>>(`/api/v1/service/${serviceId}/tip`, {
				amount
			});
		return result.data.result;
	},

	async listServiceInvoices(serviceId: string, expand?: RelationExpand<ServiceInvoice>): Promise<DTO<ServiceInvoice>[]> {
		const result = await RequestService.get<ServerResponse<DTO<ServiceInvoice>[]>>(
			`/api/v1/service/${serviceId}/invoice`, {
			params: {
				expand: expand?.join(",") ?? undefined
			}
		}
		);
		return result.data.result;
	},

	async updateServiceInvoice(serviceId: string, invoiceId: string, data: Partial<ServiceInvoice>): Promise<DTO<ServiceInvoice>> {
		const result = await RequestService.patch<ServerResponse<DTO<ServiceInvoice>>>(
			`/api/v1/service/${serviceId}/invoice/${invoiceId}`, {
			...data,
		});
		return result.data.result;
	}
};

interface CreateStoreDonationRequest {
	products: string[];
	vehicle: DTO<Vehicle>;
	requires_helper?: boolean;
};