import { ProductIntent, DTO, Service, Donation, DonationMedia, DonationMediaContext, ListingRemovedReason } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, Card, CardBody, CardHeader, FormField, Grid, Image, Text, TextInput } from "grommet";
import moment from "moment";
import { useAppDispatch } from "../../../app/store";
import { SlimHeading } from "../../common";
import { config } from "../../../app/config";
import { useEffect, useMemo, useState } from "react";
import { formatCurrency, isDonation } from "../../../helpers";
import { DonationMediaItem } from "../../admin/components";
import { OrderService } from "../../../app/services";

const ServiceReadyToSchedule: React.FC<ServiceCardBaseProps> = (props) => {
	const dispatch = useAppDispatch();

	const estimate = useMemo(() => {
		return (props.service.estimates ?? []).find(e => e.selected);
	}, [props.service]);

	const hasSellGoal = useMemo(() => {
		return (props.service.products ?? []).some(p => p.goal === ProductIntent.SELL && p.removed_reason !== ListingRemovedReason.EXPIRED);
	}, [props.service]);

	return (
		<Box gap="small">
			{estimate && (
				<Box align="center" gap="small">
					<SlimHeading level="3">Pickup Quote</SlimHeading>
					<SlimHeading level="3">
						{formatCurrency(estimate.total_amount)}
					</SlimHeading>
				</Box>
			)}
			<Box>
				<SlimHeading level="5">{props.contentReadyToSchedule}</SlimHeading>
			</Box>
			<Box align="center">
				<Button
					primary
					label="Schedule Now"
					onClick={() => {
						dispatch(push(`/schedule?serviceId=${props.service.id}`));
					}}
				/>
			</Box>
			{hasSellGoal && (
				<Box>
					<Text>
						For more information on why your item(s) were not approved for sale, please check your email
					</Text>
				</Box>
			)}
		</Box>
	);
};

const ServiceScheduled: React.FC<ServiceCardBaseProps> = (props) => {
	function getFormattedDate(): string {
		return moment(props.service.scheduled_window.date).format("MMMM DD, YYYY");
	}

	function getFormattedTime(): string {
		const window = props.service.scheduled_window;
		const from = moment(window.date).set("hours", window.from).format("hh:mm A");
		const to = moment(window.date).set("hours", window.to).format("hh:mm A");

		return `${from} to ${to}`;
	}

	return (
		<Box gap="small">
			<Box>
				<SlimHeading level="5">{props.contentScheduled}</SlimHeading>
			</Box>
			<Box align="start">
				<Button label="Call Us" href={`tel:${config.contact.phone}`} />
			</Box>
			<Box align="start">
				<FormField
					label="Pickup Date"
					contentProps={{ border: undefined }}
				>
					<TextInput
						value={getFormattedDate()}
					/>
				</FormField>
				<FormField
					label="Pickup Window"
					contentProps={{ border: undefined }}
				>
					<TextInput
						value={getFormattedTime()}
					/>
				</FormField>
				{/* <FormField
						label="Organization Name"
						contentProps={{ border: undefined }}
					>
						<Box pad="small">
							<SlimHeading level="4">
								{props.donation.store.name}
							</SlimHeading>
						</Box>
					</FormField> */}
			</Box>
		</Box>
	);
};

const ServiceStarted: React.FC<ServiceCardBaseProps> = (props) => {
	return (
		<Box gap="small">
			<Box>
				<SlimHeading level="5">{props.contentInProgress}</SlimHeading>
			</Box>
		</Box>
	);
};

const ServiceCompleted: React.FC<ServiceCardBaseProps> = (props) => {
	const [media, setMedia] = useState<DTO<DonationMedia>[]>([]);

	useEffect(() => {
		if(props.service && isDonation(props.service)) {
			OrderService.listDonationMedia(props.service.id)
				.then(res => {
					setMedia([...res]);
				})
				.catch(err => {
					console.error("Failed to load donation media", err);
				});
		}
	}, [props.service]);

	return (
		<Box gap="medium">
			<Box>
				<SlimHeading level="5">{props.contentCompleted}</SlimHeading>
			</Box>
			{isDonation(props.service) && media.some(m => m.donation_context === DonationMediaContext.RECEIPT) && (
				<Box>
					<SlimHeading level="4">Donation Receipt</SlimHeading>
					<Box gap="small" margin="small">
						{media.filter(m => m.donation_context === DonationMediaContext.RECEIPT).map(m => (
							<DonationMediaItem
								key={m.id}
								donation={props.service as DTO<Donation>}
								media={m}
							/>
						))}
					</Box>
				</Box>
			)}
		</Box>
	);
};

interface ServiceCardBaseProps {
	intent: ProductIntent;
	service: DTO<Service>;
	serviceLabel: string;
	contentCompleted: string;
	contentScheduled: string;
	contentInProgress: string;
	contentReadyToSchedule: string;
}

interface ServiceCardContentProps extends ServiceCardBaseProps {

}

export const ServiceCardContent: React.FC<ServiceCardContentProps> = (props) => {
	if(props.service.completed) {
		return (
			<ServiceCompleted
				{...props}
			/>
		);
	}

	if(props.service.started) {
		return (
			<ServiceStarted
				{...props}
			/>
		);
	}

	if(props.service.scheduled && props.service.scheduled_window) {
		return (
			<ServiceScheduled
				{...props}
			/>
		);
	}

	return (
		<ServiceReadyToSchedule
			{...props}
		/>
	);
};

interface ServiceCardProps extends ServiceCardBaseProps {
	onClickOpen(donation: DTO<Service>): void;
}

export const ServiceCard: React.FC<ServiceCardProps> = (props) => {

	function handleViewMore(): void {
		return props.onClickOpen(props.service);
	}

	return (
		<Box fill="vertical">
			<Card margin="small" fill="vertical">
				{props.service.store && (
					<CardHeader>
						<Grid columns={["1/3", "2/3"]}>
							<Box margin="small">
								<Image
									height="50px"
									alt={props.service.store.name}
									src={props.service.store.logo_url}
								/>
							</Box>
							<Box margin="small">
								<SlimHeading level="4">{props.service.store.name}</SlimHeading>
							</Box>
						</Grid>
					</CardHeader>
				)}
				<CardBody margin="medium" gap="medium" style={{ display: "inline-table" }}>
					<ServiceCardContent
						{...props}
					/>
					<Box flex="grow" align="center" justify="end">
						<Button
							label="View More"
							onClick={() => {
								handleViewMore();
							}}
						/>
					</Box>
				</CardBody>
			</Card>
		</Box>
	);
};