import { Divider } from "@mui/material";
import { push } from "connected-react-router";
import { Anchor, Box, Sidebar } from "grommet";
import { Cart, Gift, Home, Money, Trash } from "grommet-icons";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { listCustomerActionItems, selectCustomerActionItems, useAppDispatch, useAppSelector } from "../../../app/store";
import { DashboardScreen } from "../../../types";
import { SidebarButton, useScreenSize } from "../../common";

export interface SidebarButtonData {
	type: DashboardScreen;
	icon: JSX.Element;
	label: string;
	path: string;
}

export const SIDEBAR_BUTTONS: SidebarButtonData[] = [
	{
		type: DashboardScreen.HOME,
		icon: <Home />,
		label: "Home",
		path: "/dashboard"
	},
	{
		type: DashboardScreen.LISTINGS,
		icon: <Money />,
		label: "Listings",
		path: "/dashboard/listings"
	},
	{
		type: DashboardScreen.DONATIONS,
		icon: <Gift />,
		label: "Donations",
		path: "/dashboard/donations"
	},
	{
		type: DashboardScreen.REMOVALS,
		icon: <Trash />,
		label: "Removals",
		path: "/dashboard/removals"
	},
	{
		type: DashboardScreen.PURCHASES,
		icon: <Cart />,
		label: "Purchases",
		path: "/dashboard/purchases"
	}
];

export const DashboardSidebar: React.FC = () => {
	const size = useScreenSize();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const actionItems = useAppSelector(selectCustomerActionItems);

	useEffect(() => {
		dispatch(listCustomerActionItems()).unwrap()
			.catch(err => {
				console.error("Failed to load action items");
			});
	}, []);

	function handleNavigate(data: SidebarButtonData): void {
		dispatch(push(data.path));
	}

	if(size !== "small") {
		return (
			<Sidebar pad="none" id="sidebar" background="brand">
				<Box id="sidebar-content" gap="medium" pad="small">
					<Box>
						{SIDEBAR_BUTTONS.map(data => (
							<SidebarButton
								key={data.type}
								icon={data.icon}
								label={data.label}
								selected={data.path === location.pathname}
								onSelected={() => handleNavigate(data)}
							/>
						))}
					</Box>
					<Box gap="medium">
						<Divider style={{ backgroundColor: "white" }} />
						<Box align="center">
							<Anchor
								color="white"
								label={`Action Items (${actionItems.length})`}
								onClick={() => {
									dispatch(push("/dashboard/actions"));
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Sidebar >
		);
	}

	return (
		<></>
	);
};