import React, { Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCustomer, selectRequiresLogin, selectRequiresRegistration, selectUser, setFatalError, setLogin, setRequiresLogin, setRequiresRegistration } from "../../../app/store/application";
import { Login, Register } from "../components";
import { AuthService, UserService } from "../../../app/services";
import { useLocation } from "react-router-dom";
import { Loader } from "../../common";

export const AuthProvider: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const user = useAppSelector(selectUser);
	const customer = useAppSelector(selectCustomer);
	const requiresRegister = useAppSelector(selectRequiresRegistration);
	const requiresLogin = useAppSelector(selectRequiresLogin);

	useEffect(() => {
		if(user && customer) {
			if(requiresRegister) {
				setRequiresRegistration(false);
			}

			if(requiresLogin) {
				setRequiresLogin(false);
			}
		}
	}, [user, customer, requiresRegister, requiresLogin]);

	useEffect(() => {
		async function loadUserFromStorage(): Promise<void> {
			try {
				if(AuthService.getAccessToken() && AuthService.getUserId() && AuthService.getRefreshToken()) {
					console.debug("Found existing user in storage ... attempting login");
					let user = await UserService.getSelf().catch(err => {
						console.error("Failed to get user ... is token expired", err);
						return null;
					});

					console.debug("Got user", user);
					if(user) {
						const customer = await UserService.getCustomerSelf().catch(err => {
							console.error("Error getting current customer record ... refresh likely failed", err);
							return null;
						});

						dispatch(setLogin({
							user,
							customer
						}));

						return;
					}
				}

				console.debug("User not found in storage ... requesting anonymous user");
				const user = await AuthService.getAnonymousAccessToken(new URLSearchParams(location.search));
				const customer = await UserService.getCustomerSelf();

				dispatch(setLogin({
					user,
					customer
				}));
			}
			catch(e) {
				console.error("Failed during user load", e);
				dispatch(setFatalError("Something went wrong during app startup"));
			}
		}

		loadUserFromStorage();
	}, [dispatch]);

	return (
		<Loader visible={!user}>
			{requiresRegister && (
				<Register isVisible={requiresRegister} />
			)}
			{!requiresRegister && requiresLogin && (
				<Login isVisible={requiresLogin} username={AuthService.getUsername() ?? ""} />
			)}
			{props.children}
		</Loader>
	);
};