import React, { useState } from "react";
import { Anchor, Box, Button, Form, FormField, Heading, Image, Layer, MaskedInput, Spinner, TextInput, Text } from "grommet";
import regoIcon from "../../../img/icon.png";
import { AuthService, CustomerService } from "../../../app/services";
import { useAppDispatch, setLogin, setRequiresRegistration } from "../../../app/store";
import { ContactType, DTO, User } from "../../../types";
import { Mail, Phone } from "grommet-icons";
import { UtilService } from "../../../app/services";
import { ForgotPassword } from "./ForgotPassword";

interface LoginProps {
	username?: string;
	isVisible: boolean;
}

interface LoginState {
	username: string;
	password?: string;
	code?: string;
	usernameLoginType: ContactType;
	isMagicLink: boolean;
	wasMagicLinkSent: boolean;
	magicLinkSentAt: number;
	isPasswordLogin: boolean;
	isAttemptingLogin?: boolean;
	loginErrorMessage?: string;
	showForgotPassword: boolean;
}

export const Login: React.FC<LoginProps> = (props: LoginProps) => {
	const dispatch = useAppDispatch();

	const [loginState, setLoginState] = useState<LoginState>({
		username: props.username ?? "",
		isMagicLink: true,
		wasMagicLinkSent: false,
		isPasswordLogin: false,
		loginErrorMessage: "",
		magicLinkSentAt: 0,
		usernameLoginType: ContactType.PHONE,
		showForgotPassword: false
	});

	const switchLoginType = (type: "magic" | "password"): void => {
		setLoginState({
			...loginState,
			isMagicLink: type === "magic",
			isPasswordLogin: type === "password",
			loginErrorMessage: "",
			password: "",
			code: ""
		});
	};

	const toggleUsernameLoginType = (): void => {
		setLoginState({
			...loginState,
			username: "",
			usernameLoginType: loginState.usernameLoginType === ContactType.EMAIL
				? ContactType.PHONE
				: ContactType.EMAIL
		});
	};

	const sendLoginCode = async (): Promise<void> => {
		try {
			console.debug("Sending Login Token", loginState);
			//Check sent timestamp
			if(loginState.wasMagicLinkSent) {
				//can't send another code until 60 seconds
				const LIMIT = 60 * 1000;
				if(Date.now() <= LIMIT + loginState.magicLinkSentAt) {
					setLoginState({
						...loginState,
						loginErrorMessage: "You can't request another login code yet"
					});
					return;
				}
			}

			const formattedUsername = loginState.usernameLoginType === ContactType.PHONE
				? UtilService.formatPhoneToNumber(loginState.username)
				: loginState.username;

			console.debug("ABOUT TO SEND!", formattedUsername);
			if(!await AuthService.sendLoginCode(formattedUsername)) {
				throw new Error("Failed to send login code");
			};

			setLoginState({
				...loginState,
				wasMagicLinkSent: true,
				magicLinkSentAt: Date.now(),
				loginErrorMessage: ""
			});
		}
		catch(e) {
			console.error("Failed to send login code", e);
			setLoginState({
				...loginState,
				wasMagicLinkSent: false,
				loginErrorMessage: "Something wen't wrong, and we weren't able to send the code. Make sure your username is correct and try again"
			});
		}
	};

	const register = (): void => {
		dispatch(setRequiresRegistration(true));
	};

	const login = async (): Promise<void> => {
		try {
			setLoginState({
				...loginState,
				isAttemptingLogin: true
			});

			if(loginState.isMagicLink && !loginState.wasMagicLinkSent) {
				return await sendLoginCode();
			}

			let response: DTO<User> | false = false;
			if(loginState.isMagicLink) {
				response = await AuthService.loginWithCode(
					loginState.usernameLoginType === ContactType.PHONE
						? UtilService.formatPhoneToNumber(loginState.username)
						: loginState.username,
					loginState.code ?? ""
				);
			}
			else {
				response = await AuthService.login(
					loginState.usernameLoginType === ContactType.PHONE
						? UtilService.formatPhoneToNumber(loginState.username)
						: loginState.username,
					loginState.password ?? ""
				);
			}

			if(!response) {
				throw new Error("Login Failed");
			}

			dispatch(setLogin({
				user: response,
				customer: await CustomerService.getCustomerSelf()
			}));

			setLoginState({
				...loginState,
				isAttemptingLogin: false
			});
		}
		catch(e) {
			setLoginState({
				...loginState,
				isAttemptingLogin: false,
				loginErrorMessage: (loginState.isMagicLink)
					? "Incorrect verification code"
					: "Incorrect username or password"
			});
		}
	};

	return (
		<React.Fragment>
			{loginState.showForgotPassword && (
				<ForgotPassword
					onClose={() => {
						setLoginState({
							...loginState,
							showForgotPassword: false
						});
					}}
				/>
			)}
			{props.isVisible && (
				<Box overflow="auto">
					<Layer>
						<Box
							pad={{
								top: "small",
								left: "medium",
								right: "medium",
								bottom: "small"
							}}
						>
							<Box
								align="center"
								margin={{
									bottom: "medium"
								}}
							>
								<Image
									height="auto"
									width="75px"
									fill={false}
									src={regoIcon}
								/>
								<Heading
									level="3"
									margin="small"
								>Sign in With</Heading>
								<Box direction="column" gap="small" fill>
									<Button
										style={{ borderRadius: "0" }}
										onClick={() => switchLoginType("magic")}
										primary={loginState.isMagicLink}
										label="One Time Code"
									/>
									<Box align="center" justify="center">
										<Text weight="bold">OR</Text>
									</Box>
									<Button
										style={{ borderRadius: "0" }}
										onClick={() => switchLoginType("password")}
										primary={loginState.isPasswordLogin}
										label="Password"
									/>
								</Box>
							</Box>
							<Form
								messages={{
									required: "This field is required"
								}}
								value={loginState}
								onChange={nextValue => setLoginState({
									...nextValue,
									loginErrorMessage: ""
								})}
								onSubmit={({ value }) => {
									login().catch(err => {
										console.error("Failed at login", err);
									});
								}}
							>
								<Box gap="small" direction="column">
									<Box gap="none" direction="column">
										<FormField
											margin="xsmall"
											required
											name="username"
											htmlFor="username"
											label={
												loginState.usernameLoginType === ContactType.EMAIL
													? "Email Address"
													: "Phone Number"
											}
											readOnly={loginState.wasMagicLinkSent}
											error={loginState.isMagicLink && !loginState.wasMagicLinkSent && loginState.loginErrorMessage}
											info={
												<Anchor
													onClick={toggleUsernameLoginType}
												>
													{loginState.usernameLoginType === ContactType.EMAIL
														? "Login with phone instead?"
														: "Login with email instead?"
													}
												</Anchor>}
										>
											{loginState.usernameLoginType === ContactType.EMAIL
												? (<MaskedInput
													id="username"
													name="username"
													inputMode={"email"}
													required
													mask={[
														{
															regexp: /^[\w\-_.]+$/,
															placeholder: 'example',
														},
														{ fixed: '@' },
														{
															regexp: /^[\w]+$/,
															placeholder: 'rego-app',
														},
														{
															fixed: '.'
														},
														{
															regexp: /^[\w]+$/,
															placeholder: 'com',
														},
													]}
													icon={<Mail />}
												/>)
												: (<MaskedInput
													id="username"
													name="username"
													required
													inputMode={"tel"}
													mask={[
														{ fixed: '1 (' },
														{
															length: 3,
															regexp: /^[0-9]{1,3}$/,
															placeholder: 'xxx',
														},
														{ fixed: ')' },
														{ fixed: ' ' },
														{
															length: 3,
															regexp: /^[0-9]{1,3}$/,
															placeholder: 'xxx',
														},
														{ fixed: '-' },
														{
															length: 4,
															regexp: /^[0-9]{1,4}$/,
															placeholder: 'xxxx',
														},
													]}
													icon={<Phone />}
												/>)
											}
										</FormField>

										{loginState.isMagicLink && loginState.wasMagicLinkSent && (
											<Box gap="small" direction="column">
												<FormField
													margin="xsmall"
													required
													name="code"
													htmlFor="code"
													label="Login Code"
													error={loginState.loginErrorMessage}
												>
													<TextInput autoComplete="one-time-code" id="code" type="text" name="code" pattern="[0-9]{6}" />
												</FormField>
												<Box direction="column" align="start">
													<Anchor onClick={sendLoginCode}>Send another code?</Anchor>
												</Box>
											</Box>
										)}

										{loginState.isPasswordLogin && (
											<Box gap="small" direction="column">
												<FormField
													margin="xsmall"
													required
													name="password"
													htmlFor="password"
													label="Password"
													error={loginState.loginErrorMessage}
													info={<Anchor
														onClick={() => {
															setLoginState({
																...loginState,
																showForgotPassword: true
															});
														}}
													>Forgot?</Anchor>}
												>
													<TextInput id="password" type="password" name="password" />
												</FormField>
											</Box>
										)}
									</Box>
									<Box direction="column" gap="small" justify="center" align="center">
										<Button
											type="submit"
											icon={loginState.isAttemptingLogin ? <Spinner height="inherit" size="xsmall" /> : undefined}
											primary
											disabled={loginState.isAttemptingLogin}
											label={"Sign In"}
										/>
										<Anchor onClick={register}>Create Account</Anchor>
									</Box>
								</Box>
							</Form>
						</Box>
					</Layer>
				</Box>
			)}
		</React.Fragment>
	);
};