import { DTO, Payment, RelationExpand } from "@rego-app/common";
import { ServerResponse } from "../../types";
import { RequestService } from "./request";

export const PaymentService = {
	async listPayments(filters: Partial<DTO<Payment>>, expand: RelationExpand<Payment>): Promise<DTO<Payment>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Payment>[]>>(`/api/v1/payment`, {
				params: {
					...filters,
					expand: expand.join(",")
				}
			});
		return result.data.result;
	},

	async getPayment(paymentId: string, expand: RelationExpand<Payment>): Promise<DTO<Payment>> {
		const result = await RequestService
			.get<ServerResponse<DTO<Payment>>>(`/api/v1/payment/${paymentId}`, {
				params: {
					expand: expand.join(",")
				}
			});
		return result.data.result;
	},

	async getPaymentClientSecret(paymentId: string): Promise<string> {
		const result = await RequestService
			.get<ServerResponse<string>>(`/api/v1/payment/${paymentId}/secret`);
		return result.data.result;
	},

	async capturePayment(paymentId: string, amount: number): Promise<DTO<Payment>> {
		const result = await RequestService
			.post<ServerResponse<DTO<Payment>>>(`/api/v1/payment/${paymentId}/capture`, {
				amount_captured: amount
			});
		return result.data.result;
	},
};