import { DeliveryType, DTO, Product, ProductDimensions, UnionProduct } from "../../../types";
import { Anchor, Box, Button, FormField, Grid, Spinner, Text, TextInput } from "grommet";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { addProductToCart, removeProductFromCart, selectCartProducts, setShouldBeginCheckout } from "../../../app/store/purchase";
import { ImageCarousel, Loader, LoadingButton, SlimHeading, useScreenSize, useWindowDimensions } from "../../common";
import { goBack, push } from "connected-react-router";
import { ProductService } from "../../../app/services";
import { useSnackbar } from "notistack";
import { ProductGallery } from "../components";
import { formatCurrency } from "../../../helpers";
import { isStoreUserAdmin } from "../../../app/store/partners";
import { navigateToStoreProduct } from "../../partner/helpers";

export const ProductPage: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const size = useScreenSize();
	const [product, setProduct] = useState<DTO<UnionProduct> | null>(null);
	const isAdmin = useAppSelector(isStoreUserAdmin);


	const { productId } = useParams();

	function handleFetchProduct(productId: string): void {
		ProductService.getUnionProduct(productId, ["store"])
			.then(product => {
				setProduct({ ...product });
			})
			.catch(err => {
				console.error("Failed to load product", err);
				snack.enqueueSnackbar("We ran into an issue loading this item", { variant: "error" });
			});
	}

	useEffect(() => {
		if(!productId) {
			dispatch(push("/shop"));
			return;
		}

		handleFetchProduct(productId);
	}, [productId]);

	return (
		<Box margin="medium" gap="large">
			<Box gap="medium">
				<Box justify="between" direction="row">
					<Anchor
						label="Go Back"
						onClick={() => {
							dispatch(goBack());
						}}
					/>
					{(isAdmin && product?.store) && (
						<Anchor
							color="accent-1"
							label="Edit Product"
							onClick={() => {
								navigateToStoreProduct(product?.store?.id ?? "", product.id, dispatch);
							}}
						/>
					)}
				</Box>
				<Loader visible={!product}>
					{product && (
						<Grid
							columns={{ count: size === "small" ? 1 : 2, size: "auto" }}
						>
							<Box flex>
								<ImageCarousel
									width={"100%"}
									height={"100%"}
									product={product}
								/>
							</Box>
							<Box flex>
								<ProductDetails
									product={product}
								/>
							</Box>
						</Grid>
					)}
				</Loader>
			</Box>
			<Box>
				{product && (
					<SimilarProducts
						product={product}
					/>
				)}
			</Box>
		</Box>
	);
};

export const ProductDetails: React.FC<{ product: DTO<Product>; }> = (props) => {
	const dispatch = useAppDispatch();
	const snack = useSnackbar();
	const cart = useAppSelector(selectCartProducts);
	const { size } = useWindowDimensions();
	const [isLoading, setIsLoading] = useState(false);
	const [isUpdatingCart, setIsUpdatingCart] = useState(false);

	function handleNavigateToCart(): void {
		dispatch(push("/shop/cart"));
	}

	const isInCart = useMemo(() => {
		return cart.findIndex(p => p.id === props.product.id) !== -1;
	}, [props.product, cart]);

	function displayAddedToCartMessage(): void {
		snack.enqueueSnackbar(
			<Text>Successfully added to cart. <Anchor onClick={handleNavigateToCart} color="white">Checkout now?</Anchor></Text>, {
			variant: "success"
		});
	}

	function displayRemovedFromCartMessage(): void {
		snack.enqueueSnackbar(
			<Text>Successfully removed from cart.</Text>, {
			variant: "success"
		});
	}

	function handleAddOrRemoveFromCart(): void {
		setIsUpdatingCart(true);

		if(isInCart) {
			dispatch(removeProductFromCart(props.product)).unwrap()
				.then(res => {
					displayRemovedFromCartMessage();
				})
				.catch(err => {
					console.error(`Failed to remove product [${props.product.id}] from cart`, err);
				})
				.finally(() => {
					setIsUpdatingCart(false);
				});
		}
		else {
			dispatch(addProductToCart(props.product)).unwrap()
				.then(res => {
					displayAddedToCartMessage();
				})
				.catch(err => {
					console.error(`Failed to add product [${props.product.id}] to cart`, err);
				})
				.finally(() => {
					setIsUpdatingCart(false);
				});
		}
	}

	function handleBuyNow(): void {
		setIsLoading(true);
		dispatch(addProductToCart(props.product)).unwrap()
			.catch(err => {
				console.error("failed to add product to card", props.product, err);
			})
			.then(() => {
				dispatch(setShouldBeginCheckout(true));
				dispatch(push("/shop/cart"));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	return (
		<Box gap="medium" margin="medium" flex>
			<SlimHeading level="3">
				{props.product.title}
			</SlimHeading>
			<Text>
				{props.product.description}
			</Text>
			<Box align="center" justify="center" margin="medium">
				<SlimHeading level="3">
					{formatCurrency(props.product.price)}
				</SlimHeading>
				{(props.product.delivery_types ?? []).includes(DeliveryType.DELIVERY) && (
					<SlimHeading level="5">
						+ Delivery
					</SlimHeading>
				)}
			</Box>
			<Box gap="small">
				{props.product.brand && (
					<ProductLabel
						name="Brand"
						content={props.product.brand.name}
					/>
				)}
				{props.product.item?.short_name && (
					<ProductLabel
						name="Category"
						content={props.product.item.short_name}
					/>
				)}
				{props.product.condition && (
					<ProductLabel
						name="Condition"
						content={props.product.condition}
					/>
				)}
				{props.product.dimensions?.width > 0 && (
					<Dimensions
						dimensions={props.product.dimensions}
					/>
				)}
			</Box>
			<Box flex align="end">
				<Box direction="row" align="end" justify={size === "small" ? "between" : "end"} gap="small" fill>
					<LoadingButton
						isLoading={isUpdatingCart}
						color={isInCart ? "status-error" : undefined}
						label={isInCart ? "Remove from Cart" : "Add to Cart"}
						onClick={handleAddOrRemoveFromCart}
					/>
					<Button
						label="Buy Now"
						disabled={isLoading}
						icon={isLoading ? <Spinner /> : undefined}
						primary
						onClick={handleBuyNow}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export const ProductLabel: React.FC<{ name: string, content: string; }> = (props) => {
	return (
		<>
			<Text>
				<Text weight="bold">
					{props.name}:&nbsp;
				</Text>
				{props.content}
			</Text>
		</>
	);
};

export const Dimensions: React.FC<{ dimensions: ProductDimensions; }> = (props) => {
	const content = useMemo(() => {
		if(props.dimensions.depth && props.dimensions.width && props.dimensions.height) {
			return `Width: ${props.dimensions.width} ${props.dimensions.unit}, Height: ${props.dimensions.height} ${props.dimensions.unit}, Depth: ${props.dimensions.depth} ${props.dimensions.unit}`;
		}

		return "Not Provided";
	}, [props.dimensions]);
	return (
		<ProductLabel
			name="Dimensions"
			content={content}
		/>
	);
};

export const SimilarProducts: React.FC<{ product: DTO<Product>; }> = (props) => {
	const size = useScreenSize();
	const [columnSize, setColumnSize] = useState(4);
	const [wasLoaded, setWasLoaded] = useState(false);
	const [hasError, setHasError] = useState("");
	const [sameSellerProducts, setSameSellerProducts] = useState<DTO<Product>[]>([]);

	useEffect(() => {
		if(size === "small") {
			setColumnSize(1);
			return;
		}

		if(size === "medium") {
			setColumnSize(3);
			return;
		}

		setColumnSize(4);
		return;
	}, [size]);

	function handleFetchSameSellerProducts(productId: string): void {
		ProductService.getProductsFromSameSeller(productId)
			.then(products => {
				setSameSellerProducts([...products]);
			})
			.catch(err => {
				console.error("Failed to load similar products", err);
				setHasError("Failed to load similar products");
			})
			.finally(() => {
				setWasLoaded(true);
			});
	}

	useEffect(() => {
		if(props.product) {
			handleFetchSameSellerProducts(props.product.id);
		}
	}, [props.product]);

	return (
		<Loader visible={!wasLoaded}>
			{(!hasError && sameSellerProducts.length > 0) && (
				<Box gap="medium">
					<Box>
						<SlimHeading level="4">
							View other items from the same seller
						</SlimHeading>
						<Text>
							Want to save on delivery? Take a look at other items for sale from the same seller.
						</Text>
					</Box>
					<ProductGallery
						step={columnSize}
						products={sameSellerProducts}
						hasNextPage={false}
					/>
				</Box>
			)}
		</Loader>
	);
};