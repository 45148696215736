import { push } from "connected-react-router";
import { Box, Button, Form, FormField, TextInput } from "grommet";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppDispatch } from "../../../app/store";
import { fetchClientSecret, fetchPayment } from "../../../app/store/checkout";

export const TestPayment: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();

	const [state, setState] = useState({
		paymentId: ""
	});

	function doPaymentFlow(): void {
		dispatch(fetchPayment({ paymentId: state.paymentId, expandPaymentMethod: true })).unwrap()
			.then(res => {
				console.debug(res);
				snack.enqueueSnackbar("Successfully fetched payment ... getting client secret", {
					variant: "success"
				});

				return dispatch(fetchClientSecret({ paymentId: state.paymentId }));
			})
			.then((secret) => {
				console.debug("GOT SECRET", secret);
				snack.enqueueSnackbar("Successfully fetched client secret", {
					variant: "success"
				});

				dispatch(push("/checkout"));
			})
			.catch(err => {
				console.error(err);
				snack.enqueueSnackbar("Failed during payment flow", {
					variant: "error"
				});
			});
	}

	return (
		<Box margin="large">
			<Form
				value={state}
				onChange={(changes) => {
					setState({
						...state,
						...changes
					});
				}}
				onSubmit={() => {
					doPaymentFlow();
				}}
			>
				<Box gap="small">
					<FormField
						label="Payment Id"
						name="paymentId"
						required
					>
						<TextInput
							name="paymentId"
						/>
					</FormField>
					<Box align="center">
						<Button
							type="submit"
							label="Submit"
						/>
					</Box>
				</Box>
			</Form>
		</Box>
	);
};