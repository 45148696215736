import { DeliveryAddress, Address, Geolocation, States } from "../../../types";
import { Box, CheckBox, Select, Form, FormField, Text, TextArea, TextInput } from "grommet";
import { Fragment, useEffect, useState } from "react";
import { useAddressAutocomplete } from "../../common/hooks/useAddressAutocomplete";
import { Timezone } from "@rego-app/common";
import { getStandardFormValidations } from "../../../helpers";

interface AddressFormProps {
	addressOnly?: boolean;
	address: Partial<DeliveryAddress>;
	onFormSubmit(address: DeliveryAddress): void;
	onAddressSelected(address: DeliveryAddress): void;
}

interface AddressFormState extends DeliveryAddress {

}

export const AddressForm: React.FC<AddressFormProps> = (props) => {
	const addressAutoComplete = useAddressAutocomplete();

	const [state, setState] = useState<AddressFormState>({
		address_line_one: props.address.address_line_one ?? "",
		address_line_two: props.address.address_line_two ?? "",
		city: props.address.city ?? "",
		state: props.address.state as States.Abbreviation,
		timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone.Code,
		zip: props.address.zip ?? "",
		zip_plus_four: props.address.zip_plus_four ?? "",
		floor: props.address.floor || 1,
		has_driveway: props.address.has_driveway || false,
		has_elevator: props.address.has_elevator || false,
		has_parking: props.address.has_elevator || false,
		notes: props.address.notes ?? "",
		latitude: "",
		longitude: ""
	});

	useEffect(() => {
		if(props.address) {
			setState({
				...state,
				...props.address
			});
		}
	}, [props.address]);

	function handleAddressSelected(address: Address & Geolocation): void {
		setState({
			...state,
			address_line_one: address.address_line_one ?? state.address_line_one,
			city: address.city ?? state.city,
			state: address.state ?? state.state,
			zip: address.zip ?? state.zip,
			latitude: address.latitude ?? state.latitude,
			longitude: address.longitude ?? state.longitude,
		});

		props.onAddressSelected({
			...state,
			...address
		});
	}

	function updateFormState(changes: AddressFormState): void {
		const updatedState = {
			...state,
			...changes
		};

		setState(updatedState);
		//props.onAddressSelected(updatedState);
	}

	useEffect(() => {
		if(addressAutoComplete.ready) {
			addressAutoComplete.initialize(
				document.querySelector("#address_line_one") as HTMLInputElement,
				(address) => {
					handleAddressSelected(address);
				}
			);
		}
	}, [addressAutoComplete.ready]);

	return (
		<Box>
			<Form
				value={state}
				validate="submit"
				onChange={updateFormState}
				onSubmit={() => {
					props.onFormSubmit(state);
				}}
			>
				<Box gap="small">
					<FormField
						label="Street Address"
						name="address_line_one"
						htmlFor="address_line_one"
						validate={[
							...getStandardFormValidations()
						]}
					>
						<TextInput
							name="address_line_one"
							id="address_line_one"
						/>
					</FormField>
					<FormField
						label="Unit / Apt #"
						name="address_line_two"
						htmlFor="address_line_two"
					>
						<TextInput name="address_line_two" id="address_line_two" />
					</FormField>
					<FormField
						label="City"
						name="city"
						htmlFor="city"
						validate={[
							...getStandardFormValidations()
						]}
					>
						<TextInput name="city" id="city" />
					</FormField>
					<Box direction="row-responsive" gap="small" fill="horizontal">
						<Box flex>
							<FormField
								label="State"
								name="state"
								htmlFor="state"
								validate={[
									...getStandardFormValidations()
								]}
							>
								<Select
									name="state"
									id="state"
									options={States.getAbbreviations()}
								/>
							</FormField>
						</Box>
						<Box flex>
							<FormField
								label="Zip Code"
								name="zip"
								htmlFor="zip"
								validate={[
									...getStandardFormValidations()
								]}
							>
								<TextInput name="zip" id="zip" />
							</FormField>
						</Box>
					</Box>
					{!props.addressOnly && (
						<Fragment>
							<FormField
								label="What floor?"
								name="floor"
								validate={[
									...getStandardFormValidations()
								]}
							>
								<TextInput
									name="floor"
									type="number"
									inputMode="numeric"
								/>
							</FormField>
							{Number(state.floor) > 1 && (
								<FormField
									contentProps={{
										border: false
									}}
									name="has_elevator"
								>
									<CheckBox
										label={<Text margin={{ left: "small" }} weight="bold">Is there an elevator?</Text>}
										name="has_elevator"
										toggle
									/>
								</FormField>
							)}
							<FormField
								contentProps={{
									border: false
								}}
								name="has_parking"
							>
								<CheckBox
									label={<Text margin={{ left: "small" }} weight="bold">Is there parking?</Text>}
									name="has_parking"
									toggle
								/>
							</FormField>
							<FormField
								contentProps={{
									border: false
								}}
								name="has_driveway"
							>
								<CheckBox
									label={<Text margin={{ left: "small" }} weight="bold">Is there a driveway?</Text>}
									name="has_driveway"
									toggle
								/>
							</FormField>
							<FormField
								label="Notes"
								help="Anything specific we need to know?"
								name="notes"
							>
								<TextArea
									name="notes"
								/>
							</FormField>
						</Fragment>
					)}
				</Box>
				{props.children}
			</Form>
		</Box>
	);
};