import { DTO, Store, StoreProduct, DeliveryType, Brand, Item, ProductCondition, Product, ProductDimensions, ProductMedia } from "@rego-app/common";
import { Form, Box, FormField, TextInput, TextArea, Grid, Select, Heading, CheckBox, Button, Text } from "grommet";
import { useEffect, useMemo, useState } from "react";
import { buildStoreProduct } from "../../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectActiveStore, selectActiveStoreProduct, setActiveStoreProduct, StoreProductState } from "../../../../app/store/partners";
import { selectItems, selectBrands, fetchBrands, fetchItems } from "../../../../app/store/reference";
import { getStandardFormValidations, getNumberFormValidations, getOptionalNumberFormValidations } from "../../../../helpers";
import { useWindowDimensions, ProductItemField, LoadingButton, Loader } from "../../../common";
import { CreateStoreProductPreview, MediaUploadModal, UpdatePrimaryMediaModal } from "../store";

interface StoreProductFormProps {
	store: DTO<Store>;
	isUpdating: boolean;
	isSubmitting: boolean;
	isUploadingMedia: boolean;
	onSubmit(): Promise<void>;
	onMediaAccepted(files: File[]): Promise<void>;
	onMediaDeleted(media: DTO<ProductMedia>): Promise<boolean>;
}

export const StoreProductForm: React.FC<StoreProductFormProps> = (props) => {
	const items = useAppSelector(selectItems);
	const brands = useAppSelector(selectBrands);
	const dimensions = useWindowDimensions();
	const dispatch = useAppDispatch();
	const product = useAppSelector(selectActiveStoreProduct);

	const [wasChanged, setWasChanged] = useState(false);
	const [showUploadMediaModal, setShowUploadMediaModal] = useState(false);
	const [showUpdatePrimaryMediaModal, setShowUpdatePrimaryMediaModal] = useState(false);

	const allowsPickup = useMemo(() => {
		if(!product) return false;
		return product.delivery_types && product.delivery_types.includes(DeliveryType.PICKUP);
	}, [product]);

	const allowsDelivery = useMemo(() => {
		if(!product) return false;
		return product.delivery_types && product.delivery_types.includes(DeliveryType.DELIVERY);
	}, [product]);

	function updateDeliveryType(type: DeliveryType, allowed: boolean): void {
		if(!product) return;
		const types = product.delivery_types ?? [];

		if(allowed) {
			if(!types.includes(type)) {
				types.push(type);
			}
		}
		else {
			if(types.includes(type)) {
				types.splice(types.findIndex(t => t === type), 1);
			}
		}

		dispatch(setActiveStoreProduct({
			...product,
			delivery_types: [...types]
		}));
	}

	function handleSelectItem(item: DTO<Item>): void {
		if(!product) return;
		dispatch(setActiveStoreProduct({
			...product,
			item: { ...item } as Item,
			item_input: item.name
		}));
	}

	function wrapStateUpdate(changes: Partial<StoreProductState>): void {
		if(!product) return;
		dispatch(setActiveStoreProduct({
			...product,
			...changes
		}));
	}

	return (
		<Form
			style={{ height: "100%" }}
			value={product!}
			onChange={(changes) => {
				const matchedBrand = changes.brand_input && brands.find(b => b.name === changes.brand_input);
				if(matchedBrand) {
					changes.brand = matchedBrand as Brand;
				}

				const matchedItem = changes.item_input && items.find(i => i.name === changes.item_input);
				if(matchedItem) {
					changes.item = matchedItem as Item;
				}

				const dimensions = {
					unit: "inches",
					width: changes.width_input ?? product?.dimensions?.width,
					depth: changes.depth_input ?? product?.dimensions?.depth,
					height: changes.height_input ?? product?.dimensions?.height
				};
				changes.dimensions = dimensions as ProductDimensions;

				setWasChanged(true);
				wrapStateUpdate({ ...changes });
			}}
			validate="submit"
			onSubmit={() => props.onSubmit().then(() => {
				setWasChanged(false);
			}).catch(err => {
				console.error("Failed during submit", err);
			})}
		>
			<Box flex gap="small">
				<Box gap="small">
					<FormField
						label="Title"
						name="title"
						validate={[
							...getStandardFormValidations()
						]}
					>
						<TextInput
							name="title"
						/>
					</FormField>
					<FormField
						label="Description"
						name="description"
						validate={[
							...getStandardFormValidations()
						]}
					>
						<TextArea
							name="description"
						/>
					</FormField>
					<ProductItemField
						label="Item Type"
						name="item_input"
						onSuggestionSelected={(item) => {
							handleSelectItem(item);
						}}
					/>
					<Grid columns={{ count: 2, size: "auto" }} gap="small">
						<FormField
							label="Sale Price"
							name="price"
							validate={[
								...getStandardFormValidations(),
								...getNumberFormValidations()
							]}
						>
							<TextInput
								name="price"
								icon={<Text weight="bold">$</Text>}
							/>
						</FormField>
						<FormField
							label="Compare Price"
							name="price_compare"
							validate={[
								...getOptionalNumberFormValidations()
							]}
						>
							<TextInput
								name="price_compare"
								icon={<Text weight="bold">$</Text>}
							/>
						</FormField>
					</Grid>
					<Grid columns={{ count: 2, size: "auto" }} gap="small">
						<FormField
							label="Condition"
							name="condition"
							validate={[
								...getStandardFormValidations()
							]}
						>
							<Select
								name="condition"
								options={Object.values(ProductCondition)}
							/>
						</FormField>
						<FormField
							label="Brand"
							name="brand_input"
							validate={[
								...getStandardFormValidations()
							]}
						>
							<TextInput
								name="brand_input"
								suggestions={brands.map(b => b.name)}
								onSuggestionSelect={(event) => {
									const found = event.suggestion && brands.find(b => b.name === event.suggestion);
									if(found) {
										wrapStateUpdate({ brand_input: event.suggestion, brand: found as Brand });
									}
								}}
							/>
						</FormField>
					</Grid>
					<Grid columns={{ count: 3, size: "auto" }} gap="small">
						<FormField
							label="Height"
							name="height_input"
							value={product?.dimensions?.height ?? undefined}
							validate={[
								...getOptionalNumberFormValidations()
							]}
						>
							<TextInput
								name="height_input"
							/>
						</FormField>
						<FormField
							label="Width"
							name="width_input"
							value={product?.dimensions?.width ?? undefined}
							validate={[
								...getOptionalNumberFormValidations()
							]}
						>
							<TextInput
								name="width_input"
							/>
						</FormField>
						<FormField
							label="Depth"
							name="depth_input"
							value={product?.dimensions?.depth ?? undefined}
							validate={[
								...getOptionalNumberFormValidations()
							]}
						>
							<TextInput
								name="depth_input"
							/>
						</FormField>
					</Grid>
					<Grid columns={{ count: ["small", "medium"].includes(dimensions.size) ? 1 : 2, size: "auto" }}>
						<Box>
							<Heading level="4">Delivery Options</Heading>
							<Box>
								{(props.store.delivery_types ?? []).includes(DeliveryType.PICKUP) && (
									<FormField
										contentProps={{ border: undefined }}
									>
										<CheckBox
											toggle
											name="allowsPickup"
											checked={allowsPickup}
											label="Allow Store Pickup"
											onChange={(event) => updateDeliveryType(DeliveryType.PICKUP, event.target.checked)}
										/>
									</FormField>
								)}
								{(props.store.delivery_types ?? []).includes(DeliveryType.DELIVERY) && (
									<FormField
										contentProps={{ border: undefined }}
									>
										<CheckBox
											toggle
											name="allowsDelivery"
											checked={allowsDelivery}
											label="Allow Delivery"
											onChange={(event) => updateDeliveryType(DeliveryType.DELIVERY, event.target.checked)}
										/>
									</FormField>
								)}
							</Box>
						</Box>
						<Box>
							<Heading level="4">Inventory Options</Heading>
							<Box>
								<FormField
									name="quantity"
									label="Quantity Available"
									validate={[
										...getNumberFormValidations(),
										(value: unknown) => {
											if(!isNaN(Number(value)) && Number(value) < 0) {
												return {
													status: "error",
													message: "Please enter a number greater than or equal to0"
												};
											}
										}
									]}
								>
									<TextInput
										name="quantity"
									/>
								</FormField>
							</Box>
						</Box>
					</Grid>
					<Box align="start" gap="small">
						<Button
							label="Upload Image(s)"
							primary
							onClick={() => setShowUploadMediaModal(true)}
						/>
						<Button
							label="Update Primary Image"
							primary
							onClick={() => setShowUpdatePrimaryMediaModal(true)}
						/>
					</Box>
					{(showUploadMediaModal && product) && (
						<MediaUploadModal
							product={product}
							onClose={() => setShowUploadMediaModal(false)}
							onMediaDeleted={props.onMediaDeleted}
							onFilesAccepted={props.onMediaAccepted}

						/>
					)}
					{(showUpdatePrimaryMediaModal && product && product.id && (product.media?.length ?? 0) > 0) && (
						<UpdatePrimaryMediaModal
							product={product as DTO<Product>}
							store={props.store}
							onClose={() => setShowUpdatePrimaryMediaModal(false)}
						/>
					)}
				</Box>
			</Box>
			{wasChanged && (
				<Box flex align="end" justify="end" margin={{ top: "medium" }}>
					<LoadingButton
						label="Submit"
						type="submit"
						primary
						isLoading={props.isUpdating}
					/>
				</Box>
			)}
		</Form>
	);
};



interface StoreProductFormContainerProps {
	product?: DTO<StoreProduct>;
	isUpdating: boolean;
	isSubmitting: boolean;
	isUploadingMedia: boolean;
	onFilesAccepted(files: File[]): Promise<void>;
	onMediaDeleted(media: DTO<ProductMedia>): Promise<boolean>;
	onFormSubmit(product: Partial<DTO<StoreProduct> & { quantity: number; }>): Promise<void>;
}

export const StoreProductFormContainer: React.FC<StoreProductFormContainerProps> = (props) => {
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();
	const store = useAppSelector(selectActiveStore);
	const product = useAppSelector(selectActiveStoreProduct);

	const columns = useMemo(() => {
		const size = dimensions.size;

		if(size === "small") return "100%";
		if(size === "medium") return ["1/2", "1/2"];
		return ["2/3", "1/3"];
	}, [dimensions.size]);

	useEffect(() => {
		dispatch(fetchBrands());
		dispatch(fetchItems());
	}, []);


	function handleUploadMedia(files: File[]): Promise<void> {
		return props.onFilesAccepted(files);
	}

	function handleFormSubmit(): Promise<void> {
		if(!product) return Promise.resolve();

		return props.onFormSubmit({
			title: product.title,
			description: product.description,
			condition: product.condition,
			price: product.price,
			price_compare: product.price_compare,
			brand: product.brand,
			brand_input: product.brand_input,
			delivery_types: product.delivery_types,
			item: product.item,
			item_input: product.item_input,
			quantity: product.quantity,
			dimensions: product.dimensions
		});
	}

	return (
		<Loader visible={!store}>
			{!!store && (
				<Box margin="medium">
					{props.children}
					<Grid columns={columns} gap="small">
						<StoreProductForm
							store={store}
							onSubmit={handleFormSubmit}
							onMediaAccepted={handleUploadMedia}
							onMediaDeleted={props.onMediaDeleted}
							isSubmitting={props.isSubmitting}
							isUpdating={props.isUpdating}
							isUploadingMedia={props.isUploadingMedia}
						/>
						{(dimensions.size !== "small" && product) && (
							<CreateStoreProductPreview
								product={product as DTO<Product>}
							/>
						)}
					</Grid>
				</Box>
			)}
		</Loader>
	);
};