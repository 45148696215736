import { Box, Grid } from "grommet";
import { Outlet } from "react-router-dom";
import { useScreenSize } from "../../common";
import { AdminSidebar } from "../pages";

export const AdminDashboardController: React.FC = (props) => {
	const size = useScreenSize();
	return (
		<Box flex>
			<Box>
				<Grid columns={size === "small" ? "100%" : ["small", "flex"]}>
					<AdminSidebar />
					<Outlet />
				</Grid>
			</Box>
			<Box>

			</Box>
		</Box >
	);
};