import { Donation, DTO, ProductIntent, Service } from "@rego-app/common";
import { Box, Button, Grid, Layer } from "grommet";
import { FormClose } from "grommet-icons";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { listCustomerDonations, selectActiveCustomerDonations, selectCustomerDonations, useAppDispatch, useAppSelector } from "../../../app/store";
import { Loader, SlimHeading, useTimezone } from "../../common";
import { ServiceCard, ServiceCardContainer } from "../components";
import { ProductSummary } from "../../admin/components";
import { parseDateFromUTC } from "../../../helpers";

export const DonationDetails: React.FC<{ donation: DTO<Donation>; onClose: () => void; }> = (props) => {
	return (
		<Layer
			onEsc={props.onClose}
			onClickOutside={props.onClose}
		>
			<Box>
				<Box margin="small">
					<Box align="end" justify="center">
						<Button
							icon={<FormClose size="medium" />}
							hoverIndicator
							onClick={props.onClose}
						/>
					</Box>
					<Box align="center">
						<SlimHeading level="3">Item(s) for Donation</SlimHeading>
						{(props.donation.products ?? []).map(product => (
							<ProductSummary
								permitAI={false}
								key={product.id}
								product={product}
								hideChipContent
							/>
						))}
					</Box>
				</Box>
			</Box>
		</Layer>
	);
};

export const DashboardDonations: React.FC = (props) => {
	const snack = useSnackbar();
	const timezone = useTimezone();
	const dispatch = useAppDispatch();

	const donations = useAppSelector(selectCustomerDonations);
	const activeDonations = useAppSelector(selectActiveCustomerDonations);

	const [ isLoaded, setIsLoaded ] = useState(false);
	const [ isViewingAll, setIsViewingAll ] = useState(false);
	const [ displayedDonations, setDisplayedDonations ] = useState<DTO<Donation>[]>([]);
	const [ selectedDonation, setSelectedDonation ] = useState<DTO<Donation> | null>(null);
	const [ isDetailsModalOpen, setDetailsModalOpen ] = useState(false);

	function handleViewDonation(donation: DTO<Donation>): void {
		setSelectedDonation(donation);
		setDetailsModalOpen(true);
	}

	function toggleViewAll(): void {
		setIsViewingAll(!isViewingAll);
	}

	useEffect(() => {
		dispatch(listCustomerDonations({})).unwrap()
			.catch(err => {
				console.error("Failed to load customer donations", err);
				snack.enqueueSnackbar("We ran into an issue loading your information", {
					variant: "error"
				});
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}, []);

	useEffect(() => {
		setDisplayedDonations(
			(isViewingAll)
				? donations
				: activeDonations
		);
	}, [ donations, isViewingAll ]);

	const renderItems = useCallback((items: DTO<Donation>[]) => (
		<Grid columns="medium">
			{items.map(donation => (
				<ServiceCard
					intent={ProductIntent.DONATE}
					service={donation as unknown as DTO<Service>}
					serviceLabel="donation"
					onClickOpen={handleViewDonation}
					contentCompleted={`Your donation was completed on ${parseDateFromUTC(donation.completed_at, timezone)}. Your donation receipt will be uploaded here when it is received.`}
					contentInProgress={"Your donation is in progress."}
					contentReadyToSchedule={"Your donation is ready to be scheduled. Click below to pick a time for your item(s) to be picked up"}
					contentScheduled={"Your donation is scheduled. If you need to make any changes give us a call. Please note, if your pickup is cancelled within 24 hours of the scheduled date a cancellation fee of 50% will be applied"}
				/>
			))}
		</Grid>
	), [ isViewingAll, displayedDonations ]);

	return (
		<Loader visible={!isLoaded}>
			{isDetailsModalOpen && selectedDonation && (
				<DonationDetails
					donation={selectedDonation}
					onClose={() => {
						setDetailsModalOpen(false);
					}}
				/>
			)}
			<ServiceCardContainer
				title="Your Donations"
				noItemsMessage="nothing in progress right now"
				largeScreenPageSize={9}
				mediumScreenPageSize={6}
				smallScreenPageSize={4}
				isViewingAll={isViewingAll}
				toggleViewAll={toggleViewAll}
				items={displayedDonations}
				renderItems={renderItems}
			/>
		</Loader>
	);
};