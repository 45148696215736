import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store, history } from "./app/store";
import { Provider } from "react-redux";
import { History } from "history";
import { Router } from "react-router";
import "./css/index.css";
import { GlobalProvider } from "./features/common";
import "./app/reporting";

ReactDOM.render(
	<React.StrictMode>
		<GlobalProvider>
			<Provider store={store}>
				<HistoryRouter history={history}>
					<App />
				</HistoryRouter>
			</Provider>
		</GlobalProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

export interface HistoryRouterProps {
	basename?: string;
	children?: React.ReactNode;
	history: History;
}

export function HistoryRouter({ basename, children, history }: HistoryRouterProps) {
	const [state, setState] = React.useState({
		action: history.action,
		location: history.location,
	});

	React.useLayoutEffect(() => {
		history.listen((update) => {
			setState({
				action: update.action,
				location: update.location
			});
		});
	}, [history]);

	return (
		<Router
			basename={basename}
			children={children}
			location={state.location}
			navigationType={state.action}
			navigator={history}
		/>
	);
}