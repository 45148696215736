import React, { useEffect, useState } from "react";
import type { Contact, Customer, DTO } from "../../../types";
import { Anchor, Box, Button, Form, FormField, Heading, Layer, Spinner, TextInput } from "grommet";
import { Close } from "grommet-icons";
import { CustomerService } from "../../../app/services";

interface ContactVerifyProps {
	onVerifiedCallback(contact?: DTO<Contact>): void;
	customer: DTO<Customer>;
	contact: DTO<Contact>;
}

interface ContactVerifyState {
	isLoading: boolean;
	wasVerificationCodeSent: boolean;
	verificationCodeSentAt: number | null;
	errorMessage: string;
	code: string;
}

export const VerifyContactModal: React.FC<ContactVerifyProps> = (props) => {

	const [state, setState] = useState<ContactVerifyState>({
		isLoading: false,
		wasVerificationCodeSent: false,
		verificationCodeSentAt: null,
		errorMessage: "",
		code: ""
	});

	const sendVerificationCode = async (): Promise<void> => {
		try {
			setState({
				...state,
				isLoading: true
			});

			//Check sent timestamp
			if(state.wasVerificationCodeSent) {
				//can't send another code until 60 seconds
				const LIMIT = 60 * 1000;
				if(Date.now() <= LIMIT + (state.verificationCodeSentAt ?? 0)) {
					setState({
						...state,
						errorMessage: "You can't request another code yet"
					});
					return;
				}
			}

			if(!await CustomerService.sendVerificationCode(props.customer.id, props.contact.id)) {
				throw new Error("Failed to send verification code");
			};

			setState({
				...state,
				isLoading: false,
				wasVerificationCodeSent: true,
				verificationCodeSentAt: Date.now(),
				errorMessage: ""
			});
		}
		catch(e) {
			console.error("Failed to send verification code", e);
			setState({
				...state,
				isLoading: false,
				wasVerificationCodeSent: false,
				errorMessage: "Something wen't wrong, and we weren't able to send a verification code"
			});
		}
	};

	const verifyContact = async (): Promise<void> => {
		try {
			setState({
				...state,
				isLoading: true
			});

			if(!await CustomerService.confirmVerificationCode(props.customer.id, props.contact.id, state.code)) {
				throw new Error("Failed to verify code");
			};

			setState({
				...state,
				isLoading: false
			});

			props.onVerifiedCallback(props.contact);
		}
		catch(e) {
			console.error("Failed to confirm verification code", e);
			setState({
				...state,
				isLoading: false,
				wasVerificationCodeSent: false,
				verificationCodeSentAt: null,
				errorMessage: "We weren't able to verify that code"
			});
		}
	};

	useEffect(() => {
		sendVerificationCode();
	}, []);

	return (
		<Layer
			onClickOutside={() => {
				props.onVerifiedCallback();
			}}
		>
			<Box
				align="center"
				pad={{
					top: "small",
					left: "medium",
					right: "medium",
					bottom: "small"
				}}
			>
				<Box align="end" justify="center" fill>
					<Button plain icon={(
						<Close
							onClick={() => props.onVerifiedCallback()}
						/>
					)} />
				</Box>
				<Heading
					level="3"
					margin="small"
				>Verify Contact Method</Heading>

				<Heading level="6">
					Please enter the 6-digit code that was sent to {props.contact.value}
				</Heading>
				<Form
					messages={{
						required: "This field is required"
					}}
					value={state}
					onChange={nextValue => setState({
						...nextValue,
						errorMessage: ""
					})}
					onSubmit={({ value }) => {
						verifyContact();
					}}
				>
					<Box gap="medium" direction="column">
						<Box gap="xsmall" direction="column">
							<Box gap="small" direction="column">
								<FormField
									required
									name="code"
									htmlFor="code"
									label="Verification Code"
								>
									<TextInput autoComplete="one-time-code" id="code" type="text" name="code" pattern="[0-9]{6}" />
								</FormField>
								<Box direction="column" align="start">
									<Anchor onClick={sendVerificationCode}>Send another code?</Anchor>
								</Box>
							</Box>
						</Box>
						<FormField contentProps={{ border: false }} pad={false} margin="none" hidden error={state.errorMessage} />
						<Box direction="column" gap="small" justify="center" align="center">
							<Button
								type="submit"
								icon={state.isLoading ? <Spinner/> : undefined}
								primary
								reverse
								label={state.isLoading ? "Loading" : "Submit"}
							/>
						</Box>
					</Box>
				</Form>
			</Box>
		</Layer>
	);
};