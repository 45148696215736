import { ThemeType } from "grommet";

export const BRAND_COLOR = "#139B9C" as const;

export const theme: ThemeType = {
	global: {
		active: {
			background: "#5cdac2"
		},
		font: {
			family: "Montserrat",
			size: "18px"
		},
		colors: {
			brand: {
				dark: "#139B9C",
				light: "#139B9C"
			},
			"accent-1": "#13569C",
			"accent-2": "#9EE493",
			"accent-3": "#DAF7DC",
			"accent-4": "#FE5F55",
			"dark-1": "#16302B"
		},
		breakpoints: {
			small: {
				value: 550
			}
		}
	},
	layer: {
		border: {
			radius: "medium",
			intelligentRounding: true,
		},
		responsiveBreakpoint: "small"
	},
	///@ts-ignore
	formField: {
		///@ts-ignore
		border: {
			error: {
				color: "status-error",
			},
			side: "all"
		},
		///@ts-ignore
		disabled: {
			background: {
				color: undefined,
			},
			border: {
				color: "status-disabled",
			},
			label: {
				color: "status-disabled",
			},
		},
		///@ts-ignore
		help: {
			margin: {
				start: "none"
			},
		},
		///@ts-ignore
		info: {
			margin: {
				start: "none"
			},
		},
		///@ts-ignore
		label: {
			weight: "bold",
			margin: {
				horizontal: "none",
			}
		}
	}
};

export default theme;

// export const theme = createTheme({
// 	palette: {
// 		primary: {
// 			main: "#5CDAC2"
// 		},
// 		secondary: {
// 			main: "#EAFFFB"
// 		},
// 	},
// });

// export const useStyles = makeStyles({
// 	root: {
// 		background: "#EAFFFB",
// 		color: "#3F3F3F",
// 		fontFamily: "Montserrat sans-serif"
// 	},
// 	main: {
// 		color: "#3F3F3F",
// 		fontFamily: "Montserrat sans-serif",
// 		minHeight: "50vh",
// 		margin: "30px",
// 		marginBottom: "15vh"
// 	},
// 	layout: {
// 		color: "#3F3F3F",
// 		fontFamily: "Montserrat sans-serif",
// 		padding: "30px",
// 	},
// 	header: {
// 		height: "15vh",
// 		background: "white"
// 	},
// 	footer: {
// 		//top: "auto",
// 		//bottom: "0",
// 		height: "15vh",
// 		background: "#40514e",
// 		//position: "fixed",
// 		width: "100%",
// 		"& img": {
// 			maxWidth: "40px",
// 			paddingLeft: "10px",
// 			paddingRight: "10px"
// 		},
// 		"& p": {
// 			color: "white",
// 			fontFamily: "Montserrat sans-serif",
// 		},
// 		"& button": {
// 			marginLeft: "10px",
// 			marginRight: "10px"
// 		}
// 	},
// 	button: {
// 		fontSize: "14px",
// 		border: "none",
// 		backgroundColor: "#5CDAC2",
// 		padding: "8px 20px",
// 		width: "140px",
// 		marginTop: "10px",
// 		borderRadius: "69px",
// 		color: "white",
// 		boxShadow: "1px 5px 11px 0px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.20)",
// 		textTransform: "uppercase",
// 		"&:hover": {
// 			backgroundColor: "#5CDAC2",
// 			textDecoration: "underline"
// 		},
// 		"&:visited": {
// 			backgroundColor: "#5CDAC2",
// 			opacity: ".5"
// 		},
// 		"&.selected": {
// 			fontWeight: "bolder",
// 			border: "2px solid"
// 		},
// 		"&.secondary": {
// 			border: "2px solid white",
// 			backgroundColor: "transparent",
// 			padding: "8px 20px",
// 			marginLeft: "10px",
// 			borderRadius: "69px",
// 			fontSize: "14px",
// 			width: "140px",
// 			marginTop: "10px",
// 			color: "white",
// 			boxShadow: "1px 5px 11px 0px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.20)",
// 			textTransform: "uppercase",
// 			"&:hover": {
// 				color: "#5CDAC2",
// 				border: "2px solid #5CDAC2",
// 				outline: "none"
// 			}
// 		}
// 	},
// 	input: {
// 		color: "green"
// 	}
// });