import { DTO, ProductQuery, QueryProductsRequest, StoreProduct } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, Button, Image, Text } from "grommet";
import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { Outlet, useParams } from "react-router-dom";
import { PartnerService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchStore, selectActiveStore, selectProductFilters } from "../../../app/store/shop";
import { StoreAuthGuard } from "../../auth";
import { Loader, SlimHeading, useWindowDimensions } from "../../common";
import { DesktopShopPage, MobileShopPage } from "../../shop";

export const PartnerController: React.FC = (props) => {
	return (
		<Outlet />
	);
};

export const StoreController: React.FC = (props) => {
	return (
		<StoreAuthGuard>
			<Outlet />
		</StoreAuthGuard>
	);
};

interface StorePageControllerState {
	isLoading: boolean;
	wasLoaded: boolean;
	isLoadingProducts: boolean;
	wasProductsLoaded: boolean;
}

export const StorePageController: React.FC = (props) => {
	const params = useParams();
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();
	const store = useAppSelector(selectActiveStore);
	const [state, setState] = useState<StorePageControllerState>({
		isLoading: true,
		wasLoaded: false,
		isLoadingProducts: false,
		wasProductsLoaded: false
	});

	function wrapStateUpdate(changes: Partial<StorePageControllerState>): void {
		setState((state) => {
			return ({
				...state,
				...changes
			});
		});
	}

	function handleFetchStore(storeIdOrSlug: string): void {
		dispatch(fetchStore(storeIdOrSlug)).unwrap()
			.catch(err => {
				console.error(`could not find store [${storeIdOrSlug}]`, err);
				dispatch(push("/shop"));
			});
	}

	useEffect(() => {
		if(!params.storeIdOrSlug) {
			dispatch(push("/shop"));
			return;
		}

		handleFetchStore(params.storeIdOrSlug);
	}, [params]);

	const [products, setProducts] = useState<DTO<StoreProduct>[]>([]);
	const { seller, stores, categories, priceGreaterThan, priceLessThan } = useAppSelector(selectProductFilters);
	const [limit, setLimit] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
	const [step, setStep] = useState(4);
	const [totalPages, setTotalPages] = useState(0);

	const filters = useMemo(() => {
		const query: ProductQuery = {
			stores,
			categories
		};

		if(priceGreaterThan !== null) {
			query.priceGreaterThan = priceGreaterThan;
		}

		if(priceLessThan !== null) {
			query.priceLessThan = priceLessThan;
		}

		return query;
	}, [seller, stores, categories, priceGreaterThan, priceLessThan]);

	const queryProductsRequest = useMemo(() => {
		return {
			page: currentPage,
			limit,
			market: store?.market?.id ?? "",
			filters: {
				...filters
			}
		} as QueryProductsRequest;
	}, [limit, currentPage, store, filters]);

	useEffect(() => {
		setProducts([]);
		setCurrentPage(1);
		fetchProducts([]);
	}, [store]);

	useEffect(() => {
		setProducts([]);
		fetchProducts([]);
	}, [filters]);

	function fetchProducts(oldProducts: DTO<StoreProduct>[]): void {
		if(!store) {
			return;
		}

		wrapStateUpdate({
			isLoadingProducts: true
		});
		PartnerService.queryStoreProducts(store.id, queryProductsRequest)
			.then(result => {
				if(result.pagination) {
					setTotalPages(result.pagination.page.total);
				}
				setProducts([...oldProducts.concat([...result.result])]);
			})
			.catch(err => {
				console.error("Failed listing products", err);
			})
			.finally(() => {
				wrapStateUpdate({
					isLoadingProducts: false,
					wasProductsLoaded: true
				});
			});
	}

	useEffect(() => {
		if(state.wasProductsLoaded && !state.wasLoaded) {
			wrapStateUpdate({
				wasLoaded: true
			});
		}
	}, [state.wasLoaded, state.wasProductsLoaded]);

	useEffect(() => {
		fetchProducts(products);
	}, [currentPage]);

	useEffect(() => {
		if(dimensions.width >= 1200) {
			setStep(3);
			return;
		}

		if(dimensions.width >= 900) {
			setStep(3);
			return;
		}

		if(dimensions.width >= 600) {
			setStep(2);
			return;
		}

		setStep(1);
	}, [dimensions.width]);

	function fetchNextProducts(): void {
		if(currentPage >= totalPages) {
			return;
		}

		setCurrentPage(currentPage + 1);
	}

	const hasNextPage = useMemo(() => {
		return currentPage < totalPages;
	}, [currentPage, totalPages]);

	const storeHeader = useMemo(() => {
		if(dimensions.size === "small") {
			return (
				<Box gap="medium" align="center">
					<Box width="medium" align="center" justify="center">
						<Image width="100%" src={store?.logo_url} />
					</Box>
					<Button
						primary
						label="View Cart"
						onClick={() => dispatch(push("/shop/cart"))}
					/>
				</Box>
			);
		}

		return (
			<Box flex>
				<Box direction="row" margin="small" flex gap="medium" id="store-header">
					<Box>
						<Image style={{ maxHeight: "100px" }} src={store?.logo_url} />
					</Box>
					<Box gap="small">
						<SlimHeading level="3">
							Shop items sold by {store?.name}
						</SlimHeading>
						{!!store?.seller_description && (
							<Text>
								{store?.seller_description}
							</Text>
						)}
					</Box>
				</Box>
				<Box align="end">
					<Button
						primary
						label="View Cart"
						onClick={() => dispatch(push("/shop/cart"))}
					/>
				</Box>
			</Box>
		);
	}, [store, dimensions.size]);

	return (
		<Loader visible={!store}>
			<DesktopShopPage
				header={storeHeader}
				products={products}
				productStep={step}
				hasNextPage={hasNextPage}
				isLoadingProducts={state.isLoadingProducts}
				fetchNextProducts={fetchNextProducts}
			/>
		</Loader>
	);
};