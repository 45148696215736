import { Delivery, DTO, RelationExpand, DeliveryConfirmation, DeliveryWindow, CustomerDeliveryConfirmation, StoreDeliveryConfirmation, OrderDelivery, PurchaseDelivery, Service } from "@rego-app/common";
import { ServerResponse } from "../../types";
import { RequestService } from "./request";

export const DeliveryService = {
	async listDeliveries(filters: Partial<Delivery>, expand?: RelationExpand<OrderDelivery & PurchaseDelivery>): Promise<DTO<Delivery>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<Delivery>[]>>(`/api/v1/delivery/`, {
				params: {
					...filters,
					expand: expand?.join(",") ?? undefined
				}
			});
		return result.data.result;
	},

	async getDelivery(deliveryId: string, expand?: RelationExpand<OrderDelivery & PurchaseDelivery>): Promise<DTO<Delivery>> {
		const result = await RequestService
			.get<ServerResponse<DTO<Delivery>>>(`/api/v1/delivery/${deliveryId}`, {
				params: {
					expand: expand?.join(",") ?? undefined
				}
			});
		return result.data.result;
	},

	async getDeliveryService(deliveryId: string, expand?: RelationExpand<Service>): Promise<DTO<Service>> {
		const result = await RequestService
			.get<ServerResponse<DTO<Service>>>(`/api/v1/delivery/${deliveryId}/service`, {
				params: {
					expand: expand?.join(",") ?? undefined
				}
			});
		return result.data.result;
	},

	async scheduleDelivery(deliveryId: string, window: DeliveryWindow): Promise<DTO<Delivery>> {
		const result = await RequestService
			.put<ServerResponse<DTO<Delivery>>>(`/api/v1/delivery/${deliveryId}/schedule`, {
				window
			});
		return result.data.result;
	},

	async confirmCustomerDelivery(deliveryId: string, customerId: string): Promise<DTO<CustomerDeliveryConfirmation>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<CustomerDeliveryConfirmation>>>(`/api/v1/delivery/${deliveryId}/confirm`, {
				customer: {
					id: customerId
				}
			});
		return result.data.result;
	},

	async confirmStoreDelivery(deliveryId: string, storeId: string): Promise<DTO<StoreDeliveryConfirmation>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<StoreDeliveryConfirmation>>>(`/api/v1/delivery/${deliveryId}/store/${storeId}/confirm`);
		return result.data.result;
	},

	async completeDelivery(deliveryId: string): Promise<DTO<Delivery>> {
		const result = await RequestService
			.patch<ServerResponse<DTO<Delivery>>>(`/api/v1/delivery/${deliveryId}/complete`);
		return result.data.result;
	},

	async getDeliveryConfirmation(deliveryId: string, customerId: string): Promise<DTO<CustomerDeliveryConfirmation | StoreDeliveryConfirmation> | null> {
		const result = await RequestService
			.get<ServerResponse<DTO<CustomerDeliveryConfirmation | StoreDeliveryConfirmation> | null>>(`/api/v1/delivery/${deliveryId}/customer/${customerId}/confirmation`);
		return result.data.result;
	},

	async getDeliveryConfirmations(deliveryId: string,): Promise<DTO<DeliveryConfirmation>[]> {
		const result = await RequestService
			.get<ServerResponse<DTO<DeliveryConfirmation>[]>>(`/api/v1/delivery/${deliveryId}/confirmation`);
		return result.data.result;
	}
};