// Import dependencies
import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";

export function useWebcam() {
	const [isLoaded, setLoaded ] = useState(false);
	const [hasWebcam, setHasWebcam] = useState(false);
	const [hasWebcamPermission, setHasWebcamPermission] = useState(false);
	const [friendlyErrorMessage, setFriendlyErrorMessage] = useState("");

	useEffect(() => {
		navigator.mediaDevices.getUserMedia({ video: true })
			.then(function (stream) {
				if(stream.getVideoTracks().length > 0) {
					stream.getTracks().forEach(t => t.stop());
					setHasWebcam(true);
					setHasWebcamPermission(true);
					setFriendlyErrorMessage("");
				} else {
					// code for when both devices are available
					throw new Error("No camera detected");
				}
			})
			.catch(function (error: DOMException) {
				setHasWebcam(false);

				if(error.name === "NotAllowedError") {
					setHasWebcamPermission(false);
					setFriendlyErrorMessage("No camera permissions");
					return;
				}

				setFriendlyErrorMessage("Couldn't connect to camera");
			})
			.finally(() => {
				setLoaded(true);
			});

		//all good ... remove any errors
		setFriendlyErrorMessage("");
	}, []);

	return {
		ref: useRef<Webcam>(null),
		isLoaded,
		hasWebcam,
		hasWebcamPermission,
		friendlyErrorMessage
	};
}

export default useWebcam;