import { Box, Heading, ResponsiveContext, Image } from "grommet";
import React from "react";

interface HeroProps {
	title: string | JSX.Element;
	subtitle?: string | JSX.Element;
	callToAction?: JSX.Element;
	image?: JSX.Element;
}

export const Hero: React.FC<HeroProps> = (props) => {
	return (
		<ResponsiveContext.Consumer>
			{size => (
				<Box direction="row-responsive">
					<Box>
						{typeof props.title === "string"
							? (
								<Heading color="brand" size="large">
									{props.title}
								</Heading>
							)
							: (
								<>
									{props.title}
								</>
							)
						}
						{typeof props.subtitle === "string"
							? (
								<Heading size="large" level={3}>
									{props.subtitle}
								</Heading>
							)
							: (
								<>
									{props.subtitle}
								</>
							)
						}
						<Box>
							{props.callToAction}
						</Box>
					</Box>
					{["medium", "large", "xlarge"].includes(size) && props.image && (
						<Box justify="center" align="center">
							{props.image}
						</Box>
					)}
				</Box>
			)}
		</ResponsiveContext.Consumer>
	);
};