import { Chip } from "@mui/material";
import { Delivery, DTO, PurchaseDelivery } from "@rego-app/common";
import { push } from "connected-react-router";
import { Box, FormField, TextInput } from "grommet";
import { useMemo } from "react";
import { useAppDispatch } from "../../../app/store";

export const DeliveryDetails: React.FC<{ purchase: DTO<PurchaseDelivery>; }> = (props) => {
	return (
		<Box>
			DELIVERY DETAILS
		</Box>
	);
};

export const DeliverySummary: React.FC<{ delivery: DTO<Delivery>; }> = (props) => {
	const dispatch = useAppDispatch();

	function handleNavigate(): void {
		dispatch(push(`/admin/dashboard/deliveries/${props.delivery.id}`));
	}

	const deliveryStatus = useMemo(() => {
		const delivery = props.delivery;
		if(!delivery.accepted) {
			return "Not Accepted";
		}

		if(!delivery.completed) {
			return "Scheduled";
		}

		return "Completed";
	}, [props.delivery]);

	return (
		<Box
			hoverIndicator
			border
			gap="small"
			margin="small"
			onClick={handleNavigate}
		>
			<Box margin="small" gap="small">
				<Box direction="row" align="center" justify="end">
					<Chip label={deliveryStatus} />
				</Box>
				<Box gap="small" align="start">
					<FormField
						label="Delivery Id"
					>
						<TextInput
							value={props.delivery.id}
							readOnly
						/>
					</FormField>
					<FormField
						label="Delivery Number"
					>
						<TextInput
							value={props.delivery.number}
							readOnly
						/>
					</FormField>
				</Box>
			</Box>
		</Box>
	);
};