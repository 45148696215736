import { DTO, Review } from "@rego-app/common";
import { Box, Button, FormField, TextArea, TextInput } from "grommet";
import { LocalDateField, useAlert } from "../../common";

export const useViewReview = () => {
	const alerts = useAlert();

	function handleShowReview(review: DTO<Review>): void {
		alerts.display<boolean>((callback) => (
			<Box margin="medium" style={{ minWidth: "400px" }}>
				<LocalDateField
					label="Reviewed At"
					value={review.created_at}
				/>
				<FormField
					label="Rating"
					help="out of 5"
				>
					<TextInput
						readOnly
						value={review.rating}
					/>
				</FormField>
				<FormField
					label="Comments"
				>
					<TextArea
						readOnly
						value={review.comments}
					/>
				</FormField>
				<Box align="end">
					<Button
						primary
						label="Close"
						onClick={() => callback(false)}
					/>
				</Box>
			</Box>
		)).then(() => {
			return false;
		});
	}

	return {
		show: handleShowReview
	};
};