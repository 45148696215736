import { Route, Routes } from "react-router-dom";
import { DashboardController } from "../controller";
import { DashboardActions, DashboardDonations, DashboardHome, DashboardListings, DashboardPurchases, DashboardRemovals } from "../pages";

export const DashboardRouter: React.FC = (props) => {
	return (
		<Routes>
			<Route path="/" element={
				<DashboardController />
			}>
				<Route path="" element={
					<DashboardHome />
				} />
				< Route path="listings" element={
					<DashboardListings />
				} />
				< Route path="donations" element={
					<DashboardDonations />
				} />
				< Route path="removals" element={
					<DashboardRemovals />
				} />
				< Route path="purchases" element={
					<DashboardPurchases />
				} />
				< Route path="actions" element={
					<DashboardActions />
				} />
			</Route>
		</Routes>
	);
};