import { push } from "redux-first-history";
import { Image, Button } from "grommet";
import React from "react";
import { useAppDispatch } from "../../../app/store";
import regoIcon from "../../../img/icon.png";
import { Container, Hero } from "../components";

export const Home: React.FC = (props) => {
	const dispatch = useAppDispatch();

	return (
		<Container background="white" margin="medium" round>
			<Hero
				title="Welcome to Rego"
				subtitle="Shop, sell, or donate furniture. We have you covered"
				callToAction={(
					<div>
						<Button
							size="large"
							primary
							label="Get Started"
							onClick={(() => {
								dispatch(push("/order"));
							})}
						/>
					</div>
				)}
				image={(
					<Image
						style={{
							maxWidth: "80%"
						}}
						src={regoIcon}
					/>
				)}
			/>
		</Container>

	);
};