import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { CustomerService } from "../services";
import type { Order, Product } from "../../types";
import { OrderService } from "../services/order";
import { ActionItem, CustomerProduct, Donation, DTO, OrderStatus, Purchase, RelationExpand, Removal } from "@rego-app/common";

export interface DashboardState {
	products: DTO<CustomerProduct>[];
	isInsideOrderFlow: boolean;
	customerOrders: DTO<Order>[];
	customerRemovals: DTO<Removal>[];
	customerDonations: DTO<Donation>[];
	customerPurchases: DTO<Purchase>[];
	activeCustomerOrder: DTO<Order> | null;
	actionItems: ActionItem[];
}

const initialState: DashboardState = {
	products: [],
	isInsideOrderFlow: false,
	customerOrders: [],
	customerRemovals: [],
	customerDonations: [],
	customerPurchases: [],
	activeCustomerOrder: null,
	actionItems: []
};

export const listCustomerActionItems = createAsyncThunk<ActionItem[], void, { state: RootState; }>(
	"dashboard/listCustomerActionItems",
	async (_, thunk) => {
		const customer = thunk.getState().application.customer;
		if(!customer) {
			throw new Error(`No customer present in state`);
		}

		return await CustomerService.getCustomerActionItems(customer.id);
	}
);

export const listCustomerProducts = createAsyncThunk<DTO<CustomerProduct>[], { expand?: RelationExpand<CustomerProduct>; }, { state: RootState; }>(
	"dashboard/listCustomerProducts",
	async (data, thunk) => {
		const customer = thunk.getState().application.customer;
		if(!customer) {
			throw new Error(`No customer present in state`);
		}

		return await CustomerService.listCustomerProducts(customer.id, data.expand);
	}
);

export const listCustomerOrders = createAsyncThunk<DTO<Order>[], void, { state: RootState; }>(
	"dashboard/listCustomerOrders",
	async (_, thunk) => {
		const customer = thunk.getState().application.customer;
		if(!customer) {
			throw new Error(`No customer present in state`);
		}

		return await CustomerService.listCustomerOrders(
			customer.id,
			{},
			["donations", "removals", "products", "referrals"]
		);
	}
);

export const listCustomerDonations = createAsyncThunk<DTO<Donation>[], Partial<DTO<Donation>>, { state: RootState; }>(
	"dashboard/listCustomerDonations",
	async (filters, thunk) => {
		const customer = thunk.getState().application.customer;
		if(!customer) {
			throw new Error(`No customer present in state`);
		}

		return await CustomerService.listCustomerDonations(customer.id, filters ?? {});
	}
);

export const listCustomerRemovals = createAsyncThunk<DTO<Removal>[], Partial<DTO<Removal>>, { state: RootState; }>(
	"dashboard/listCustomerRemovals",
	async (filters, thunk) => {
		const customer = thunk.getState().application.customer;
		if(!customer) {
			throw new Error(`No customer present in state`);
		}

		return await CustomerService.listCustomerRemovals(customer.id, filters ?? {});
	}
);

export const listCustomerPurchases = createAsyncThunk<DTO<Purchase>[], Partial<DTO<Purchase>>, { state: RootState; }>(
	"dashboard/listCustomerPurchases",
	async (filters, thunk) => {
		const customer = thunk.getState().application.customer;
		if(!customer) {
			throw new Error(`No customer present in state`);
		}

		return await CustomerService.listCustomerPurchases(customer.id, filters ?? {}, ["payment", "payment.estimate", "deliveries", "intents"] as RelationExpand<Purchase>);
	}
);

export const loadActiveOrder = createAsyncThunk<DTO<Order>, string, { state: RootState; }>(
	"dashboard/loadActiveOrder",
	async (orderId: string, thunk) => {
		return await OrderService.getOrder(orderId);
	}
);

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setCustomerProducts: (state: typeof initialState, action: PayloadAction<DTO<CustomerProduct>[]>) => {
			state.products = action.payload;
		},
		setInsideOrderFlow: (state: typeof initialState, action: PayloadAction<boolean>) => {
			state.isInsideOrderFlow = action.payload;
		},
		setActiveCustomerOrder: (state: typeof initialState, action: PayloadAction<Order>) => {
			state.activeCustomerOrder = action.payload;
		},
		setCustomerOrders: (state: typeof initialState, action: PayloadAction<Order[]>) => {
			state.customerOrders = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(listCustomerProducts.fulfilled, (state, action: PayloadAction<DTO<CustomerProduct>[]>) => {
			state.products = [
				...action.payload
			];
		});
		builder.addCase(listCustomerOrders.fulfilled, (state, action: PayloadAction<DTO<Order>[]>) => {
			state.customerOrders = [
				...action.payload
			];
		});
		builder.addCase(listCustomerDonations.fulfilled, (state, action: PayloadAction<DTO<Donation>[]>) => {
			state.customerDonations = [
				...action.payload
			];
		});
		builder.addCase(listCustomerRemovals.fulfilled, (state, action: PayloadAction<DTO<Removal>[]>) => {
			state.customerRemovals = [
				...action.payload
			];
		});
		builder.addCase(listCustomerPurchases.fulfilled, (state, action: PayloadAction<DTO<Purchase>[]>) => {
			state.customerPurchases = [
				...action.payload
			];
		});
		builder.addCase(loadActiveOrder.fulfilled, (state, action: PayloadAction<DTO<Order>>) => {
			state.activeCustomerOrder = {
				...action.payload
			} as Order;
		});
		builder.addCase(listCustomerActionItems.fulfilled, (state, action: PayloadAction<ActionItem[]>) => {
			state.actionItems = [
				...action.payload
			];
		});
	}
});

export const {
	setCustomerProducts,
	setInsideOrderFlow,
	setActiveCustomerOrder,
	setCustomerOrders
} = dashboardSlice.actions;

export const selectCustomerActionItems = (state: RootState): ActionItem[] => state.dashboard.actionItems;
export const selectCustomerProducts = (state: RootState): DTO<CustomerProduct>[] => state.dashboard.products;
export const selectInsideOrderFlow = (state: RootState): boolean => state.dashboard.isInsideOrderFlow;
export const selectCustomerOrders = (state: RootState): DTO<Order>[] => state.dashboard.customerOrders;
export const selectOrderedCustomerOrders = (state: RootState): DTO<Order>[] => state.dashboard.customerOrders.filter(o => o.status === OrderStatus.ORDERED);
export const selectCustomerRemovals = (state: RootState): DTO<Removal>[] => state.dashboard.customerRemovals.filter(v => v.visible);
export const selectCustomerDonations = (state: RootState): DTO<Donation>[] => state.dashboard.customerDonations.filter(v => v.visible);
export const selectCustomerPurchases = (state: RootState): DTO<Purchase>[] => state.dashboard.customerPurchases;
export const selectActiveCustomerPurchases = (state: RootState): DTO<Purchase>[] => state.dashboard.customerPurchases.filter(v => !v.expired);
export const selectHiddenCustomerDonations = (state: RootState): DTO<Donation>[] => state.dashboard.customerDonations.filter(v => !v.visible);
export const selectActiveCustomerDonations = (state: RootState): DTO<Donation>[] => selectCustomerDonations(state).filter(d => !d.completed);
export const selectHiddenCustomerRemovals = (state: RootState): DTO<Removal>[] => state.dashboard.customerRemovals.filter(v => !v.visible);
export const selectActiveCustomerRemovals = (state: RootState): DTO<Removal>[] => selectCustomerRemovals(state).filter(d => !d.completed);
export const selectCompletedCustomerOrders = (state: RootState): DTO<Order>[] => state.dashboard.customerOrders.filter(o => o.status === OrderStatus.COMPLETED);
export const selectActiveCustomerOrder = (state: RootState): DTO<Order> | null => state.dashboard.activeCustomerOrder;

export default dashboardSlice.reducer;