import { OpenReason, CloseReason, Badge, Divider } from "@mui/material";
import { formatCurrency, DTO, Product } from "@rego-app/common";
import { push } from "connected-react-router";
import { Anchor, Box, Button, FormField, Grid, GridExtendedProps, Heading, Image, Select, Spinner, Stack, Text } from "grommet";
import { Cart, CircleInformation } from "grommet-icons";
import { Fragment, useEffect, useMemo, useState } from "react";
import { ProductService } from "../../../app/services";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { fetchCustomerCart, removeProductFromCart, selectCartProductQuantity, selectCartProducts, updateCartProductQuantity } from "../../../app/store/purchase";
import { placeholder } from "../../../img";
import { ElevatedCard, LoadingButton, SlimHeading, useWindowDimensions } from "../../common";

export const SpeedDialCartIcon: React.FC = (props) => {
	return (
		<Box>
			<Badge badgeContent={2}>
				<Cart size="large" color="white" />
			</Badge>
		</Box>
	);
};

export const FloatingCart: React.FC = (props) => {
	const [ isOpen, setIsOpen ] = useState(false);
	const cart = useAppSelector(selectCartProducts);

	function handleOnOpen(event: React.SyntheticEvent<{}>, reason: OpenReason): void {
		setIsOpen(true);
	}

	function handleOnClose(event: React.SyntheticEvent<{}>, reason: CloseReason): void {
		setIsOpen(false);
	}

	return (
		<Box
			style={{
				zIndex: 2,
				position: "fixed",
				right: 16,
				bottom: 16
			}}
		>
			<Stack>
				<CircleInformation size="large" color="brand" />
			</Stack>
		</Box>
		// <SpeedDial
		// 	direction="up"
		// 	ariaLabel="cart action button"
		// 	onClose={handleOnClose}
		// 	onOpen={handleOnOpen}
		// 	open={isOpen}
		// 	icon={<SpeedDialCartIcon />}
		// 	openIcon={<FormClose />}
		// 	style={{
		// 		position: "fixed",
		// 		bottom: 16,
		// 		right: 16,
		// 	}}
		// 	FabProps={{
		// 		color: "primary",
		// 		size: 'small',
		// 	}}
		// >
		// 	<SpeedDialAction
		// 		tooltipTitle="View Cart"
		// 		icon={(
		// 			<Button label="View Cart" />
		// 		)}
		// 	/>
		// </SpeedDial>
	);
};


export const CartSubtotal: React.FC = (props) => {
	const cart = useAppSelector(selectCartProducts);
	const cartProductQuantity = useAppSelector(selectCartProductQuantity);

	const subtotalMessage = useMemo(() => {
		const count = cart.map(product => {
			return cartProductQuantity[ product.id ] ?? 1;
		}).reduce((p, c) => p + c, 0);

		const subtotal = cart.map(product => {
			const quantity = cartProductQuantity[ product.id ] ?? 1;
			return Number(product.price) * quantity;
		}).reduce((p, c) => p + c, 0);
		const itemsMessage = count > 1 || count === 0 ? "items" : "item";
		return (
			<Text weight="bold">
				Subtotal ({count} {itemsMessage}): <Text weight="bolder">{formatCurrency(subtotal)}</Text>
			</Text>
		);
	}, [ cart, cartProductQuantity ]);

	return (
		<Box align="start">
			{subtotalMessage}
		</Box>
	);
};

interface CartSubtotalContainerProps {
	isLoading: boolean;
	onCreatePurchase(): void;
}

export const CartSubtotalContainer: React.FC<CartSubtotalContainerProps> = (props) => {
	const cart = useAppSelector(selectCartProducts);
	return (
		<ElevatedCard>
			<Box align="center" margin="small" gap="medium" flex>
				<CartSubtotal />
				<Box flex>
					<LoadingButton
						primary
						label="Continue to Checkout"
						isLoading={props.isLoading}
						onClick={props.onCreatePurchase}
						disabled={cart.length <= 0}
					/>
				</Box>
			</Box>
		</ElevatedCard>
	);
};

export const CartProductsContainer: React.FC = (props) => {
	const dispatch = useAppDispatch();
	const cart = useAppSelector(selectCartProducts);

	return (
		<ElevatedCard
			header={(
				<Box flex margin="small" gap="small">
					<SlimHeading level="3">Shopping Cart</SlimHeading>
					<Divider />
				</Box>
			)}
		>
			<Box margin="small">
				{cart.length === 0
					? (
						<Box align="center" justify="center" gap="small">
							<Heading level="3">
								Your Cart is Empty
							</Heading>
							<Anchor
								color="brand"
								label="Continue Shopping?"
								onClick={() => dispatch(push("/shop"))}
							/>
						</Box>
					) : (
						<Box>
							{cart.map(product => (
								<CartProductCard
									key={product.id}
									product={product}
								/>
							))}
						</Box>
					)}
			</Box>
			<Box align="end" margin="small">
				<CartSubtotal />
			</Box>
		</ElevatedCard >
	);
};

interface CartProductCardProps {
	product: DTO<Product>;
}

export const CartProductCard: React.FC<CartProductCardProps> = (props) => {
	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();
	const cartProductQuantity = useAppSelector(selectCartProductQuantity);
	const [ thumbnail, setThumbnail ] = useState("");
	const [ isRemoving, setIsRemoving ] = useState(false);

	const quantity = useMemo(() => {
		return cartProductQuantity[ props.product.id ] ?? 1;
	}, [ props.product, cartProductQuantity ]);

	useEffect(() => {
		if(!cartProductQuantity[ props.product.id ]) {
			dispatch(updateCartProductQuantity({ productId: props.product.id, quantity }));
		}
	}, [ cartProductQuantity, props.product, quantity ]);

	useEffect(() => {
		if(props.product.media.length) {
			ProductService.getMediaURL(props.product.id, props.product.media[ 0 ].id)
				.then(res => {
					setThumbnail(res);
				})
				.catch(err => {
					console.error("Failed to load product media", props.product, err);
					setThumbnail(placeholder);
				});
		}
		else {
			setThumbnail(placeholder);
		}
	}, []);

	const subtotal = useMemo(() => {
		return Number(props.product.price) * quantity;
	}, [ props.product, quantity ]);

	const isFullWidth = useMemo(() => {
		return [ "small", "medium" ].includes(dimensions.size);
	}, [ dimensions.size ]);

	const productInventoryOptions = useMemo(() => {
		const inventory = props.product.inventory?.quantity_available ?? 0;
		const options = [];
		for(let i = 0; i < inventory; i++) {
			options.push(String(i + 1));
		}

		if(options.length === 0) {
			return [ "1" ];
		}

		return options;
	}, [ props.product ]);

	function handleRemoveProductFromCart(): void {
		setIsRemoving(true);
		dispatch(removeProductFromCart(props.product)).unwrap().then(() => {
			return dispatch(fetchCustomerCart());
		}).catch(err => {
			console.error("Failed to remove from cart", err);
		}).finally(() => {
			setIsRemoving(false);
		});
	}

	const imageComponent = useMemo(() => {
		return (
			<Box align="center" justify="center">
				<Box>
					{!thumbnail
						? (
							<Box style={{ height: 150, width: 150 }} align="center" justify="center">
								<Spinner size="medium" />
							</Box>
						)
						: (
							<Image height={150} width={150} src={thumbnail} />
						)
					}
				</Box>
			</Box>
		);
	}, [ thumbnail ]);

	const forcedQuantity = useMemo(() => {
		if(!props.product.inventory || props.product.inventory.quantity <= 1) {
			return 1;
		}

		return null;
	}, [ props.product ]);

	useEffect(() => {
		if(forcedQuantity) {
			dispatch(updateCartProductQuantity({ productId: props.product.id, quantity: forcedQuantity }));
		}
	}, [ forcedQuantity ]);

	const actionComponent = useMemo(() => {
		return (
			<Box direction="row" gap="small">
				<Box align="start" justify="end">
					<FormField
						margin={"none"}
						label="Quantity"
						name="quantity"
						onChange={(event) => {
							dispatch(updateCartProductQuantity({ productId: props.product.id, quantity: Number(event.target.value) }));
						}}
					>
						<Select
							name="quantity"
							value={String(quantity)}
							disabled={!!forcedQuantity}
							options={productInventoryOptions}
						/>
					</FormField>
				</Box>
				<Box align="start" justify="end">
					<LoadingButton
						primary
						label="Remove"
						size="small"
						color="status-error"
						isLoading={isRemoving}
						onClick={handleRemoveProductFromCart}
					/>
				</Box>
			</Box>
		);
	}, [ isRemoving, quantity, productInventoryOptions, props.product ]);

	const descriptionComponent = useMemo(() => {
		return (
			<Box flex gap="small">
				<Text
					color="black"
					className="text-max-2-lines"
					weight="bold" wordBreak="break-word">
					{props.product.title}
				</Text>
				<Text
					color="brand"
					className="text-max-4-lines"
					weight="bold" wordBreak="break-word">
					{props.product.description}
				</Text>

			</Box>
		);
	}, [ props.product ]);

	return (
		<Box margin="small" gap="medium">
			{isFullWidth && (
				<Box flex gap="small">
					<Box direction="row" justify="between">
						{descriptionComponent}
						<Box justify="start" align="end">
							<Text weight="bold">{formatCurrency(subtotal)}</Text>
						</Box>
					</Box>
					<Box align="end" flex>
						{actionComponent}
					</Box>
				</Box>
			)}
			{!isFullWidth && (
				<Grid columns={[ "auto", "flex", "auto" ]} gap="small">
					{imageComponent}
					<Box>
						{descriptionComponent}
						{actionComponent}
					</Box>
					<Box justify="start" align="end">
						<Text weight="bold">{formatCurrency(subtotal)}</Text>
					</Box>
				</Grid>
			)}
			<Divider />
		</Box>
	);
};

interface CartContainerProps {
	isCreatingPurchase: boolean;
	onCreatePurchase(): void;
}

export const CartContainer: React.FC<CartContainerProps> = (props) => {
	const dimensions = useWindowDimensions();
	const gridProps = useMemo((): GridExtendedProps => {
		switch(dimensions.size) {
			case "small":
			case "medium": {
				return {
					columns: "100%"
				};
			}
			case "large":
			case "xlarge": {
				return {
					columns: [ "flex", "auto" ]
				};
			}
		}
	}, [ dimensions.size ]);

	const isFullWidth = useMemo(() => {
		return [ "small", "medium" ].includes(dimensions.size);
	}, [ dimensions.size ]);

	return (
		<Grid {...gridProps} gap="medium">
			{isFullWidth && (
				<CartSubtotalContainer
					isLoading={props.isCreatingPurchase}
					onCreatePurchase={props.onCreatePurchase}
				/>
			)}
			<CartProductsContainer />
			<Box>
				<CartSubtotalContainer
					isLoading={props.isCreatingPurchase}
					onCreatePurchase={props.onCreatePurchase}
				/>
				<Box flex>

				</Box>
			</Box>
		</Grid>
	);
};