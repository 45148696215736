import { Box, Button, Text } from "grommet";

export interface SidebarButtonProps {
	selected?: boolean;
	label: string;
	icon: JSX.Element;
	onSelected: () => void;
}

export const SidebarButton: React.FC<SidebarButtonProps> = (props) => (
	<Box
		border={{ color: !props.selected ? "brand" : "accent-2", side: "all", size: "small" }}
		pad="small" onClick={props.onSelected}
		hoverIndicator={true}
		focusIndicator={false}
	>
		<Button
			gap="medium"
			alignSelf="start"
			plain
			icon={props.icon}
			label={
				<Text weight="bold">{props.label}</Text>
			}
		/>
	</Box>
);