import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import type { DTO } from "../../types";
import { PartnerService, UserService } from "../services";
import { Store, StoreProduct } from "@rego-app/common";

export interface StoreProductState extends DTO<StoreProduct> {
	quantity?: number;
	depth_input?: string | number | undefined;
	height_input?: string | number | undefined;
	width_input?: string | number | undefined;
}

export interface PartnerState {
	userStores: DTO<Store>[];
	activeStore: DTO<Store> | null;
	activeStoreProduct: StoreProductState | null;
}

const initialState: PartnerState = {
	userStores: [],
	activeStore: null,
	activeStoreProduct: null
};

export const fetchStore = createAsyncThunk<DTO<Store>, string, { state: RootState; }>(
	"partner/fetchStore",
	async (storeId, thunk) => {
		return await PartnerService.getStore(storeId).catch(err => thunk.rejectWithValue(err));
	}
);

export const fetchUserStores = createAsyncThunk<DTO<Store>[], void, { state: RootState; }>(
	"partner/fetchUserStores",
	async (_, thunk) => {
		return await UserService.getUserStores().catch(err => thunk.rejectWithValue(err));
	}
);

export const partnerSlice = createSlice({
	name: "partner",
	initialState,
	reducers: {
		setActiveStoreProduct(state, action: PayloadAction<StoreProductState>): void {
			state.activeStoreProduct = {
				...action.payload,
				depth_input: action.payload.depth_input ?? action.payload.dimensions?.depth,
				height_input: action.payload.height_input ?? action.payload.dimensions?.height,
				width_input: action.payload.width_input ?? action.payload.dimensions?.width,
				quantity: action.payload.quantity ?? action.payload.inventory?.quantity
			};
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchStore.fulfilled, (state, action) => {
				state.activeStore = action.payload;
			})
			.addCase(fetchUserStores.fulfilled, (state, action) => {
				state.userStores = action.payload;
			});
	}
});

export const {
	setActiveStoreProduct
} = partnerSlice.actions;

export const isStoreUserAdmin = (state: RootState): boolean => state.partner.userStores.length > 0;
export const selectUserStores = (state: RootState): DTO<Store>[] => state.partner.userStores;
export const selectActiveStore = (state: RootState): DTO<Store> | null => state.partner.activeStore;
export const selectActiveStoreProduct = (state: RootState): StoreProductState | null => state.partner.activeStoreProduct;

export default partnerSlice.reducer;