import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { listCustomerOrders, useAppDispatch } from "../../../app/store";
import { Loader } from "../../common";

export const DashboardProvider: React.FC = (props) => {
	const snack = useSnackbar();
	const dispatch = useAppDispatch();
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		dispatch(listCustomerOrders()).unwrap()
			.catch(err => {
				console.error("Failed to load customer orders", err);
				snack.enqueueSnackbar("We ran into an issue loading your information", {
					variant: "error"
				});
			}).finally(() => {
				setIsLoaded(true);
			});
	}, []);

	return (
		<Loader visible={!isLoaded}>
			{props.children}
		</Loader>
	);
};